import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'

// Components
import Button from '../global/Button'
import SimpleModal from '../global/SimpleModal'
import { Checkbox } from '@progress/kendo-react-inputs'
import { DropDownList } from '@progress/kendo-react-dropdowns'

import MallSlider from './components/MallSlider'
import AddToCartModalItem from './components/AddToCartModalItem'
import BannerImage from './components/BannerImage'
import Promotion from './components/Promotion'
import ProductItemTemplate2 from './components/ProductItemTemplate2'
import * as analytics from '../../analyticsEvents'
import ImpersonateBar from '../impersonate/ImpersonateBar'

import { SORT_OPTIONS, SORT_CLEAR_OPTION, getBrandNameWithoutSpaces, isFilterSelected, hasImage, getMallProduct } from './utils'
import Translation from '../global/Translation'

const LandingTemplates = ({
    landing,
    currentIso,
    showToastNotification,
    filters,
    addCartLine,
    fetchProductsLandingsByCenters,
    productsLanding,
    productsLots,
    lines,
    redirect,
    intl,
    addProductInFetchedProducts,
    setSelectedProductId,
    showFilters,
    setShowFilters,
    productSelectedToAdd,
    setProductSelectedToAdd,
    sortSelected,
    selectedSort,
    products,
    thumbsSwiper,
    setThumbsSwiper,
    selectFilter,
    unselectFilters,
    filtersSelected,
    landingData,
    commonParamsAnalytics,
    priceList,
    userPriceList,
    selectedCenter,
    userType,
    ...props
}) => {
    useEffect(() => {
        analytics.sendMallImpressions(products, priceList, userPriceList, commonParamsAnalytics)
    }, [products.length])

    const isAnyFilterActive = () => {
        let active = false

        Object.keys(filtersSelected).forEach(f => {
            if (f.length > 0) {
                active = true
            }
        })

        return active
    }

    return (
        <div className="mall">
            <MallSlider
                userType={userType}
                selectedCenter={selectedCenter}
                productsLanding={productsLanding}
                productsLots={productsLots}
                intl={intl}
                redirect={redirect}
                products={products}
                setProductSelectedToAdd={setProductSelectedToAdd}
                addProductInFetchedProducts={addProductInFetchedProducts}
                currentIso={currentIso}
                setSelectedProductId={setSelectedProductId}
            />
            <ImpersonateBar />
            <div className="container">
                <BannerImage landingData={landingData} />

                {landingData.promotion && landingData.promotion !== 'null' && <Promotion landingData={landingData} />}
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-12">
                        {/* Integrar clase .mall__filters--closed para controlar visibilidad de filtros */}
                        <div className="mall__filters mall__filters--column .mall__filters--closed">
                            <p className="mall__filters__toggle">
                                <span>Filtros</span>
                                <i className="fas fa-chevron-down"></i>
                            </p>
                            <div className="mall__filters__content">
                                <div className="row">
                                    {filters && filters.brand && !!filters.brand.length && (
                                        <div className="col-12">
                                            <p className="mall__filters__content__section">{intl.formatMessage({ id: 'brands' })}</p>
                                            <div className="mall__filters__content__checks">
                                                {filters.brand.map((filter, i) => (
                                                    <div key={i} className="mall__filters__content__check">
                                                        <Checkbox onChange={selectFilter('brand', filter)} id={filter} checked={isFilterSelected(filtersSelected['brand'], filter)}>
                                                            <label htmlFor={filter} className={'k-checkbox-label'}>
                                                                {filter === 'DEKTON' && selectedCenter === 7130 ? (
                                                                    <span>DKTN &reg;</span>
                                                                ) : !!hasImage(getBrandNameWithoutSpaces(filter)) ? (
                                                                    <img
                                                                        alt={filter}
                                                                        src={hasImage(getBrandNameWithoutSpaces(filter))}
                                                                        className={getBrandNameWithoutSpaces(filter)}
                                                                    />
                                                                ) : (
                                                                    <span>{filter} &reg;</span>
                                                                )}
                                                            </label>
                                                        </Checkbox>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {filters && filters.format && !!filters.format.length && (
                                        <div className="col-12">
                                            <p className="mall__filters__content__section">{intl.formatMessage({ id: 'format' })}</p>
                                            <div className="mall__filters__content__checks">
                                                {filters.format.map((filter, i) => (
                                                    <div key={i} className="mall__filters__content__check">
                                                        <Checkbox
                                                            onChange={selectFilter('format', filter)}
                                                            id={filter}
                                                            checked={isFilterSelected(filtersSelected['format'], filter)}
                                                        >
                                                            <label htmlFor={filter} className={'k-checkbox-label'}>
                                                                {filter}
                                                            </label>
                                                        </Checkbox>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {filters && filters.thickness && !!filters.thickness.length && (
                                        <div className="col-12">
                                            <p className="mall__filters__content__section">{intl.formatMessage({ id: 'thickness' })}</p>
                                            <div className="mall__filters__content__checks">
                                                {filters.thickness.map((filter, i) => (
                                                    <div key={i} className="mall__filters__content__check">
                                                        <Checkbox
                                                            onChange={selectFilter('thickness', filter)}
                                                            id={filter}
                                                            checked={isFilterSelected(filtersSelected['thickness'], filter)}
                                                        >
                                                            <label htmlFor={filter} className={'k-checkbox-label'}>
                                                                {filter} cm
                                                            </label>
                                                        </Checkbox>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-8 col-12">
                        <div className="mall__products">
                            <div className="mall__products__order">
                                <DropDownList
                                    label={intl.formatMessage({ id: 'order_by' })}
                                    textField="text"
                                    dataItemKey="id"
                                    data={sortSelected ? [...SORT_OPTIONS, SORT_CLEAR_OPTION] : SORT_OPTIONS}
                                    onChange={event => selectedSort(event.target.value.id)}
                                />
                            </div>
                            <div>
                                <div className="row">
                                    {!isAnyFilterActive() || (isAnyFilterActive() && products.length > 0) ? (
                                        products.map((product, i) => (
                                            <ProductItemTemplate2
                                                userType={userType}
                                                selectedCenter={selectedCenter}
                                                key={i}
                                                index={i}
                                                priceList={priceList}
                                                userPriceList={userPriceList}
                                                commonParamsAnalytics={commonParamsAnalytics}
                                                currentIso={currentIso}
                                                addProductInFetchedProducts={addProductInFetchedProducts}
                                                setSelectedProductId={setSelectedProductId}
                                                redirect={redirect}
                                                intl={intl}
                                                setProductSelectedToAdd={setProductSelectedToAdd}
                                                product={getMallProduct(product, productsLanding, productsLots)}
                                                {...props}
                                            />
                                        ))
                                    ) : (
                                        <div className="not-found">
                                            <p>
                                                <Translation id="no_materials_found" defaultMessage="No materials found according to the search parameters" />
                                            </p>
                                            <Button onClick={unselectFilters}>
                                                <Translation id="remove_filters" defaultMessage="Remove filters" />
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {productSelectedToAdd && (
                <SimpleModal customClass="mall__modal" title={''} size="large" footer={null} onClose={() => setProductSelectedToAdd(null)}>
                    <AddToCartModalItem
                        showToastNotification={showToastNotification}
                        userType={userType}
                        lines={lines}
                        priceList={priceList}
                        userPriceList={userPriceList}
                        commonParamsAnalytics={commonParamsAnalytics}
                        addCartLine={addCartLine}
                        product={productSelectedToAdd}
                        setProductSelectedToAdd={setProductSelectedToAdd}
                        addProductInFetchedProducts={addProductInFetchedProducts}
                        intl={intl}
                        selectedCenter={selectedCenter}
                        sap_landing_id={landing.landing.sap_id}
                    />
                </SimpleModal>
            )}
        </div>
    )
}

export default injectIntl(LandingTemplates)
