import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Translation from '../global/Translation'
import $ from 'jquery'
import { getIsOrderHistoryScreen, getIsShippingHistoryScreen } from '../../store/ui/reducers'

defineMessages({
    tell_us_what_you_think: {
        id: 'tell_us_what_you_think',
        description: 'tell_us_what_you_think',
        defaultMessage: 'Tell us what you think'
    },
})

const mapStateToProps = state => {
    return {
        isOrderHistoryScreen: getIsOrderHistoryScreen(state),
        isShippingHistoryScreen: getIsShippingHistoryScreen(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showModal: () => {
            $('.banner-comments').hide()
            dispatch({ type: 'SHOW_NEW_VERSION_MODAL_EXPERIENCE', showNewVersionModalExperience: true })
        }
    }
}

class BannerComments extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { showModal } = this.props
        return !this.props.isOrderHistoryScreen && !this.props.isShippingHistoryScreen ? (
            <div style={{
                position: 'absolute',
                right: 0
            }} gtm-label="ec_feedback_comp">
                <div className='banner-comments' style={{display: 'none'}}>
                    <div>
                        <span onClick={() => $('.banner-comments').hide()}>
                            <i className="fal fa-times"  />
                        </span>
                        <h1><Translation id="do_you_like_the_new_eCosentino" defaultMessage="Do you like the new eCosentino?" /></h1>
                        <h3><Translation id="help_us_improve_the_platform_by_giving_your_opinion" defaultMessage="Help us improve the platform by giving your opinion" /></h3>
                        <button onClick={() => showModal()} gtm-label="ec_feedback_comp_comments_send"><Translation id="post_comments" defaultMessage="Post comments" /></button>
                    </div>
                </div>
            </div>
        ) : null
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(BannerComments)
)
