import React from 'react'
import Translation from '../../global/Translation'

import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import { injectIntl } from 'react-intl'

import Alerts from '../../alerts/Alerts'
import Button from '../../global/Button'
import moment from 'moment'
import OrderFinishedContainer from '../../order-finished/OrderFinishedContainer'
import Select from 'react-select'
import CheckboxLinkSaleConditions from '../global/CheckboxLinkSaleConditions'

class CheckoutFormShop extends React.Component {
    componentDidMount() {
        this.props.resetCheckout().then(() => {
            this.props.setDeliveryDate(moment().add(1, 'days'))
        })
        this.props.onLoadCheckout()
    }

    canSendCheckout = () => {
        return (
            this.props.canSendMinMaxCheckout() &&
            !this.props.isSending &&
            this.props.totalQty > 0 &&
            this.props.canSendNeedDestination() &&
            this.props.canSendCheckoutSaleConditions
        )
    }

    render() {
        const { current, shippingMethod, goalMax } = this.props
        const sendText = <Translation id="reserve" defaultMessage="Reserve" />
        const difCurrent = current <= goalMax ? 0 : current - goalMax
        const errorMessage = difCurrent !== 0 ? 'MAX_WEIGHT_EXCEEDED' : null
        const submit = this.canSendCheckout() ? (
            <Button inverted datacy="checkout__send_checkout" onClick={() => this.props.submitCheckout()}>
                {sendText}
            </Button>
        ) : (
            <Button inverted disabled>
                {sendText}
            </Button>
        )
        if (this.props.isLoadingShippingAddress) return <Translation id="loading_shipping_addresses" defaultMessage="Loading Shipping Addresses" />
        const destinationForShop = [
            { value: 'CLIENTE', label: this.props.intl.formatMessage({ id: 'customer' }) },
            {
                value: 'HUB_CLIENTE',
                label: this.props.intl.formatMessage({ id: 'cosentino_hub' })
            },
            {
                value: 'CENTER',
                label: this.props.intl.formatMessage({ id: 'center' })
            }
        ]
        return (
            <div>
                <h4 className="form-heading heading">{sendText}</h4>
                <form>
                    <Alerts
                        alert={this.props.alert}
                        values={{ monthItems: this.props.monthItems, waitTillDate: this.props.waitTillDate ? moment(this.props.waitTillDate).format('L') : null }}
                        status={this.props.status}
                    />

                    {this.props.hasDestination && (
                        <div className={`form-group`}>
                            <label htmlFor="input-date">
                                {this.props.shippingMethod === 'CONTAINER' ? (
                                    <Translation id="destination_for_container" defaultMessage="Container unloading location" />
                                ) : (
                                    <Translation id="destination" defaultMessage="Destination" />
                                )}
                            </label>
                            <Select
                                className={`form-control`}
                                placeholder={`${this.props.intl.formatMessage({ id: 'select_shipping_address' })}...`}
                                value={this.props.destination}
                                onChange={e => {
                                    e && this.props.setDestination(e.value)
                                }}
                                options={destinationForShop}
                            />
                        </div>
                    )}

                    <div className="form-group">
                        <label htmlFor="input-comments">
                            <Translation id="comment" defaultMessage="Comments" />
                        </label>
                        <textarea
                            data-cy="checkout__textarea_comment"
                            className="form-control"
                            id="input-comments"
                            rows="3"
                            maxlength="2500"
                            onChange={e => {
                                e && this.props.setComments(e.target.value)
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <CheckboxLinkSaleConditions />
                    </div>
                </form>
                <div className="row align-items-baseline">
                    <div className="col-12" style={{ paddingLeft: '35px', paddingBottom: '10px' }}>
                        {errorMessage && (
                            <Translation
                                id="MAX_WEIGHT_EXCEEDED"
                                values={{
                                    shippingMethod: this.props.intl.formatMessage({ id: shippingMethod }).toLowerCase()
                                }}
                            />
                        )}
                        {this.props.isEuropeOrIberia ? (
                            <Translation
                                id="same_batch"
                                defaultMessage="Please, indicate if you want the slabs to be the same batch. Check availability with your center. Thank you"
                            />
                        ) : null}
                    </div>
                    <div className="col-4 offset-8">{submit}</div>
                </div>
                {this.props.reservationCode && <OrderFinishedContainer />}
            </div>
        )
    }
}

export default injectIntl(CheckoutFormShop)
