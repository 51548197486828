import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { getShippingMethod } from '../../../store/centers/reducers'
import { checkout } from '../../../store/cart/actions'
import { getAlert, isSending, getReservationCode, qtySelector, getStatus, getIsCartCutToSize } from '../../../store/cart/reducers'
import { getUserType, getUserName, getVersion } from '../../../store/login/reducers'
import { getProfileConfiguration } from '../../../store/profile-configuration/reducers'
import { getShippingAddresses, getShippingAddressesCenters, isLoadingShippingAddress, getSelectedShippingAddressForIkea } from '../../../store/shipping-addresses/reducers'
import { fetchShippingAddresses, changeIkeaShippingAddress } from '../../../store/shipping-addresses/actions'
import { getEmployeeId } from '../../../store/big-account/reducers'
import {
    resetCheckout,
    setDeliveryDate,
    setSelectedShippingAddress,
    setShippingAddressesEmails,
    setStoreId,
    setEmployeeId,
    setSelectedShippingAddressId,
    setSelectedShippingCenterId,
    setIkeaTransportArea,
    setPo,
    setComments,
    setInstallationDataName,
    setInstallationDataAddress,
    setInstallationDataTown,
    setInstallationDataProvince,
    setInstallationDataZipCode,
    setInstallationDataPhone
} from '../../../store/checkout/actions'
import {
    getDeliveryDate,
    getIkeaTransportArea,
    getSelectedShippingCenterId,
    getInstallationDataName,
    getInstallationDataAddress,
    getInstallationDataTown,
    getInstallationDataProvince,
    getInstallationDataZipCode,
    getInstallationDataPhone,
    getComments
} from '../../../store/checkout/reducers'
import { dispatchPushURL } from '../../../store/ui/actions'
import React from 'react'
import CheckoutFormIkea from './CheckoutFormIkea'
import CheckoutFormIkeaV2 from './CheckoutFormIkeaV2'
import CheckoutFormCustomerV2 from '../customer/CheckoutFormCustomerV2'
import CheckoutFormCustomer from '../customer/CheckoutFormCustomer'

const mapStateToProps = state => {
    return {
        shippingAddresses: getShippingAddresses(state),
        shippingAddressesCenters: getShippingAddressesCenters(state),
        alert: getAlert(state),
        status: getStatus(state),
        userType: getUserType(state),
        userName: getUserName(state),
        isSending: isSending(state),
        reservationCode: getReservationCode(state),
        totalQty: qtySelector(state),
        isLoadingShippingAddress: isLoadingShippingAddress(state),
        ikeaSelectedShippingAddress: getSelectedShippingAddressForIkea(state),
        profileConfiguration: getProfileConfiguration(state),
        employeeId: getEmployeeId(state),
        comments: getComments(state),
        storeId: getSelectedShippingAddressForIkea(state),

        deliveryDate: getDeliveryDate(state),

        //installation Data
        installationDataName: getInstallationDataName(state),
        installationDataAddress: getInstallationDataAddress(state),
        installationDataTown: getInstallationDataTown(state),
        installationDataProvince: getInstallationDataProvince(state),
        installationDataZipCode: getInstallationDataZipCode(state),
        installationDataPhone: getInstallationDataPhone(state),

        //posible abstracción
        shippingMethod: getShippingMethod(state),
        haveCutToSize: getIsCartCutToSize(state),
        ikeaTransportArea: getIkeaTransportArea(state),
        selectedShippingCenterId: getSelectedShippingCenterId(state),
        newVersion: getVersion(state),
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchShippingAddresses: () => dispatch(fetchShippingAddresses()),
        submitCheckout: () => dispatch(checkout()),
        onLoadCheckout: () => dispatch({ type: 'CHECKOUT_VISIT' }),
        changeIkeaShippingAddress: ikeaSelectedShippingAddress => dispatch(changeIkeaShippingAddress(ikeaSelectedShippingAddress)),
        goCatalog: intl => {
            dispatch(dispatchPushURL(intl.formatMessage({ id: 'ROUTE_CATALOG' }).replace(':outlet?', '')))
            dispatch({ type: 'REMEMBER_CENTER' })
        },
        resetCheckout: () => dispatch(resetCheckout()),
        setDeliveryDate: value => dispatch(setDeliveryDate(value)),
        setSelectedShippingAddress: value => dispatch(setSelectedShippingAddress(value)),
        setShippingAddressesEmails: value => dispatch(setShippingAddressesEmails(value)),
        setStoreId: value => dispatch(setStoreId(value)),
        setEmployeeId: value => dispatch(setEmployeeId(value)),
        setSelectedShippingAddressId: value => dispatch(setSelectedShippingAddressId(value)),
        setSelectedShippingCenterId: value => dispatch(setSelectedShippingCenterId(value)),
        setIkeaTransportArea: value => dispatch(setIkeaTransportArea(value)),
        setPo: value => dispatch(setPo(value)),

        setInstallationDataName: value => dispatch(setInstallationDataName(value)),
        setInstallationDataAddress: value => dispatch(setInstallationDataAddress(value)),
        setInstallationDataTown: value => dispatch(setInstallationDataTown(value)),
        setInstallationDataProvince: value => dispatch(setInstallationDataProvince(value)),
        setInstallationDataZipCode: value => dispatch(setInstallationDataZipCode(value)),
        setInstallationDataPhone: value => dispatch(setInstallationDataPhone(value)),

        setComments: value => dispatch(setComments(value))
    }
}

const CheckoutFormIkeaContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(React.forwardRef((props, ref) => props.newVersion ? <CheckoutFormIkeaV2 ref={ref} {...props} /> : <CheckoutFormIkea {...props} />))

export default CheckoutFormIkeaContainer
