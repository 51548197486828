import { connect } from 'react-redux'
import { fetchLots, fetchLotsImages } from '../../store/lots/actions'
import { fetchProduct } from '../../store/products/actions'

import Lots from './Lots'
import { byProductId, canViewLotId } from '../../store/products/reducers'
import {addCartBulkLots, addCartLineBundle, addCartLineLot, bulkAddToCart} from '../../store/cart/actions'
import {getLots, getIsLoading, getFiltered, getLotsImages, getSelectedLotsToBy, getBundlesRejectionCodes} from '../../store/lots/reducers'
import {
    getSelectedCenter,
    getHQCenterId,
    canBuyCatalog,
    isUsaOrCanada,
    isLatinaVitoria
} from '../../store/centers/reducers'
import {
    getIsAdmin,
    getToken,
    getUserType,
    getImpersonatedBy,
    getCommonParams,
    getVersion
} from '../../store/login/reducers'
import { getLanguage } from '../../store/ui/reducers'
import { injectIntl } from 'react-intl'
import { goBack } from 'react-router-redux'
import withRouter from 'react-router/withRouter'
import { push } from 'react-router-redux'
import { getProfileConfiguration } from '../../store/profile-configuration/reducers'
import {dispatchPushURL, openBundleContent} from '../../store/ui/actions'
import {getMallProductsLots, getSelectedProduct, getSelectedProductId} from "../../store/landing/reducers";
import {getLastSelectedProduct} from "../../store/selected-objects/reducers";
const mapStateToProps = (state, { match }) => {
    const selectedProduct = getLastSelectedProduct(state)
    return {
        isAdmin: getIsAdmin(state),
        impersonatedBy: getImpersonatedBy(state),
        token: getToken(state),
        language: getLanguage(state),
        productId: match.params.id,
        lots: getLots(state),
        lotsImages: getLotsImages(state),
        original: byProductId(state, match.params.id),
        isLoading: getIsLoading(state),
        outlet: match.params.outlet === 'outlet',
        fromMall: (match.params.outlet === 'mall' || (selectedProduct && selectedProduct.fromMall)),
        filtered: getFiltered(state),
        center: getSelectedCenter(state),
        canBuyCatalog: canBuyCatalog(state),
        userType: getUserType(state),
        userPreferences: getProfileConfiguration(state),
        canViewLotId: canViewLotId(state),
        commonParamsAnalytics: getCommonParams(state),
        HQCenterId: getHQCenterId(),
        isUsaOrCanada: isUsaOrCanada(state),
        mallProductLots: getMallProductsLots(state),
        selectedMallProductId: getSelectedProductId(state),
        isNewVersion: getVersion(state),
        minimumOrder: selectedProduct && selectedProduct.fromMall && selectedProduct.minimumOrder,
        selectedLots: getSelectedLotsToBy(state),
        isLatinaVitoria: isLatinaVitoria(state),
        selectedProduct,
        bundlesRejectionCodes: getBundlesRejectionCodes(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchLots: (productId, quarryBlock, shade, quality, location, outlet, callback) => {
            dispatch(fetchLots(productId, quarryBlock, shade, quality, location, outlet, callback))
        },
        fetchLotsImages: lotIds => {
            dispatch(fetchLotsImages(lotIds))
        },
        fetchProduct: productId => {
            dispatch(fetchProduct(productId))
        },
        buyTables: (productId, tableData, centerId) => {

        },
        bulkAddToCart: (product, lots, bundles, outlet) => {
            dispatch(bulkAddToCart(product, lots, bundles, outlet))
        },
        addCartLineLot: (product, lot) => {
            dispatch(addCartLineLot(product, lot))
        },
        goBack: () => {
            dispatch(goBack())
        },
        onLoadLots: (outlet, productId) => {
            dispatch({ type: 'LOTS_VISIT', outlet, productId })
        },
        redirect: url => {
            dispatch(dispatchPushURL(url))
        },
        setCurrentProductLots: productLots => {
            dispatch({ type: 'SET_CURRENT_PRODUCT_LOTS', productLots})
        },
        clearSelectedLots: () => dispatch({type: 'CLEAR_LOTS_TO_BUY'}),
        buyLot: (productId, lotId, centerId, extra = null, supplierCenterId = null) => {
            dispatch(addCartLineLot(productId, lotId, centerId, extra, supplierCenterId))
        },
        buyBundle: (productId, bundleId, lotId, qty, centerId, extra = null, supplierCenterId = null) => {
            dispatch(addCartLineBundle(productId, bundleId, lotId, qty, centerId, extra, supplierCenterId))
        },
        selectLot: (selectedLot, uuid) => {
            dispatch({type:'ADD_LOT_TO_BUY', selectedLot, uuid})
        },
        addCartBulkLots: (productId, selectedLots, isOutlet) => dispatch(addCartBulkLots(productId, selectedLots, isOutlet)),
        openBundleContent: bundle => dispatch(openBundleContent(bundle))

    }
}

const LotsContainer = injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Lots)
)

export default withRouter(LotsContainer)
