import React from 'react'
import Translation from '../global/Translation'

import LocalizedLink from '../global/LocalizedLink'
import Button from '../global/Button'
import imageOutlet from '../../assets/img/backgrounds/outlet.jpg'
import imageOutletThumb from '../../assets/img/backgrounds/outlet-thumb.jpg'
import imageMkWarehouse from '../../assets/img/backgrounds/warehouse.jpg'
import imageMkWarehouseThumb from '../../assets/img/backgrounds/warehouse-thumb.jpg'
import slide from '../../assets/img/home/home-slide.png'
import CTS_V2 from '../../assets/img/banners-v2/CTS_V2.png'
import Banos_V2 from '../../assets/img/banners-v2/Banos_V2.png'
import Outlet_V2 from '../../assets/img/banners-v2/Outlet_V2.png'
import Tablas_V2 from '../../assets/img/banners-v2/Tablas_V2.png'
import PPCC_V2 from '../../assets/img/banners-v2/PPCC_V2.png'
import outlet from '../../assets/img/home/home-slide-outlet.png'
import mall from '../../assets/img/home/mall-slide.png'
import promoNaturalStoneV2 from '../../assets/img/banners-exclusive-promo-natural-stone/promo_natural_stone_v2.png'
import { injectIntl } from 'react-intl'

import LazyImage from '../global/LazyImage'
import LastBoughtProductsContainer from '../products/LastBoughtProductsContainer'

import Spinner from '../global/Spinner'
import ChangeLogHomeNews from '../changelog/ChangeLogHomeNews'
import Alerts from '../alerts/Alerts'
import BannerHybriq from '../banner-hybriq/BannerHybriq'
import MenuContainer from '../header/MenuContainer'
import ProductsForYouContainer from "../products/ProductsForYouContainer";
import MallPreviewContainer from "../products/MallPreviewContainer";
import BannerDekton from '../banner-dekton/BannerDekton'
import Banner1Mktools from '../../assets/img/banners-mktools/Promo_Card_1.png'
import Banner2Mktools from '../../assets/img/banners-mktools/Promo_Card_2.png'
import Banner3Mktools from '../../assets/img/banners-mktools/Promo_Card_3.png'
import BannerWorkwear from '../../assets/img/banners-mktools/cosentino-workwear.jpg'
import BannerSupplies from '../../assets/img/banners-mktools/cosentino-supplies.jpg'
import BannerRockMaster from '../banner-rockmaster/BannerRockMaster'
import BannerEarthic from '../banner-earthic/BannerEarthic'
import BannerSilestoneXM from '../banner-silestone-xm/BannerSilestoneXM'

class HomeV2 extends React.Component {
    state = {hasMall: true}
    componentDidMount() {
        if (this.props.selectedCenter === 7777) this.props.goLowes(this.props.userType, this.props.intl)
        else if (this.props.isIkeaCenter) this.props.goIkea(this.props.intl)
    }

    render() {
        let homeSlides = [
                {
                    title: <Translation id="tables" defaultMessage="Slabs" />,
                    colorTitle: 'white',
                    subtitle: <Translation id="ask_about_stock" defaultMessage="Check Stock of your Usual Center" />,
                    colorSubtitle: 'white',
                    buttonText: <Translation id="shop_here" defaultMessage="Buy here" />,
                    buttonColor: 'white',
                    backgroundImage: slide,
                    backgroundImageLazy: slide,
                    routeId: 'ROUTE_CATALOG',
                    queryString: '?type[0]=TABLA',
                    params: { outlet: '' },
                    extraClassName: 'card-wide card-tall',
                    dataQas: ''
                },
                {
                    title: <Translation id="tables" defaultMessage="Slabs" />,
                    colorTitle: 'white',
                    subtitle: <Translation id="ask_about_stock" defaultMessage="Check Stock of your Usual Center" />,
                    colorSubtitle: 'white',
                    buttonText: <Translation id="shop_here" defaultMessage="Buy here" />,
                    buttonColor: 'white',
                    backgroundImage: mall,
                    backgroundImageLazy: mall,
                    routeId: 'ROUTE_CATALOG',
                    queryString: '?type[0]=TABLA',
                    params: { outlet: '' },
                    extraClassName: '',
                    dataQas: ''
                },
                {
                    title: <Translation id="cosentino_outlet" defaultMessage="Cosentino Outlet" />,
                    colorTitle: 'white',
                    subtitle: <Translation id="shop_here" defaultMessage="Buy here" />,
                    colorSubtitle: 'white',
                    buttonText: <Translation id="shop_here" defaultMessage="Buy here" />,
                    buttonColor: 'white',
                    backgroundImage: outlet,
                    backgroundImageLazy: outlet,
                    routeId: 'ROUTE_CATALOG',
                    queryString: '?type[0]=TABLA',
                    params: { outlet: 'outlet' },
                    extraClassName: '',
                    dataQas: ''
                },

            ]
        if(this.props.typeClient){
            switch (this.props.typeClient) {
                case 1:
                    if(this.props.testAB === 'A'){
                        homeSlides[0].title = <Translation id="CTS_V2_TXT" defaultMessage="Slabs" />
                        homeSlides[0].subtitle = <Translation id="CTS_V2_TXT_2" defaultMessage="Check Stock of your Usual Center" />
                        homeSlides[0].buttonText = <Translation id="access" defaultMessage="Buy here" />
                        homeSlides[0].backgroundImage = CTS_V2
                        homeSlides[0].backgroundImageLazy = CTS_V2
                        homeSlides[0].queryString = ''
                        homeSlides[0].params = { outlet: '' }
                        homeSlides[0].routeId = 'ROUTE_CUT_TO_SIZE'
                        homeSlides[0].dataQas = 'home_hero-cut-to-size-buy-here-button'

                        homeSlides[1].title = this.props.isAustralianCenter ?
                            <Translation id="TABLAS_V2_NO_SL_TXT" defaultMessage="Dekton and Sensa" />
                            :
                            <Translation id="TABLAS_V2_TXT" defaultMessage="Slabs" />
                        homeSlides[1].subtitle = <Translation id="tables" defaultMessage="Slabs" />
                        homeSlides[1].buttonText = <Translation id="access" defaultMessage="Buy here" />
                        homeSlides[1].backgroundImage = Tablas_V2
                        homeSlides[1].backgroundImageLazy = Tablas_V2
                        homeSlides[1].queryString = '?type[0]=TABLA'
                        homeSlides[1].params = { outlet: '' }
                        homeSlides[1].routeId = 'ROUTE_CATALOG'
                        homeSlides[1].dataQas = 'home_hero-slabs-buy-here-button'
                    }else{
                        homeSlides[0].title = this.props.isAustralianCenter ?
                            <Translation id="TABLAS_V2_NO_SL_TXT" defaultMessage="Dekton and Sensa" />
                            :
                            <Translation id="TABLAS_V2_TXT" defaultMessage="Slabs" />
                        homeSlides[0].subtitle = <Translation id="tables" defaultMessage="Slabs" />
                        homeSlides[0].buttonText = <Translation id="access" defaultMessage="Buy here" />
                        homeSlides[0].backgroundImage = Tablas_V2
                        homeSlides[0].backgroundImageLazy = Tablas_V2
                        homeSlides[0].queryString = '?type[0]=TABLA'
                        homeSlides[0].params = { outlet: '' }
                        homeSlides[0].routeId = 'ROUTE_CATALOG'
                        homeSlides[0].dataQas = 'home_hero-slabs-buy-here-button'

                        homeSlides[1].title = <Translation id="CTS_V2_TXT" defaultMessage="Slabs" />
                        homeSlides[1].subtitle = <Translation id="CTS_V2_TXT_2" defaultMessage="Check Stock of your Usual Center" />
                        homeSlides[1].buttonText = <Translation id="access" defaultMessage="Buy here" />
                        homeSlides[1].backgroundImage = CTS_V2
                        homeSlides[1].backgroundImageLazy = CTS_V2
                        homeSlides[1].queryString = ''
                        homeSlides[1].params = { outlet: '' }
                        homeSlides[1].routeId = 'ROUTE_CUT_TO_SIZE'
                        homeSlides[1].dataQas = 'home_hero-cut-to-size-buy-here-button'
                    }

                    homeSlides[2].title = <Translation id="OUTLET_V2_TXT" defaultMessage="Cosentino Outlet" />
                    homeSlides[2].subtitle = <Translation id="cosentino_outlet" defaultMessage="Cosentino Outlet" />
                    homeSlides[2].buttonText = <Translation id="view_promotion" defaultMessage="Buy here" />
                    homeSlides[2].backgroundImage = Outlet_V2
                    homeSlides[2].backgroundImageLazy = Outlet_V2
                    homeSlides[2].queryString = '?type[0]=TABLA'
                    homeSlides[2].params = { outlet: 'outlet' }
                    homeSlides[2].routeId = 'ROUTE_CATALOG'
                    homeSlides[2].dataQas = 'home_hero-outlet-buy-here-button'

                    break
                case 2:
                    homeSlides[0].title = <Translation id="CTS_V2_TXT" defaultMessage="Slabs" />
                    homeSlides[0].subtitle = <Translation id="CTS_V2_TXT_2" defaultMessage="Check Stock of your Usual Center" />
                    homeSlides[0].buttonText = <Translation id="access" defaultMessage="Buy here" />
                    homeSlides[0].backgroundImage = CTS_V2
                    homeSlides[0].backgroundImageLazy = CTS_V2
                    homeSlides[0].queryString = ''
                    homeSlides[0].params = { outlet: '' }
                    homeSlides[0].routeId = 'ROUTE_CUT_TO_SIZE'
                    homeSlides[0].dataQas = 'home_hero-cut-to-size-buy-here-button'

                    homeSlides[1].title = this.props.isAustralianCenter ?
                        <Translation id="TABLAS_V2_NO_SL_TXT" defaultMessage="Dekton and Sensa" />
                        :
                        <Translation id="TABLAS_V2_TXT" defaultMessage="Slabs" />
                    homeSlides[1].subtitle = <Translation id="tables" defaultMessage="Slabs" />
                    homeSlides[1].buttonText = <Translation id="access" defaultMessage="Buy here" />
                    homeSlides[1].backgroundImage = Tablas_V2
                    homeSlides[1].backgroundImageLazy = Tablas_V2
                    homeSlides[1].queryString = '?type[0]=TABLA'
                    homeSlides[1].params = { outlet: '' }
                    homeSlides[1].routeId = 'ROUTE_CATALOG'
                    homeSlides[1].dataQas = 'home_hero-slabs-buy-here-button'

                    homeSlides[2].title = <Translation id="OUTLET_V2_TXT" defaultMessage="Cosentino Outlet" />
                    homeSlides[2].subtitle = <Translation id="cosentino_outlet" defaultMessage="Cosentino Outlet" />
                    homeSlides[2].buttonText = <Translation id="view_promotion" defaultMessage="Buy here" />
                    homeSlides[2].backgroundImage = Outlet_V2
                    homeSlides[2].backgroundImageLazy = Outlet_V2
                    homeSlides[2].queryString = '?type[0]=TABLA'
                    homeSlides[2].params = { outlet: 'outlet' }
                    homeSlides[2].routeId = 'ROUTE_CATALOG'
                    homeSlides[2].dataQas = 'home_hero-outlet-buy-here-button'
                    break
                case 3:
                    homeSlides[0].title = <Translation id="baths_and_wall_cladding" defaultMessage="Slabs" />
                    homeSlides[0].subtitle = <Translation id="new_section" defaultMessage="Check Stock of your Usual Center" />
                    homeSlides[0].buttonText = <Translation id="access" defaultMessage="Buy here" />
                    homeSlides[0].backgroundImage = Banos_V2
                    homeSlides[0].backgroundImageLazy = Banos_V2
                    homeSlides[0].queryString = '?type[0]=BAÑOS'
                    homeSlides[0].params = { outlet: '' }
                    homeSlides[0].routeId = 'ROUTE_CATALOG'
                    homeSlides[0].dataQas = 'home_hero-baths-cladding-buy-here-button'

                    homeSlides[1].title = <Translation id="CTS_V2_TXT" defaultMessage="Slabs" />
                    homeSlides[1].subtitle = <Translation id="CTS_V2_TXT_2" defaultMessage="Check Stock of your Usual Center" />
                    homeSlides[1].buttonText = <Translation id="access" defaultMessage="Buy here" />
                    homeSlides[1].backgroundImage = CTS_V2
                    homeSlides[1].backgroundImageLazy = CTS_V2
                    homeSlides[0].queryString = ''
                    homeSlides[0].params = { outlet: '' }
                    homeSlides[0].routeId = 'ROUTE_CUT_TO_SIZE'
                    homeSlides[0].dataQas = 'home_hero-cut-to-size-buy-here-button'

                    homeSlides[2].title = <Translation id="professional_products" defaultMessage="Cosentino Outlet" />
                    homeSlides[2].subtitle = <Translation id="PPCC_V2_TITLE" defaultMessage="Buy here" />
                    homeSlides[2].buttonText = <Translation id="access" defaultMessage="Buy here" />
                    homeSlides[2].backgroundImage = PPCC_V2
                    homeSlides[2].backgroundImageLazy = PPCC_V2
                    homeSlides[2].queryString = '?type[0]=PPCC'
                    homeSlides[2].params = { outlet: '' }
                    homeSlides[2].routeId = 'ROUTE_CATALOG'
                    homeSlides[2].dataQas = 'home_hero-professional-products-buy-here-button'
                    break
                default:

            }
        }
        //const { salesOrg, userType } = this.props
        const promoEnabled = this.props.hasPromoActivated
        const virtualVisitEnabled = this.props.virtualVisitEnabled
        const URLVirtualVisit = this.props.URLVirtualVisit

        const outletImage = (
            <LazyImage preview={imageOutletThumb} image={imageOutlet} type="div" extraClass="image-slide">
                <div className="slide-title">
                    <h2 className="title">
                        <Translation id="cosentino_outlet" defaultMessage="Cosentino Outlet" />
                    </h2>
                </div>
                <div className="slide-bt">
                    <Button inline noborder size="medium">
                        <Translation id="shop_here" defaultMessage="Buy here" />
                    </Button>
                </div>
            </LazyImage>
        )
        const warehouseImage = (
            <LazyImage preview={imageMkWarehouseThumb} image={imageMkWarehouse} type="div" extraClass="image-slide">
                <div className="slide-title">
                    <h2 className="title">
                        <Translation id="warehouse" defaultMessage="Warehouse" />
                    </h2>
                </div>
                <div className="slide-bt">
                    <Button inline noborder size="medium">
                        <Translation id="shop_here" defaultMessage="Buy here" />
                    </Button>
                </div>
            </LazyImage>
        )
        let bannerSelector =
            this.props.selectedCenter === 7110
                ? 'BE-' + this.props.locale.toUpperCase()
                : this.props.selectedCenter === 7130
                ? 'CH-' + this.props.locale.toUpperCase()
                : this.props.locale.toUpperCase()
        // homeSlides = [
        //     {
        //         title: <Translation id="tables" defaultMessage="Slabs" />,
        //         colorTitle: 'white',
        //         subtitle: <Translation id="ask_about_stock" defaultMessage="Check Stock of your Usual Center" />,
        //         colorSubtitle: 'white',
        //         buttonText: <Translation id="shop_here" defaultMessage="Buy here" />,
        //         buttonColor: 'white',
        //         backgroundImage: newSlide,
        //         backgroundImageLazy: newSlide,
        //         routeId: 'ROUTE_CATALOG',
        //         queryString: '?type[0]=TABLA',
        //         params: { outlet: '' },
        //         extraClassName: 'card-wide card-tall'
        //     },
        //     {
        //         title: <Translation id="tables" defaultMessage="Slabs" />,
        //         colorTitle: 'white',
        //         subtitle: <Translation id="ask_about_stock" defaultMessage="Check Stock of your Usual Center" />,
        //         colorSubtitle: 'white',
        //         buttonText: <Translation id="shop_here" defaultMessage="Buy here" />,
        //         buttonColor: 'white',
        //         backgroundImage: newMall,
        //         backgroundImageLazy: newMall,
        //         routeId: 'ROUTE_CATALOG',
        //         queryString: '?type[0]=TABLA',
        //         params: { outlet: '' },
        //         extraClassName: ''
        //     },
        //     {
        //         title: <Translation id="cosentino_outlet" defaultMessage="Cosentino Outlet" />,
        //         colorTitle: 'white',
        //         subtitle: <Translation id="shop_here" defaultMessage="Buy here" />,
        //         colorSubtitle: 'white',
        //         buttonText: <Translation id="shop_here" defaultMessage="Buy here" />,
        //         buttonColor: 'white',
        //         backgroundImage: outlet,
        //         backgroundImageLazy: outlet,
        //         routeId: 'ROUTE_CATALOG',
        //         queryString: '?type[0]=TABLA',
        //         params: { outlet: 'outlet' },
        //         extraClassName: ''
        //     },
        //
        // ]
        // if(true && (this.props.promoBanners[bannerSelector] || this.props.promoBanners['default'].banner)){
        //     homeSlides.push({
        //         title: 'hello, im a title',
        //         subtitle: 'hello again ',
        //         buttonText: <Translation id="shop_here" defaultMessage="Buy here" />,
        //         backgroundImage: this.props.promoBanners['default'].banner,
        //         backgroundImageLazy: this.props.promoBanners['default'].banner,
        //         routeId: 'ROUTE_CATALOG',
        //         queryString: '?type[0]=TABLA&promo=true',
        //         params: { outlet: '' },
        //         extraClassName: ''
        //     })
        // }

        // if (virtualVisitEnabled){
        //     homeSlides[2] = {
        //         title:<Translation id="virtual_visit" defaultMessage="Book a Cosentino virtual visit" />,
        //         colorTitle: 'white',
        //         subtitle:<Translation id="virtual_visit_subtitle" defaultMessage="Select slabs from wherever you are" />,
        //         colorSubtitle: 'white',
        //         buttonText:<Translation id="make_appointment" defaultMessage="Make your appointment now" />,
        //         buttonColor: 'white',
        //         backgroundImage: newBannerHomeDraw,
        //         backgroundImageLazy: newBannerHomeDraw,
        //         routeId: URLVirtualVisit,
        //         queryString: '',
        //         params: {},
        //         extraClassName: ''
        //     }
        //     if(this.props.isMallPilotUser && !!this.props.userLandings.length && this.props.userLandings.length > 0 )
        //     homeSlides[2] = {
        //         title: 'Mall Campaing',
        //         subtitle: 'There is a mall campaing active on your center!',
        //         buttonText: <Translation id="shop_here" defaultMessage="Buy here" />,
        //         backgroundImage: newMall,
        //         backgroundImageLazy: newMall,
        //         routeId: 'ROUTE_LANDING_TEMPLATE',
        //         queryString: '',
        //         params: { slug: this.props.userLandings[0]['slug'], outlet: '', preview: '', iso: '' },
        //         buttonColor: 'white',
        //         extraClassName: ''
        //     }
            // homeSlides.push({
            //     title: 'hello, im a title',
            //     subtitle: 'hello again ',
            //     buttonText: <Translation id="shop_here" defaultMessage="Buy here" />,
            //     backgroundImage: bannerHomeDraw,
            //     backgroundImageLazy: bannerHomeDraw,
            //     routeId: URLVirtualVisit,
            //     queryString: '',
            //     params: {},
            //     extraClassName: ''
            // })
        // }
        /*
                     title: <Translation id="tables" defaultMessage="Slabs" />,
                    colorTitle: 'white',
                    subtitle: <Translation id="ask_about_stock" defaultMessage="Check Stock of your Usual Center" />,
                    colorSubtitle: 'white',
                    buttonText: <Translation id="shop_here" defaultMessage="Buy here" />,
                    buttonColor: 'white',
                    backgroundImage: mall,
                    backgroundImageLazy: mall,
                    routeId: 'ROUTE_CATALOG',
                    queryString: '?type[0]=TABLA',
                    params: { outlet: '' },
                    extraClassName: ''
                },        
        */
        if (this.props.canViewMktools && !this.props.isWarehouse) {
             homeSlides[0] = {
                 title: <Translation id="marketing_tools" defaultMessage="Marketing Tools" />,
                 colorTitle: 'white',
                 subtitle: '',
                 buttonText: <Translation id="shop_here" defaultMessage="Buy here" />,
                 backgroundImage: Banner1Mktools,
                 backgroundImageLazy: Banner1Mktools,
                 routeId: 'ROUTE_CATALOG',
                 queryString: '?webHeading[0]=MK',
                 params: { outlet: '' },
                 buttonColor: 'white',
                 extraClassName: ''
             }
             homeSlides[1] = {
                 title: <Translation id="showroom" defaultMessage="Showroom" />,
                 colorTitle: 'white',
                 subtitle: '',
                 buttonText: <Translation id="shop_here" defaultMessage="Buy here" />,
                 backgroundImage: Banner2Mktools,
                 backgroundImageLazy: Banner2Mktools,
                 routeId: 'ROUTE_CATALOG',
                 queryString: '?webHeading[0]=SH',
                 params: { outlet: '' },
                 buttonColor: 'white',
                 extraClassName: ''
             }
             delete homeSlides[2]
        }

        if (this.props.canViewMktools && this.props.isWarehouse) {
            homeSlides[0] = {
                title: <Translation id="WORKWEAR" defaultMessage="Workwear" />,
                colorTitle: 'white',
                subtitle: '',
                buttonText: <Translation id="shop_here" defaultMessage="Buy here" />,
                backgroundImage: BannerWorkwear,
                backgroundImageLazy: BannerWorkwear,
                routeId: 'ROUTE_CATALOG',
                queryString: '?typeId[0]=13',
                params: { outlet: '' },
                buttonColor: 'white',
                extraClassName: 'card-wide card-tall background-position-center'
            }
            homeSlides[1] = {
                title: <Translation id="WAREHOUSE_SUPPLIES" defaultMessage="warehouse supplies" />,
                colorTitle: 'white',
                subtitle: '',
                buttonText: <Translation id="shop_here" defaultMessage="Buy here" />,
                backgroundImage: BannerSupplies,
                backgroundImageLazy: BannerSupplies,
                routeId: 'ROUTE_CATALOG',
                queryString: '?typeId[0]=15',
                params: { outlet: '' },
                buttonColor: 'white',
                extraClassName: 'background-position-center'
            }
            homeSlides[2] = {
                title: <Translation id="warehouse" defaultMessage="Warehouse" />,
                colorTitle: 'white',
                subtitle: '',
                buttonText: <Translation id="shop_here" defaultMessage="Buy here" />,
                backgroundImage: Banner3Mktools,
                backgroundImageLazy: Banner3Mktools,
                routeId: 'ROUTE_CATALOG',
                queryString: '?webHeading[0]=WH',
                params: { outlet: '' },
                buttonColor: 'white',
                extraClassName: 'background-position-center'
            }
        }

        if(this.props.showBannerPromo) {
            homeSlides[0]['backgroundImage'] = promoNaturalStoneV2
            homeSlides[0]['title'] = <Translation id="exclusive_promo_natural_stone" defaultMessage="Exclusive promo Natural Stone" />
            homeSlides[0]['subtitle'] = <Translation id="from_14_18_november" defaultMessage="From 14-18 November" />
            homeSlides[0]['buttonText'] = <Translation id="make_a_reservation" defaultMessage="Make a reservation" />
            homeSlides[0]['URL'] = 'https://forms.cosentino.com/natural-stone-week-eu/'
        }

            return (
                <div id="home" style={{overflow: "hidden"}}>
                    {this.props.isLogging && <Spinner isLoading={this.props.isLogging} />}

                    <div className="container">
                        {this.props.maintenanceScheduledText && (
                            <Alerts status="primary" alert={<div dangerouslySetInnerHTML={{ __html: this.props.maintenanceScheduledText }} />} />
                        )}
                        <div className="home-slide-container">
                            {homeSlides.map((element, index) => {
                                return (
                                    element.URL && element.URL !== '' ? (
                                        <a href={element.URL} className={'image-slide-item ' + element.extraClassName} target='_blank'>
                                            <div className={'image-slide-item ' + element.extraClassName} style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${element.backgroundImage})` }}>
                                                <div className="slide-title">
                                                    <div className="subtitle" style={{ color: element.colorSubtitle }}>
                                                        {element.subtitle}
                                                    </div>
                                                    <h2 className="title" style={{ color: element.colorTitle }}>
                                                        {element.title}
                                                    </h2>
                                                </div>
                                                <div className="slide-bt">
                                                    <Button inline gentle size="medium" style={{ color: element.buttonColor, borderColor: element.buttonColor }} dataQas={element.dataQas} gtmLabel="ec_home_banner_mosaico">
                                                        {element.buttonText}
                                                    </Button>
                                                </div>
                                            </div>
                                        </a>
                                        ) : (
                                        <LocalizedLink
                                            key={index}
                                            routeId={element.routeId}
                                            queryString={element.queryString}
                                            params={element.params}
                                            className={'image-slide-item ' + element.extraClassName}
                                        >
                                            <div className={'image-slide-item ' + element.extraClassName} style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${element.backgroundImage})` }}>
                                                <div className="slide-title">
                                                    <div className="subtitle" style={{ color: element.colorSubtitle }}>
                                                        {element.subtitle}
                                                    </div>
                                                    <h2 className="title" style={{ color: element.colorTitle }}>
                                                        {element.title}
                                                    </h2>
                                                </div>
                                                <div className="slide-bt">
                                                    <Button inline gentle size="medium" style={{ color: element.buttonColor, borderColor: element.buttonColor }} dataQas={element.dataQas} gtmLabel="ec_home_banner_mosaico">
                                                        {element.buttonText}
                                                    </Button>
                                                </div>
                                            </div>
                                        </LocalizedLink>
                                    )
                                )
                            })}
                        </div>
                        {/* <div className="row slider no-gutters mb-5">
                            {promoEnabled && (this.props.promoBanners[bannerSelector] || this.props.promoBanners['default'].banner) ? (
                                <div className="col-12 col-lg-6">
                                    <LocalizedLink routeId="ROUTE_CATALOG" queryString="?type[0]=TABLA&promo=true" params={{ outlet: '' }}>
                                        <div className="d-block d-md-none">
                                            <img
                                                src={
                                                    this.props.promoBanners[bannerSelector]
                                                        ? this.props.promoBanners[bannerSelector].banner
                                                        : this.props.promoBanners['default'].banner
                                                }
                                                style={{ width: '100%' }}
                                                alt=""
                                            />
                                        </div>
                                        <div className="d-none d-md-block">
                                            <LazyImage
                                                preview={
                                                    this.props.promoBanners[bannerSelector]
                                                        ? this.props.promoBanners[bannerSelector].banner
                                                        : this.props.promoBanners['default'].banner
                                                }
                                                image={
                                                    this.props.promoBanners[bannerSelector]
                                                        ? this.props.promoBanners[bannerSelector].banner
                                                        : this.props.promoBanners['default'].banner
                                                }
                                                type="div"
                                                extraClass="left image-slide"
                                                style={{ maxHeight: '200px' }}
                                            />
                                        </div>
                                    </LocalizedLink>
                                </div>
                            ) : (
                                <div className="col-12 col-lg-6">
                                    {this.props.canViewMktools ? (
                                        <LocalizedLink
                                            routeId="ROUTE_CATALOG"
                                            queryString="?webHeading[0]=MK"
                                            params={{ outlet: '' }}
                                            onClick={() => this.props.changeCenter(1060)}
                                        >
                                            <LazyImage preview={imageMktoolsThumb} image={imageMktools} type="div" extraClass="left image-slide">
                                                <div className="slide-title">
                                                    <h2 className="title">
                                                        <Translation id="marketing_tools" defaultMessage="Marketing Tools" />
                                                    </h2>
                                                </div>
                                                <div className="slide-bt">
                                                    <Button inline gentle size="medium">
                                                        <Translation id="shop_here" defaultMessage="Buy here" />
                                                    </Button>
                                                </div>
                                            </LazyImage>
                                        </LocalizedLink>
                                    ) : (
                                        <div>
                                            {virtualVisitEnabled ? (
                                                <a href={URLVirtualVisit} target="_blank">
                                                    <LazyImage preview={bannerHomeDraw} image={bannerHomeDraw} extraClass="left image-slide banner-home" type="div">
                                                        <div className="slide-title">
                                                            <h2 className="title">
                                                                <b>
                                                                    <Translation id="virtual_visit" defaultMessage="Book a Cosentino virtual visit" />
                                                                </b>
                                                            </h2>
                                                            <h3 className="virtual_visit_subtitle">
                                                                <Translation id="virtual_visit_subtitle" defaultMessage="Select slabs from wherever you are" />
                                                            </h3>
                                                        </div>
                                                        <div className="slide-bt">
                                                            <Button inline gentle size="large">
                                                                <Translation id="make_appointment" defaultMessage="Make your appointment now" />
                                                            </Button>
                                                        </div>
                                                    </LazyImage>
                                                </a>
                                            ) : (
                                                <LocalizedLink routeId="ROUTE_CATALOG" queryString="?type[0]=TABLA" params={{ outlet: '' }}>
                                                    <LazyImage preview={imageSlabsThumb} image={imageSlabs} type="div" extraClass="left image-slide">
                                                        <div className="slide-title">
                                                            <h2 className="title">
                                                                <Translation id="tables" defaultMessage="Slabs" />
                                                            </h2>
                                                            <div className="subtitle">
                                                                <Translation id="ask_about_stock" defaultMessage="Check Stock of your Usual Center" />
                                                            </div>
                                                        </div>
                                                        <div className="slide-bt">
                                                            <Button inline gentle size="medium">
                                                                <Translation id="shop_here" defaultMessage="Buy here" />
                                                            </Button>
                                                        </div>
                                                    </LazyImage>
                                                </LocalizedLink>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className="col-12 col-lg-6">
                                <div className="row">
                                    <div className="col-12">
                                        {this.props.canViewMktools ? (
                                            <LocalizedLink
                                                routeId="ROUTE_CATALOG"
                                                queryString="?webHeading[0]=SH"
                                                params={{ outlet: '' }}
                                                onClick={() => this.props.changeCenter(1060)}
                                            >
                                                <LazyImage preview={imageShowroomThumb} image={imageShowroom} type="div" extraClass="image-slide">
                                                    <div className="slide-title">
                                                        <h2 className="title">
                                                            <Translation id="showroom" defaultMessage="Showroom" />
                                                        </h2>
                                                    </div>
                                                    <div className="slide-bt">
                                                        <Button inline noborder size="medium">
                                                            <Translation id="shop_here" defaultMessage="Buy here" />
                                                        </Button>
                                                    </div>
                                                </LazyImage>
                                            </LocalizedLink>
                                        ) : (
                                            <div>
                                                {virtualVisitEnabled ? (
                                                    <LocalizedLink routeId="ROUTE_CATALOG" queryString="?type[0]=TABLA" params={{ outlet: '' }}>
                                                        <LazyImage preview={imageSlabsThumb} image={imageSlabs} type="div" extraClass="image-slide mini-slab">
                                                            <div className="slide-title">
                                                                <h2 className="title">
                                                                    <Translation id="tables" defaultMessage="Slabs" />
                                                                </h2>
                                                                <div className="subtitle">
                                                                    <Translation id="ask_about_stock" defaultMessage="Check Stock of your Usual Center" />
                                                                </div>
                                                            </div>
                                                            <div className="slide-bt">
                                                                <Button inline gentle size="medium">
                                                                    <Translation id="shop_here" defaultMessage="Buy here" />
                                                                </Button>
                                                            </div>
                                                        </LazyImage>
                                                    </LocalizedLink>
                                                ) : (
                                                    <LocalizedLink routeId="ROUTE_CATALOG" queryString="?type[0]=PPCC" params={{ outlet: '' }}>
                                                        <LazyImage preview={imagePpccThumb} image={imagePpcc} type="div" extraClass="image-slide">
                                                            <div className="slide-title">
                                                                <h2 className="title">
                                                                    <Translation id="professional_products" defaultMessage="Professional Products" />
                                                                </h2>
                                                            </div>
                                                            <div className="slide-bt">
                                                                <Button inline noborder size="medium">
                                                                    <Translation id="shop_here" defaultMessage="Buy here" />
                                                                </Button>
                                                            </div>
                                                        </LazyImage>
                                                    </LocalizedLink>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-12">
                                        {this.props.canViewMktools ? (
                                            <LocalizedLink
                                                routeId="ROUTE_CATALOG"
                                                queryString="?webHeading[0]=WH"
                                                params={{ outlet: '' }}
                                                onClick={() => this.props.changeCenter(5000)}
                                            >
                                                {warehouseImage}
                                            </LocalizedLink>
                                        ) : (
                                            <LocalizedLink routeId="ROUTE_CATALOG" params={{ outlet: 'outlet' }}>
                                                {outletImage}
                                            </LocalizedLink>
                                        )}
                                    </div>
                                </div>
                            </div> */}
                        <div className="home-cards-container">
                            <MenuContainer customClass="home-card"></MenuContainer>
                        </div>
                        {this.props.hasPromotionLandingActivated && this.props.homeBanner && (
                            <div className="col-12">
                                <LocalizedLink routeId="ROUTE_PROMOTION_LANDING" params={{ promotionId: this.props.promotionLink }}>
                                    <div>
                                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%' }}>
                                            <div dangerouslySetInnerHTML={{ __html: this.props.promotionBannerTitle }} />
                                            <div dangerouslySetInnerHTML={{ __html: this.props.promotionBannerDescription }} />
                                        </div>
                                        <img src={this.props.homeBanner} style={{ width: '100%' }} alt="" className="" />
                                    </div>
                                </LocalizedLink>
                            </div>
                        )}
                    </div>


                    {
                        this.props.isUsaOrCanada &&
                        <React.Fragment>
                            <div className="container dekton-banner-desktop">
                                <BannerDekton />
                            </div>
                            <div className="container dekton-banner-squared">
                                <BannerDekton squared={true} />
                            </div>
                        </React.Fragment>
                    }

                    <ProductsForYouContainer sectionFlag="HP"/>
                    {
                        // this.props.isMallPilotUser && this.props.userLandings && this.props.userLandings.length > 0 &&
                        //this.state.hasMall > 0 &&
                        <MallPreviewContainer/>
                    }
                    <LastBoughtProductsContainer sectionFlag="HB"/>
                    
                    {this.props.canViewEarthicPromotion && (
                        <div className="container">
                            <BannerEarthic />
                        </div>
                    )}

                    {this.props.canViewRockMasterPromotion && (
                        <div className="container mb-4">
                            <BannerRockMaster />
                        </div>
                    )}

                    {this.props.canViewSilestonePromotionXM && (
                        <div className="container">
                            <BannerSilestoneXM />
                        </div>
                    )}

                    {/* {this.props.canViewHybriqPromotion && (
                        <div className="container">
                            <BannerHybriq />
                        </div>
                    )} */}

                    {this.props.isLogged && (
                        <div className="row">
                            <div className="col-12">
                                <ChangeLogHomeNews />
                            </div>
                        </div>
                    )}
                </div>
                // </div>
            )
    }
}

export default injectIntl(HomeV2)
