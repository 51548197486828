import React from 'react'
import Translation from '../global/Translation'
import FiltersV2 from './FiltersV2'
import CircleResultContainer from './CircleResultContainer'
import RectangleResultContainer from './rectangleResult/RectangleResultContainer'
import SampleResultContainer from './SampleResultContainer'
import * as analytics from '../../analyticsEvents'
import {
    SearchkitManager,
    SearchkitProvider,
    SearchBox,
    Hits,
    ResetFilters,
    BoolShould,
    TermQuery,
    InitialLoader,
    HitsStats,
    BoolMust, QueryAccessor, TermsQuery
} from 'searchkit'
import { injectIntl } from 'react-intl'
import MoreInfo from '../global/MoreInfo'
import OutletModal from '../global/OutletModal'
import Spinner from '../global/Spinner'
import { LowesInfoText } from './LowesInfoText'
import OutletInfoText from './OutletInfoText'
import Alerts from '../alerts/Alerts'
import ResultsV2 from "./ResultsV2";
import BannerDekton from '../banner-dekton/BannerDekton'
import BannerRockMaster from "../banner-rockmaster/BannerRockMaster";
import ProductContext, {CatalogProductContext} from "./rectangleResult/contexts/ProductContext";
import BigBannerRocket from '../banner-rocket/BigBannerRocket'
import Slider from 'react-slick'
import BannerSilestone from '../banner-silestone/BannerSilestone';
import BannerHybriq from '../banner-hybriq/BannerHybriq'
import { v4 as uuidv4 } from 'uuid'
import BannerEarthic from '../banner-earthic/BannerEarthic'
import BannerSilestoneXM from '../banner-silestone-xm/BannerSilestoneXM'

const customHitStats = (props) => {
    const {hitsCount} = props
    return hitsCount
}

const listRef = {
    value: ''
}

const getSk = (
    token,
    center,
    locale,
    outlet, search, fullView, userType, impersonatedBy, match, priceList, userPriceList, commonParamsAnalytics,
    HQCenterId,
    LowesCenterId,
    newVersion,
    sendSearchProduct,
    isPnHq,
    parent
) => {
    let lang = locale === 'fr-CA' || locale === 'en-CA' ? 'en-US' : locale
    const catalogUri = '/catalog/' + center
    const uri = catalogUri + '?lang=' + lang
    const uri2 = outlet ? uri + '&outlet=true' : uri
    //promo link works fine withouth this since dont knowh what version of react
    // const uri3 = search.indexOf('promo=true') > -1 ? uri2 + '&promo=true' : uri2

    // const uri3 = token ? uri2 + '&authToken=' + token : uri2
    const httpHeaders = token ? { Authorization: 'Bearer ' + token } : {}

    let sk = new SearchkitManager(process.env.REACT_APP_API_HOST + uri2, {
        searchUrlPath: '',
        timeout: 60000,
        httpHeaders
    })

    // Traducciones de cadenas de SearchKit
    sk.translateFunction = key => {
        let translations = {
            'pagination.previous': <Translation id="previous" defaultMessage="Previous" />,
            'pagination.next': <Translation id="next" defaultMessage="Next" />,
            'facets.view_more': <Translation id="view_more" defaultMessage="View more" />,
            'facets.view_less': <Translation id="view_less" defaultMessage="View less" />,
            'facets.view_all': <Translation id="view_all" defaultMessage="View all" />,
            'reset.clear_all': <Translation id="clear_all" defaultMessage="Clear all" />
        }
        return translations[key]
    }
    sk.addResultsListener(results => {
        parent.setState({ skKey: uuidv4() })
        if(sk.query.getQueryString()){
            sendSearchProduct(sk.query.getQueryString())
        }
        // analytics.sendCatalogImpressions(results.hits.hits, listRef.value, priceList, userPriceList, commonParamsAnalytics)
        analytics.sendCatalogImpressions(results.hits.hits, 'CATALOG', priceList, userPriceList, commonParamsAnalytics, null, center)
    })

    if (isPnHq) {
        const filter = outlet ? [
                TermQuery('centerId', '1100')
            ] : [
                TermQuery('centerId', '1100'),
                TermsQuery('brand.raw', ['SENSA', 'PREXURY', 'SCALEA'])
        ];

        sk.addDefaultQuery(query => {
            return query.addFilter('centerHQ', BoolMust(filter))
        })
    }

    if (center === LowesCenterId) {
        if (match.url.includes('customer-sample'))
            sk.addDefaultQuery(query => {
                return query.addFilter('type', BoolShould([TermQuery('subtypeId.raw', 'CUSTOMER SAMPLES')]))
            })
        else if (match.url.includes('brochures'))
            sk.addDefaultQuery(query => {
                return query.addFilter('type', BoolShould([TermQuery('subtypeId.raw', 'BROCHURES')]))
            })
    }

    if (!(userType === 'ADMIN' || impersonatedBy) && outlet && center === HQCenterId)
        sk.addDefaultQuery(query => {
            return query.addFilter('outletDeviated', BoolShould([TermQuery('outletDeviated.raw', false)]))
        })







    return sk
}

const sectionMapToLangId = {
    tabla: 'tables',
    productos_estandar: 'standard_products',
    ppcc: 'professional_products',
    outlet: 'outlet'
}

class Catalog extends React.Component {
    constructor(props) {
        super(props)
        let initState = {
            sk: null,
            showHelp: true,
            showOutletModal: this.props.outlet === 'outlet' && this.props.isAdmin,
            countResult: 0
        }
        this.state = initState
    }
    setSk = () => {
        const newSk = getSk(
            this.props.token,
            this.props.center,
            this.props.intl.locale,
            this.props.outlet,
            this.props.location.search,
            this.props.fullView,
            this.props.userType,
            this.props.impersonatedBy,
            this.props.match,
            this.props.priceList,
            this.props.userPriceList,
            this.props.commonParamsAnalytics,
            this.props.HQCenterId,
            this.props.LowesCenterId,
            this.props.newVersion,
            this.props.sendSearchProduct,
            this.state.pn_hq,
            this
        )

        //console.log('currentSearchRequest', newSk.currentSearchRequest)

        this.setState({ sk: newSk }
            // () => {
            // if (this.props.location.search.indexOf('promo=true') > -1 && this.props.isLogged && this.props.center !== 7500) {
            //     this.props.changeCenter(7500)
            // }
            // }
        )
    }
    componentDidMount() {
        this.setSk() // en el centro 1060 se pregunta locationf
        if (this.props.isLogged) {
            this.props.fetchProductsFavourite()
        }
        if (this.props.isMkToolsCenter) this.props.fetchShippingAddresses() // en el centro 1060 se pregunta location
        if (this.props.center === this.props.HQCenterId) this.props.fetchDeviatedDescriptions()
        let callbackSalesOrg = null;
        const search = this.props.location.search;
        const salesOrgSearch = '&salesorg=';
        if(search.includes(salesOrgSearch)){
            callbackSalesOrg = search.substring(
              search.indexOf(salesOrgSearch) + salesOrgSearch.length,
              search.indexOf(salesOrgSearch) + salesOrgSearch.length + 4
            );
        }
        if (!this.props.isLogged && this.props.location.search.includes('&lnd=true')) {
            this.props.loginRequired(callbackSalesOrg)
        }
        if(callbackSalesOrg){
            this.props.changeCenterFromSalesOrg(callbackSalesOrg)
        }
    }

    componentDidUpdate(prevProps) {

        const fromCrossSelling = this.props.location.search && this.props.location.search.includes('cross-selling')
        if (fromCrossSelling) listRef.value = 'crossselling-categoria'
        else {
            let catalogPage = 'CATALOG'
            if (this.props.location.search) {
                catalogPage = catalogPage + ' - busqueda de' + this.props.location.search
            }
            listRef.value = catalogPage
        }

        // if(this.props.location.search && this.props.location.search.includes('LAVABO')){
        //     this.props.locations = '?type[0]=BAÑOS&subtype[0]=Washbasin'
        //     this.state.sk && this.state.sk.runInitialSearch()
        // }
        // console.log(this.props)

        if (this.props.location !== prevProps.location) {

            //TODO solo hace la llamada cuando cambiar la url
            this.state.sk && this.state.sk.runInitialSearch() //TODO :identificar motivo y si se puede borrar, duplica llamada a elastic see on network
        }

        if (this.props.isLowesCenter && prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({ sk: null }, () => {
                this.setSk()
            })
        }
    }

    filterFavourites = () => {
        this.setState({ sk: null, favouriteFilter: !this.state.favouriteFilter }, () => {
            this.setSk()
        })
    }
    filterDiscounts = () => {
        this.setState({ sk: null, discountsFilter: !this.state.discountFilter }, () => {
            this.setSk()
        })
    }

    setPnHQ = (isPnHQ) => {
        // this.props.redirect(this.props.intl.formatMessage({ id: 'ROUTE_CATALOG' }).replace(':outlet?', ''))
        this.setState({pn_hq: isPnHQ})
        this.setState({ sk: null }, () => {
            this.setSk()
        })
    }

    render() {
        let LANG_TO_INDEX = {
            da: 'EN',
            de: 'DE',
            'en-US': 'Z2',
            en: 'EN',
            es: 'ES',
            fi: 'EN',
            'fr-CA': 'Z2',
            'en-CA': 'Z2',
            fr: 'FR',
            it: 'IT',
            nl: 'NL',
            no: 'EN',
            pt: 'PT',
            pl: 'EN',
            sv: 'SV',
            tr: 'TR'
        }
        if (this.props.canOnlyViewOutlet && this.props.outlet !== 'outlet') {
            this.props.redirect(this.props.intl.formatMessage({ id: 'ROUTE_CATALOG' }).replace(':outlet?', 'outlet'))
        }
        if (!this.props.canViewOutlet && this.props.outlet === 'outlet') {
            this.props.redirect(this.props.intl.formatMessage({ id: 'ROUTE_CATALOG' }).replace(':outlet?', ''))
        }

        const hitsComponent = this.props.fullView ? (this.props.isLowesCenter ? SampleResultContainer : RectangleResultContainer) : CircleResultContainer
        const hits = <Hits hitsPerPage={this.props.newVersion ? 23 : 24} itemComponent={hitsComponent} className={`${this.props.newVersion ? !this.props.fullView ? 'v2-circle' : '' : ''}`} />
        const ResetSearchbox = ({ hasFilters, resetFilters }) => {
            return <div onClick={resetFilters} style={{ visibility: hasFilters ? 'visible' : 'hidden' }} className="bt-reset-searchbox" />
        }
        const settings = {
            arrows: false,
            swipeToSlide: true,
            dots: false,
            speed: 300,
            autoplaySpeed: 10000,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            autoplay: true,
            variableWidth: false,
        }
        if (!this.state.sk) {
            return null
        }
        let bannerSelector =
            this.props.center === 7110
                ? 'BE-' + this.props.intl.locale.toUpperCase()
                : this.props.center === 7130
                ? 'CH-' + this.props.intl.locale.toUpperCase()
                : this.props.intl.locale.toUpperCase()
        return (
            <React.Fragment>
                {this.props.canViewRocketPromotion || this.props.canViewSilestonePromotion ? (
                    <div className="banners-rocket-silestone">
                        <Slider ref={s => (this.slider = s)} {...settings}>
                            {this.props.canViewRocketPromotion && <BigBannerRocket market={this.props.canViewRocketPromotion} />}
                            {this.props.canViewSilestonePromotion && <BannerSilestone isCheckoutView={false} />}
                        </Slider>
                    </div>
                ) : null}
                <SearchkitProvider searchkit={this.state.sk}>
                    <section id="section-catalog" className={this.props.outlet ? 'outlet' : ''}>
                        {this.state.showOutletModal && this.props.center === this.props.HQCenterId && !this.props.alreadyClosedOutletModal && (
                            <OutletModal
                                locale={this.props.intl.locale}
                                onClose={() =>
                                    this.setState({ showOutletModal: false }, () => {
                                        this.props.dontShowOutletModalAgain()
                                    })
                                }
                            />
                        )}
                        {this.props.currentLocation && this.props.currentLocation.section && sectionMapToLangId[this.props.currentLocation.section] && (
                            <div className="container">
                                <div className="row">
                                    <h2 className="catalog-title">
                                        <Translation id={sectionMapToLangId[this.props.currentLocation.section]} />
                                    </h2>
                                </div>
                            </div>
                        )}
                        {this.props.isIkeaCenter && (
                            <div className="container">
                                <div className="row">
                                    <h2 className="catalog-title">IKEA</h2>
                                </div>
                            </div>
                        )}
                        {this.props.isLowesCenter && (
                            <div className="container">
                                <div className="row">
                                    <h2 className="catalog-title">
                                        <Translation id="customer_samples" defaultMessage="Customer Samples" />
                                    </h2>
                                </div>
                            </div>
                        )}
                        <div className="search-container">
                            <div className="search-overlay">
                                <div className="container">
                                    <div className="row">
                                        <div className={'w-100'}>
                                            <div className="search-box-container">
                                                <div className="search-box">
                                                    <SearchBox
                                                        searchThrottleTime={1000}
                                                        searchOnChange={true}
                                                        queryFields={[
                                                            'colorId',
                                                            'colorName',
                                                            'description',
                                                            'brand',
                                                            'color',
                                                            'productId',
                                                            'productName',
                                                            'subtypeName',
                                                            'format',
                                                            'bigAccountsErpCode',
                                                            `translations.${LANG_TO_INDEX[this.props.intl.locale]}.displayName`,
                                                            `translations.${LANG_TO_INDEX[this.props.intl.locale]}.colorName`,
                                                            `translations.${LANG_TO_INDEX[this.props.intl.locale]}.productName`,
                                                            `translations.${LANG_TO_INDEX[this.props.intl.locale]}.zaddon`,
                                                            `colorFormulations.colorName`,
                                                            'technology'
                                                        ]}
                                                        queryOptions={{ default_operator: 'AND' }}
                                                        // placeholder={this.props.intl.formatMessage({ id: 'search' })}
                                                        placeholder="calacatta pol 2cm jumbo..."
                                                    />
                                                    <ResetFilters component={ResetSearchbox} options={{ query: true, filter: false, pagination: true }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.props.maintenanceScheduledText && (
                            <div className="container">
                                <div className="row">
                                    <Alerts status="primary" alert={<div dangerouslySetInnerHTML={{ __html: this.props.maintenanceScheduledText }} />} />
                                </div>
                            </div>
                        )}
                        <div className="filter-and-results-container">
                            <div className="container">
                                <div className={'row f-direction-column filtersv2'}>
                                    <div className={'filter-container'}>
                                        <FiltersV2
                                            {...this.props}
                                            filterFavourites={this.filterFavourites}
                                            filterDiscounts={this.filterDiscounts}
                                            favouriteProducts={this.props.productsIdFavourite}
                                            userDiscountsProductIds={this.props.promotionsProductIds}
                                            mktools={this.props.center === 5200 || this.props.center === 5000 || this.props.center === 1060}
                                            selectedCenter={this.props.center}
                                        ></FiltersV2>
                                    </div>

                                    <div className={'filter-container'}>
                                        {this.props.isLowesCenter && this.props.match.url.includes('customer-samples') && (
                                            <MoreInfo static>
                                                <LowesInfoText />
                                            </MoreInfo>
                                        )}
                                        {this.props.isLowesCenter && this.props.match.url.includes('brochures') && (
                                            <MoreInfo static>
                                                <h4>
                                                    <Translation id="brochure_re-ordering" defaultMessage="Brochure Re-Ordering" />
                                                </h4>

                                                <ul>
                                                    <li>
                                                        <Translation
                                                            id="To_start_your_order_click_on_the_brochure_that_you_need_to_re-order"
                                                            defaultMessage="To start your order, click on the brochure that you need to re-order"
                                                        />
                                                    </li>
                                                    <li>
                                                        <Translation
                                                            id="Brochures_are_in_packs_of_50_individual_brochures"
                                                            defaultMessage="Brochures are in packs of 50 individual brochures"
                                                        />
                                                    </li>
                                                    <li>
                                                        <Translation
                                                            id="Each_store_is_capped_at_1_pack_of_brochures_for_each_brand_per_month"
                                                            defaultMessage="Each store is capped at 1 pack of brochures for each brand, per month. "
                                                        />
                                                    </li>
                                                    <li>
                                                        <Translation
                                                            id="All_orders_will_be_delivered_within_2-3_weeks_of_order_and_sent_to_the_person_who_placed_the_order"
                                                            defaultMessage="All orders will be delivered within 2-3 weeks of order and sent to the person who placed the order"
                                                        />
                                                    </li>
                                                    <li>
                                                        <Translation id="Shipment_sent_to_the_assigned_store_only" defaultMessage="Shipment sent to the assigned store only" />
                                                    </li>
                                                </ul>
                                            </MoreInfo>
                                        )}
                                        {this.props.outlet && this.props.center === this.props.HQCenterId && (this.props.isAdmin || this.props.impersonatedBy) && (
                                            <MoreInfo moreInfoOutlet>
                                                <OutletInfoText language={this.props.language} />
                                            </MoreInfo>
                                        )}
                                        <div>
                                            <ResultsV2
                                                totalResult={<HitsStats component={customHitStats} />}
                                                hits={hits}
                                                token={this.props.token}
                                                outlet={this.props.outlet}
                                                isMkToolsCenter={this.props.isMkToolsCenter}
                                                isLowesCenter={this.props.isLowesCenter}
                                                isIkeaCenter={this.props.isIkeaCenter}
                                                currentLocation={this.props.currentLocation}
                                                statePnHQ={this.state.pn_hq}
                                                setPnHQ={this.setPnHQ}
                                                canViewOutletAndNaturalStoneFromHQInOtherCenters={this.props.canViewOutletAndNaturalStoneFromHQInOtherCenters}
                                                centerData={this.props.centerData}
                                                skKey={this.state.skKey}
                                            />
                                            {this.props.isUsaOrCanada && (
                                                <React.Fragment>
                                                    <div className="container dekton-banner-desktop">
                                                        <BannerDekton />
                                                    </div>
                                                    <div className="container dekton-banner-squared">
                                                        <BannerDekton squared={true} />
                                                    </div>
                                                </React.Fragment>
                                            )}
                                            
                                            {this.props.canViewEarthicPromotion && (
                                                <BannerEarthic />
                                            )}

                                            {this.props.canViewRockMasterPromotion && (
                                                <BannerRockMaster />
                                            )}

                                            {this.props.canViewSilestonePromotionXM && (
                                                <BannerSilestoneXM />
                                            )}
                                            
                                            {/* {this.props.canViewHybriqPromotion && (
                                                <BannerHybriq />
                                            )} */}
                                            
                                        </div>

                                        <InitialLoader component={() => <Spinner isLoading={this.state.sk} relative />} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </SearchkitProvider>
            </React.Fragment>
        )
    }
}

export default injectIntl(Catalog)

