import React from 'react'
import Translation from '../../global/Translation'

import { injectIntl, FormattedMessage } from 'react-intl'

import Alerts from '../../alerts/Alerts'
import Button from '../../global/Button'
import confirm from '../../global/confirm'
import moment from 'moment'
import OrderFinishedContainer from '../../order-finished/OrderFinishedContainer'

class CheckoutFormLowes extends React.Component {
    componentDidMount() {
        this.props.resetCheckout().then(() => {
            this.props.setDeliveryDate(moment().add(1, 'days'))
            this.props.setLowesDataFirstName('')
            this.props.setLowesDataLastName('')
            this.props.setLowesDataEmail('')
            this.props.setLowesDataTitle('')
        })
        this.props.onLoadCheckout()
    }

    confirmSubmitCheckout = e => {
        e.preventDefault()
        let storeNumber = this.props.userName
        confirm(
            <div className="confirm-checkout confirm-checkout--lowes">
                <div className="confirm-checkout_title">
                    <FormattedMessage id="please_confirm_the_order" defaultMessage="Please confirm the order" />:
                </div>
                <div className="confirm-checkout_sub-title">
                    <FormattedMessage id="lowes_store_number" defaultMessage="Lowes store: {storeNumber}" values={{ storeNumber: storeNumber }} />
                </div>
                <div className="confirm-checkout_text">
                    <FormattedMessage id="firt_name" defaultMessage="First Name" />: {this.props.lowesDataFirstName}
                </div>
                <div className="confirm-checkout_text">
                    <FormattedMessage id="last_name" defaultMessage="Last Name" />: {this.props.lowesDataLastName}
                </div>
                <div className="confirm-checkout_text">
                    <FormattedMessage id="email" defaultMessage="Email" />: {this.props.lowesDataEmail}
                </div>
                {this.props.lowesDataTitle && (
                    <div className="confirm-checkout_text">
                        <FormattedMessage id="title" defaultMessage="Title" />: {this.props.lowesDataTitle}
                    </div>
                )}
            </div>
        , {intl: this.props.intl}).then(
            confirm => {
                this.submitCheckout()
            },
            cancel => {}
        )
    }

    canSendCheckout = () => {
        return this.props.canSendLowesData() && this.props.canSendMinMaxCheckout() && !this.props.isSending && this.props.totalQty > 0 && this.props.canSendNeedDestination()
    }
    submitCheckout = () => {
        let realComments = {
            firstName: this.props.lowesDataFirstName,

            lastName: this.props.lowesDataLastName,
            email: this.props.lowesDataEmail,
            title: this.props.lowesDataTitle
        }
        this.props.setComments(JSON.stringify(realComments))

        this.props.submitCheckout()
    }
    render() {
        const { current, shippingMethod, goalMax } = this.props

        const sendText = <Translation id="submit" defaultMessage="Submit" />

        const difCurrent = current <= goalMax ? 0 : current - goalMax
        const errorMessage = difCurrent !== 0 ? 'MAX_WEIGHT_EXCEEDED' : null
        const submit = this.canSendCheckout() ? (
            <input style={{ width: '100%' }} type="submit" datacy="checkout__send_checkout" className="bt bt-inverted" value={this.props.intl.formatMessage({ id: 'submit' })} />
        ) : (
            <Button inverted disabled>
                {sendText}
            </Button>
        )
        return (
            <div className="checkout-form checkout-form--lowes">
                <h4 className="form-heading heading">{sendText}</h4>
                <form onSubmit={e => this.confirmSubmitCheckout(e)}>
                    <Alerts
                        alert={this.props.alert}
                        values={{ monthItems: this.props.monthItems, waitTillDate: this.props.waitTillDate ? moment(this.props.waitTillDate).format('L') : null }}
                        status={this.props.status}
                    />

                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="input-lowes-data-first-name">
                                    <Translation id="first_name" defaultMessage="First name" /> <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    name="name"
                                    required
                                    id="input-lowes-data-first-name"
                                    className="form-control"
                                    value={this.props.lowesDataFirstName}
                                    type="text"
                                    onChange={e => {
                                        e && this.props.setLowesDataFirstName(e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="input-lowes-data-last-name">
                                    <Translation id="last_name" defaultMessage="Last Name" /> <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    name="name"
                                    required
                                    id="input-lowes-data-last-name"
                                    className="form-control"
                                    value={this.props.lowesDataLastName}
                                    type="text"
                                    onChange={e => {
                                        e && this.props.setLowesDataLastName(e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="input-lowes-data-email">
                                    <Translation id="email" defaultMessage="Email" /> <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    name="email"
                                    required
                                    id="input-lowes-data-email"
                                    className="form-control"
                                    value={this.props.lowesDataEmail}
                                    type="email"
                                    onChange={e => {
                                        e && this.props.setLowesDataEmail(e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="input-lowes-data-title">
                                    <Translation id="title" defaultMessage="Title" />
                                </label>
                                <input
                                    name="title"
                                    id="input-lowes-data-title"
                                    className="form-control"
                                    value={this.props.lowesDataTitle}
                                    type="text"
                                    onChange={e => {
                                        e && this.props.setLowesDataTitle(e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-baseline">
                        <div className="col-12" style={{ paddingLeft: '35px', paddingBottom: '10px' }}>
                            {errorMessage && (
                                <Translation
                                    id="MAX_WEIGHT_EXCEEDED"
                                    values={{
                                        shippingMethod: this.props.intl.formatMessage({ id: shippingMethod }).toLowerCase()
                                    }}
                                />
                            )}
                            {this.props.isEuropeOrIberia ? (
                                <Translation
                                    id="same_batch"
                                    defaultMessage="Please, indicate if you want the slabs to be the same batch. Check availability with your center. Thank you"
                                />
                            ) : null}
                        </div>
                        <div className="col-4 offset-8">{submit}</div>
                    </div>
                </form>

                {this.props.reservationCode && <OrderFinishedContainer />}
            </div>
        )
    }
}

export default injectIntl(CheckoutFormLowes)
