import React from 'react'

import ReactTooltip from 'react-tooltip'
import { defineMessages, injectIntl } from 'react-intl'
import Translation from '../global/Translation'
import moment from 'moment'
import { getVersion } from '../../store/login/reducers'
import { connect } from 'react-redux'

defineMessages({
    pendiente_de_crear: {
        id: 'order_in_preparation',
        description: 'order_in_preparation',
        defaultMessage: 'Order in preparation'
    },
    order_delivered: {
        id: 'order_delivered',
        description: 'order_delivered',
        defaultMessage: 'Order delivered'
    },
    order_billed: {
        id: 'order_billed',
        description: 'order_billed',
        defaultMessage: 'Order Billed'
    },
    order_shipped: {
        id: 'order_shipped',
        description: 'order_shipped',
        defaultMessage: 'Order Shipped'
    },
    reservation_status_created: {
        id: 'reservation_status_created',
        description: 'reservation_status_created',
        defaultMessage: 'Reservation Created'
    },
    order_status_created: {
        id: 'order_status_created',
        description: 'order_status_created',
        defaultMessage: 'Order Created'
    },
    order_status_approved: {
        id: 'order_status_approved',
        description: 'order_status_approved',
        defaultMessage: 'Order Confirmed'
    },
    order_status_preparation: {
        id: 'order_status_preparation',
        description: 'order_status_preparation',
        defaultMessage: 'In Process'
    },
    order_status_shipped: {
        id: 'order_status_shipped',
        description: 'order_status_shipped',
        defaultMessage: 'In Transit'
    },
    order_status_pickup: {
        id: 'order_status_pickup',
        description: 'order_status_pickup',
        defaultMessage: 'Ready for pickup on Center / HUB'
    },
    order_status_delivered: {
        id: 'order_status_delivered',
        description: 'order_status_delivered',
        defaultMessage: 'Delivered'
    },
    order_status_invoiced: {
        id: 'order_status_invoiced',
        description: 'order_status_invoiced',
        defaultMessage: 'Order invoiced'
    },
    order_pending_cancellation: {
        id: 'order_pending_cancellation',
        description: 'order_pending_cancellation',
        defaultMessage: 'Order Pending Cancellation'
    },
    order_status_pending_changes: {
        id: 'order_status_pending_changes',
        description: 'order_status_pending_changes',
        defaultMessage: 'Order Pending Changes'
    },
    order_status_cancelled: {
        id: 'order_status_cancelled',
        description: 'order_status_cancelled',
        defaultMessage: 'Order Cancelled'
    },
    order_status_error: {
        id: 'order_status_error',
        description: 'order_status_error',
        defaultMessage: 'Error'
    },
    order_status_partially_delivered: {
        id: 'order_status_partially_delivered',
        description: 'order_status_partially_delivered',
        defaultMessage: 'Partially delivered'
    }
})

const mapStateToProps = (state, { match }) => {
    return {
        isNewVersion: getVersion(state),
    }
}

function focusSphere(index) {
    const focusClass = ' focus'
    const visibleClass = ' show'
    try {
        var spherasElement = document.getElementsByClassName('tracking-status-bar__item')
        var advertElement = document.getElementsByClassName('timeline-advert')[0]
        if (spherasElement[index].className.includes(focusClass)) {
            //Eliminamos clase focus.
            spherasElement[index].className = spherasElement[index].className.replace(focusClass, '')
            advertElement.className += visibleClass
        } else {
            //Eliminamos clase focus a todos y la añadimos al index
            advertElement.className = advertElement.className.replace(visibleClass, '')
            Array.prototype.forEach.call(spherasElement, function(sphera) {
                sphera.className = sphera.className.replace(focusClass, '')
            })
            spherasElement[index].className += focusClass
        }
    } catch (e) {
        // console.log('Unable to find tracking-status-bar__item object with index ' + index)
    }
}

let Sphere = ({ active, current, date, statusText, warning, danger, warningMsg, sphereIndex, opposite, condensed, checkMark, isNewVersion, delivery }) => {
    const labelStyle = {}
    if (opposite) labelStyle.top = '-2em'
    if (condensed || (active && checkMark)) {
        labelStyle.top = '2.6em'
    }

    const shouldHighlighted = delivery && delivery[sphereIndex] && delivery[sphereIndex].active && (!delivery[sphereIndex + 1] || !delivery[sphereIndex + 1].active)

    return (
        <div
            className={`tracking-status-bar__item ${active ? 'tracking-status-bar__item--active' : ''}`}
            data-tip={warning ? warningMsg : null}
            onClick={() => focusSphere(sphereIndex)}
        >
            <div className={
                    `tracking-status-bar__item__sphere 
                    ${condensed ? 'tracking-status-bar__item__sphere__condensed' : ''} 
                    ${warning ? 'bg-warning' : ''}
                    ${danger ? 'bg-danger' : ''}`
                }
                style={ (current && checkMark) ? {width: '1.8em',height: '1.8em'} : {} }
            >
                { (current && checkMark && !danger) && <i className="fal fa-check"></i> }
                { (current && checkMark && danger) && <i className="fal fa-times"></i> }
            </div>
            {/* {active && <span>sfera activa</span>} */}

            {!isNewVersion ? (
                ((condensed && current) || (!condensed && active)) && (
                    <React.Fragment>
                        {date && (
                            <div className={`tracking-status-bar__item__date ${warning ? 'tracking-status-bar__item__date__warning text-warning' : ''}`}
                            >
                                {warning && (
                                    <React.Fragment>
                                        <i className="fa fa-exclamation-triangle text-warning" /> <br />
                                    </React.Fragment>
                                )}
                                <span>{moment(date).format('L')}</span>
                            </div>
                        )}
                        <div className="tracking-status-bar__item__text" style={labelStyle}>{statusText}</div>
                    </React.Fragment>
                )
            ) : (
                <React.Fragment>
                    {date ? (
                        <div className={`tracking-status-bar__item__date ${warning ? 'tracking-status-bar__item__date__warning text-warning' : ''}`}
                        >
                            {warning && (
                                <React.Fragment>
                                    <i className="fa fa-exclamation-triangle text-warning" /> <br />
                                </React.Fragment>
                            )}
                            <div className={`${shouldHighlighted ? 'tracking-status-bar__item__date__highlighted' : ''}`}>
                                <div className="tracking-status-bar__item__text">{moment(date).format('L')}</div>
                                <span className={`${shouldHighlighted ? 'tracking-status-bar__item__date__status' : ''}`}>{statusText}</span>
                            </div>
                        </div>
                    ) : (
                        <div className={`tracking-status-bar__item__date test`}>
                            <div className={`${shouldHighlighted ? 'tracking-status-bar__item__date__highlighted' : ''}`}>
                                <div className='tracking-status-bar__item__text'>{' '}</div>
                                <span className={`${shouldHighlighted ? 'tracking-status-bar__item__date__status' : ''}`}>{statusText}</span>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
        </div>
    )
}
let LineSphere = ({ active, current, date, statusText, warning, danger, lineWarning, warningMsg, index, alternate, condensed, checkMark, isNewVersion, delivery }) => {
    return (
        <React.Fragment>
            <div className={`tracking-status-bar__line 
                            ${active ? 'tracking-status-bar__line--active' : ''} 
                            ${lineWarning ? 'bg-warning' : ''}1
                            ${condensed ? 'tracking-status-bar__line__condensed' : ''}`}
            />
            <Sphere active={active}
                    current={current}
                    date={date}
                    statusText={statusText}
                    warning={warning}
                    warningMsg={warningMsg}
                    danger={danger}
                    sphereIndex={index}
                    opposite={!condensed && alternate && index%2 > 0}
                    condensed={condensed}
                    checkMark={{checkMark}}
                    isNewVersion={isNewVersion}
                    delivery={delivery}
            />
        </React.Fragment>
    )
}

class Timeline extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    findLast = (items, predicate) => {
        for (var i = items.length - 1; i >= 0; i--) {
            var item = items[i]

            if (predicate(item)) {
                return item
            }
        }
    }
    render() {
        const { orderStatusMap, delivery, detailsView, isNewVersion, isVertical, isHorizontal } = this.props

        if (this.props.rejected) {
            return (
                <div className="reject-col">
                    <div className="reject-indicator">
                        <i className="fa fa-times" /> <Translation id="order_rejected" defaultMessage="Order rejected" />
                    </div>
                </div>
            )
        }
        return (
            <div className={(detailsView ? 'detail-view ' : 'preview ') + 'wrapper__tracking-status-bar'}>
                <div className={`tracking-status-bar tracking-status-bar--ok 
                ${isVertical ? 'tracking-status-bar__vertical' : ''} 
                ${isHorizontal ? 'tracking-status-bar__horizontal' : ''}
                `}>
                    {delivery.map((info, index) => {
                        return index === 0 ? (
                            <Sphere
                                active={info.active}
                                current={info.current}
                                date={this.props.withDates ? info.date : null}
                                statusText={orderStatusMap[info.statusText] && this.props.intl.formatMessage({ id: orderStatusMap[info.statusText].textToShow })}
                                // warning={info.alert} para mostrar bolas en amarillo rellenas
                                warning={info.alert}
                                danger={info.danger}
                                warningMsg={this.props.intl.formatMessage({
                                    id: 'something_went_wrong_before_delivery',
                                    defaultMessage: 'Something went wrong with the process before delivery'
                                })}
                                sphereIndex={index}
                                condensed={this.props.condensed}
                                checkMark={this.props.checkMark}
                                isNewVersion={isNewVersion}
                                delivery={delivery}
                            />
                        ) : (
                            <LineSphere
                                active={info.active}
                                current={info.current}
                                date={this.props.withDates ? info.date : null}
                                statusText={orderStatusMap[info.statusText] && this.props.intl.formatMessage({ id: orderStatusMap[info.statusText].textToShow })}
                                // warning={info.alert} para mostrar bolas en amarillo rellenas
                                warning={info.alert}
                                danger={info.danger}
                                lineWarning={info.alert && this.findLast(delivery, item => item.active) == index} //la linea solo amarilla si estamos en el ultimo step y tenemos alerta
                                warningMsg={this.props.intl.formatMessage({
                                    id: 'something_went_wrong_before_delivery',
                                    defaultMessage: 'Something went wrong with the process before delivery'
                                })}
                                index={index}
                                alternate={this.props.alternate}
                                condensed={this.props.condensed}
                                checkMark={this.props.checkMark}
                                isNewVersion={isNewVersion}
                                delivery={delivery}
                            />
                        )
                    })}
                </div>
                <div className="timeline-advert show">
                    <Translation id="click_stage_timeline" defaultMessage="Clic on any stage of the timeline" />
                </div>
                <ReactTooltip type="warning" backgroundColor="#f7d21d" effect="solid" place="bottom" />
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(Timeline)
)
