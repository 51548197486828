import React from 'react'
import LocalizedLink from '../global/LocalizedLink'
import Banner_portal_financiero from '../../assets/img/banners-v2/banner-portal-financiero.png'
import Translation from '../global/Translation'

export default class BannerMiPortalFinanciero extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <a
                href="https://www.cosentino.com/"
            >
                <div className={this.props.type && this.props.type === 'v2' ? 'box-parent-banner-portal-financiero-v2' : 'box-parent-banner-portal-financiero'} style={{
                    backgroundImage: `url(${Banner_portal_financiero})`,
                }}>
                    <div className="box-container">
                        <div className="box-title">
                            <Translation id="my_inancial_portal" defaultMessage="My Financial Portal" />
                        </div>
                        <div className="box-subtitle">
                            <Translation id="check_your_purchases" defaultMessage="Check your purchases, discounts and the status of your account" />
                        </div>
                        <div className="box-button-container">
                            <button className={this.props.type && this.props.type === 'v2' ? 'v2 bt bt-inverted' : 'bt bt-inverted'}>
                                <a href='https://we.cosentino.com/s/financialPortal'>
                                    <Translation id="go_to_cosentino_we" defaultMessage="Go to Cosentino We" />
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </a>
        )
    }
}
