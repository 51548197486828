import React, { Component } from 'react'
import LocalizedLink from '../global/LocalizedLink'
import { getIsLogged } from '../../store/login/reducers'
import { connect } from 'react-redux'
import { getIsLowesCenter } from '../../store/centers/reducers'
import Translation from '../global/Translation'

const mapStateToProps = state => {
    return { isLogged: getIsLogged(state), isLowesCenter: getIsLowesCenter(state) }
}
class Suggestions extends Component {
    render() {
        return (
            this.props.isLogged &&
            !this.props.isLowesCenter && (
                <div>
                    <div className="suggestions-row">
                        <LocalizedLink routeId="ROUTE_SUGGESTIONS">
                            <div className="container" data-cy="link-suggestions">
                                <i className="icon fas visible fa-inbox" /> <Translation id="msg_suggestion_mailbox" defaultMessage="Suggestion mailbox" />
                            </div>
                        </LocalizedLink>
                    </div>
                </div>
            )
        )
    }
}

export default connect(
    mapStateToProps,
    null
)(Suggestions)
