import React from 'react'
import Translation from '../global/Translation'
import DisplayThickness from '../catalog/rectangleResult/displays/DisplayThickness'

import { connect } from 'react-redux'
import { defineMessages, injectIntl } from 'react-intl'
import { push } from 'connected-react-router'
import {
    clearOrderDetails,
    fetchOrderDetails,
    fetchPackagingImages,
    fetchPackingList,
    getFileByUrl,
    rejectOrder
} from '../../store/orders/actions'
import {
    getError,
    getInvoices,
    getOpenDocDetails,
    getOrder,
    getOrderDetailsHead,
    getOrderDetailsLoading,
    getOrderStatusMap, getPackagingImages,
    getPackingList,
    getStatusMap, isFetchingPackagingImages
} from '../../store/orders/reducers'
import {getToken, isEmserUser} from '../../store/login/reducers'
import { getLanguage } from '../../store/ui/reducers'
import Button from '../global/Button'
import Spinner from '../global/Spinner'
import Modal from '../global/Modal'
import GoBack from '../global/GoBack'
import { getShippingAddresses } from '../../store/shipping-addresses/reducers'
import OrderLineContainer from '../cart-line/OrderLineContainer'
import moment from 'moment'
import PackingList from './PackingList'
import OrderHeader from './OrderHeader'
import confirm from '../global/confirm'
import ProductDisplayName from '../global/ProductDisplayName'
import OrderDetailsTable from './OrderDetailsTable'
import OrderFullMobile from './OrderFullMobile'
import ProductImage from '../catalog/ProductImage'
import iconCancel from '../../assets/img/icons/trash.svg'
import iconFile from '../../assets/img/icons/file.svg'
import iconPrint from '../../assets/img/icons/print.svg'
import iconEdit from '../../assets/img/icons/edit.svg'
import ReactTable from 'react-table'
import Error from '../alerts/Error'
import DisplayOutletIndicator from '../catalog/rectangleResult/displays/DisplayOutletIndicator'
import { dispatchPushURL } from '../../store/ui/actions'
import PackagingImageViewer from "../packaging-images-viewer/PackagingImageViewer"

defineMessages({
    delivery_status_NOT_TREATED: {
        id: 'delivery_status_NOT_TREATED',
        description: 'delivery_status_NOT_TREATED',
        defaultMessage: 'Pending'
    },
    delivery_status_TREATED_PARTIALLY: {
        id: 'delivery_status_TREATED_PARTIALLY',
        description: 'delivery_status_TREATED_PARTIALLY',
        defaultMessage: 'In progress'
    },
    delivery_status_TREATED_COMPLETELLY: {
        id: 'delivery_status_TREATED_COMPLETELLY',
        description: 'delivery_status_TREATED_COMPLETELLY',
        defaultMessage: 'Finalized'
    }
})

const mapStateToProps = (state, { match }) => {
    return {
        packingList: getPackingList(state),
        order: getOrder(state),
        orderDetailsHead: getOrderDetailsHead(state),
        openDocDetails: getOpenDocDetails(state),
        isLoading: getOrderDetailsLoading(state),
        token: getToken(state),
        lang: getLanguage(state),
        error: getError(state),
        reservationId: match.params.open,
        shippingAddresses: getShippingAddresses(state),
        statusMap: getStatusMap(state),
        orderStatusMap: getOrderStatusMap(state),
        invoices: getInvoices(state),
        isEmserUser : isEmserUser(state),
        packagingImages : getPackagingImages(state),
        isFetchingPackagingImages: isFetchingPackagingImages(state)
    }
}



const mapDispatchToProps = dispatch => {
    return {
        fetchOrderDetails: reservationId => dispatch(fetchOrderDetails(reservationId)),
        fetchPackingList: reservationId => dispatch(fetchPackingList(reservationId)),
        fetchPackagingImages: orderId => {
            dispatch(fetchPackagingImages(orderId))
        },
        clearOrderDetails: reservationId => {
            dispatch(clearOrderDetails(reservationId))
        },
        rejectOrder: (reservationId, orderId) => {
            dispatch(rejectOrder(reservationId, orderId))
        },
        getFileByUrl: url => dispatch(getFileByUrl(url)),
        redirect: url => {
            dispatch(dispatchPushURL(url))
        }
    }
}

const ProgressDeliveryDate = props => {
    let total = moment(props.finishDate).valueOf() - moment(props.initDate).valueOf()
    let progressValueOf = moment().valueOf() - moment(props.initDate).valueOf()
    let percentage = (progressValueOf * 100) / total
    if (total < 0) percentage = 100

    return (
        <div className="row">
            <div className="col-12">
                <div className="row">
                    <div className="col-12">{props.intl.formatMessage({ id: props.statusText })}</div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="progress">
                            <div
                                className="progress-bar bg-success"
                                role="progressbar"
                                style={{ width: percentage + '%' }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

class OrderDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showDetails: false,
            showHelp: false,
            isLoading: false,
            showPackingList: false,
            showPackagingImages: false,
            itemsView: 'per-delivery',
            showInvoicesModal: false,
            width: window.innerWidth
        }

        this.getOrderInvoiceDownloadUrl = this.getOrderInvoiceDownloadUrl.bind(this)
        this.getOrderImagesDownloadUrl = this.getOrderImagesDownloadUrl.bind(this)
    }
    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange)
    }

    componentDidMount() {
        this.props.fetchOrderDetails(this.props.reservationId).then(
            () => {
                console.log('PROPS', this.props)
                if (this.props.order && this.props.order.isCutToSize && this.props.order.orderId) {
                    this.props.fetchPackagingImages(this.props.order.orderId)
                }
            }
        )
        this.props.fetchPackingList(this.props.reservationId)
    }

    componentWillUnmount() {
        this.props.clearOrderDetails(this.props.reservationId)
        window.removeEventListener('resize', this.handleWindowSizeChange)
    }

    componentDidUpdate(prevProps) {
        const order = this.props.order

        if (order) {
            if (order.deliveries.length === 0 && this.state.itemsView !== 'all') {
                this.setState({ itemsView: 'all' })
            }
        }
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth })
    }

    getOrderInvoiceDownloadUrl(invoiceNum) {
        // return process.env.REACT_APP_API_HOST + '/users/order-invoice/' + invoiceNum + '/?authToken=' + this.props.token
        return process.env.REACT_APP_API_HOST + '/users/order-invoice/' + invoiceNum
    }

    getOrderImagesDownloadUrl() {
        // return process.env.REACT_APP_API_HOST + '/users/order-images?reservationId=' + this.props.reservationId + '&lang=' + this.props.lang + '&authToken=' + this.props.token
        return process.env.REACT_APP_API_HOST + '/users/order-images?reservationId=' + this.props.reservationId + '&lang=' + this.props.lang
    }

    setItemsView = type => {
        this.setState({ itemsView: type })
    }

    writeContent = printWindow => {
        printWindow.document.write('<html><head>')
        printWindow.document.write(document.getElementsByTagName('head')[0].innerHTML)

        printWindow.document.write('</head><body class="p-4 my-4">')
        printWindow.document.write(this.orders_group.innerHTML)
        printWindow.document.write('</body></html>')

        return Promise.resolve(printWindow)
    }

    downloadPdf = () => {
        let printWindow = window.open('', 'PRINT', 'height=400,width=600')
        setTimeout(() => {
            this.writeContent(printWindow).then(printWindow => {
                setTimeout(() => {
                    printWindow.focus()
                    printWindow.print()
                    printWindow.close()
                }, 2000)
            })
        }, 100)

        return false
    }

    confirmRejectOrder = (reservationId, orderId, callback, intl) => {
        return confirm(intl.formatMessage({ id: 'CONFIRM_REJECT_ORDER' }), {intl: intl}).then(
            confirm => {
                return callback(reservationId, orderId)
            },
            cancel => {
                return Promise.reject()
            }
        )
    }

    rejectOrder = (reservationId, orderId) => {
        this.confirmRejectOrder(reservationId, orderId, this.props.rejectOrder, this.props.intl)
    }

    showInvoicesModal = () => {
        this.setState({ showInvoicesModal: true })
    }

    closeInvoicesModal = () => {
        this.setState({ showInvoicesModal: false })
    }

    render() {
        const { order, error } = this.props
        if (!order && error) {
            return <Error alert={this.props.intl.formatMessage({ id: error.data.err })} />
        }
        const { width } = this.state
        const isMobile = width <= 768
        let orderShippingAddress =
            this.props.shippingAddresses && this.props.order
                ? this.props.shippingAddresses.filter(shippingAddress => {
                      return shippingAddress.shippingAddressId === this.props.order.shippingAddressId
                  })[0]
                : null

        let shippingDates = []
        this.props.openDocDetails &&
            this.props.openDocDetails.forEach(line => {
                if (!shippingDates.includes(line.shipDate)) {
                    shippingDates.push(line.shipDate)
                }
            })

        return (
            <div className={`container mt-4 order_details ${this.props.isLoading && 'container--loading'}`}>
                <div className="row pt-4">
                    <div className="col-12 col-sm-2">
                        <GoBack />
                    </div>
                    <div className="col-12 col-sm-10 bt-group-detail">
                        {order && order.ecosentino_is_deletable && (
                            <Button inline danger size="small" onClick={() => this.rejectOrder(order.reservationId, order.orderId)}>
                                <img alt="" className="icon" src={iconCancel} />
                                <Translation id="cancel_order" defaultMessage="Cancel order" />
                            </Button>
                        )}
                        {order && order.ecosentino_is_editable && (
                            <Button
                                inline
                                size="small"
                                onClick={() => {
                                    this.props.redirect(
                                        this.props.intl
                                            .formatMessage({ id: 'ROUTE_PROFILE' })
                                            .replace(':filter', 'edit-order')
                                            .replace(':open', order.orderId)
                                    )
                                }}
                            >
                                <img alt="" className="icon" src={iconEdit} />
                                <Translation id="edit_order" defaultMessage="Edit order" />
                            </Button>
                        )}



                        <Button inline
                                size="small"
                                onClick={() => this.setState({ showPackagingImages: true })}
                                disabled={!(order && this.props.packagingImages && this.props.packagingImages.length)}
                        >
                            <div style={{width:'fit-content', position:'relative'}}>
                                <Translation id="packaging_images" defaultMessage="Packaging images" />
                                { this.props.isFetchingPackagingImages && <Spinner isLoading={true} inner small></Spinner> }
                            </div>
                        </Button>

                        <Button inline size="small" className="mr-2" onClick={() => this.setState({ showPackingList: true })}>
                            <img alt="" className="icon" src={iconFile} />
                            <Translation id="packing_list" defaultMessage="Packing list" />
                        </Button>
                        {false && (
                            <Button inline size="small" onClick={() => alert('Under construction')}>
                                <Translation id="create_recurring_order" defaultMessage="Create recurring order" />
                            </Button>
                        )}

                        {this.props.invoices && this.props.invoices.length > 0 && (
                            <Button inline size="small" onClick={this.showInvoicesModal}>
                                <img alt="" className="icon" src={iconFile} />
                                <Translation id="view_invoice" defaultMessage="View invoice" />
                            </Button>
                        )}
                        {order && order.orderLines && (
                            <Button inline size="small" onClick={this.downloadPdf}>
                                <img alt="" className="icon" src={iconPrint} />
                                <Translation id="print" defaultMessage="Print" />
                            </Button>
                        )}
                    </div>
                </div>

                <div className="row">
                    <div id="orders_group" className="col-12" ref={element => (this.orders_group = element)}>
                        {this.props.order && (
                            <div style={{ marginTop: -30 }}>
                                <OrderHeader
                                    dataOrder={this.props.order}
                                    showDetails={this.state.showDetails}
                                    toggleShowDetails={() => this.setState({ showDetails: !this.state.showDetails })}
                                    showActionButtons={this.props.statusMap[this.props.order.statusText] ? this.props.statusMap[this.props.order.statusText].editable : false}
                                    backgroundTransparent={true}
                                    isOrderDetails={true}
                                />
                            </div>
                        )}

                        {this.state.showInvoicesModal && (
                            <Modal title={<Translation id="invoices" defaultMessage="Invoices" />} onClose={this.closeInvoicesModal}>
                                <ReactTable
                                    data={this.props.invoices}
                                    columns={[
                                        {
                                            Header: <Translation id="invoice_num" defaultMessage="Invoice Num." />,
                                            Cell: ({ original }) => {
                                                return (
                                                    <span
                                                        onClick={e => {
                                                            e.preventDefault()

                                                            this.props.getFileByUrl(this.getOrderInvoiceDownloadUrl(original.invoiceNum))
                                                        }}
                                                        style={{ color: 'blue', cursor: 'pointer' }}
                                                    >
                                                        {original.invoiceNum}
                                                    </span>
                                                )
                                            }
                                        },
                                        { Header: <Translation id="invoice_date" defaultMessage="Invoice Date" />, accessor: 'invoiceDate' }
                                    ]}
                                    defaultPageSize={4}
                                    pageSizeOptions={[8, 16, 32, 64, 128]}
                                    previousText={<Translation id="previous" defaultMessage="Previous" />}
                                    nextText={<Translation id="next" defaultMessage="Next" />}
                                    noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                                    pageText={<Translation id="page" defaultMessage="Page" />}
                                    ofText={<Translation id="of" defaultMessage="Of" />}
                                />
                            </Modal>
                        )}

                        {order && (
                            <div className="order-history-detail-items">
                                <div className="view-selector">
                                    {order.deliveries.length > 0 && (
                                        <span className={this.state.itemsView == 'per-delivery' ? 'active' : null} onClick={() => this.setItemsView('per-delivery')}>
                                            <Translation id="delivery_view" defaultMessage="View by deliveries" />
                                        </span>
                                    )}
                                    <span className={this.state.itemsView == 'all' ? 'active' : null} onClick={() => this.setItemsView('all')}>
                                        <Translation id="full_order_view" defaultMessage="View full order" />
                                    </span>
                                </div>
                                {this.state.itemsView == 'per-delivery' && (
                                    <div className="per-delivery-view">
                                        {order.deliveries.map((delivery, i) => {
                                            let deliveryStatus = order.timelines.find(timeline => timeline.delivery_id == delivery.delivery_id).delivery_status
                                            return (
                                                <div className="delivery ohcard" key={delivery.delivery_id} data={delivery.delivery_id}>
                                                    <div className="delivery-head row">
                                                        <div className="col-5 col-md-2">
                                                            <Translation id="delivery_number" defaultMessage="Nº delivery" />: <b>{i + 1}</b>
                                                        </div>
                                                        <div className="col-7 col-md-3">
                                                            <Translation id="delivery_id" defaultMessage="Delivery id" />: <b>#{delivery.delivery_id}</b>
                                                        </div>
                                                        <div className="col-12 col-md-4">
                                                            {deliveryStatus === 'TREATED_COMPLETELLY' ? (
                                                                <div>
                                                                    <Translation id="delivered_date" defaultMessage="Delivered date" />:{' '}
                                                                    <b>{moment(delivery.delivered_date || delivery.billing_date).format('ll')}</b>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <Translation id="estimated_delivery_date" defaultMessage="Estimated delivery date" />:{' '}
                                                                    <b> {moment(delivery.expected_delivery_date).format('ll')}</b>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-12 col-md-3 text-right">
                                                            <Translation id="delivery_status" defaultMessage="Delivery status" />:
                                                            <b>
                                                                {' '}
                                                                {this.props.intl.formatMessage({ id: 'delivery_status_' + deliveryStatus })}{' '}
                                                                <span className={`delivery-status-dot ${deliveryStatus === 'TREATED_COMPLETELLY' ? 'blue' : 'grey'}`}></span>
                                                            </b>
                                                        </div>
                                                    </div>
                                                    <div className="delivery-lines">
                                                        {order.orderLinesByDelivery[delivery.delivery_id] &&
                                                            order.orderLinesByDelivery[delivery.delivery_id].map((orderLine, i) => {
                                                                if (orderLine.product) {
                                                                    if (isMobile) {
                                                                        return (
                                                                            <div className="delivery-line row" key={i}>
                                                                                <div className="col-6">
                                                                                    <ProductImage product={orderLine.product} />
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <div className="row">
                                                                                        {orderLine.outlet_quality && (
                                                                                            <p>
                                                                                                <DisplayOutletIndicator outletQuality={orderLine.outlet_quality} />
                                                                                            </p>
                                                                                        )}
                                                                                        <p>
                                                                                            <Translation id="color" defaultMessage="Color" />:{' '}
                                                                                            <b>
                                                                                                <ProductDisplayName product={orderLine.product} />
                                                                                            </b>
                                                                                        </p>
                                                                                        <p>
                                                                                            <Translation id="brand" defaultMessage="Brand" />: <b>{orderLine.product.brand}</b>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <p>
                                                                                            <Translation id="thickness" defaultMessage="Thickness" />:{' '}
                                                                                            <b>
                                                                                                <DisplayThickness thickness={orderLine.product.thickness} string />
                                                                                            </b>
                                                                                        </p>
                                                                                        <p>
                                                                                            <Translation id="finish" defaultMessage="Finish" />:{' '}
                                                                                            <b>
                                                                                                {orderLine.product.finish &&
                                                                                                    this.props.intl.formatMessage({ id: orderLine.product.finish })}
                                                                                            </b>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <p>
                                                                                            <Translation id="material_id" defaultMessage="Material Id" />:{' '}
                                                                                            <b>{orderLine.product.productId}</b>
                                                                                        </p>
                                                                                        {orderLine.lot_id && (
                                                                                            <p>
                                                                                                <Translation id="lot_id" defaultMessage="Lot ID" />: <b>{orderLine.lot_id}</b>
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <p>
                                                                                            <Translation id="format" defaultMessage="Format" />: <b>{orderLine.product.format}</b>
                                                                                        </p>
                                                                                        {orderLine.delivery_qty && (
                                                                                            <p>
                                                                                                <Translation id="delivery_units" defaultMessage="Units included in shipment" />:
                                                                                                <b>
                                                                                                    {parseInt(orderLine.delivery_qty, 10)}/{parseInt(orderLine.quantity, 10)}
                                                                                                </b>
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <div className="delivery-line row" key={i}>
                                                                                <div className="col-12 col-md-2">
                                                                                    <ProductImage product={orderLine.product} />
                                                                                </div>
                                                                                <div className="col-12 col-md-3">
                                                                                    <p>
                                                                                        <Translation id="color" defaultMessage="Color" />:{' '}
                                                                                        <b>
                                                                                            <ProductDisplayName product={orderLine.product} />
                                                                                        </b>
                                                                                    </p>
                                                                                    <p>
                                                                                        <Translation id="brand" defaultMessage="Brand" />: <b>{orderLine.product.brand}</b>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-12 col-md-2">
                                                                                    {orderLine.product.thickness && (
                                                                                        <p>
                                                                                            <Translation id="thickness" defaultMessage="Thickness" />:{' '}
                                                                                            <b>
                                                                                                <DisplayThickness thickness={orderLine.product.thickness} string />
                                                                                            </b>
                                                                                        </p>
                                                                                    )}
                                                                                    {orderLine.product.finish && (
                                                                                        <p>
                                                                                            <Translation id="finish" defaultMessage="Finish" />:{' '}
                                                                                            <b>{this.props.intl.formatMessage({ id: orderLine.product.finish })}</b>
                                                                                        </p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="col-12 col-md-3">
                                                                                    <p>
                                                                                        <Translation id="material_id" defaultMessage="Material Id" />:{' '}
                                                                                        <b>{orderLine.product.productId}</b>
                                                                                    </p>
                                                                                    {orderLine.lot_id && (
                                                                                        <p>
                                                                                            <Translation id="lot_id" defaultMessage="Lot ID" />: <b>{orderLine.lot_id}</b>
                                                                                        </p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="col-12 col-md-2">
                                                                                    {orderLine.product.format && (
                                                                                        <p>
                                                                                            <Translation id="format" defaultMessage="Format" />: <b>{orderLine.product.format}</b>
                                                                                        </p>
                                                                                    )}

                                                                                    <p>
                                                                                        <Translation id="delivery_units" defaultMessage="Units included in shipment" />:{' '}
                                                                                        <b>
                                                                                            {orderLine.delivery_qty && (
                                                                                                <span>
                                                                                                    {parseInt(orderLine.delivery_qty, 10)}/{parseInt(orderLine.quantity, 10)}
                                                                                                </span>
                                                                                            )}
                                                                                        </b>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                } else {
                                                                    return (
                                                                        <div className="delivery-line row" key={i}>
                                                                            <div className="col-12 col-md-2">
                                                                                <ProductImage product={null}></ProductImage>
                                                                            </div>
                                                                            <div className="col-12 col-md-5"></div>
                                                                            <div className="col-12 col-md-2">
                                                                                <p>
                                                                                    <Translation id="material_id" defaultMessage="Material Id" />: <b>{orderLine.material_id}</b>
                                                                                </p>
                                                                                {orderLine.lot_id && (
                                                                                    <p>
                                                                                        <Translation id="lot_id" defaultMessage="Lot ID" />: <b>{orderLine.lot_id}</b>
                                                                                    </p>
                                                                                )}
                                                                            </div>
                                                                            <div className="col-12 col-md-2">
                                                                                <p>
                                                                                    <Translation id="delivery_units" defaultMessage="Units included in shipment" />:{' '}
                                                                                    <b>
                                                                                        {orderLine.delivery_qty && (
                                                                                            <span>
                                                                                                {parseInt(orderLine.delivery_qty, 10)}/{parseInt(orderLine.quantity, 10)}
                                                                                            </span>
                                                                                        )}
                                                                                    </b>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                                {this.state.itemsView == 'all' &&
                                    (!isMobile ? (
                                        <OrderDetailsTable orderLines={order.orderLines} editMode={false} isEmserUser={this.props.isEmserUser} preferredDeliveryDate={order.preferredDeliveryDate} lang={this.props.lang}/>
                                    ) : (
                                        <OrderFullMobile orderLines={order.orderLines} lang={this.props.lang} preferredDeliveryDate={order.preferredDeliveryDate}/>
                                    ))}
                            </div>
                        )}
                    </div>
                </div>
                {this.state.showPackingList && this.props.orderDetailsHead && (
                    <PackingList
                        reservationId={this.props.reservationId}
                        orderId={this.props.orderDetailsHead.orderId}
                        packingList={this.props.packingList}
                        onClose={() => this.setState({ showPackingList: false })}
                        canEditOrders={false}
                    />
                )}


                {order && this.state.showPackagingImages && (
                    // <PackagingImagesModal
                    <PackagingImageViewer
                        reservationId={this.props.reservationId}
                        images={this.props.packagingImages}
                        onClose={() => this.setState({ showPackagingImages: false })}
                    />
                )}


                <Spinner isLoading={this.props.isLoading} />
            </div>
        )
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(OrderDetails)
)
