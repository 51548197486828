import React from 'react'
import Translation from '../global/Translation'

import { connect } from 'react-redux'
import { getSelectedSubchannel, getCurrentSegmentation, getSelectedChannel, getSelectedSalesForceId, getSalesforceRelationsV3 } from '../../store/segmentation/reducers'
import { toggleSegmentation, selectSalesForceId } from '../../store/segmentation/actions'
import Select from 'react-select'

const mapStateToProps = state => {
    const channel = getSelectedChannel(state)
    const subchannel = getSelectedSubchannel(state)
    return {
        show: getCurrentSegmentation(state) === 'SUBCHANNEL',
        selected: getSelectedSalesForceId(state),
        options: getSalesforceRelationsV3(state, channel, subchannel)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onToggle: () => {
            dispatch(toggleSegmentation('SUBCHANNEL'))
        },
        onSelect: (value, name, subchannelProgram, businessSegment, subchannelId) => {
            dispatch(selectSalesForceId(value, name, subchannelProgram, businessSegment, subchannelId))
        }
    }
}
class SalesForceIdV3 extends React.Component {
    render() {
        const { selected, onSelect, options, intl } = this.props
        if (options === undefined) {
            return ''
        }
        return (
            <div>
                <Translation id="account" defaultMessage="Account" /> <small>{"*"}</small>
                <Select
                    placeholder={intl.formatMessage({ id: 'choose_option' })}
                    options={Object.entries(options).map(option => {
                        const projectOptions = Object.values(option[1].projectOptions)[0]
                        return {
                        label: option[1].name === '' ? 'RESIDENTIAL' : option[1].name,
                        value: option[0],
                        businessSegment: option[1].businessSegment,
                        subchannelProgram: projectOptions.subchannelProgram,
                        subchannelId: projectOptions.subchannelId
                    }})}
                    value={selected}
                    onChange={option => {
                        option && onSelect(option.value, option.label, option.subchannelProgram, option.businessSegment, option.subchannelId)
                    }}
                    clearable={false}
                />
                {/* <hr /> */}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SalesForceIdV3)
