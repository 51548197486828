import * as api from '../api'
import { getToken } from '../login/reducers'
import { getLanguage } from '../ui/reducers'
import { fetchLotsImages } from '../lots/actions'

export const fetchDirectSlabAccess = (reservationId, invoiceId) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_DIRECT_SLAB_ACCESS_REQUEST'
    })
    return api.fetchDirectSlabAccess(reservationId, invoiceId, getToken(getState()), getLanguage(getState())).then(
        response => {
            dispatch({ type: 'FETCH_DIRECT_SLAB_ACCESS_SUCCESS', response: response.data, reservationId, invoiceId })
            let lotIds = []
            response.data.forEach(row => {
                if (row.lotId) lotIds.push(row.lotId)
            })
            dispatch(fetchLotsImages(lotIds))
        },
        error => {
            dispatch({ type: 'FETCH_DIRECT_SLAB_ACCESS_FAILURE' })
            api.errorHandler(dispatch, error.response)
        }
    )
}
