import { connect } from 'react-redux'
import CheckoutFormShop from './CheckoutFormShop'
import { getShippingMethod, isEuropeOrIberia } from '../../../store/centers/reducers'
import { checkout } from '../../../store/cart/actions'
import { getAlert, isSending, weightSelector, getGoalMax, getKgPoundRatio, getReservationCode, qtySelector, getStatus } from '../../../store/cart/reducers'
import { getLanguage } from '../../../store/ui/reducers'
import { getProfileConfiguration } from '../../../store/profile-configuration/reducers'
import { isLoadingShippingAddress } from '../../../store/shipping-addresses/reducers'
import { getDeliveryDate, getDestination, canSendCheckoutSaleConditions } from '../../../store/checkout/reducers'
import {
    setDeliveryDate,
    setDestination,
    setComments,
    canSendNeedDestination,
    resetCheckout,
    canSendMinMaxCheckout,
    canSendNaturalStoneContainer
} from '../../../store/checkout/actions'

const mapStateToProps = state => {
    let language = getLanguage(state)
    let defaultUnit = language === 'en' || language === 'en-US' || language === 'fr-CA' ? 'LB' : 'KG'
    let unit = getProfileConfiguration(state).weightUnit ? getProfileConfiguration(state).weightUnit : defaultUnit

    return {
        current: unit === 'KG' ? weightSelector(state) : Math.floor(weightSelector(state) * getKgPoundRatio()),
        goalMax: getGoalMax(state, unit),
        hasDestination: getShippingMethod(state) === 'CONTAINER',
        shippingMethod: getShippingMethod(state),
        alert: getAlert(state),
        status: getStatus(state),
        isSending: isSending(state),
        reservationCode: getReservationCode(state),
        totalQty: qtySelector(state),
        isEuropeOrIberia: isEuropeOrIberia(state),
        isLoadingShippingAddress: isLoadingShippingAddress(state),
        //checkout
        deliveryDate: getDeliveryDate(state),
        destination: getDestination(state),
        canSendCheckoutSaleConditions: canSendCheckoutSaleConditions(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        submitCheckout: () => dispatch(checkout()),
        onLoadCheckout: () => dispatch({ type: 'CHECKOUT_VISIT' }),
        setDeliveryDate: deliveryDate => dispatch(setDeliveryDate(deliveryDate)),
        setDestination: destination => dispatch(setDestination(destination)),
        setComments: comments => dispatch(setComments(comments)),
        canSendMinMaxCheckout: () => dispatch(canSendMinMaxCheckout()),
        canSendNeedDestination: () => dispatch(canSendNeedDestination()),
        canSendNaturalStoneContainer: () => dispatch(canSendNaturalStoneContainer()),
        resetCheckout: () => dispatch(resetCheckout())
    }
}
const CheckoutFormShopContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutFormShop)

export default CheckoutFormShopContainer
