import { connect } from 'react-redux'
import { removeCartLine, changeCartLineQty, changeCartLineSegmentation, changeCartLineComment, saveCartLineComment } from '../../store/cart/actions'
import { openBundleContent, showSegmentationModal } from '../../store/ui/actions'
import { getBusinesSegmentOptions, getUseOptions, getChannelOptionsV3, getTypologyOptionsV3, getHasSegmentationV3 } from '../../store/segmentation/reducers'
import { getShippingMethod, getIsMkToolsCenter, getIsIkeaCenter, getIsMktoolsUsaCenter, getPricesGroup } from '../../store/centers/reducers'
import { loadThisSegmentation } from '../../store/segmentation/actions'
import { getQtyProductsCart } from '../../store/cart/reducers'
import { getImageBackground } from '../../store/products/actions'
import { getPriceList, getUserPriceList, getPriceListAtSelectedCenter } from '../../store/login/reducers'
import { getProfileConfiguration } from '../../store/profile-configuration/reducers'
import OrderLine from './OrderLine'

const mapStateToProps = state => {
    return {
        businesSegmentOptions: getBusinesSegmentOptions(state),
        useOptions: getUseOptions(state),
        userPreferences: getProfileConfiguration(state),
        shippingMethod: getShippingMethod(state),
        segmentationV3: getHasSegmentationV3(state),
        channelOptionsV3: getChannelOptionsV3(state),
        typologyOptionsV3: getTypologyOptionsV3(state),
        qtyProductCart: getQtyProductsCart(state),
        priceGroup: getPricesGroup(state),
        isMktoolsCenter: getIsMkToolsCenter(state),
        isMktoolsUsaCenter: getIsMktoolsUsaCenter(state),
        isIkeaCenter: getIsIkeaCenter(state),
        userPriceList: getUserPriceList(state),
        priceList: getPriceListAtSelectedCenter(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onDelete: lineId => {
            dispatch(removeCartLine(lineId))
        },
        changeCartLineQty: (lineId, qty) => {
            dispatch(changeCartLineQty(lineId, qty))
        },
        openBundleContent: product => {
            dispatch(openBundleContent(product))
        },
        loadThisSegmentation: seg => {
            dispatch(loadThisSegmentation(seg))
        },
        showSegmentationModal: (mode, callback) => {
            dispatch(showSegmentationModal(mode, callback))
        },
        changeCartLineSegmentation: lineId => {
            dispatch(changeCartLineSegmentation(lineId))
        },
        onOpenBundleContent: product => {
            dispatch(openBundleContent(product))
        },
        getImageBackground: product => dispatch(getImageBackground(product)),

        saveCartLineComment: (lineId, comment) => dispatch(saveCartLineComment(lineId, comment)),

        changeCartLineComment: (lineId, comment) => dispatch(changeCartLineComment(lineId, comment))
    }
}

const OrderLineContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderLine)

export default OrderLineContainer
