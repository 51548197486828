import React from 'react'
import HeaderV2 from './HeaderV2'
import Header from './Header'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

const mapStateToProps = state => {
    return {
        
    }
}


class HeaderSelectorRedesign extends React.Component {
    render() {
        return this.props.newVersion ? <HeaderV2 {...this.props} /> : <Header {...this.props} />
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(HeaderSelectorRedesign)
)
