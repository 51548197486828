import React from 'react'
import Translation from '../global/Translation'

import silestone from '../../assets/img/logos/brands/logo-silestone.svg'
import dekton from '../../assets/img/logos/brands/logo-dekton.svg'
import dktn from '../../assets/img/DKTN.svg'

import sensa from '../../assets/img/logos/brands/logo-sensa.svg'
import naturalStone from '../../assets/img/logos/brands/logo-natural-stone.svg'
import Suggestions from './Suggestions'
import DeveloperBar from '../global/DeveloperBar'
import LocalizedLink from '../global/LocalizedLink'
import {connect} from "react-redux";
import {getSalesOrg} from "../../store/centers/reducers";
import usaCaPrTerms_es from "../../assets/pdf/conditions/2023-General-T&C_Cosentino-USA-Canada-PR_Spanish.pdf"
import usaCaPrTerms_en from "../../assets/pdf/conditions/2023-General-T&C_Cosentino-USA-Canada-PR.pdf"
import usaCaPrTerms_fr from "../../assets/pdf/conditions/2023-General-T&C_Cosentino-USA-Canada-PR_French.pdf"
import {getShowSaleConditions} from "../../store/ui/reducers";
import { getLanguage } from '../../store/ui/reducers'

const mapStateToProps = state => {
    return {
        getSalesOrg: getSalesOrg(state),
        showSaleConditions: getShowSaleConditions(state),
        language: getLanguage(state)
    }
}

class Footer extends React.Component {

    preferedLanguageToShowTerms = () => {
        let language = this.props.language
        switch (language) {
            case 'es':
                return usaCaPrTerms_es
            case 'en-CA':
                return usaCaPrTerms_en
            case 'fr-CA':
                return usaCaPrTerms_fr
            default:
                return usaCaPrTerms_en
        }
    }
    render() {
        const usaSalesOrgs = ['2011', '2032', '2037'];

        return (
            <div>
                {process.env.NODE_ENV !== 'production' && <DeveloperBar />}

                <Suggestions />
                <footer className="footer">
                    <div className="container">
                        {/*     <div className="row">
                            <div className="col-md-12">
                                                          <div className="row align-items-center apps">
                                    <div className="col-12 col-md-2 footer-col">
                                        <span className="footer-title">
                                            <Translation id="apps" defaultMessage="Apps" />
                                        </span>
                                    </div>

                                    <div className="col-12 col-md-2 footer-col">
                                        <ul>
                                            <li>
                                                <a href="/">
                                                    <Translation id="online_visualizer" defaultMessage="Online visualizer" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/">
                                                    <Translation id="3d_kitchen" defaultMessage="3D Kitchen" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-2 footer-col">
                                        <ul>
                                            <li>
                                                <a href="/">
                                                    <Translation id="silestone" defaultMessage="Silestone" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/">
                                                    <Translation id="dekton" defaultMessage="Dekton" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-2 footer-col">
                                        <ul>
                                            <li>
                                                <a href="/">
                                                    <Translation id="home_design" defaultMessage="Home design" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/">
                                                    <Translation id="silestone_25" defaultMessage="Silestone 25" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                       <div className="col-6 col-md-2 footer-col">
                                        <a href="/">
                                            <img alt="" className="dl-play-store" src={iconPlayStore} />
                                        </a>
                                    </div>
                                    <div className="col-6 col-md-2 footer-col">
                                        <a href="/">
                                            <img alt="" className="dl-app-store" src={iconAppStore} />
                                        </a>
                                    </div> 
                                </div> 
                            </div>
                        </div>*/}
                        {/*                    <div className="row">
                            <div className="col-md-12">
                                <hr />
                            </div>
                        </div> */}
                        <div className="legal row">
                            <div className="col-12">
                                <ul>
                                    <li>
                                        <LocalizedLink routeId="ROUTE_COOKIES">
                                            <Translation id="cookies_policy" defaultMessage="Cookies policy" />
                                        </LocalizedLink>
                                    </li>
                                    <li>
                                        <LocalizedLink routeId="ROUTE_PRIVACY">
                                            <Translation id="privacy_policy" defaultMessage="Privacy policy" />
                                        </LocalizedLink>
                                    </li>
                                    {this.props.showSaleConditions && !usaSalesOrgs.includes(this.props.getSalesOrg) && (
                                        <li>
                                            <a
                                                href="https://assetstools.cosentino.com/api/v1/bynder/doc/511A2D40-054A-41D5-89CE81CF6DB105D8/cgv.pdf"
                                                target="_blank"
                                                rel="nofollow noopener noreferrer"
                                            >
                                                <Translation id="general_conditions_of_sale" defaultMessage="General conditions of sale" />
                                            </a>
                                        </li>
                                    )}                                    
                                    {this.props.showSaleConditions && usaSalesOrgs.includes(this.props.getSalesOrg) && (
                                      <li>
                                          <a
                                            href={this.preferedLanguageToShowTerms()}
                                            target="_blank"
                                            rel="nofollow noopener noreferrer"
                                          >
                                              <Translation id="general_conditions_of_sale" defaultMessage="General conditions of sale" />
                                          </a>
                                      </li>
                                    )}
                                </ul>
                            </div>
                            <br />
                            <br />
                            <div className="col-md-6">
                                © Cosentino S.A. <Translation id="all_rights_reserved" defaultMessage="All rights reserved" />
                            </div>
                            <div className="social-links col-md-6">
                                <ul>
                                    <li>
                                        <a href="http://facebook.com/grupocosentino" target="_blank" rel="noopener noreferrer">
                                            <i className="fab fa-facebook-square" />
                                        </a>
                                        <a href="http://instagram.com/grupocosentino" target="_blank" rel="noopener noreferrer">
                                            <i className="fab fa-instagram" />
                                        </a>
                                        <a href="http://twitter.com/grupocosentino" target="_blank" rel="noopener noreferrer">
                                            <i className="fab fa-twitter" />
                                        </a>
                                        <a href="http://youtube.com/cosentinotv" target="_blank" rel="noopener noreferrer">
                                            <i className="fab fa-youtube" />
                                        </a>
                                        <a href="https://goo.gl/jPTSgu" target="_blank" rel="noopener noreferrer">
                                            <i className="fab fa-linkedin" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/*                         <div className="legal row">
                            <div className="col-md-12">
                                <br />
                                <ul>
                                    <li>
                                        <a href="/">
                                            <Translation id="legal_notice" defaultMessage="Legal notice" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <Translation id="cookies_policy" defaultMessage="Cookies policy" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <Translation id="conduct_code" defaultMessage="Conduct code" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <Translation id="faqs" defaultMessage="FAQs" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <Translation id="contact" defaultMessage="Contact us" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-md-12">
                                <hr />
                            </div>
                        </div>
                        <div className="row brands ">
                            <div className="col-12 col-md-auto text-center p-2">
                                <a href="https://silestone.com/" target="_blank" rel="noopener noreferrer">
                                    <img src={silestone} alt="Silestone" className="img-fluid silestone" />
                                </a>
                            </div>
                            <div className="col-12 col-md-auto text-center p-2">
                                <a href="https://www.dekton.com/" target="_blank" rel="noopener noreferrer">
                                    {this.props.selectedCenter === 7130 ? (
                                        <img src={dktn} alt="Dekton" className="img-fluid dekton" />
                                    ) : (
                                        <img src={dekton} alt="Dekton" className="img-fluid dekton" />
                                    )}
                                </a>
                            </div>
                            <div className="col-12 col-md-auto text-center p-2">
                                <a href="https://sensabycosentino.com/" target="_blank" rel="noopener noreferrer">
                                    <img src={sensa} alt="Sensa" className="img-fluid sensa" />
                                </a>
                            </div>
                            <div className="col-12 col-md-auto text-center p-2">
                                <a href="https://www.cosentino.com/natural-stone/" target="_blank" rel="noopener noreferrer">
                                    <img src={naturalStone} alt="Natural Stone" className="img-fluid natural-stone" />
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default connect(mapStateToProps, null)(Footer);
