import { connect } from 'react-redux'
import { addCartLineWithouthSeg } from '../../store/cart/actions'
import SampleResult from './SampleResult'
import { getShippingMethod, canOnlyViewOutlet, productIsInPromo, canBuyCatalog } from '../../store/centers/reducers'
import { getIsAdmin, getUserType } from '../../store/login/reducers'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { getProfileConfiguration } from '../../store/profile-configuration/reducers'
import { getLines } from '../../store/cart/reducers'
import { getImageBackground } from '../../store/products/actions'

const mapStateToProps = (state, ownProps) => {
    const source = ownProps.result !== undefined ? ownProps.result._source : ownProps.product

    return {
        shippingMethod: getShippingMethod(state),
        isAdmin: getIsAdmin(state),
        canBuyCatalog: canBuyCatalog(state),
        canOnlyViewOutlet: canOnlyViewOutlet(state),
        userPreferences: getProfileConfiguration(state),
        userType: getUserType(state),
        productIsInPromo: productIsInPromo(state, source),
        cart: getLines(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        addCartLine: (productId, qty, extra) => {
            dispatch(addCartLineWithouthSeg(productId, qty, extra))
        },
        getImageBackground: product => dispatch(getImageBackground(product))
    }
}

const SampleResultContainer = withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(SampleResult)
    )
)

export default SampleResultContainer
