import React from 'react'
import HomeV2 from './HomeV2'
import Home from './Home'

class HomeSelectorRedesign extends React.Component {
    render() {
        return this.props.newVersion ? <HomeV2 {...this.props} /> : <Home {...this.props} />
    }
}

export default HomeSelectorRedesign
