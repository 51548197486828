import React from 'react'

export default class DisplayProductInfoTable extends React.Component {
    render() {
        return (
            <div className="product-info-table">
                <table>
                    <thead>
                        <tr>
                            {this.props.th.map(th => (
                                <th key={th.datacy + th.value} data-cy={th.datacy}>
                                    {th.value}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {this.props.td.map(td => (
                                <td key={td.datacy + td.value} data-cy={td.datacy}>
                                    {td.value}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}
