import * as api from '../api'
import * as segmentation from '../segmentation/actions'
import { fetchCart, emptyCart } from '../cart/actions'
import { push } from 'react-router-redux'
import { getIsLogged, getToken, getIsAdmin } from '../login/reducers'
import {
    canViewCatalog,
    canBuy,
    getSelectedCenter,
    getMktoolsShippingCenter,
    getCanViewImageByLot,
    getShowroomCenterId,
    getLowesCenterId,
    getCenters
} from './reducers'
import { fetchProductsFavourite } from '../products/actions'
import { fetchRecurringOrders } from '../orders/actions'
import { fetchShippingAddresses } from '../shipping-addresses/actions'
import { fetchUserLandings } from '../landing/actions'
import { fetchPromotionData, getPromotionByCenterId } from '../promotions/actions'
import { fetchAdvertisements} from '../advertisement/actions'
import { dispatchPushURL } from '../ui/actions'
import {setLastSelectedProduct} from "../selected-objects/actions";

export const changeCenter = (centerId, urlCallback) => (dispatch, getState) => {
    dispatch({
        type: 'CHANGE_CENTER',
        center: centerId
    })
    dispatch(setLastSelectedProduct(null))
    dispatch(fetchCenterPreferences())
    dispatch({ type: 'EMPTY_CART' })
    dispatch({ type: 'EMPTY_PRODUCTS_FAVOURITE' })
    dispatch(fetchProductsFavourite())
    // dispatch(fetchRecurringOrders())
    dispatch(fetchUserLandings())

    dispatch(getPromotionByCenterId()).then(promo => {
        if (promo.promotionId) dispatch(fetchPromotionData(promo.promotionId))
    })
    if (canBuy(getState()) && getIsLogged(getState())) {
        dispatch(segmentation.fetchSegmentation())
        dispatch(fetchCart())
        dispatch(fetchShippingAddresses())
    }
    if (urlCallback) {
        if (centerId !== getLowesCenterId()) {
            if (canViewCatalog(getState())) {
                dispatch(dispatchPushURL(urlCallback + ''))
            } else {
                dispatch(dispatchPushURL(urlCallback + 'outlet'))
            }
        } else {
            dispatch(dispatchPushURL(urlCallback + ''))
        }
    }
}
export const changeFirstCenterFromSalesOrg = (callbackSalesOrg) => (dispatch, getState) => {
    const firstCenterWithSelectedSalesOrg = getCenters(getState()).find(center => parseInt(center.salesOrg, 10) === parseInt(callbackSalesOrg, 10))

    if(firstCenterWithSelectedSalesOrg && firstCenterWithSelectedSalesOrg.centerId !== getSelectedCenter(getState())) {
        dispatch(changeCenter(parseInt(firstCenterWithSelectedSalesOrg.centerId, 10)));
    }
}
export const changeShippingMethod = shippingMethod => (dispatch, getState) => {
    dispatch({ type: 'CHANGE_SHIPPING_METHOD', shippingMethod: shippingMethod })
}
export const fetchCenterPreferences = () => (dispatch, getState) => {
    
    if (!getIsAdmin(getState())) dispatch(fetchAdvertisements())
    
    let token = getToken(getState())
    const centerId = getSelectedCenter(getState()) || 'near'
    return api
        .fetchCenterPreferences(token, centerId)
        .then(response => {
            dispatch({ type: 'FETCH_CENTER_PREFERENCES_SUCCESS', centerPreferences: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_CENTER_PREFERENCES_FAILURE' })
            api.errorHandler(dispatch, error.response)
        })
}
export const setMkToolsShippingCenter = mktoolsShippingCenter => (dispatch, getState) => {
    if (mktoolsShippingCenter !== getMktoolsShippingCenter(getState()) && getSelectedCenter(getState()) === getShowroomCenterId()) dispatch(emptyCart())
    dispatch({ type: 'SET_MKTOOLS_SHIPPING_CENTER', mktoolsShippingCenter })
}

export const canViewImageByLot = location => (dispathc, getState) => {
    if (location === 'slabsmith') return getCanViewImageByLot(getState())
    return true
}

export const onlyChangeCenter = centerId => (dispatch) => {
    dispatch({
        type: 'ONLY_CHANGE_CENTER',
        center: centerId
    })
}
