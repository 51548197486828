import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'
import Alerts from '../alerts/Alerts'
import groupBy from 'json-groupby'
import DisplayPrice from "../catalog/rectangleResult/displays/DisplayPrice";
import {getProfileConfiguration} from "../../store/profile-configuration/reducers";
import {connect} from "react-redux";
import {
    getIsMkToolsCenter
} from "../../store/centers/reducers";
import { getPriceListAtSelectedCenter, getUserPriceList, getVersion } from '../../store/login/reducers'
import ProductDisplayName from "../global/ProductDisplayName";
import CloseableAlerts from "../alerts/CloseableAlerts";


const mapStateToProps = state => {
    return {
        userPreferences: getProfileConfiguration(state),
        priceList: getPriceListAtSelectedCenter(state),
        userPriceList: getUserPriceList(state),
        isMktoolsCenter: getIsMkToolsCenter(state),
        isNewVersion: getVersion(state),
    }
}


class LotsHeader extends React.Component {

    state={
        showAdvisorAlert: true
    }


    getQtyOfQuality(groupByQuality) {
        const keys = Object.keys(groupByQuality)
        const values = Object.values(groupByQuality)
        let result = []
        keys.forEach((key, index) => {
            result.push({ key: key, value: values[index].length })
        })
        return result
    }

    render() {
        const { original, filtered, goBack, intl, onlyLots } = this.props
        const groupByQuality = onlyLots !== undefined ? groupBy(onlyLots, ['quality']) : null

        const lotsBooked = filtered ? (
            <div className="row mt-2">
                <div className="col-12">
                    <Alerts alert={'LOTS_ALREADY_BOOKED'} status="warning" />
                </div>
            </div>
        ) : null

        return (
            <div>
                <div className="toolbar">
                    <div className="row align-items-end no-gutters">
                        <div className="col-12 mb-1">
                            <div className="position-relative position-lg-absolute mb-lg-0"
                                 style={{position:"absolute", top:0, left:0, fontSize:"14px", color:"#232323", fontWeight:"500"}}>
                                <div className="go-back" onClick={goBack}>
                                    <span>
                                        <i className="fal fa-chevron-left" />&nbsp;
                                        <Translation id="back_to" defaultMessage="Back to" />&nbsp;
                                        <ProductDisplayName product={original} />
                                    </span>
                                </div>
                            </div>

                            {original && (
                                <div className="lots__header__container">
                                    <div className="lots__header__container--title">
                                        <span><Translation id="choose_lots_of" defaultMessage="Choose lots of" />&nbsp;</span>
                                        <ProductDisplayName product={original} />
                                        {original.thickness.toLowerCase()} cm,&nbsp;
                                        {intl.formatMessage({ id: original.finish }).toLowerCase()}.
                                    </div>

                                    <div className="lots__header__container--price">
                                        <DisplayPrice
                                            userPreferences={this.props.userPreferences}
                                            priceList={this.props.priceList}
                                            userPriceList={this.props.userPriceList}
                                            product={original}
                                            isMktoolsCenter={this.props.isMktoolsCenter}
                                            centerId={this.props.centerId}
                                        />
                                    </div>

                                    <div className="lots__header__container--text">
                                        <Translation id="lots-faster-way-message" defaultMessage="Now you can select loose boards, complete or partial bundles in a faster way"/>
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>
                    {lotsBooked}

                    {
                        this.state.showAdvisorAlert && !this.props.isNewVersion && (
                            <div className="row mt-2">
                                <div className="col-12">
                                    <CloseableAlerts alerts={[{id:'LOTS_IMAGE_MAY_NOT_MATCH'},{id:'AVG_BUNDLE_SIZE'}]} status="warning" onClose={()=>this.setState({showAdvisorAlert:false})}/>
                                </div>
                            </div>
                        )
                    }

                    {/*{(this.props.isAdmin || this.props.impersonatedBy) && (*/}
                    {/*    <div className="row mt-2">*/}
                    {/*        <div className="col-12">*/}
                    {/*            <Alerts alert={'LOTS_IMAGE_MAY_NOT_MATCH'} status="warning" />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </div>

            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(LotsHeader)
)

