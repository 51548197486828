import React from 'react'
import Translation from '../global/Translation'

import { defineMessages } from 'react-intl'


const MESSAGES = defineMessages({
    CLIENT_ALREADY_HAS_ACCOUNT: {
        id: 'CLIENT_ALREADY_HAS_ACCOUNT',
        description: 'CLIENT_ALREADY_HAS_ACCOUNT',
        defaultMessage: 'Customer already has an account'
    },
    EXCLUSIVE_HOME_DEPOT: {
        id: 'EXCLUSIVE_HOME_DEPOT',
        description: 'EXCLUSIVE_HOME_DEPOT',
        defaultMessage: 'Material only available for Home Depot projects'
    },
    THERE_IS_NO_THEORETICAL_WEIGHT: {
        id: 'THERE_IS_NO_THEORETICAL_WEIGHT',
        description: 'THERE_IS_NO_THEORETICAL_WEIGHT',
        defaultMessage: 'There is no theoretical weight'
    },
    INFO_IKEA_CHECKOUT_CONFIRM: {
        id: 'info_ikea_checkout_confirm',
        description: 'Are you sure to place the order for the shipping address "{storeName}" with the employee id "{employee}" ?',
        defaultMessage: 'Are you sure to place the order for the shipping address "{storeName}" with the employee id "{employee}" ?'
    },
    THE_FILE_IS_NOT_YET_AVAILABLE: {
        id: 'the_file_is_not_yet_available',
        description: 'Your invoice is not uploaded yet. Please try again later',
        defaultMessage: 'Your invoice is not uploaded yet. Please try again later'
    },
    LOT_NO_LONGER_AVAILABLE: {
        id: 'LOT_NO_LONGER_AVAILABLE',
        description: 'LOT_NO_LONGER_AVAILABLE',
        defaultMessage: 'Some lot is no longer available'
    },
    SHIPPING_ADDRESS_NOT_FOUND: {
        id: 'SHIPPING_ADDRESS_NOT_FOUND',
        description: 'SHIPPING_ADDRESS_NOT_FOUND',
        defaultMessage: 'Shipping address not found'
    },
    CUSTOMER_REQUIRED: {
        id: 'CUSTOMER_REQUIRED',
        description: 'CUSTOMER_REQUIRED',
        defaultMessage: 'Customer is required'
    },
    INVALID_EMAIL: {
        id: 'INVALID_EMAIL',
        description: 'INVALID_EMAIL',
        defaultMessage: 'Invalid email'
    },
    INVALID_LENGTH_UNIT: {
        id: 'INVALID_LENGTH_UNIT',
        description: 'INVALID_LENGTH_UNIT',
        defaultMessage: 'Invalid length unit'
    },
    INVALID_WEIGHT_UNIT: {
        id: 'INVALID_WEIGHT_UNIT',
        description: 'INVALID_WEIGHT_UNIT',
        defaultMessage: 'Invalid weight unit'
    },
    LOGIN_REQUIRED: {
        id: 'LOGIN_REQUIRED',
        description: 'LOGIN_REQUIRED',
        defaultMessage: 'Login required'
    },
    LOTS_ALREADY_BOOKED: {
        id: 'LOTS_ALREADY_BOOKED',
        description: 'LOTS_ALREADY_BOOKED',
        defaultMessage: 'Some batches have been excluded from the list as they are already reserved. They might be available later'
    },
    TRANSFORMING_SKU_TO_LOTS: {
        id: 'TRANSFORMING_SKU_TO_LOTS',
        description: 'TRANSFORMING_SKU_TO_LOTS',
        defaultMessage: 'We are looking for available batches'
    },

    LOTS_IMAGE_MAY_NOT_MATCH: {
        id: 'LOTS_IMAGE_MAY_NOT_MATCH',
        description: 'LOTS_IMAGE_MAY_NOT_MATCH',
        defaultMessage: 'Batch images might not exactly match the final material'
    },
    NOT_ENOUGH_LOTS: {
        id: 'NOT_ENOUGH_LOTS',
        description: 'NOT_ENOUGH_LOTS',
        defaultMessage: 'Not enough lots available for your order'
    },
    NOT_ENOUGH_LOTS_OFFER: {
        id: 'NOT_ENOUGH_LOTS_OFFER',
        description: 'NOT_ENOUGH_LOTS_OFFER',
        defaultMessage: 'Not enough lots available for your offer'
    },
    MAX_WEIGHT_EXCEEDED: {
        id: 'MAX_WEIGHT_EXCEEDED',
        description: 'MAX_WEIGHT_EXCEEDED',
        defaultMessage: 'Maximum weight has been exceeded, to order another {shippingMethod} you must create a new order'
    },
    MIN_WEIGHT_NOT_REACHED: {
        id: 'MIN_WEIGHT_NOT_REACHED',
        description: 'MIN_WEIGHT_NOT_REACHED',
        defaultMessage: 'Minimum weight not reached'
    },
    MISSING_MANDATORY_FIELD: {
        id: 'MISSING_MANDATORY_FIELD',
        description: 'MISSING_MANDATORY_FIELD',
        defaultMessage: 'Fields are required'
    },
    REMEMBER_PASSWORD_REQUEST_CONFIRMED: {
        id: 'REMEMBER_PASSWORD_REQUEST_CONFIRMED',
        description: 'REMEMBER_PASSWORD_REQUEST_CONFIRMED',
        defaultMessage: 'If the user and email address match, an email will be sent'
    },
    TSC_ORDER_FAILED: {
        id: 'TSC_ORDER_FAILED',
        description: 'TSC_ORDER_FAILED',
        defaultMessage: 'The order could not be sent'
    },
    TOKEN_HAS_EXPIRED: {
        id: 'TOKEN_HAS_EXPIRED',
        description: 'TOKEN_HAS_EXPIRED',
        defaultMessage: 'Your session has expired'
    },
    WRONG_CREDENTIALS: {
        id: 'WRONG_CREDENTIALS',
        description: 'WRONG_CREDENTIALS',
        defaultMessage: 'Invalid username or password'
    },
    WRONG_CREDENTIALS_AZURE: {
        id: 'WRONG_CREDENTIALS_AZURE',
        description: 'WRONG_CREDENTIALS_AZURE',
        defaultMessage: '{lastLoginAccount} does not have access to eCosentino. Either try with another account or contact your nearest Cosentino center'
    },
    TOO_MANY_REQUESTS: {
        id: 'TOO_MANY_REQUESTS',
        description: 'TOO_MANY_REQUESTS',
        defaultMessage: 'You have exceeded the maximum number of login attempts. Wait a minute to keep trying'
    },
    YOUR_PREFERENCES_HAVE_BEEN_SAVED_SUCCESSFULLY: {
        id: 'YOUR_PREFERENCES_HAVE_BEEN_SAVED_SUCCESSFULLY',
        description: 'YOUR_PREFERENCES_HAVE_BEEN_SAVED_SUCCESSFULLY',
        defaultMessage: 'Your preferences have been saved successfully'
    },
    MANDATORY_PASSWORD_CHANGE: {
        id: 'MANDATORY_PASSWORD_CHANGE',
        description: 'MANDATORY_PASSWORD_CHANGE',
        defaultMessage: 'Your current password is not secure and needs to be changed. Please enter a new password.'
    },
    PASSWORD_DOESNT_MEET_REQUIREMENTS: {
        id: 'PASSWORD_DOESNT_MEET_REQUIREMENTS',
        description: 'PASSWORD_DOESNT_MEET_REQUIREMENTS',
        defaultMessage: "Password doesn't meet the requirements."
    },
    PASSWORDS_NOT_MATCH: {
        id: 'PASSWORDS_NOT_MATCH',
        description: 'PASSWORDS_NOT_MATCH',
        defaultMessage: 'Passwords do not match.'
    },
    PASS_CHANGED_LOGIN_AGAIN: {
        id: 'PASS_CHANGED_LOGIN_AGAIN',
        description: 'PASS_CHANGED_LOGIN_AGAIN',
        defaultMessage: 'The password was changed. Please, login again with your new password.'
    },
    your_suggestions_has_been_sent: {
        id: 'your_suggestions_has_been_sent',
        description: 'your_suggestions_has_been_sent',
        defaultMessage: 'Your suggestions has been sent'
    },
    undefined: {
        id: 'undefined',
        description: 'undefined',
        defaultMessage: 'Something went wrong'
    },
    maximum_weight_of_bundles_has_been_exceeded: {
        id: 'maximum_weight_of_bundles_has_been_exceeded',
        description: 'maximum_weight_of_bundles_has_been_exceeded',
        defaultMessage: 'Maximum weight of bundles has been exceeded'
    },
    container_must_have_7_bundles: {
        id: 'container_must_have_7_bundles',
        description: 'container_must_have_7_bundles',
        defaultMessage: 'Natural Stone containers must have 7 bundles'
    },
    EXCEEDE_ORDER_MAXIMUM_LOWES: {
        id: 'EXCEEDE_ORDER_MAXIMUM_LOWES',
        description: 'EXCEEDE_ORDER_MAXIMUM_LOWES',
        defaultMessage: 'You have reached the limit for this month, {monthItems} / 30'
    },
    EXCEEDE_ORDER_MAXIMUM_MKTOOLS: {
        id: 'EXCEEDE_ORDER_MAXIMUM_MKTOOLS',
        description: 'EXCEEDE_ORDER_MAXIMUM_MKTOOLS',
        defaultMessage: 'You have reached weekly limit of orders, wait till {waitTillDate}'
    },
    EXCEEDE_CHECKOUT_COMMENT_LENGTH: {
        id: 'EXCEEDE_CHECKOUT_COMMENT_LENGTH',
        description: 'EXCEEDE_CHECKOUT_COMMENT_LENGTH',
        defaultMessage: 'You have exceeded the character limit allowed for comments'
    },
    encimera_ikea: {
        id: 'encimera_ikea',
        description: 'Encimera Ikea',
        defaultMessage: 'Encimera Ikea'
    },
    ORDER_IMAGES_NOT_FOUND: {
        id: 'ORDER_IMAGES_NOT_FOUND',
        description: 'ORDER_IMAGES_NOT_FOUND',
        defaultMessage: 'The images are not unavailable'
    },
    EMPTY_INVOICE: {
        id: 'EMPTY_INVOICE',
        description: 'The invoice is not availabel yet',
        defaultMessage: 'The invoice is not available yet'
    },
    USER_WITHOUT_CENTERS: {
        id: 'USER_WITHOUT_CENTERS',
        description: 'USER_WITHOUT_CENTERS',
        defaultMessage: 'The user haven`t centers'
    },
    INSUFFICIENT_PERMISSIONS: {
        id: 'INSUFFICIENT_PERMISSIONS',
        description: 'INSUFFICIENT_PERMISSIONS',
        defaultMessage: 'Your WE by Cosentino account is not valid to access e.Cosentino. Please contact your Sales Representative'
    },
    MUST_BE_A_VALID_EMAIL: {
        id: 'MUST_BE_A_VALID_EMAIL',
        description: 'MUST_BE_A_VALID_EMAIL',
        defaultMessage: 'Must be a valid email'
    },
    EMAIL_ALREADY_IN_USE: {
        id: 'EMAIL_ALREADY_IN_USE',
        description: 'EMAIL_ALREADY_IN_USE',
        defaultMessage: 'This email is already in use in another account'
    },
    SessionNotValidated: {
        id: 'SessionNotValidated',
        description: 'SessionNotValidated',
        defaultMessage: 'The session could not be started, please try again on JobTracker'
    },
    upcoming_product_alert: {
        id: 'upcoming_product_alert',
        description: 'upcoming_product_alert',
        defaultMessage: 'The chosen material still does not have stock available. Contact your nearest Center for an estimated delivery date'
    },
    BANNER_HOME_AND_BANNER_NEWSLETTER_REQUIRED: {
        id: 'BANNER_HOME_AND_BANNER_NEWSLETTER_REQUIRED',
        description: 'BANNER_HOME_AND_BANNER_NEWSLETTER_REQUIRED',
        defaultMessage: 'You must select a banner for the newsletter and the landing of the promotion'
    },
    DONT_ALLOWED_EMPTY_TEXT: {
        id: 'DONT_ALLOWED_EMPTY_TEXT',
        description: 'DONT_ALLOWED_EMPTY_TEXT',
        defaultMessage: 'You must write some text before save'
    },
    AVG_BUNDLE_SIZE: {
        id: 'AVG_BUNDLE_SIZE',
        description: 'AVG_BUNDLE_SIZE',
        defaultMessage: '* The size indicated in the Bundles is the average size of the Bundle.'
    },
    EXCEEDED_CHARS_LIMIT: {
        id: 'EXCEEDED_CHARS_LIMIT',
        description: 'EXCEEDED_CHARS_LIMIT',
        defaultMessage: 'The character limit has been exceeded in the following languages:{langs}'
    }
})

const Alerts = ({ alert, status, values, lastLoginAccount, cssIcon, iconSize, className, element }) => {
    let cssClasses = 'alert mb-1 mt-1 '
    if (status === 'warning') {
        cssClasses += 'alert-warning'
    } else if (status === 'info') {
        cssClasses += 'alert-info'
    } else if (status === 'primary') {
        cssClasses += 'alert-primary'
    } else if (status >= 200 && status < 300) {
        cssClasses += 'alert-success'
    } else if (status >= 300) {
        cssClasses += 'alert-danger'
    } else {
        cssClasses += 'alert-dark'
    }

    cssClasses = className ? (cssClasses += ' ' + className) : cssClasses

    const error =
        alert != null ? (
            <div className={cssClasses} role="alert">
                <div className="d-flex align-items-center">
                    {cssIcon && (
                        <div className="mr-2">
                            <i style={{ fontSize: iconSize }} className={cssIcon} />
                        </div>    
                    )}
                    <div>
                        <Translation id={alert} /*defaultMessage={MESSAGES[alert].defaultMessage}*/ values={values ? values : {}} />
                    </div>
                </div>
            </div>
        ) : (element ? (
            <div className={cssClasses} role="alert">
                <div className="d-flex align-items-center">
                    {cssIcon && (
                        <div className="mr-3">
                            <i style={{ fontSize: iconSize }} className={cssIcon} />
                        </div>
                    )}
                    <div>
                        {element}
                    </div>
                </div>
            </div>
        ) : (''))


    return <div>{error}</div>
}
export default Alerts
