import React from 'react'
import rectangle from '../../assets/img/banners-lechic/wide200.png'
import squared from '../../assets/img/banners-lechic/square800.jpg'
import imgit from '../../assets/img/banners-lechic/IT/wide.jpg'
import imgja from '../../assets/img/banners-lechic/JP/wide.jpg'
import imgde from '../../assets/img/banners-lechic/DE/wide.jpg'
import imges from '../../assets/img/banners-lechic/ES/wide.jpg'
import imghe from '../../assets/img/banners-lechic/HE/wide.jpg'
import imgen from '../../assets/img/banners-lechic/EN/wide.jpg'
import imgfr from '../../assets/img/banners-lechic/FR/wide.jpg'
import imgtr from '../../assets/img/banners-lechic/TR/wide.jpg'
import imgno from '../../assets/img/banners-lechic/NO/wide.jpg'
import imgnl from '../../assets/img/banners-lechic/NL/wide.jpg'
import imgfi from '../../assets/img/banners-lechic/FI/wide.jpg'
import imgpt from '../../assets/img/banners-lechic/PT/wide.jpg'
import imgitSquared from '../../assets/img/banners-lechic/IT/squared.jpg'
import imgjaSquared from '../../assets/img/banners-lechic/JP/squared.jpg'
import imgdeSquared from '../../assets/img/banners-lechic/DE/squared.jpg'
import imgesSquared from '../../assets/img/banners-lechic/ES/squared.jpg'
import imgheSquared from '../../assets/img/banners-lechic/HE/squared.jpg'
import imgenSquared from '../../assets/img/banners-lechic/EN/squared.jpg'
import imgfrSquared from '../../assets/img/banners-lechic/FR/squared.jpg'
import imgtrSquared from '../../assets/img/banners-lechic/TR/squared.jpg'
import imgnoSquared from '../../assets/img/banners-lechic/NO/squared.jpg'
import imgnlSquared from '../../assets/img/banners-lechic/NL/squared.jpg'
import imgfiSquared from '../../assets/img/banners-lechic/FI/squared.jpg'
import imgptSquared from '../../assets/img/banners-lechic/PT/squared.jpg'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getLanguage } from '../../store/ui/reducers'
import { getSalesOrg } from '../../store/login/reducers'

const mapStateToProps = (state, { match }) => {
    return {
        language: getLanguage(state),
        salesOrg: getSalesOrg(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}
class BannerLechic extends React.Component {
    render() {
        const img = this.props.squared ? squared : rectangle
        const images = {
            it: this.props.squared?imgitSquared:imgit,
            ja: this.props.squared?imgjaSquared:imgja,
            de: this.props.squared?imgdeSquared:imgde,
            es: this.props.squared?imgesSquared:imges,
            he: this.props.squared?imgheSquared:imghe,
            en: this.props.squared?imgenSquared:imgen,
            fr: this.props.squared?imgfrSquared:imgfr,
            sv: this.props.squared?imgenSquared:imgen,
            tr: this.props.squared?imgtrSquared:imgtr,
            no: this.props.squared?imgnoSquared:imgno,
            da: this.props.squared?imgenSquared:imgen,
            nl: this.props.squared?imgnlSquared:imgnl,
            fi: this.props.squared?imgfiSquared:imgfi,
            pl: this.props.squared?imgenSquared:imgen,
            pt: this.props.squared?imgptSquared:imgpt,
            'en-US': this.props.squared?imgenSquared:imgen,
            'fr-CA': this.props.squared?imgfrSquared:imgfr,
            'en-CA': this.props.squared?imgenSquared:imgen
        }
        const links = {
            it: 'https://www.cosentino.com/it-it/silestone/le-chic/',
            ja: 'https://www.cosentino.com/ja-jp/silestone/le-chic/',
            de: 'https://www.cosentino.com/de/silestone/le-chic/',
            es: 'https://www.cosentino.com/es/silestone/le-chic/',
            he: 'https://www.cosentino.com/silestone/le-chic/',
            en: 'https://www.cosentino.com/en-gb/silestone/le-chic/',
            fr: 'https://www.cosentino.com/fr-fr/silestone/le-chic/',
            sv: 'https://www.cosentino.com/sv-se/silestone/le-chic/',
            tr: 'https://www.cosentino.com/tr-tr/silestone/le-chic/',
            no: 'https://www.cosentino.com/no-no/silestone/le-chic/',
            da: 'https://www.cosentino.com/silestone/le-chic/',
            nl: 'https://www.cosentino.com/nl-nl/silestone/le-chic/',
            fi: 'https://www.cosentino.com/fi-fi/silestone/le-chic/',
            pl: 'https://www.cosentino.com/silestone/le-chic/',
            pt: 'https://www.cosentino.com/pt-pt/silestone/le-chic/',
            'en-US': 'https://www.cosentino.com/usa/silestone/le-chic/',
            'fr-CA': 'https://www.cosentino.com/fr-ca/silestone/le-chic/',
            'en-CA': 'https://www.cosentino.com/en-ca/silestone/le-chic/'
        }
        return (
            <div className="banner_pietrakode" style={this.props.styles}>
                <a href={links[this.props.language]} target="_blank" rel="noopener noreferrer">
                    <img style={{ maxWidth: '100%' }} src={images[this.props.language]} alt="banner-le-chic" />
                </a>
            </div>
        )
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(BannerLechic))
