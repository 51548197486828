import React from 'react'
import Button from '../../global/Button'
import DisplayPricePhase2 from '../../catalog/rectangleResult/displays/DisplayPricePhase2'
import DisplayOutletIndicator from '../../catalog/rectangleResult/displays/DisplayOutletIndicator';
import cameraOff from '../../../assets/img/icons/camera-off.svg'
import ProductDisplayName from '../../global/ProductDisplayName';
import Translation from '../../global/Translation';
import RectangleResultContainer from '../../catalog/rectangleResult/RectangleResultContainer';
import ProductContext, {MallProductContext} from "../../catalog/rectangleResult/contexts/ProductContext";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {
    getCommonParams,
    getPriceListAtSelectedCenter,
    getUserPriceList, getUserType
} from "../../../store/login/reducers";
import {getSelectedCenter} from "../../../store/centers/reducers";
import {dispatchPushURL} from "../../../store/ui/actions";
import {setLastSelectedProduct} from "../../../store/selected-objects/actions";

const SliderItem = ({
    product,
    setProductSelectedToAdd,
    onZoomClick,
    sapLandingId,
    ...props
}) => {

    const showValidImage = () => {
        if (product.detailImage && product.detailImage !== 'null' && product.detailImage !== undefined && product.detailImage !== 'undefined' ) return product.detailImage
        if (product.imgLarge && (product.imgLarge !== 'null' || product.imgLarge != 'null')) return product.imgLarge
        return cameraOff
    }

    const placeHolderClassName = () => {
        if((!product.detailImage || product.detailImage === 'null') || (!product.imgLarge || product.imgLarge === 'null' || product.imgLarge == 'null')) return '--placeholder'
        return ''
    }

    return (
        props.newVersion ?
            <div className="slide-card">
                <div className="container p-0">
                    <div className="slide-card--content">
                        <div className={"col-lg-7 col-md-6 col-12 slide-card--image" + placeHolderClassName()}
                             style={{ backgroundImage: `url(${showValidImage()})` }}
                        >
                            {product && product.outlet && product.realStock > 0 &&
                                <DisplayOutletIndicator outletQuality={product.outletQuality} />
                            }
                            {product.realStock === 0 &&
                                <span className="slide-card__tag"><Translation id="sold_out" defaultMessage="Sold out" /></span>
                            }
                            {(product.detailImage && product.detailImage !== 'null') || (product.imgLarge && (product.imgLarge !== 'null' || product.imgLarge != 'null')) ?
                                <Button
                                    className="slide-card__zoom"
                                    icon="expand"
                                    size="medium"
                                    inverted
                                    onClick={onZoomClick}
                                />
                            : null}
                        </div>

                        <div className="col-lg-5 col-md-6 col-12 mall-rectangle-container">
                            <ProductContext.Provider value={MallProductContext}>
                                <RectangleResultContainer
                                    product={product}
                                    key={product.productId}
                                    list={'MALL'}
                                    sapLandingId={sapLandingId}
                                />
                            </ProductContext.Provider>
                        </div>
                    </div>
                </div>
            </div>
            :
            // ******************** OLD VERSION *******************
            <div className="slide-card">
                <div className="container p-0">
                    <div className="row no-gutters slide-card--content">                      
                        <div className={"col-lg-7 col-md-6 col-12 slide-card--image" + placeHolderClassName()}
                            style={{ backgroundImage: `url(${showValidImage()})` }}
                        >
                            {product && product.outlet && product.realStock > 0 &&
                                <DisplayOutletIndicator outletQuality={product.outletQuality} />
                            }
                            {product.realStock === 0 &&
                                <span className="slide-card__tag"><Translation id="sold_out" defaultMessage="Sold out" /></span>
                            }
                            {(product.detailImage && product.detailImage !== 'null') || (product.imgLarge && (product.imgLarge !== 'null' || product.imgLarge != 'null')) ?
                                <Button
                                    className="slide-card__zoom"
                                    icon="expand"
                                    size="medium"
                                    inverted
                                    onClick={onZoomClick}
                                />
                            : null}
                        </div>
                        <div className="col-lg-5 col-md-6 col-12 slide-card--text">
                            <div className="slide-card__brand--wrapper">
                                {
                                    product.brand === 'DEKTON' && props.selectedCenter === 7130 ?
                                        <span>DKTN &reg;</span>
                                        :
                                        !!product.hasImageWhite && <img className={`slide-card__brand slide-card__brand--image slide-card__brand--${product.brandWithoutSpaces} slide-card__brand--white`}
                                                                        alt={product.brand}
                                                                        src={product.hasImageWhite}
                                                                        height="20"
                                        />
                                }
                                {
                                    !product.hasImageWhite && product.brand !== 'DEKTON' && <p className="slide-card__brand slide-card__brand--text">{product.brand} &reg;</p>
                                }
                            </div>
                            <p className="slide-card__name">
                                <ProductDisplayName product={{ ...product,
                                                                displayName: product.name ? product.name.toUpperCase() : '',
                                                                translations: product.translations ? product.translations : ( product.data.translations ? product.data.translations : {} )
                                }} />
                            </p>
                            <div className="slide-card__data">
                                <div className="slide-card__data--specs">
                                    <p className="slide-card__data--thickness">
                                        <i className="icon-thickness light"></i>
                                        <span>{product.thickness}</span>
                                    </p>
                                    <p className="slide-card__data--finish">
                                        <i className="icon-finish light"></i>
                                        <span>{product.finish}</span>
                                    </p>
                                </div>
                                {
                                    product && props.userType !== 'ADMIN' ?
                                        <p className="slide-card__data--price">
                                            <DisplayPricePhase2
                                                product={product}
                                            />
                                        </p>
                                        :
                                        <span><Translation id="price_not_available" defaultMessage="Price not available" /></span>
                                }
                            </div>
                            <div className="slide-card__order">
                                <p className="slide-card__order--stock">
                                    <strong>{props.intl.formatMessage({ id: 'stock' })}:</strong> {product.realStock}
                                </p>
                                <p className="slide-card__order--minimum">
                                    {
                                        !!product.minimumOrder && (
                                            <React.Fragment><strong><Translation id="minimum_order" defaultMessage="Minimum order" />:</strong> {product.minimumOrder}</React.Fragment>
                                        )
                                    }
                                </p>
                            </div>
                            <div className="slide-card__buttons">
                                <Button
                                    size="medium"
                                    icon="plus-circle"
                                    customClass="slide-card__buttons__btn slide-card__buttons__btn--ghost"
                                    onClick={() => {
                                        // analytics.productClick(product, index, 'MALL', priceList, userPriceList, commonParamsAnalytics)
                                        props.setSelectedProduct(product);
                                        props.addProductInFetchedProducts(product, parseInt(product.centerId, 10), props.intl.locale);
                                        props.redirect(props.intl.formatMessage({ id: 'ROUTE_PRODUCT_DETAIL' }).replace(':id', product.productId).replace(':outlet?', product.outlet ? 'outlet' : ''))
                                    }}
                                >
                                    <Translation id="info" defaultMessage="Information" />
                                </Button>
                                {
                                    product.realStock > 0 && (
                                        <Button
                                            size="medium"
                                            icon="shopping-cart"
                                            customClass="slide-card__buttons__btn"
                                            onClick={() => {
                                                props.addProductInFetchedProducts(product, parseInt(product.centerId, 10), props.intl.locale);
                                                props.setSelectedProduct(product);
                                                setProductSelectedToAdd(product)
                                            }}
                                        >
                                            {props.intl.formatMessage({ id: 'add_to_cart' })}
                                        </Button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

const mapStateToProps = state => {
    return {
        landing: state.landing.landingBySlug,
        userType: getUserType(state),
        newVersion: state.login.newVersion,
        commonParamsAnalytics: getCommonParams(state),
        priceList: getPriceListAtSelectedCenter(state),
        userPriceList: getUserPriceList(state),
        selectedCenter: getSelectedCenter(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        redirect: url => {
            dispatch(dispatchPushURL(url))
        },
        addProductInFetchedProducts: (product, centerId, locale) => {
            console.log('FETCHED PRODUCT FROM MALL SLIDER ITEM:' , product.productId, centerId)
            dispatch({ type: 'FETCH_PRODUCT_SUCCESS', product, centerId, locale })
        },
        setSelectedProduct: product => dispatch(setLastSelectedProduct(product))
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SliderItem)
)

