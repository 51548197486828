import React from 'react'

import { connect } from 'react-redux'
import { getProfileConfigurationEmails } from '../../store/profile-configuration/reducers'
import { getVersion } from '../../store/login/reducers'
import Translation from '../global/Translation'
import {clone} from "lodash";

const mapStateToProps = (state, ownProps) => {      
    return {
        emails: ownProps.emails ? ownProps.emails : getProfileConfigurationEmails(state),
        newVersion: getVersion(state),
    }
}

class ProfileConfigurationEmails extends React.Component {
    constructor(props) {
        super(props)            
        this.email = []
        this.state={
            emails: []
        }
    }

    componentDidMount() {
        // this.setState({ emails: this.props.emails }, () => {
        //     this.props.change(this.state.emails)
        // })
        // this.setState({ emails: this.props.emails})
        const emails = clone(this.props.emails)
        this.setState({emails})
    }
   
    addEmail = input => {
        if (input.validity.valid && input.value !== '') {
            // this.props.change(this.props.emails.concat(input.value))
            // this.setState({ emails: this.props.emails.concat(input.value) })

            let emails = clone(this.state.emails)
            emails = emails.concat(input.value)
            this.setState({emails})
            this.props.change(emails)

            input.value = ''
        }
    }

    removeEmail = idRemove => {
        const emails = clone(this.state.emails)
        emails.splice(idRemove, 1)
        this.setState({emails})
        this.props.change(emails)
        // if (this.props.emails.length > 1) {
        //     [...document.querySelectorAll("#email" + idRemove)].map(e => e.hidden = true);
        //     this.props.change(this.props.emails.filter((email, index) => index !== idRemove))
        //     this.setState(
        //         {
        //             emails: this.state.emails.filter((email, index) => index !== idRemove)
        //         },
        //         function() {
        //             this.props.change(this.state.emails)
        //         }
        //     )
        //
        // }


    }
    
    changeValueInput = (id, value) => {
        let emails = this.state.emails
        emails[id] = value
        this.setState({ emails: emails }, function() {
            this.props.change(this.state.emails)
        })
    }

    render() {
        return (
            <div>
                <div className="input-group input-group-sm">
                    <input
                        id={this.props.idForInput ? `input-${this.props.idForInput}` : null}
                        type="email"
                        ref={node => {
                            this.inputEmail = node
                        }}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                e.preventDefault()
                                this.addEmail(this.inputEmail)
                            }
                        }}
                        className={`form-control ${this.props.newVersion ? 'customDropdown' : '' }`}
                        placeholder="Email"
                        style={{ height: this.props.newVersion ? '35px' : '' }}
                    />
                    <span className="input-group-btn input-group-append">
                        <button
                            className="btn btn-outline-secondary btn-add"
                            type="button"
                            onClick={() => {
                                this.addEmail(this.inputEmail)
                            }}
                        >
                            <i className="fa fa-plus" />
                            <span style={{ marginLeft: '6px' }}><Translation id="add" defaultMessage="Add" /></span>
                        </button>
                    </span>
                </div>
                {this.state.emails.map((email, i) => {
                    return (
                        <span className="badge badge-secondary badge-emails" key={email}>
                            {email}{' '}
                            {this.state.emails.length > 0 ? (
                                <i
                                    className={`fa fa-times-circle ${this.props.newVersion ? '' : 'btn-secondary'}`}
                                    onClick={() => {
                                        this.removeEmail(i)
                                    }}
                                />
                            ) : null}
                        </span>
                    )
                })}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    null
)(ProfileConfigurationEmails)
