import React from 'react'
import Translation from '../global/Translation'

import { connect } from 'react-redux'
import { getChannelByName, getChannelNameById } from '../../store/segmentation/reducers'
const mapStateToProps = (state, ownProps) => {
    let channelId = ownProps.channelId
    return {
        channel: getChannelNameById(state, channelId)
    }
}
const DisplayChannel = ({ channel, channelId }) => {
    return <span>{(channel && channel.name) || channelId}</span>
}

export default connect(
    mapStateToProps,
    null
)(DisplayChannel)
