import React from 'react'
import Translation from '../global/Translation'

import { NoHits, PageSizeSelector, Select, Pagination, SortingSelector } from 'searchkit'
import { injectIntl, defineMessages } from 'react-intl'
import FormatCSVModal from '../global/FormatCSVModal'
import { ReactComponent as IconTruck } from '../../assets/img/icons/icon-catalog-shipping-truck.svg'
import { ReactComponent as IconSlabs } from '../../assets/img/icons/icon-catalog-slabs.svg'
import { ReactComponent as IconDownload } from '../../assets/img/icons/icon-catalog-download.svg'
import { ReactComponent as IconInfo } from '../../assets/img/icons/icon-catalog-info.svg'
import { ReactComponent as IconWarning } from '../../assets/img/icons/icon-catalog-warning.svg'
import OutletPnFromOtherCentersWalkthrough from '../walkthroughs/outlet-pn-from-other-centers/OutletPnFromOtherCentersWalkthrough'
import axios from 'axios'

defineMessages({
    displayName: {
        id: 'displayName',
        description: 'displayName',
        defaultMessage: 'Product Name'
    },
    csv: {
        id: 'csv',
        description: 'csv',
        defaultMessage: 'csv'
    },
    subtype_standard: {
        id: 'subtype_standard',
        description: 'subtype_standard',
        defaultMessage: 'Standard'
    },
    subtype_raw: {
        id: 'subtype_raw',
        description: 'subtype_raw',
        defaultMessage: 'Raw'
    },
    subtype_optimma: {
        id: 'subtype_optimma',
        description: 'subtype_optimma',
        defaultMessage: 'Optimma'
    },
    subtype_jumbo: {
        id: 'subtype_jumbo',
        description: 'subtype_jumbo',
        defaultMessage: 'Jumbo'
    },
    2.0: {
        id: '2.0',
        description: '2.0',
        defaultMessage: '2.0'
    },
    1.2: {
        id: '1.2',
        description: '1.2',
        defaultMessage: '1.2'
    },
    3.0: {
        id: '3.0',
        description: '3.0',
        defaultMessage: '3.0'
    },
    '306X141': {
        id: '306X141',
        description: '306X141',
        defaultMessage: '306X141'
    },
    thank_you_very_much_would_you_like_to_add_anything_else: {
        id: 'thank_you_very_much_would_you_like_to_add_anything_else',
        description: 'Thank you very much, would you like to add anything else?',
        defaultMessage: 'Thank you very much, would you like to add anything else?'
    }
})

class ResultsV2 extends React.Component {
    state = { showCSVFormat: false }

    resize = () => {
        let pageScope = 4
        if (window.innerWidth < 768) {
            pageScope = 1
        }
        this.setState({ pageScope })
    }
    componentDidMount() {
        window.addEventListener('resize', this.resize)
        this.resize()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    customSelect = () => {
        this.setState({ classActiveBool: !this.state.classActiveBool })
    }

    closeFormatCSVModal(format) {
        this.setState({ showCSVFormat: false })

        if (!format) {
            return
        }

        const { intl } = this.props
        const fieldsSlabs = [
            'brand',
            'subtypeName',
            'productId',
            'colorName',
            'finish',
            'thickness',
            'stock_m2',
            'stock_ft2',
            'stock',
            'stock_outlet_m2',
            'stock_outlet_ft2',
            'stock_outlet',
            'outletQuality'
        ]
        const fieldsMktools = [
            'productId',
            'displayName',
            'brand',
            'webHeading',
            'type',
            'subtypeName',
            'subtype1Name',
            'subtype2Name',
            'colorName',
            'finishFamily',
            'thickness',
            'stock',

            'stock_outlet',
            'outletQuality'
        ]
        const fields = this.props.isMkToolsCenter ? fieldsMktools : fieldsSlabs
        const header = fields.map(field => {
            return intl.formatMessage({ id: `${field}` })
        })

        const FileDownload = require('js-file-download')
        axios
            .get(process.env.REACT_APP_API_HOST + `/catalog/csv?format=${format}&fields=${encodeURIComponent(fields.join(','))}&header=${encodeURIComponent(header.join(','))}`, {
                headers: { Authorization: `Bearer ${this.props.token}` },
                'Content-Type': 'text/plain',
                responseType: 'blob'
            })
            .then(response => {
                FileDownload(response.data, `products.${format}`)
            })
    }

    render() {
        const sortingSelector = (
            <SortingSelector
                options={[
                    {
                        label: this.props.intl.formatMessage({
                            id: 'stock_high_to_low'
                        }),
                        field: this.props.outlet ? 'stock_outlet' : 'stock',
                        order: 'desc',
                        defaultOption: !this.props.isIkeaCenter
                    },
                    {
                        label: this.props.intl.formatMessage({
                            id: 'stock_low_to_high'
                        }),
                        field: this.props.outlet ? 'stock_outlet' : 'stock',
                        order: 'asc',
                        defaultOption: false
                    },
                    {
                        label: this.props.intl.formatMessage({
                            id: 'newest_arrivals'
                        }),
                        field: 'creationDate',
                        order: 'desc',
                        defaultOption: this.props.isIkeaCenter
                    },
                    {
                        label: this.props.intl.formatMessage({
                            id: 'oldest_materials'
                        }),
                        field: 'creationDate',
                        order: 'asc',
                        defaultOption: false
                    }
                ]}
            />
        )

        const slabOutletLocation = ['tabla', 'outlet'].includes(this.props.currentLocation.section)

        return (
            <div className="results">
                <div className={`results__header__options ${slabOutletLocation ? 'justify-content-between' : 'justify-content-end'}`}>
                    {this.props.canViewOutletAndNaturalStoneFromHQInOtherCenters && (
                        <React.Fragment>
                            {slabOutletLocation && (
                                <div id="pn_outlet_hq_button" className="pn__switch__tabs">
                                    <button className={`pn__switch__tabs--madrid ${this.props.statePnHQ ? '' : 'active-madrid'}`} onClick={() => this.props.setPnHQ(false)}>
                                        <IconSlabs />
                                        <span>{this.props.centerData && this.props.centerData.name}</span>
                                    </button>
                                    <button className={`pn__switch__tabs--pn ${this.props.statePnHQ ? 'active-pn' : ''}`} onClick={() => this.props.setPnHQ(true)}>
                                        <IconTruck />
                                        {this.props.currentLocation.section === 'tabla' ? (
                                            <Translation id="natural_stone_hq" defaultMessage="Natural stone in HQ" />
                                        ) : this.props.currentLocation.section === 'outlet' ? (
                                            <Translation id="outlet_in_hq" defaultMessage="Outlet in HQ" />
                                        ) : (null)}
                                    </button>
                                </div>
                            )}
                            <OutletPnFromOtherCentersWalkthrough key={this.props.skKey}/>
                        </React.Fragment>
                    )}

                    <div className="options__filters">
                        {!this.props.hideOptions && (
                            <React.Fragment>
                                <div className="options__filters--count">
                                    <span className="showing">
                                        <Translation id="showing" defaultMessage="Showing" />
                                    </span>
                                    &nbsp;
                                    <span className="count-text">{this.props.totalResult}</span>
                                    <span>
                                        &nbsp;
                                        <Translation id="results" defaultMessage="Results" />
                                    </span>
                                </div>

                                {this.props.token && !this.props.isLowesCenter && (
                                    <div onClick={this.customSelect}>
                                        <div className={this.state.classActiveBool ? 'select-excel-v2 open-excel-v2' : 'select-excel-v2'} gtm-label="ec_plp_descarga">
                                            <button className="options__filters--icon">
                                                <IconDownload />
                                            </button>

                                            <div className="custom-options-excel-v2">
                                                <button data-value="xls" onClick={() => this.closeFormatCSVModal('xls')}>
                                                    {this.props.intl.formatMessage({ id: 'excel' })}
                                                </button>
                                                <button data-value="csv" onClick={() => this.closeFormatCSVModal('csv')}>
                                                    {this.props.intl.formatMessage({ id: 'csv' })}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {!this.props.isLowesCenter && !this.props.isIkeaCenter && (
                                    <div className="catalog__sorting__selector">
                                        <div className="order-by">
                                            <span>
                                                <Translation id="order_by" defaultMessage="Order by" />
                                            </span>
                                        </div>
                                        <div className="order-input" gtm-label="ec_plp_orden_listado">{sortingSelector}</div>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>

                {this.props.canViewOutletAndNaturalStoneFromHQInOtherCenters && this.props.statePnHQ && (
                    <div className="banner__pn__outlet">
                        <div>
                            <IconInfo />
                        </div>
                        <div className="banner__pn__outlet--content">
                            <div>
                                <span className="main-text">
                                    <Translation
                                        id="you_are_viewing_exclusively_natural_stone_materials"
                                        defaultMessage="You are viewing exclusively natural stone materials from HQ. Please note the following conditions to add them to your cart:"
                                    />
                                </span>
                            </div>
                            <div className="content-row">
                                <div>
                                    <IconTruck />
                                </div>
                                <div>
                                    <span>
                                        <Translation
                                            id="delivery_times_may_be_longer_than_the_delivery"
                                            defaultMessage="Delivery times may be longer than the delivery times of your usual Center."
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="content-row">
                                <div>
                                    <IconWarning />
                                </div>
                                <div>
                                    <span>
                                        <Translation
                                            id="the_total_width_of_all_the_natural_stone_slabs_you_buy_from_HQ"
                                            defaultMessage="The TOTAL thickness of all the natural stone slabs you buy from HQ must be at least: 12 cm. Example: 4 slabs of 3 cm = 12 cm."
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className={`results-list text-center text-sm-left ${this.props.isLowesCenter ? 'lowes-list' : ''}`}>
                    {this.props.hits}
                    <NoHits />
                </div>
                <div
                    style={{
                        display: 'flex',
                        columnGap: '24px',
                        justifyContent: 'center',
                        alignItems: 'baseline'
                    }}
                >
                    {!this.props.hidePagination && (
                        <div className="results-pagination" data-cy="catalog__pagination_catalog" onClick={() => window.scrollTo(0, 160)}>
                            <Pagination showNumbers={true} pageScope={this.state.pageScope} />
                        </div>
                    )}
                    <div>{!this.props.hideOptions && <PageSizeSelector className="sk-select-v2" options={[24, 50, 100, 500]} listComponent={Select} />}</div>
                </div>

                {this.state.showCSVFormat && <FormatCSVModal onClose={format => this.closeFormatCSVModal(format)} token={() => this.props.token}></FormatCSVModal>}
            </div>
        )
    }
}

export default injectIntl(ResultsV2)
