import { connect } from 'react-redux'
import RecurringOrders from './RecurringOrders'
import {
    getRecurringOrder,
    getOrdersLoading,
    getOrdersLoadingAdd,
    getShowRecurringOrderDetails,
    getRecurringOrderDetails
} from '../../store/orders/reducers'
import {
    fetchRecurringOrders,
    add2CartRecurringOrder,
    toggleModalRecurringOrderDetails,
    fetchRecurringOrderDetails, add2CartRecurringOrderQty
} from '../../store/orders/actions'
import { getVersion } from '../../store/login/reducers'
import { addCartLineNotSegmentation } from '../../store/cart/actions'
import { getIsEndiCenter, getIsIkeaCenter, getIsLowesCenter, getIsMkToolsCenter, getIsMktoolsUsaCenter, getShippingMethod } from '../../store/centers/reducers'
import { getProductMinStock } from '../../store/landing/reducers'
import { getQtyProductsCart } from '../../store/cart/reducers'

const mapStateToProps = state => {
    return {
        recurringOrders: getRecurringOrder(state),
        isLoading: getOrdersLoading(state),
        isLoadingAdd: getOrdersLoadingAdd(state),
        showRecurringOrderDetails: getShowRecurringOrderDetails(state),
        recurringOrderDetails: getRecurringOrderDetails(state),
        newVersion: getVersion(state),
        shippingMethod: getShippingMethod(state),
        isEndiCenter: getIsEndiCenter(state),
        mallMinOrder: (productId) => getProductMinStock(state, productId),
        isMktoolsCenter: getIsMkToolsCenter(state),
        isMktoolsUsaCenter: getIsMktoolsUsaCenter(state),
        isIkeaCenter: getIsIkeaCenter(state),
        isLowesCenter: getIsLowesCenter(state),
        qtyProductCart: getQtyProductsCart(state),
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchRecurringOrders: (dateFrom = null, dateTo = null, search = null, page = 1) => dispatch(fetchRecurringOrders(dateFrom, dateTo, search, page)),
        add2CartRecurringOrder: id => {
            dispatch(add2CartRecurringOrder(id))
        },
        add2CartRecurringOrderQty: value => {
            dispatch(add2CartRecurringOrderQty(value))
        },
        openModalRecurringOrderDetails: selectedRecurringOrder => {
            dispatch(toggleModalRecurringOrderDetails(selectedRecurringOrder))
        },
        closeModalRecurringOrderDetails: () => {
            dispatch(toggleModalRecurringOrderDetails())
        },
        fetchRecurringOrderDetails: reservationId => {
            dispatch(fetchRecurringOrderDetails(reservationId))
        },
        addCartLine: (productId, qty) => dispatch(addCartLineNotSegmentation(productId, qty)),
    }
}

const RecurringOrdersContainer = connect(mapStateToProps, mapDispatchToProps)(RecurringOrders)

export default RecurringOrdersContainer
