import React from 'react'
import ProductDisplayName from '../global/ProductDisplayName'
import DisplayThickness from '../catalog/rectangleResult/displays/DisplayThickness'
import { injectIntl } from 'react-intl'
import Translation from '../global/Translation'
import InputNumber from '../global/InputNumber'
import LineSegmentation from '../cart-line/LineSegmentation'
import ProductImage from '../catalog/ProductImage'
import DisplayOutletIndicator from '../catalog/rectangleResult/displays/DisplayOutletIndicator'
import DisplayTechnology from '../catalog/rectangleResult/displays/DisplayTechnology'
import mallLarge from '../../assets/img/logos/mall-large.jpg'
import {getAdaptedFormat, getCtsMeasures} from "../../helpers";
import {connect} from "react-redux";
import moment from "moment";
import * as CutToSizeMaps from "../cut-to-size/CutToSizeMaps";
import getNumberFormat from '../getNumberFormat'


const CUT_TO_SIZE_MATERIALS = ['80457', '80461', '80451', '80458']

const OrderDetailsTable = props => {
    const { orderLines, orderToEdit, editMode, canEdit, onLineChangeValue, isEmserUser, preferredDeliveryDate, lang } = props

    if (editMode && !orderToEdit) return null

    const getOrderToEditLine = orderLine => {
        return orderToEdit.find(line => line.position === orderLine.Posnr)
    }

    const regularProductData = orderLine => {
        return orderLine.product ? (
            <div className="row">
                <div className={editMode ? 'col-3' : 'col-6'}>
                    <ProductImage product={orderLine.product}/>
                    <DisplayTechnology technology={orderLine.product.technology} />
                </div>
                <div className={editMode ? 'col-9' : 'col-6'}>
                    <p>
                        {indicator(orderLine)}
                        <Translation id="material" defaultMessage="Material" />:
                        <b>
                            <ProductDisplayName product={orderLine.product} />
                        </b>
                    </p>
                    <p>
                        <Translation id="brand" defaultMessage="Brand" />: <b>{orderLine.product.brand}</b>
                    </p>
                    <p>
                        <Translation id="product_id" defaultMessage="Product ID" />: <b>{orderLine.product.productId}</b>
                    </p>
                    {orderLine.lot_id && (
                        <p>
                            <Translation id="lot_id" defaultMessage="Lot ID" />: <b>{orderLine.lot_id}</b>
                        </p>
                    )}
                    {orderLine.product.thickness && (
                        <p>
                            <Translation id="thickness" defaultMessage="Thickness" />:
                            <b>
                                <DisplayThickness thickness={orderLine.product.thickness} string />
                            </b>
                        </p>
                    )}
                    {orderLine.product.finish && (
                        <p>
                            <Translation id="finish" defaultMessage="Finish" />:{' '}
                            <b>{props.intl.formatMessage({ id: orderLine.product.finish })}</b>
                        </p>
                    )}
                    {orderLine.product.format && (
                        <p>
                            <Translation id="format" defaultMessage="Format" />: <b>{
                            props.adaptedFormat(orderLine.product.format, {upper:true, showUnits:true})
                        }</b>
                        </p>
                    )}
                    {orderLine.outlet_quality && (
                        <p>
                            <DisplayOutletIndicator outletQuality={orderLine.outlet_quality} />
                        </p>
                    )}
                    {orderLine.mall_promotion_id && (
                        <div className="wrapper__logo_mall d-flex justify-content-end pr-2">
                            <img style={{ width: '60px' }} src={mallLarge} alt="logo mall" />
                        </div>
                    )}
                </div>
            </div>
        ) : (
            <div className="row">
                <div className={editMode ? 'col-3' : 'col-6'}>
                    <ProductImage product={null} materialId={orderLine.material_id}></ProductImage>
                </div>
                <div className={editMode ? 'col-9' : 'col-6'}>
                    <p>
                        <Translation id="product_id" defaultMessage="Product ID" />: <b>{orderLine.material_id}</b>
                    </p>
                    <p>
                        <Translation id="material" defaultMessage="Material" />: <b>{orderLine.short_text}</b>
                    </p>
                </div>
            </div>
        )
    }

    const ctsData = orderLine => {
        const cts = orderLine.cut_to_size
        const measures = props.getMeasure(cts)
        return (
        <div className="row">
            <div className={editMode ? 'col-3' : 'col-6'}>
                <div className="product-image-container">
                    <div
                        className="product-image"
                        style={{
                            backgroundColor: 'white',
                            backgroundImage: 'url("' + cts.thumb + '")',
                            backgroundSize: 'cover',
                            maxHeight:'164px'
                        }}
                    ></div>
                </div>
                {/*<DisplayTechnology technology={orderLine.product.technology} />*/}
            </div>
            <div className={editMode ? 'col-9' : 'col-6'} style={{fontSize:'0.94em'}}>
                <div style={{fontWeight: 500, marginBottom: '4px'}}>
                    {/*<Translation id="elaboration" defaultMessage="Elaboration" />:&nbsp;*/}
                    { cts.elaboration === 'CUSTOM_MOBILIARIO' &&
                        <div>
                            <b>{props.intl.formatMessage(CutToSizeMaps.Messages[cts.elaboration])}</b>&nbsp;
                            (<b className="my-1">
                                {
                                    !!cts.samePattern ? (
                                        <Translation id="extension" defaultMessage="Extension" />
                                        ) : (
                                        <Translation id="main_piece" defaultMessage="Main piece" />
                                    )
                                }
                            </b>)
                        </div>
                    }
                </div>

                <div>
                    <Translation id="product_id" defaultMessage="Product ID" />:&nbsp;<b>{orderLine.material_id}</b>
                </div>
                <div>
                    <Translation id="brand" defaultMessage="Brand" />:&nbsp;<b>{cts.brand}</b>
                </div>
                <div>
                    <Translation id="color" defaultMessage="Color" />:&nbsp;
                    <b>{cts.colorName}</b>
                </div>
                <div>
                    <Translation id="thickness" defaultMessage="Thickness" />:&nbsp;
                    <b><DisplayThickness thickness={cts.thickness} string /></b>
                </div>
                <div>
                    <Translation id="finish" defaultMessage="Finish" />:&nbsp;
                    <b>{props.intl.formatMessage({ id: cts.finish })}</b>
                </div>
                {
                    cts.shape && (
                        <div>
                            <Translation id="shape" defaultMessage="Shape" />:&nbsp;
                            <b>{props.intl.formatMessage(CutToSizeMaps.Messages[cts.shape])}</b>
                        </div>
                    )
                }
                {
                    measures.diameter && (
                        <div>
                            <Translation id="diameter" defaultMessage="Diameter" />:&nbsp;
                            <b>{measures.diameter}</b>
                        </div>
                    )
                }
                {
                    measures.format && (
                        <div>
                            <Translation id="format" defaultMessage="Format" />:&nbsp;
                            <b>{measures.format}</b>
                        </div>
                    )
                }
                {
                    measures.radius && (
                        <div>
                            <Translation id="radius" defaultMessage="Radius" />:&nbsp;
                            <b>{measures.radius}</b>
                        </div>
                    )
                }
                {
                    cts.edge && (
                        <div>
                            <Translation id="edge" defaultMessage="Edge" />:&nbsp;
                            <b>{props.intl.formatMessage(CutToSizeMaps.Messages[cts.edge])}</b>
                        </div>
                    )
                }
                {
                    cts.bevel && (
                        <div>
                            <Translation id="bevel" defaultMessage="Bevel" />:&nbsp;
                            <b>{props.intl.formatMessage(CutToSizeMaps.Messages[cts.bevel])}</b>
                        </div>
                    )
                }
                {
                    cts.packaging && (
                        <div>
                            <Translation id="packaging" defaultMessage="Packaging" />:&nbsp;
                            <b>{props.intl.formatMessage(CutToSizeMaps.Messages[cts.packaging])}</b>
                        </div>
                    )
                }
                {
                    cts.samePattern === 'YES' && (
                        <div>
                            <b><Translation id="same_pattern"/></b>
                        </div>
                    )
                }
            </div>
        </div>)
    }


    const editModeQuantityCell = orderLine => {
        const editLine = getOrderToEditLine(orderLine)

        if (orderLine.product) {
            if (orderLine.product.lotId && orderLine.product.lotId !== '') {
                return (
                    <span>
                        <Translation id="lot_id" defaultMessage="Lot ID" />: {orderLine.product.lotId}
                    </span>
                )
            } else if (orderLine.product.bundleId && orderLine.product.bundleId !== '') {
                return (
                    <span>
                        <Translation id="bundle_id" defaultMessage="bundle ID" />: {orderLine.product.bundleId}
                    </span>
                )
            } else if (canEdit(orderLine.Posnr)) {
                return <InputNumber key={orderLine.Posnr + '' + editLine.quantity} value={editLine.quantity} change={qty => onLineChangeValue(orderLine.Posnr, 'qty', qty)} />
            }
        }

        return editLine ? editLine.quantity : null
    }

    const editModeActionCell = orderLine => {
        const editLine = getOrderToEditLine(orderLine)

        if (canEdit(orderLine.Posnr)) {
            if (!editLine.rejected) {
                return (
                    <div className="reject" style={{ cursor: 'pointer' }}>
                        <i className="fal fa-trash-alt" onClick={() => onLineChangeValue(orderLine.Posnr, 'rejected', editLine.rejected)} />
                    </div>
                )
            } else {
                return (
                    <div className="accept" style={{ cursor: 'pointer' }}>
                        <i className="fal fa-undo" onClick={() => onLineChangeValue(orderLine.Posnr, 'rejected', editLine.rejected)} />
                    </div>
                )
            }
        } else return null
    }

    const isRejected = orderLine => {
        return getOrderToEditLine(orderLine).rejected
    }

    const isEditable = orderLine => {
        return !indicator(orderLine)
    }

    const indicator = orderLine => {
        let indicator = null

        if (orderLine.reservation_quantity != orderLine.quantity) {
            indicator = (
                <div className="new-indicator mt-1 mb-3">
                    <i className="fa fa-check" /> <Translation id="modified_line" defaultMessage="Modified line" />
                </div>
            )
        }
        if (orderLine.reservation_quantity == 0 && orderLine.quantity > 0) {
            indicator = (
                <div className="new-indicator mt-1 mb-3">
                    <i className="fa fa-check" /> <Translation id="added_line" defaultMessage="Added line by center" />
                </div>
            )
        }
        if ((orderLine.reservation_quantity > 0 && orderLine.quantity == 0) || orderLine.rejected || orderLine.reject_reason) {
            indicator = (
                <div className="reject-indicator mt-1 mb-3">
                    <i className="fa fa-times" /> <Translation id="rejected_line" defaultMessage="Rejected line" />
                </div>
            )
        }

        return indicator
    }

    const showPrice = () => !isEmserUser && !CUT_TO_SIZE_MATERIALS.includes(String(orderLines[0].material_id))

    return (
        <div className="ohcard">
            <div className="all-view">
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ width: editMode ? '70%' : '35%' }}>Item</th>
                            {editMode ? (
                                <th>
                                    <Translation id="qty" defaultMessage="Quantity" />
                                </th>
                            ) : (
                                <React.Fragment>
                                    <th>
                                        <Translation id="applied_segmentation" defaultMessage="Applied segmentation" />
                                    </th>
                                    <th>
                                        <Translation id="comments" defaultMessage="Comments" />
                                    </th>
                                    <th>
                                        <Translation id="requested_delivery_date" defaultMessage="Requested Delivery Date"/>
                                    </th>
                                    <th>
                                        <Translation id="quantity_request" defaultMessage="Quantity requested" />
                                    </th>
                                    <th>
                                        <Translation id="order_quantity" defaultMessage="Order quantity" />
                                    </th>

                                    {showPrice() && (
                                        <th>
                                            <Translation id="price" defaultMessage="Price" />
                                        </th>
                                    )}

                                </React.Fragment>
                            )}
                            {/* <th>Descuento aplicado</th> */}
                            {/* <th>Precio aplicado</th> */}
                            {editMode && (
                                <th>
                                    <Translation id="reject" defaultMessage="Reject" />
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {orderLines &&
                            orderLines.length > 0 &&
                            orderLines.map(orderLine => {
                                let numberFormat = getNumberFormat(lang, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                                if(orderLine.coin === 'JPY') {
                                    numberFormat = getNumberFormat(lang, { maximumFractionDigits: 0, minimumFractionDigits: 0 })
                                }
                                return (
                                    <tr className={`${editMode && getOrderToEditLine(orderLine) && getOrderToEditLine(orderLine).rejected ? 'rejected' : ''}`}>
                                        <td className="text-left">
                                            { orderLine.cut_to_size ? ctsData(orderLine) : regularProductData(orderLine) }
                                        </td>
                                        {editMode ? (
                                            <td>{isEditable(orderLine) ? editModeQuantityCell(orderLine) : getOrderToEditLine(orderLine).quantity}</td>
                                        ) : (
                                            <React.Fragment>
                                                <td className="text-left">
                                                    {/* {orderLine.serializedLine && (
                                                    <div>
                                                        <strong>By you</strong>
                                                        <LineSegmentation line={orderLine.serializedLine} showAsList />
                                                    </div>
                                                )} */}

                                                    {orderLine.segmentation && (
                                                        <div>
                                                            {/* <strong>By your center</strong> */}
                                                            <LineSegmentation line={orderLine} showAsList />
                                                        </div>
                                                    )}
                                                    {/* <p><DisplayChannel channelId={orderLine.zz_channel}></DisplayChannel></p>
                                                <p><DisplaySubChannel channelId={orderLine.zz_channel} subchannelId={orderLine.zz_subchannel}></DisplaySubChannel></p>
                                                <p>Lorem ipsum dolor</p>
                                                <p>Lorem ipsum dolor</p> */}
                                                </td>
                                                <td>{orderLine.comments}</td>
                                                <td>
                                                    {preferredDeliveryDate
                                                          ? moment(preferredDeliveryDate).format('L')
                                                          : orderLine.preferredDeliveryDate
                                                            ? moment(orderLine.preferredDeliveryDate).format('L')
                                                            : ''
                                                    }
                                                </td>
                                                <td>
                                                    {orderLine.reservation_quantity == 0 ? (
                                                        <Translation id="added_line" defaultMessage="Added line by center" />
                                                    ) : (
                                                        parseInt(orderLine.reservation_quantity)
                                                    )}
                                                </td>
                                                <td>
                                                    {orderLine.reject_reason ? <Translation id="rejected_line" defaultMessage="Rejected line" /> : parseInt(orderLine.quantity)}
                                                </td>
                                                {showPrice() && (
                                                    <td>
                                                        {numberFormat.format(orderLine.import)} {orderLine.coin}
                                                    </td>
                                                )}
                                            </React.Fragment>
                                        )}
                                        {/* <td>5%</td> */}
                                        {/* <td>150.000</td> */}
                                        {editMode && <td>{isEditable(orderLine) && editModeActionCell(orderLine)}</td>}
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        adaptedFormat: (format, params) => getAdaptedFormat(format, params, state),
        getMeasure: (cutToSize) => getCtsMeasures(cutToSize, state)
    }
}


export default injectIntl(connect(
    mapStateToProps,
    null
)(OrderDetailsTable))
