import { injectIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'
import SimpleModal from '../global/SimpleModal'
import { Checkbox } from '@progress/kendo-react-inputs'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import MallSlider from './components/MallSlider'
import AddToCartModalItem from './components/AddToCartModalItem'
import BannerImage from './components/BannerImage'
import * as analytics from '../../analyticsEvents'
import { hasImage, isFilterSelected, getBrandNameWithoutSpaces, SORT_OPTIONS, SORT_CLEAR_OPTION } from './utils'
import Button from '../global/Button'
import Translation from '../global/Translation'
import FloatingAlert from '../alerts/FloatingAlert'
import ImpersonateBar from '../impersonate/ImpersonateBar'
import Countdown from '../global/Countdown'
import ProductItem from "./components/ProductItem";
import ProductItemOld from "./components/ProductItemOld";
import MallImageViewer from "./components/MallImageViewer";


const LandingTemplates = ({
    landing,
    currentIso,
    filters,
    addCartLine,
    formattedProducts,
    redirect,
    intl,
    addProductInFetchedProducts,
    setSelectedProduct,
    showFilters,
    setShowFilters,
    productSelectedToAdd,
    setProductSelectedToAdd,
    sortSelected,
    selectedSort,
    selectFilter,
    unselectFilters,
    filtersSelected,
    landingData,
    commonParamsAnalytics,
    priceList,
    showToastNotification,
    lines,
    userPriceList,
    selectedCenter,
    userType,
    newVersion,
    ...props
}) => {
    useEffect(() => {
        if (formattedProducts.length > 0) analytics.sendMallImpressions(formattedProducts, priceList, userPriceList, commonParamsAnalytics)
    }, [formattedProducts.length])

    const [toggleState, setToggleState] = useState(true)
    const [showImageModal, setShowImageModal] = useState(false)
    const [imageModal, setImageModal] = useState('')
      

    function toggleMallContainerDiscount() {
        setToggleState(!toggleState)
    }

    const isAnyFilterActive = () => {
        let active = false

        Object.keys(filtersSelected).forEach(f => {
            if (filtersSelected[f].length > 0) {
                active = true
            }
        })

        return active
    }

    const onZoomClick = product => {
        setImageModal(product)
        setShowImageModal(true)
    }

    return (
        <div className={`mall ${newVersion ? "new-mall" : ""}`} style={{ backgroundColor: "#F9F9F9"}}>
            <div className="container">
                <div className='mall-header'>
                    <div>
                        <h1 className='mall-title'>
                            {landingData.title}
                        </h1>
                    </div>
                    <div className="timer-promo">
                        <span className="time-remaining"><Translation id="time_remaining" defaultMessage="Promotion time remaining" /></span>
                        <Countdown endDate={landingData.end_at ? landingData.end_at : "2025-02-15"} />
                    </div>                
                </div>
            </div>
            <MallSlider
                products={formattedProducts}
                newVersion={newVersion}
                onZoomClick={onZoomClick}
                setProductSelectedToAdd={setProductSelectedToAdd}
                sapId={landing.landing.sap_id}
            />

            {props.isUsaOrCa && toggleState && (
                <FloatingAlert
                    warningMessage={<Translation id="extra_discount_for_full_containers" defaultMessage="Extra discount for full containers"></Translation>}
                    mallContainerDiscount
                    className="catalog-position extra-discount"
                    onClose={() => toggleMallContainerDiscount()}
                />
            )}
            <ImpersonateBar />

            <hr></hr>
            <div className="container">
            <h2 className='all-materials-mall'><Translation id={"all_materials_view"} defaultMessage={"All materials view"}></Translation></h2>
                
            <BannerImage landingData={landingData} />

                {/*landingData.promotion && landingData.promotion !== 'null' && <Promotion landingData={landingData} />*/}
                <div>
                        {/* Integrar clase .mall__filters--closed para controlar visibilidad de filtros */}
                        <div className={`mall__filters`}>
                            <div className="mall__filters__content">
                                <div className="filter-list-mall row ml-0 mr-0">
                                    {filters && filters.brand && !!filters.brand.length && (
                                        <div className="col-12 col-md-3 col-lg-2 p-0">
                                            <div className="mall__filters__content__section">
                                                <div className='filter-header-mall' onClick={()=> {
                                                    [...document.querySelectorAll(`#brand-mall-filter`)].map(e => {
                                                        e.hidden = !e.hidden
                                                    });
                                                    [...document.querySelectorAll(`#up-brands-mall`)].map(e => {
                                                        if (e.classList.contains("d-none")) {
                                                            e.classList.remove("d-none")
                                                        } else {
                                                            e.classList.add("d-none")
                                                        }
                                                    });
                                                    [...document.querySelectorAll(`#down-brands-mall`)].map(e => {
                                                        if (e.classList.contains("d-none")) {
                                                            e.classList.remove("d-none")
                                                        } else {
                                                            e.classList.add("d-none")
                                                        }
                                                    })
                                                }}>
                                                    <span className='filter-mall-name'>{intl.formatMessage({ id: 'brands' })}</span>
                                                    <i className={`fal fa-chevron-down`} id={"down-brands-mall"}></i>
                                                    <i className={`fal fa-chevron-up d-none`} id={"up-brands-mall"}></i>
                                                </div>
                                                <div className="mall__filters__content__check" id='brand-mall-filter' hidden={true}>
                                                    {filters.brand.map((filter, i) => {
                                                        return (
                                                            <Checkbox
                                                                onChange={selectFilter('brand', filter)}
                                                                id={filter}
                                                                checked={isFilterSelected(filtersSelected['brand'], filter)}
                                                                key={i}
                                                            >
                                                                <label htmlFor={filter} className={'k-checkbox-label'}>
                                                                    {filter === 'DEKTON' && selectedCenter === 7130 ? (
                                                                        <span>DKTN</span>
                                                                    ) : ( <span>{filter}</span>)}
                                                                </label>
                                                                {/* <label htmlFor={filter} className={'k-checkbox-label'}>
                                                                    {filter === 'DEKTON' && selectedCenter === 7130 ? (
                                                                        <span>DKTN &reg;</span>
                                                                    ) : !!hasImage(getBrandNameWithoutSpaces(filter)) ? (
                                                                        <img
                                                                            alt={filter}
                                                                            src={hasImage(getBrandNameWithoutSpaces(filter))}
                                                                            className={getBrandNameWithoutSpaces(filter)}
                                                                        />
                                                                    ) : (
                                                                        <span>{filter} &reg;</span>
                                                                    )}
                                                                </label> */}
                                                            </Checkbox>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {filters && filters.format && !!filters.format.length && (
                                        <div className="col-12 col-md-3 col-lg-2 p-0">
                                            <div className="mall__filters__content__section">
                                                <div className='filter-header-mall' onClick={() => {
                                                    [...document.querySelectorAll(`#format-mall-filter`)].map(e => {
                                                        e.hidden = !e.hidden
                                                    });
                                                    [...document.querySelectorAll(`#up-format-mall`)].map(e => {
                                                        if (e.classList.contains("d-none")) {
                                                            e.classList.remove("d-none")
                                                        } else {
                                                            e.classList.add("d-none")
                                                        }
                                                    });
                                                    [...document.querySelectorAll(`#down-format-mall`)].map(e => {
                                                        if (e.classList.contains("d-none")) {
                                                            e.classList.remove("d-none")
                                                        } else {
                                                            e.classList.add("d-none")
                                                        }
                                                    })
                                                }}>
                                                    <span className='filter-mall-name'>{intl.formatMessage({ id: 'format' })}</span>
                                                    <i className={`fal fa-chevron-down`} id={"down-format-mall"}></i>
                                                    <i className={`fal fa-chevron-up d-none`} id={"up-format-mall"}></i>
                                                </div>
                                                <div className="mall__filters__content__checks" id='format-mall-filter' hidden={true}>
                                                    {filters.format.map((filter, i) => (
                                                        <Checkbox
                                                            onChange={selectFilter('format', filter)}
                                                            id={filter}
                                                            key={i}
                                                            checked={isFilterSelected(filtersSelected['format'], filter)}
                                                        >
                                                            <label htmlFor={filter} className={'k-checkbox-label'}>
                                                                {filter}
                                                            </label>
                                                        </Checkbox>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {filters && filters.thickness && !!filters.thickness.length && (
                                        <div className="col-12 col-md-3 col-lg-2 p-0">
                                            <div className="mall__filters__content__section">
                                                <div className='filter-header-mall' onClick={() => {
                                                    [...document.querySelectorAll(`#thickness-mall-filter`)].map(e => {
                                                        e.hidden = !e.hidden
                                                    });
                                                    [...document.querySelectorAll(`#up-thick-mall`)].map(e => {
                                                        if(e.classList.contains("d-none")){
                                                            e.classList.remove("d-none")
                                                        }else{
                                                            e.classList.add("d-none")
                                                        }
                                                    });
                                                    [...document.querySelectorAll(`#down-thick-mall`)].map(e => {
                                                        if (e.classList.contains("d-none")) {
                                                            e.classList.remove("d-none")
                                                        } else {
                                                            e.classList.add("d-none")
                                                        }
                                                    })
                                                }}>
                                                    <span className='filter-mall-name'>{intl.formatMessage({ id: 'thickness' })}</span>
                                                    <i className={`fal fa-chevron-down`} id={"down-thick-mall"}></i>
                                                    <i className={`fal fa-chevron-up d-none`} id={"up-thick-mall"}></i>
                                                </div>
                                                <div className="mall__filters__content__checks" id='thickness-mall-filter' hidden={true}>
                                                    {filters.thickness.map((filter, i) => (
                                                        <Checkbox
                                                            onChange={selectFilter('thickness', filter)}
                                                            id={filter}
                                                            key={i}
                                                            checked={isFilterSelected(filtersSelected['thickness'], filter)}
                                                        >
                                                            <label htmlFor={filter} className={'k-checkbox-label'}>
                                                                {filter} cm
                                                            </label>
                                                        </Checkbox>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                </div>
                <div className='all-filters-mall' style={{ justifyContent: isAnyFilterActive() ? "space-between" : "right" }}>
                    <div className={'tags-clean row'} hidden={!isAnyFilterActive()}>
                        <div className={'col-12'}>
                            <div className={'mall_filters__tags'}>
                                {Object.keys(filtersSelected).map((key) => {
                                    let output = [];
                                    if (filtersSelected[key].length !== 0) {
                                        filtersSelected[key].forEach((item) => {
                                            output.push((
                                                <div className={'mall_filters__tag'}>
                                                    <div className={'mall_filters__tag__text'}>
                                                        <span><Translation id={key} />: {item}</span>
                                                    </div>
                                                    <div className={'mall_filters__tag__close'} onClick={selectFilter(key, item)}>
                                                        X
                                                    </div>
                                                </div>
                                            ));
                                        })
                                        return (<div className={'mall_filters__tags__container'}>{output}</div>);
                                    }

                                })}

                                <span className="clean-filters" onClick={unselectFilters}><Translation id={'clear_all'} /></span>
                            </div>
                        </div>

                    </div>
                    <div className="mall__products__order">
                        <DropDownList
                            label={intl.formatMessage({ id: 'order_by' })}
                            textField="text"
                            dataItemKey="id"
                            data={sortSelected ? [...SORT_OPTIONS, SORT_CLEAR_OPTION] : SORT_OPTIONS}
                            onChange={event => selectedSort(event.target.value.id)}
                            popupSettings={{
                                className: "options-mall-select"
                            }}
                        />
                    </div>
                </div>
                
                <div className={"mall-products-container"}>
                    <div className="mall__products">
                        <div className="mall__products__content">
                            {!isAnyFilterActive() || (isAnyFilterActive() && formattedProducts.length > 0) ? (
                                formattedProducts.map((product, i) => {
                                    // const productLanding = productsLanding.find(prod => prod.productId === product.product_id);
                                    // if (!productLanding) return ''
                                    // productLanding.fromMall = true
                                    //
                                    // {console.log('DRAWING ProductItem')}
                                    return (
                                        <div className={`position-relative ${newVersion ? '' : 'd-flex align-items-center justify-content-center'}`}
                                             key={'mall-item_' + i}
                                        >
                                            {/* {newVersion ? */}
                                                <ProductItem
                                                    // userType={userType}
                                                    // selectedCenter={selectedCenter}
                                                    // index={i}
                                                    // priceList={priceList}
                                                    // userPriceList={userPriceList}
                                                    // commonParamsAnalytics={commonParamsAnalytics}
                                                    // currentIso={currentIso}
                                                    // setSelectedProduct={setSelectedProduct}
                                                    // redirect={redirect}
                                                    // intl={intl}
                                                    // setProductSelectedToAdd={setProductSelectedToAdd}
                                                    product={product}
                                                    sapLandingId={landing.landing.sap_id}
                                                    onZoomClick={onZoomClick}
                                                    {...props}
                                                />
                                              
                                                {/* <ProductItemOld
                                                    userType={userType}
                                                    selectedCenter={selectedCenter}
                                                    index={i}
                                                    priceList={priceList}
                                                    userPriceList={userPriceList}
                                                    commonParamsAnalytics={commonParamsAnalytics}
                                                    currentIso={currentIso}
                                                    setSelectedProduct={setSelectedProduct}
                                                    redirect={redirect}
                                                    intl={intl}
                                                    setProductSelectedToAdd={setProductSelectedToAdd}
                                                    product={product}
                                                    onZoomClick={onZoomClick}
                                                    sapLandingId={landing.landing.sap_id}
                                                    {...props}
                                                /> */}
                                           {/*  } */}
                                        </div>
                                    )
                                })
                            ) : (
                                <div className="not-found">
                                    <p>
                                        <Translation id="no_materials_found" defaultMessage="No materials found according to the search parameters" />
                                    </p>
                                    <Button onClick={unselectFilters}>
                                        <Translation id="remove_filters" defaultMessage="Remove filters" />
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {productSelectedToAdd && (
                <SimpleModal customClass="mall__modal" title={''} size="large" footer={null} onClose={() => setProductSelectedToAdd(null)}>
                    <AddToCartModalItem
                        showToastNotification={showToastNotification}
                        userType={userType}
                        priceList={priceList}
                        userPriceList={userPriceList}
                        lines={lines}
                        commonParamsAnalytics={commonParamsAnalytics}
                        addCartLine={addCartLine}
                        product={productSelectedToAdd}
                        setProductSelectedToAdd={setProductSelectedToAdd}
                        addProductInFetchedProducts={addProductInFetchedProducts}
                        intl={intl}
                        selectedCenter={selectedCenter}
                        sap_landing_id={landing.landing.sap_id}
                    />
                </SimpleModal>
            )}
            {showImageModal && (
                <MallImageViewer product={imageModal} onClose={() => setShowImageModal(false)}/>
                // <div className='img-show-mall'>
                //     <SimpleModal customClass="image-zoom-modal" size="large"
                //         {
                //             //console.log(imageModal.productLanding)
                //         }
                //         <div className='header-image-mall'>
                //             <span className='name-image-mall'>{imageModal.name}</span>
                //             <a href={(imageModal.productLanding !== undefined && imageModal.productLanding.imgFull !== undefined) ? imageModal.productLanding.imgFull : imageModal.detailImage} download target='_blank'>
                //                 <span>Abrir en pestaña nueva</span></a>
                //         </div>
                //
                //         <div className='image-mall-zoom-container'>
                //             <img src={(imageModal.productLanding && imageModal.productLanding.imgFull) ? imageModal.productLanding.imgFull : imageModal.detailImage }  className='zoom-image-mall'/>
                //         </div>
                //     </SimpleModal>
                // </div>
            )}
        </div>
        
    )
}

export default injectIntl(LandingTemplates)
