import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { getBusinesSegmentOptions, getUseOptions, getHasSegmentationV3, getChannelOptionsV3, getTypologyOptionsV3 } from '../../store/segmentation/reducers'
import { getShippingMethod } from '../../store/centers/reducers'
import { connect } from 'react-redux'
import SegmentationTranslation from '../segmentation/SegmentationTranslation'
import { capitalizeFirstLetter } from '../orders/new-order-history/Utils'

const mapStateToProps = state => {
    return {
        businesSegmentOptions: getBusinesSegmentOptions(state),
        useOptions: getUseOptions(state),
        shippingMethod: getShippingMethod(state),
        segmentationV3: getHasSegmentationV3(state),
        channelOptionsV3: getChannelOptionsV3(state),
        typologyOptionsV3: getTypologyOptionsV3(state)
    }
}
class LineSegmentation extends React.Component {
    constructor(props) {
        super(props)
        this.state = { showHelp: false }
    }

    render() {
        let { line, businesSegmentOptions, useOptions, segmentationV3, channelOptionsV3, typologyOptionsV3 } = this.props

        let productBS, businessSegment, channel, subchannel, salesforceId, projectId, typology, use
        if (line.segmentation) {
            if (!segmentationV3) {
                productBS = line.businessSegment === null ? 'NOT' : line.businessSegment
                businessSegment = businesSegmentOptions ? businesSegmentOptions[productBS] : undefined
                channel = businessSegment ? businessSegment.channels[line.channel] : undefined
                subchannel = channel ? channel.subchannels[line.subchannel] : undefined
                typology = subchannel ? subchannel.typologies[line.typology] : undefined
                use = useOptions ? useOptions.find(option => line.use === option.id) : undefined
            } else {
                channel = channelOptionsV3 ? channelOptionsV3[line.channel] : undefined
                // subchannel = channel ? channel.subchannels[line.subchannel] : undefined //subchannel is no longer needed to retrieve the salesforceid
                businessSegment = line.segmentation.businessSegment ? line.segmentation.businessSegment : undefined
                productBS = line.businessSegment === null ? 'NOT' : line.businessSegment
                salesforceId = channel ? channel.salesforceRelations[line.segmentation.salesforceId] : undefined
                projectId = salesforceId && line.segmentation.projectId ? salesforceId.projectOptions[line.segmentation.projectId] : undefined
                typology = typologyOptionsV3 ? typologyOptionsV3.find(option => line.typology === option.id) : undefined
                use = useOptions ? useOptions.find(option => line.use === option.id) : undefined
            }
        }

        return (
            Object.values(line.segmentation).some(value => {
                if (Array.isArray(value)) return value.length > 0
                else return value !== null
            }) &&
            (this.props.showAsList ? (
                <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>
                    {line.segmentation.businessSegment && (
                        <li style={{display: this.props.hideSpans? 'inline-block':'list-item'}}>
                            {!this.props.hideSpans &&
                                <React.Fragment>
                                    <FormattedMessage id="business_segment" defaultMessage="business_segment" />:&nbsp;
                                </React.Fragment>
                            }
                            <strong>
                                {line.segmentation.businessSegment && productBS && <FormattedMessage id={productBS} />}
                            </strong>
                        </li>
                    )}
                    {channel && (
                        <li style={{display: this.props.hideSpans? 'inline-block':'list-item'}}>
                            {this.props.hideSpans ? ' / ' :
                                <React.Fragment>
                                    <FormattedMessage id="channel" defaultMessage="channel" />:&nbsp;
                                </React.Fragment>}
                            <strong>
                                <SegmentationTranslation channel={line.channel} />
                            </strong>
                        </li>
                    )}
                    {subchannel && (
                        <li style={{display: this.props.hideSpans? 'inline-block':'list-item'}}>
                            {this.props.hideSpans ? ' / ':
                                <React.Fragment>
                                    <FormattedMessage id="subchannel" defaultMessage="subchannel" />:&nbsp;
                                </React.Fragment>}
                            <strong>
                                {subchannel && capitalizeFirstLetter(subchannel.name)}
                            </strong>
                        </li>
                    )}

                    {typology && (
                        <li style={{display: this.props.hideSpans? 'inline-block':'list-item'}}>
                            {this.props.hideSpans ? ' / ':
                                <React.Fragment>
                                    <FormattedMessage id="typology" defaultMessage="Typology" />:&nbsp;
                                </React.Fragment>}
                            <strong>
                                {capitalizeFirstLetter(typology.name)}
                            </strong>
                        </li>
                    )}
                    {salesforceId && (
                        <li style={{display: this.props.hideSpans? 'inline-block':'list-item'}}>
                            {this.props.hideSpans ? ' / ':
                                <React.Fragment>
                                    <FormattedMessage id="salesforceId" defaultMessage="Salesforce Id" />:&nbsp;
                                </React.Fragment>}
                            <strong>
                                {capitalizeFirstLetter(salesforceId.name)}
                            </strong>
                        </li>
                    )}
                    {projectId && (
                        <li style={{display: this.props.hideSpans? 'inline-block':'list-item'}}>
                            {this.props.hideSpans ? ' / ':
                                <React.Fragment>
                                    <FormattedMessage id="projectId" defaultMessage="Project Id" />:&nbsp;
                                </React.Fragment>}
                            <strong>
                                {capitalizeFirstLetter(projectId.projectName)}
                            </strong>
                        </li>
                    )}
                    {use && (
                        <li style={{display: this.props.hideSpans? 'inline-block':'list-item'}}>
                            {this.props.hideSpans ? ' / ':
                                <React.Fragment>
                                    <FormattedMessage id="use" defaultMessage="Use" />:&nbsp;
                                </React.Fragment>}
                            <strong>
                                {capitalizeFirstLetter(use.name)}
                            </strong>
                        </li>
                    )}
                </ul>
            ) : (
                <div className="segmentation">
                    <div
                        className="segmentation__button_info"
                        onClick={() => {
                            this.setState({ showHelp: !this.state.showHelp })
                        }}
                    >
                        <i className="fal fa-info-circle" style={{ margin: '5px' }} />
                        <FormattedMessage id="show_segmentation" defaultMessage="Show segmentation" />
                    </div>
                    <div className={`info ${!this.state.showHelp ? 'info--hidden' : ''}`}>
                        {line.segmentation.businessSegment && (
                            <span>
                                <strong>
                                    <FormattedMessage id="business_segment" defaultMessage="business_segment" />
                                    :&nbsp;
                                </strong>
                                {line.segmentation.businessSegment && <FormattedMessage id={productBS} />}
                            </span>
                        )}
                        {channel && (
                            <span>
                                <strong>
                                    <FormattedMessage id="channel" defaultMessage="channel" />
                                    :&nbsp;
                                </strong>
                                <SegmentationTranslation channel={line.channel} />
                            </span>
                        )}
                        {subchannel && (
                            <span>
                                <strong>
                                    <FormattedMessage id="subchannel" defaultMessage="subchannel" />
                                    :&nbsp;
                                </strong>
                                {subchannel && subchannel.name}
                            </span>
                        )}

                        {typology && (
                            <span>
                                <strong>
                                    <FormattedMessage id="typology" defaultMessage="Typology" />
                                    :&nbsp;
                                </strong>
                                {typology.name}
                            </span>
                        )}
                        {salesforceId && (
                            <span>
                                <strong>
                                    <FormattedMessage id="salesforceId" defaultMessage="Salesforce Id" />
                                    :&nbsp;
                                </strong>
                                {salesforceId.name}
                            </span>
                        )}
                        {projectId && (
                            <span>
                                <strong>
                                    <FormattedMessage id="projectId" defaultMessage="Project Id" />
                                    :&nbsp;
                                </strong>
                                {projectId.projectName}
                            </span>
                        )}
                        {use && (
                            <span>
                                <strong>
                                    <FormattedMessage id="use" defaultMessage="Use" />
                                    :&nbsp;
                                </strong>
                                {use.name}
                            </span>
                        )}
                    </div>
                </div>
            ))
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(LineSegmentation)
)
