export const initState = {
    employeeId: null
}

const bigAccount = (state = initState, action) => {
    //please define error messages translations at floatingAlert component
    switch (action.type) {
        case 'SET_EMPLOYEE_ID':
            return Object.assign({}, state, { employeeId: action.employeeId })
        case 'LOGOUT':
            return Object.assign({}, state, { employeeId: null })
        default:
            return state
    }
}

export const getEmployeeId = state => {
    return state.bigAccount.employeeId
}
export default bigAccount
