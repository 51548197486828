import * as api from '../api'
import {byProductId} from '../products/reducers'
import {getSelectedCenter} from '../centers/reducers'
import {getIsLogged, getToken, getUserType} from '../login/reducers'
import {canViewCrosselling} from '../login/reducers'
import {fetchBoughtTogetherProducts} from "../api";


export const fetchCrossSelling = productId => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_PRODUCT_CROSSELLING_REQUEST',
        productId
    })
    const state = getState()
    const centerId = state.centers.selectedCenter ? state.centers.selectedCenter : 'near'

    return api
        .fetchCrossSelling(centerId, productId)
        .then(response => {
            dispatch({ type: 'FETCH_PRODUCT_CROSSELLING_SUCCESS', crossSelling: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_PRODUCT_CROSSELLING_FAILURE' })
            // api.errorHandler(dispatch, error.response)
        })
}

export const fetchBoughtTogether = productId => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_BOUGHT_TOGETHER_REQUEST',
        productId
    })
    const state = getState()
    const centerId = state.centers.selectedCenter ? state.centers.selectedCenter : 'near'
    const token = getToken(state)

    return api
        .fetchBoughtTogetherProducts(token, centerId, productId)
        .then(response => {
            dispatch({ type: 'FETCH_BOUGHT_TOGETHER_SUCCESS', boughtTogether: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_BOUGHT_TOGETHER_FAILURE' })
            // api.errorHandler(dispatch, error.response)
            return Promise.reject()
        })
}

export const fetchRelatedProducts = productId => (dispatch, getState) => {
    const isLogged  = getIsLogged(getState())
    return isLogged ? dispatch(fetchBoughtTogether(productId)) : dispatch(fetchCrossSelling(productId))
}

export const fetchCrossSellingCheckOut = productIds => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_PRODUCT_CROSSELLING_CHECKOUT_REQUEST',
        productIds
    })
    const state = getState()
    const centerId = state.centers.selectedCenter ? state.centers.selectedCenter : 'near'
    const token = getToken(state)

    return api
        .fetchCrossSellingCheckOut(token, centerId, productIds)
        .then(response => {
            dispatch({ type: 'FETCH_PRODUCT_CROSSELLING_CHECKOUT_SUCCESS', crossSelling: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_PRODUCT_CROSSELLING_CHECKOUT_FAILURE' })
            // api.errorHandler(dispatch, error.response)
        })
}

export const fetchCartCrossSelling = productId => (dispatch, getState) => {

    const state = getState()
    const product = byProductId(state, productId)

    if (!canViewCrosselling(state, productId) || !product || product.type != 'TABLA') return Promise.reject()
    const token = getToken(state)

    dispatch({
        type: 'FETCH_ADD_TO_CART_CROSSELLING_REQUEST',
        productId
    })
    const centerId = state.centers.selectedCenter
    return api
        .fetchCartCrossSelling(token, centerId, productId)
        .then(response => {
            dispatch({ type: 'FETCH_ADD_TO_CART_CROSSELLING_SUCCESS', crossSelling: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_ADD_TO_CART_CROSSELLING_FAILURE' })
            // api.errorHandler(dispatch, error.response)
        })
}

export const fetchAlternatives = productId => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_PRODUCT_ALTERNATIVES_REQUEST',
        productId
    })
    const centerId = getState().centers.selectedCenter ? getState().centers.selectedCenter : 'near'

    return api
        .fetchAlternatives(centerId, productId)
        .then(response => {
            dispatch({ type: 'FETCH_PRODUCT_ALTERNATIVES_SUCCESS', alternatives: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_PRODUCT_ALTERNATIVES_FAILURE' })
            // api.errorHandler(dispatch, error.response)
        })
}

export const fetchCartSimilarProducts = (productIds, quantities) => (dispatch, getState) => {
    dispatch({ type: 'FETCH_CART_SIMILAR_PRODUCTS_REQUEST' })

    return api
        .fetchCartSimilarProducts(getToken(getState()), getSelectedCenter(getState()), productIds, quantities)
        .then(response => {
            dispatch({ type: 'FETCH_CART_SIMILAR_PRODUCTS_SUCCESS', products: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_CART_SIMILAR_PRODUCTS_FAILURE' })
            // api.errorHandler(dispatch, error.response)
            return Promise.reject()
        })
}

export const fetchCartComplementaryProducts = (productIds, quantities) => (dispatch, getState) => {
    dispatch({ type: 'FETCH_CART_COMPLEMENTARY_PRODUCTS_REQUEST' })

    return api
        .fetchCartComplementaryProducts(getToken(getState()), getSelectedCenter(getState()), productIds, quantities)
        .then(response => {
            dispatch({ type: 'FETCH_CART_COMPLEMENTARY_PRODUCTS_SUCCESS', products: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_CART_COMPLEMENTARY_PRODUCTS_FAILURE' })
            // api.errorHandler(dispatch, error.response)
            return Promise.reject()
        })
}

export const hideCrossSellingModal = () => (dispatch, getState) => {
    
}
