import React from 'react'
import Translation from '../../../global/Translation'

export default class DisplayNewIndicator extends React.Component {
    render() {
        return (
            <div className="new-indicator">
                <Translation id="new" defaultMessage="New" />
            </div>
        )
    }
}
