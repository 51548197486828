import { connect } from 'react-redux'
import * as ui from '../../store/ui/actions'
import { getCustomers, getReservations, getReservationsLoading } from '../../store/reservations/reducers'
import { fetchCustomers, assignCustomer, fetchReservations, rejectReservation } from '../../store/reservations/actions'
import Reservations from './Reservations'
import { withRouter } from 'react-router-dom'

const mapStateToProps = (state, { match }) => {
    return {
        reservations: getReservations(state),
        customers: getCustomers(state),
        unassigned: match.params.unassigned,
        key: match.params.unassigned,
        isLoading: getReservationsLoading(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(ui.closeImpersonateModal())
        },
        fetchReservations: unassigned => {
            dispatch(fetchReservations(unassigned))
        },
        fetchCustomers: () => {
            dispatch(fetchCustomers())
        },
        assignCustomer: (shippingAddressId, reservation, date, po, comments) => {
            dispatch(assignCustomer(shippingAddressId, reservation, date, po, comments))
        },
        rejectReservation: reservation => {
            dispatch(rejectReservation(reservation))
        }
    }
}

const ReservationsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Reservations))

export default ReservationsContainer
