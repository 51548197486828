import React, { useEffect, useState } from 'react'
import FiltersModal from "../../../global/Filters/FiltersModal";
import Translation from "../../../global/Translation";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import {getCenters, getIsIkeaCenter} from "../../../../store/centers/reducers";
import {injectIntl, defineMessages} from "react-intl";
import {connect} from "react-redux";
import FilterTags from "../../../global/Filters/FilterTags";
import debounce from "lodash/debounce";
import {cloneDeep} from "lodash";

defineMessages({
    by_estimated_delivery_date: {
        id: 'by_estimated_delivery_date',
        description: 'by_estimated_delivery_date',
        defaultMessage: 'By estimated delivery date'
    },
    requested_date: {
        id: 'requested_date',
        description: 'requested_date',
        defaultMessage: 'Requested delivery date'
    },
    status_type_filter: {
        id: 'status_type_filter',
        description: 'status_type_filter',
        defaultMessage: 'By status'
    },
    order_confirmed: {
        id: 'order_confirmed',
        description: 'order_confirmed',
        defaultMessage: 'Order confirmed'
    },
    dist_center_filter: {
        id: 'dist_center_filter',
        description: 'dist_center_filter',
        defaultMessage: 'Distribution center'
    },
    order_canceled: {
        id: 'order_canceled',
        description: 'order_canceled',
        defaultMessage: 'Distribution center'
    },
    shipping_type_filter: {
        id: 'shipping_type_filter',
        description: 'shipping_type_filter',
        defaultMessage: 'Shipment type'
    },
    filters: {
        id: 'filters',
        description: 'filters',
        defaultMessage: 'Filters'
    }
})

let debSearch

const OrdersHistoryFilter = props => {

    const centerOptions = props.centers.reduce((p, v) => (
        {...p, ['_' + v.centerId]: {label: v.name, isSelected: false, value: v.centerId} }),
        {empty: {label: props.intl.formatMessage({id: 'view_all'}), value: false, isSelected: true}}
    )

    const statusTypeOptions = {
        empty: {label: props.intl.formatMessage({id: 'view_all'}), value: false, isSelected: true},
        order_created: {label: props.intl.formatMessage({ id: 'ORDER_CREATED' }), value: 'order_created', isSelected: false },
        order_confirmed: {label: props.intl.formatMessage({ id: 'order_confirmed' }), value: 'order_confirmed', isSelected: false },
        // partially_delivered: {label: props.intl.formatMessage({ id: 'order_status_partially_delivered' }), value: 'partially_delivered', isSelected: false },
        cancelled: {label: props.intl.formatMessage({ id: 'order_canceled' }), value: 'cancelled', isSelected: false },
        // delivered: {label: props.intl.formatMessage({ id: 'order_status_delivered' }), value: 'delivered', isSelected: false }
    }

    const [search, setSearch] = useState('')

    const dateFilterMapper = {
        output: {
            startDate: null,
            endDate: null,
            typeDate: 'order_date'
        },
        prevOutput: {
            startDate: null,
            endDate: null,
            typeDate: 'order_date'
        },
        onChange: {
            changeStartDate: selectedOption => dateFilterMapper.output.startDate = selectedOption,
            changeEndDate: selectedOption => dateFilterMapper.output.endDate = selectedOption,
            changeTypeDate: selectedOption => dateFilterMapper.output.typeDate = selectedOption,
        }
    }

    const formatDateLabel = (output) => {
        const startLabel = `${output.startDate && !output.endDate ? props.intl.formatMessage({ id: 'from'}) + ' ' : ''}${output.startDate ? output.startDate.format('L') : ''}`
        const endLabel = `${output.startDate && output.endDate ? ' - ' : ''}${!output.startDate && output.endDate ? props.intl.formatMessage({ id: 'to'}) + ' ' : ''}${output.endDate ? output.endDate.format('L') : ''}`

        const label = startLabel + endLabel
        return label === '' ? 'all' : label
    }

    const DateFilter = (mapper, _filters) => {

        const startMaxDate = mapper.output.endDate ? mapper.output.endDate : moment().add(12, 'month')
        const endMaxDate = props.isIkeaCenter ? moment(mapper.output.startDate).add(3, 'month')
            : //RESTO DE CASOS 12 MESES
            moment(mapper.output.startDate).add(12, 'month')

        return <React.Children mapper={mapper}>

            <div className="order__shipping__date__section">
                <div className="order__shipping__date__section--select">
                    <Select
                        options={[
                            {
                                label: <Translation id="order_date" defaultMessage="Order date"/>,
                                value: 'order_date'
                            },
                            {
                                label: <Translation id="requested_delivery_date"
                                                    defaultMessage="Requested delivery date"/>,
                                value: 'requested_date'
                            }
                        ]}
                        value={mapper.output.typeDate}
                        clearable={false}
                        onChange={selectedOption => {
                            mapper.onChange.changeTypeDate(selectedOption, 'typeDate')
                            _filters.label = formatDateLabel(mapper.output) ? props.intl.formatMessage({ id: selectedOption.value }) : null
                        }}
                    />
                </div>

                <div className="order__shipping__date__section--dates">
                    <div className="from">
                        <label htmlFor='from_order_date'>
                            <Translation id="from" defaultMessage="From"/>
                        </label>
                        <DatePicker
                            id={'from_order_date'}
                            selected={mapper.output.startDate}
                            selectsStart={true}
                            startDate={mapper.output.startDate}
                            endDate={mapper.output.endDate}
                            maxDate={startMaxDate}
                            onChange={selectedOption => {
                                mapper.onChange.changeStartDate(selectedOption, 'startDate')
                                _filters.filters.custom_date_filter.label = formatDateLabel(mapper.output)
                                _filters.filters.custom_date_filter.isSelected = false
                                //console.log('START DATE ' , _filters.filters.custom_date_filter.isSelected)
                            }}
                            autoComplete="off"
                            onKeyDown={e => e.preventDefault()}
                            showMonthDropdown
                            showYearDropdown
                            isClearable={true}
                            className="form-control form-control-sm date customDatePicker"
                        />
                    </div>
                    <div className="to">
                        <label htmlFor='to_order_date'>
                            <Translation id="to" defaultMessage="To"/>
                        </label>
                        <DatePicker
                            id={'to_order_date'}
                            selected={mapper.output.endDate}
                            selectsEnd
                            startDate={mapper.output.startDate}
                            endDate={mapper.output.endDate}
                            minDate={mapper.output.startDate}
                            maxDate={endMaxDate}
                            onChange={selectedOption => {
                                mapper.onChange.changeEndDate(selectedOption, 'endDate')
                                _filters.filters.custom_date_filter.label = formatDateLabel(mapper.output)
                                _filters.filters.custom_date_filter.isSelected = !!(mapper.output.startDate && mapper.output.endDate)
                            }}
                            autoComplete="off"
                            onKeyDown={e => e.preventDefault()}
                            showMonthDropdown
                            showYearDropdown
                            isClearable={true}
                            className="form-control form-control-sm date customDatePicker"
                        />
                    </div>
                </div>
            </div>
        </React.Children>
    }

    const initialFilters = {
        by_date_filter: {
            label: props.intl.formatMessage({ id: dateFilterMapper.output.typeDate }),
            id: 'by_date_filter',
            filters: {
                custom_date_filter : {
                    id: 'custom_date_filter',
                    label: 'all',
                    defaultLabel: 'all',
                    value: cloneDeep(dateFilterMapper.output),
                    mapper: cloneDeep(dateFilterMapper),
                    custom: DateFilter,
                    isSelected: false,
                    defaultValue: {
                        startDate: null,
                        endDate: null,
                        typeDate: 'order_date'
                    },
                    getIsSelected: (output) => !!(output.startDate || output.endDate)
                }
            }
        },
        status_type_filter: {
            label: props.intl.formatMessage({ id: 'status_type_filter' }),
            id: 'status_type_filter',
            filters: statusTypeOptions
        },
        dist_center_filter: {
            label: props.intl.formatMessage({ id: 'dist_center_filter' }),
            id: 'dist_center_filter',
            filters: centerOptions
        }
    }

    const [filters, setFilters] = useState(cloneDeep(initialFilters))
    const [selections, setSelections] = useState({})

    const changeDateFilter = type => {
        let startDate = moment()
        switch (type) {
            case 'today':
                filters.by_date_filter.filters.custom_date_filter.value.startDate = startDate
                filters.by_date_filter.filters.custom_date_filter.value.typeDate = 'order_date'
                filters.by_date_filter.label = props.intl.formatMessage({ id: 'order_date' })
                break
            case 'last_week':
                startDate = startDate.add(-7, 'days')
                filters.by_date_filter.filters.custom_date_filter.value.startDate = startDate
                filters.by_date_filter.filters.custom_date_filter.value.typeDate = 'order_date'
                filters.by_date_filter.label = props.intl.formatMessage({ id: 'order_date' })
                break
            case 'last_month':
                startDate = startDate.add(-30, 'days')
                filters.by_date_filter.filters.custom_date_filter.value.startDate = startDate
                filters.by_date_filter.filters.custom_date_filter.value.typeDate = 'order_date'
                filters.by_date_filter.label = props.intl.formatMessage({ id: 'order_date' })
                break
            case 'last_6_months':
                startDate = startDate.add(-180, 'days')
                filters.by_date_filter.filters.custom_date_filter.value.startDate = startDate
                filters.by_date_filter.filters.custom_date_filter.value.typeDate = 'order_date'
                filters.by_date_filter.label = props.intl.formatMessage({ id: 'order_date' })
                break
            case 'all':
                break
        }

        const endDate = type !== 'all' ? moment() : null
        filters.by_date_filter.filters.custom_date_filter.label = type
        filters.by_date_filter.filters.custom_date_filter.isSelected = type !== 'all'
        filters.by_date_filter.filters.custom_date_filter.value.endDate = endDate

        // Actualizar mappers
        filters.by_date_filter.filters.custom_date_filter.mapper.output.startDate = startDate
        filters.by_date_filter.filters.custom_date_filter.mapper.output.endDate = endDate
        filters.by_date_filter.filters.custom_date_filter.mapper.prevOutput.startDate = startDate
        filters.by_date_filter.filters.custom_date_filter.mapper.prevOutput.endDate = endDate
        filters.by_date_filter.filters.custom_date_filter.mapper.output.typeDate = 'order_date'
        filters.by_date_filter.filters.custom_date_filter.mapper.prevOutput.typeDate = 'order_date'

        setFilters(Object.assign({}, filters))
    }

    const dateFilterActive = type => filters && filters.by_date_filter && filters.by_date_filter.filters.custom_date_filter.label === type

    useEffect(() => {
        const selections = Object.entries(filters).reduce(
            (p, [k, v]) => ({
                ...p, [k]: Object.entries(v.filters).reduce((_p, [_k, _v]) => ({..._p, [_k]: _v.isSelected}), {})
            }), {}
        )
        const selected = {}
        Object.entries(selections).forEach(([k, v]) => {
            const positives = Object.keys(v).filter(_k => !!v[_k])
            positives.forEach(v => {
                if (filters[k] && filters[k].filters[v].value) {
                    selected[k] = {id:v, value: filters[k].filters[v].value}}
            })
        })

        setSelections(selected)
    }, [filters])

    useEffect(() => {
        if (debSearch) {
            debSearch.cancel()
        }
        debSearch = debounce(() => {
            if ((search && search.length > 2) || !search || search.length === 0) {
                props.onChange({search, selections})
            }
        }, 1000)

        debSearch()
    }, [search, selections])


    const searchBar = (
        <input
            type="text"
            data-qa="query"
            placeholder={props.intl.formatMessage({ id: 'search' })}
            className="form-control"
            value={search}
            onChange={e => setSearch(e.target.value)}
            gtm-label="ec_historial_pedidos_barra_busqueda"
        />
    )

    const onClean = () => {
        if (filters !== initialFilters) {
            setFilters(cloneDeep(initialFilters))
        }
    }

    useEffect(() => {
        changeDateFilter('last_month')
    }, [])
    
    return (
        <React.Fragment>
            <div className="header__filters__heading">
                <h4><Translation id="filter_by_order_date" defaultMessage="Filter by order date" />:</h4>
            </div>
            <div className="header__filters">
                <nav className="header__filters__buttons">
                    <div className={`order-walkthrough-filter-quick header__filters__buttons--btn ${dateFilterActive('today') ? 'active' : ''}`}>
                        <button onClick={() => changeDateFilter('today')}>
                            <Translation id={'today'} defaultMessage={'Today'}/>
                        </button>
                    </div>
                    <div className={`header__filters__buttons--btn ${dateFilterActive('last_week') ? 'active' : ''}`}>
                        <button onClick={() => changeDateFilter('last_week')}>
                            <Translation id={'last_week'} defaultMessage={'Last week'}/>
                        </button>
                    </div>
                    <div className={`header__filters__buttons--btn ${dateFilterActive('last_month') ? 'active' : ''}`}>
                        <button onClick={() => changeDateFilter('last_month')}>
                            <Translation id={'last_month'} defaultMessage={'Last month'}/>
                        </button>
                    </div>
                    <div className={`header__filters__buttons--btn ${dateFilterActive('last_6_months') ? 'active' : ''}`}>
                        <button onClick={() => changeDateFilter('last_6_months')}>
                            <Translation id={'last_6_months'} defaultMessage={'Last 6 months'}/>
                        </button>
                    </div>
                    <div className={`header__filters__buttons--btn ${dateFilterActive('all') ? 'active' : ''}`}>
                        <button onClick={() => changeDateFilter('all')}>
                            <Translation id={'all'} defaultMessage={'All'}/>
                        </button>
                    </div>
                    <div className="order-walkthrough-more-filter-desktop header__filters__buttons--btn-more">
                        <FiltersModal
                            title={props.intl.formatMessage({ id: 'filters' })}
                            filters={filters}
                            customClassName="order__shipping__filter__modal"
                            onChange={_filters => setFilters(_filters)}
                            onClean={onClean}
                            gtmLabel="ec_historial_pedidos_mas_filtros"
                            gtmLabelModal="ec_historial_pedidos_filtros_popup"
                            gtmLabelApplyFilters="ec_historial_pedidos_filtros_aplicar"
                        />
                    </div>
                </nav>
                {/* Desktop search */}
                <div className="filters__search__desktop">
                    <div className="filters__search__desktop--input">{searchBar}</div>
                </div>
            </div>
            {/* Responsive search */}
            <div className="filters__search__responsive">
                <div className="flex__container">
                    <div className="flex__container--input">{searchBar}</div>
                    {/* For mobile screen */}
                    <div className="order-walkthrough-more-filter-responsive flex__container--btn">
                        <FiltersModal
                            title={props.intl.formatMessage({ id: 'filters' })}
                            filters={filters}
                            customClassName="order__shipping__filter__modal"
                            onChange={_filters => setFilters(_filters)}
                            onClean={onClean}
                            gtmLabel="ec_historial_pedidos_mas_filtros"
                            gtmLabelModal="ec_historial_pedidos_filtros_popup"
                            gtmLabelApplyFilters="ec_historial_pedidos_filtros_aplicar"
                        />
                    </div>
                </div>
            </div>

            <div className="order__history__header--filter-tags">
                <FilterTags
                    filters={filters}
                    onChange={_filters => setFilters(_filters)}
                    onClean={onClean}
                />
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        isIkeaCenter: getIsIkeaCenter(state),
        centers: getCenters(state),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(OrdersHistoryFilter)
)