import React from 'react'
import Translation from '../global/Translation'

import LocalizedLink from '../global/LocalizedLink'
import { injectIntl } from 'react-intl'

import Button from '../global/Button'
import MiniCartSummary from './MiniCartSummary'
import IncentivesContainer from '../incentives/IncentivesContainer'
import confirm from '../global/confirm'
import GroupInputVoucher from '../checkout/global/GroupInputVoucher'
import mallDiscount from '../../assets/img/logos/mall-discount.svg'
import MallIncentivesContainer from '../incentives/MallIncentivesContainer'
import { connect } from 'react-redux'
import { showBannerOldVersion } from '../../store/ui/reducers'
import SmallBannerRocket from '../banner-rocket/SmallBannerRocket'

const mapStateToProps = state => {
    return {
        showBanner: showBannerOldVersion(state),
    }
}

class MiniCart extends React.Component {
    componentDidMount() {
        this.props.fetchCart(this.props.selectedCenter)
    }
    confirmEmptyCart = () => {
        confirm(this.props.intl.formatMessage({ id: 'CONFIRM' })).then(
            confirm => {
                this.props.emptyCart()
            },
            cancel => {}
        )
    }
    canViewIncentives = () => {
        if (!this.props.totalQty > 0) return false
        else if (this.props.isIkeaCenter) return false
        else if (this.props.isMktoolsCart && this.props.userType === 'CUSTOMER') return false
        else return true
    }
    render() {
        const {
            lines,
            totalQty,
            total,
            onDelete,
            changeCartLineQty,
            show,
            onClose,
            shippingMethod,
            onOpenBundleContent,
            editSegmentation,
            loadThisSegmentation,
            showSegmentationModal,
            changeCartLineSegmentation,
            coin,
            isIkeaCenter,
            languageDir,
            calculatingLinesPrice,
            canViewPricesPhase2,
            totalCoin,
            lang,
            newVersion,
            showBanner
        } = this.props
        if (!shippingMethod || !lines) {
            return ''
        }
        //console.log(newVersion,'new version on cart')
        const buttons = (
            <div className="row no-gutters">
                <div className="col-12 col-md-4 p-1">
                    <Button onClick={() => this.confirmEmptyCart()}>
                        <Translation id="empty" defaultMessage="Empty" />
                    </Button>
                </div>

                <div className="col-12 col-md-8 p-1">
                    {/* {newVersion ? (
                        <LocalizedLink routeId="ROUTE_CART_SUMMARY">
                            <Button inverted datacy="modal_minicarrito__go_checkout">
                                <Translation id="checkout" defaultMessage="Checkout" />
                            </Button>
                        </LocalizedLink>
                    ) : ( */}
                        <LocalizedLink routeId="ROUTE_CHECKOUT">
                            <Button inverted datacy="modal_minicarrito__go_checkout">
                                <Translation id="checkout" defaultMessage="Checkout" />
                            </Button>
                        </LocalizedLink>
                    {/* )} */}
                </div>
            </div>
        )

        return (
            <div>
                <div className="modal-overlay" style={{ display: show ? 'block' : 'none' }} onClick={onClose} />
                <div id="mini-cart-container" style={{ display: show ? 'block' : 'none' }}>
                    <div id="mini-cart">
                        <header className="container-fluid">
                            <div className="row">
                                <div className="col-9 my-auto"></div>
                                <div className="col-3 my-auto text-right">
                                    <div className="bt-close" onClick={onClose}>
                                        <i className="fal fa-times" />
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div>
                            <MiniCartSummary
                                {...this.props}
                                lines={lines}
                                totalQty={totalQty}
                                total={total}
                                totalCoin={totalCoin}
                                onDelete={onDelete}
                                editSegmentation={editSegmentation}
                                changeCartLineQty={changeCartLineQty}
                                shippingMethod={shippingMethod}
                                onOpenBundleContent={onOpenBundleContent}
                                loadThisSegmentation={loadThisSegmentation}
                                showSegmentationModal={showSegmentationModal}
                                changeCartLineSegmentation={changeCartLineSegmentation}
                                coin={coin}
                                isIkeaCenter={isIkeaCenter}
                                calculatingLinesPrice={calculatingLinesPrice}
                                canViewPricesPhase2={canViewPricesPhase2}
                                lang={lang}
                            />
                        </div>
                        <div className="incentive-wrapper">{this.canViewIncentives() && <IncentivesContainer />}</div>
                        {this.props.isMallCartForContainer && <MallIncentivesContainer />}
                        {lines && lines.length > 0 && (
                            <div className="container-fluid">
                                <GroupInputVoucher />
                                {this.props.canViewRocketPromotion && <SmallBannerRocket market={this.props.canViewRocketPromotion} />}
                            </div>
                        )}
                        <footer className="container-fluid"> {totalQty > 0 && buttons}</footer>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(MiniCart)
)