import React from 'react'
import Translation from '../global/Translation'

import LocalizedLink from '../global/LocalizedLink'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { getIsIkeaCenter, getIsLowesCenter, getIsMkToolsCenter } from '../../store/centers/reducers'
import {
    getIsCampaignsPilotUser, getIsDeviatedAdmin,
    getIsMallAdmin,
    getIsMktoolsAdmin,
    getUserAuthorization,
    canViewPreorder
} from '../../store/login/reducers'
import {withMsal} from '@azure/msal-react'
import { connect } from 'react-redux'
import { logout } from '../../store/login/actions'
import iconLogoutV2 from '../../assets/img/icons/logoutv2.svg'
import { getEmployeeId } from '../../store/big-account/reducers'

const mapStateToProps = state => {
    return {
        hasPreOrder: canViewPreorder(state),
        isMktoolsCenter: getIsMkToolsCenter(state),
        isMktoolsAdmin: getIsMktoolsAdmin(state),
        isMallAdmin: getIsMallAdmin(state),
        isUserMallAuthorized: getUserAuthorization(state),
        isCampaignAdmin: getIsCampaignsPilotUser(state),
        isDeviatedAdmin: getIsDeviatedAdmin(state),
        isLowesCenter: getIsLowesCenter(state),
        isIkeaCenter: getIsIkeaCenter(state),
        employeeId: getEmployeeId(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => {
            dispatch(logout())
        }
    }
}

const ProfileNavBar = ({
    match,
    userType,
    canRouteConfiguration,
    canRouteOrders,
    canRouteTransfers,
    canRouteDirectSlabAccess,
    isLowesCenter,
    canRouteOfferStatusHistory,
    isIkeaCenter,
    isMktoolsCenter,
    newVersion,
    msalContext,
    name,
    email,
    username,
   employeeId,
    location,
    intl,
    onLogout,
    hasPreOrder
}) => {
    const links = withButtons => (
        <div className='d-flex flex-column menu-configuration'>
            
            <div className='d-flex' style={{padding: '0.5rem 0', fontWeight: '300'}}>
                <div className='mr-3'>
                    <i className="fas fa-user" />
                </div>
                <div>
                    <span>
                        
                        {name} {employeeId && <strong>{employeeId}</strong>}
                        <span className='txt-username' style={{float: "none"}}>
                            {userType === 'SHOP' || userType === 'LOWES' ? email : username}
                        </span>
                    </span>
                </div>
            </div>
            
            {canRouteConfiguration && (
                <LocalizedLink
                    className={`${withButtons ? 'configuration-btn btn btn-secondary' : ''} ${location.pathname.indexOf('configuration') !== -1  ? 'active' : ''} linkMenu`}
                    routeId="ROUTE_PROFILE"
                    params={{ filter: 'configuration', open: '' }}
                >
                    {isLowesCenter ? <Translation id="store_profile" defaultMessage="Store Profile" /> : <Translation id="configuration" defaultMessage="Configuration" />}
                </LocalizedLink>
            )}
            {canRouteOrders && (
                <LocalizedLink
                    className={`${withButtons ? 'configuration-btn btn btn-secondary' : ''} ${location.pathname.indexOf('orders') !== -1  && location.pathname.indexOf('recurring-orders') === -1 && location.pathname.indexOf('pre-orders-cts') === -1 ? 'active' : ''} linkMenu`}
                    routeId="ROUTE_PROFILE"
                    params={{ filter: 'orders', open: '' }}
                >
                    <Translation id="orders_history" defaultMessage="Orders history" />
                </LocalizedLink>
            )}
            {canRouteOrders && userType === 'CUSTOMER' && isIkeaCenter && (
                <LocalizedLink
                    className={`${withButtons ? 'configuration-btn btn btn-secondary' : ''} ${location.pathname.indexOf('orders') !== -1 && match.params.open === 'open' ? 'active' : ''} linkMenu`}
                    routeId="ROUTE_PROFILE"
                    params={{ filter: 'orders', open: 'open' }}
                >
                    <Translation id="open_orders" defaultMessage="Open orders" />
                </LocalizedLink>
            )}
            {userType === 'CUSTOMER' && hasPreOrder && (
                    <LocalizedLink
                    className={`${withButtons ? 'configuration-btn btn btn-secondary' : ''} ${location.pathname.indexOf('pre-orders-cts') !== -1 ? 'active' : ''} linkMenu`}
                    routeId="ROUTE_PROFILE" 
                    params={{ filter: 'pre-orders-cts', open: '' }}
                >    
                    <Translation id="pre_orders_cts" defaultMessage="Pre Orders CTS" />   
                </LocalizedLink>
            )}
            {canRouteOfferStatusHistory && (
                <LocalizedLink
                    className={`${withButtons ? 'configuration-btn btn btn-secondary' : ''} ${location.pathname.indexOf('offers') !== -1 ? 'active' : ''} linkMenu`}
                    routeId="ROUTE_PROFILE"
                    params={{ filter: 'offers', open: '' }}
                >
                    <Translation id="quotes" defaultMessage="Quotes" />
                </LocalizedLink>
            )}
            {canRouteTransfers && (
                <LocalizedLink
                    className={`${withButtons ? 'configuration-btn btn btn-secondary' : ''} ${location.pathname.indexOf('transers') !== -1 ? 'active' : ''} linkMenu`}
                    routeId="ROUTE_PROFILE"
                    params={{ filter: 'transfers', open: '' }}
                >
                    <Translation id="transfer_orders" defaultMessage="Transfer orders" />
                </LocalizedLink>
            )}
            {/*{canRouteDirectSlabAccess && (*/}
            {/*    <LocalizedLink*/}
            {/*        className={`${withButtons ? 'configuration-btn btn btn-secondary' : ''} ${location.pathname.indexOf('direct-slab-access') !== -1 ? 'active' : ''} linkMenu`}*/}
            {/*        routeId="ROUTE_PROFILE"*/}
            {/*        params={{ filter: 'direct-slab-access', open: '' }}*/}
            {/*    >*/}
            {/*        <Translation id="direct_slab_access" defaultMessage="Direct slab access" />*/}
            {/*    </LocalizedLink>*/}
            {/*)}*/}
            {userType === 'CUSTOMER' && !isMktoolsCenter && !isIkeaCenter && !isLowesCenter && (
                <LocalizedLink
                    className={`${withButtons ? 'configuration-btn btn btn-secondary' : ''} ${location.pathname.indexOf('recurring-orders') !== -1 ? 'active' : ''} linkMenu`}
                    routeId="ROUTE_PROFILE"
                    params={{ filter: 'recurring-orders', open: '' }}
                >
                    <Translation id="recurring_orders_access" defaultMessage="Recurring orders access" />
                </LocalizedLink>
            )}
            <LocalizedLink
                className={`${withButtons ? 'configuration-btn btn btn-secondary' : ''} ${location.pathname.indexOf('segmentation') !== -1 ? 'active' : ''} linkMenu`}
                routeId="ROUTE_PROFILE"
                params={{ filter: 'segmentation', open: '' }}
            >
                <Translation id="segmentation" defaultMessage="Segmentation" />
            </LocalizedLink>
            <LocalizedLink
                className={`${withButtons ? 'configuration-btn btn btn-secondary' : ''} ${location.pathname.indexOf('products-favourite') !== -1 ? 'active' : ''} linkMenu`}
                routeId="ROUTE_PROFILE"
                params={{ filter: 'products-favourite', open: '' }}
            >
                <Translation id="products_favourite" defaultMessage="Favourite products" />
            </LocalizedLink>

            <span className={`${withButtons ? 'configuration-btn btn btn-secondary' : ''} linkMenu linkLogout`} onClick={() => {
                if (msalContext.accounts && msalContext.accounts[0]) {
                    msalContext.instance.logoutRedirect()
                }
                onLogout()
            }}
            >
                <div className='d-flex'>
                    <div className="icon" style={{marginRight: '8px'}}>
                        <img alt="" src={iconLogoutV2} aria-hidden="true" />
                    </div>
                    <Translation id="logout" defaultMessage="Logout" />
                </div>
            </span>
        </div>
    )

    return (
        <div className="row profile-nav-bar">
            <div className="col-12">
                <div className="without-buttons d-sm-none d-flex justify-content-around flex-wrap" role="group">
                    {links(false)}
                </div>
                <div className="btn-group d-none d-sm-block" role="group">
                    {links(false)}
                </div>
            </div>
        </div>
    )
}

export default withMsal(
  injectIntl(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileNavBar))))

