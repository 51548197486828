import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { showingNewVersionModalExperience } from '../../store/ui/reducers'
import * as ui from '../../store/ui/actions'
import { connect } from 'react-redux'
import redFace from '../../assets/img/red_face.png'
import orangeFace from '../../assets/img/orange_face.png'
import yellowFace from '../../assets/img/yellow_face.png'
import lightgreenFace from '../../assets/img/lightgreen_face.png'
import darkgreenFace from '../../assets/img/darkgreen_face.png'
import Translation from '../global/Translation'
import $ from 'jquery'
import Button from '../global/Button'

defineMessages({
    the_score_cannot_be_left_empty: {
        id: 'the_score_cannot_be_left_empty',
        description: 'the_score_cannot_be_left_empty',
        defaultMessage: 'The score cannot be left empty'
    },
    the_comment_cannot_be_left_empty: {
        id: 'the_comment_cannot_be_left_empty',
        description: 'the_comment_cannot_be_left_empty',
        defaultMessage: 'The comment cannot be left empty'
    },
    EXISTS_FEEDBACK: {
        id: 'EXISTS_FEEDBACK',
        description: 'EXISTS_FEEDBACK',
        defaultMessage: 'It already exists'
    },
    SUCCESS_SEND_FEDDBACK: {
        id: 'SUCCESS_SEND_FEDDBACK',
        description: 'SUCCESS_SEND_FEDDBACK',
        defaultMessage: 'Sent'
    }
})

const mapStateToProps = state => {
    return {
        show: showingNewVersionModalExperience(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(ui.closeNewVersionModalExperience())
        },
        onSendFeedback: (state) => {
            dispatch(ui.sendFeedback(state.score, state.comments, state.banner))
        },
        onError: (error) => {
            dispatch({
                type: 'SEND_FEDDBACK_FAILURE',
                statusText: error,
                status: 400
            })
        }
    }
}

class NewVersionModalExperience extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            score: 0,
            comments: '',
            banner: 'BANNER_NEW_VERSION_EXPERIENCE'
        }
    }

    setScore(value) {
        this.setState({score: value})
        $('.score').css('opacity', '0.5')
        $('#score_' + value).css('opacity', '1')
    }

    sendFeedBack(){
        if(this.state.score === 0){
            this.props.onError(this.props.intl.formatMessage({id: 'the_score_cannot_be_left_empty', defaultMessage: 'The score cannot be left empty'}))
        }else if(this.state.comments === ''){
            this.props.onError(this.props.intl.formatMessage({id: 'the_comment_cannot_be_left_empty', defaultMessage: 'The comment cannot be left empty'}))
        }else {
            this.props.onSendFeedback(this.state)
            this.props.onClose(this.props.onClose)
        }
    }
    render() {
        const { show, onClose } = this.props
        return show && !this.props.previousPreferences ? (
            <div>
                <div className="full-overlay" style={{ display: show ? 'block' : 'none' }} />
                <div id="modal-new-version-experience" style={{ display: show ? 'block' : 'none' }}>
                    <div className="row no-gutters">                  
                        <div className="col-12 logo-container">
                            <header className="container-fluid">
                                <div className="row">
                                    <div className="col-3 offset-9 my-auto text-right">
                                        <div className="bt-close" onClick={onClose}>
                                            <i className="fal fa-times" style={{fontSize: '2rem'}} />
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className='row'>
                                <div className='col-12'>
                                    <h1 className='title'>
                                        <Translation id="how_has_your_experience_been_with_the_new_eCosentino" defaultMessage="How has your experience been with the new eCosentino?" />
                                    </h1>
                                    <h3 className='subtitle'>
                                        <Translation id="your_opinion_is_very_important_to_help_us_improve_the_platform" defaultMessage="Your opinion is very important to help us improve the platform" />
                                    </h3>
                                </div>
                                <div className='col-12 col-md-10 mx-auto pb-3'>
                                    <div className="modal-experience-faces">
                                        <div id='score_1' className='score' onClick={() => this.setScore(1)}>
                                            <img src={redFace} alt='' />
                                            <span><Translation id="very_unsatisfied" defaultMessage="Very unsatisfied" /></span>
                                        </div>
                                        <div id='score_2' className='score' onClick={() => this.setScore(2)}>
                                            <img src={orangeFace} alt='' />
                                            <span><Translation id="dissatisfied" defaultMessage="Dissatisfied" /></span>
                                        </div>
                                        <div id='score_3' className='score' onClick={() => this.setScore(3)}>
                                            <img src={yellowFace} alt='' />
                                            <span><Translation id="neutral" defaultMessage="Neutral" /></span>
                                        </div>
                                        <div id='score_4' className='score' onClick={() => this.setScore(4)}>
                                            <img src={lightgreenFace} alt='' />
                                            <span><Translation id="satisfied" defaultMessage="Satisfied" /></span>
                                        </div>
                                        <div id='score_5' className='score' onClick={() => this.setScore(5)}>
                                            <img src={darkgreenFace} alt='' />
                                            <span><Translation id="very_satisfied" defaultMessage="Very satisfied" /></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-8 mx-auto modal-experience-footer'>
                                    <div className="form-group">
                                        <textarea
                                            rows={4}
                                            className="form-control mb-4"
                                            placeholder={this.props.intl.formatMessage({ id: 'thank_you_very_much_would_you_like_to_add_anything_else' })}
                                            onChange={e => {e && this.setState({comments: e.target.value})}}
                                        />
                                        <Button onClick={() => this.sendFeedBack()} gtmLabel="ec_feedback_comp_faces_send">
                                            <Translation id="submit_feedback" defaultMessage="Submit Feedback" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(NewVersionModalExperience)
)
