import React from 'react'
import ProductDisplayName from '../global/ProductDisplayName'
import DisplayDetail from './rectangleResult/displays/DisplayDetail'
import DisplayBrand from './rectangleResult/displays/DisplayBrand'
import DisplayThickness from './rectangleResult/displays/DisplayThickness'
import DisplayOutletIndicator from './rectangleResult/displays/DisplayOutletIndicator'
import ReactTooltip from 'react-tooltip'
import Translation from '../global/Translation'

export default class ListResult extends React.Component {
    // constructor(props) {
    //     super(props)
    //     this.state = { showModalStock: false }
    //
    //     this.product = props.result !== undefined ? props.result._source : props.product
    // }

    render() {
        const typeMap = {
            TABLA: <Translation id="tables" defaultMessage="Slabs" />,
            PPCC: <Translation id="professional_products" defaultMessage="Professional Products" />,
            FREGADERO: <Translation id="sinks" defaultMessage="Sinks" />,
            MK: <Translation id="mktool" defaultMessage="Marketing Tools" />,
            SH: <Translation id="showroom" defaultMessage="Showroom" />,
            WH: <Translation id="warehouse" defaultMessage="Warehouse" />,
            OPERATIONS: <Translation id="OPERATIONS" defaultMessage="Operations" />,
            SERVICE: <Translation id="SERVICE" defaultMessage="Services" />,
            ENCIMERA: <Translation id="WORKTOPS" defaultMessage="Worktops" />,
            BAÑOS: <Translation id="baths_and_wall_cladding" defaultMessage="Bathrooms and Wall Cladding" />,
            '01': <Translation id="SAMPLES" defaultMessage="Samples" />,
            '02': <Translation id="OTHERS" defaultMessage="Others" />,
            '03': <Translation id="MERCHANDISING" defaultMessage="Merchandising" />,
            '04': <Translation id="LITERATURE" defaultMessage="Literature" />,
            '05': <Translation id="TAGS" defaultMessage="Tags" />,
            '06': <Translation id="ANCHORING" defaultMessage="anchoring" />,
            '07': <Translation id="BOXES" defaultMessage="Boxes" />,
            '08': <Translation id="DISPLAYS" defaultMessage="Displays" />,
            '09': <Translation id="BOOKS" defaultMessage="Books" />,
            '10': <Translation id="CABINETS" defaultMessage="Cabinets" />,
            '11': <Translation id="SIGNAGE" defaultMessage="Signage" />,
            '12': <Translation id="TEST_KITS" defaultMessage="Test kits" />,
            '13': <Translation id="WORKWEAR" defaultMessage="Workwear" />,
            '14': <Translation id="EPP" defaultMessage="EPP" />,
            '15': <Translation id="WAREHOUSE_SUPPLIES" defaultMessage="warehouse supplies" />
        }
        const cantBeSelected = this.props.result.cantBeSelected
        const product = this.props.result._source

        let indRotationLabel = ''
        let lang = this.props.language.toUpperCase()
        if (!(lang in product.translations)) lang = 'Z2'
        if (lang in product.translations && product.translations[lang].indRotation) {
            indRotationLabel = `(${product.indRotation}) ${product.translations[lang].indRotation}`
        }


        return (

            <tr key={this.props.result._id}>
                <td>
                    <div className="product-image" style={{ backgroundImage: 'url("' + product.thumb + '")' }} />
                    {/*<div className="product-image" style={{ backgroundImage: 'url("' + this.props.getImageBackground(this.product) + '")' }} />*/}
                </td>

                <td>
                    <div
                        style={{
                            fontSize: '1rem',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <ProductDisplayName product={product} />
                        <DisplayBrand style={{fontSize: '0.8em',textTransform:"capitalize"}} brand={product.brand} />
                        <span style={{ fontSize: '0.9em', color: '#0a0a0a'}}><b># {product.productId}</b></span>
                    </div>
                </td>

                <td style={{textAlign:'end'}}>
                    <DisplayThickness thickness={product.thickness} />
                </td>

                <td>
                    {typeMap[product.type] ? typeMap[product.type] : <DisplayDetail class="type" value={product.type} />}
                </td>

                <td>
                    <DisplayDetail class="finish" value={product.finish} />
                </td>

                <td>
                    <DisplayDetail class="subtypeName" value={product.subtypeName} />
                </td>

                <td>
                    <DisplayDetail class="subtypeName" value={indRotationLabel} />
                </td>

                <td>
                    <DisplayOutletIndicator
                        outletQuality={product.outletQuality}
                        outletDeviated={this.props.isAdmin || this.props.impersonatedBy ? product.outletDeviated : null}
                    />
                </td>

                <td style={{textAlign:'center'}}>
                    {!cantBeSelected ? (
                        <input
                            type="checkbox"
                            onClick={() => this.props.selectMaterial(product)}
                            key={product.productId + this.props.selectedMaterials.includes(product.productId)}
                            defaultChecked={this.props.selectedMaterials.includes(product.productId)}
                        />
                    ) : (
                        <span
                            data-tip={this.props.intl.formatMessage({
                                id: 'disabled_the_product_is_not_common_to_all_centers',
                                defaultMessage: 'Disabled, The product is not common to all centers'
                            })}
                        >
                            <i className="fas fa-exclamation-triangle" />
                            <ReactTooltip bg-coloreffect="solid" place="bottom" />
                        </span>
                    )}
                </td>

            </tr>



            // <div className="list-result" type={this.product.type}>
            //     <div className="row" style={{ width: '100%' }}>
            //         <div className="col-1">
            //             <div className="product-image-container">
            //                 <div className="product-image" style={{ backgroundImage: 'url("' + this.props.getImageBackground(this.product) + '")' }} />
            //             </div>
            //         </div>
            //         <div className="col-11">
            //             <div className="row">
            //                 <div className="col-4">
            //                     <div
            //                         style={{
            //                             fontSize: '1rem',
            //                             display: 'flex',
            //                             flexDirection: 'column',
            //                             //maxWidth: '180px',
            //
            //
            //                         }}
            //                     >
            //                         <ProductDisplayName product={this.product} />
            //                         <DisplayBrand brand={this.product.brand} />
            //                         <span style={{ fontSize: '0.8em' }}># {this.product.productId}</span>
            //                     </div>
            //                 </div>
            //
            //                 <div className="col-6"
            //                     style={{
            //                         fontSize: '1rem',
            //                         display: 'flex',
            //                         alignItems: 'baseline',
            //                         gap: '1em',
            //                         fontWeight: 500
            //                     }}
            //                 >
            //                     <DisplayThickness thickness={this.product.thickness} />
            //                     {typeMap[this.product.type] ? typeMap[this.product.type] : <DisplayDetail class="type" value={this.product.type} />}
            //                     <DisplayDetail class="finish" value={this.product.finish} />
            //                     <DisplayDetail class="subtypeName" value={this.product.subtypeName} />
            //                     <DisplayDetail class="subtypeName" value={indRotationLabel} />
            //                 </div>
            //
            //                 {/*<div className="col-1">*/}
            //                 {/*    <DisplayThickness thickness={this.product.thickness} />*/}
            //                 {/*</div>*/}
            //                 {/*<div className="col-2">{typeMap[this.product.type] ? typeMap[this.product.type] : <DisplayDetail class="type" value={this.product.type} />}</div>*/}
            //                 {/*<div className="col-1">*/}
            //                 {/*    <DisplayDetail class="finish" value={this.product.finish} />*/}
            //                 {/*</div>*/}
            //                 {/*<div className="col-1">*/}
            //                 {/*    <DisplayDetail class="subtypeName" value={this.product.subtypeName} />*/}
            //                 {/*</div>*/}
            //                 {/*<div className="col-2">*/}
            //                 {/*    <DisplayDetail class="subtypeName" value={this.product.indRotation} />*/}
            //                 {/*</div>*/}
            //
            //                 <div className="col-1">
            //                     {this.product.outlet && (
            //                         <DisplayOutletIndicator
            //                             outletQuality={this.product.outletQuality}
            //                             outletDeviated={this.props.isAdmin || this.props.impersonatedBy ? this.product.outletDeviated : null}
            //                         />
            //                     )}
            //                 </div>
            //                 <div className="col-1">
            //                     {canBeSelected ? (
            //                         <input
            //                             type="checkbox"
            //                             onClick={() => this.props.selectMaterial(this.product)}
            //                             key={this.product.productId + this.props.selectedMaterials.includes(this.product.productId)}
            //                             defaultChecked={this.props.selectedMaterials.includes(this.product.productId)}
            //                         />
            //                     ) : (
            //                         <span
            //                             data-tip={this.props.intl.formatMessage({
            //                                 id: 'disabled_the_product_is_not_common_to_all_centers',
            //                                 defaultMessage: 'Disabled, The product is not common to all centers'
            //                             })}
            //                         >
            //                             <i className="fas fa-exclamation-triangle" />
            //                             <ReactTooltip bg-coloreffect="solid" place="bottom" />
            //                         </span>
            //                     )}
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </div>
        )
    }
}
