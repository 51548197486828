import React from 'react'
import { injectIntl } from 'react-intl'
import DisplayTechnology from '../../catalog/rectangleResult/displays/DisplayTechnology'
import DisplayDiscontinued from '../../catalog/rectangleResult/displays/DisplayDiscontinued'
import DisplayOutletIndicator from '../../catalog/rectangleResult/displays/DisplayOutletIndicator'
import DisplayNewIndicator from '../../catalog/rectangleResult/displays/DisplayNewIndicator'
import DisplayBlackIndicator from '../../catalog/rectangleResult/displays/DisplayBlackIndicator'
import LocalizedLink from '../../global/LocalizedLink'
import ProductImage from '../../catalog/ProductImage'
import moment from 'moment'
import DetailsMktools from '../../catalog/rectangleResult/DetailsMktools'
import DetailsIkea from '../../catalog/rectangleResult/DetailsIkea'
import InputNumber from '../../global/InputNumber'
import Button from '../../global/Button'
import Translation from '../../global/Translation'
import { canBuy, getIsIkeaCenter, getIsMkToolsCenter, getNeedsSegmentation } from '../../../store/centers/reducers'
import { getQtyProductsCart } from '../../../store/cart/reducers'
import { addCartLine, addCartLineWithouthSeg } from '../../../store/cart/actions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getImpersonatedBy, getIsAdmin } from '../../../store/login/reducers'
import DetailsDefaultV2 from '../../catalog/rectangleResult/DetailsDefaultV2'
import DetailsDefaultV2TemporalFix from '../../catalog/rectangleResult/DetailsDefaultV2TemporalFix'

const mapStateToProps = (state, ownProps) => {
    let isOutletFixBoolean = ownProps.isSellingOutlet ? true : null
    return {
        isMkToolsCenter: getIsMkToolsCenter(state),
        isIkeaCenter: getIsIkeaCenter(state),
        qtyProductCart: getQtyProductsCart(state),
        canBuy: canBuy(state),
        isAdmin: getIsAdmin(state),
        impersonatedBy: getImpersonatedBy(state),
        outlet: ownProps.isSellingOutlet !== undefined ? isOutletFixBoolean : ownProps.product.outlet,
        needsSegmentation: getNeedsSegmentation(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addCartLine: (product, qty, extra, callbackCenter, dektonGripRequested) => {
            dispatch(addCartLine(product, qty, extra, callbackCenter, dektonGripRequested))
        },
        addCartLineWithouthSeg: (productId, qty, extra) => {
            dispatch(addCartLineWithouthSeg(productId, qty, extra))
        },
        clickDetail: (product, list, index) => {
            dispatch({ type: 'PRODUCT_CLICK', product, list, index })
        }
    }
}

class ProductLandingTwo extends React.Component {
    constructor(props) {
        super(props)
        let firstState = {}
        let step = 1
        let qtyAdd = 1
        if (this.props.product && this.props.product.typeId === '01') step = 1
        else if (this.props.product.minQty[this.props.shippingMethod]) step = this.props.product.minQty[this.props.shippingMethod]

        if (this.getMin()) qtyAdd = this.getMin()
        else if (this.props.product && this.props.product.typeId === '01') qtyAdd = 1
        else if (this.props.product.minQty[this.props.shippingMethod]) qtyAdd = this.props.product.minQty[this.props.shippingMethod]
        firstState = { ...firstState, qtyToAdd: qtyAdd, step: step }

        if (this.getLimit() === 0) firstState = { ...firstState, qtyToAdd: 0 }

        this.state = firstState
    }
    getMin() {
        return this.props.product.min
    }
    getLimit() {
        if (this.props.product.discontinued) {
            if (this.props.product.isMktool && this.getLimitDiscontinued() > this.getLimitMktool()) return this.getLimitMktool() < this.getMin() ? 0 : this.getLimitMktool()
            else return this.getLimitDiscontinued()
        } else if (this.props.product.isMktool) return this.getLimitMktool() < this.getMin() ? 0 : this.getLimitMktool()
        else if (this.props.outlet && this.props.product.outlet) return this.getLimitDiscontinued()
        else return null
    }
    getLimitMktool() {
        let result = 0
        if (this.props.product.isMktool) {
            if (this.props.isMkToolsUsaCenter && (this.props.product.max || this.props.product.max === 0)) result = this.props.product.max
            else result = 1000
        }
        if (this.props.qtyProductCart[this.props.product.productId]) {
            result = result - parseInt(this.props.qtyProductCart[this.props.product.productId], 10)
        }
        return result
    }
    getLimitDiscontinued() {
        let result = 0
        if (this.props.outlet) {
            if (this.props.product.stock_outlet) {
                result = parseInt(this.props.product.stock_outlet, 10)
            }
        } else {
            if (this.props.product.stock) {
                result = parseInt(this.props.product.stock, 10)
            }
        }
        if (this.props.qtyProductCart[this.props.product.productId]) {
            result = result - parseInt(this.props.qtyProductCart[this.props.product.productId], 10)
        }
        return result
    }
    getValueWithMax() {
        return this.state.qtyToAdd <= this.getLimit() ? this.state.qtyToAdd : this.getLimit()
    }
    isSample() {
        return this.props.product.typeId === '01'
    }
    getType() {
        let type = null
        if (this.props.isIkeaCenter && this.props.product.brand === 'SERVICES') type = 'ikea_service'
        else if (this.props.isIkeaCenter) type = 'ikea'
        else if (this.props.isMkToolsCenter) type = 'mktools'
        return type
    }
    getTypeProductForStyles() {
        if (this.props.isIkeaCenter && (this.props.product.brand === 'DEKTON' || this.props.product.brand === 'SILESTONE')) return 'TABLA'
        else return this.props.product.type
    }
    getTypeRectangleResult() {
        let type = 'default'
        if (this.props.isMkToolsCenter) type = 'mktool'
        else if (this.props.isIkeaCenter) type = 'ikea'
        return type
    }
    canDisplayDiscontinued() {
        return !((['MK', 'SH', 'WH'].includes(this.props.product.webHeading) && this.props.product.discontinued === 'DD') || (this.props.product.discontinued === 'DD' || this.props.product.discontinued === 'DF'))
    }

    render() {
        let details
        switch (this.getTypeRectangleResult()) {
            case 'default':
                details = <DetailsDefaultV2TemporalFix {...this.props} product={this.props.product} />
                break
            case 'mktool':
                details = <DetailsMktools {...this.props} product={this.props.product} />
                break
            case 'ikea':
                details = <DetailsIkea {...this.props} product={this.props.product} />
                break
            default:
                details = <DetailsDefaultV2TemporalFix {...this.props} product={this.props.product} />
                break
        }
        let todayMinusOneYear = new moment().subtract(365, 'days')
        let today = new moment()
        let creationDate = moment(this.props.product.creationDate)
        return (
            <div className="rectangle-result v2" type={this.getTypeProductForStyles()}>
                <div className="wrapper-product-image">
                    <DisplayTechnology technology={this.props.product.technology} />
                    <div className="indicators">
                        {this.canDisplayDiscontinued() && (
                            <React.Fragment>
                                <DisplayDiscontinued discontinued={this.props.product.discontinued} /> <div style={{ width: '100%' }} />
                            </React.Fragment>
                        )}

                        {this.props.outlet && (
                            <DisplayOutletIndicator
                                outletQuality={this.props.product.outletQuality}
                                outletDeviated={this.props.isAdmin || this.props.impersonatedBy ? this.props.product.outletDeviated : null}
                            />
                        )}
                    </div>
                    <div className="spheres-indicator">
                        {creationDate > todayMinusOneYear && creationDate < today && <DisplayNewIndicator />}
                        {this.props.canViewPricesPhase3 && this.promotions.length && this.promotions[0].dealType === 'B' ? (
                            <DisplayBlackIndicator value={`${this.promotions[0].value}% Dto`} productDiscounts={this.props.productDiscounts} />
                        ) : null}
                        {this.props.canViewPricesPhase3 && this.promotions.length && this.promotions[0].dealType === 'A' ? (
                            <DisplayBlackIndicator value={`New price`} productDiscounts={this.props.productDiscounts} />
                        ) : null}
                    </div>
                    <LocalizedLink
                        routeId="ROUTE_PRODUCT_DETAIL"
                        params={{ id: this.props.product.productId, outlet: this.props.outlet ? 'outlet' : '' }}
                        onClick={() => {
                            if (this.props.indexOfList !== undefined) this.props.clickDetail(this.props.product, this.props.list, this.props.indexOfList)
                        }}
                    >
                        <ProductImage product={this.props.product} />
                    </LocalizedLink>
                </div>
                {details}
                {this.props.canOnlyViewOutlet && (
                    <div className="product-actions">
                        <div className="bt-row">
                            {(this.props.product.lotViewAvailable === undefined || this.props.product.lotViewAvailable) && (
                                <LocalizedLink routeId="ROUTE_LOTS" params={{ id: this.props.product.productId, outlet: 'outlet' }}>
                                    <Button size="small">
                                        <Translation id="view_lots" defaultMessage="View lots" />
                                    </Button>
                                </LocalizedLink>
                            )}
                        </div>
                    </div>
                )}
                {this.props.canBuy && (
                    <div className={"product-actions"}>
                    {!this.props.product.haveOptions && (
                        <div className="input-row">
                            <div className="input">
                                <InputNumber
                                    value={this.getLimit() !== null ? this.getValueWithMax() : this.state.qtyToAdd}
                                    change={qty =>
                                        this.setState({
                                            qtyToAdd: qty
                                        })
                                    }
                                    step={this.props.isIkeaCenter && this.props.product.unit === 'M2' ? 0.01 : this.state.step}
                                    limit={this.getLimit()}
                                    min={this.getMin()}
                                    decimals={this.props.isIkeaCenter && this.props.product.unit === 'M2' ? 2 : 0}
                                    newVersion={true}
                                />
                            </div>
                        </div>
                    )}
                    <div className="bt-row">
                        {this.props.product.haveOptions ? (
                            this.props.canOnlyViewOutlet ? (
                                (this.props.product.lotViewAvailable === undefined || this.props.product.lotViewAvailable) && (
                                    <LocalizedLink routeId="ROUTE_LOTS" params={{ id: this.props.product.productId, outlet: 'outlet' }}>
                                    <Button icon="shopping-cart" size="small" customClass="view-more-promo-btn cart">
                                            <Translation id="options" defaultMessage="Options" />
                                        </Button>
                                    </LocalizedLink>
                                )
                            ) : (
                                <LocalizedLink routeId="ROUTE_PRODUCT_DETAIL" params={{ id: this.props.product.productId, outlet: this.props.outlet ? 'outlet' : '' }}>
                                    <Button icon="shopping-cart" size="small" customClass="view-more-promo-btn cart">
                                        <Translation id="options" defaultMessage="Options" />
                                    </Button>
                                </LocalizedLink>
                            )
                        ) : (
                            <Button
                                far={true}
                                icon="cart-shopping"
                                inverted
                                customClass="view-more-promo-btn cart"
                                disabled={this.props.isAdding || (this.getLimit() != null ? this.getLimit() : 1) <= 0 || !this.state.qtyToAdd >= 1}
                                onClick={() => {
                                    if (this.props.needsSegmentation) {
                                        this.props.addCartLine(
                                            this.props.product.productId,
                                            this.state.qtyToAdd,
                                            this.props.outlet ? { outlet: this.props.product.outletQuality } : null,
                                            null,
                                            this.props.product.finish === 'AND' && ['DEKTON', 'DKTN'].includes(this.props.product.brand)
                                        )
                                    } else {
                                        this.props.addCartLineWithouthSeg(this.props.product.productId, this.state.qtyToAdd, {
                                            type: this.getType()
                                        })
                                    }
                                }}
                            >
                                <span className="btn-add-to-cart-text"><Translation id="add" defaultMessage="add" /></span>
                            </Button>
                        )}
                        </div>
                    </div>
                    )}
            </div>
            )
        }
}
const ProductLandingTwoContainer = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ProductLandingTwo)
)
export default injectIntl(ProductLandingTwoContainer)
