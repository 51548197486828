import React from 'react'
import LocalizedLink from '../global/LocalizedLink'
import Banner_PPCC from '../../assets/img/banners-v2/PPCC_V2.png'
import Translation from '../global/Translation'

export default class BannerPPCC extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <LocalizedLink
                routeId="ROUTE_CATALOG"
                queryString="?type[0]=TABLA"
                params={{ outlet: '' }}
            >
                <div className={this.props.type && this.props.type === 'v2' ? 'box-parent-banner-ppcc v2' : 'box-parent-banner-ppcc'} style={{
                    backgroundImage: `url(${Banner_PPCC})`,
                }}>
                    <div className="box-container">
                        <div className="box-title">
                            <Translation id="professional_products" defaultMessage="Professional products" />
                        </div>
                        <div className="box-subtitle">
                            <Translation id="banner_ppcc_result_v2" defaultMessage="Find here your silicones, adhesives, tapes and much more" />
                        </div>
                        <div className="box-button-container">
                            <div className="box-button">
                                <LocalizedLink routeId='ROUTE_CATALOG' queryString='?type[0]=PPCC' params={{ outlet: '' }}>
                                    {this.props.type && this.props.type === 'v2' ? (
                                        <Translation id="view_section" defaultMessage="View section" />
                                    ) : (
                                        <Translation id="shop_here" defaultMessage="Buy here" />
                                    )}
                                </LocalizedLink>
                            </div>
                        </div>
                    </div>
                </div>
            </LocalizedLink>
        )
    }
}
