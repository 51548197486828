import React from 'react'
import { injectIntl } from 'react-intl'
import Translation from '../global/Translation'
import ProductDisplayName from '../global/ProductDisplayName'
import DisplayChannel from '../segmentation/DisplayChannel'
import DisplayThickness from '../catalog/rectangleResult/displays/DisplayThickness'
import LineSegmentation from '../cart-line/LineSegmentation'
import {isEmserUser} from "../../store/login/reducers";
import {getActiveOrderLine, showModalLineDetail} from "../../store/orders/reducers";
import {connect} from "react-redux";
import { showBannerOldVersion } from '../../store/ui/reducers'
import { getVersion } from '../../store/login/reducers'
import moment from "moment";
import getNumberFormat from "../getNumberFormat";

const mapStateToProps = (state, ownProps) => {
    const { onClose, showModal, orderLine, lang } = ownProps
    return {
        isEmserUser : isEmserUser(state),
        showBanner: showBannerOldVersion(state),
        newVersion: getVersion(state),
        onClose,
        showModal,
        orderLine,
        lang
    }
}

class OrderLineModal extends React.Component {
    render() {
        const { isEmserUser, onClose, showModal, orderLine, lang, showBanner, newVersion, preferredDeliveryDate } = this.props
        let numberFormat = getNumberFormat(lang, { maximumFractionDigits: 2, minimumFractionDigits: 2 })

        if(orderLine.coin === 'JPY') {
            numberFormat = getNumberFormat(this.props.lang, { maximumFractionDigits: 0, minimumFractionDigits: 0 })
        }
        
        return (
            <div>
                <div className="modal-overlay" style={{display: showModal ? 'block' : 'none'}} onClick={onClose} />
                <div id="order-line-modal" style={{ display: showModal ? 'block' : 'none' }}>
                    <div id="ordel-line-detail">
                        <header className="container-fluid">
                            <div className="row">
                                <div className="col-9 my-auto">
                                    <div className="go-back" onClick={onClose}>
                                        <i className={`fal fa-chevron-left`} />
                                        <span className="label">
                                            <Translation id="back" defaultMessage="Back" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 title">
                                    <Translation id="product" defaultMessage="Product" />
                                </div>
                            </div>
                            <div className="row product_group">
                                <div className="row">
                                    <div className="col-6">
                                        <img className="img-fluid" src={orderLine.product.thumbMd} />
                                    </div>
                                    <div className="col-6 product_detail">
                                        <b>
                                            <ProductDisplayName product={orderLine.product} />
                                        </b>
                                        <div>{orderLine.product.brand}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <Translation id="thickness" defaultMessage="Thickness" />:{' '}
                                        <b>
                                            <DisplayThickness thickness={orderLine.product.thickness} string />
                                        </b>
                                    </div>
                                    <div className="col-6">
                                        <Translation id="finish" defaultMessage="Finish" />: <b>{this.props.intl.formatMessage({ id: orderLine.product.finish })}</b>
                                    </div>
                                    <div className="col-6">
                                        <Translation id="material_id" defaultMessage="Material Id" />: <b>{orderLine.product.productId}</b>
                                    </div>
                                    {orderLine.lot_id && (
                                        <div className="col-6">
                                            <Translation id="lot_id" defaultMessage="Lot ID" />: <b>{orderLine.lot_id}</b>
                                        </div>
                                    )}
                                    <div className="col-6">
                                        <Translation id="format" defaultMessage="Format" />: <b>{orderLine.product.format}</b>
                                    </div>
                                </div>
                            </div>
                            {(orderLine.serializedLine || orderLine.segmentation) && (
                                <div className="row">
                                    <div className="col-12 title">
                                        <Translation id="segmentation_applied" defaultMessage="Segmentation applied" />
                                    </div>
                                    <div className="col-12">
                                        {orderLine.serializedLine && (
                                            <div>
                                                <strong>
                                                    <Translation id="by_you" defaultMessage="By you" />
                                                </strong>
                                                <LineSegmentation line={orderLine.serializedLine} showAsList />
                                            </div>
                                        )}
                                        {orderLine.segmentation && (
                                            <div>
                                                <strong>
                                                    <Translation id="by_your_center" defaultMessage="By your center" />
                                                </strong>
                                                <LineSegmentation line={orderLine} showAsList />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {orderLine.comments && (
                                <div className="row">
                                    <div className="col-12 title">
                                        <Translation id="comment_per_line" defaultMessage="Comment per line" />
                                    </div>
                                    <div className="col-12">
                                        <p> {orderLine.comments} </p>
                                    </div>
                                </div>
                            )}

                            {(orderLine.preferredDeliveryDate || preferredDeliveryDate) && (
                              <div className="row">
                                  <div className="col-12 title">
                                      <Translation id="requested_delivery_date" defaultMessage="Requested Delivery Date"/>
                                  </div>
                                  <div className="col-12">
                                      <p>
                                        {preferredDeliveryDate
                                            ? moment(preferredDeliveryDate).format('L')
                                            : orderLine.preferredDeliveryDate
                                                ? moment(orderLine.preferredDeliveryDate).format('L')
                                                : ''
                                        }
                                      </p>
                                  </div>
                              </div>
                            )}

                            {orderLine.reservation_quantity && (
                                <div className="row">
                                    <div className="col-12 title">
                                        <Translation id="quantity_request" defaultMessage="Quantity requested" />
                                    </div>
                                    <div className="col-12">
                                        <div className="d-flex number">
                                            <b>
                                                {parseInt(orderLine.reservation_quantity)}
                                                {'  '}
                                            </b>{' '}
                                            <Translation id="units" defaultMessage="Units" />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {orderLine.quantity && (
                                <div className="row">
                                    <div className="col-12 title">
                                        <Translation id="quantity_confirmed" defaultMessage="Quantity confirmed" />
                                    </div>
                                    <div className="col-12">
                                        <div className="d-flex number">
                                            <b>
                                                {parseInt(orderLine.quantity)}
                                                {'  '}
                                            </b>{' '}
                                            <Translation id="units" defaultMessage="Units" />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!isEmserUser && orderLine.unitary_import && (
                                <div className="row">
                                    <div className="col-12 title">
                                        <Translation id="unit_price" defaultMessage="Unitary price" />
                                    </div>
                                    <div className="col-12">
                                        <div className="d-flex number">
                                            <b> {numberFormat.format(orderLine.unitary_import)}</b>
                                            {'  '} {orderLine.coin}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!isEmserUser && orderLine.discount && (
                                <div className="row">
                                    <div className="col-12 title">
                                        <Translation id="applied_discount" defaultMessage="Applied discount" />
                                    </div>
                                    <div className="col-12">
                                        <div className="d-flex number">
                                            <b>{numberFormat.format(orderLine.discount)}</b> {'%'}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!isEmserUser && orderLine.import && (
                                <div className="row">
                                    <div className="col-12 title">
                                        <Translation id="total_import" defaultMessage="Total price" />
                                    </div>
                                    <div className="col-12">
                                        <div className="d-flex number">
                                            <b>{numberFormat.format(orderLine.import)}</b> {orderLine.coin}
                                        </div>
                                    </div>
                                </div>
                            )}


                        </div>

                        <footer className="container-fluid">
                            <div className="row">
                                <div className="col-9 my-auto">
                                    <div className="go-back" onClick={onClose}>
                                        <i className={`fal fa-chevron-left`} />
                                        <span className="label">
                                            <Translation id="back" defaultMessage="Back" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(connect(
    mapStateToProps,
    null
)(OrderLineModal))


