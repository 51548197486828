import React from 'react'

import DeviatedAdminContainer from '../components/deviated-admin/DeviatedAdminContainer'
import ErrorContainer from '../components/alerts/ErrorContainer'

const mapStateToProps = state => {
    return {
        isAdminDeviated: true
    }
}

const DeviatedAdminScreen = mapStateToProps().isAdminDeviated ? () => <DeviatedAdminContainer /> : () => <ErrorContainer />

export default DeviatedAdminScreen
