import React from 'react'

import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getSelectedCenter } from '../../../../store/centers/reducers'

const mapStateToProps = state => {
    return {
        selectedCenter: getSelectedCenter(state)
    }
}
class DisplayBrand extends React.Component {
    render() {
        if (!this.props.brand) return ''
        if (this.props.span) return <span>{this.props.selectedCenter === 7130 && this.props.brand === 'DEKTON' ? 'DKTN' : this.props.brand.toLowerCase()}&reg;</span>

        return <div className="product-brand" style={this.props.style}>
            {this.props.selectedCenter === 7130 && this.props.brand === 'DEKTON' ? 'DKTN' : 
                this.props.brand.toLowerCase() === "services" ? "worktop" : this.props.brand.toLowerCase()} &reg;
        </div>
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(DisplayBrand)
)
