import React from 'react'
import Translation from '../../global/Translation'

import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import { injectIntl } from 'react-intl'

import Alerts from '../../alerts/Alerts'
import Button from '../../global/Button'
import confirm from '../../global/confirm'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import OrderFinishedContainer from '../../order-finished/OrderFinishedContainer'
import Select from 'react-select'
import Modal from '../../global/Modal'
import CheckboxLinkSaleConditions from '../global/CheckboxLinkSaleConditions'
import GroupInputVoucher from '../global/GroupInputVoucher'
import ProductDisplayName from '../../global/ProductDisplayName'
import ProductImage from '../../catalog/ProductImage'
import DisplayOutletIndicator from '../../catalog/rectangleResult/displays/DisplayOutletIndicator'
import SlaDateSelect from "../SlaDateSelect";
import {getNextWorkingDay} from "../../../helpers";
import CheckboxLinkHealthConditions from '../global/CheckboxLinkHealthConditions'
import SmallBannerRocket from '../../banner-rocket/SmallBannerRocket'

class CheckoutFormCustomer extends React.Component {

    componentDidMount() {
        this.props.resetCheckout().then(() => {
            // this.props.setDeliveryDate(this.getNextWeekDay(this.props.minDeliveryDate))
            this.props.setEmployeeId(this.props.employeeId)
            this.props.fetchShippingAddresses().then(() => {
                if (this.props.shippingAddresses && this.props.shippingAddresses.length > 0) {
                    let firstShippingAddres = this.props.shippingAddresses.filter(shippingAddress => {
                        if (this.props.isMallCartForContainer) return shippingAddress.allowedShippingTypes.includes('SINGLE_SLAB')
                        return shippingAddress.allowedShippingTypes.includes(this.props.shippingMethod)
                    })[0]

                    this.props.setCountryOfSend(firstShippingAddres.country)
                    this.props.setSelectedShippingCenterId(firstShippingAddres.centerId)
                    this.props.setSelectedShippingAddressId(firstShippingAddres.shippingAddressId)
                    this.props.setSelectedShippingAddressSalesOrg(firstShippingAddres.salesOrg)
                    this.props.setSelectedShippingAddressEcosentinoKey(firstShippingAddres.ecosentinoKey)

                    this.props.setSelectedShippingAddress(firstShippingAddres)
                    this.props.setHasSelectedCenter(false)
                    // this.props.setDeliveryDate(this.getNextWeekDay(this.props.minDeliveryDate))
                }
            })
            this.props.isOffer && this.props.setOfferMode()
        })

        this.props.onLoadCheckout()
    }

    canSendCheckout = () => {
        return (
            this.props.selectedShippingAddress &&
            this.props.canSendMinMaxCheckout() &&
            !this.props.isSending &&
            this.props.totalQty > 0 &&
            this.props.canSendNeedDestination() &&
            this.props.canSendCheckoutSaleConditions &&
            this.props.canSendCheckoutHealthConditions &&
            !this.props.transformingSkuToLots &&
            (!this.props.isEmserUser || this.props.po)
        )
    }

    getOptionsCountryOfSend = filteredShippingAddressesForUsers => {
        if (filteredShippingAddressesForUsers)
            return filteredShippingAddressesForUsers.map(shippingAddress => ({
                value: shippingAddress.ecosentinoKey,
                label:
                    shippingAddress.centerName +
                    ' ' +
                    shippingAddress.customerName +
                    ' ' +
                    shippingAddress.address +
                    ' ' +
                    shippingAddress.zipCode +
                    ' ' +
                    shippingAddress.city +
                    ' ' +
                    shippingAddress.country +
                    ' ' +
                    shippingAddress.salesOrg
            }))
        else return []
    }
    getNextWeekDay(days) {
        let plannedDay = moment().add(days, 'days')
        let plannedWeekDay = plannedDay.day()
        if (plannedWeekDay === 6) {
            plannedDay = plannedDay.add(2, 'days')
        } else if (plannedWeekDay === 0) {
            plannedDay = plannedDay.add(1, 'days')
        }

        return plannedDay
    }

    prepareCart() {
        return this.props.isOffer ? this.props.transformSkuToLots() : Promise.resolve()
    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    // onFileChange = async event => {
    //     const file = event.target.files[0];
    //     const b64File = await this.toBase64(file);
    //     this.props.setCutToSizeFile({name: file.name, data:b64File, type: 'BX01'});
    // }

    submitCheckout() {
        this.prepareCart().then(() => {
            if (this.props.haveSomeProductsBelowThreshold) {
                confirm(
                    <div className="confirm-checkout">
                        <div className="confirm-checkout_title">{this.props.intl.formatMessage({ id: 'please_confirm_the_order' })}:</div>
                        <div className="confirm-checkout_text">
                            {this.props.intl.formatMessage({
                                id:
                                    'there_are_only_a_few_remaining_items_in_stock_in_at_least_one_of_the_chosen_materials_in_the_order_which_may_constrain_the_availability_upon_orden_confirmation'
                            })}
                        </div>
                    </div>
                , {intl: this.props.intl}).then(
                    confirm => {
                        this.props.submitCheckout()
                    },
                    cancel => {
                        //close modeal
                    }
                )
            } else {
                this.props.submitCheckout()
            }
        })
    }

    render() {
        const { shippingAddresses, current, shippingMethod, goalMax } = this.props

        //allowedShippingTypes for users
        let filteredShippingAddressesForUsers = shippingAddresses
            ? shippingAddresses.filter(shippingAddress => {
                  if (this.props.isMallCartForContainer) return shippingAddress.allowedShippingTypes.includes('SINGLE_SLAB')
                  return shippingAddress.allowedShippingTypes.includes(shippingMethod)
              })
            : []

        const sendText = this.props.isOffer ? <Translation id="request_a_quote" defaultMessage="Request a Quote" /> : <Translation id="send" defaultMessage="Send" />

        const difCurrent = current <= goalMax ? 0 : current - goalMax
        const errorMessage = difCurrent !== 0 ? 'MAX_WEIGHT_EXCEEDED' : null
        const submit = this.canSendCheckout() ? (
            <Button inverted datacy="checkout__send_checkout" onClick={() => this.submitCheckout()}>
                {sendText}
            </Button>
        ) : (
            <Button inverted disabled>
                {sendText}
            </Button>
        )
        if (this.props.isLoadingShippingAddress) return <Translation id="loading_shipping_addresses" defaultMessage="Loading Shipping Addresses" />
        let destinationForCustomer = [
            { value: 'CLIENTE', label: this.props.intl.formatMessage({ id: 'customer' }) },
            {
                value: 'HUB_CLIENTE',
                label: this.props.intl.formatMessage({ id: 'cosentino_hub' })
            },
            {
                value: 'CENTER',
                label: this.props.intl.formatMessage({ id: 'center' })
            }
        ]
        if (this.props.isMallCartForContainer && this.props.current <= this.props.goalMin) {
            destinationForCustomer = [
                {
                    value: 'HUB_CLIENTE',
                    label: this.props.intl.formatMessage({ id: 'cosentino_hub' })
                }
            ]
        }

        let alertType = this.props.alert
        let alertStatus = this.props.status
        if (this.props.alert === 'NOT_ENOUGH_LOTS' && this.props.isOffer) {
            alertType = 'NOT_ENOUGH_LOTS_OFFER'
            alertStatus = 400
        }

        return (
            <div>
                <h4 className="form-heading heading">{sendText}</h4>
                <form>
                    <Alerts
                        alert={alertType}
                        values={{ monthItems: this.props.monthItems, waitTillDate: this.props.waitTillDate ? moment(this.props.waitTillDate).format('L') : null }}
                        status={alertStatus}
                    />

                    {this.props.centerSelected == this.props.latinaVitoriaCenterId && !this.props.canSendNaturalStoneContainer() ? (
                        <Alerts alert={this.props.intl.formatMessage({ id: 'Minimum/maximum weight exceeded, please adjust your order' })} />
                    ) : (
                        ''
                    )}
                    {(this.props.centerSelected == this.props.naturalStoneSpainCenterId || this.props.centerSelected == this.props.latinaVitoriaCenterId) &&
                    this.canSendCheckout() ? (
                        <Translation id="natural_stone_may_be_modified_due_to_shipment" defaultMessage="Please bear in mind the order can be modified due to the shipment" />
                    ) : (
                        ''
                    )}
                    {this.props.hasDestination && (
                        <div className={`form-group`}>
                            <label htmlFor="input-date">
                                {this.props.shippingMethod === 'CONTAINER' ? (
                                    <Translation id="destination_for_container" defaultMessage="Container unloading location" />
                                ) : (
                                    <Translation id="destination" defaultMessage="Destination" />
                                )}
                            </label>
                            <Select
                                className={'form-control '}
                                placeholder={this.props.intl.formatMessage({ id: 'select_shipping_address' }) + '...'}
                                value={this.props.destination}
                                onChange={e => {
                                    e && this.props.setDestination(e.value)
                                }}
                                options={destinationForCustomer}
                            />
                        </div>
                    )}

                    {(shippingAddresses || this.props.destination === 'CENTER') && (
                        <div className="form-group">
                            <label htmlFor="input-date">
                                {this.props.shippingMethod === 'CONTAINER' ? (
                                    <Translation id="shipping_address_for_container" defaultMessage="Final Destination" />
                                ) : (
                                    <React.Fragment>
                                        <Translation id="shipping_address" defaultMessage="Shipping Address" /> <span style={{ color: 'red' }}>*</span>
                                    </React.Fragment>
                                )}
                            </label>
                            <Select
                                id="checkout__select_shipping_address-default"
                                className="form-control"
                                placeholder={`${this.props.intl.formatMessage({ id: 'select_shipping_address' })}...`}
                                value={this.props.selectedShippingAddress ? this.props.selectedShippingAddress.ecosentinoKey : null}
                                onChange={e => {
                                    e && this.props.changeShippingAddress(e.value)
                                }}
                                options={this.getOptionsCountryOfSend(filteredShippingAddressesForUsers)}
                                clearable={false}
                            />
                        </div>
                    )}

                    <SlaDateSelect
                        selected={this.props.deliveryDate}
                        onChange={this.props.setDeliveryDate}
                        minDate={this.props.centerSelected == 1100 ? getNextWorkingDay(this.props.minDeliveryDate) : getNextWorkingDay(1)}
                    />

                    {/*<div className="form-group select_date">*/}
                    {/*    <label htmlFor="input-date">*/}
                    {/*        <Translation id="required_delivery_date" defaultMessage="Required delivery date" />*/}
                    {/*    </label>*/}
                    {/*    <DatePicker*/}
                    {/*        selected={this.props.deliveryDate}*/}
                    {/*        onChange={this.props.setDeliveryDate}*/}
                    {/*        minDate={this.getNextWeekDay(this.props.minDeliveryDate)}*/}
                    {/*        className="form-control"*/}
                    {/*        autoComplete="off"*/}
                    {/*        onKeyDown={e => e.preventDefault()}*/}
                    {/*        dropdownMode="select"*/}
                    {/*    />*/}
                    {/*</div>*/}

                    <div className="form-group">
                        <label htmlFor="input-po">
                            <Translation id="insert_po" defaultMessage="Insert your PO#" />
                            {this.props.isEmserUser && <span style={{ color: 'red' }}> *</span>}
                        </label>
                        <input
                            data-cy="checkout__input_po"
                            type="text"
                            className="form-control"
                            id="input-po"
                            onChange={e => {
                                e && this.props.setPo(e.target.value)
                            }}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="input-comments">
                            <Translation id="comment" defaultMessage="Comments" />
                        </label>
                        <textarea
                            data-cy="checkout__textarea_comment"
                            className="form-control"
                            id="input-comments"
                            rows="3"
                            maxLength="2499"
                            onChange={e => {
                                e && this.props.setComments(e.target.value)
                            }}
                        />
                    </div>

                    {
                        // this.props.haveCutToSize && (
                        //     <div className="form-group">
                        // <label htmlFor="attach-document">
                        //     Documento
                        // </label>
                        //     <input type="file" id="attach-document" onChange={this.onFileChange}/>
                        // </div>
                        // )
                    }

                    <GroupInputVoucher />
                    {this.props.totalQty > 0 && this.props.canViewRocketPromotion && (
                        <div className="mb-3">
                            <SmallBannerRocket market={this.props.canViewRocketPromotion} />
                        </div>
                    )}
                    <div className="form-group">
                        <CheckboxLinkSaleConditions />
                        <CheckboxLinkHealthConditions />
                    </div>
                </form>
                <div className="row align-items-baseline">
                    <div className="col-12" style={{ paddingLeft: '35px', paddingBottom: '10px' }}>
                        {errorMessage && (
                            <Translation
                                id="MAX_WEIGHT_EXCEEDED"
                                values={{
                                    shippingMethod: this.props.intl.formatMessage({ id: shippingMethod }).toLowerCase()
                                }}
                            />
                        )}
                        {this.props.isEuropeOrIberia ? (
                            <Translation
                                id="same_batch"
                                defaultMessage="Please, indicate if you want the slabs to be the same batch. Check availability with your center. Thank you"
                            />
                        ) : null}
                        {this.props.transformingSkuToLots && <Alerts alert={'TRANSFORMING_SKU_TO_LOTS'} status="warning" />}
                    </div>
                    <div className="col-12">
                        <div className="d-flex justify-content-end">{submit}</div>
                    </div>
                </div>
                {(this.props.reservationCode || this.props.reservationCutToSizeCode) && <OrderFinishedContainer />}
                {this.props.showModalNoStock && (
                    <Modal
                        title={<Translation id="no_stock_checkout_title" defaultMessage="Materials without stock in your order" />}
                        onClose={() => this.props.closeNoStockModal()}
                        size="large"
                        footer={
                            <Button onClick={() => this.props.closeNoStockModal()} inverted size="medium">
                                <Translation id="accept" />
                            </Button>
                        }
                    >
                        <div className="row">
                            <div className="col-12">
                                <div className="confirm-checkout_text">
                                    <Translation
                                        id="no_stock_checkout_desc"
                                        defaultMessage="There are some materials in your order without enough stock to continue. Please, review the material list below and make the adjustment in the materials units to continue with your order."
                                    />
                                </div>
                            </div>
                        </div>
                        {this.props.haveSomeProductsWithoutSapStock.length > 0 && (
                            <div className="row mb-2 ">
                                <div className="offset-2 col-6 align-self-center">
                                    <b>
                                        <Translation id="displayName" />
                                    </b>
                                </div>
                                <div className="col-2 d-none d-lg-block text-center">
                                    <b>
                                        <Translation id="requestedQty" />
                                    </b>
                                </div>
                                <div className="col-2 text-center">
                                    <b>
                                        <Translation id="available" />
                                    </b>
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-12">
                                {this.props.haveSomeProductsWithoutSapStock.map(line => (
                                    <div className="row mb-2 matNoStock-modal">
                                        <div className="col-4 col-md-2">
                                            {/* <img className="img" src={orderLine.product.thumbMd}/> */}
                                            <div className="indicators">
                                                {line.extra && line.extra.outlet && (
                                                    <DisplayOutletIndicator
                                                        outletQuality={line.extra.outlet}
                                                        // outletDeviated={this.props.isAdmin || this.props.impersonatedBy ? this.product.outletDeviated : null}
                                                    />
                                                )}
                                            </div>

                                            <ProductImage product={line.product} />
                                        </div>
                                        <div className="col-3 align-self-center">
                                            <b>
                                                <ProductDisplayName product={line.product} />
                                            </b>
                                            <div className="">{line.product.brand}</div>
                                        </div>
                                        <div className="col-3 align-self-center">
                                            {!line.cutToSize && line.product.finish && (
                                                <div className="mini_cart_line_info__line">
                                                    <Translation id={line.product.finish} />
                                                </div>
                                            )}
                                            {line.cutToSize && line.cutToSize.finish && (
                                                <div className="mini_cart_line_info__line">
                                                    <div className="subtitle">
                                                        <Translation id={line.cutToSize.finish} />
                                                    </div>
                                                </div>
                                            )}
                                            {line.product.thickness && !line.cutToSize && (
                                                <div className="mini_cart_line_info__line mini_cart_line_info__line--dimensions">
                                                    <span>
                                                        <i className="fas fa-sort-amount-down" />
                                                        {line.product.thickness} cm.{' '}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-2 d-none d-lg-block">
                                            <div className="qty-cell">
                                                <p>{line.qty}</p>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            {line.extra && line.extra.outlet ? (
                                                <div className="qty-cell">
                                                    <p>
                                                        <b>{line.stockOutletAvailable}</b>
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className="qty-cell">
                                                    <p>
                                                        <b>{line.stockAvailable}</b>
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Modal>
                )}
            </div>
        )
    }
}


export default injectIntl(CheckoutFormCustomer)
