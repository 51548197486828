import React from 'react'
import Translation from '../global/Translation'

import LocalizedLink from '../global/LocalizedLink'
import Button from '../global/Button'
import imageSlabs from '../../assets/img/backgrounds/tables-ecosentino-banner.png'
import imageOutlet from '../../assets/img/backgrounds/outlet-ecosentino-banner.png'
import imageMktools from '../../assets/img/backgrounds/mktools.jpg'
import imageMktoolsThumb from '../../assets/img/backgrounds/mktools-thumb.jpg'
import imageShowroom from '../../assets/img/backgrounds/showroom.jpg'
import imageShowroomThumb from '../../assets/img/backgrounds/showroom-thumb.jpg'
import imageMkWarehouse from '../../assets/img/backgrounds/warehouse.jpg'
import imageMkWarehouseThumb from '../../assets/img/backgrounds/warehouse-thumb.jpg'
import cutToSizeSection from '../../assets/img/backgrounds/newCutSection.jpg'
import imageOutletIcon from '../../assets/img/backgrounds/imageOutletIcon.png'

import { injectIntl } from 'react-intl'

import LazyImage from '../global/LazyImage'
import LastBoughtProductsContainer from '../products/LastBoughtProductsContainer'
import Spinner from '../global/Spinner'
import ChangeLogHomeNews from '../changelog/ChangeLogHomeNews'
import Alerts from '../alerts/Alerts'
import moment from 'moment'
import BannerLechic from "../banner-lechic/BannerLechic";
import BannerDekton from '../banner-dekton/BannerDekton'

class Home extends React.Component {

    componentDidMount() {
        if (this.props.selectedCenter === this.props.LowesCenterId) this.props.goLowes(this.props.userType, this.props.intl)
        else if (this.props.isIkeaCenter) this.props.goIkea(this.props.intl)
    }

    render() {
        const promoEnabled = this.props.hasPromoActivated

        const outletImage = (
            <LazyImage preview={imageOutlet} image={imageOutlet} type="div" extraClass="image-slide custom-move">
                <div className="slide-title">
                    <div className="d-flex justify-content-between justify-content-start">
                        <div>
                            <h2 className="title">
                                <Translation id="cosentino_outlet" defaultMessage="Cosentino Outlet" />
                            </h2>
                            <div className="subtitle">
                                <Translation id="outlet_offers_nearest" defaultMessage="Offers at your nearest Center" />
                            </div>
                        </div>
                        <div>
                            <LazyImage preview={imageOutletIcon} image={imageOutletIcon} extraClass="outlet-image__icon" />
                        </div>
                    </div>
                    <Button inline size="medium">
                        <Translation id="shop_here" defaultMessage="Buy here" />
                    </Button>
                </div>
            </LazyImage>
        )
        const warehouseImage = (
            <LazyImage preview={imageMkWarehouseThumb} image={imageMkWarehouse} type="div" extraClass="image-slide">
                <div className="slide-title">
                    <h2 className="title">
                        <Translation id="warehouse" defaultMessage="Warehouse" />
                    </h2>
                    <Button inline size="medium">
                        <Translation id="shop_here" defaultMessage="Buy here" />
                    </Button>
                </div>
            </LazyImage>
        )
        let bannerSelector =
            this.props.selectedCenter === 7110
                ? 'BE-' + this.props.locale.toUpperCase()
                : this.props.selectedCenter === 7130
                ? 'CH-' + this.props.locale.toUpperCase()
                : this.props.locale.toUpperCase()
        return (
            <div id="home">
                {this.props.isLogging && <Spinner isLoading={this.props.isLogging} />}
                <div className="container">
                    {this.props.maintenanceScheduledText && <Alerts status="primary" alert={<div dangerouslySetInnerHTML={{ __html: this.props.maintenanceScheduledText }} />} />}
                    <div className="row slider no-gutters mb-5">
                        {promoEnabled && (this.props.promoBanners[bannerSelector] || this.props.promoBanners['default'].banner) ? (
                            <div className="col-12 col-lg-6">
                                <LocalizedLink routeId="ROUTE_CATALOG" queryString="?type[0]=TABLA&promo=true" params={{ outlet: '' }}>
                                    <div className="d-block d-md-none">
                                        <img
                                            src={
                                                this.props.promoBanners[bannerSelector] ? this.props.promoBanners[bannerSelector].banner : this.props.promoBanners['default'].banner
                                            }
                                            style={{ width: '100%' }}
                                            alt=""
                                        />
                                    </div>
                                    <div className="d-none d-md-block">
                                        <LazyImage
                                            preview={
                                                this.props.promoBanners[bannerSelector] ? this.props.promoBanners[bannerSelector].banner : this.props.promoBanners['default'].banner
                                            }
                                            image={
                                                this.props.promoBanners[bannerSelector] ? this.props.promoBanners[bannerSelector].banner : this.props.promoBanners['default'].banner
                                            }
                                            type="div"
                                            extraClass="left image-slide"
                                            style={{ maxHeight: '200px' }}
                                        />
                                    </div>
                                </LocalizedLink>
                            </div>
                        ) : (
                            <div className="col-12 col-lg-6">
                                {this.props.canViewMktools ? (
                                    <LocalizedLink
                                        routeId="ROUTE_CATALOG"
                                        queryString="?webHeading[0]=MK"
                                        params={{ outlet: '' }}
                                        onClick={() => this.props.changeCenter(this.props.showroomCenterId)}
                                    >
                                        <LazyImage preview={imageMktoolsThumb} image={imageMktools} type="div" extraClass="left image-slide">
                                            <div className="slide-title">
                                                <h2 className="title">
                                                    <Translation id="marketing_tools" defaultMessage="Marketing Tools" />
                                                </h2>
                                                <Button inline gentle size="medium">
                                                    <Translation id="shop_here" defaultMessage="Buy here" />
                                                </Button>
                                            </div>
                                        </LazyImage>
                                    </LocalizedLink>
                                ) : (
                                    <div>
                                        <LocalizedLink routeId="ROUTE_CUT_TO_SIZE" params={{ outlet: '' }}>
                                            <LazyImage preview={cutToSizeSection} image={cutToSizeSection} extraClass="left image-slide banner-home" type="div">
                                                <div className="slide-title-box-one">
                                                    <h2 className="title">
                                                        <Translation id="cut_to_lenght_section" defaultMessage="New Custom Products section" />
                                                    </h2>
                                                    <h3 className="subtitle">
                                                        <Translation id="materials_cut_to_size" defaultMessage="Now your materials cut to size" />
                                                    </h3>
                                                    <Button inline size="large">
                                                        <Translation id="shop_here" defaultMessage="Buy here" />
                                                    </Button>
                                                </div>
                                            </LazyImage>
                                        </LocalizedLink>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="col-12 col-lg-6">
                            <div className="row">
                                <div className="col-12">
                                    {this.props.canViewMktools ? (
                                        <LocalizedLink
                                            routeId="ROUTE_CATALOG"
                                            queryString="?webHeading[0]=SH"
                                            params={{ outlet: '' }}
                                            onClick={() => this.props.changeCenter(this.props.showroomCenterId)}
                                        >
                                            <LazyImage preview={imageShowroomThumb} image={imageShowroom} type="div" extraClass="image-slide">
                                                <div className="slide-title">
                                                    <h2 className="title">
                                                        <Translation id="showroom" defaultMessage="Showroom" />
                                                    </h2>
                                                    <Button inline size="medium">
                                                        <Translation id="shop_here" defaultMessage="Buy here" />
                                                    </Button>
                                                </div>
                                            </LazyImage>
                                        </LocalizedLink>
                                    ) : (
                                        <div>
                                            <LocalizedLink routeId="ROUTE_CATALOG" queryString="?type[0]=TABLA" params={{ outlet: '' }}>
                                                <LazyImage preview={imageSlabs} image={imageSlabs} type="div" extraClass="image-slide custom-move">
                                                    <div className="slide-title">
                                                        <h2 className="title">
                                                            <Translation id="tables" defaultMessage="Slabs" />
                                                        </h2>
                                                        <div className="subtitle-tables">
                                                            <Translation id="our_boards" defaultMessage="Our Silestione, Dekton and Sensa" />
                                                        </div>
                                                        <Button inline size="medium">
                                                            <Translation id="shop_here" defaultMessage="Buy here" />
                                                        </Button>
                                                    </div>
                                                </LazyImage>
                                            </LocalizedLink>
                                        </div>
                                    )}
                                </div>
                                <div className="col-12">
                                    {this.props.canViewMktools ? (
                                        <LocalizedLink
                                            routeId="ROUTE_CATALOG"
                                            queryString="?webHeading[0]=WH"
                                            params={{ outlet: '' }}
                                            onClick={() => this.props.changeCenter(this.props.warehoseCenterId)}
                                        >
                                            {warehouseImage}
                                        </LocalizedLink>
                                    ) : (
                                        <LocalizedLink routeId="ROUTE_CATALOG" params={{ outlet: 'outlet' }}>
                                            {outletImage}
                                        </LocalizedLink>
                                    )}
                                </div>
                            </div>
                        </div>

                        {
                            !this.props.isUsaOrCanada &&
                            <div className="col-12">
                                <BannerLechic />
                            </div>
                        }

                        {
                            this.props.isUsaOrCanada &&
                            <React.Fragment>
                                <div className="col-12 dekton-banner-desktop">
                                    <BannerDekton />
                                </div>
                                <div className="col-12 dekton-banner-squared">
                                    <BannerDekton squared={true} />
                                </div>
                            </React.Fragment>
                        }


                        {this.props.hasPromotionLandingActivated && this.props.homeBanner && (
                            <div className="col-12">
                                <LocalizedLink routeId="ROUTE_PROMOTION_LANDING" params={{ promotionId: this.props.promotionLink }}>
                                    <div className="promotion-home-banner">
                                        <div className="promotion-home-banner-text" style={{backgroundImage: 'url(' + this.props.homeBanner + ')'}}>
                                            <div dangerouslySetInnerHTML={{ __html: this.props.promotionBannerTitle }} />
                                            <div dangerouslySetInnerHTML={{ __html: this.props.promotionBannerDescription }} />
                                        </div>
                                        <div className="promotion-home-banner-image">
                                            <img src={this.props.homeBanner} style={{ width: '100%' }} alt="" className="" />
                                        </div>
                                    </div>
                                </LocalizedLink>
                            </div>
                        )}
                    </div>
                    {this.props.isLogged && !this.props.canViewMktools && <LastBoughtProductsContainer />}
                    {this.props.isLogged && (
                        <div className="row">
                            <div className="col-12">
                                <ChangeLogHomeNews />
                            </div>
                        </div>
                    )}
                    {
                        this.props.isUsaOrCanada &&
                        <div className="col-12 dekton-banner-squared">
                            <BannerDekton squared={true} />
                        </div>
                    }

                </div>

            </div>
        )
    }
}

export default injectIntl(Home)
