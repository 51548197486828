import React from 'react'
import { injectIntl } from 'react-intl'
import {  showingPedidosRecurrentesModal } from '../../store/ui/reducers'
import * as ui from '../../store/ui/actions'
import { connect } from 'react-redux'
import image from '../../assets/img/pedidos_recurrentes.png'
import Translation from '../global/Translation'

const mapStateToProps = state => {
    return {
        show: showingPedidosRecurrentesModal(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(ui.closePedidosRecurrentesModal())
        }
    }
}

class PedidosRecurrentesModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { show, onClose } = this.props
        return show && !this.props.previousPreferences ? (
            <div>
                <div className="full-overlay" style={{ display: show ? 'block' : 'none' }} />
                <div id="modal-pedidos-recurrentes-container" style={{ display: show ? 'block' : 'none' }}>
                    <div className="">
                        <div className="row no-gutters">
                            <header className="container-fluid" style={{ position: 'absolute', zIndex: '5', color: 'black' }}>
                                <div className="row">
                                    <div className="col-3 offset-9 my-auto text-right">
                                        <div className="bt-close" onClick={onClose}>
                                            <i className="fal fa-times" style={{fontSize: '2rem'}} />
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className="col-12 logo-container">
                                <div className='row'>
                                    <div className='col-sm-12 col-md-3'>
                                        <h1 className='title'>
                                            <Translation id="welcome_to_ecosentino_portal" defaultMessage="Welcome to eCosentino Portal" />
                                        </h1>
                                        <hr/>
                                        <h2 className='title-subtitle'>
                                            <Translation id="repurchase" defaultMessage="Repurchase" />
                                        </h2>
                                        <h3 className='subtitle'>
                                            <Translation id="we_have_improved_the_platform_now_try_the_launch_version_and_buy_more_easily_and_intuitively" defaultMessage="We have improved the platform, now try the launch version and buy more easily and intuitively." />
                                        </h3>
                                    </div>
                                    <div className='col-sm-12 col-md-9 box-image' style={{textAlign: 'right'}}>
                                        <div className='image'>
                                            <img className='img-fluid' src={image} alt='' />
                                        </div>
                                    </div>
                                    <div className='col-12 buttons row'>
                                        <button className='col-5 btn-old'>
                                            <Translation id="don_t_show_again" defaultMessage="Don't show again" />
                                        </button>
                                        <button className='col-5 btn-new'>
                                            <Translation id="close" defaultMessage="Close" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(PedidosRecurrentesModal)
)
