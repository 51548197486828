import { connect } from 'react-redux'
import ProductsFavourite from './ProductsFavourite'
import { getIsLoading, getProductsFavouritesByIds } from '../../store/products/reducers'
import { fetchProductsFavourite } from '../../store/products/actions'
import { getVersion } from '../../store/login/reducers'

const mapStateToProps = state => {
    return {
        products: getProductsFavouritesByIds(state),
        isLoading: getIsLoading(state),
        isNewVersion: getVersion(state),
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchProductsFavourite: () => {
            dispatch(fetchProductsFavourite())
        }
    }
}

const ProductsFavouriteContainer = connect(mapStateToProps, mapDispatchToProps)(ProductsFavourite)

export default ProductsFavouriteContainer
