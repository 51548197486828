import { connect } from 'react-redux'
import { addCartLine } from '../../store/cart/actions'
import CircleResult from './CircleResult'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import {setLastSelectedProduct} from "../../store/selected-objects/actions";

const mapStateToProps = (state, { match }) => {
    return { outlet: match.params.outlet }
}

const CircleResultContainer = withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            { addCartLine: addCartLine,
              setLastSelectedProduct: setLastSelectedProduct}
        )(CircleResult)
    )
)

export default CircleResultContainer
