import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getProfileConfiguration } from '../../store/profile-configuration/reducers'

const mapStateToProps = state => {
    return { userPreferences: getProfileConfiguration(state) }
}

const QtyMeasure = ({ userPreferences, intl, original }) => {
    const localeWithft2 = ['en-US', 'fr-CA', 'en-CA']
    const userLengthPrefer = userPreferences.lengthUnit === 'M2' ? original.qty_m2 : original.qty_ft2
    const lengthDefault = localeWithft2.includes(intl.locale) ? original.qty_ft2 : original.qty_m2
    const meters2 = userPreferences.lengthUnit ? parseFloat(userLengthPrefer).toFixed(2) : parseFloat(lengthDefault).toFixed(2)
    return meters2
}

export default injectIntl(connect(mapStateToProps, null)(QtyMeasure))
