import React, { createRef, useRef } from 'react'
import Translation from '../global/Translation'

import LocalizedLink from '../global/LocalizedLink'
import { injectIntl, defineMessages } from 'react-intl'

import { connect } from 'react-redux'
import {
    canViewCatalog,
    canViewBaths,
    canViewMktools,
    canViewOutlet,
    canViewPromotions,
    getCenterMktool,
    getCenterShowroom,
    getCenterWarehose,
    getDRIUSACenterId,
    getLowesCenterId,
    getMktoolsShippingCenter,
    getSelectedCenter,
    getSelectedCenterPreferences,
    getShippingMethod,
    getShowroomCenterId,
    getWarehoseCenterId,
    hasPromoActivated
} from '../../store/centers/reducers'
import { changeCenter } from '../../store/centers/actions'
import { canViewCutToSize, getIsLogged, getIsMallPilotUser, getUserType } from '../../store/login/reducers'
import { getUserLandings } from '../../store/landing/reducers'
import { withRouter } from 'react-router-dom'
import $ from 'jquery';
import reactDom from 'react-dom'
import { values } from 'lodash'
import { log } from 'logrocket'


defineMessages({
    STANDARD_PRODUCTS: {
        id: "standard_products",
        description: "standard_products",
        defaultMessage: 'Standard Products'
    },
    CUSTOM_PRODUCTS: {
        id: "custom_products",
        description: "custom_products",
        defaultMessage: "Custom Products"
    },
    FLOORING_CLADDING: {
        id: "flooring_cladding",
        description: "flooring_cladding",
        defaultMessage: "Flooring & Cladding"
    },
    VANITIES: {
        id: "vanities",
        description: "vanities",
        defaultMessage: "Vanities"
    },
    CUSTOM_FLOORING: {
        id: "custom_flooring",
        description: "custom_flooring",
        defaultMessage: "Custom Flooring"
    },
    BASEBOARDS: {
        id: "baseboards",
        description: "baseboards",
        defaultMessage: "Baseboards"
    },
    FURNITURE: {
        id: "furniture",
        description: "furniture",
        defaultMessage: "Furniture"
    },
    SOECIAL_CUSTOM_PROJECTS: {
        id: "special_custom_projects",
        description: "special_custom_projects",
        defaultMessage: "Special custom projects"
    },
    SCALEA: {
        id: "scalea",
        description: "scalea",
        defaultMessage: 'Scalea'
    },

    PREXURY: {
        id: "prexury",
        description: "prexury",
        defaultMessage: 'Prexury'
    },
    SENSA: {
        id: "sensa",
        description: "sensa",
        defaultMessage: 'Sensa'
    },
    COLORSIL: {
        id: "colorsil",
        description: "colorsil",
        defaultMessage: 'Colorsil'
    },
    SILESTONE: {
        id: "silestone",
        description: "silestone",
        defaultMessage: 'Silestone'
    },
    DEKTON: {
        id: "dekton",
        description: "dekton",
        defaultMessage: 'Dekton'
    },

})


class MenuLinks extends React.Component{

    
    constructor(props) {
        super(props)    
        this.extraMenu = createRef(null)
        this.state = {
            props: props,           
        }
       
    }

   

    //Funciones como en JS pero sin el Function
    componentDidMount(){
        //Esto se ejecutará según se monte el componente
        //Buscar ciclos de vida componentes react

    }

    LinkTo(responsive, routeId, queryString, params){
        return (
            <LocalizedLink routeId={routeId} queryString={queryString} params={params} className={responsive ? 'nav-link' : null}>
                <Translation id="tables" defaultMessage="Slabs" />
            </LocalizedLink>
        )
    }

    

    //Aquí la estructura HTML del menú (se ejecuta cada vez que se modifique
    //algo en el DOM)
    render() {
        //Pasarle props al container
        const{
            responsive,
            customClass,
            centerPreferences,
            mktoolShippingCenter
        } = this.state.props
        
        
        let linksMenu = [
            {
                name: "slabs",
                routeId: "ROUTE_CATALOG",
                params: { outlet: '' },
                queryString: "?type[0]=TABLA",
                translationId: 'tables',
                customClassTag: "slabs",
                show: this.props.centerPreferences.productsByType.slab && this.props.canViewCatalog,
                links: [
                    {
                        name: "Silestone",
                        queryString: "?type[0]=TABLA&brands[0]=SILESTONE",
                        routeId: "ROUTE_CATALOG",
                        translationId: '',
                        params: { outlet: '' },
                    },
                    {
                        name: "Dekton",                       
                        translationId: '',
                        queryString: "?type[0]=TABLA&brands[0]=DEKTON",
                        routeId: "ROUTE_CATALOG",
                        params: { outlet: '' },
                    },
                    {
                        name: "Scalea",                       
                        translationId: '',
                        queryString: "?type[0]=TABLA&brands[0]=SCALEA",
                        routeId: "ROUTE_CATALOG",
                    },
                    {
                        name: "Sensa",                        
                        translationId: '',
                        queryString: "?type[0]=TABLA&brands[0]=SENSA",
                        routeId: "ROUTE_CATALOG",
                    },
                    {
                        name: "Prexury",                        
                        translationId: '',
                        queryString: "?type[0]=TABLA&brands[0]=PREXURY",
                        routeId: "ROUTE_CATALOG",
                    },

                ]
            },
            {
                name: "standard products",
                routeId: "/",
                queryString: "",
                params: { outlet: '' },
                translationId: 'menu_link_standard_products',
                customClassTag: "slabs",
                show: true,
                links: [
                    {
                        name: "Flooring & Cladding",
                        queryString: "?type[0]=TABLA&subtype[0]=Optimma",
                        routeId: "ROUTE_CATALOG",
                        translationId: '',
                    },
                    {
                        name: "Sinks",
                        queryString: "?type[0]=BAÑOS&subtype[0]=Washbasin",
                        translationId: '',                       
                        routeId: "ROUTE_CATALOG",
                    },
                    {
                        name: "Shower Trays",
                        queryString: "?type[0]=BAÑOS&subtype[0]=Shower%20tray",
                        translationId: '',                        
                        routeId: "ROUTE_CATALOG",
                    },
                    {
                        name: "Vanities",
                        translationId: '',
                        queryString: "?type[0]=FREGADERO",
                        routeId: "ROUTE_CATALOG",
                    },                    

                ]
            },
            {
                name: "custom products",
                routeId: "ROUTE_CUT_TO_SIZE",
                queryString: "",
                translationId: "menu_link_custom_products",
                customClassTag: "cts",
                show: this.props.canViewCutToSize && this.props.isLogged,
                links: [
                    {
                        name: "Cut to Size",
                        queryString: "?type=solo_corte",
                        routeId: "ROUTE_CUT_TO_SIZE",
                        translationId: '',
                    },
                    {
                        name: "Custom Flooring",
                        queryString: "?type=soleria",
                        translationId: '',
                        routeId: "ROUTE_CUT_TO_SIZE",
                    },
                    {
                        name: "Baseboards",
                        queryString: "?type=rodapies",
                        translationId: '',
                        routeId: "ROUTE_CUT_TO_SIZE",
                    },
                    {
                        name: "Furniture",
                        translationId: '',
                        queryString: "",
                        routeId: "ROUTE_CUT_TO_SIZE",
                    },
                    {
                        name: "Special custom projects",
                        queryString: "?type=budget",
                        translationId: '',
                        routeId: "ROUTE_CUT_TO_SIZE",
                    },

                ]
            },
            {
                name: "ppcc",
                routeId: "ROUTE_CATALOG",
                queryString: "?type[0]=PPCC",
                translationId: "professional_products",
                params: { outlet: '' },
                customClassTag: "ppcc",
                show: this.props.centerPreferences.productsByType.complementary && this.props.canViewCatalog,
                links: [
                    {
                        name: "Soulmastik",
                        queryString: "?type[0]=PPCC&subtype[0]=Solumastik",                        
                        translationId: '',
                        routeId: "ROUTE_CATALOG",
                        params: { outlet: '' },
                    },
                    {
                        name: "Colorsil",
                        queryString: "?type[0]=PPCC&subtype[0]=Colorsil",
                        translationId: '',
                        routeId: "ROUTE_CATALOG",
                    },
                    {
                        name: "Mastidek",
                        queryString: "?type[0]=PPCC&subtype[0]=Mastidek",
                        translationId: '',
                        routeId: "ROUTE_CATALOG",
                    },
                    {
                        name: "Colordek",
                        translationId: '',
                        queryString: "?type[0]=PPCC&subtype[0]=Colordek",
                        routeId: "ROUTE_CATALOG",
                    },
                    {
                        name: "Abrasive",
                        queryString: "?type[0]=PPCC&subtype[0]=ABRASIVE",
                        translationId: '',
                        routeId: "ROUTE_CATALOG",
                    },
                    {
                        name: "Others",
                        queryString: "?type[0]=PPCC",
                        translationId: '',
                        routeId: "ROUTE_CATALOG",
                    },

                ]
            },
            {
                name: "outlet",
                routeId: "ROUTE_CATALOG",
                queryString: "",
                translationId: "outlet",
                params: { outlet: 'outlet' },
                customClassTag: "outlet",
                show: this.props.canViewCatalog,
                links: [
                    {
                        name: "Silestone",
                        queryString: "?brands[0]=SILESTONE",
                        routeId: "ROUTE_CATALOG",
                        translationId: '',      
                        params: { outlet: 'outlet' },                  
                    },
                    {
                        name: "Dekton",
                        translationId: '',
                        queryString: "?brands[0]=DEKTON",
                        routeId: "ROUTE_CATALOG",                        
                    },
                    {
                        name: "Scalea",
                        translationId: '',
                        queryString: "?brands[0]=SCALEA",
                        routeId: "ROUTE_CATALOG",
                    },
                    {
                        name: "Sensa",
                        translationId: '',
                        queryString: "?brands[0]=SENSA",
                        routeId: "ROUTE_CATALOG",
                    },                    


                ]
            },
            
        ]
        let links = [
            {
                name: "slabs",
                routeId: "ROUTE_CATALOG",
                queryString: "?type[0]=TABLA",
                params: { outlet: '' },
                translationId: 'tables',
                customClassTag: "slabs",
                show: this.props.centerPreferences.productsByType.slab &&  this.props.canViewCatalog  
            },           
            
            {
                name: "baths",
                routeId: "ROUTE_CATALOG",
                queryString: "?type[0]=BAÑOS",
                translationId: "baths_and_wall_cladding",
                params: { outlet: '' },
                customClassTag: "elaborados",
                show: this.props.centerPreferences.productsByType.bath && this.props.canViewCatalog
            },
            {
                name: "ppcc",
                routeId: "ROUTE_CATALOG",
                queryString: "?type[0]=PPCC",
                translationId: "professional_products",
                params: { outlet: '' },
                customClassTag: "ppcc",
                show: this.props.centerPreferences.productsByType.complementary && this.props.canViewCatalog
            },
             {
                name: "sinks",
                routeId: "ROUTE_CATALOG",
                queryString: "?type[0]=TABLA",
                translationId: "sinks",
                params: { outlet: '' },
                customClassTag: "sinks",
                show: this.props.centerPreferences.productsByType.sink && this.props.canViewCatalog
            },
             {
                name: "outlet",
                routeId: "ROUTE_CATALOG",
                queryString: "",
                translationId: "outlet",
                params: { outlet: 'outlet' },
                customClassTag: "outlet",
                show: this.props.canViewCatalog
            },
            {
                name: "cutToSize",
                routeId: "ROUTE_CUT_TO_SIZE",
                queryString: "",
                translationId: "cut_to_size",
                customClassTag: "cts",
                show: this.props.canViewCutToSize && this.props.isLogged
            },
            {
                name: "promo",
                routeId: "ROUTE_CATALOG",
                queryString: "?type[0]=TABLA&promo=true",
                translationId: "promotions",
                params: { outlet: '' },
                customClassTag: "",
                show: this.props.hasPromoActivated
            },
             {
                name: "lowesBrochures",
                routeId: "ROUTE_CATALOG_LOWES",
                queryString: "",
                translationId: "brochures",
                params: { filter: 'brochures' },
                customClassTag: "",
                show: this.props.userType !== "Admin"
            },
             {
                name: "lowesCustomerSamples",
                routeId: "ROUTE_CATALOG_LOWES",
                queryString: "",
                translationId: "customer_samples",
                params: { filter: 'customer-samples' },
                customClassTag: "",
                show: this.props.userType !== "Admin"
            },
            {
                name: "lowesAdmin",
                routeId: "ROUTE_CATALOG_LOWES_ADMIN",
                queryString: "",
                translationId: "lowes",
                params: { filter: 'home' },
                customClassTag: "",
                show: this.props.userType === "Admin"
            },
            {
                name: "profile",
                routeId: "ROUTE_PROFILE",
                queryString: "",
                translationId: "profile",
                customClassTag: "profile",
                params: { filter: 'configuration', open: '' },
                show: this.props.canViewCatalog
            },
            {
                name: "mkTool",
                routeId: "ROUTE_CATALOG",
                queryString:  mktoolShippingCenter ? `?webHeading[0]=MK&mktoolShippingCenter[0]=${mktoolShippingCenter}` : '?webHeading[0]=MK',
                translationId: "mktool",
                params: { outlet: '' },
                customClassTag: "",
                show: this.props.canViewCatalog && this.props.canViewMktools && this.props.centerMktool && (this.props.selectedCenter === this.props.showroomCenterId || this.props.selectedCenter === this.props.DRIUSACenterId)
            },
            {
                name: "showRoom",
                routeId: "ROUTE_CATALOG",
                queryString: mktoolShippingCenter ? `?webHeading[0]=SH&mktoolShippingCenter[0]=${mktoolShippingCenter}` : '?webHeading[0]=SH',
                translationId: "showroom",
                params: { outlet: '' },
                customClassTag: "",
                show: this.props.canViewCatalog && this.props.canViewMktools && this.props.centerShowroom && (this.props.selectedCenter === this.props.showroomCenterId || this.props.selectedCenter === this.props.DRIUSACenterId)
            },
             {
                name: "warehouse",
                routeId: "ROUTE_CATALOG",
                queryString: "?webHeading[0]=WH",
                params: { outlet: '' },
                translationId: "warehouse",
                customClassTag: "",
                show: this.props.canViewCatalog && this.props.canViewMktools && this.props.centerWarehouse && this.props.selectedCenter === this.props.warehoseCenterId
            }
        ]

        const LinkList = ({ children, responsive, customClass = "", index}) => 
            <li className={responsive ? 'nav-item ' + customClass : customClass}  onClick={() => {
                if (customClass) {
                    let elementsClass = customClass.split(' ')
                    console.log({"selector": elementsClass});
                    let selector = (elementsClass[0].length > 0 ? `.${elementsClass[0]}` : "") + (elementsClass[1].length > 0 ? `.${elementsClass[1]}` : "")  
                    let element = selector !== "" ? document.querySelectorAll(selector) : null
                    if (element && element[0]) {
                        if (element[0].children && element[0].children[0]) {
                            element[0].children[0].click()
                        }
                    }
                }
                if(responsive) {
                    [...document.querySelectorAll(`#extra-menu-${index}`)].map(e => {
                        e.hidden = !e.hidden
                    });
                    [...document.querySelectorAll(`#arrow-down-${index}`)].map(e => {
                        e.hidden = !e.hidden
                    });
                    [...document.querySelectorAll(`#arrow-up-${index}`)].map(e => {
                        e.hidden = !e.hidden
                    });
                }
            }}>{children}</li>

        return(
               <React.Fragment>
                        {
                            !this.props.comp &&
                            links.map((value, index) => (
                                value.show &&
                                <LinkList className='link-hover'  responsive={responsive} params={values.params ? values.params : {}} customClass={(customClass ? customClass : "") + ` ${value.customClassTag}`} key={index}>
                                    <LocalizedLink routeId={value.routeId} queryString={value.queryString} params={value.params} className={responsive ? 'nav-link' : null}>
                                        <Translation id={value.translationId} />
                                    </LocalizedLink>
                                </LinkList>
                            ))
                        }
                        {
                            this.props.comp &&
                            <ul className={`nav-burger ${responsive ? "responsive" : ""}`} onMouseOver={() => {
                                    if(!responsive) {
                                        [...document.querySelectorAll(".header-links-separator")].map(e => e.hidden = false);
                                        [...document.querySelectorAll(".extra-menu")].map(e => { e.hidden = false; e.classList.add("fade-in") })                                
                                    }
                                
                                }} onMouseOut={() => {    
                                    if(!responsive) {
                                        [...document.querySelectorAll(".header-links-separator")].map(e => e.hidden = true);
                                        [...document.querySelectorAll(".extra-menu")].map(e => { e.hidden = true; e.classList.remove("fade-in") })
                                    }                          
                                    
                                }}>
                                    {
                                        linksMenu.map((value, index) => (
                                            value.show &&
                                            <LinkList className='link-hover' index={index} responsive={responsive} params={values.params ? values.params : {}} customClass={(customClass ? customClass : "") + ` ${value.customClassTag} header-menu-link ${responsive ? "responsive" : ""}`} key={index}>
                                                    
                                                    <a className='a-hover-menu'>
                                                        <Translation id={value.translationId} />
                                                    
                                                        {
                                                                responsive &&
                                                                <React.Fragment>
                                                                    <span className='arrow-down' id={`arrow-down-${index}`}><i class={`fal fa-chevron-down`}></i></span>
                                                                    <span className='arrow-down' id={`arrow-up-${index}`} hidden={true}><i class={`fal fa-chevron-up`}></i></span>
                                                                </React.Fragment>
                                                        } 
                                                        
                                                        </a>
                                                        <hr className='header-links-separator' hidden={true}></hr>
                                                    <div className="extra-menu" id={`extra-menu-${index}`} hidden={true}>
                                                        <ul className={`group-links header-menu-link`}>
                                                            {
                                                                value.links &&
                                                                value.links.map((link, i) => (

                                                                    <LinkList className='link-hover' responsive={responsive} params={values.params ? values.params : {}} key={i}>
                                                                        <LocalizedLink routeId={link.routeId} header={true} params={value.params} queryString={link.queryString} className={`${responsive ? 'nav-link' : null}`}>
                                                                            {
                                                                                link.translationId !== "" &&
                                                                                <Translation id={link.translationId} />
                                                                            }
                                                                            {
                                                                                link.translationId === "" &&
                                                                                <React.Fragment>{link.name}</React.Fragment>
                                                                            }
                                                                            
                                                                        </LocalizedLink>
                                                                    </LinkList>

                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </LinkList>
                                        ))
                                    }
                            {
                                    this.state.props.isMallPilotUser && this.state.props.userLandings && this.state.props.userLandings.length > 0 &&
                                (
                                <LinkList
                                    className={responsive ? 'nav-link' : null}
                                    routeId="ROUTE_LANDING_TEMPLATE"
                                    /*params={{ slug: this.state.props.userLandings[0]['slug'], outlet: '', preview: '', iso: '' }}*/
                                    queryString=""
                                    index={linksMenu.length}
                                >
                                            <LocalizedLink routeId={"ROUTE_LANDING_TEMPLATE"} header={true} params={{ slug: this.state.props.userLandings['slug'], outlet: '', preview: '', iso: '' }} queryString={""} className={`a-hover-menu ${responsive ? 'nav-link' : null}`}>
                                            <Translation id={"Mall"} defaultMessage="Mall" />                                        
                                        </LocalizedLink>
                                    <hr className='header-links-separator' hidden={true}></hr>
                                </LinkList>
                                )    
                            }                        
                            </ul>
                        }
                        
                        
                        
                    </React.Fragment>
        )
    }

}

export default MenuLinks;
