import * as api from '../api'

export const fetchInfoEnv = () => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_INFO_DEV_REQUEST'
    })
    return api
        .fetchInfoEnv()
        .then(response => {
            dispatch({ type: 'FETCH_INFO_DEV_SUCCESS', infoEnv: response.data })
        })
        .catch(() => {
            dispatch({ type: 'FETCH_INFO_DEV_FAILURE', extra: 'do nothing' })
        })
}
