import React from 'react'
import Translation from '../global/Translation'
import iconLogoutV2 from '../../assets/img/icons/logoutv2.svg'
import MiniCartContainer from '../mini-cart/MiniCartContainer'
import iconProfile from '../../assets/img/icons/user.svg'
import iconProfileInverted from '../../assets/img/icons/user-inverted.svg'
import iconChatter from '../../assets/img/icons/chatter.svg'
import iconCart from '../../assets/img/icons/carrito.svg'
import iconBars from '../../assets/img/icons/bars.svg'
import iconList from '../../assets/img/icons/list.svg'
import closeViewOn from '../../assets/img/icons/close-black.svg'
import ImpersonateContainer from '../impersonate/ImpersonateContainer'
import ProfileModalContainer from '../profile-modal/ProfileModalContainer'
import Button from '../global/Button'
import GoChatter from '../global/GoChatter'
import CrossSellingModal from '../cross-selling/CrossSellingModal'
import LocalizedLink from '../global/LocalizedLink'

export default class IconButtons extends React.Component {
    render() {
        const totalBadge = this.props.isLogged && this.props.totalQty ? <span className="badge badge-pill badge-secondary"><span>{this.props.totalQty}</span></span> : null
        const countReservationsBadge =
            this.props.isLogged && this.props.countReservations ? <span className="badge badge-pill badge-secondary"><span>{this.props.countReservations}</span></span> : null
        const changeLogBadge =
            this.props.selectedCenter !== this.props.LowesCenterId && this.props.isLogged && !this.props.changesRead ? (
                <span className="badge badge-pill badge-primary">!</span>
            ) : null
        const miniCart =
            this.props.isLogged && this.props.canViewCart ? (
                this.props.newVersion ? (
                    <li>
                        {this.props.totalQty > 0 ? (
                            <LocalizedLink routeId="ROUTE_CHECKOUT">
                                <span
                                    id="cart-doll"
                                    className="cart"
                                    data-cy="user_menu__cart_doll"
                                >
                                    <img alt="" className={this.props.isAddingCallApi ? 'icon shake-horizontal-slow shake-constant' : 'icon'} src={iconCart} />
                                    {totalBadge}
                                </span>
                            </LocalizedLink>
                        ) : (
                            <span
                                id="cart-doll"
                                className="cart"
                                data-cy="user_menu__cart_doll"
                            >
                                <img alt="" className={this.props.isAddingCallApi ? 'icon shake-horizontal-slow shake-constant' : 'icon'} src={iconCart} />
                                {totalBadge}
                            </span>
                        )}
                    </li>
                    ) : (
                    <li>
                        <span
                            id="cart-doll"
                            className="cart"
                            data-cy="user_menu__cart_doll"
                            onClick={() => {
                                this.props.onToggleCart(this.props.showCart)
                            }}
                        >
                            <img alt="" className={this.props.isAddingCallApi ? 'icon shake-horizontal-slow shake-constant' : 'icon'} src={iconCart} />
                            {totalBadge}
                        </span>
                    </li>
                    )
            ) : null

        const impersonateButton = this.props.canImpersonate ? (            
            <li className="d-inline">
                <span
                    id="impersonate-doll"
                    data-cy="user_menu__impersonate_doll"
                    onClick={() => {
                        this.props.onToggleImpersonate(this.props.showImpersonate)
                    }}
                >
                    <img alt="" className="icon" src={iconList} />
                </span>
            </li>
        ) : null
        const userBadge = this.props.isLogged ? (
            <li className="">
                <span
                    id="profile-doll"
                    className="cart"
                    data-cy="user_menu__profile_doll"
                    onClick={() => {                        
                        if (!this.props.showMenu) this.props.onToggleMenu(this.props.showMenu)
                        if (this.props.showMenu && this.props.showPersonalData) {
                            this.props.onToggleMenu(this.props.showMenu)
                        }
                        this.props.onTogglePersonalData(this.props.showPersonalData)
                    }}
                >
                    <img alt="" src={this.props.userimage || iconProfile} className="icon" style={{ borderRadius: this.props.userimage ? '100%' : '' }} />
                    {this.props.newVersion && (
                        <span className="d-none d-xl-inline my-account-text">
                            <Translation id="my_account" defaultMessage="My account" />
                        </span>
                    )}
                    {countReservationsBadge}
                    {this.props.userType === 'ADMIN' ? changeLogBadge : null}
                </span>
            </li>
        ) : (
            <li className="d-none d-lg-inline">
                <span className="cart" onClick={this.props.onShowUpLogin}>
                    <Button size="medium" inverted style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} dataQas="global_header-login-button-desktop">
                        <img alt="" src={iconProfileInverted} style={{margin:'0px 5px',width:'16px',position:'relative',top:'-1px'}} />
                        <Translation id="login" defaultMessage="Login" />
                    </Button>
                </span>
            </li>
        )
        const goChatterButton =
            !this.props.newVersion && (this.props.isAdmin || this.props.impersonatedBy) ? (
                <li className="d-none d-lg-inline">
                    <GoChatter>
                        <img alt="" className="icon" src={iconChatter} />
                    </GoChatter>
                </li>
            ) : null        
    

        const userProfile2 = (
            <div className="d-flex flex-column menu-configuration my-account-header">
                {
                    <React.Fragment>
                        <div className="linkMenu" style={{ padding: '0.5rem 1rem', fontWeight: '300' }}>
                            <div className="mr-3">
                                <i className="fal fa-user fa-lg" />
                            </div>
                            <div>
                                <span>
                                    {this.props.name} {this.props.employeeId && <strong>{this.props.employeeId}</strong>}
                                    <br></br>
                                    <span className="txt-username">
                                        {this.props.userType === 'SHOP' || this.props.userType === 'LOWES' ? this.props.email : this.props.username}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </React.Fragment>
                }

                {this.props.canRouteConfiguration && (
                    <LocalizedLink
                        className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                            window.location.pathname.indexOf('configuration') !== -1 ? 'active' : ''
                        } linkMenu`}
                        routeId="ROUTE_PROFILE"
                        params={{ filter: 'configuration', open: '' }}
                        onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                    >
                        {this.props.isLowesCenter ? (
                            <Translation id="store_profile" defaultMessage="Store Profile" />
                        ) : (
                            <Translation id="configuration" defaultMessage="Configuration" />
                        )}
                    </LocalizedLink>
                )}

                {this.props.userType === 'SHOP' && (
                    <React.Fragment>
                        <LocalizedLink
                            routeId="ROUTE_RESERVATIONS"
                            params={{ unassigned: 'unassigned' }}
                            className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                                window.location.pathname.indexOf('orders') !== -1 && window.location.pathname.indexOf('recurring-orders') === -1 ? 'active' : ''
                            } linkMenu`}
                        >
                            <li className="nav-item">
                                <Translation id="unassigned_reservations" defaultMessage="Unassigned reservations" />{' '}
                                {this.props.countReservations ? (
                                    <span className="badge badge-pill badge-secondary">
                                        <span>{this.props.countReservations}</span>
                                    </span>
                                ) : null}
                            </li>
                        </LocalizedLink>
                        <LocalizedLink
                            routeId="ROUTE_RESERVATIONS"
                            params={{ unassigned: '' }}
                            className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                                window.location.pathname.indexOf('orders') !== -1 && window.location.pathname.indexOf('recurring-orders') === -1 ? 'active' : ''
                            } linkMenu`}
                        >
                            <li className="nav-item">
                                <Translation id="reservations_history" defaultMessage="Reservations history" />
                            </li>
                        </LocalizedLink>
                    </React.Fragment>
                )}
                {(this.props.userType === 'CUSTOMER' || this.props.isLowesCenter) && (
                    <LocalizedLink
                        className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                            window.location.pathname.indexOf('orders') !== -1 && window.location.pathname.indexOf('recurring-orders') === -1 && window.location.pathname.indexOf('pre-orders-cts') === -1 ? 'active' : ''
                        } linkMenu`}
                        routeId="ROUTE_PROFILE"
                        params={{ filter: 'orders', open: '' }}
                        onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                    >
                        <Translation id="orders_history" defaultMessage="Orders history" />
                    </LocalizedLink>
                )}

                {this.props.userType === 'CUSTOMER' && this.props.hasPreOrder && (
                    <LocalizedLink
                        className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                            window.location.pathname.indexOf('pre-orders-cts') !== -1 ? 'active' : ''
                        } linkMenu`}
                        routeId="ROUTE_PROFILE"
                        params={{ filter: 'pre-orders-cts', open: '' }}
                        onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                    >
                        <Translation id="pre_orders_cts" defaultMessage="Pre Orders CTS" />
                    </LocalizedLink>
                )}

                {this.props.canRouteOrders && this.props.userType === 'CUSTOMER' && this.props.isIkeaCenter && (
                    <LocalizedLink
                        className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                            window.location.pathname.indexOf('orders') !== -1 && this.props.match.params.open === 'open' ? 'active' : ''
                        } linkMenu`}
                        routeId="ROUTE_PROFILE"
                        params={{ filter: 'orders', open: 'open' }}
                        onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                    >
                        <Translation id="open_orders" defaultMessage="Open orders" />
                    </LocalizedLink>
                )}

                {this.props.canRouteOfferStatusHistory && (
                    <LocalizedLink
                        className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                            window.location.pathname.indexOf('offers') !== -1 ? 'active' : ''
                        } linkMenu`}
                        routeId="ROUTE_PROFILE"
                        params={{ filter: 'offers', open: '' }}
                        onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                    >
                        <Translation id="quotes" defaultMessage="Quotes" />
                    </LocalizedLink>
                )}

                {this.props.canRouteTransfers && (
                    <LocalizedLink
                        className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                            window.location.pathname.indexOf('transers') !== -1 ? 'active' : ''
                        } linkMenu`}
                        routeId="ROUTE_PROFILE"
                        params={{ filter: 'transfers', open: '' }}
                        onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                    >
                        <Translation id="transfer_orders" defaultMessage="Transfer orders" />
                    </LocalizedLink>
                )}

                {this.props.userType === 'CUSTOMER' && !this.props.isMktoolsCenter && !this.props.isIkeaCenter && !this.props.isLowesCenter && (
                    <LocalizedLink
                        className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                            window.location.pathname.indexOf('recurring-orders') !== -1 ? 'active' : ''
                        } linkMenu`}
                        routeId="ROUTE_PROFILE"
                        params={{ filter: 'recurring-orders', open: '' }}
                        onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                        gtmLabel="ec_volver_a_comprar"
                    >
                        <Translation id="recurring_orders_access" defaultMessage="Recurring orders access" />
                    </LocalizedLink>
                )}

                {this.props.userType === 'ADMIN' && this.props.isMktoolsCenter && this.props.isMktoolsAdmin && (
                    <React.Fragment>
                        <LocalizedLink
                            className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                                window.location.pathname.indexOf('mkt-admin-products') !== -1 ? 'active' : ''
                            } linkMenu`}
                            routeId={'ROUTE_MKT_ADMIN_PRODUCTS'}
                            params={{ filter: 'mkt-admin-products', open: '' }}
                            onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                        >
                            <Translation id="mkt_admin_products" defaultMessage="Mktools admin" />
                        </LocalizedLink>
                        <LocalizedLink
                            className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                                window.location.pathname.indexOf('mkt-admin-colors') !== -1 ? 'active' : ''
                            } linkMenu`}
                            routeId={'ROUTE_MKT_ADMIN_COLORS'}
                            params={{ filter: 'mkt-admin-colors', open: '' }}
                            onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                        >
                            <Translation id="mkt_admin_colors" defaultMessage="Admin colors" />
                        </LocalizedLink>
                    </React.Fragment>
                )}

                <LocalizedLink
                    className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                        window.location.pathname.indexOf('segmentation') !== -1 ? 'active' : ''
                    } linkMenu`}
                    routeId="ROUTE_PROFILE"
                    params={{ filter: 'segmentation', open: '' }}
                    onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                >
                    <Translation id={'segmentation'} defaultMessage="Segmentation" />
                </LocalizedLink>

                <LocalizedLink
                    className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                        window.location.pathname.indexOf('products-favourite') !== -1 ? 'active' : ''
                    } linkMenu`}
                    routeId="ROUTE_PROFILE"
                    params={{ filter: 'products-favourite', open: '' }}
                    onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                >
                    <Translation id="products_favourite" defaultMessage="Favourite Products" />
                </LocalizedLink>

                {this.props.isUserMallAuthorized && this.props.isMallAdmin && (
                    <LocalizedLink
                        routeId="ROUTE_LANDING_MANAGER"
                        queryString=""
                        className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                            window.location.pathname.indexOf('landing-manager') !== -1 ? 'active' : ''
                        } linkMenu`}
                        params={{ filter: 'landing-manager', open: '' }}
                        onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                    >
                        <Translation id="lndmngr" defaultMessage="Landing Manager" />
                    </LocalizedLink>
                )}

                {this.props.isCampaignAdmin && (
                    <LocalizedLink
                        routeId={'ROUTE_PROMOTIONS_LIST'}
                        className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                            window.location.pathname.indexOf('promotions-list') !== -1 ? 'active' : ''
                        } linkMenu`}
                        params={{ filter: 'promotions-list', open: '' }}
                        onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                    >
                        <li className="nav-item">
                            <Translation id="admin_campaigns" defaultMessage="Admin campaigns" />
                        </li>
                    </LocalizedLink>
                )}

                {(process.env.NODE_ENV !== 'production' || process.env.REACT_APP_ENV === 'qas') && (
                    <a
                        className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} linkMenu`}
                        onClick={() => {
                            this.props.onTogglePersonalData(this.props.showPersonalData)
                            this.props.toggleVersion()
                        }}
                    >
                        <Translation id="swap_version" defaultMessage="Swap version" />
                    </a>
                )}

                <span
                    className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} linkMenu linkLogout`}
                    onClick={() => {
                        this.props.onTogglePersonalData(this.props.showPersonalData)
                        if (this.props.msalContext.accounts && this.props.msalContext.accounts[0]) {
                            this.props.msalContext.instance.logoutRedirect()
                        }
                        this.props.onLogout()
                    }}
                >
                    <div className="d-flex">
                        <div className="icon" style={{ marginRight: '8px' }}>
                            <img alt="" src={iconLogoutV2} aria-hidden="true" />
                        </div>
                        <Translation id="logout" defaultMessage="Logout" />
                    </div>
                </span>
            </div>
        )

        const logButton = (
            <li className="login-btn-responsive d-lg-none">
                <span className="cart" onClick={this.props.onShowUpLogin}>
                    <Button size="medium" inverted style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} dataQas="global_header-login-button-responsive">
                        <img alt="" src={iconProfileInverted} style={{ margin: '0px 5px', width: '16px', position: 'relative', top: '-1px' }} />
                        <Translation id="login" defaultMessage="Login" />
                    </Button>
                </span>
            </li>
        )

        return (
            <div>
                <nav id="top-side-menu" onMouseLeave={() => {
                    //this.props.onTogglePersonalData(true)                    
                    //this.props.onTogglePersonalData(true)                    
                }}>
                    
                    {this.props.isLogged && <ProfileModalContainer />}
                    {this.props.canImpersonate && <ImpersonateContainer />}
                    {/*MiniCartContainer only shows in V1 I believe*/}
                    {this.props.isLogged && this.props.canViewCart && <MiniCartContainer />}

                    <ul>
                        
                        {goChatterButton}
                        {impersonateButton}
                        {miniCart}
                        {userBadge}
                        {
                            this.props.showPersonalData &&  window.innerWidth > 1199 && 
                            <React.Fragment>
                                <div className="modal-overlay" id='profile-modal-bg' style={{
                                    display: 'block',
                                }} onClick={() => {
                                    this.props.onTogglePersonalData(this.props.showPersonalData)
                                }} />
                                {userProfile2}
                            </React.Fragment>
                        }
                      {
                        !this.props.isLogged &&
                        logButton
                      }
                        
                        <li className="d-xl-none burger-menu">
                            <span
                                id="menu-doll"
                                className="cart"
                                onClick={() => {
                                    if (this.props.showPersonalData){
                                        this.props.onTogglePersonalData(this.props.showPersonalData)
                                    }else{
                                        this.props.onToggleMenu(this.props.showMenu)
                                    }
                                }}
                            >
                                <img alt="" className={`icon ${!this.props.showMenu ? "" : (this.props.showPersonalData ? "" : "close-btn-icon") }`} src={!this.props.showMenu ? iconBars : (this.props.showPersonalData ? iconBars : closeViewOn)} />
                            </span>
                        </li>
                        
                    </ul>
                </nav>
                {this.props.canViewCrossellin && <CrossSellingModal />}                
            </div>
        )
    }
}
