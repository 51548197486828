import React from 'react'
import LocalizedLink from '../global/LocalizedLink'
import Translation from '../global/Translation'
import OUTLET_IMAGE from '../../assets/img/banners-v2/outlet_2.png'
import Button from '../global/Button'

export default class BannerOutlet extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <LocalizedLink
                routeId="ROUTE_CATALOG"
                queryString="?type[0]=TABLA"
                params={{ outlet: '' }}
            >
                <div className="box-parent-banner-outlet" style={{
                    backgroundImage: `url(${OUTLET_IMAGE})`,
                }}>
                    <div className="box-container">
                        <div className="box-title">
                        <Translation id="cosentino_outlet" defaultMessage="Cosentino Outlet" />
                        </div>
                        <div className="box-subtitle">
                            <Translation id="find_special_discounts" defaultMessage="Find special discounts on our boards for all available thicknesses" />
                        </div>
                        <div className="box-button-container">
                            <div className="bt bt-inverted">
                                <LocalizedLink routeId='ROUTE_CATALOG' queryString='?type[0]=TABLA' params={{ outlet: 'outlet' }}>
                                    <Translation id="shop_here" defaultMessage="Buy here" />
                                </LocalizedLink>
                            </div>
                        </div>
                    </div>
                </div>
            </LocalizedLink>
        )
    }
}
