import { connect } from 'react-redux'
import ProfileConfiguration from './ProfileConfiguration'
import { getCenters, getIsIkeaCenter } from '../../store/centers/reducers'
import { getEmail, getIsAdmin, getUserName, getVersion } from '../../store/login/reducers'
import {
    getProfileConfiguration,
    getProfileConfigurationLoading,
    getProfileConfigurationAlert,
    getProfileConfigurationStatus,
    getProfileConfigurationSending,
    getProfileConfigurationAlertOfPassword,
    getProfileConfigurationStatusOfPassword,
    getPromotionConfiguration,
    getPromotionConfigurationEmails,
    getPromotionConfigurationAcceptPromotionNotifications,
    getPromotionConfigurationPromotionCopyEmailAddress,
    getPromotionConfigurationAcceptPromotionPolicies, getProfileConfigurationEmails
} from '../../store/profile-configuration/reducers'
import {
    fetchProfileConfiguration,
    saveProfileConfiguration,
    changePassword,
    fetchPromotionConfiguration,
    savePromotionConfiguration
} from '../../store/profile-configuration/actions'
import { getAvailableLands } from '../../store/ui/reducers'
import { getShippingAddresses } from '../../store/shipping-addresses/reducers'
import { createAccount } from '../../store/login/actions'
import { addEmailCenter } from '../../store/login/actions'

const mapStateToProps = (state, ownProps) => {
    return {
        email: getEmail(state),
        profileConfiguration: getProfileConfiguration(state),
        centers: getCenters(state),
        isLoading: getProfileConfigurationLoading(state),
        isSending: getProfileConfigurationSending(state),
        alert: getProfileConfigurationAlert(state),
        alertOfPassword: getProfileConfigurationAlertOfPassword(state),
        status: getProfileConfigurationStatus(state),
        statusOfPassword: getProfileConfigurationStatusOfPassword(state),
        availableLands: getAvailableLands(state),
        isIkeaCenter: getIsIkeaCenter(state),
        shippingAddresses: getShippingAddresses(state),
        promotionConfiguration: getPromotionConfiguration(state),
        isAdmin: getIsAdmin(state),
        userName: getUserName(state),
        newVersion: getVersion(state),
        emails: ownProps.emails ? ownProps.emails : getProfileConfigurationEmails(state),
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchProfileConfiguration: () => dispatch(fetchProfileConfiguration()),
        saveProfileConfiguration: profileConfigData => {
            dispatch(saveProfileConfiguration(profileConfigData))
        },
        changePassword: pass => {
            return dispatch(changePassword(pass))
        },
        fetchPromotionConfiguration: () => dispatch(fetchPromotionConfiguration()),
        savePromotionConfiguration: profileConfigPromotion => {
            dispatch(savePromotionConfiguration(profileConfigPromotion))
        },
        onCreateAccount: (username, email) => {
            dispatch(createAccount(username, email))
        },
        onAddEmailCenter: (user, email) => {
            dispatch(addEmailCenter(user, email))
        }
    }
}

const ProfileConfigurationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileConfiguration)

export default ProfileConfigurationContainer
