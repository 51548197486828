import React from 'react'
import LocalizedLink from '../global/LocalizedLink'
import Translation from '../global/Translation'
import CTS_IMAGE from '../../assets/img/banners-v2/corte.png'
import Button from '../global/Button'

export default class BannerCTS extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <LocalizedLink
                routeId="ROUTE_CATALOG"
                queryString="?type[0]=TABLA"
                params={{ outlet: '' }}
            >
                <div className="box-parent-banner-cts" style={{
                    backgroundImage: `url(${CTS_IMAGE})`,
                }}>
                    <div className="box-container">
                        <div className="box-title">
                        <Translation id="new_section_cts_title" defaultMessage="New cut-to-size section" />
                        </div>
                        <div className="box-subtitle">
                            <Translation id="new_section_cts_subtitle" defaultMessage="Now your materials cut to size" />
                        </div>
                        <div className="box-button-container">
                            <div className="bt bt-inverted">
                                <LocalizedLink routeId='ROUTE_CUT_TO_SIZE' queryString='' params={{ outlet: '' }}>
                                    <Translation id="shop_here" defaultMessage="Buy here" />
                                </LocalizedLink>
                            </div>
                        </div>
                    </div>
                </div>
            </LocalizedLink>
        )
    }
}
