import Cookies from 'cookies-js'
import * as api from '../api'
import { getToken } from '../login/reducers'

export const setLastView = () => {
    Cookies.set('changeLogLastView', Date.now())
    return {
        type: 'SET_LAST_VIEW',
        date: parseInt(Cookies.get('changeLogLastView'), 10)
    }
}
export const fetchChangelogByProject = onlyNews => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_CHANGELOG_BY_PROJECT_REQUEST'
    })
    return api
        .fetchChangelogByProject(getToken(getState()), onlyNews)
        .then(response => {
            if (onlyNews) dispatch({ type: 'FETCH_CHANGELOG_BY_PROJECT_NEWS_SUCCESS', items: response.data })
            else dispatch({ type: 'FETCH_CHANGELOG_BY_PROJECT_SUCCESS', items: response.data })
        })
        .catch(() => {
            dispatch({ type: 'FETCH_CHANGELOG_BY_PROJECT_FAILURE', extra: 'do nothing' })
        })
}
