import React from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import ProductDisplayName from '../global/ProductDisplayName'
import ProductImage from '../catalog/ProductImage'
import DisplayBrand from '../catalog/rectangleResult/displays/DisplayBrand'
import LineSegmentation from './LineSegmentation'
import Translation from '../global/Translation'

defineMessages({
    WALL_SAMPLE: {
        id: 'wall_sample',
        description: 'wall_sample',
        defaultMessage: 'Wall Sample'
    },
    CUSTOM_SAMPLE: {
        id: 'custom_sample',
        description: 'custom_sample',
        defaultMessage: 'Customer Sample'
    }
})
const DetailsQty = props => {
    return (
        <div className="group-details group-details__quantity">
            {props.qty !== null && (
                <div>
                    <FormattedMessage id="requestedQtyFromEcosentino" defaultMessage="requested qty on Ecosentino" />: {props.qty}
                </div>
            )}
            {props.quantity !== null && (
                <div>
                    <FormattedMessage id="quantity" defaultMessage="Quantity" />: {props.quantity}
                </div>
            )}
            {props.openQty !== null && (
                <div>
                    <FormattedMessage id="openQty" defaultMessage="open qty" />: {props.openQty}
                </div>
            )}
            {props.requestedQty !== null && (
                <div>
                    <FormattedMessage id="requestedQty" defaultMessage="requested qty" />: {props.requestedQty}
                </div>
            )}
            {props.confirmedQty !== null && (
                <div>
                    <FormattedMessage id="confirmedQty" defaultMessage="confirmed qty" />: {props.confirmedQty}
                </div>
            )}
        </div>
    )
}
class OrderLine extends React.Component {
    constructor(props) {
        super(props)
        this.state = { showHelp: false, showComment: false }
    }
    editSegmentation = () => {
        this.props.loadThisSegmentation(this.props.line.segmentation)
        this.props.showSegmentationModal('edit', () => this.props.changeCartLineSegmentation(this.props.line))
    }
    render() {
        let { line } = this.props
        if (!line || !line.lineId) return null
        let newLine = (line && line.openDocDetails && line.openDocDetails.requestedQty === 0) || line.lineFromSap
        let rejectedLine = line && (line.openDocDetails == null || line.rejected)
        const openDocDetails = line.openDocDetails ? (
            <div>
                {/* <ul> */}
                {/* <li>
                        <FormattedMessage id="productId" defaultMessage="Product ID" /> {line.openDocDetails.productId},{' '}
                    </li> */}
                {/* <li>
                        <FormattedMessage id="rejected" defaultMessage="rejected" /> {line.openDocDetails.rejected},{' '}
                    </li> */}

                {line.openDocDetails.openQty > 0 && (
                    <li>
                        <FormattedMessage id="openQty" defaultMessage="open qty" /> {line.openDocDetails.openQty},{' '}
                    </li>
                )}
                {line.openDocDetails.confirmedQty !== line.qty ? (
                    <DetailsQty
                        qty={!newLine ? line.qty : null}
                        quantity={line.openDocDetails.quantity}
                        openQty={line.openDocDetails.openQty > 0 ? line.openDocDetails.openQty : null}
                        requestedQty={line.openDocDetails.requestedQty}
                        confirmedQty={line.openDocDetails.confirmedQty}
                    />
                ) : (
                    <React.Fragment>
                        <FormattedMessage id="quantity" defaultMessage="Quantity" /> {line.qty}
                    </React.Fragment>
                )}
                {/* <li>
                        <FormattedMessage id="distributedQty" defaultMessage="distributed qty" /> {line.openDocDetails.distributedQty},{' '}
                    </li> */}
                {/*
                    <li>
                        <FormattedMessage id="distributionNumber" defaultMessage="distributionNumber" /> {line.openDocDetails.distributionNumber},{' '}
                    </li> */}
                {/* <li>
                        <FormattedMessage id="salesDocType" defaultMessage="salesDocType" /> {line.openDocDetails.salesDocType},{' '}
                    </li> */}
                {/* <li>
                        <FormattedMessage id="creationDateAtSystem" defaultMessage="creationDateAtSystem" /> {line.openDocDetails.creationDateAtSystem},{' '}
                    </li> */}
                {/* <li>
                        <FormattedMessage id="distributionDate" defaultMessage="distributionDate" /> {line.openDocDetails.distributionDate},{' '}
                    </li>
                    <li>
                        <FormattedMessage id="shipDate" defaultMessage="shipDate" /> {line.openDocDetails.shipDate},{' '}
                    </li>
                    <li>
                        <FormattedMessage id="preferredDeliveryDate" defaultMessage="preferredDeliveryDate" /> {line.openDocDetails.preferredDeliveryDate},{' '}
                    </li> */}
                {/* <li>
                        <FormattedMessage id="creationDate" defaultMessage="creationDate" /> {line.openDocDetails.creationDate},{' '}
                    </li> */}
                {/* <li>
                        <FormattedMessage id="applicant" defaultMessage="applicant" /> {line.openDocDetails.applicant},{' '}
                    </li> */}
                {/* <li>
                        <FormattedMessage id="strangething1" defaultMessage="strangething1" /> {line.openDocDetails.strangething1},{' '}
                    </li> */}
                {/* <li>
                        <FormattedMessage id="salesOrg" defaultMessage="salesOrg" /> {line.openDocDetails.salesOrg},{' '}
                    </li> */}
                {/* <li>
                        <FormattedMessage id="center" defaultMessage="center" /> {line.openDocDetails.center},{' '}
                    </li> */}
                {/* <li>
                        <FormattedMessage id="shippingAddress" defaultMessage="shippingAddress" /> {line.openDocDetails.shippingAddress},{' '}
                    </li> */}
                {/* </ul> */}
                {/* <div className="group-details group-details__date">
                    <div>
                        <FormattedMessage id="distributionDate" defaultMessage="distributionDate" />: {line.openDocDetails.distributionDate}
                    </div>
                    <div>
                        <FormattedMessage id="shipDate" defaultMessage="shipDate" />: {line.openDocDetails.shipDate}
                    </div>
                    <div>
                        <FormattedMessage id="preferredDeliveryDate" defaultMessage="preferredDeliveryDate" />: {line.openDocDetails.preferredDeliveryDate}
                    </div>
                </div> */}
                {this.props.userType === 'ADMIN' && (
                    <div className="group-details groupu-details__admin">
                        <div>
                            <FormattedMessage id="merchandiseRecipient" defaultMessage="merchandiseRecipient" />: {line.openDocDetails.merchandiseRecipient}{' '}
                        </div>
                        {/* <div>
                                <FormattedMessage id="distributionChannel" defaultMessage="distributionChannel" /> {line.openDocDetails.distributionChannel},{' '}
                            </div> */}
                    </div>
                )}
            </div>
        ) : null

        return (
            <div className={`order-item-list ${rejectedLine ? 'rejected-item-line' : newLine ? 'new-item-list' : ''}`}>
                {newLine && (
                    <div className="new-indicator">
                        <i className="fa fa-check" /> <Translation id="added_line" defaultMessage="Added line by center" />
                    </div>
                )}
                {rejectedLine && (
                    <div className="reject-indicator">
                        <i className="fa fa-times" /> <Translation id="rejected_line" defaultMessage="Rejected line" />
                    </div>
                )}

                <div>
                    <div className="order-item-list__header">
                        <div className="wrapper_header">
                            {line.product && <ProductDisplayName product={line.product} />}
                            {line.product && (
                                <span className="subtitle">
                                    <DisplayBrand brand={line.product.brand} span></DisplayBrand>
                                </span>
                            )}
                            {line.product && line.product.finish && (
                                <div className="mini_cart_line_info__line">
                                    <Translation id={line.product.finish} />
                                </div>
                            )}
                            {line.product && line.product.thickness && (
                                <div className="mini_cart_line_info__line mini_cart_line_info__line--dimensions">
                                    <span>
                                        <i className="fas fa-sort-amount-down" />
                                        {line.product.thickness} cm.{' '}
                                    </span>
                                </div>
                            )}
                            <span>
                                <strong><Translation id="product_id" defaultMessage="Product ID" /></strong>
                                {line.productId}
                            </span>
                            <div>
                                <span className="font-weight-bold">
                                    {/* {line.openDocDetails && <React.Fragment>Arrives on {moment(line.openDocDetails.distributionDate).format('dddd LL')}</React.Fragment>} */}
                                </span>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className="order-item-list__body">
                        <div className="wrapper_content">
                            <div className="warpper_img">{line.product && <ProductImage product={line.product} />}</div>
                            <div className="wrapper_open-doc-details">
                                {openDocDetails}
                                {line.segmentation && <LineSegmentation line={line} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default injectIntl(OrderLine)
