import React, {useContext} from 'react'
import Translation from '../../global/Translation'
import LocalizedLink from '../../global/LocalizedLink'
import Button from '../../global/Button'
import InputNumber from '../../global/InputNumber'
import AddFavourite from '../../global/AddFavourite'
import ProductImage from '../ProductImage'
import DisplayTechnology from './displays/DisplayTechnology'
import ProductIndicators from "./contexts/ProductIndicators";
import {ProductDetails} from "./contexts/ProductDetails";
import cameraOff from '../../../assets/img/icons/camera-off.svg'

const RectangleResultUI = (props) => {

    const limit = props.getLimit()

    const showValidImage = () => {
        if (props.product.detailImage && props.product.detailImage !== 'null' && props.product.detailImage !== undefined && props.product.detailImage !== 'undefined' ) return props.product.detailImage
        if (props.product.imgLarge && (props.product.imgLarge !== 'null' || props.product.imgLarge != 'null')) return props.product.imgLarge
        return cameraOff
    }

    const placeHolderClassName = () => {
        if((!props.product.detailImage || props.product.detailImage === 'null') || (!props.product.imgLarge || props.product.imgLarge === 'null' || props.product.imgLarge == 'null')) return '--placeholder'
        return ''
    }

    return (
        <div
            className="rectangle-result"
            data-cy="catalog__product_rectangle_result"
            data-key={props.product.productId}
            data-color={props.product.colorId}
            type={props.getTypeProductForStyles()}
        >
            <div className="wrapper-product-image">
                <DisplayTechnology technology={props.product.technology} />

                {props.indicators}

                {props.canOnlyViewOutlet ? (
                    props.product.lotViewAvailable === undefined || props.product.lotViewAvailable ? (
                        <LocalizedLink routeId="ROUTE_LOTS" params={{ id: props.product.productId, outlet: 'outlet' }}>
                            <ProductImage product={props.product} />
                        </LocalizedLink>
                    ) : (
                        <ProductImage product={props.product} />
                    )
                ) : (
                    <LocalizedLink
                        routeId="ROUTE_PRODUCT_DETAIL"
                        params={{ id: props.product.productId, outlet: props.outlet ? 'outlet' : '' }}
                        onClick={() => props.goToProductCard(props.product)}
                    >
                        {/* Show the custom upload image or normal image of Mall Campaigns */}
                        {props.product.fromMall ? (
                            <div className={'product-image-container ' + placeHolderClassName()}>
                                <div
                                    className="product-image"
                                    style={{
                                        backgroundColor: '#f6f6f6',
                                        backgroundImage: 'url("' + showValidImage() + '")',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center'
                                    }}
                                ></div>
                            </div>
                        ) : (
                            <ProductImage product={props.product} />
                        )}
                    </LocalizedLink>
                )}
                {!props.product.fromMall && <AddFavourite productId={props.product.productId} />}
            </div>

            {props.details}

            {props.canOnlyViewOutlet && (
                <div className="product-actions">
                    <div className="bt-row">
                        {(props.product.lotViewAvailable === undefined || props.product.lotViewAvailable) && (
                            <LocalizedLink routeId="ROUTE_LOTS" params={{ id: props.product.productId, outlet: 'outlet' }}>
                                <Button size="small">
                                    <Translation id="view_lots" defaultMessage="View lots" />
                                </Button>
                            </LocalizedLink>
                        )}
                    </div>
                </div>
            )}
            {props.canBuy && (
                <div className="product-actions">
                    {!props.product.haveOptions && (
                        <div className="input-row">
                            <div className="input">
                                <InputNumber
                                    value={props.getValueToAdd()}
                                    change={qty => props.state.setQtyToAdd(qty)}
                                    size="small"
                                    step={props.isIkeaCenter && props.product.unit === 'M2' ? 0.01 : props.state.step}
                                    limit={limit}
                                    min={props.getMin()}
                                    decimals={props.isIkeaCenter && props.product.unit === 'M2' ? 2 : 0}
                                />
                            </div>
                        </div>
                    )}

                    <div className="bt-row">
                        {props.product.haveOptions ? (
                            props.canOnlyViewOutlet ? (
                                (props.product.lotViewAvailable === undefined || props.product.lotViewAvailable) && (
                                    <LocalizedLink routeId="ROUTE_LOTS" params={{ id: props.product.productId, outlet: 'outlet' }}>
                                        <Button icon="shopping-cart" size="small" customClass="bt-add-cart">
                                            <Translation id="options" defaultMessage="Options" />
                                        </Button>
                                    </LocalizedLink>
                                )
                            ) : (
                                <LocalizedLink routeId="ROUTE_PRODUCT_DETAIL" params={{ id: props.product.productId, outlet: props.outlet ? 'outlet' : '' }}>
                                    <Button icon="shopping-cart" size="small" customClass="bt-add-cart">
                                        <Translation id="options" defaultMessage="Options" />
                                    </Button>
                                </LocalizedLink>
                            )
                        ) : (
                            <Button
                                icon="shopping-cart"
                                size="small"
                                customClass="bt-add-cart"
                                disabled={props.isAdding || (limit != null ? limit : 1) <= 0 || !props.state.qtyToAdd >= 1}
                                onClick={() => props.addToCart()}
                            >
                                <Translation id="add_to_cart" defaultMessage="Add to cart" />
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
    
}

export default RectangleResultUI
