import React from 'react'
import Translation from '../global/Translation'
import { connect } from 'react-redux'
import ProductDisplayName from '../global/ProductDisplayName'
import { closeOrderLineModal, toggleModalOrderLineDetail } from '../../store/orders/actions'
import { getActiveOrderLine, showModalLineDetail } from '../../store/orders/reducers'
import OrderLineModal from './OrderLineModal'
import ProductImage from '../catalog/ProductImage'
import DisplayOutletIndicator from '../catalog/rectangleResult/displays/DisplayOutletIndicator'
import DisplayTechnology from '../catalog/rectangleResult/displays/DisplayTechnology'
import mallLarge from '../../assets/img/logos/mall-large.jpg'
import {isEmserUser} from "../../store/login/reducers";
import LineSegmentation from '../cart-line/LineSegmentation'
import getNumberFormat from "../getNumberFormat";
import DisplayThickness from "../catalog/rectangleResult/displays/DisplayThickness";
import {getAdaptedFormat} from "../../helpers";

const mapStateToProps = state => {
    return {
        isEmserUser: isEmserUser(state),
        showModal: showModalLineDetail(state),
        activeOrderLine: getActiveOrderLine(state),
        adaptedFormat: (format, params) => getAdaptedFormat(format, params, state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onToggleLineDetail: orderLine => {
            dispatch(toggleModalOrderLineDetail(orderLine))
        },
        onClose: () => {
            dispatch(closeOrderLineModal())
        }
    }
}

class OrderFullMobile extends React.Component {
    render() {
        const { orderLines, lang, adaptedFormat } = this.props
    
        return (
            <React.Fragment>
                {orderLines &&
                    orderLines.length > 0 &&
                    orderLines.map(orderLine => {
                        let numberFormat = getNumberFormat(lang, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                        if(orderLine.coin === 'JPY') {
                            numberFormat = getNumberFormat(lang, { maximumFractionDigits: 0, minimumFractionDigits: 0 })
                        }
                        return (
                            <div
                                className="d-flex order-details justify-content-around"
                                style={{ borderTop: '1px solid #EBEBEB', backgroundColor: 'white', padding: '1em', gap: '1em' }}
                            >
                                <div>
                                    <ProductImage product={orderLine.product} />
                                </div>
                                <div className="order-details-txt">
                                    {orderLine.outlet_quality && <DisplayOutletIndicator outletQuality={orderLine.outlet_quality} />}
                                    {orderLine && orderLine.product && orderLine.product.productId && (
                                        <div>
                                            <b>Material: </b>
                                            <ProductDisplayName product={orderLine.product} />
                                        </div>
                                    )}
                                    {orderLine && orderLine.product && orderLine.product.brand && (
                                        <div>
                                            <b>Marca: </b>
                                            {orderLine.product.brand}
                                        </div>
                                    )}
                                    {orderLine && orderLine.product && orderLine.product.productId && (
                                        <div>
                                            <b>
                                                <Translation id="material_id" defaultMessage="Material Id" />:{' '}
                                            </b>
                                            {orderLine.product.productId}
                                        </div>
                                    )}
                                    {orderLine && orderLine.product && orderLine.product.finish && (
                                        <div>
                                            <b>
                                                <Translation id="finish" defaultMessage="Finish" />:{' '}
                                            </b>
                                            <Translation id={orderLine.product.finish} />
                                        </div>
                                    )}

                                    {orderLine.lot_id && (
                                        <div>
                                            <b>
                                                <Translation id="lot_id" defaultMessage="Lot ID" />:{' '}
                                            </b>
                                            {orderLine.lot_id}
                                        </div>
                                    )}
                                    {orderLine && orderLine.product && orderLine.product.format && (
                                        <div>
                                            <b>
                                                <Translation id="format" defaultMessage="Format" />:{' '}
                                            </b>
                                            {adaptedFormat(orderLine.product.format, { upper: true, showUnits: true })}
                                        </div>
                                    )}

                                    {orderLine && orderLine.product && orderLine.product.thickness && (
                                      <div>
                                          <b>
                                              <Translation id="thickness" defaultMessage="Thickness" />:{' '}
                                          </b>
                                          <DisplayThickness thickness={orderLine.product.thickness} string />
                                      </div>
                                    )}
                                    {orderLine.serializedLine && (
                                        <div>
                                            <b style={{ padding: 0 }}>Segmentación</b>
                                        </div>
                                    )}
                                    {orderLine.segmentation && (
                                        <div>
                                            <LineSegmentation line={orderLine} responsive={true} showAsList />
                                        </div>
                                    )}

                                    {/*{orderLine.delivery_qty && (*/}
                                    {/*    <div>*/}

                                    {/*        <b><Translation id="delivery_units" defaultMessage="Units included in shipment" />: {' '}</b>*/}
                                    {/*        {parseInt(orderLine.delivery_qty, 10)}/{parseInt(orderLine.quantity, 10)}*/}

                                    {/*    </div>*/}
                                    {/*)}*/}

                                    <div>
                                        <b>Precio: </b>
                                        {numberFormat.format(orderLine.import)} {orderLine.coin}   
                                    </div>
                                    <div>
                                        <b>Cantidad en pedido: </b>
                                        {orderLine.reservation_quantity == 0 ? (
                                            <Translation id="added_line" defaultMessage="Added line by center" />
                                        ) : (
                                            parseInt(orderLine.reservation_quantity)
                                        )}
                                    </div>
                                    <div>
                                        <b>Cantidad solicitada: </b>
                                        {orderLine.reservation_quantity == 0 ? (
                                            <Translation id="added_line" defaultMessage="Added line by center" />
                                        ) : (
                                            parseInt(orderLine.reservation_quantity)
                                        )}
                                    </div>
                                    <div>
                                        <b>Fecha de envío solicitada: </b>
                                        {orderLine.preferredDeliveryDate}
                                    </div>

                                    <div>
                                        <b>Comentarios: </b>
                                        {orderLine.comments ? orderLine.comments : '--'}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
            </React.Fragment>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderFullMobile)
