import {useEffect, useState} from 'react'

export const productLogic = (props, baseLogicFunction) => {
    const {product, getMin, getLimit, getType, getTypeProductForStyles, canDisplayDiscontinued, validateOutletPromotions, isCosentinoGlobal} = baseLogicFunction(props)

    const [qtyToAdd, setQtyToAdd] = useState(1)
    const [step, setStep] = useState(1)
    const [promotions, setPromotions] = useState([])

    useEffect(() => {
        let qtyAdd = 1
        let step = 1

        if (product && (product.typeId === '01' || product.typeId === 'NEWS')) step = 1
        else if (product.minQty[props.shippingMethod]) step = product.minQty[props.shippingMethod]
        setStep(step)

        if (getLimit() === 0) qtyAdd = 0
        else if (getMin()) qtyAdd = getMin()
        else if (product && (product.typeId === '01' || product.typeId === 'NEWS')) qtyAdd = 1
        else if (product.minQty[props.shippingMethod]) qtyAdd = product.minQty[props.shippingMethod]
        setQtyToAdd(qtyAdd)

        generatePromotionTags()
    }, [product])

    useEffect(() => {
        let qty = qtyToAdd
        if (getLimit() && getValueWithMax() < qtyToAdd) qty = getValueWithMax()
        if (getMin()) qty = Math.max(getMin(), qty)
        setQtyToAdd(qty)
    }, [qtyToAdd])

    const getExtras = () => {
        let extras = baseLogicFunction(props).getExtras()
        if (isCosentinoGlobal) {
            if (!extras) extras = {}
            extras.isCosentinoGlobal = isCosentinoGlobal
        }
        return extras
    }

    const addToCart = (extras=null) => {
        extras = extras ? extras : getExtras()
        if (props.needsSegmentation) {
            props.addCartLine(
                product.productId,
                qtyToAdd,
                extras,
                null,
                product.finish === 'AND' && ['DEKTON', 'DKTN'].includes(product.brand),
                product.centerId
            )
        } else {
            props.addCartLineWithouthSeg(product.productId, qtyToAdd, extras)
        }
    }

    const getValueWithMax = () => {
        return qtyToAdd <= getLimit() ? qtyToAdd : getLimit()
    }

    const getValueToAdd = () => {
        let value = getLimit() ? getValueWithMax() : qtyToAdd;
        value = value > 0 ? value : 1;
        return Math.max(getMin(), value)
    }

    const generatePromotionTags = () => {

        let allPromotions = []

        let sorting = {
            Z70D: { val: 0 },
            Z71D: { val: 1 },
            Z73D: { val: 2 },
            ZQ00: { val: 3 }
        }

        if (props.productDiscounts && Object.keys(props.productDiscounts).length > 0) {
            allPromotions = allPromotions.concat(props.productDiscounts)
        }
        if (props.outlet) {
            allPromotions = validateOutletPromotions(allPromotions)
        }
        let promotionsUpdated = allPromotions.sort((a, b) => {
            if (sorting[b.conditionClass] && sorting[a.conditionClass]) {
                // console.log(a.conditionClass)
                // console.log(sorting[a.conditionClass].val)
                if (a.conditionClass == b.conditionClass) {
                    if (a.conditionClass == 'Z71D') {
                        //Discunt higher more favorable.
                        if (a.value > b.value) {
                            return -1
                        }
                        if (a.value < b.value) {
                            return 1
                        }
                    } else {
                        if (a.value > b.value) {
                            return 1
                        }
                        if (a.value < b.value) {
                            return -1
                        }
                    }
                }

                return sorting[a.conditionClass].val - sorting[b.conditionClass].val
            }

            // a must be equal to b
            return 1
        })

        setPromotions(promotionsUpdated)
    }

    const state = {
        qtyToAdd, setQtyToAdd,
        step, setStep,
        promotions, setPromotions
    }



    const showLots = () => {
        return getStock() > 0 && product.type === 'TABLA' && (
            (product.brand !== 'SILESTONE' && product.brand !== 'DEKTON')
            || ((props.impersonatedBy || props.isAdmin) && props.isUsaOrCanada)
        )
    }

    const getStock = () => {
        const { canViewThresholdMax } = props
        let result

        if (product.fromMall) {
            result = product.realStock
        } else if (!!props.outlet) {
            result = product.stock_outlet
        } else {
            result = product.stock
        }
        return result
    }

    const goToProductCard = (_product) => {
        _product.extra = getExtras()
        if (props.indexOfList !== undefined) props.clickDetail(_product, props.list, props.indexOfList)
    }

    return {...props,
        state,
        addToCart,
        goToProductCard,
        getExtras,
        getMin,
        getLimit,
        getType,
        product,
        canDisplayDiscontinued,
        getTypeProductForStyles,
        getValueToAdd,
        getStock,
        showLots
    }
}
