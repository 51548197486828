import { connect } from 'react-redux'
import { addCartLineWithouthSeg } from '../../store/cart/actions'
import ListResult from './ListResult'
import { getShippingMethod, canBuy, canOnlyViewOutlet, productIsInPromo } from '../../store/centers/reducers'
import { getIsAdmin, getUserType } from '../../store/login/reducers'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { getProfileConfiguration } from '../../store/profile-configuration/reducers'
import { getLines } from '../../store/cart/reducers'
import { getImageBackground } from '../../store/products/actions'

const mapStateToProps = (state, ownProps) => {
    const source = ownProps.result !== undefined ? ownProps.result._source : ownProps.product

    return {
        shippingMethod: getShippingMethod(state),
        userType: getUserType(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        addCartLine: (productId, qty, extra) => {
            dispatch(addCartLineWithouthSeg(productId, qty, extra))
        },
        getImageBackground: product => dispatch(getImageBackground(product))
    }
}

const ListResultContainer = withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(ListResult)
    )
)

export default ListResultContainer
