import React from 'react'

import { connect } from 'react-redux'
import { getImageBackground, isOtherMask } from '../../store/products/actions'
import { canViewImageByLot } from '../../store/centers/actions'
import AddFavourite from '../global/AddFavourite'
import LazyImage from '../global/LazyImage'
import DisplayTechnology from '../catalog/rectangleResult/displays/DisplayTechnology'

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        getImageBackground: product => dispatch(getImageBackground(product)),
        isOtherMask: product => isOtherMask(product),
        canViewImageLot: location => dispatch(canViewImageByLot(location))

    }
}
class ProductCarousel extends React.Component {
    constructor(props) {
        const initState = { imgSelected: null, widthCarouselItem: 100, positionCarousel: 0, widthOfButtonNextandBack: 35 }
        super(props)
        this.state = initState
        this.setState()
        this.product = this.props.product
        //HARDCODE: Añade segunda imagen a los productos
        // const productDoubleImage = ['237395', '241062', '245528', '245547', '245699', '245700', '245704', '300020', '906273', '906287', '906563', '907058', '907364', '907366']
        // if (!this.props.product.imgGroup && productDoubleImage.includes(this.props.product.productId)) {
        //     this.product = {
        //         ...this.props.product,
        //         imgGroup: [
        //             {
        //                 thumb: 'https://apiepro.cosentino.com/mktools/' + this.props.product.productId + '.jpg',
        //                 img: 'https://apiepro.cosentino.com/mktools/' + this.props.product.productId + '.jpg'
        //             },
        //             {
        //                 thumb: 'https://apiepro.cosentino.com/mktools/' + this.props.product.productId + '-2.jpg',
        //                 img: 'https://apiepro.cosentino.com/mktools/' + this.props.product.productId + '-2.jpg'
        //             }
        //         ]
        //     }
        // } else this.product = this.props.product
    }
    componentDidMount() {
        this.product.imgGroup && this.setState({ imgSelected: this.product.imgGroup[0] })
    }
    moveCarouselNext(next) {
        if (
            next &&
            this.state.positionCarousel + this.state.widthOfButtonNextandBack >=
            -1 * (this.props.product.img.length * this.state.widthCarouselItem - this.carouselWrapper.offsetWidth)
        ) {
            this.setState({
                positionCarousel:
                    this.state.positionCarousel - this.state.widthCarouselItem - this.state.widthOfButtonNextandBack <
                    this.carouselWrapper.offsetWidth - this.props.product.img.length * this.state.widthCarouselItem
                        ? this.carouselWrapper.offsetWidth - this.props.product.img.length * this.state.widthCarouselItem - this.state.widthOfButtonNextandBack
                        : this.state.positionCarousel - this.state.widthCarouselItem
            })
        } else if (!next && this.state.positionCarousel < this.state.widthOfButtonNextandBack) {
            this.setState({
                positionCarousel:
                    this.state.positionCarousel + this.state.widthCarouselItem > this.state.widthOfButtonNextandBack
                        ? this.state.widthOfButtonNextandBack
                        : this.state.positionCarousel + this.state.widthCarouselItem
            })
        }
    }
    render() {
        let { isLogged } = this.props
        let { imgSelected, widthCarouselItem, positionCarousel } = this.state
        if (this.product.imgGroup)
            return (
                <div className="image-carousel">
                    <div className="product-image" data-cy="product_detail__image">
                        <div className="fit-image">
                            {imgSelected && <LazyImage preview={imgSelected.thumb} image={imgSelected.img} alt="" extraClass="img-fluid" />}
                            <div className="fit-image add-favourite">{isLogged && <AddFavourite productId={this.product.productId} />}</div>
                        </div>
                        <div className="wrapper_technology_indicator">
                            <DisplayTechnology technology={this.product.technology} />
                        </div>
                    </div>
                    <div className="carousel_container">
                        <div
                            className="carousel_container__carousel carousel_wrapper"
                            ref={element => {
                                this.carouselWrapper = element
                            }}
                        >
                            {this.carouselWrapper && this.carouselWrapper.offsetWidth < this.product.imgGroup.length * widthCarouselItem && (
                                <div className="button button__back" onClick={() => this.moveCarouselNext(false)}>
                                    <i className="fas fa-chevron-left" />
                                </div>
                            )}
                            <div
                                className="carousel_wrapper__carousel carousel"
                                style={{ left: `${positionCarousel}px`, width: `${this.product.imgGroup.length * widthCarouselItem}px` }}
                            >
                                {this.product.imgGroup.map(img => {
                                    return (
                                        <div
                                            className="carousel__item item"
                                            style={{ width: widthCarouselItem, height: widthCarouselItem, backgroundImage: `url('${img.thumb}')` }}
                                            onClick={() => {
                                                this.setState({ imgSelected: img })
                                            }}
                                        />
                                    )
                                })}
                            </div>
                            {this.carouselWrapper && this.carouselWrapper.offsetWidth < this.product.imgGroup.length * widthCarouselItem && (
                                <div className="button button__next" onClick={() => this.moveCarouselNext(true)}>
                                    <i className="fas fa-chevron-right" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )
        else
            return (
                <div className="product-image" data-cy="product_detail__image">

                    <div className={`fit-image ${!this.product.img && this.product.type !== 'SAMPLES' && !this.props.canViewImageLot(this.product.location) ? 'fit-image--no-image' : ''}`}>
                        <LazyImage
                            preview={this.props.getImageBackground(this.product)}
                            image={this.props.getImageBackground(this.product)}
                            alt=""
                            extraClass="img-fluid"
                            masked={this.product.typeId === '01' ? this.product.colorImg : null}
                            type={this.props.isOtherMask(this.product) ? 'otherMask' : null}
                        />
                        <div className="fit-image add-favourite">{isLogged && <AddFavourite productId={this.product.productId} />}</div>
                    </div>
                    <div className="wrapper_technology_indicator">
                        <DisplayTechnology technology={this.product.technology} />
                    </div>
                </div>
            )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductCarousel)
