import React from 'react'
import FooterV2 from './FooterV2'
import Footer from './Footer'

class FooterSelectorRedesign extends React.Component {
    render() {
        return this.props.newVersion ? <FooterV2 {...this.props} /> : <Footer {...this.props} />
    }
}

export default FooterSelectorRedesign
