import React from 'react'
import { connect } from 'react-redux'
import { getLanguage } from '../../store/ui/reducers'

//Danes
import imgda from '../../assets/img/banner-earthic/DA/wide.jpg'
import imgdaSquared from '../../assets/img/banner-earthic/DA/squared.jpg'
//Aleman
import imgde from '../../assets/img/banner-earthic/DE/wide.jpg'
import imgdeSquared from '../../assets/img/banner-earthic/DE/squared.jpg'
//Ingles
import imgen from '../../assets/img/banner-earthic/EN/wide.jpg'
import imgenSquared from '../../assets/img/banner-earthic/EN/squared.jpg'
//Español
import imges from '../../assets/img/banner-earthic/ES/wide.jpg'
import imgesSquared from '../../assets/img/banner-earthic/ES/squared.jpg'
//Suomi
import imgfi from '../../assets/img/banner-earthic/FI/wide.jpg'
import imgfiSquared from '../../assets/img/banner-earthic/FI/squared.jpg'
//Frances
import imgfr from '../../assets/img/banner-earthic/FR/wide.jpg'
import imgfrSquared from '../../assets/img/banner-earthic/FR/squared.jpg'
//Hungria (Se usa el ingles)
import imghu from '../../assets/img/banner-earthic/EN/wide.jpg'
import imghuSquared from '../../assets/img/banner-earthic/EN/squared.jpg'
//Italiano
import imgit from '../../assets/img/banner-earthic/IT/wide.jpg'
import imgitSquared from '../../assets/img/banner-earthic/IT/squared.jpg'
//Japones
import imgja from '../../assets/img/banner-earthic/JP/wide.jpg'
import imgjaSquared from '../../assets/img/banner-earthic/JP/squared.jpg'
//Neerlandes
import imgnl from '../../assets/img/banner-earthic/NL/wide.jpg'
import imgnlSquared from '../../assets/img/banner-earthic/NL/squared.jpg'
//Noruego
import imgno from '../../assets/img/banner-earthic/NO/wide.jpg'
import imgnoSquared from '../../assets/img/banner-earthic/NO/squared.jpg'
//Polaco (We use EN)
import imgpl from '../../assets/img/banner-earthic/EN/wide.jpg'
import imgplSquared from '../../assets/img/banner-earthic/EN/squared.jpg'
//Portugues
import imgpt from '../../assets/img/banner-earthic/PT/wide.jpg'
import imgptSquared from '../../assets/img/banner-earthic/PT/squared.jpg'
//Suecia
import imgsv from '../../assets/img/banner-earthic/SE/wide.jpg'
import imgsvSquared from '../../assets/img/banner-earthic/SE/squared.jpg'
//Turquia
import imgtr from '../../assets/img/banner-earthic/TR/wide.jpg'
import imgtrSquared from '../../assets/img/banner-earthic/TR/squared.jpg'
//Hebreo
import imghe from '../../assets/img/banner-earthic/EN/wide.jpg'
import imgheSquared from '../../assets/img/banner-earthic/EN/squared.jpg'
import { isAustralianCenter } from '../../store/centers/reducers'

const mapStateToProps = state => {
    return {
        language: getLanguage(state),
        isAustralianCenter: isAustralianCenter(state)
    }
}

class BannerEarthic extends React.Component {
    render() {
        if (this.props.isAustralianCenter) return ''
        const { language } = this.props
        const responsiveWidth = window.innerWidth <= 768

        const images = {
            da: responsiveWidth ? imgdaSquared : imgda,
            de: responsiveWidth ? imgdeSquared : imgde,
            en: responsiveWidth ? imgenSquared : imgen,
            es: responsiveWidth ? imgesSquared : imges,
            fi: responsiveWidth ? imgfiSquared : imgfi,
            fr: responsiveWidth ? imgfrSquared : imgfr,
            hu: responsiveWidth ? imghuSquared : imghu,
            it: responsiveWidth ? imgitSquared : imgit,
            ja: responsiveWidth ? imgjaSquared : imgja,
            nl: responsiveWidth ? imgnlSquared : imgnl,
            no: responsiveWidth ? imgnoSquared : imgno,
            pl: responsiveWidth ? imgplSquared : imgpl,
            pt: responsiveWidth ? imgptSquared : imgpt,
            sv: responsiveWidth ? imgsvSquared : imgsv,
            tr: responsiveWidth ? imgtrSquared : imgtr,
            he: responsiveWidth ? imgheSquared : imghe,
            'en-US': responsiveWidth ? imgenSquared : imgen,
            'fr-CA': responsiveWidth ? imgfrSquared : imgfr,
            'en-CA': responsiveWidth ? imgenSquared : imgen
        }

        const links = {
            da: 'https://www.cosentino.com/da-dk/silestone-xm/earthic/',
            de: 'https://www.cosentino.com/de/silestone-xm/earthic/',
            en: 'https://www.cosentino.com/en-gb/silestone-xm/earthic/',
            es: 'https://www.cosentino.com/es/silestone-xm/earthic/',
            fi: 'https://www.cosentino.com/fi-fi/silestone-xm/earthic/',
            fr: 'https://www.cosentino.com/fr-fr/silestone-xm/earthic/',
            hu: 'https://www.cosentino.com/silestone-xm/earthic/',
            it: 'https://www.cosentino.com/it-it/silestone-xm/earthic/',
            ja: 'https://www.cosentino.com/ja-jp/silestone-xm/earthic/',
            nl: 'https://www.cosentino.com/nl-nl/silestone-xm/earthic/',
            no: 'https://www.cosentino.com/no-no/silestone-xm/earthic/',
            pl: 'https://www.cosentino.com/pl-pl/silestone-xm/earthic/',
            pt: 'https://www.cosentino.com/pt-pt/silestone-xm/earthic/',
            sv: 'https://www.cosentino.com/sv-se/silestone-xm/earthic/',
            tr: 'https://www.cosentino.com/tr-tr/silestone-xm/earthic/',
            he: 'https://www.cosentino.com/silestone-xm/earthic/',
            'en-US': 'https://www.cosentino.com/silestone-xm/earthic/',
            'fr-CA': 'https://www.cosentino.com/fr-ca/silestone-xm/earthic/',
            'en-CA': 'https://www.cosentino.com/en-ca/silestone-xm/earthic/'
        }

        return (
            <div className="mt-4">
                <a href={links[language]} target="_blank" rel="noopener noreferrer">
                    <img style={{ maxWidth: '100%' }} src={images[language]} alt="Banner Earthic" />
                </a>
            </div>
        )
    }
}

export default connect(mapStateToProps, null)(BannerEarthic)
