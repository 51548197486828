import { getToken } from './../../store/login/reducers';
import { getFileByUrl } from './../../store/orders/actions';
import { getOrderDetailsHead, getInvoices } from './../../store/orders/reducers';
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Translation from '../global/Translation'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import Timeline from './Timeline'
import Button from '../global/Button'
import confirm from '../global/confirm'
import Select from 'react-select'
import PackingList from './PackingList'
import { canDeleteOrders, canEditOrders, getOrderStatusMap, getStatusMap } from '../../store/orders/reducers'
import { rejectOrder } from '../../store/orders/actions'
import { getIsIkeaCenter } from '../../store/centers/reducers'
import { getShippingAddresses } from '../../store/shipping-addresses/reducers'
import { push } from 'connected-react-router'
import LocalizedLink from '../global/LocalizedLink'
import { dispatchPushURL } from '../../store/ui/actions'
import { getVersion } from '../../store/login/reducers'
import GoBack from '../global/GoBack'
import Modal from '../global/Modal'
import iconFile from '../../assets/img/icons/file.svg'
import iconPrint from '../../assets/img/icons/print.svg'
import ReactTable from 'react-table'

const mapStateToProps = (state, { match }) => {
    return {
        canEditOrders: canEditOrders(state),
        canDeleteOrders: canDeleteOrders(state),
        shippingAddressesByUser: getShippingAddresses(state),
        statusMap: getStatusMap(state),
        orderStatusMap: getOrderStatusMap(state),
        isIkeaCenter: getIsIkeaCenter(state),
        newVersion: getVersion(state),
        orderDetailsHead: getOrderDetailsHead(state),
        invoices: getInvoices(state),
        reservationId: match.params.open,
        token:getToken(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        rejectOrder: (reservationId, orderId) => {
            return dispatch(rejectOrder(reservationId, orderId))
        },
        redirect: url => {
            dispatch(dispatchPushURL(url))
        },
        getFileByUrl: url => dispatch(getFileByUrl(url))
    }
}

const DisplayShippingAddress = props => {
    let { shippingAddress, shippingAddressId, shippingAddressesByUser } = props
    if (!shippingAddress) {
        shippingAddress =
            shippingAddressesByUser && shippingAddressId
                ? shippingAddressesByUser.filter(shippingAddress => {
                      return parseInt(shippingAddress.shippingAddressId) === parseInt(shippingAddressId)
                  })[0]
                : null
    }

    return shippingAddress ? (
        <div>
            {shippingAddress.customerName}
            <br />
            {shippingAddress.address + '' + shippingAddress.zipCode}
            <br />
            {shippingAddress.city + ' ' + shippingAddress.country}
        </div>
    ) : null
}

class OrderHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedDelivery: 0 // siempre seleccionamos la 0 porque se ordenan en el metodo getOrderStatusAndDelivery
        }
        this.getOrderInvoiceDownloadUrl = this.getOrderInvoiceDownloadUrl.bind(this)
        this.getOrderImagesDownloadUrl = this.getOrderImagesDownloadUrl.bind(this)
    }
    getOrderInvoiceDownloadUrl(invoiceNum) {
        // return process.env.REACT_APP_API_HOST + '/users/order-invoice/' + invoiceNum + '/?authToken=' + this.props.token
        return process.env.REACT_APP_API_HOST + '/users/order-invoice/' + invoiceNum
    }

    getOrderImagesDownloadUrl() {
        return process.env.REACT_APP_API_HOST + '/users/order-images?reservationId=' + this.props.reservationId + '&lang=' + this.props.lang
        // return process.env.REACT_APP_API_HOST + '/users/order-images?reservationId=' + this.props.reservationId + '&lang=' + this.props.lang + '&authToken=' + this.props.token
    }

    confirmRejectOrder = (reservationId, orderId, intl) => {
        return confirm(intl.formatMessage({ id: 'CONFIRM_REJECT_ORDER' }), { intl: intl }).then(
            confirm => {
                //TODO: actualizar este registro (recargando, mirar a ver opciones)
                this.props.rejectOrder(reservationId, orderId).then(() => {
                    this.props.fetchOrders()
                })
            },
            cancel => {
                return Promise.reject()
            }
        )
    }

    rejectOrder = (reservationId, orderId) => {
        this.confirmRejectOrder(reservationId, orderId, this.props.intl)
    }
    showDeliveryDate = dataOrder => {
        if (
            dataOrder.deliveries &&
            dataOrder.deliveries.length > 0 &&
            this.state.selectedDelivery !== null &&
            dataOrder.ecosentino_expected_delivery_date &&
            dataOrder.ecosentino_status !== 'ERROR'
        )
            return true
        return false
    }
    showDeliveries = dataOrder => {
        if (dataOrder.deliveries && dataOrder.deliveries.length > 0) return true
        return false
    }
    showLinkToDetails = dataOrder => {
        if (this.props.isOrderDetails) return false
        if (dataOrder.orderLines && dataOrder.orderLines.length > 0 && dataOrder.orderId) return true
        return false
    }
    writeContent = printWindow => {
        printWindow.document.write('<html><head>')
        printWindow.document.write(document.getElementsByTagName('head')[0].innerHTML)

        printWindow.document.write('</head><body class="p-4 my-4">')
        printWindow.document.write(this.props.ordersGroup.innerHTML)
        printWindow.document.write('</body></html>')

        return Promise.resolve(printWindow)
    }

    downloadPdf = () => {
        let printWindow = window.open('', 'PRINT', 'height=400,width=600')
        setTimeout(() => {
            this.writeContent(printWindow).then(printWindow => {
                setTimeout(() => {
                    printWindow.focus()
                    printWindow.print()
                    printWindow.close()
                }, 2000)
            })
        }, 100)

        return false
    }
    showInvoicesModal = () => {
        this.setState({ showInvoicesModal: true })
    }

    closeInvoicesModal = () => {
        this.setState({ showInvoicesModal: false })
    }
    render() {
        const { dataOrder, statusMap, orderStatusMap } = this.props

        let deliveryOptions = dataOrder.timelines.map((item, index) => ({
            label:
                item.delivery_id === 'OTHERS_PENDING'
                    ? this.props.intl.formatMessage({ id: 'other_deliveries_pending' })
                    : item.delivery_id + ' (' + this.props.intl.formatMessage({ id: 'shipment', defaultMessage: 'Shipment' }) + ' ' + item.number + ')',
            value: index
        }))

        let activeTimeLine = dataOrder.timelines && dataOrder.timelines.length > 0 ? dataOrder.timelines[this.state.selectedDelivery].timeline : null

        return !this.props.newVersion ? (
            <div id={dataOrder.reservationId} className={`order-history-item card order__card my-4 p-1 p-sm-4 ${this.props.backgroundTransparent ? 'background-transparent' : ''}`}>
                <div className="card-header py-1 pt-1 px-1">
                    <div className="row no-gutters">
                        {dataOrder.orderId && (
                            <div className="col col-lg-3 order-1 my-1" style={{ whiteSpace: 'nowrap' }}>
                                <b className="font-weight-bold">
                                    <Translation id="order_#" defaultMessage="Order #" />: <span className="font-weight-bold">{dataOrder.orderId}</span>
                                </b>
                            </div>
                        )}

                        {!this.props.newVersion && (
                            <div className="col-12 col-md-6 col-lg-3 my-1 text-md-right text-lg-left order-4 order-md-2 text-center text-md-left">
                                <Translation id="order_status" defaultMessage="Order Status" />:{' '}
                                <span
                                    className="font-weight-bold"
                                    data-order-status={dataOrder.ecosentino_status}
                                    data-order-status={dataOrder.status}
                                    data-order-status={dataOrder.reservationStatus}
                                >
                                    {/* {orderStatusMap[globalStatusAndDelivery.status] && this.props.intl.formatMessage({ id: orderStatusMap[globalStatusAndDelivery.status].textToShow })} */}
                                    {dataOrder.ecosentino_status && this.props.intl.formatMessage({ id: orderStatusMap[dataOrder.ecosentino_status].textToShow })}
                                </span>
                            </div>
                        )}
                        {!this.props.newVersion && (
                            <div className="col-12 col-md-6 col-lg-4 order-3 order-md-3 my-1 text-center text-md-left">
                                {dataOrder.ecosentino_status === 'INVOICED' ? (
                                    <LocalizedLink routeId="ROUTE_PROFILE" params={{ filter: 'view-order', open: dataOrder.reservationId }}>
                                        <Translation id="view_delivery_details" defaultMessage="View delivery details" />
                                    </LocalizedLink>
                                ) : (
                                    this.showDeliveryDate(dataOrder) && (
                                        <span>
                                            <Translation id="estimated_delivery_date" defaultMessage="Estimated delivery date" />:{' '}
                                            {moment(dataOrder.ecosentino_expected_delivery_date).format('L')}
                                        </span>
                                    )
                                )}
                            </div>
                        )}
                        {!this.props.newVersion && (
                            <div className="col col-lg-2 text-right order-2 order-md-4 my-1">
                                {this.showDeliveries(dataOrder) && (
                                    <span>
                                        <Translation id="qty_of_deliveries_on_this_order" defaultMessage="No. of Shipments" />:{' '}
                                        <span className="font-weight-bold">{dataOrder.deliveries && dataOrder.deliveries.length}</span>
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                    {!this.props.newVersion && (
                        <div className="row">
                            <div className="col-12 col-md-3 offset-md-9">
                                {deliveryOptions && deliveryOptions.length > 1 && (
                                    <div style={{ whiteSpace: 'nowrap' }}>
                                        <Select
                                            options={deliveryOptions}
                                            value={this.state.selectedDelivery}
                                            clearable={false}
                                            onChange={selectedOption => {
                                                selectedOption && this.setState({ selectedDelivery: selectedOption.value })
                                            }}
                                            // onChange={selectedOption => {
                                            //     selectedOption &&
                                            //         this.setState({ selectedDelivery: selectedOption.value }, () => {
                                            //             this.props.getOrderStatusAndDelivery(dataOrder, this.state.selectedDelivery)
                                            //         })
                                            // }}
                                            placeholder={this.props.intl.formatMessage({ id: 'select' }) + '...'}
                                        />
                                    </div>
                                )}
                                {deliveryOptions && deliveryOptions.length === 1 && <div className="shipping-label">{deliveryOptions[0].label}</div>}
                            </div>
                        </div>
                    )}
                    <div className="col-12 pb-md-4">
                        {activeTimeLine && (
                            <Timeline
                                detailsView={this.props.isOrderDetails}
                                // delivery={statusAndDelivery.delivery}
                                delivery={activeTimeLine}
                                rejected={dataOrder.rejected}
                                orderStatusMap={orderStatusMap}
                                withDates
                            />
                        )}
                    </div>
                </div>
                <div className={`p-1 card-body collapse ${this.props.showDetails ? 'show' : ''}`}>
                    <div className="row py-2 text-center justify-content-between">
                        <div className="col-6 col-sm-4 col-md-2 p-2 ">
                            <Translation id="reservation_#" defaultMessage="Reservation #" />
                            <div className="value pt-1 font-weight-bold">{dataOrder.reservationId}</div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-2 p-2 ">
                            <Translation id="order_date" defaultMessage="Order date" />
                            <div className="value pt-1 font-weight-bold">
                                {dataOrder.orderCreationDate
                                    ? moment(dataOrder.orderCreationDate).format('L')
                                    : dataOrder.createdDate
                                    ? moment(dataOrder.createdDate).format('L')
                                    : '--'}
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-2 p-2 ">
                            <Translation id="order_type" defaultMessage="Order Type" />
                            <div className="value pt-1 font-weight-bold">
                                <Translation id={dataOrder.shippingType} />
                            </div>
                        </div>
                        {!this.props.isOrderDetails && (
                            <div className="col-6 col-sm-4 col-md-2 p-2 ">
                                <Translation id="requested_delivery_date" defaultMessage="Requested Delivery Date" />
                                <div className="value pt-1">
                                    {(this.showLinkToDetails(dataOrder) && (
                                        <div
                                            className="a_link"
                                            onClick={() => {
                                                if (dataOrder.reservationId && !this.props.isIkeaCenter) {
                                                    this.props.redirect(
                                                        this.props.intl
                                                            .formatMessage({ id: 'ROUTE_PROFILE' })
                                                            .replace(':filter', 'view-order')
                                                            .replace(':open', dataOrder.reservationId)
                                                    )
                                                }
                                            }}
                                        >
                                            <Translation id="view_more" defaultMessage="View more" />
                                        </div>
                                    )) || <div className="font-weight-bold">--</div>}
                                </div>
                            </div>
                        )}
                        <div className="col-6 col-sm-4 col-md-2 p-2 ">
                            <Translation id="supplier_center" defaultMessage="Supplier center" />
                            <div className="value pt-1 font-weight-bold">{dataOrder.centerId && `${dataOrder.centerName} (${dataOrder.centerId})`}</div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-2 p-2 ">
                            PO
                            <div className="value pt-1 font-weight-bold">{dataOrder.po ? '#' + dataOrder.po : '--'}</div>
                        </div>
                    </div>
                    <div className="row pt-3 px-4">
                        <div className="col-12 col-md-6">
                            <Translation id="shipping_to" defaultMessage="Shipping To" />:{' '}
                            <div className="value pt-1 font-weight-bold">
                                <DisplayShippingAddress
                                    shippingAddress={dataOrder.shippingAddress}
                                    shippingAddressId={dataOrder.shippingAddressId}
                                    shippingAddressesByUser={this.props.shippingAddressesByUser}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <Translation id="comments" defaultMessage="Comments" />: <div className="value pt-1 font-weight-bold">{dataOrder.comments || '--'}</div>
                        </div>
                    </div>
                </div>
                <div className="card-footer pt-md-4">
                    <div className="row">
                        <div className="col-12 col-md-5 order-2 order-md-1 d-flex align-items-start my-1">
                            {!this.props.isOrderDetails && dataOrder.ecosentino_is_deletable && (
                                <Button danger size="medium" inline onClick={() => this.rejectOrder(dataOrder.reservationId, dataOrder.orderId)}>
                                    <Translation id="cancel_order" defaultMessage="Cancel order" />
                                </Button>
                            )}
                            {!this.props.isOrderDetails && dataOrder.ecosentino_is_editable && (
                                <Button
                                    size="medium"
                                    inline
                                    customClass="ml-3"
                                    onClick={() => {
                                        this.props.redirect(
                                            this.props.intl
                                                .formatMessage({ id: 'ROUTE_PROFILE' })
                                                .replace(':filter', 'edit-order')
                                                .replace(':open', dataOrder.orderId)
                                        )
                                    }}
                                >
                                    <Translation id="edit_order" defaultMessage="Edit order" />
                                </Button>
                            )}
                        </div>
                        <div className="col-12 col-md-2 order-1 order-md-2 text-center my-1" style={{ cursor: 'pointer' }} onClick={this.props.toggleShowDetails}>
                            {this.props.showDetails ? (
                                <div className="d-inline-block">
                                    <i className="fal fa-chevron-up" />
                                    <br />
                                    <Translation id="view_less" defaultMessage="view less" />
                                </div>
                            ) : (
                                <div className="d-inline-block">
                                    <i className="fal fa-chevron-down" />
                                    <br />
                                    <Translation id="view_more" defaultMessage="View more" />
                                </div>
                            )}
                        </div>
                        {this.showLinkToDetails(dataOrder) && (
                            <div className="col-12 col-md-5 text-md-right order-3 my-1">
                                <Button
                                    size="medium"
                                    inline
                                    customClass="ml-md-3"
                                    onClick={() => {
                                        if (dataOrder.reservationId && !this.props.isIkeaCenter) {
                                            this.props.redirect(
                                                this.props.intl
                                                    .formatMessage({ id: 'ROUTE_PROFILE' })
                                                    .replace(':filter', 'view-order')
                                                    .replace(':open', dataOrder.reservationId)
                                            )
                                        }
                                    }}
                                >
                                    <Translation id="view_order_details" defaultMessage="View order details" />
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        ) : (
            <div id={dataOrder.reservationId}>
                <div style={{ backgroundColor: 'white', padding: '1rem', marginRight: '-15px', marginLeft: '-15px' }}>
                    <div className="row" style={{ marginLeft: '0px' }}>
                        <div className="col-6">
                            <GoBack text={this.props.intl.formatMessage({ id: 'back' })} newView={true} />
                        </div>
                        <div className="col-6" style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="col-12 bt-group-detail">
                                <Button inline size="small" className="mr-2" onClick={() => this.setState({ showPackingList: true })}>
                                    <img alt="" className="icon" src={iconFile} />
                                    <Translation id="packing_list" defaultMessage="Packing list" />
                                </Button>
                                {false && (
                                    <Button inline size="small" onClick={() => alert('Under construction')}>
                                        <Translation id="create_recurring_order" defaultMessage="Create recurring order" />
                                    </Button>
                                )}
                                {this.props.invoices && this.props.invoices.length > 0 && (
                                    <Button inline size="small" onClick={this.showInvoicesModal}>
                                        <img alt="" className="icon" src={iconFile} />
                                        <Translation id="view_invoice" defaultMessage="View invoice" />
                                    </Button>
                                )}
                                {dataOrder && dataOrder.orderLines && (
                                    <Button inline size="small" onClick={this.downloadPdf}>
                                        <img alt="" className="icon" src={iconPrint} />
                                        <Translation id="print" defaultMessage="Print" />
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                    <br></br>
                    {dataOrder.orderId && (
                        <React.Fragment>
                            <b className="font-weight-bold">
                                <Translation id="reservation_#" defaultMessage="Reservation #" /> : <span className="font-weight-bold">{dataOrder.reservationId}</span>
                            </b>
                            <br></br>
                            <b className="font-weight-bold">
                                <Translation id="order_#" defaultMessage="Order #" /> : <span className="font-weight-bold">{dataOrder.orderId}</span>
                            </b>
                        </React.Fragment>
                    )}
                    <div className="order-details-header">
                        {dataOrder.ecosentino_is_editable && dataOrder.reservationStatus !== 'PENDING_CANCELLATION' && dataOrder.reservationStatus !== 'PENDING_CHANGES' && (
                            <React.Fragment>
                                <div>
                                    <button className="bt bt-not-style" style={{ paddingLeft: 0 }} onClick={() => this.rejectOrder(dataOrder.reservationId, dataOrder.orderId)}>
                                        <i className="fal fa-times" /> <Translation id="cancel_order" defaultMessage="Cancel order" />
                                    </button>                 
                                </div>
                                <div>
                                    <button
                                        className="bt bt-not-style"
                                        onClick={() => {
                                            this.props.redirect(
                                                this.props.intl
                                                    .formatMessage({ id: 'ROUTE_PROFILE' })
                                                    .replace(':filter', 'edit-order')
                                                    .replace(':open', dataOrder.orderId)
                                            )
                                        }}
                                    >
                                        <i className="fal fa-pencil" /> <Translation id="edit_order" defaultMessage="Edit order" />
                                    </button>
                                </div>
                            </React.Fragment>
                        )}

                        <div className="d-flex justify-content-end" style={{ whiteSpace: 'nowrap' }}>
                            <button className="bt bt-not-style" onClick={this.props.toggleShowDetails}>
                                {this.props.showDetails ? (
                                    <span>
                                        <span>
                                            <Translation id="hide_details" defaultMessage="Hide details" />
                                        </span>
                                        <i style={{ marginLeft: '8px' }} className="fal fa-chevron-up" />
                                    </span>
                                ) : (
                                    <span>
                                        <span>
                                            <Translation id="show_details" defaultMessage="Show details" />
                                        </span>
                                        <i style={{ marginLeft: '8px' }} className="fal fa-chevron-down" />
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                    <div className={`box-details-order p-1 card-body collapse ${this.props.showDetails ? 'd-flex' : ''}`}>
                        <div>
                            <span className="font-weight-bold">
                                <Translation id="shipping_to" defaultMessage="Shipping To" />:{' '}
                            </span>
                            <div className="value pt-1">
                                <DisplayShippingAddress
                                    shippingAddress={dataOrder.shippingAddress}
                                    shippingAddressId={dataOrder.shippingAddressId}
                                    shippingAddressesByUser={this.props.shippingAddressesByUser}
                                />
                            </div>
                            {dataOrder.po && (
                              <React.Fragment>
                                  <br></br>
                                  <b style={{fontWeight: 500}}>
                                      PO: <span className="font-weight-normal">{dataOrder.po}</span>
                                  </b>
                              </React.Fragment>
                            )}
                            {dataOrder.comments && (
                              <React.Fragment>
                                  <br></br>
                                  <b style={{fontWeight: 500}}>
                                      <Translation id="comment" defaultMessage="Comments" />: <span className="font-weight-normal">{dataOrder.comments}</span>
                                  </b>
                              </React.Fragment>
                            )}
                        </div>
                        <div className="d-flex flex-column font-weight-bold">
                            <div className="d-flex">
                                <span style={{ marginRight: '8px' }}>
                                    <Translation id="reservation_#" defaultMessage="Reservation #" />
                                </span>
                                <span>{dataOrder.reservationId}</span>
                            </div>
                            <div className="d-flex">
                                <span style={{ marginRight: '8px' }}>
                                    <Translation id="order_date" defaultMessage="Order date" />
                                </span>
                                <span>
                                    {dataOrder.orderCreationDate
                                        ? moment(dataOrder.orderCreationDate).format('L')
                                        : dataOrder.createdDate
                                        ? moment(dataOrder.createdDate).format('L')
                                        : '--'}
                                </span>
                            </div>
                            <div className="d-flex">
                                <span style={{ marginRight: '8px' }}>
                                    <Translation id="order_type" defaultMessage="Order Type" />
                                </span>
                                <span>
                                    <Translation id={dataOrder.shippingType} />
                                </span>
                            </div>
                            <div className="d-flex">
                                <span style={{ marginRight: '8px' }}>
                                    <Translation id="supplier_center" defaultMessage="Supplier center" />
                                </span>
                                <span>{dataOrder.centerId && `${dataOrder.centerName} (${dataOrder.centerId})`}</span>
                            </div>
                        </div>
                    </div>
                </div>
                {activeTimeLine && (
                    <div className="mt-3" style={{ backgroundColor: 'white', padding: '1rem', margin: '0', marginLeft: '-15px', marginRight: '-15px' }}>
                        <Timeline
                            detailsView={false /*this.props.isOrderDetails*/}
                            // delivery={statusAndDelivery.delivery}
                            delivery={activeTimeLine}
                            rejected={dataOrder.rejected}
                            orderStatusMap={orderStatusMap}
                            withDates
                        />
                    </div>
                )}
                {this.state.showInvoicesModal && (
                    <Modal title={<Translation id="invoices" defaultMessage="Invoices" />} onClose={this.closeInvoicesModal}>
                        <ReactTable
                            data={this.props.invoices}
                            columns={[
                                {
                                    Header: <Translation id="invoice_num" defaultMessage="Invoice Num." />,
                                    Cell: ({ original }) => {
                                        return (
                                            <span
                                                onClick={e => {
                                                    e.preventDefault()

                                                    this.props.getFileByUrl(this.getOrderInvoiceDownloadUrl(original.invoiceNum))
                                                }}
                                                style={{ color: 'blue', cursor: 'pointer' }}
                                            >
                                                {original.invoiceNum}
                                            </span>
                                        )
                                    }
                                },
                                { Header: <Translation id="invoice_date" defaultMessage="Invoice Date" />, accessor: 'invoiceDate' }
                            ]}
                            defaultPageSize={4}
                            pageSizeOptions={[8, 16, 32, 64, 128]}
                            previousText={<Translation id="previous" defaultMessage="Previous" />}
                            nextText={<Translation id="next" defaultMessage="Next" />}
                            noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                            pageText={<Translation id="page" defaultMessage="Page" />}
                            ofText={<Translation id="of" defaultMessage="Of" />}
                        />
                    </Modal>
                )}

                {this.state.showPackingList && this.props.orderDetailsHead && (
                    <PackingList
                        reservationId={this.props.reservationId}
                        orderId={this.props.orderDetailsHead.orderId}
                        packingList={this.props.packingList}
                        onClose={() => this.setState({ showPackingList: false })}
                        canEditOrders={false}
                    />
                )}
            </div>
        )
    }
}

//export default injectIntl(OrderHeader)

export default withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(OrderHeader)
    )
)
