import React from 'react'
import Translation from '../global/Translation'
import logo from '../../assets/img/icons/ecosentino-white.svg'
import iconSearch from '../../assets/img/icons/search.svg'
import CentersContainer from '../centers/CentersContainer'
import { injectIntl } from 'react-intl'
import LocalizedLink from '../global/LocalizedLink'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import LanguageSelector from '../language-selector/LanguageSelector'
import { getIsLogged, getUserName, getName, getEmail, getUserType } from '../../store/login/reducers'
import AssignCenterInfoModal from '../global/AssignCenterInfoModal'
import { getIsRemembered, getAnimateChangeCenter, getCenters, getIsIkeaCenter, getIsLowesCenter, getIsEndiCenter } from '../../store/centers/reducers'
import IkeaShippingAddressContainer from '../centers/IkeaShippingAddressContainer'
import { getIsShippingAddressRemembered, getShippingAddresses, getShippingAddressFilteredToSelectByCenter, isLoadingShippingAddress } from '../../store/shipping-addresses/reducers'
import { getEmployeeId } from '../../store/big-account/reducers'
import { dispatchPushURL } from '../../store/ui/actions'

const mapStateToProps = state => {
    return {
        username: getUserName(state),
        name: getName(state),
        email: getEmail(state),
        userType: getUserType(state),
        isLogged: getIsLogged(state),
        isRemembered: getIsRemembered(state),
        isShippingAddressRemembered: getIsShippingAddressRemembered(state),
        animateChangeCenter: getAnimateChangeCenter(state),
        centers: getCenters(state),
        shippingAddress: getShippingAddresses(state),
        isIkeaCenter: getIsIkeaCenter(state),
        employeeId: getEmployeeId(state),
        isLowesCenter: getIsLowesCenter(state),
        isEndiCenter: getIsEndiCenter(state),
        sippingAddressFilteredToSelectByCenter: getShippingAddressFilteredToSelectByCenter(state),
        isShippingAddressLoading: isLoadingShippingAddress(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: url => {
            dispatch(dispatchPushURL(url))
        },
        rememberedCenter: () => {
            dispatch({ type: 'REMEMBERED_CENTER' })
        },
        toggleShowIkeaModalEmployeeId: open => {
            dispatch({ type: 'TOGGLE_SHOW_IKEA_MODAL_EMPLOYEE_ID', open: open })
        }
    }
}
class TopRibbon extends React.Component {
    render() {
        const showRememberSelectCenter = this.props.isLogged && !this.props.isRemembered && ((this.props.centers && this.props.centers.length > 1) || this.props.isIkeaCenter)
        const showRememberSelectShippingAddress =
            this.props.isLogged &&
            !this.props.isShippingAddressRemembered &&
            this.props.isEndiCenter &&
            this.props.sippingAddressFilteredToSelectByCenter.length !== 1 &&
            !this.props.isShippingAddressLoading
        const { intl, onSubmit, username, name, email, userType, animateChangeCenter, isIkeaCenter, employeeId, isLowesCenter } = this.props
        const url = intl.formatMessage({ id: 'ROUTE_CATALOG' }).replace(':outlet?', '')
        let input
        const handleSubmit = event => {
            event.preventDefault()
            onSubmit(url + '?q=' + input.value)
            input.value = ''
        }
        return (
            <div id="top-ribbon" className="top-ribbon-v2 d-md-block">
                <div className="container-fluid">
                    <div className="row no-gutters align-items-center d-flex" style={{ justifyContent: 'space-between' }}>
                       
                        <AssignCenterInfoModal
                            show={showRememberSelectCenter || showRememberSelectShippingAddress}
                            showSelectShippingAddress={showRememberSelectShippingAddress}
                            onClose={() => {
                                this.props.rememberedCenter()
                            }}
                        />

                        <div
                            className={`location col-5 col-lg-3 ${animateChangeCenter ? ' highlight-animate1' : ' highlight-animate2'} ${
                                showRememberSelectCenter ? ' highlight' : ''
                            }`}
                        >
                            {this.props.isIkeaCenter ? <IkeaShippingAddressContainer /> : <CentersContainer />}
                            {showRememberSelectCenter && (
                                <div className="text">
                                    <h3>
                                        {this.props.isIkeaCenter ? (
                                            <Translation id="choose_destination_address" defaultMessage="Choose destination address" />
                                        ) : (
                                            <Translation id="remember_select_your_center" defaultMessage="Remember select your center" />
                                        )}
                                    </h3>
                                </div>
                            )}
                        </div>

                        <div id="logo" className="logo-cosentino" style={{ textAlign: "center" }}>
                            {/* //REVIEW: ¿Es necesario con el redirect que hay en el home.js? */}
                            {this.props.center === 7777 ? (
                                this.props.userType === 'ADMIN' ? (
                                    <LocalizedLink routeId="ROUTE_CATALOG_LOWES_ADMIN" params={{ filter: 'home' }}>
                                        <img alt="" src={logo} />
                                    </LocalizedLink>
                                ) : (
                                    <LocalizedLink routeId="ROUTE_CATALOG_LOWES" params={{ filter: 'home' }}>
                                        <img alt="" src={logo} />
                                    </LocalizedLink>
                                )
                            ) : (
                                <LocalizedLink routeId="ROUTE_HOME">
                                    <img alt="" src={logo} />
                                </LocalizedLink>
                            )}
                        </div>
                        <div className="language-selector-topRibbon">
                            <LanguageSelector />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TopRibbon)
)
