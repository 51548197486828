import React from 'react'

import Select from 'react-select'
import iconLocation from '../../assets/img/icons/location.svg'
import { injectIntl } from 'react-intl'
import confirm from '../global/confirm'
import PropTypes from 'prop-types'
import createClass from 'create-react-class'
import { getLowesCenterId, getIsMkToolsCenter,getDRIUSACenterId,getWarehoseCenterId,getShowroomCenterId } from '../../store/centers/reducers'

const centerOption = createClass({
    propTypes: {
        children: PropTypes.node,
        className: PropTypes.string,
        isDisabled: PropTypes.bool,
        isFocused: PropTypes.bool,
        isSelected: PropTypes.bool,
        onFocus: PropTypes.func,
        onSelect: PropTypes.func,
        option: PropTypes.object.isRequired
    },
    handleMouseDown(event) {
        event.preventDefault()
        event.stopPropagation()
        this.props.onSelect(this.props.option, event)
    },
    handleMouseEnter(event) {
        this.props.onFocus(this.props.option, event)
    },
    handleMouseMove(event) {
        if (this.props.isFocused) return
        this.props.onFocus(this.props.option, event)
    },
    render() {
        let imageStyle = {
            borderRadius: 3,
            display: 'inline-block',
            marginRight: 2,
            position: 'relative',
            top: -2,
            verticalAlign: 'middle',
            height: '20px',
            background: 'transparent',
            color: 'black'
        }
        const icons = { FULL_TRUCK: 'icon fal fa-truck', DIRECT_TRUCK: 'icon fal fa-truck', CONTAINER: 'icon fal fa-ship', SINGLE_SLAB: 'icon fal fa-rectangle-wide' }
        return (
            <div
                className={this.props.className}
                onMouseDown={this.handleMouseDown}
                onMouseEnter={this.handleMouseEnter}
                onMouseMove={this.handleMouseMove}
                title={this.props.option.title}
            >
                <span>{this.props.children}</span>
                {this.props.option.userType !== 'ADMIN'
                    ? this.props.option.shippingMethods.map((shippingMethod, index) => <i key={index} className={icons[shippingMethod]} style={imageStyle} />)
                    : null}
            </div>
        )
    }
})

const confirmChangeCenter = (center, onChangeCenter, intl, keepHere, userType, localizedUrl, isMkToolsCenter, returnChangeCenter) => {
    if (keepHere) {
        onChangeCenter(parseInt(center, 10))
        returnChangeCenter(parseInt(center, 10))
    } else {
        confirm(intl.formatMessage({ id: 'CHANGE_CENTER_CONFIRM' })).then(
            confirm => {
                if (center === getLowesCenterId()) {
                    if (userType !== 'ADMIN') return onChangeCenter(parseInt(center, 10), localizedUrl(intl,'ROUTE_CATALOG_LOWES' ).replace(':filter', 'home'))
                    else return onChangeCenter(parseInt(center, 10), localizedUrl(intl,'ROUTE_CATALOG_LOWES_ADMIN').replace(':filter', 'home'))
                }
                if (center === getDRIUSACenterId()|| center === getWarehoseCenterId()|| center === getShowroomCenterId()) {
                    return onChangeCenter(parseInt(center, 10), localizedUrl(intl,'ROUTE_CATALOG').replace(':outlet?', ''))
                }
                return onChangeCenter(parseInt(center, 10), localizedUrl(intl,'ROUTE_CATALOG').replace(':outlet?', '') + '?type[0]=TABLA')
            },
            cancel => {}
        )
    }
}

const Centers = ({ centers, intl, onChangeCenter, selectedCenter, keepHere, userType, dir: languageDir, employeeId, setEmployeeId,localizedUrl, isMkToolsCenter }) => {
    let options = centers.map(center => {
        return { value: center.centerId, label: center.name, shippingMethods: center.shippingTypes, userType: userType }
    })
    let input = ''
    if (!selectedCenter) {
        input = <input type="text" readOnly placeholder={intl.formatMessage({ id: 'a_center_near_to_you' })} />
    } else if (centers.length === 1) {
        input = <input type="text" readOnly value={centers[0].name !== 'IKEA' ? centers[0].name : ''} className={'center' + selectedCenter} />
    } else {
        input = (
            <Select
                onChange={selectOption => {
                    if (selectOption !== null) confirmChangeCenter(selectOption.value, onChangeCenter, intl, keepHere, userType,localizedUrl,isMkToolsCenter)
                }}
                value={selectedCenter}
                options={options}
                optionComponent={centerOption}
                clearable={false}
                className={'Select_center center' + selectedCenter}
                rtl={languageDir === 'rtl'}
            />
        )
    }

    return (
        <div className="select-center">
            {input}
            {!keepHere && (
                <div className="icon">
                    <img alt="" src={iconLocation} aria-hidden="true" />
                </div>
            )}
        </div>
    )
}

export default injectIntl(Centers)
