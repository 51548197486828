import { connect } from 'react-redux'
import CheckoutFormEndi from './CheckoutFormEndi'
import { getShippingMethod, getHasMinWeight, getSelectedCenter, isEuropeOrIberia, canOffer } from '../../../store/centers/reducers'
import { checkout, transformSkuToLots, setShowModalNoStock } from '../../../store/cart/actions'
import {
    getAlert,
    isSending,
    weightSelector,
    getGoalMin,
    getGoalMax,
    getKgPoundRatio,
    getReservationCode,
    qtySelector,
    getMonthItems,
    getWaitTillDate,
    getStatus,
    getIsCartCutToSize,
    getHaveSomeProductsBelowThreshold,
    getHaveSomeProductsWithoutSapStock,
    getShowModalNoStock,
    getReservationCutToSizeCode
} from '../../../store/cart/reducers'
import { getLanguage } from '../../../store/ui/reducers'
import { getProfileConfiguration } from '../../../store/profile-configuration/reducers'
import {
    getEndiSelectedShippingAddressId,
    getShippingAddresses,
    getShippingAddressFilteredToSelectByCenter,
    isLoadingShippingAddress
} from '../../../store/shipping-addresses/reducers'
import { fetchShippingAddresses } from '../../../store/shipping-addresses/actions'
import {
    changeShippingAddress,
    canSendMinMaxCheckout,
    canSendNeedDestination,
    canSendNaturalStoneContainer,
    resetCheckout,
    setDeliveryDate,
    setCountryOfSend,
    setSelectedShippingCenterId,
    setSelectedShippingAddress,
    setSelectedShippingAddressId,
    setDestination,
    setPo,
    setComments,
    setHasSelectedCenter,
    setEmployeeId,
    setSelectedShippingAddressSalesOrg,
    setSelectedShippingAddressEcosentinoKey,
    setCutToSizeFile
} from '../../../store/checkout/actions'
import { getDeliveryDate, getSelectedShippingAddress, getDestination, getMinRequiredDate, canSendCheckoutSaleConditions } from '../../../store/checkout/reducers'
import { getEmployeeId } from '../../../store/big-account/reducers'
import { push } from 'react-router-redux'
import { dispatchPushURL } from '../../../store/ui/actions'

const mapStateToProps = state => {
    let language = getLanguage(state)
    let defaultUnit = language === 'en' || language === 'en-US' || language === 'fr-CA' ? 'LB' : 'KG'
    let unit = getProfileConfiguration(state).weightUnit ? getProfileConfiguration(state).weightUnit : defaultUnit

    return {
        current: unit === 'KG' ? weightSelector(state) : Math.floor(weightSelector(state) * getKgPoundRatio()),
        goalMin: getGoalMin(state, unit),
        goalMax: getGoalMax(state, unit),
        shippingAddresses: getShippingAddresses(state),
        hasDestination: getShippingMethod(state) === 'CONTAINER',
        shippingMethod: getShippingMethod(state),
        alert: getAlert(state),
        status: getStatus(state),
        monthItems: getMonthItems(state),
        waitTillDate: getWaitTillDate(state),
        isSending: isSending(state),
        reservationCode: getReservationCode(state),
        totalQty: qtySelector(state),
        hasMinWeight: getHasMinWeight(state),
        centerSelected: getSelectedCenter(state),
        haveCutToSize: getIsCartCutToSize(state),
        isEuropeOrIberia: isEuropeOrIberia(state),
        isLoadingShippingAddress: isLoadingShippingAddress(state),
        deliveryDate: getDeliveryDate(state),
        minDeliveryDate: getMinRequiredDate(state),
        selectedShippingAddress: getSelectedShippingAddress(state),
        endiSelectedShippingAddressId: getEndiSelectedShippingAddressId(state),
        destination: getDestination(state),
        haveSomeProductsBelowThreshold: getHaveSomeProductsBelowThreshold(state),
        haveSomeProductsWithoutSapStock: getHaveSomeProductsWithoutSapStock(state),
        canSendCheckoutSaleConditions: canSendCheckoutSaleConditions(state),
        canOffer: canOffer(state),
        shippingAddressFilteredToSelectByCenter: getShippingAddressFilteredToSelectByCenter(state),
        employeeId: getEmployeeId(state),
        showModalNoStock: getShowModalNoStock(state),
        reservationCutToSizeCode: getReservationCutToSizeCode(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchShippingAddresses: () => dispatch(fetchShippingAddresses()),
        onSubmit: checkoutData => dispatch(checkout(checkoutData)),
        onLoadCheckout: () => dispatch({ type: 'CHECKOUT_VISIT' }),
        setDeliveryDate: value => dispatch(setDeliveryDate(value)),
        changeShippingAddress: value => dispatch(changeShippingAddress(value)),
        canSendMinMaxCheckout: () => dispatch(canSendMinMaxCheckout()),
        canSendNeedDestination: () => dispatch(canSendNeedDestination()),
        canSendNaturalStoneContainer: () => dispatch(canSendNaturalStoneContainer()),
        resetCheckout: () => dispatch(resetCheckout()),
        setCountryOfSend: value => dispatch(setCountryOfSend(value)),
        setSelectedShippingCenterId: value => dispatch(setSelectedShippingCenterId(value)),
        setSelectedShippingAddressId: value => dispatch(setSelectedShippingAddressId(value)),
        setSelectedShippingAddress: value => dispatch(setSelectedShippingAddress(value)),
        setSelectedShippingAddressEcosentinoKey: value => dispatch(setSelectedShippingAddressEcosentinoKey(value)),
        setSelectedShippingAddressSalesOrg: value => dispatch(setSelectedShippingAddressSalesOrg(value)),
        setDestination: value => dispatch(setDestination(value)),
        setPo: value => dispatch(setPo(value)),
        setComments: value => dispatch(setComments(value)),
        submitCheckout: () => dispatch(checkout()),
        setEmployeeId: value => dispatch(setEmployeeId(value)),
        setHasSelectedCenter: value => dispatch(setHasSelectedCenter(value)),
        transformSkuToLots: () => {
            return dispatch(transformSkuToLots())
        },
        setHaveOffer: value => {
            if (value) return Promise.resolve(dispatch({ type: 'CHECKOUT_HAVE_OFFER' }))
            else return Promise.resolve(dispatch({ type: 'CHECKOUT_HAVE_NOT_OFFER' }))
        },
        closeNoStockModal: () => dispatch(setShowModalNoStock(false)),
        changeEndiShippingAddress: () => dispatch({ type: 'REMEMBER_SELECT_SHIPPING_ADDRESS_ENDI' }),
        redirect: url => Promise.resolve(dispatch(dispatchPushURL(url))),
        setCutToSizeFile: value => dispatch(setCutToSizeFile(value))
    }
}
const CheckoutFormEndiContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutFormEndi)

export default CheckoutFormEndiContainer
