import React from 'react'
import Translation from '../global/Translation'

// import Slider from 'react-slick'
import { injectIntl } from 'react-intl'

import Button from '../global/Button'
import LocalizedLink from '../global/LocalizedLink'

import promoBanner_en from '../../assets/img/promotions/promo-image-en.jpg'
import promoBanner_fr from '../../assets/img/promotions/promo-image-fr.jpg'

import flyer_2011 from '../../assets/pdf/promotions/flyer-2011.pdf'
import flyer_2032_en from '../../assets/pdf/promotions/flyer-2032-en.pdf'
import flyer_2032_fr from '../../assets/pdf/promotions/flyer-2032-fr.pdf'

import promoColors from '../../assets/img/promotions/promo-colors.jpg'

/*import promotionMainImage1 from '../../assets/img/temp/promotion-main-image-1.jpg'
import promotionMainImage2 from '../../assets/img/temp/promotion-main-image-2.jpg'
import promotionMainImage3 from '../../assets/img/temp/promotion-main-image-3.jpg'
import promotionProductImage1 from '../../assets/img/temp/promotion-product-image-1.png'
import promotionProductImage2 from '../../assets/img/temp/promotion-product-image-2.png'
import promotionProductImage3 from '../../assets/img/temp/promotion-product-image-3.png'*/

class Promotions extends React.Component {
    render() {
        // const promoEnabled = this.props.salesOrg === '2011' || this.props.salesOrg === '2032' || this.props.salesOrg === '2037'
        const promoBanner = this.props.intl.locale === 'fr' || this.props.intl.locale === 'fr-CA' ? promoBanner_fr : promoBanner_en
        const flyer = this.props.salesOrg === '2032' ? (this.props.intl.locale === 'fr' || this.props.intl.locale === 'fr-CA' ? flyer_2032_fr : flyer_2032_en) : flyer_2011

        return (
            <section id="section-promotions">
                <header>
                    <h2>
                        <Translation id="new_promotions" defaultMessage="New Promotions" />
                    </h2>
                </header>

                <div className="promotions">
                    <div className="container">
                        {/*<div className="slider row mt-3 mb-3">
                            <div className="col">
                                <Slider autoplay={true} autoplaySpeed={5000} speed={1000}>
                                    <div className="ec-slide">
                                        <LocalizedLink routeId="ROUTE_CATALOG" params={{ outlet: '' }} queryString="outlet?type[0]=TABLA&promo=true">
                                            <img src={promoBanner} style={{ width: '100%' }} />
                                        </LocalizedLink>
                                    </div>
                                </Slider>
                            </div>
                        </div>*/}
                        <div className="promotion row">
                            <div className="promotion-info col-12 col-md-6">
                                <div className="promotion-title">
                                    <Translation id="promo_title" defaultMessage="Special Summer Promotion" />
                                </div>
                                <div className="promotion-description">
                                    Scalea - <Translation id="promo_subtitle" defaultMessage="July 9th – July 20th" />
                                </div>
                                <div className="promotion-product-image">
                                    <img src={promoColors} alt="" />
                                </div>
                                <div className="promotion-actions">
                                    <LocalizedLink routeId="ROUTE_CATALOG" params={{ outlet: '' }} queryString="outlet?type[0]=TABLA&promo=true">
                                        <Button onClick={() => {}} inverted inline size="small">
                                            <Translation id="view_products" defaultMessage="View Products" />
                                        </Button>
                                    </LocalizedLink>
                                    <a href={flyer} target="_blank" rel="noopener noreferrer">
                                        <Button inline size="small">
                                            <Translation id="download_flyer" defaultMessage="Download Flyer" />
                                        </Button>
                                    </a>
                                </div>
                            </div>
                            <div className="promotion-main-image col-12 col-md-6" style={{ backgroundImage: `url(${promoBanner})` }} />
                        </div>
                        {/*<div className="promotion row">
                            <div className="promotion-info col-12 col-md-6">
                                <div className="promotion-title">Integrity One</div>
                                <div className="promotion-description">Lorem ipsum dolor amet intescetur copen ictor sum imatnesac lorem ipsum dolor amet intescetur</div>
                                <div className="promotion-product-image">
                                    <img src={promotionProductImage2} alt="" />
                                </div>
                                <div className="promotion-actions">
                                    <LocalizedLink routeId="ROUTE_CATALOG" params={{ outlet: '' }} queryString="outlet?type[0]=TABLA&promo=true">
                                        <Button onClick={() => {}} inverted inline size="small">
                                            Ver productos
                                        </Button>
                                    </LocalizedLink>
                                    <a href="#">
                                        <Button inline size="small">
                                            Descargar flyer
                                        </Button>
                                    </a>
                                </div>
                            </div>
                            <div className="promotion-main-image col-12 col-md-6 order-md-first" style={{ backgroundImage: `url(${promotionMainImage2})` }} />
                        </div>
                        <div className="promotion row">
                            <div className="promotion-info col-12 col-md-6">
                                <div className="promotion-title">Integrity DUE L</div>
                                <div className="promotion-description">Lorem ipsum dolor amet intescetur copen ictor sum imatnesac lorem ipsum dolor amet intescetur</div>
                                <div className="promotion-product-image">
                                    <img src={promotionProductImage3} alt="" />
                                </div>
                                <div className="promotion-actions">
                                    <LocalizedLink routeId="ROUTE_CATALOG" params={{ outlet: '' }} queryString="outlet?type[0]=TABLA&promo=true">
                                        <Button onClick={() => {}} inverted inline size="small">
                                            Ver productos
                                        </Button>
                                    </LocalizedLink>
                                    <a href="#">
                                        <Button inline size="small">
                                            Descargar flyer
                                        </Button>
                                    </a>
                                </div>
                            </div>
                            <div className="promotion-main-image col-12 col-md-6" style={{ backgroundImage: `url(${promotionMainImage3})` }} />
                        </div>*/}
                    </div>
                </div>
            </section>
        )
    }
}

export default injectIntl(Promotions)
