import { LOCATION_CHANGE } from 'react-router-redux'
import * as impersonate from '../../store/impersonate/reducers'
import * as login from '../../store/login/reducers'
import * as cart from '../../store/cart/reducers'
import { getSalesOrg, getIsLowesCenter, getIsMkToolsCenter, getIsIkeaCenter, getSelectedCenter, isAustralianCenter, isAustraliaSalesOrg } from '../centers/reducers'
import { int } from 'react-intl'

export const initState = {
    showMenu: false,
    showPersonalData: false,
    showProfileModal: false,
    showSegmentationModal: false,
    showDektonGripModal: false,
    showLanguageModal: false,
    showEmailPromotionsModal: false,
    showWhatsappModal:false,
    showNewVersionModal: false,
    showNewVersionModalExperience: false,
    showPedidosRecurrentesModal: false,
    showAdd2CartSuccessModal: false,
    maintenanceScheduled: null,
    maintenanceScheduledText: null,
    maintenanceScheduledTextSpanish: null,
    isLogging: false,
    language: null,
    bundle: { lots: [] },
    mode: null,
    country: null,
    translateMode: false,
    showBannerOldVersion: true,
    hideAllBannerCatalog: false,
    availableLands: [
        {
            name: 'Italiano',
            code: 'it',
            localecode: 'it',
            langName: 'Italiano',
            dir: 'ltr',
            sfLang: 'it'
        },
        {
            name: '日本',
            code: 'jp',
            localecode: 'ja',
            langName: 'Japanese',
            sfLang: 'en_US'
        },
        {
            name: 'Deutsch',
            code: 'de',
            localecode: 'de',
            langName: 'Deutsch',
            dir: 'ltr',
            sfLang: 'de'
        },
        {
            name: 'Español',
            code: 'es',
            localecode: 'es',
            langName: 'Español',
            dir: 'ltr',
            sfLang: 'es'
        },
        {
            name: 'עִברִית',
            code: 'il',
            localecode: 'he',
            langName: 'עִברִית',
            dir: 'rtl',
            sfLang: 'iw'
        },
        {
            name: 'English',
            code: 'gb',
            localecode: 'en',
            langName: 'English',
            dir: 'ltr',
            sfLang: 'en_GB'
        },
        {
            name: 'Français',
            code: 'fr',
            localecode: 'fr',
            langName: 'Français',
            sfLang: 'fr'
        },
        {
            name: 'Svenska',
            code: 'se',
            localecode: 'sv',
            langName: 'Svenska',
            sfLang: 'sv'
        },
        {
            name: 'Türkçe',
            code: 'tr',
            localecode: 'tr',
            langName: 'Türkçe',
            sfLang: 'tr'
        },
        {
            name: 'Norsk',
            code: 'no',
            localecode: 'no',
            langName: 'Norsk',
            dir: 'ltr',
            sfLang: 'no'
        },
        {
            name: 'Dansk',
            code: 'dk',
            localecode: 'da',
            langName: 'Dansk',
            dir: 'ltr',
            sfLang: 'da'
        },
        {
            name: 'Nederlands',
            code: 'nl',
            localecode: 'nl',
            langName: 'Nederlands',
            dir: 'ltr',
            sfLang: 'nl'
        },
        {
            name: 'Suomi',
            code: 'fi',
            localecode: 'fi',
            langName: 'Suomi',
            dir: 'ltr',
            sfLang: 'fi'
        },
        {
            name: 'Polish',
            code: 'pl',
            localecode: 'pl',
            langName: 'Polish',
            dir: 'ltr',
            sfLang: 'pl'
        },
        {
            name: 'Portugues',
            code: 'pt',
            localecode: 'pt',
            langName: 'Portugues',
            dir: 'ltr',
            sfLang: 'pt_PT'
        },
        {
            name: 'English (United States)',
            code: 'us',
            localecode: 'en-US',
            langName: 'English (United States)',
            dir: 'ltr',
            sfLang: 'en_US'
        },
        {
            name: 'Français (Canada)',
            code: 'ca',
            localecode: 'fr-CA',
            langName: 'Français (Canada)',
            dir: 'ltr',
            sfLang: 'fr_CA'
        },
        {
            name: 'English (Canada)',
            code: 'ca',
            localecode: 'en-CA',
            langName: 'English (Canada)',
            dir: 'ltr',
            sfLang: 'en_CA'
        }
    ],
    currentUrl: null,
    isObsolete: null, //set to true when obsolete
    showIkeaModalEmployeeId: false,
    showBigAccountModal: false,
    //TODO: change to false
    canViewThresholdMax: true,
    showInsertTranslation: false,
    idTranslation: '',
    defaultMessageTranslation: '',
    isComingFromMall: false,
    isComingFromCampaign: false,
    showThankYouSubscribeModal: false,
    inProductDetailScreen: false,
    checkoutStep:0,
    isShippingHistoryScreen: false,
    currentLocation: {
      pageType: '',
      section: '',
      subtype: ''
    },
    isWalkthroughWatched: false,
    showSilestoneXMModal: false
  
}
const ui = (state = initState, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return Object.assign({}, state, {
                showMenu: false,
                showProfileModal: false,
                showSegmentationModal: false,
                currentUrl: { pathname: action.payload.location.pathname, search: action.payload.location.search },
                //TODO - update i18n url mall landing template
                isComingFromMall: state.currentUrl && (state.currentUrl.pathname.includes('landing-templates') || state.currentUrl.pathname.includes('mall')) ? true : false,
                isComingFromCampaign: state.currentUrl && state.currentUrl.pathname.includes('/landing/') ? true : false,
                // checkoutStep: 0
            })
        case 'CHECKOUT_SET_STEP':
            return Object.assign({}, state, { checkoutStep: action.step })
        case 'SET_LANGUAGE':
            return Object.assign({}, state, { language: action.locale })
        case 'CLOSE_BUNDLE_CONTENT':
            return Object.assign({}, state, { showBundleContent: false, bundle: { lots: [] } })
        case 'OPEN_BUNDLE_CONTENT':
            return Object.assign({}, state, { showBundleContent: true, bundle: action.bundle })
        case 'SHOW_LANGUAGE_MODAL':
            return Object.assign({}, state, { showLanguageModal: true })

        case 'SHOW_WHATSAPP_MODAL':
            return Object.assign({}, state, { showWhatsappModal: true })
        case 'CLOSE_WHATSAPP_MODAL':
            return Object.assign({}, state, { showWhatsappModal: false })

        case 'SHOW_EMAIL_PROMOTIONS_MODAL':
            return Object.assign({}, state, { showEmailPromotionsModal: true })
        case 'CLOSE_EMAIL_PROMOTIONS_MODAL':
            return Object.assign({}, state, { showEmailPromotionsModal: false })
        case 'SHOW_NEW_VERSION_MODAL':
            return Object.assign({}, state, { showNewVersionModal: true })
        case 'CLOSE_NEW_VERSION_MODAL':
            return Object.assign({}, state, { showNewVersionModal: false })
        case 'SHOW_NEW_VERSION_MODAL_EXPERIENCE':
            return Object.assign({}, state, { showNewVersionModalExperience: true })
        case 'CLOSE_NEW_VERSION_MODAL_EXPERIENCE':
            return Object.assign({}, state, { showNewVersionModalExperience: false })
        case 'SHOW_PEDIDOS_RECURRENTES_MODAL':
            return Object.assign({}, state, { showPedidosRecurrentesModal: true })
        case 'CLOSE_PEDIDOS_RECURRENTES_MODAL':
            return Object.assign({}, state, { showPedidosRecurrentesModal: false })
        case 'SEND_EMAIL_PROMOTIONS_MODAL':
            return Object.assign({}, state, { showEmailPromotionsModal: false })
        case 'CLOSE_LANGUAGE_MODAL':
            return Object.assign({}, state, { showLanguageModal: false })
        case 'SHOW_ADD2CARTSUCCESS_MODAL':
            return Object.assign({}, state, { showAdd2CartSuccessModal: true })
        case 'CLOSE_ADD2CARTSUCCESS_MODAL':
            return Object.assign({}, state, { showAdd2CartSuccessModal: false })
        case 'SHOW_SEGMENTATION_MODAL':
            return Object.assign({}, state, { showSegmentationModal: true, mode: action.mode, callback: action.callback })
        case 'CLOSE_SEGMENTATION_MODAL':
            return Object.assign({}, state, { showSegmentationModal: false })
        case 'SHOW_DEKTON_GRIP_MODAL':
            return Object.assign({}, state, { showDektonGripModal: true, callback: action.callback })
        case 'CLOSE_DEKTON_GRIP_MODAL':
            return Object.assign({}, state, { showDektonGripModal: false })
        case 'SHOW_PROFILE_MODAL':
            return Object.assign({}, state, { showProfileModal: true })
        case 'CLOSE_PROFILE_MODAL':
            return Object.assign({}, state, { showProfileModal: false })
        case 'SHOW_MENU':
            return Object.assign({}, state, { showMenu: true })
        case 'CLOSE_MENU':
            return Object.assign({}, state, { showMenu: false })
        case 'SHOW_PERSONAL_DATA':
            return Object.assign({}, state, { showPersonalData: true })
        case 'CLOSE_PERSONAL_DATA':
            return Object.assign({}, state, { showPersonalData: false })
        case 'RESET_UI':
            return Object.assign({}, state, {
                showMenu: false,
                showPersonalData: false,
                showProfileModal: false,
                showSegmentationModal: false,
                showLanguageModal: false,
                showBundleContent: false,
                showAdd2CartSuccessModal: false
            })
        case 'IMPERSONATE_PROCESS_INIT':
        case 'LOGIN_PROCESS_INIT':
            return Object.assign({}, state, { isLogging: true })
        case 'IMPERSONATE_PROCESS_FINISH':
        case 'LOGIN_PROCESS_FINISH':
            return Object.assign({}, state, { isLogging: false })
        case 'IMPERSONATE_REQUEST':
            return Object.assign({}, state, { isLogging: true })
        case 'IMPERSONATE_SUCCESS':
            return Object.assign({}, state, { isLogging: false })
        case 'MANDATORY_CHANGE_PASSWORD':
        case 'IMPERSONATE_PROCESS_FAILURE':
        case 'LOGIN_PROCESS_FAILURE':
            return Object.assign({}, state, { isLogging: false })
        case 'FETCH_LOCATION_COUNTRY_SUCCESS':
            return Object.assign({}, state, { country: action.location.landIsoCode })
        case 'FETCH_LOCATION_COUNTRY_FAILURE_SAVE_DEFAULT':
            return Object.assign({}, state, { country: 'US' })

        case 'FETCH_MAINTENANCE_SCHEDULED_SUCCESS':
            return Object.assign({}, state, {
                maintenanceScheduled: action.maintenance.maintenanceScheduled,
                maintenanceScheduledText: action.maintenance.maintenanceScheduledText,
                maintenanceScheduledTextSpanish: action.maintenance.maintenanceScheduledTextSpanish
            })
        case 'TOGGLE_TRANSLATE_MODE':
            return Object.assign({}, state, { translateMode: !state.translateMode })
        case 'TOGGLE_ISOBSOLETE':
            return Object.assign({}, state, { isObsolete: false })
        case 'TOGGLE_SHOW_IKEA_MODAL_EMPLOYEE_ID':
            return Object.assign({}, state, { showIkeaModalEmployeeId: !(action.open === null || action.open === undefined) ? action.open : !state.showIkeaModalEmployeeId })
        case 'TOGGLE_SHOW_BIG_ACCOUNT_MODAL':
            return Object.assign({}, state, { showBigAccountModal: !(action.open === null || action.open === undefined) ? action.open : !state.showBigAccountModal })
        case 'OPEN_MODAL_TRANSLATION':
            return Object.assign({}, state, { showInsertTranslation: true, idTranslation: action.idTranslation, defaultMessageTranslation: action.defaultMessageTranslation })
        case 'CLOSE_MODAL_TRANSLATION':
            return Object.assign({}, state, { showInsertTranslation: false })
        case 'OPEN_THANK_YOU_SUBSCRIBE_MODAL':
        case 'SAVE_EMAIL_PROMOTIONS_MODAL_SUCCESS':
            return Object.assign({}, state, { showThankYouSubscribeModal: true })
        case 'CLOSE_THANK_YOU_SUBSCRIBE_MODAL':
            return Object.assign({}, state, { showThankYouSubscribeModal: false })
        case 'CLOSE_BANNER_OLD_VERSION':
            return Object.assign({}, state, { showBannerOldVersion: false })
        case 'SHOW_BANNER_OLD_VERSION':
            return Object.assign({}, state, { showBannerOldVersion: true })
        case 'HIDE_ALL_BANNER_CATALOG':
            return Object.assign({}, state, { hideAllBannerCatalog: true })
        case 'SET_IS_COMMING_FROM_MALL':
            return Object.assign({}, state, { isComingFromMall: action.isComingFromMall })
        case 'PROCCESS_IN_DETAILS_SCREEN':
            return Object.assign({}, state, { inProductDetailScreen: action.inProductDetailScreen })
        case 'PROCCESS_IS_ORDER_HISTORY_SCREEN':
            return Object.assign({}, state, { isOrderHistoryScreen: action.isOrderHistoryScreen })
        case 'PROCCESS_IS_SHIPPING_HISTORY_SCREEN':
            return Object.assign({}, state, { isShippingHistoryScreen: action.isShippingHistoryScreen })
        case 'SET_LOCATION':
            return Object.assign({}, state, { currentLocation: { pageType: action.pageType, section: action.section, subtype: action.subsection }})
        case 'WALTHROUGH_HAS_BEEN_WATCHED':
            return Object.assign({}, state, { isWalkthroughWatched: true })
        case 'SHOW_SILESTONEXM_MODAL':
            return Object.assign({}, state, { showSilestoneXMModal: true })
        case 'CLOSE_SILESTONEXM_MODAL':
            return Object.assign({}, state, { showSilestoneXMModal: false })
        default:
            return state
    }
}

export const showBannerOldVersion = state => {
    return state.ui.showBannerOldVersion
}

export const showMenuSelector = state => {
    return state.ui.showMenu
}

export const showPersonalDataSelector = state => {
    return state.ui.showPersonalData
}
export const showLanguageSelector = state => {
    return state.ui.showLanguageModal
}
export const showingProfileModal = state => {
    return state.ui.showProfileModal
}
export const showingEmailPromotionsModal = state => {
    //show when login process finished
    return state.ui.showEmailPromotionsModal && !state.ui.isLogging && state.login.isLogged
}
export const showingWhatsappModal = state => {
    //show when login process finished
    return state.ui.showWhatsappModal && !state.ui.isLogging && state.login.isLogged
}
export const showingNewVersionModal = state => {
    //show when login process finished
    return state.ui.showNewVersionModal && !state.ui.isLogging && state.login.isLogged
}
export const showingPedidosRecurrentesModal = state => {
    //show when login process finished
    return state.ui.showPedidosRecurrentesModal && !state.ui.isLogging && state.login.isLogged
}
export const showingNewVersionModalExperience = state => {
    //show when login process finished
    return state.ui.showNewVersionModalExperience && !state.ui.isLogging && state.login.isLogged
}
export const showingSegmentationModal = state => {
    return state.ui.showSegmentationModal
}
export const showingDektonGripModal = state => {
    return state.ui.showDektonGripModal
}
export const showingAdd2CartSuccessModal = state => {
    return state.ui.showAdd2CartSuccessModal
}
export const showBundleContent = state => {
    return state.ui.showBundleContent
}
export const showingAnyModal = state => {
    let showing = false
    if (
        impersonate.showModalSelector(state) ||
        login.showModalSelector(state) ||
        cart.showModalSelector(state) ||
        cart.showCheckoutModalSelector(state) ||
        state.ui.showProfileModal ||
        state.ui.showSegmentationModal ||
        state.ui.showLanguageModal ||
        state.ui.showBundleContent
    ) {
        showing = true
    }
    return showing
}
export const getUICallback = state => {
    return state.ui.callback
}
export const getLanguage = state => {
    return state.ui.language
}

export const getLocalizedUrl = (state, intl, routeId) => {
    const {language} = state.ui
    const {newVersion} = state.login
    const prefix = (language !== 'en-US' ? ('/' + language) : '')
    // const prefix = (language !== 'en-US' ? ('/' + language) : '') + (newVersion ? '/v2' : '')
    const path = prefix + intl.formatMessage({ id: routeId })
    return path
}

export const getLanguageDir = state => {
    let lang = state.ui.availableLands.find(lang => lang.localecode === state.ui.language)
    return lang && lang.dir
}

export const getLanguageToFetchZ2 = state => {
    let locale = state.ui.language === 'fr-CA' || state.ui.language === 'en-CA' || state.ui.language === 'pl' ? 'en-US' : state.ui.language

    return locale
}
export const getMode = state => {
    return state.ui.mode
}
export const getTranslateMode = state => {
    return state.ui.translateMode
}
export const getCountry = state => {
    return state.ui.country
}
export const getAvailableLands = state => {
    return state.ui.availableLands
}
export const getCurrentUrl = state => {
    return state.ui.currentUrl
}
export const getIsObsolete = state => {
    return state.ui.isObsolete
}
export const getBundleContent = state => {
    return state.ui.bundle.lots
}
export const getOriginalBundle = state => {
    return state.ui.bundle
}
export const getShowIkeaModalEmployeeId = state => {
    return state.ui.showIkeaModalEmployeeId
}
export const getShowBigAccountModal = state => {
    return state.ui.showBigAccountModal
}
export const getMaintenanceScheduled = state => {
    return state.ui.maintenanceScheduled
}
export const getMaintenanceScheduledText = state => {
    if (getLanguage(state) == 'es') return state.ui.maintenanceScheduledTextSpanish
    return state.ui.maintenanceScheduledText
}
export const canViewThresholdMax = state => {
    return true
}
export const getShowSaleConditions = state => {
    return true
}
export const getShowHealthConditions = state => {
    if (getIsLowesCenter(state) || getIsMkToolsCenter(state) || getIsIkeaCenter(state)) {
        return false
    }
    return true
}
export const getShowInsertTranslation = state => state.ui.showInsertTranslation
export const getIdTranslation = state => state.ui.idTranslation
export const getDefaultMessageTranslation = state => state.ui.defaultMessageTranslation
export const getLanguageSF = state => {
    let lang = state.ui.availableLands.find(lang => lang.localecode === state.ui.language)
    return lang && lang.sfLang
}

export const isComingFromMall = state => {
    return state.ui.isComingFromMall
}
export const isComingFromCampaign = state => {
    return state.ui.isComingFromCampaign
}
export const fetchPromotionConfigurationEmail = state => {
    return state.profileConfiguration.promotionConfiguration.email
}

export const getHideAllBannerCatalog = state => {
    return state.ui.hideAllBannerCatalog
}

export const getCheckoutStep = state => {
    return state.ui.checkoutStep
}

export const getShowThankYouSubscribeModal = state => state.ui.showThankYouSubscribeModal

export const getIsOrderHistoryScreen = state => {
    return state.ui.isOrderHistoryScreen
}

export const getIsShippingHistoryScreen = state => {
    return state.ui.isShippingHistoryScreen
}

export const getInProductDetailScreen = state => {
    return state.ui.inProductDetailScreen
}

export const getCurrentLocation = state => {
    return state.ui.currentLocation
}

export const getWalkthroughWatched = state => {
    return state.ui.isWalkthroughWatched
}

export const getShowCheckboxAustraliaLegalProcess = state => {
    if (isAustralianCenter(state)) return true
    return false
}

export const getShowSilestoneXMModal = state => {
    const { showSilestoneXMModal, isLogging } = state.ui
    const { isLogged } = state.login

    if (isAustraliaSalesOrg(state)) {
        return false
    }

    return showSilestoneXMModal && !isLogging && isLogged
}

export default ui