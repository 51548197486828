import React from 'react'
import Translation from '../global/Translation'
import Button from '../global/Button'
import GoBack from '../global/GoBack'
import InputNumber from '../global/InputNumber'
import LengthMeasure from '../global/LengthMeasure'
import LengthMeasureTranslation from '../global/LengthMeasureTranslation'
import LocalizedLink from '../global/LocalizedLink'
import ProductCarousel from './ProductCarousel'
import ProductDisplayName from '../global/ProductDisplayName'
import Select from 'react-select'
import iconDownload from '../../assets/img/icons/download-gray.svg'
import DisplayBrand from '../catalog/rectangleResult/displays/DisplayBrand'
import DisplayPriceMktools from '../catalog/rectangleResult/displays/DisplayPriceMktools'
import mallLarge from '../../assets/img/logos/mall-large.jpg'
import DisplayPrice from "../catalog/rectangleResult/displays/DisplayPrice";
import DisplayPricePhase2 from "../catalog/rectangleResult/displays/DisplayPricePhase2";
import BannerLechic from "../banner-lechic/BannerLechic";
import {injectIntl} from "react-intl";

class ProductCard extends React.Component {

    render() {
        const typeIdShowDownlad = ['03', '09', '04']
        let { product,  isLogged, intl, canBuy, canViewOutlet, isMkToolsCenter, isIkeaCenter, colorsMktoolsAttr, brandsMktoolsAttr } = this.props
        //TODO: review callback current center
        // const callbackCenter = location.search.indexOf('promo=true') > -1 ? 7500 : null

        return (
            <div className="container product-detail product-detail-card" data-type={product.type}>
                <div className="row group_tag">
                    {((this.props.outlet && product.outlet && product.outletQuality) || (product.fromMall && product.outlet)) && (
                        <div className="outlet-indicator">{product.outletQuality && this.props.intl.formatMessage({ id: `outlet_${product.outletQuality.toLowerCase()}` })}</div>
                    )}
                    {this.props.productIsInPromo && (
                        <div className="promo-indicator">
                            <Translation id="promotion" defaultMessage="Promotion" />
                        </div>
                    )}
                    {!(product.outlet && product.outletQuality) && product.discontinued && product.discontinued !== 'DD' && product.discontinued !== 'DF' && (
                        <div className="discontinued_wrapper">
                            <div className="tooltip">
                                <div className="discontinued-indicator">
                                    <Translation id="discontinued" defaultMessage="Discontinued" />
                                    {' - '}
                                    {this.props.intl.formatMessage({ id: product.discontinued + '_SHORT' })}
                                </div>
                                <p className="tooltiptext tooltip-discontinued">{this.props.intl.formatMessage({ id: product.discontinued + '_LONG' })}</p>
                            </div>
                        </div>
                    )}
                </div>

                <GoBack text={this.props.intl.formatMessage({ id: 'keep_buying' })} datacy={'product_detail__go_back'} />
                <div className="row">
                    <div className="col-12 col-md-7 text-center">
                        <ProductCarousel product={product} isLogged={isLogged} />
                        {isMkToolsCenter && product.download && typeIdShowDownlad.includes(product.typeId) && (
                            <a href={product.download} className="bt-download">
                                <div className="icon">
                                    <img alt="" src={iconDownload} />
                                </div>
                                <div className="label">
                                    <Translation id="download_pdf" defaultMessage="Download pdf" />
                                </div>
                            </a>
                        )}
                        {product.technology == 'HYBRIQ' && (
                            <div className="product-image" style={{ minHeight: '0' }}>
                                <div className="fit-image row no-gutters" style={{ fontSize: '12px', textAlign: 'left' }}>
                                    <div className="col-3 col-md-2" style={{ paddingRight: '10px' }}>
                                        <img style={{width: '80px'}} className="image img-fluid" src="https://assetsstatic.cosentino.com/img/Patent-Pending-Logo.svg"></img>
                                    </div>
                                    <div className="col-9 col-md-10">
                                        <Translation
                                            id="hybriq_incorporate_patented"
                                            defaultMessage="HybriQ and HybriQ+® incorporate patented or patent-pending technologies."
                                        ></Translation>
                                        <br />
                                        <Translation
                                            id="hybriq_registered_trademarks"
                                            defaultMessage="HybriQ+® and HybriQ Technology® are registered trademarks owned by Cosentino S.A.U."
                                        ></Translation>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="col-12 col-md-5 product-description">
                        <h2 className="product-title" data-cy="product_detail__title">
                            <ProductDisplayName product={product} />
                        </h2>
                        <div className="d-flex justify-content-between flex-wrap align-items-center">
                            {isMkToolsCenter && product.webHeadingDesc && product.subtypeId !== 'MONOCOLOR BOX' ? (
                                <p className="product-serie">{product.webHeadingDesc.toLowerCase()}</p>
                            ) : (
                                <DisplayBrand brand={product.brand} />
                            )}
                            {this.props.product.fromMall && (
                                <div className="wrapper__logo_mall">
                                    <img style={{ maxWidth: '75px' }} src={mallLarge} alt="logo mall" />
                                </div>
                            )}
                        </div>
                        <p className="product-description-p" />

                        {product.technology == 'HYBRIQ' && (
                            <p style={{ fontSize: '12px' }}>
                                <Translation id="hybriq_loft_series" defaultMessage="The Silestone® LOFT series designs have been registered by Cosentino S.A.U."></Translation>
                                <br />
                            </p>
                        )}

                        {process.env.REACT_APP_FEATURE_ZAPATILLAS_V1 && (
                            <React.Fragment>
                                {product.thickness && (
                                    <div className="row row-others">
                                        <div className="col-12">
                                            <div className="finished">
                                                <p className="finished-title">
                                                    <Translation id="thickness" />
                                                </p>
                                                <p className="available-finished">
                                                    {this.props.alternatives && (
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div class="btn-group" role="group" aria-label="Basic example">
                                                                    {Object.entries(this.props.alternatives.thickness).map(thickness => (
                                                                        <LocalizedLink
                                                                            routeId="ROUTE_PRODUCT_DETAIL"
                                                                            params={{ id: thickness[1], outlet: this.props.outlet ? 'outlet' : '' }}
                                                                        >
                                                                            <button
                                                                                type="button"
                                                                                className={this.props.productId === thickness[1] ? 'btn btn-primary' : 'btn btn-secondary'}
                                                                            >
                                                                                {thickness[0]} cm
                                                                            </button>
                                                                        </LocalizedLink>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {product.finish && (
                                    <div className="row row-others">
                                        <div className="col-12">
                                            <div className="finished">
                                                <p className="finished-title">
                                                    <Translation id="finish" />
                                                </p>
                                                <p className="available-finished">
                                                    {this.props.alternatives && (
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div class="btn-group" role="group" aria-label="Basic example">
                                                                    {Object.entries(this.props.alternatives.finish).map(finish => (
                                                                        <LocalizedLink
                                                                            routeId="ROUTE_PRODUCT_DETAIL"
                                                                            params={{ id: finish[1], outlet: this.props.outlet ? 'outlet' : '' }}
                                                                        >
                                                                            <button
                                                                                type="button"
                                                                                className={this.props.productId === finish[1] ? 'btn btn-primary' : 'btn btn-secondary'}
                                                                            >
                                                                                <Translation id={finish[0]} />
                                                                            </button>
                                                                        </LocalizedLink>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {product.format && (
                                    <div className="row row-others">
                                        <div className="col-12">
                                            <div className="finished">
                                                <p className="finished-title">
                                                    <Translation id="format" />
                                                </p>
                                                <p className="available-finished">
                                                    {this.props.alternatives && (
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div class="btn-group" role="group" aria-label="Basic example">
                                                                    {Object.entries(this.props.alternatives.format).map(format => (
                                                                        <LocalizedLink
                                                                            routeId="ROUTE_PRODUCT_DETAIL"
                                                                            params={{ id: format[1], outlet: this.props.outlet ? 'outlet' : '' }}
                                                                        >
                                                                            <button
                                                                                type="button"
                                                                                className={this.props.productId === format[1] ? 'btn btn-primary' : 'btn btn-secondary'}
                                                                            >
                                                                                {format[0]}
                                                                            </button>
                                                                        </LocalizedLink>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                        <div className="row-others">
                            {product.finish && (
                                <div className="finished">
                                    <p className="finished-title">
                                        <Translation id="finish" defaultMessage="Finish" />
                                    </p>
                                    <p className="available-finished">
                                        <span>{intl.formatMessage({ id: product.finish })}</span>
                                    </p>
                                </div>
                            )}
                            {product.thickness && (
                                <div className="finished">
                                    <p className="finished-title">
                                        <Translation id="thickness" defaultMessage="Thickness" />
                                    </p>
                                    <p className="available-finished">
                                        <span>{product.thickness} cm</span>
                                    </p>
                                </div>
                            )}
                            {!isIkeaCenter && product.format && (
                                <div className="finished">
                                    <p className="finished-title">
                                        <Translation id="format" defaultMessage="Format" />
                                    </p>
                                    <p className="available-finished">
                                        <span> {this.props.adaptedFormat(product.format, {upper:true, showUnits:true})}</span>
                                    </p>
                                </div>
                            )}
                            {product.subtypeName && (
                                <div className="finished">
                                    <p className="finished-title">
                                        <Translation id="subtype" defaultMessage="Subtype" />
                                    </p>
                                    <p className="available-finished">
                                        <span> {isMkToolsCenter ? this.props.intl.formatMessage({ id: `subtype_${product.subtypeId}` }) : product.subtypeName}</span>
                                    </p>
                                </div>
                            )}
                            {product.pricesMktools && isMkToolsCenter && (
                                <div className="finished">
                                    <p className="finished-title">
                                        <Translation id="price" defaultMessage="Price" />
                                    </p>
                                    <p className="available-finished">
                                        <DisplayPriceMktools prices={product.pricesMktools} group={this.props.priceGroup} />
                                    </p>
                                </div>
                            )}
                            <div className="finished">
                                <p className="finished-title">
                                    <Translation id="material_id" defaultMessage="Material Id" />
                                </p>
                                <p className="available-finished">{product.productId}</p>
                            </div>
                            {isLogged && (
                                <React.Fragment>
                                    {this.props.getStock() ? (
                                        <div className="finished" data-cy="product_detail__info_logged">
                                            <p className="finished-title">
                                                <Translation id={product.type === 'TABLA' ? 'tables' : 'available'} />
                                            </p>
                                            <p className="available-finished">
                                                <span>{this.props.getStock()}</span>
                                            </p>
                                        </div>
                                    ) : null}

                                    {!product.fromMall &&
                                        (product.type === 'TABLA' ? (
                                            <div className="finished" data-cy="product_detail__info_logged">
                                                <p className="finished-title">
                                                    <LengthMeasureTranslation square={true} />
                                                </p>
                                                <p className="available-finished">
                                            <span>
                                                <LengthMeasure product={product} />
                                            </span>
                                                </p>
                                            </div>
                                        ) : (
                                            <div className="finished" data-cy="product_detail__info_logged">
                                                <p className="finished-title">
                                                    {product.unitsByBox && <Translation defaultMessage="u/box" id="u/box" />}
                                                    {product.unitsByLot && <Translation defaultMessage="u/lot" id="u/lot" />}
                                                    {!product.isMktool && !product.unitsByLot && !product.unitsByBox && <Translation id="quantity" defaultMessage="Quantity" />}
                                                </p>
                                                <p className="available-finished">
                                            <span>
                                                {product.unitsByLot && product.unitsByLot}
                                                {product.unitsByBox && product.unitsByBox}
                                                {!product.isMktool && !product.unitsByLot && !product.unitsByBox && product.stock_m2}
                                            </span>
                                                </p>
                                            </div>
                                        ))
                                    }


                                </React.Fragment>
                            )}
                        </div>
                        {product.haveOptions && brandsMktoolsAttr && (
                            <div className="row" style={{ marginBottom: '15px' }}>
                                <div className="col-12">
                                    <Select
                                        placeholder="Choose Brand"
                                        options={['No Brand'].concat(brandsMktoolsAttr).map(brand => ({
                                            label: brand.toLowerCase(),
                                            value: brand
                                        }))}
                                        className="select-mktools-attr select-mktools-attr--brand"
                                        value={this.props.state.selectedBrandMktoolAttr}
                                        onChange={selectedOption => this.props.changeBrandOption(selectedOption)}
                                        clearable={false}
                                    />
                                </div>
                            </div>
                        )}
                        {product.type === 'PPCC' && product.colorFormulations && product.colorFormulations.length > 0 && (
                            <div className="row row-others">
                                <div className="col-12">
                                    <div className="finished">
                                        <p className="finished-title">
                                            <Translation id="compatible_with_next_colors" defaultMessage="Compatible with next colors:"></Translation>
                                        </p>
                                    </div>

                                    <div className="finished" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                        {product.colorFormulations.map(colorFormulation => (
                                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: '10px' }}>
                                                <img alt="" style={{ height: '40px', width: '60px', border: '1px dashed #ccc' }} src={colorFormulation.thumb}></img>
                                                <p className="finished-title">
                                                    <span style={{ textTransform: 'Capitalize', fontSize: '12px' }}>{colorFormulation.colorName.toLowerCase()}</span>
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                        {product.haveOptions && colorsMktoolsAttr && (
                            <div className="row">
                                <div className="col-12">
                                    <Select
                                        placeholder="Choose Color"
                                        options={this.props.getOptionsColors()}
                                        className="select-mktools-attr select-mktools-attr--color"
                                        value={this.props.state.selectedColorMktoolAttr}
                                        onChange={selectedOption => this.props.changeColorOption(selectedOption)}
                                        clearable={false}
                                    />
                                </div>
                            </div>
                        )}
                        {
                            this.props.product.fromMall ?
                                <strong><DisplayPricePhase2 product={this.props.product} /></strong>
                                :
                                <DisplayPrice product={this.props.product} />
                        }

                        {
                            this.props.showLots() && !isIkeaCenter && (
                                <div className="row row-others mt-1">
                                    {
                                        isLogged && this.props.product.fromMall && product.type === 'TABLA' && this.props.getStock() > 0 && (
                                            <div className="col-12 col-sm-6 buttons-lots">
                                                {
                                                    product.lotViewAvailable === undefined || product.lotViewAvailable ? (
                                                        <LocalizedLink routeId="ROUTE_LOTS"
                                                                       params={{id: product.productId, outlet: 'mall'}}
                                                        >
                                                            <div className="lots">
                                                                <i className="icon fal fa-clone" aria-hidden="true"/>
                                                                <Translation id="view_lots" defaultMessage="View lots"/>&nbsp;({this.props.getStock()})
                                                            </div>
                                                        </LocalizedLink>
                                                    ) : (
                                                        <React.Fragment>
                                                            <i className="icon fal fa-clone" aria-hidden="true"/>
                                                            <Translation id="lots" defaultMessage="Lots"/>&nbsp;({this.props.getStock()})
                                                        </React.Fragment>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        isLogged && !this.props.product.fromMall && product.stock > 0 && product.type === 'TABLA' && !this.props.outlet && (
                                            <div className="col-12 col-sm-6 buttons-lots">
                                                {
                                                    product.lotViewAvailable === undefined || product.lotViewAvailable ? (
                                                        <LocalizedLink routeId="ROUTE_LOTS"
                                                                       params={{id: product.productId, outlet: ''}}
                                                        >
                                                            <div className="lots">
                                                                <i className="icon fal fa-clone" aria-hidden="true"/>
                                                                <Translation id="view_lots" defaultMessage="View lots"/>&nbsp;({this.props.getStock()})
                                                            </div>
                                                        </LocalizedLink>
                                                    ) : (
                                                        <React.Fragment>
                                                            <i className="icon fal fa-clone" aria-hidden="true"/>
                                                            <Translation id="lots" defaultMessage="Lots"/>&nbsp;({this.props.getStock()})
                                                        </React.Fragment>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        isLogged && !this.props.product.fromMall && product.stock_outlet > 0 && product.outlet &&
                                        product.type === 'TABLA' && canViewOutlet && this.props.outlet && (
                                            <div className="col-12 col-sm-6 buttons-lots">
                                                {
                                                    product.lotViewAvailable === undefined || product.lotViewAvailable ? (
                                                        <LocalizedLink routeId="ROUTE_LOTS"
                                                                       params={{id: product.productId, outlet: 'outlet'}}
                                                        >
                                                            <div className="lots">
                                                                <Translation id="outlet" defaultMessage="Outlet"/> ({this.props.getStock()})&nbsp;
                                                                <Translation id="lots" defaultMessage="Lots"/>
                                                            </div>
                                                        </LocalizedLink>
                                                    ) : (
                                                        <React.Fragment>
                                                            <Translation id="outlet" defaultMessage="Outlet"/> ({this.props.getStock()})&nbsp;
                                                            <Translation id="lots" defaultMessage="Lots"/>
                                                        </React.Fragment>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>

                            )
                        }


                        {canBuy && (
                            <div className="row row-price">
                                <div className="col-6">
                                        <div className="form-group">
                                            {isLogged && (
                                                <div>
                                                    <label htmlFor="quantity" className="quantity">
                                                        <Translation id="quantity" defaultMessage="Quantity" />
                                                    </label>
                                                    <InputNumber
                                                        value={this.props.state.qtyToAdd}
                                                        change={qty => this.props.state.setQtyToAdd(qty)}
                                                        step={isIkeaCenter && product.unit === 'M2' ? 0.01 : this.props.state.step}
                                                        limit={this.props.getLimit()}
                                                        min={this.props.getMin()}
                                                        decimals={isIkeaCenter && product.unit === 'M2' ? 2 : 0}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                </div>
                                <div className="col-12 col-md-6 pt-3">
                                        <div className="bt-add-to-cart">
                                            <Button
                                                inverted
                                                icon="shopping-cart"
                                                disabled={this.props.isAdding || !this.props.canAddCart()}
                                                onClick={() => this.props.addToCart()}
                                                datacy="product_detail__add"
                                            >
                                                <Translation id="add" defaultMessage="Add" />
                                            </Button>
                                        </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="row my-4">
                    <div className="col-12">
                        <BannerLechic />
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(ProductCard)
