import * as api from '../api'
import { getToken } from '../login/reducers'

export const sendSuggestion = data => (dispatch, getState) => {
    dispatch({ type: 'SEND_SUGGESTION_REQUEST', suggestion: data.suggestion })
    return api
        .sendSuggestion(getToken(getState()), data)
        .then(response => {
            dispatch({ type: 'SEND_SUGGESTION_SUCCESS', status: 200, alert: 'your_suggestions_has_been_sent' })
            return Promise.resolve()
        })
        .catch(error => {
            dispatch({ type: 'SEND_SUGGESTION_FAILURE', alert: error.response.data.err, status: error.response.status })
            return Promise.reject()
        })
}
