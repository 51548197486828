import { connect } from 'react-redux'
import { canViewCatalog } from '../../store/centers/reducers'
import IkeaShippingAddress from './IkeaShippingAddress'
import { getShippingAddresses, getSelectedShippingAddressForIkea } from '../../store/shipping-addresses/reducers'
import { changeIkeaShippingAddress } from '../../store/shipping-addresses/actions'
import { getEmployeeId } from '../../store/big-account/reducers'
import { push } from 'react-router-redux'
import { dispatchPushURL } from '../../store/ui/actions'
import { getVersion } from '../../store/login/reducers'

const mapStateToProps = state => {
    return {
        shippingAddress: getShippingAddresses(state),
        canViewCatalog: canViewCatalog(state),
        key: getSelectedShippingAddressForIkea(state),
        selectedShippingAddress: getSelectedShippingAddressForIkea(state),
        employeeId: getEmployeeId(state),
        newVersion: getVersion(state),
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onChangeIkeaShippingAddress: (shippingAddressId, intl) => {
            dispatch(changeIkeaShippingAddress(shippingAddressId))
            dispatch({ type: 'REMEMBERED_CENTER' })
            dispatch(dispatchPushURL(intl.formatMessage({ id: 'ROUTE_CATALOG' }).replace(':outlet?', '')))
        },
        rememberedCenter: () => {
            dispatch({ type: 'REMEMBERED_CENTER' })
        },
        setEmployeeId: employeeId => Promise.resolve(dispatch({ type: 'SET_EMPLOYEE_ID', employeeId }))
    }
}

const IkeaShippingAddressContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(IkeaShippingAddress)

export default IkeaShippingAddressContainer
