import { connect } from 'react-redux';
import { getSalesOrg } from './../../store/login/reducers';
import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import Translation from '../global/Translation'
import copyToClipboardIcon from '../../assets/img/components/copy-to-clipboard.svg'
import ReactTooltip from 'react-tooltip'

defineMessages({
    rocket_main_title_big_banner: {
        id: 'rocket_main_title_big_banner',
        description: 'rocket_main_title_big_banner',
        defaultMessage: 'The exclusive Rock[et] Promotion is here!'
    },
    rocket_main_title_small_banner: {
        id: 'rocket_main_title_small_banner',
        description: 'rocket_main_title_small_banner',
        defaultMessage: 'Save 15% with the Rock[et] Promotion'
    },
    rocket_description_big_banner_long: {
        id: 'rocket_description_big_banner_long',
        description: 'rocket_description_big_banner_long',
        defaultMessage: "You've been chosen for an exclusive promotion offering a <b>15%</b> off on all <b>Silestone</b> and <b>Dekton</b> slabs!"
    },
    rocket_description_big_banner_long_on_selected_colors: {
        id: 'rocket_description_big_banner_long_on_selected_colors',
        description: 'rocket_description_big_banner_long_on_selected_colors',
        defaultMessage: "You've been chosen for an exclusive promotion offering a <b>15%</b> off on selected colors of <b>Silestone</b> and <b>Dekton</b> slabs!"
    },
    rocket_promotion_description_long: {
        id: 'rocket_promotion_description_long',
        description: 'rocket_promotion_description_long',
        defaultMessage: "Don't forget to enter your promo code to take advantage of this <b>exclusive promotion for a limited time only!</b> 15% savings on all Silestone and Dekton slabs"
    },
    rocket_description_big_banner_long_selected_colors: {
        id: 'rocket_description_big_banner_long_selected_colors',
        description: 'rocket_description_big_banner_long_selected_colors',
        defaultMessage: "Don't forget to enter your promo code to take advantage of this <b>exclusive promotion for a limited time only!</b> 15% savings on selected colors of Silestone and Dekton slabs"
    },
    copy_to_clipboard: {
        id: 'copy_to_clipboard',
        description: 'copy_to_clipboard',
        defaultMessage: "Copy to clipboard"
    },
    copied: {
        id: 'copied',
        description: 'copied',
        defaultMessage: "Copied"
    },
    rocket_promo_description_just_for_italy_big_banner: {
        id: 'rocket_promo_description_just_for_italy_big_banner',
        description: 'rocket_promo_description_just_for_italy_big_banner',
        defaultMessage: "You have been chosen for an exclusive promotion offering a <b>55% discount</b> on all <b>Silestone</b> boards and <b>50% off Dekton!</b>"

    },
    rocket_promo_description_just_for_italy_small_banner_subtitle: {
        id: 'rocket_promo_description_just_for_italy_small_banner_subtitle',
        description: 'rocket_promo_description_just_for_italy_small_banner_subtitle',
        defaultMessage: "Save up to 55% with the Rock[et] promotion"
    },
    rocket_promo_description_just_for_italy_small_banner_description: {
        id: 'rocket_promo_description_just_for_italy_small_banner_description',
        description: 'rocket_promo_description_just_for_italy_small_banner_description',
        defaultMessage: "Don't forget to enter your promo code to take advantage of this <b>exclusive promotion for a limited time only!</b> 55% savings on all Silestone and 50% off Dekton slabs"
    },
    rocket_promo_subtitle_iberia: {
        id: 'rocket_promo_subtitle_iberia',
        description: 'rocket_promo_subtitle_iberia',
        defaultMessage: 'Save with the Rock[et] promotion'
    },
    rocket_promo_description_iberia: {
        id: 'rocket_promo_description_iberia',
        description: 'rocket_promo_description_iberia',
        defaultMessage: 'You have been chosen for an exclusive promotion offering a <b>discount</b> on all <b>Silestone</b> and <b>Dekton</b> slabs!'
    },
    rocket_promo_second_description_iberia: {
        id: 'rocket_promo_second_description_iberia',
        description: 'rocket_promo_second_description_iberia',
        defaultMessage: "Don't forget to enter your promotional code to benefit from this <b>exclusive promotion, for a limited time only!</b> Savings on all Silestone and Dekton slabs"
    }
    
})
const mapStateToProps = (state) => {
    return {
        salesOrg: getSalesOrg(state)
    }
}

class BigBannerRocket extends React.Component {
    constructor(props) {
        super(props)

        const copyToClipboardMsg = this.props.intl.formatMessage({
            id: 'copy_to_clipboard',
            defaultMessage: 'Copy to clipboard'
        })

        this.state = {
            copyToClipboardMsg: copyToClipboardMsg
        }
        this.bannerDetails = {
            //DEFAULT
            title: this.props.intl.formatMessage({ id: 'rocket_main_title_big_banner', defaultMessage: 'The exclusive Rock[et] Promotion is here!' }),
            subtitle: this.props.intl.formatMessage({ id: 'rocket_main_title_small_banner', defaultMessage: 'Save 15% with the Rock[et] Promotion' }),
            description: this.props.intl.formatMessage({
                id: 'rocket_description_big_banner_long',
                defaultMessage: "You've been chosen for an exclusive promotion offering a <b>15%</b> off on all <b>Silestone</b> and <b>Dekton</b> slabs!"
            }),
            couponCode: '100000331'
        }

        this.bannerDetailsColor = {
            //DEFAULT FOR COLOR
            description: this.props.intl.formatMessage({
                id: 'rocket_description_big_banner_long_on_selected_colors',
                defaultMessage: "You've been chosen for an exclusive promotion offering a <b>15%</b> off on selected colors of <b>Silestone</b> and <b>Dekton</b> slabs!"
            })
        }

        //DEFAULT
        if (props.market == 'AT') {
            this.bannerDetails.subtitle = this.bannerDetails.subtitle.replace('15%', '10%')
            this.bannerDetails.description = this.bannerDetails.description.replace('15%', '10%')
        }

        if (props.market == 'CH') {
            this.bannerDetails.subtitle = this.bannerDetails.subtitle.replace('15%', '10%')
            this.bannerDetails.description = this.bannerDetails.description.replace('15%', '10%')
        }

        if (props.market == 'DE') {
            this.bannerDetails.subtitle = this.bannerDetails.subtitle.replace('15%', '10%')
            this.bannerDetails.description = this.bannerDetails.description.replace('15%', '10%')
        }
        
        if (props.market == 'BE') {
            this.bannerDetails.subtitle = this.bannerDetails.subtitle.replace('15%', '10%')
            this.bannerDetails.description = this.bannerDetails.description.replace('15%', '10%')
        }

        if (props.market == 'NLD') {
            this.bannerDetails.subtitle = this.bannerDetails.subtitle.replace('15%', '10%')
            this.bannerDetails.description = this.bannerDetails.description.replace('15%', '10%')
        }

        if (props.market == 'IT') {
            this.bannerDetails.description = this.props.intl.formatMessage({
                id: 'rocket_promo_description_just_for_italy_big_banner',
                defaultMessage: "You have been chosen for an exclusive promotion offering a <b>55% discount</b> on all <b>Silestone</b> boards and <b>50% off Dekton!</b>"
            })
        }

        // DEFAULT WITH COLORS
        if (props.market == 'Iberia') {
            this.bannerDetails.subtitle = this.props.intl.formatMessage({
                id: 'rocket_promo_subtitle_iberia',
                defaultMessage: "Save with the Rock[et] promotion"
            })

            this.bannerDetails.description = this.props.intl.formatMessage({
                id: 'rocket_promo_description_iberia',
                defaultMessage: "You have been chosen for an exclusive promotion offering a <b>discount</b> on all <b>Silestone</b> and <b>Dekton</b> slabs!"
            })
        }

        if (props.market == 'IRLSCOT') {
            this.bannerDetails.subtitle = this.bannerDetails.subtitle.replace('15%', '20%')
            this.bannerDetails.description = this.bannerDetailsColor.description.replace('15%', '20%')
        }

        if (props.market == 'PL') {
            this.bannerDetails.description = this.bannerDetailsColor.description
        }

        // Caso base 15%. No afecta el replace.
        if (props.market == 'SE') {
            this.bannerDetails.description = this.bannerDetailsColor.description
        }

        if (props.market == 'UK') {
            this.bannerDetails.subtitle = this.bannerDetails.subtitle.replace('15%', '20%')
            this.bannerDetails.description = this.bannerDetailsColor.description.replace('15%', '20%')
        }

        // Caso base 15%. No afecta el replace.
        if (props.market == 'NO') {
            this.bannerDetails.description = this.bannerDetailsColor.description
        }

        if (props.market == 'DK') {
            this.bannerDetails.description = this.bannerDetailsColor.description
        }

        if (props.market == 'FI') {
            this.bannerDetails.description = this.bannerDetailsColor.description
        }

        if (props.market == 'Baltic') {
            this.bannerDetails.description = this.bannerDetailsColor.description
        }

        if(props.salesOrg == '1037') this.bannerDetails.couponCode =  '100000378'
        if (props.salesOrg == '1100' || props.salesOrg == '1000') this.bannerDetails.couponCode = '100000378'
        if(props.salesOrg == '2005') this.bannerDetails.couponCode =  '100000378'
        if(props.salesOrg == '2000') this.bannerDetails.couponCode =  '100000370'
        if(props.salesOrg == '2001') this.bannerDetails.couponCode =  '100000371'
        if(props.salesOrg == '2002') this.bannerDetails.couponCode =  '100000390'
        if(props.salesOrg == '2004') this.bannerDetails.couponCode =  '100000372'
        if(props.salesOrg == '2007') this.bannerDetails.couponCode =  '100000383'
        if(props.salesOrg == '2008') this.bannerDetails.couponCode =  '100000373'
        if(props.salesOrg == '2009') this.bannerDetails.couponCode =  '100000380'
        if(props.salesOrg == '2010') this.bannerDetails.couponCode =  '100000374'
        if(props.salesOrg == '2014') this.bannerDetails.couponCode =  '100000375'
        if(props.salesOrg == '2015') this.bannerDetails.couponCode =  '100000379'
        if(props.salesOrg == '2016') this.bannerDetails.couponCode =  '100000384'
        if(props.salesOrg == '2038') this.bannerDetails.couponCode =  '100000382'
        if(props.salesOrg == '2039') this.bannerDetails.couponCode =  '100000385'
        if(props.salesOrg == '2042') this.bannerDetails.couponCode =  '100000381'
        if(props.salesOrg == '2047') this.bannerDetails.couponCode =  '100000386'
        if(props.salesOrg == '2049') this.bannerDetails.couponCode =  '100000376'
        if(props.salesOrg == '2050') this.bannerDetails.couponCode =  '100000377'

        // Special vouchers and discounts percent for USA/CA customers promotion list
        if (props.market === 'USACA5') {
            this.bannerDetails.subtitle = this.bannerDetails.subtitle.replace('15%', '5%')
            this.bannerDetails.description = this.bannerDetails.description.replace('15%', '5%')
            this.bannerDetails.couponCode =  '100000431'
        }

        if (props.market === 'USACA10') {
            this.bannerDetails.subtitle = this.bannerDetails.subtitle.replace('15%', '10%')
            this.bannerDetails.description = this.bannerDetails.description.replace('15%', '10%')
            this.bannerDetails.couponCode =  '100000446'
        }
    }

    copyOnClick(e) {
        e.preventDefault()
        navigator.clipboard.writeText(this.bannerDetails.couponCode).then(() => {
            this.setState({ copied: true }, () =>
                setTimeout(() => {
                    this.setState({ copied: false })
                }, 2000)
            )
        })
    }

    flyersByMarket = {
        'SE': 'https://assetsstatic.cosentino.com/mailmarketing/rocket-promo-ib-eu-2024/Rocket_promo_2024_SE.pdf',
        'FI': 'https://assetsstatic.cosentino.com/mailmarketing/rocket-promo-ib-eu-2024/Rocket_promo_2024_FI.pdf',
        'NO': 'https://assetsstatic.cosentino.com/mailmarketing/rocket-promo-ib-eu-2024/Rocket_promo_2024_NO.pdf',
        'DK': 'https://assetsstatic.cosentino.com/mailmarketing/rocket-promo-ib-eu-2024/Rocket_promo_2024_DK.pdf',
        'Baltic': 'https://assetsstatic.cosentino.com/mailmarketing/rocket-promo-ib-eu-2024/Rocket_promo_2024_Baltics.pdf',
        'PL': 'https://assetsstatic.cosentino.com/mailmarketing/rocket-promo-ib-eu-2024/Rocket_promo_2024_PL.pdf',
        'IRLSCOT': 'https://assetsstatic.cosentino.com/mailmarketing/rocket-promo-ib-eu-2024/Rocket_promo_2024_IE-SCOTLAND.pdf',
        'UK': 'https://assetsstatic.cosentino.com/mailmarketing/rocket-promo-ib-eu-2024/Rocket_promo_2024_UK.pdf'
    }

    marketHasFlyer() {
        const marketsWithFlyers = ['Iberia', 'SE', 'FI', 'NO', 'DK', 'Baltic', 'PL', 'IRLSCOT', 'UK']
        if (marketsWithFlyers.includes(this.props.market)) return true
        return false
    }

    render() {
        return (
            <div class="big-banner-rocket">
                <div className="big-banner-rocket__container container">
                    <div className="big-banner-rocket__description">
                        <div>
                            <h2 className="big-banner-rocket-title">{this.bannerDetails.title}</h2>
                            <h2 className="small-banner-rocket-title">{this.bannerDetails.subtitle}</h2>
                        </div>
                        <div className="rocket_promotion__description--long">
                            <span dangerouslySetInnerHTML={{ __html: this.bannerDetails.description }}></span>
                        </div>
                    </div>
                    <div className="big-banner-rocket__code--section">
                        <div className="big-banner-rocket__code">
                            <div>
                                <span>
                                    <Translation id="enter" defaultMessage="Enter" />
                                </span>
                                :&nbsp;
                                <span>{this.bannerDetails.couponCode}</span>
                            </div>
                            <div>
                                <button
                                    onClick={e => {
                                        this.copyOnClick(e)
                                    }}
                                    data-tip={this.state.copyToClipboardMsg}
                                    className="copy-to-clipboard__btn"
                                >
                                    <img src={copyToClipboardIcon} alt="copy to clipboard" />
                                    <ReactTooltip
                                        bg-coloreffect="solid"
                                        place="top"
                                        getContent={() =>
                                            this.state.copied ? this.props.intl.formatMessage({ id: 'copied', defaultMessage: 'Copied' }) : this.state.copyToClipboardMsg
                                        }
                                    />
                                </button>
                            </div>
                        </div>
                        <div className="big-banner-rocket__small--text">
                            <span>
                                <Translation id="rocket_promotion_checkout_start_saving" defaultMessage="at checkout to start saving!" />
                            </span>
                        </div>
                    </div>
                </div>
                
                {this.marketHasFlyer() && (
                    this.props.market == 'Iberia' ? (
                        <div className="container check-colors">
                            <small>
                                <Translation id="check_the_selected_colors" defaultMessage="Check the selected colors" />{' '}
                                <a href="https://static.cosentino.com/mailmarketing/rocket-promo-ib-eu-2024/Rocket_promo_2024_SILESTONE_ES.pdf" target="_blank" rel="noopener noreferrer" className="brand-new-blue"><Translation id="silestone" defaultMessage="Silestone"/></a>{' '}
                                <a href="https://static.cosentino.com/mailmarketing/rocket-promo-ib-eu-2024/Rocket_promo_2024_DEKTON_ES.pdf" target="_blank" rel="noopener noreferrer" className="brand-new-blue"><Translation id="dekton" defaultMessage="Dekton" /></a>
                            </small>
                        </div>
                    ) : (
                        <div className="container check-colors">
                        <small>
                            <Translation id="check_the_selected_colors" defaultMessage="Check the selected colors" />{' '}
                            <a href={this.flyersByMarket[this.props.market]} target="_blank" rel="noopener noreferrer" className="brand-new-blue">
                                <Translation id="here" defaultMessage="Here" />
                            </a>
                        </small>
                    </div>
                    )
                )}
            </div>
        )
    }
}
export default injectIntl(connect(mapStateToProps, null)(BigBannerRocket))
