import React from 'react'
import LocalizedLink from '../global/LocalizedLink'
import Translation from '../global/Translation'
import TABLAS_IMAGE from '../../assets/img/banners-v2/tablas.png'
import Button from '../global/Button'

export default class BannerTablas extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <LocalizedLink
                routeId="ROUTE_CATALOG"
                queryString="?type[0]=TABLA"
                params={{ outlet: '' }}
            >
                <div className="box-parent-banner-tablas" style={{
                    backgroundImage: `url(${TABLAS_IMAGE})`,
                }}>
                    <div className="box-container">
                        <div className="box-title">
                        <Translation id="tables" defaultMessage="Tables" />
                        </div>
                        <div className="box-subtitle">
                            <Translation id="our_boards" defaultMessage="Our Silestione, Dekton and Sensa" />
                        </div>
                        <div className="box-button-container">
                            <div className="box-button">
                                <LocalizedLink routeId='ROUTE_CATALOG' queryString='?type[0]=TABLA' params={{ outlet: '' }}>
                                    <Translation id="shop_here" defaultMessage="Buy here" />
                                </LocalizedLink>
                            </div>
                        </div>
                    </div>
                </div>
            </LocalizedLink>
        )
    }
}
