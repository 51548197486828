import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import Button from '../global/Button'
import Modal from '../global/Modal'
import confirm from '../global/confirm'
import Spinner from '../global/Spinner'
import Alerts from '../alerts/Alerts'
import ChannelV4 from './ChannelV4'
import SalesForceIdV3 from './SalesForceIdV3'
import ProjectIdV3 from './ProjectIdV3'
import UseV3 from './UseV3'
import TypologyV3 from './TypologyV3'
import LineSegmentationV2 from '../cart-line/LineSegmentationV2'

const confirmDeleteSegmentation = (currentFavourite, deletePreferredSegmentation, intl) => {
    return confirm(intl.formatMessage({ id: 'CONFIRM' })).then(
        confirm => {
            return deletePreferredSegmentation(currentFavourite)
        },
        cancel => {
            return Promise.reject()
        }
    )
}
class SegmentationV3 extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            saveSegmentationPromptOpen: false,
            active: null,
            excuse: null,
            expandSaved: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.segmentatingProductId !== this.props.segmentatingProductId) {
            this.setState({ product: this.props.getProductById(this.props.segmentatingProductId) })
            // console.log('PROPS', this.props)
            // console.log('STATE', this.state)
        }

        if (
            prevState.active && (
                this.props.businessSegment != prevProps.businessSegment ||
                this.props.channel != prevProps.channel ||
                this.props.subchannel != prevProps.subchannel ||
                this.props.salesForceId != prevProps.salesForceId ||
                this.props.salesForceName != prevProps.salesForceName ||
                this.props.projectId != prevProps.projectId ||
                this.props.projectName != prevProps.projectName ||
                this.props.subchannelProgram != prevProps.subchannelProgram ||
                this.props.typology != prevProps.typology ||
                this.props.useName != prevProps.useName
            )
        ) {
            console.log('CAMBIO')
            this.setState({active: null})
        }
    }

    clearSegmentation = () => {
        this.setState({ active: null })
        this.props.clearSegmentation()
    }
    deleteSegmentation = async currentFavourite => {
        confirmDeleteSegmentation(currentFavourite, this.props.deletePreferredSegmentation, this.props.intl).then(() => {
            if (currentFavourite === this.state.active) this.setState({ active: null })
        })
    }
    toggleSegmentation = name => {
        const activeSegmentation = this.state.active === name ? null : name
        if(activeSegmentation){            
            this.loadSegmentation(name)
        }else{
            this.clearSegmentation()
        }
        this.setState({ active: activeSegmentation })
    }
    loadSegmentation = name => {
        this.props.loadPreferredSegmentation(name)
        this.setState({ active: name })
    }
    saveSegmentation = name => {
        this.props.saveSegmentationPreferred(name)
        this.setState({ saveSegmentationPromptOpen: false, active: name })
    }
    handleSubmit() {
        const { callback } = this.props
        if (this.poInput) {
            const po = this.poInput.value.split(';')
            let poTrue = po.filter(function (poItem) {
                return poItem !== ''
            })
            this.props.setPO(poTrue)
            this.poInput.value = ''
        }
        this.props.onSubmitSegmentation(callback)
    }
    componentDidMount() {
        this.props.fetchSegmentation()
    }
    canAddMaterial() {
        if (this.props.isExclusiveHomeDepot) {
            // if (exclusiveMaterialsHomeDepot.includes(this.props.segmentatingProductId)) {
            if (!this.props.validHomeDepotSegmentation) {
                if (!this.state.excuse) this.setState({ excuse: 'EXCLUSIVE_HOME_DEPOT' })
                return false
            }
        }
        if (this.state.excuse) this.setState({ excuse: null })
        return true
    }
    isFormFullFilled = () => {
        let salesForceIsValid = this.props.sfOptions && Object.keys(this.props.sfOptions).length > 0 ? this.props.salesforceId !== null : true
        if (this.props.channel === 'B0') {
            salesForceIsValid = true
        }
        const needsProjectId = (
            (
                (this.props.channel === 'BD' || this.props.channel === 'BB')
                && this.props.salesforceId
                && this.props.sfOptions[this.props.salesforceId]
                && Object.keys(this.props.sfOptions[this.props.salesforceId].projectOptions).length > 0
            ) || (
                this.props.channel === 'B0' && Object.keys(this.props.projectOptions).length > 0
            ) || (
                this.props.channel === 'C0' && Object.keys(this.props.projectOptions).length > 0 && !this.props.isOptionalBuilderProjectCenter
            )
        )

        // const needsUse = this.props.isAdmin || !this.props.use
        // if (needsUse) return false

        const formIsFullfilled = this.props.channel === 'A0' || (
            this.props.channel && salesForceIsValid && (needsProjectId ? this.props.projectId : true)
        )

        return formIsFullfilled
    }

    scrollDown = (e) => {
        var element = document.getElementById("seg-content")
        element.scrollTop = element.scrollHeight
    }

    render() {

        const isInAreaPersonal = !!window.location.pathname.match(/segmentation/gi)

        const { salesforceId, channel } = this.props
        // const salesForceIsValid = this.props.sfOptions && Object.keys(this.props.sfOptions).length > 0 ? salesforceId !== null : true
        // const formIsFullfilled = (channel && salesForceIsValid) || channel === 'A0'
        const submitButton =
            this.isFormFullFilled() && this.canAddMaterial() && !window.location.href.includes("personal") ? (
                <Button onClick={() => this.handleSubmit()} inverted dataQas="global_segmentation-add-to-cart-button">
                    <Translation id="add_to_cart" defaultMessage="Add to cart" />
                </Button>
            ) : (
                <Button disabled inverted>
                    <Translation id="add_to_cart" defaultMessage="Add to cart" dataQas="global_segmentation-add-to-cart-button"/>
                </Button>
            )

        return (
            <div>
                {(this.props.isSaving || this.props.isLoading) && <Spinner isLoading={this.props.isSaving || this.props.isLoading} />}
                <div className="container-fluid">                   
                    {this.state.excuse && <Alerts alert={this.state.excuse} status="warning" />}
                    <div id="seg-content" className="row" style={{ overflow: 'auto', padding: ".5em" }}>
                        {this.props.preferredSegmentation && this.props.preferredSegmentation.length > 0 && (
                            <div className="col-12">
                                <div className="form-group pt-3 pb-2" style={{ background: '#F9F9F9', marginBottom: 0 }}>
                                    <h6 onClick={() => this.setState({ expandSaved: !this.state.expandSaved })} style={{ cursor: 'pointer' }} className="mb-2 ml-3">
                                        <Translation id="saved_segmentations" defaultMessage="Saved segmentations" />
                                        {this.state.expandSaved ? <i className="fa fa-chevron-up" /> : <i className="fa fa-chevron-down" />}
                                    </h6>
                                    <div className="saved-segmentations" style={{ display: this.state.expandSaved ? 'block' : 'none' }}>
                                        {this.props.preferredSegmentation.map(option => (
                                            <div
                                                onClick={selectedOption => this.toggleSegmentation(option.name)}
                                                key={option.name}
                                                className={option.name === this.state.active ? 'active-segmentation-v4 row no-gutters ml-1' : 'row no-gutters ml-2'}
                                            >
                                                <div className="col-9" style={{display: "flex", flexDirection: "column"}}>
                                                    <strong style={{fontWeight: "bold"}}>{option.name}</strong>
                                                    <small>
                                                        <LineSegmentationV2 line={{ ...option, segmentation: option }} showAsList hideSpans />
                                                    </small>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="col-12">
                            <div className="row">
                                {/* <div className="form-group"> */}
                                <div className="col-12 mt-3">
                                    <h5>
                                        <Translation id="choose_segmentation" defaultMessage="Choose segmentation"></Translation>
                                    </h5>
                                    <ChannelV4 intl={this.props.intl} />
                                </div>
                                {/*
                                        <div className="col-12">
                                            <SubChannelV3 />
                                        </div>
                                    )} */}
                            </div>
                            <div className="row">
                                {channel != null && channel != 'B0' && channel != 'HE' && (
                                    <div className="col" onClick={this.scrollDown}>
                                        <SalesForceIdV3 intl={this.props.intl} />
                                    </div>
                                )}
                                {(channel == 'C0' || channel == 'BB' || channel == 'BD' || channel == 'B0' || channel == 'HE' || channel == 'DA' || channel == 'HO') && (
                                    <div className="col" onClick={this.scrollDown}>
                                        <ProjectIdV3 intl={this.props.intl} />
                                    </div>
                                )}
                            </div>
                            {this.props.viewFullSegmentationV3 && (
                                <div className="row">
                                    <div className="col" onClick={this.scrollDown}>
                                        <TypologyV3 intl={this.props.intl} product={this.state.product} />
                                    </div>
                                    <div className="col" onClick={this.scrollDown}>
                                        <UseV3 intl={this.props.intl} />
                                    </div>
                                </div>
                            )}
                            {/* {channel === 'C0' && (
                                        <div className="col-12">
                                            <BuildersV3 />
                                        </div>
                                    )} */}
                        </div>
                        {/* </div> */}
                    </div>
                    <hr />
                    <footer>
                        <div>
                            {this.isFormFullFilled() ? (
                                <Button
                                    inverted
                                    onClick={
                                        this.state.active
                                            ? () => this.saveSegmentation(this.state.active)
                                            : () => {
                                                this.setState({ saveSegmentationPromptOpen: true })
                                            }
                                    }
                                >
                                    <Translation id="save" defaultMessage="Save" />
                                </Button>
                            ) : (
                                <Button inverted disabled>
                                    <Translation id="save" defaultMessage="Save" />
                                </Button>
                            )}
                        </div>
                        {/* <div>
                            <Button inverted onClick={() => this.clearSegmentation()}>
                                <Translation id="clear_segmentation" defaultMessage="Clear segmentation" />
                            </Button>
                        </div> */}

                        <div>{submitButton}</div>
                    </footer>
                </div>
                {this.state.saveSegmentationPromptOpen && (
                    <Modal
                        title={<Translation id="segmentation_prompt_title" defaultMessage="Saving segmentation" />}
                        onClose={() =>
                            this.setState({
                                saveSegmentationPromptOpen: false
                            })
                        }
                        footer={
                            <Button onClick={() => this.saveSegmentation(this.inputSegmentation.value)} inverted size="medium">
                                <Translation id="save" defaultMessage="Save" />
                            </Button>
                        }
                    >
                        <p>
                            <Translation id="segmentation_prompt_body" defaultMessage="Please choose a unique name for this configuration" />
                        </p>
                        <div className="form-group">
                            <b>
                                <small>
                                    <Translation id="name" defaultMessage="Name" />
                                </small>
                            </b>
                            <input
                                ref={ref => (this.inputSegmentation = ref)}
                                type="text"
                                className="form-control"
                                data-cy="modal_segmentation__input_name_save_segmentation"
                                placeholder={this.props.intl.formatMessage({
                                    id: 'name'
                                })}
                            />
                        </div>
                    </Modal>
                )}
            </div>
        )
    }
}
export default injectIntl(SegmentationV3)
