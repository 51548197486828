import { connect } from 'react-redux'
import {
    getSelectedCenter,
    canOnlyViewOutlet,
    canViewOutlet,
    hasPromoActivated,
    getIsMkToolsCenter,
    getIsLowesCenter,
    getMktoolsShippingCenter,
    getIsIkeaCenter,
    getPromoBanners,
    getIsVirtualVisitEnabled,
    hasPromotionLandingActivated,
    getHQCenterId,
    getShowroomCenterId,
    getLowesCenterId,
    isEuropeOrIberia,
    isUsaOrCanada,
    getCenterData,
    getWarehoseCenterId
} from '../../store/centers/reducers'
import {changeCenter, changeFirstCenterFromSalesOrg, setMkToolsShippingCenter} from '../../store/centers/actions'
import Catalog from './Catalog'
import { injectIntl } from 'react-intl'
import { fetchProductsFavourite, fetchDeviatedDescriptions } from '../../store/products/actions'
import { getProductsIdFavourite, canViewPricesPhase3, getPromotionsProductIds } from '../../store/products/reducers'
import {
    getIsLogged,
    getToken,
    getSalesOrg,
    getUserType,
    getImpersonatedBy,
    getIsAdmin,
    getHaveUserSeenOutletModal,
    getIsMagazineMk,
    getPriceList,
    getCommonParams,
    getPriceListAtSelectedCenter,
    getVirtualVisitURL,
    getUserDiscountsProductIds,
    getUserPriceList,
    getTypeClient,
    getTestAB,
    canViewRocketPromotion,
    canViewSilestonePromotion,
    canViewRockMasterPromotion,
    canViewOutletAndNaturalStoneFromHQInOtherCenters,
    canViewEarthicPromotion,
    canViewHybriqPromotion,
    canViewSilestonePromotionXM,
} from '../../store/login/reducers'
import { withRouter } from 'react-router-dom'
import { push } from 'react-router-redux'
import { getShippingAddressesCenters, isLoadingShippingAddress } from '../../store/shipping-addresses/reducers'
import { fetchShippingAddresses } from '../../store/shipping-addresses/actions'
import {
    getCurrentLocation,
    getHideAllBannerCatalog,
    getLanguage,
    getMaintenanceScheduledText
} from '../../store/ui/reducers'
import { loginRequired } from '../../store/login/actions'
import { getBannerDescription, getBannerTitle, getPromotionLateralBanner, getPromotionLink } from '../../store/promotions/reducers'
import {getAdaptedFormat} from "../../helpers";
import { dispatchPushURL } from '../../store/ui/actions'
import moment from 'moment'
const mapStateToProps = (state, { match }) => {
    return {
        token: getToken(state),
        fullView: getIsLogged(state),
        center: getSelectedCenter(state) ? getSelectedCenter(state) : 'near',
        key: getSelectedCenter(state) + '-' + match.params.outlet,
        outlet: match.params.outlet,
        canOnlyViewOutlet: canOnlyViewOutlet(state),
        isLogged: getIsLogged(state),
        isAdmin: getIsAdmin(state),
        salesOrg: getSalesOrg(state),
        productsIdFavourite: getProductsIdFavourite(state),
        promotionsProductIds: getPromotionsProductIds(state, match.params.outlet),
        userType: getUserType(state),
        canViewOutlet: canViewOutlet(state),
        canViewPricesPhase3: canViewPricesPhase3(state),
        hasPromoActivated: hasPromoActivated(state),
        hasPromotionLandingActivated: hasPromotionLandingActivated(state),
        promotionLateralBanner: getPromotionLateralBanner(state),
        promotionLink: getPromotionLink(state),
        promoBanners: getPromoBanners(state),
        isMkToolsCenter: getIsMkToolsCenter(state),
        impersonatedBy: getImpersonatedBy(state),
        isLowesCenter: getIsLowesCenter(state),
        isIkeaCenter: getIsIkeaCenter(state),
        shippingAddressesCenters: getShippingAddressesCenters(state),
        isLoadingShippingAddresses: isLoadingShippingAddress(state),
        mktoolsShippingCenter: getMktoolsShippingCenter(state),
        alreadyClosedOutletModal: getHaveUserSeenOutletModal(state),
        isMagazineMk: getIsMagazineMk(state),
        language: getLanguage(state),
        virtualVisitiEnabled: getIsVirtualVisitEnabled(state),
        URLVirtualVisit: getVirtualVisitURL(state),
        priceList: getPriceListAtSelectedCenter(state),
        userPriceList: getUserPriceList(state),
        commonParamsAnalytics: getCommonParams(state),
        maintenanceScheduledText: getMaintenanceScheduledText(state),
        promotionBannerTitle: getBannerTitle(state),
        promotionBannerDescription: getBannerDescription(state),
        newVersion: state.login.newVersion,
        HQCenterId: getHQCenterId(),
        showroomCenterId: getShowroomCenterId(),
        adaptedFormat: (format, params) => getAdaptedFormat(format, params, state),
        LowesCenterId: getLowesCenterId(),
        typeClient: getTypeClient(state),
        testAB: getTestAB(state),
        isHideAllBannerCatalog: getHideAllBannerCatalog(state),
        isUsaOrCanada: isUsaOrCanada(state),
        showBannerPromo: new moment() < moment('2022-11-18 18:00') && isEuropeOrIberia(state),
        canViewRocketPromotion: canViewRocketPromotion(state),
        canViewSilestonePromotion: canViewSilestonePromotion(state),
        canViewOutletAndNaturalStoneFromHQInOtherCenters: canViewOutletAndNaturalStoneFromHQInOtherCenters(state),
        currentLocation: getCurrentLocation(state),
        centerData: getCenterData(state),
        canViewRockMasterPromotion: canViewRockMasterPromotion(state),
        canViewEarthicPromotion: canViewEarthicPromotion(state),
        canViewHybriqPromotion: canViewHybriqPromotion(state),
        isWarehouse: getSelectedCenter(state) == getWarehoseCenterId(),
        canViewSilestonePromotionXM: canViewSilestonePromotionXM(state),
    }
}
const mapDispatchToProps = dispatch => {
    return {
        redirect: url => {
            dispatch(dispatchPushURL(url))
        },
        changeCenter: center => {
            dispatch(changeCenter(center))
        },
        changeCenterFromSalesOrg: salesOrg => {
            dispatch(changeFirstCenterFromSalesOrg(salesOrg))
        },
        fetchProductsFavourite: () => {
            dispatch(fetchProductsFavourite())
        },
        fetchShippingAddresses: () => {
            dispatch(fetchShippingAddresses())
        },
        setMkToolsShippingCenter: mktoolsShippingCenter => {
            dispatch(setMkToolsShippingCenter(mktoolsShippingCenter))
        },
        dontShowOutletModalAgain: () => {
            dispatch({ type: 'DONT_SHOW_OUTLET_MODAL_AGAIN' })
        },
        fetchDeviatedDescriptions: () => dispatch(fetchDeviatedDescriptions()),
        loginRequired: (callbackSalesOrg = null) => {
            dispatch(loginRequired(null, null, callbackSalesOrg))
        },
        sendSearchProduct: (term) => {
            dispatch({type: 'SEND_SEARCH_PRODUCT', term})
        }
    }
}
const CatalogContainer = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Catalog)
)

export default injectIntl(CatalogContainer)
