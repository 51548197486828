
export const initState = {
           isLoading: false,
           mainParams: {
               materials: null,
               catalog: null,
               pricingGroup: null,
               soleriaColors: null
           },
           secondaryParams: null,
           preOrdersCts: [],
           preOrdersCurrentPage: 1,
           preOrdersLastPage: 1,
           preOrdersProjectAvailables: [],
           isSending:false
       }

const cutToSize = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_CUT_TO_SIZE_MAIN_PARAMS_REQUEST':
            return { ...state, isLoading: true }

        case 'FETCH_CUT_TO_SIZE_MAIN_PARAMS_SUCCESS':
            return { ...state, mainParams: action.mainParams, isLoading: false }

        case 'RESET_CUT_TO_SIZE':
            return { ...state, mainParams: initState.mainParams, secondaryParams: null, isLoading: false }

        case 'FETCH_CUT_TO_SIZE_MAIN_PARAMS_FAILURE':
            return { ...state, mainParams: initState.mainParams, isLoading: false }

        case 'FETCH_CUT_TO_SIZE_SECONDARY_PARAMS_REQUEST':
            return { ...state, isLoading: true }

        case 'FETCH_CUT_TO_SIZE_SECONDARY_PARAMS_SUCCESS':
            return { ...state, secondaryParams: action.secondaryParams, isLoading: false }

        case 'FETCH_CUT_TO_SIZE_SECONDARY_PARAMS_FAILURE':
            return { ...state, secondaryParams: null, isLoading: false }
        case 'FETCH_PRE_ORDERS_CTS_FAILURE':
        case 'CUT_TO_SIZE_SEND_PREORDER_MESSAGE_FAILURE':
        case 'CUT_TO_SIZE_SEND_APPROVE_PREORDER_MESSAGE_FAILURE':
        case 'CUT_TO_SIZE_CANCEL_PREORDER_FAILURE':
            return { ...state, isLoading: false }

        case 'CUT_TO_SIZE_SEND_PREORDER_MESSAGE_SUCCESS':
        case 'CUT_TO_SIZE_SEND_APPROVE_PREORDER_MESSAGE_SUCCESS':
            const preorder = state.preOrdersCts.find(po => po.id === action.preorderId)
            preorder.communications = action.communications
            preorder.files = action.files
            return { ...state, isLoading: false }

        case 'CLEAR_PRE_ORDERS_CTS':
            return { ...state, preOrdersCts: [] }

        case 'FETCH_PRE_ORDERS_CTS_REQUEST':
        case 'CUT_TO_SIZE_SEND_PREORDER_MESSAGE':
        case 'CUT_TO_SIZE_SEND_APPROVE_PREORDER_MESSAGE':
        case 'CUT_TO_SIZE_CANCEL_PREORDER':
            return { ...state, isLoading: true }
        case 'FETCH_PRE_ORDERS_CTS_SUCCESS':
            return {
                ...state,
                preOrdersCts: action.ordersCurrentPage > 1 ? state.preOrdersCts.concat(action.data) : action.data,
                isLoading: false,
                preOrdersCurrentPage: action.ordersCurrentPage,
                preOrdersLastPage: action.ordersLastPage
            }
        case 'FETCH_PRE_ORDERS_CTS_PROJECT_AVAILABLES_SUCCESS':
            return { ...state, preOrdersProjectAvailables: action.projects }
        case 'CUT_TO_SIZE_PREORDER_REQUEST':
            return { ...state, isSending: true }
        case 'CUT_TO_SIZE_PREORDER_REQUEST_SUCCESS':
            return { ...state, isSending: false }
        case 'CUT_TO_SIZE_PREORDER_REQUEST_FAILURE':
            return { ...state, isSending: false }
        default:
            return state
    }
}

export const isLoading = state => {
    return state.cutToSize.isLoading
}
export const getPreOrdersCts = state => {
    return state.cutToSize.preOrdersCts
}

export const getPreOrderById = (state,id) => {
    return state.cutToSize.preOrdersCts.find(item =>{ return item.id == id || item.id.toLowerCase() == id.toLowerCase()})
}
export const getCutToSizeMainParams = state => {
    return state.cutToSize.mainParams
}

export const getCutToSizeMaterials = state => {
    return state.cutToSize.mainParams.materials
}

export const getCutToSizeCatalog = state => {
    return state.cutToSize.mainParams.catalog
}

export const getCutToSizeSoleriaColors = state => {
    return state.cutToSize.mainParams.soleriaColors
}

export const getCutToSizeCustomerPricingGroup = state => {
    return state.cutToSize.mainParams.pricingGroup
}

export const getCutToSizeSecondaryParams = state => {
    return state.cutToSize.secondaryParams
}
export const getPreOrdersCurrentPage = state => {
    return state.cutToSize.preOrdersCurrentPage
}
export const getPreOrdersLastPage = state => {
    return state.cutToSize.preOrdersLastPage
}
export const getPreOrdersProjectAvailables = state => {
    return state.cutToSize.preOrdersProjectAvailables
}
export const getIsSending = state => {
    return state.cutToSize.isSending
}
export default cutToSize
