import React from 'react'
import Translation from '../global/Translation'

import { connect } from 'react-redux'
import { getCurrentSegmentation, getSelectedUse, getUseOptions } from '../../store/segmentation/reducers'
import { selectUse, toggleSegmentation } from '../../store/segmentation/actions'
import Select from 'react-select'

const mapStateToProps = state => {
    return {
        show: getCurrentSegmentation(state) === 'USE',
        selected: getSelectedUse(state),
        options: getUseOptions(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onToggle: () => {
            dispatch(toggleSegmentation('USE'))
        },
        onSelect: value => {
            dispatch(selectUse(value))
        }
    }
}
const UseV3 = ({ show, selected, onToggle, onSelect, options, intl }) => {
    if (!options || Object.entries(options).length === 0) {
        return ''
    }

    return (
        <div>
            <Translation id="use" defaultMessage="Use" />
            <Select
                // placeholder={intl.formatMessage({ id: 'use' })}
                placeholder={intl.formatMessage({ id: 'use' }) + ' (' + intl.formatMessage({ id: 'optional' }) + ')'}
                options={Object.entries(options).map(option => ({
                    label: option[1].name,
                    value: option[1].id
                }))}
                value={selected ? selected.id : null}
                onChange={option => {
                    if (option) onSelect({ id: option.value, name: option.label })
                    else onSelect(null)
                }}
                clearable={true}
            />

            {/* <hr /> */}
        </div>
    )
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UseV3)
