import React, { Component } from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import GoBack from '../global/GoBack'
import Button from '../global/Button'
import Translation from '../global/Translation'
import { getCutToSizeSecondaryParams, getCutToSizeMaterials, getCutToSizeCatalog, getCutToSizeCustomerPricingGroup } from '../../store/cut-to-size/reducers'
import { askForBudget } from '../../store/cut-to-size/actions'
import { colorOption, colorValue } from './ColorOptions'
import { fetchCutToSizeSecondaryParams } from '../../store/cut-to-size/actions'
import ElaborationDetails from './ElaborationDetails'
import { Messages, BrandOptionsMap } from './CutToSizeMaps'
import FloatingAlert from '../alerts/FloatingAlert'
import { showToastNotification } from '../../store/toast-notifications/actions'
import LocalizedLink from "../global/LocalizedLink";
import {getAddonOptions, getFinishOptions, getThicknessOptions} from "./CalculateFromOptions";

const FILE_LIMIT = 4 //MB
const BYTES_IN_MB = 1024 * 1024
const DEFAULT_FILE_TYPE = 'BX01'


const mapStateToProps = state => {
    return {
        secondaryParams: getCutToSizeSecondaryParams(state),
        materials: getMaterials(state),
        catalog: getCutToSizeCatalog(state),
        pricingGroup: getCutToSizeCustomerPricingGroup(state),
    }
}

//Tratar genéricos como solo corte
const getMaterials = (state) => {
    const materials = Object.assign({}, getCutToSizeMaterials(state))
    if (materials) {
        materials['BUDGET'] = materials['SOLO_CORTE']
    }
    return materials
}

const mapDispatchToProps = dispatch => {
    return {
        fetchSecondaryParams: (elaboration, brand, color, pricingGroup, hasSoleriaDekton, callback) => {
            const elaborationType = elaboration === 'BUDGET' ? 'SOLO_CORTE' : elaboration
            dispatch(fetchCutToSizeSecondaryParams(elaborationType, brand, color, pricingGroup, hasSoleriaDekton, callback))
        },
        askForBudget: (formData, callback) => dispatch(askForBudget(formData, callback)),
        showToastNotification: (text = '', type = 'none', duration = 10000) => dispatch(showToastNotification(text, type, duration)),
    }
}


class CutToSizeFormBudget extends Component {

    state = {
        selectedBrand: null,
        selectedColor: null,
        currentColorOptions: [],
        currentBrandOptions: [],
        selectedThickness: null,
        selectedFinished: null,
        selectedAddon: null,
        selectedFiles: {},
        quoteFile: null,
        showExceedFileSizeAlert: false,
        comments: null
    }

    componentDidMount() {
        Object.keys(this.props.materials[this.props.selectedElaboration]).forEach(brand => {
            this.state.currentBrandOptions.push({
                label: BrandOptionsMap[brand].name,
                value: brand
            })
        })

        this.fillColors()
        this.updateOptions()
        this.forceUpdate()
    }

    fillColors = () => {
        Object.entries(this.props.materials[this.props.selectedElaboration]).forEach(([brand, brandColors]) => {
            brandColors.forEach(colorKey => {
                const catalogColor = this.props.catalog[colorKey]
                if (catalogColor) {
                    this.state.currentColorOptions.push({
                        label: catalogColor.name + ' (' + brand + ')',
                        value: colorKey,
                        thumb: catalogColor.thumb,
                        brand: brand
                    })
                }

                this.state.currentColorOptions.sort((a, b) => a.label.localeCompare(b.label))
            })
        })
    }

    resetProducts = () => {
        this.setState({
            selectedBrand: null,
            selectedColor: null,
            selectedFormat: null,
            selectedThickness: null,
            selectedFinished: null,
            selectedAddon: null
        })

        for (let m in this.state.selectedFiles) delete this.state.selectedFiles[m]
        const fileInputs = window.top.document.querySelectorAll('.attach-file')
        if (fileInputs) fileInputs.forEach(i => (i.value = ''))
        const radio = window.top.document.querySelectorAll('input[type="radio"]')
        if (radio) radio.forEach(r => (r.checked = false))
        const text = window.top.document.querySelectorAll('input[type="text"]')
        if (text) text.forEach(r => (r.value = null))
        this.qtyToAdd = 1
    }

    updateOptions = () => {
        if (this.state.currentBrandOptions.length === 1 && this.state.currentBrandOptions[0].value != this.state.selectedBrand)
            this.handleSelectBrand(this.state.currentBrandOptions[0])
        if (this.state.currentColorOptions.length === 1 && this.state.currentColorOptions[0].value != this.state.selectedColor)
            this.handleSelectColor(this.state.currentColorOptions[0])
    }

    handleSelectBrand = brandOption => {
        const brand = brandOption ? brandOption.value : null
        const { materials, catalog, selectedElaboration } = this.props
        this.resetProducts()
        this.state.currentColorOptions.length = 0

        if (brand) {
            const materialColors =  materials[selectedElaboration][brand]

            materialColors.forEach(color => {
                if (catalog[color]) {
                    this.state.currentColorOptions.push({
                        label: catalog[color].name,
                        value: color,
                        thumb: catalog[color].thumb,
                        brand: brand
                    })
                }
            })
            this.state.currentColorOptions.sort((a, b) => a.label.localeCompare(b.label))
        } else{
            this.fillColors()
        }

        this.setState({ selectedBrand: brand }, this.updateOptions)
    }

    handleSelectColor = colorOption => {
        if (colorOption) {
            if (this.props.secondaryParams) this.props.secondaryParams.length = 0
            this.props.fetchSecondaryParams(
                this.props.selectedElaboration,
                colorOption.brand,
                colorOption.value,
                this.props.pricingGroup,
                false,
                () => {
                    this.setState({
                        selectedThickness: null,
                        selectedFinished: null,
                        selectedAddon: null,
                        width: null,
                        height: null,
                        selectedColor: colorOption && colorOption.value,
                        selectedBrand: colorOption.brand
                    })
                }
            )
        } else {
            this.setState({
                selectedThickness: null,
                selectedFinished: null,
                selectedAddon: null,
                width: null,
                height: null,
                selectedColor: null
            })
        }
    }

    handleSelectFinish = finishOptions => {
        const finish = finishOptions && finishOptions.value
        if (finish === this.state.selectedFinished) return
        this.setState({ selectedFinished: finish })
        if (!finish) {
            this.setState({ selectedThickness: null, selectedAddon: null })
        }
    }

    handleSelectThickness = thicknessOption => {
        const thickness = thicknessOption && thicknessOption.value
        if (thickness === this.state.selectedThickness) return
        this.setState({ selectedThickness: thickness })
    }

    handleSelectAddon = addonOption => {
        const addon = addonOption && addonOption.value
        if (addon === this.state.selectedAddon) return

        this.setState({ selectedAddon: addon })
    }

    disableSendButton = () => {
        return (
            !this.state.selectedBrand ||
            !this.state.selectedColor ||
            !this.state.selectedThickness ||
            !this.state.selectedAddon ||
            !this.state.selectedFinished
        )
    }

    onFileChange = async (event, type) => {
        const file = event.target.files[0]
        if (file) {
            const fileSizeInMB = file.size / BYTES_IN_MB
            if (fileSizeInMB > FILE_LIMIT) {
                this.setState({ showExceedFileSizeAlert: true })
                event.target.value = ''
            } else {
                this.setState({ quoteFile: file })
            }
        } else {
            this.setState({ quoteFile: null })
        }
    }

    submitData = () => {
        const formData = new FormData()
        formData.append('brand', this.state.selectedBrand)
        formData.append('colorId', this.state.selectedColor)
        formData.append('finish', this.state.selectedFinished)
        formData.append('thickness', this.state.selectedThickness)
        formData.append('addon', this.state.selectedAddon)
        formData.append('file', this.state.quoteFile)
        formData.append('comment', this.state.comments)

        this.props.askForBudget(formData, () => this.props.showToastNotification(this.props.intl.formatMessage({ id: 'success_request_quote' }), 'success'))
    }

    qtyToAdd = 1

    render() {
        const { selectedBrand, selectedColor, selectedThickness, currentBrandOptions, currentColorOptions } = this.state
        const { intl, selectedElaboration } = this.props

        if (!selectedElaboration) return null

        const finishesOptions = getFinishOptions(this.props, this.state)
        const thicknessOptions = getThicknessOptions(this.props, this.state)
        const addonsOptions = getAddonOptions(this.props, this.state)

        if (addonsOptions && addonsOptions.length === 1) this.handleSelectAddon(addonsOptions[0])
        if (thicknessOptions && thicknessOptions.length === 1) this.handleSelectThickness(thicknessOptions[0])
        if (thicknessOptions && finishesOptions.length === 1) this.handleSelectFinish(finishesOptions[0])

        return (
            <section className="cut_to_size">
                <div className="cut_to_size__go_back mt-4 mb-3">
                    <LocalizedLink routeId={'ROUTE_CUT_TO_SIZE'} header={true} queryString={''} params={{'outlet':''}}>
                        <GoBack
                            text={intl.formatMessage(Messages.BACK_SELECT_ELABORATED_TYPE)}
                            dataCY={'product_detail__go_back'}
                        />
                    </LocalizedLink>
                </div>

                <div className="container form">
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <div style={{ width: '100%' }}>
                                <ElaborationDetails
                                    id={selectedElaboration}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-5 cut_to_size__filters">
                            {/**************** MARCA **********************/}
                            {selectedElaboration && (
                                <div className="group__input">
                                    <label htmlFor="brand-select" className="form-label">
                                        <Translation id="brand" defaultMessage="Brand" />
                                    </label>
                                    <div className="contain__select--brand">
                                        <Select
                                            id="brand-select"
                                            placeholder={this.props.intl.formatMessage(Messages.CHOOSE_BRAND)}
                                            options={currentBrandOptions}
                                            value={selectedBrand}
                                            onChange={selectedOption => this.handleSelectBrand(selectedOption)}
                                            disabled={!currentBrandOptions || currentBrandOptions.length <= 1}
                                            className={`${!currentBrandOptions || currentBrandOptions.length <= 1 ? ' disabled' : ' '}`}
                                        />
                                    </div>
                                </div>
                            )}

                            {/**************** COLORES **********************/}
                            <div className="group__input">
                                <label htmlFor="color-select" className="form-label">
                                    <Translation id="color" defaultMessage="Color" />
                                </label>
                                <Select
                                    id="color-select"
                                    placeholder={this.props.intl.formatMessage(Messages.CHOOSE_COLOR)}
                                    options={currentColorOptions}
                                    value={selectedColor}
                                    onChange={selectedOption => this.handleSelectColor(selectedOption)}
                                    optionComponent={colorOption}
                                    valueComponent={colorValue}
                                    disabled={!currentColorOptions || currentColorOptions.length <= 1}
                                    className={`${!currentColorOptions || currentColorOptions.length <= 1 ? ' disabled' : ' '}`}
                                />
                            </div>

                            {/**************** ACABADOS **********************/}
                            <div className="group__input group__input--finish">
                                <label htmlFor="finish-select" className="form-label">
                                    <Translation id="finish" defaultMessage="Finish" />
                                </label>
                                <Select
                                    id="finish-select"
                                    placeholder={this.props.intl.formatMessage(Messages.CHOOSE_FINISH)}
                                    options={finishesOptions}
                                    value={this.state.selectedFinished}
                                    onChange={selectedOption => this.handleSelectFinish(selectedOption)}
                                    disabled={!finishesOptions || finishesOptions.length === 0 }
                                    className={`capitalize ${!finishesOptions || finishesOptions.length === 0 ? ' disabled' : ''}`}
                                />
                            </div>

                            {/**************** GROSOR **********************/}
                            <div className="group__input group__input--thickness">
                                <label htmlFor="thickness-select" className="form-label">
                                    <Translation id="thickness" defaultMessage="Thickness" />
                                </label>
                                <Select
                                    id="thickness-select"
                                    // clearable={false}
                                    placeholder={this.props.intl.formatMessage(Messages.CHOOSE_THICKNESS)}
                                    options={thicknessOptions}
                                    value={selectedThickness}
                                    onChange={selectedOption => this.handleSelectThickness(selectedOption)}
                                    disabled={!thicknessOptions || thicknessOptions.length <= 1}
                                    className={`${!thicknessOptions || thicknessOptions.length === 0 ? ' disabled' : ''}`}
                                />
                            </div>

                            {/**************** ADDONS **********************/}
                            <div className="group__input group__input--addon">
                                <label htmlFor="addon-select" className="form-label">
                                    <Translation id="addon" defaultMessage="Addons" />
                                </label>
                                <div data-qas="cts_form_default-addon-dropdown">
                                    <Select
                                        id="addon-select"
                                        placeholder={this.props.intl.formatMessage(Messages.CHOOSE_ADDON)}
                                        options={addonsOptions}
                                        value={this.state.selectedAddon}
                                        onChange={selectedOption => this.handleSelectAddon(selectedOption)}
                                        disabled={!addonsOptions || addonsOptions.length <= 1}
                                        className={`capitalize ${!addonsOptions || addonsOptions.length === 0 ? ' disabled' : ''}`}
                                        // clearable={addonsOptions && addonsOptions.length > 1}
                                    />
                                </div>
                            </div>

                            {/**************** ADJUNTAR DOCUMENTO **********************/}
                            <div className="colgroup__input">
                                <label htmlFor="attach_file" className="form-label">
                                    <Translation id="document" defaultMessage="Document" />
                                </label>
                                <div>
                                    <input
                                        id="attach_file"
                                        className="attach-file"
                                        type="file"
                                        onChange={e => this.onFileChange(e, DEFAULT_FILE_TYPE)}
                                        // accept="image/jpeg, image/png, application/pdf"
                                    />
                                </div>
                                <small>
                                    <Translation id="exceed_file_limit" defaultMessage={`The file has a limit of {fileLimit} MB`} values={{ fileLimit: FILE_LIMIT }} />
                                </small>
                            </div>

                            {/**************** COMENTARIOS **********************/}
                            <div className="group__input">
                                <label htmlFor="input-comments" className="form-label">
                                    <Translation id="comment" defaultMessage="Comments" />
                                </label>
                                <textarea
                                    className="form-control"
                                    id="input-comments"
                                    rows="3"
                                    maxLength="2499"
                                    onChange={e => {
                                        e && this.setState({ comments: e.target.value })
                                    }}
                                />
                            </div>

                            {/**************** SOLICITAR PRESUPUESTO **********************/}
                            <div className="mt-1">
                                <Button
                                    customClass="bt-dark gt"
                                    dataCY="product_detail__add"
                                    disabled={this.disableSendButton()}
                                    onClick={() => (this.submitData())}
                                    gtmLabel="ec_cam_submit"
                                >
                                    <Translation id="request_for_quote" defaultMessage="Request quotation" />
                                </Button>
                            </div>
                        </div>{' '}

                        {this.state.showExceedFileSizeAlert && (
                            <FloatingAlert
                                warningMessage={<Translation id="exceed_file_limit" defaultMessage={`The file has a limit of {fileLimit} MB`} values={{ fileLimit: FILE_LIMIT }} />}
                                onClose={() => {
                                    this.setState({ showExceedFileSizeAlert: false })
                                }}
                            />
                        )}
                    </div>
                </div>
            </section>
        )
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CutToSizeFormBudget)
)
