import React from 'react'
import ProductCard from './ProductCard'
import RectangleResultContainer from '../catalog/rectangleResult/RectangleResultContainer'
import CircleResultContainer from '../catalog/CircleResultContainer'
import Spinner from '../global/Spinner'
import SimilarProducts from './SimilarProducts'
import Error from '../alerts/Error'
import Translation from '../global/Translation'
import ProductCardV2 from "../product-detail/ProductCardV2";
import {productCardLogic} from "./ProductCardLogic";

const ProductCardContainer = (props) => {

    const logicProps = productCardLogic(props)

    const { product, addCartLine, isLogged, shippingMethod, crossSelling, error } = props
    const Result = isLogged ? RectangleResultContainer : CircleResultContainer
    const crossSellingView =
        product && product.type === 'TABLA' && crossSelling !== undefined && crossSelling.length > 0 ? (
            <div style={{ display: 'none' }}>
                <div className="last-colors show-phone-related-products">
                    <section id="section-catalog">
                        <h4 style={{textAlign: 'initial', marginTop: '1rem'}}>
                            <Translation id="related_products" defaultMessage="Related products" />
                        </h4>
                        <div className="filter-and-results-container">
                            <div className="results">
                                <div className="results-list" style={{justifyContent: 'center'}}>
                                    {!props.newVersion ? (
                                        <div className="row">
                                            {crossSelling.map((product, index) => (
                                                <div className="col-12 col-md" key={product.productId}>
                                                    <Result
                                                        product={product}
                                                        shippingMethod={shippingMethod}
                                                        intl={props.intl}
                                                        addCartLine={addCartLine}
                                                        list={'related_product'}
                                                        indexOfList={index}/>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            {crossSelling.map(product => (
                                                <Result
                                                    key={product.productId}
                                                    product={product}
                                                    shippingMethod={shippingMethod}
                                                    intl={props.intl}
                                                    addCartLine={addCartLine}
                                                    notDescriptionProduct={true}
                                                />
                                            ))}
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        ) : null
    let customId = ''
    // if(this.props.newVersion){
        customId = 'product-detail'
    // }
    return (
        <section id={customId}>
            {!product && error ? (
                <Error alert={props.intl.formatMessage({ id: error.data.err })} />
            ) : !product && !error ? (
                <Spinner isLoading={true} />
            ) : (
                <div>
                    {props.newVersion ? (
                        product && <ProductCardV2 {...logicProps} crossSellingView={crossSellingView} />
                    ) : (
                        product && <ProductCard {...logicProps} />
                    )}
                    <div className="container">
                        <div className="row">
                            <div className="col-12">{product && <SimilarProducts products={product.similarProducts} />}</div>
                            {!props.newVersion && (
                                <div className="col-12">{crossSellingView}</div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </section>
    )
}

export default ProductCardContainer
