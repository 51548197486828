import React from 'react'
import Translation from '../global/Translation'
import LocalizedLink from '../global/LocalizedLink'
import PPCC_V2 from '../../assets/img/banners-v2/PPCC_V2.png'
import CTS_V2 from '../../assets/img/banners-v2/CTS_V2.png'
import Banos_V2 from '../../assets/img/banners-v2/NewBanos_v2.png'
import OUTLET from '../../assets/img/banners-v2/Outlet_V2.png'
import TARIFICADOR from '../../assets/img/banners-v2/banner-tarificador.png'
import promoNaturalStoneCatalogV2 from '../../assets/img/banners-exclusive-promo-natural-stone/promo_natural_stone_catalog_v2.png'
import moment from 'moment/moment'
import { isEuropeOrIberia } from '../../store/centers/reducers'

export default class BannerResultV2 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        let banner = [
            [
                PPCC_V2,
                <Translation id="professional_products" defaultMessage="Professional products" />,
                <Translation id="banner_ppcc_result_v2" defaultMessage="Find here your silicones, adhesives, tapes and much more" />,
                <Translation id="view_section" defaultMessage="View secction" />,
                'cover',
                'center center',
                '27.43%',
                '?type[0]=PPCC',
                { outlet: '' },
                'ROUTE_CATALOG'
            ],
            [
                Banos_V2,
                <Translation id="standard_products" defaultMessage="Standard Products" />,
                <Translation id="banner_bath_subtitle" defaultMessage="Washbasins, shower trays and optima boards" />,
                <Translation id="view_section" defaultMessage="View secction" />,
                'cover',
                'center center',
                null,
                '?type[0]=TABLA&type[1]=CTS&subtype[0]=Optimma&subtype[1]=SOLERIA&type[2]=BAÑOS&subtype[2]=Washbasin&type[3]=BAÑOS&subtype[3]=Shower%20tray&?type[4]=FREGADERO',
                { outlet: '' },
                'ROUTE_CATALOG'
            ],
            [
                CTS_V2,
                <Translation id="CTS_V2_TXT" defaultMessage="Now your materials cut to size" />,
                <Translation id="CTS_V2_TXT_2" defaultMessage="Now your materials cut to size" />,
                <Translation id="view_section" defaultMessage="View secction" />,
                'cover',
                'center center',
                '-0.57%',
                '',
                { outlet: '' },
                'ROUTE_CUT_TO_SIZE'
            ],
            [
                OUTLET,
                <Translation id="cosentino_outlet" defaultMessage="Cosentino outlet" />,
                <Translation id="OUTLET_V2_TXT" defaultMessage="Offers at your nearest Center" />,
                <Translation id="view_section" defaultMessage="View secction" />,
                'cover',
                'center center',
                '-0.57%',
                '?type[0]=TABLA',
                { outlet: 'outlet' },
                'ROUTE_CATALOG'
            ],
            [
                TARIFICADOR,
                <Translation id="try_our_new_rater" defaultMessage="Try our new Rater" />,
                <Translation id="upload_your_rates" defaultMessage="Upload your rates and generate quotes in seconds" />,
                <Translation id="view_section" defaultMessage="View secction" />,
                'cover',
                'center center',
                '-0.57%',
                '',
                { outlet: '' },
                ''
            ]
        ]
        let type;
        if(this.props.typeClient === 1 && this.props.location.search !== "?type[0]=BAÑOS" && this.props.testAB === 'A'){
            type = 1
        }else if(this.props.typeClient === 1 && this.props.location.search !== "?type[0]=PPCC" && this.props.testAB === 'A'){
            type = 0
        }else if(this.props.typeClient === 1 && this.props.location.search !== "?type[0]=BAÑOS" && this.props.testAB === 'B'){
            type = 4
        }else if(this.props.typeClient === 3 && this.props.location.search !== "?type[0]=BAÑOS"){
            type = 1
        }else if(this.props.typeClient === 3 && this.props.location.search !== "?type[0]=PPCC"){
            type = 0
        }else if(this.props.typeClient === 2 && this.props.location.search !== "?type[0]=TABLA"){
            type = 2
        }else{
            type = 3
        }

        // if(this.props.type && this.props.type !== ''){
        //     type = this.props.type
        // }

        if(this.props.showBannerPromo) {
            banner[type][0] = promoNaturalStoneCatalogV2
            banner[type][1] = <Translation id="exclusive_promo_natural_stone" defaultMessage="Exclusive promo Natural Stone" />
            banner[type][2] = <Translation id="from_14_18_november" defaultMessage="From 14-18 November" />
            banner[type][3] = <Translation id="make_a_reservation" defaultMessage="Make a reservation" />
            banner[type][4] = 'auto'
            banner[type][9] = 'URL'
            banner[type][10] = 'https://forms.cosentino.com/natural-stone-week-eu/'
        }
        //console.log(type)

        return (        
                
                banner[type][9] !== 'URL' ? (
                    <LocalizedLink
                        routeId={banner[type][9]}
                        queryString={banner[type][7]}
                        params={banner[type][8]}
                        gtmLabel="ec_banner_list"
                    >                        
                        <div className={'box-parent-banner-result-v2'} style={{
                            backgroundImage: `url(${banner[type][0]})`,
                            backgroundSize: banner[type][4],
                            backgroundPosition: banner[type][5]
                        }}>
                            <div className="box-container" style={banner[type][6] ?
                                {background: `linear-gradient(179.95deg, #494E51 ${banner[type][6]}, rgba(58, 58, 58, 0) 99.94%)`}
                                : {}
                            }>
                                <div className={`box-title  ${banner[type][0] === Banos_V2 ? 'maxi' : ''}`}>{banner[type][1]}</div>
                                <div className="box-subtitle">{banner[type][2]}</div>
                                <div className="box-button-container" style={{
                                    maxHeight: type === 3 ? '290px' : type === 4 ? '197px' : '230px'
                                }}>
                                    <div className="v2 bt bt-inverted bt-size-large">{banner[type][3]}</div>
                                </div>
                            </div>
                        </div>
                    </LocalizedLink>
                ) : (
                    <a href={banner[type][10]} target='_blank' gtm-label="ec_banner_list">
                        <div className="box-parent-banner-result-v2" style={{
                            backgroundImage: `url(${banner[type][0]})`,
                            backgroundSize: banner[type][4],
                            backgroundPosition: banner[type][5]
                        }}>
                           
                            <div className="box-container" style={{
                                background: `linear-gradient(179.95deg, #494E51 ${banner[type][6]}, rgba(58, 58, 58, 0) 99.94%)`,
                            }}>
                                <div className="box-title">{banner[type][1]}</div>
                                <div className="box-subtitle">{banner[type][2]}</div>
                                <div className="box-button-container" style={{
                                    maxHeight: type === 3 ? '290px' : type === 4 ? '197px' : '230px'
                                }}>
                                    <div className="v2 bt bt-inverted bt-size-large">{banner[type][3]}</div>
                                </div>
                            </div>
                        </div>
                    </a>
                )   
            
            
        )
    }
}
