import { getLanguage } from './../store/ui/reducers';
import { getQuickGuides } from './../store/products/reducers';
import React from 'react'
import { addCartLine, addCartLineWithouthSeg } from '../store/cart/actions'
import { byProductId, getGallery, getError, getColorsMktoolsAttr, getBrandsMktoolsAttr } from '../store/products/reducers'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../store/products/actions'
import { getIsLogged, getIsAdmin, getUserType, getImpersonatedBy, canViewRockMasterPromotion, canViewEarthicPromotion, canViewHybriqPromotion, canViewSilestonePromotionXM } from '../store/login/reducers'
import {
    getShippingMethod,
    getSelectedCenter,
    canViewOutlet,
    productIsInPromo,
    getIsMkToolsCenter,
    getNeedsSegmentation,
    getIsIkeaCenter,
    getIsMktoolsUsaCenter,
    getPricesGroup,
    canBuyCatalog,
    canBuyMktools, isUsaOrCanada
} from '../store/centers/reducers'
import { goBack } from 'react-router-redux'
import { getCurrentCrossSelling, getCurrentAlternatives } from '../store/crossSelling/reducers'
import { getCurrentPromotionId, isProductIdInPromotion } from '../store/promotions/reducers'

import { injectIntl } from 'react-intl'
import { getProfileConfiguration } from '../store/profile-configuration/reducers'
import { getIsAdding, getQtyProductsCart } from '../store/cart/reducers'
import { fetchCrossSelling, fetchAlternatives } from '../store/crossSelling/actions'
import {
    canViewThresholdMax,
    isComingFromMall,
    isComingFromCampaign,
    showingSegmentationModal
} from '../store/ui/reducers'
import { dispatchPushURL, proccessInDetailScreen } from '../store/ui/actions'
import {postVisitedProduct} from "../store/products/actions";
import {getLastSelectedProduct} from "../store/selected-objects/reducers";
import {getAdaptedFormat} from "../helpers";
import {
    getLandingByCommonId,
    getMallProductsLots,
    getSelectedProduct,
    getSelectedProductId
} from "../store/landing/reducers";
import ProductCardContainer from "../components/product-detail/ProductCardContainer";
import {fetchProductsLandingsByCenters} from "../store/landing/actions";
import {setLastSelectedProduct} from "../store/selected-objects/actions";

const mapStateToProps = (state, { match }) => {
    const source = byProductId(state, match.params.id)
    const lastSelectedProduct = getLastSelectedProduct(state)

    return {
        product: source,
        lastSelectedProduct: lastSelectedProduct && lastSelectedProduct.productId == match.params.id ? lastSelectedProduct : null,
        productId: match.params.id,
        key: match.params.id,
        isLogged: getIsLogged(state),
        isAdmin: getIsAdmin(state),
        shippingMethod: getShippingMethod(state),
        selectedCenter: getSelectedCenter(state),
        alternatives: getCurrentAlternatives(state),
        crossSelling: getCurrentCrossSelling(state),
        outlet: match.params.outlet,
        canBuy: (canBuyCatalog(state) && source && !source.isMktool) || (canBuyMktools(state) && source && source.isMktool),
        userPreferences: getProfileConfiguration(state),
        userType: getUserType(state),
        impersonatedBy: getImpersonatedBy(state),
        canViewOutlet: canViewOutlet(state),
        isMkToolsCenter: getIsMkToolsCenter(state),
        productIsInPromo: productIsInPromo(state, source),
        gallery: getGallery(state),
        qtyProductCart: getQtyProductsCart(state),
        centerId: getSelectedCenter(state),
        error: getError(state),
        priceGroup: getPricesGroup(state),
        needsSegmentation: getNeedsSegmentation(state),
        isIkeaCenter: getIsIkeaCenter(state),
        isMktoolsUsaCenter: getIsMktoolsUsaCenter(state),
        colorsMktoolsAttr: getColorsMktoolsAttr(state),
        brandsMktoolsAttr: getBrandsMktoolsAttr(state),
        canViewThresholdMax: canViewThresholdMax(state),
        isAdding: getIsAdding(state),
        isComingFromMall: isComingFromMall(state),
        landing: getLandingByCommonId(state, lastSelectedProduct),
        isComingFromCampaign: isComingFromCampaign(state),
        currentPromotionId: getCurrentPromotionId(state),
        isProductIdInPromo: isProductIdInPromotion(state, match.params.id),
        newVersion: state.login.newVersion,
        isShowingSegmentationModal: showingSegmentationModal(state),
        isUsaOrCanada: isUsaOrCanada(state),
        isImpersonated: !!getImpersonatedBy(state),
        adaptedFormat: (format, params) => getAdaptedFormat(format, params, state),
        mallProductsLots: getMallProductsLots(state),
        mallProducts: state.landing.productsLanding,
        mallSelectedProductId: getSelectedProductId(state),
        mallSelectedProduct: getSelectedProduct(state),
        quickGuides: getQuickGuides(state),
        locale:getLanguage(state),
        canViewRockMasterPromotion: canViewRockMasterPromotion(state),
        canViewEarthicPromotion: canViewEarthicPromotion(state),
        canViewHybriqPromotion: canViewHybriqPromotion(state),
        canViewSilestonePromotionXM: canViewSilestonePromotionXM(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addCartLine: (product, qty, extra, callbackCenter, dektonGripRequested, supplierCenterId) => {
            dispatch(addCartLine(product, qty, extra, callbackCenter, dektonGripRequested, supplierCenterId))
        },
        addCartLineWithouthSeg: (product, qty, extra, callbackCenter, dektonGripRequested) => {
            dispatch(addCartLineWithouthSeg(product, qty, extra, callbackCenter, dektonGripRequested))
        },
        fetchProduct: productId => {
            return dispatch(actions.fetchProduct(productId))
        },
        fetchSimilarProduct: (productId, colorId) => {
            return dispatch(actions.fetchSimilarProduct(productId, colorId))
        },
        fetchCrossSelling: productId => {
            return dispatch(fetchCrossSelling(productId))
        },
        fetchAlternatives: productId => {
            dispatch(fetchAlternatives(productId))
        },
        fetchProductsFavourite: productId => {
            dispatch(actions.fetchProductsFavourite(productId))
        },
        goBack: () => {
            dispatch(goBack())
        },
        goCatalog: (intl, outlet) => {
            dispatch(dispatchPushURL(intl.formatMessage({ id: 'ROUTE_CATALOG' }).replace(':outlet?', outlet ? 'outlet' : '')))
        },
        onLoadProductDetail: product => {
            dispatch({ type: 'PRODUCT_DETAIL_VISIT', product })
        },
        fetchColorsMktoolsAttr: () => dispatch(actions.fetchColorsMktoolsAttr()),
        setInProductDetailScreen: inDetailScreen => {
            dispatch(proccessInDetailScreen(inDetailScreen))
        },
        postVisited: (productId, outlet) => {
            return dispatch(postVisitedProduct(productId, outlet))
        },
        fetchProductsLandingsByCenters: (landingId, centers, products, portfolioMode) => {
            return dispatch(fetchProductsLandingsByCenters(landingId, centers, products, portfolioMode))
        },
        setLastSelectedProduct: product => dispatch(setLastSelectedProduct(product))
    }
}

class ProductDetailScreen extends React.Component {

    componentDidMount() {
        if (!this.props.product && !this.props.lastSelectedProduct) {
            // console.log('FETCHING')
            this.props.fetchProduct(this.props.productId)
        }
    }

    render() {
        return this.props.product || this.props.lastSelectedProduct ? <ProductCardContainer {...this.props}/> : ''
    }
}

ProductDetailScreen = injectIntl(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(ProductDetailScreen)
    )
)

export default ProductDetailScreen
