import React, { useState } from 'react'

// Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Thumbs, Autoplay } from 'swiper'

// Swiper styles
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'

import { Swiper, SwiperSlide } from 'swiper/react'

import SliderItem from './SliderItem'
import cameraOff from '../../../assets/img/icons/camera-off.svg'
import Translation from '../../global/Translation'
import DisplayOutletIndicator from "../../catalog/rectangleResult/displays/DisplayOutletIndicator";

const MallSlider = ({
    onZoomClick,
    newVersion,
    products,
    setProductSelectedToAdd,
    sapId
}) => {
    // install Swiper modules
    SwiperCore.use([Navigation, Pagination, Thumbs, Autoplay])
    const [thumbsSwiper, setThumbsSwiper] = useState(null)
    
    return (
        <React.Fragment>
            <div className="container mt-3">
                <h2 className='carrusel-title-mall'>
                    <Translation id={"carousel_view"} defaultMessage={"Carousel View"}/>
                </h2>
            </div>
            <div className={`mall__slider container ${newVersion ? "v2" : ""}`}>
                <Swiper
                    className="mall__slider__thumbs"
                    slidesPerView={1.5}
                    threshold={10}
                    breakpoints={{
                        768: {
                            slidesPerView: 3
                        },
                        991: {
                            slidesPerView: 4
                        },
                        1200: {
                            slidesPerView: 5
                        }
                    }}
                    centerInsufficientSlides={true}
                    spaceBetween={20}
                    pagination={{clickable:true}}
                    navigation={true}
                    modules={[Pagination]}
                    watchSlidesVisibility
                    watchSlidesProgress
                    onSwiper={setThumbsSwiper} 
                >
                    {products.map((product, i) => {

                        const showValidImage = () => {
                            if (product.detailImage && product.detailImage !== 'null' && product.detailImage !== undefined && product.detailImage !== 'undefined' ) return product.detailImage
                            if (product.imgLarge && (product.imgLarge !== 'null' || product.imgLarge != 'null')) return product.imgLarge
                            return cameraOff
                        }

                        const placeHolderClassName = () => {
                            if((!product.detailImage || product.detailImage === 'null') || (!product.imgLarge || product.imgLarge === 'null' || product.imgLarge == 'null')) return '--placeholder'
                            return ''
                        }

                        return (
                            <SwiperSlide
                                key={i}
                                className={"mall__slider__thumbs__slide " + placeHolderClassName() + " sliderV2"}
                            >
                                <div style={{
                                    backgroundImage: `url(${showValidImage()})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    height: '160px'
                                }}>
                                    {product.realStock === 0 && (
                                        <div className="sold-out-indicator-wrapper">
                                            <span className="sold-out-indicator">
                                                <Translation id="sold_out" defaultMessage="Sold out" />
                                            </span>
                                        </div>
                                    )}
                                    {product.outlet && product.realStock > 0 && (
                                        <DisplayOutletIndicator outletQuality={product.outletQuality} />
                                    )}
                                </div>
                                <span className='product-name'>{product.name}</span>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>

                <Swiper
                    className="mall__slider__main"
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{ delay: 7000, disableOnInteraction: true }}
                    threshold={10}
                    thumbs={{ swiper: thumbsSwiper }}
                >
                    {products.map((product, i) => (
                        <SwiperSlide key={i} className="mall__slider__main__slide" style={{ backgroundImage: `${!newVersion ? `url(${product.imgLarge})` : ""}` }}>
                            <SliderItem
                                product={product}
                                newVersion={newVersion}
                                onZoomClick={()=>onZoomClick(product)}
                                setProductSelectedToAdd={setProductSelectedToAdd}
                                sapLandingId={sapId}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </React.Fragment>
    )
}

export default MallSlider
