import React from 'react'
import LocalizedLink from '../global/LocalizedLink'
import Translation from '../global/Translation'
import PEDIDOS_RECURRENTES_IMAGE from '../../assets/img/pedidos_recurrentes.png'

export default class BannerRecurringOrder extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <LocalizedLink
                routeId="ROUTE_CATALOG"
                queryString="?type[0]=TABLA"
                params={{ outlet: '' }}
            >
                <div className="box-parent-banner-recurring-order" style={{
                    backgroundImage: `url(${PEDIDOS_RECURRENTES_IMAGE})`,
                }}>
                    <div className="box-container">
                        <div className="box-title">
                            <Translation id="new_functionality" defaultMessage="New functionality" />
                            {' '}
                            <span>
                                <Translation id="repurchase" defaultMessage="Repurchase" />
                            </span>
                        </div>
                        <div className="box-subtitle">
                            <Translation id="use_the_new_buy_back_functionality_to_reorder_or_buy_materials_faster_and_more_efficiently" defaultMessage="Use the new Buy Back functionality to reorder or buy materials faster and more efficiently" />
                        </div>
                        <div className="box-button-container">
                            <div className="box-button">
                                <Translation id="repurchase" defaultMessage="Repurchase" />
                            </div>
                        </div>
                    </div>
                </div>
            </LocalizedLink>
        )
    }
}
