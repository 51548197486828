import React from 'react'
import { injectIntl } from 'react-intl'
import Translation from '../../global/Translation'
import Modal from '../../global/Modal'
import Button from '../../global/Button'
import SimpleModal from '../../global/SimpleModal'
import MaterialsListContainer from './MaterialsListContainer'
import Alerts from '../../alerts/Alerts'
import { Pagination, SearchkitManager, SearchkitProvider } from 'searchkit'
import { getIsAdmin, getIsLogged, getSpecialNames, getToken, getUserType } from '../../../store/login/reducers'
import { getLanguage } from '../../../store/ui/reducers'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import ProductDisplayName from '../../global/ProductDisplayName'
import DisplayBrand from '../../catalog/rectangleResult/displays/DisplayBrand'
import DisplayThickness from '../../catalog/rectangleResult/displays/DisplayThickness'
import DisplayOutletIndicator from '../../catalog/rectangleResult/displays/DisplayOutletIndicator'

import DisplayDetail from '../../catalog/rectangleResult/displays/DisplayDetail'
import { getImageBackground } from '../../../store/products/actions'
import { getAllProductOfTheLastQueryMultiCenterByUser } from '../../../store/promotions/actions'
import Spinner from '../../global/Spinner'
import { getIsLoading } from '../../../store/promotions/reducers'
import {typeMap} from "../../global/TypeMaps";

const getSk = (token, centers, locale, outlet, productId) => {
    // let lang = locale === 'fr-CA' || locale === 'en-CA' ? 'en-US' : locale

    // const multiCenterSearch = '/catalog/search-multi-center/7704,7701,7713'
    const multiCenterSearch = '/catalog/search-multi-center/' + centers.map(center => center.value).toString()
    const uri = productId ? multiCenterSearch + '?productId=' + productId : multiCenterSearch
    const httpHeaders = token ? { Authorization: 'Bearer ' + token } : {}

    let sk = new SearchkitManager(process.env.REACT_APP_API_HOST + uri, {
        searchUrlPath: '',
        timeout: 60000,
        httpHeaders
    })
    // Traducciones de cadenas de SearchKit
    sk.translateFunction = key => {
        let translations = {
            'pagination.previous': <Translation id="previous" defaultMessage="Previous" />,
            'pagination.next': <Translation id="next" defaultMessage="Next" />,
            'facets.view_more': <Translation id="view_more" defaultMessage="View more" />,
            'facets.view_less': <Translation id="view_less" defaultMessage="View less" />,
            'facets.view_all': <Translation id="view_all" defaultMessage="View all" />,
            'reset.clear_all': <Translation id="clear_all" defaultMessage="Clear all" />
        }
        return translations[key]
    }
    return sk
}

const materials_limit = 50

class ChooseMaterials extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedMaterials: [],
            selectedMaterialsData: [],
            showModalToSelect: false,
            showModalAddProduct: false,
            lastModalProduct: null,
            lastModalProducts: [],
            sk: null,
            search: '',
            //Unsaved changes
            hasUnsavedChanges: {
                promotionMaterialsDirty: false
            }
        }
    }

    setSk = () => {
        this.setState({
            sk: getSk(this.props.token, this.props.centers, this.props.intl.locale, this.props.outlet, null)
        })
    }
    setSkStock = (productId = null) => {
        this.setState({
            skStock: getSk(this.props.token, this.props.centers, this.props.intl.locale, this.props.outlet, productId)
        })
    }

    componentDidMount = () => {
        this.props.handleReference(this)
        this.setSk() // en el centro 1060 se pregunta locationf
        if (this.props.materialsSelected && this.props.materialsSelectedData) {
            this.setState({
                selectedMaterials: this.props.materialsSelected,
                selectedMaterialsData: this.props.materialsSelectedData
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            //TODO solo hace la llamada cuando cambiar la url
            this.state.sk && this.state.sk.runInitialSearch() //TODO :identificar motivo y si se puede borrar, duplica llamada a elastic see on network
        }
    }

    handleSubmit = e => {
        if (e) e.preventDefault()
        //No more unsaved changes after send the form for step three
        this.setState({ hasUnsavedChanges: {
            ...this.state.hasUnsavedChanges,
            promotionMaterialsDirty: false
        }}, () => {
            this.props.sendMaterialsSelected(this.state.selectedMaterials, this.state.selectedMaterialsData)
            //Update global state after form submit
            this.props.setUnsavedChangesStepFour(this.state.hasUnsavedChanges)
            //Make sure the change step is the last function

            //Only if event exists we use this change step. If not we use the one inside StepsBar component
            if (e) {
                this.props.changeStep(this.props.step + 1)
            }
        })
    }

    tryAddProduct = (prod, checkOutlet = true, isOutlet = prod.outlet, callback) => {
        if ((checkOutlet && prod.outlet && prod.stock > 0 && prod.stock_outlet > 0) || (checkOutlet && prod.outlet && prod.stock > 0 && !prod.stock_outlet > 0)) {
            this.setState({ showModalAddProduct: true, lastModalProduct: prod, lastModalProducts: this.state.lastModalProducts.concat(prod) })
        } else {
            const prd = [...this.state.selectedMaterialsData]
            const bFound = prd.find(a => {
                return a.productId === prod.productId && a.isSellingOutlet == isOutlet
            })

            if (!bFound) {
                this.selectMaterial(prod, isOutlet, () => {
                    if (typeof callback === 'function') callback()
                })
            } else {
                this.removeMaterial(prod, isOutlet, () => {
                    if (typeof callback === 'function') callback()
                })
            }
        }
    }

    selectMaterial = (product, isOutlet, callback) => {
        this.setState(
            {
                selectedMaterials: this.state.selectedMaterials.concat(product.productId),
                selectedMaterialsData: [...this.state.selectedMaterialsData, { ...product, isSellingOutlet: isOutlet }]
            },
            () => {
                if (typeof callback === 'function') callback()
                this.handleMaterialsUnsavedChanges()
            }
        )
    }
    selectBulkMaterials = products => {
        // console.log('selecting on bulk', products, products.length)
        if (products && products.length) {
            products.forEach(product => {
                // if (!this.state.selectedMaterials.includes(product.productId) && product.centersCount === this.props.centers.length) {
                if (!this.state.selectedMaterials.includes(product.productId) && !product.cantBeSelected) {
                    this.tryAddProduct(product)
                }
            })
        }
    }

    removeMaterial = (product, isOutlet, callback) => {
        this.setState(
            {
                selectedMaterials: this.state.selectedMaterials.filter(material => material !== product.productId),
                selectedMaterialsData: this.state.selectedMaterialsData.filter(material => material.productId !== product.productId)
            },
            () => {
                if (typeof callback === 'function') callback()
                this.handleMaterialsUnsavedChanges()
            }
        )
    }
    removeBulkMaterials = products => {
        if (products && products.length) {
            products.forEach(product => {
                // if (this.state.selectedMaterials.includes(product.productId) && product.centersCount === this.props.centers.length) {
                    if (this.state.selectedMaterials.includes(product.productId) && !product.cantBeSelected) {
                    this.setState({
                        selectedMaterials: this.state.selectedMaterials.filter(material => material !== product.productId),
                        selectedMaterialsData: this.state.selectedMaterialsData.filter(material => material.productId !== product.productId)
                    })
                }
            })
        }
    }
    getNameText = product => {
        const { specialNames } = this.props
        let LANG_TO_INDEX = {
            da: 'EN',
            de: 'DE',
            'en-US': 'Z2',
            en: 'EN',
            es: 'ES',
            fi: 'EN',
            'fr-CA': 'Z2',
            'en-CA': 'Z2',
            fr: 'FR',
            it: 'IT',
            nl: 'NL',
            no: 'EN',
            pt: 'PT',
            pl: 'EN',
            sv: 'SV',
            tr: 'TR'
        }
        let locale = LANG_TO_INDEX[this.props.intl.locale]

        let showName

        if (specialNames && specialNames[product.productId]) {
            showName = specialNames[product.productId]
        } else if (product.translations && product.translations[locale]) {
            showName = product.translations[locale].displayName.toLowerCase()
        } else {
            showName = product.displayName.toLowerCase()
        }
        return showName
    }

    selectAllMaterials = () => {
        this.props.getAllProductOfTheLastQueryMultiCenterByUser().then(products => {
            // console.log('SELECT ALL', products)
            this.selectBulkMaterials(products)
        })
    }

    removeAllMaterials = () => {
        this.props.getAllProductOfTheLastQueryMultiCenterByUser().then(products => {
            // console.log('REMOVE ALL', products)
            this.removeBulkMaterials(products)
        })
    }

    /**
     * Handle the state value materials unsaved changes.
     */
    handleMaterialsUnsavedChanges = () => {
        const stateMaterials = this.state.selectedMaterials
        const propsMaterials = this.props.materialsSelected

        if ((propsMaterials && JSON.stringify(propsMaterials).trim()) !== (stateMaterials && JSON.stringify(stateMaterials).trim())) {
            this.setState({hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionMaterialsDirty: true}}, () => {
                //Update global state
                this.props.setUnsavedChangesStepFour(this.state.hasUnsavedChanges)
            })
        } else if ((propsMaterials && JSON.stringify(propsMaterials).trim()) === (stateMaterials && JSON.stringify(stateMaterials).trim())) {
            this.setState({hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionMaterialsDirty: false}}, () => {
                //Update global state
                this.props.setUnsavedChangesStepFour(this.state.hasUnsavedChanges)
            })
        }
    }

    render() {

        let selectedMaterialsFiltered = this.state.selectedMaterialsData
        if (this.state.search) {
            selectedMaterialsFiltered = this.state.selectedMaterialsData.filter(product => {

                let indRotationLabel
                if (product.translations) {
                    let lang = this.props.language.toUpperCase()
                    if (!(lang in product.translations)) lang = 'Z2'
                    if (lang in product.translations) {
                        indRotationLabel = product.translations[lang].indRotation && product.translations[lang].indRotation.toLowerCase()
                    }
                }


                return (
                    this.getNameText(product)
                        .toLowerCase()
                        .includes(this.state.search.toLowerCase()) ||
                    product.productId.toLowerCase().includes(this.state.search.toLowerCase()) ||
                    product.brand.toLowerCase().includes(this.state.search.toLowerCase()) ||
                    this.props.intl
                        .formatMessage({ id: product.finish })
                        .toLowerCase()
                        .includes(this.state.search.toLowerCase()) ||
                    this.props.intl
                        .formatMessage({ id: product.type })
                        .toLowerCase()
                        .includes(this.state.search.toLowerCase()) ||
                    this.props.intl
                        .formatMessage({ id: product.subtypeName })
                        .toLowerCase()
                        .includes(this.state.search.toLowerCase()) ||
                    product.thickness.toLowerCase().includes(this.state.search.toLowerCase()) ||
                    (indRotationLabel && indRotationLabel.includes(this.state.search.toLowerCase()))

                )
            })
        }
        if (!this.props.market || !this.props.countries || !this.props.centers) {
            return <Alerts alert={'NEED_TO_COMPLETE_STEP_2'} status="warning" />
        }

        return (
            <div>
                <div className="row">
                    <div className="col-12 col-lg-10">
                        <h3 style={{ fontWeight: '400' }}>
                            <span style={{ fontWeight: '300' }}>
                                <Translation id="step" defaultMessage={'Step'} /> {this.props.step}
                            </span>{' '}
                            <Translation id="promotion_line_materials" defaultMessage="Materials" />
                        </h3>
                        <p>
                            <Translation id="select_the_materials_to_apply_your_campaign" defaultMessage="Select the materials to apply your campaign" />
                        </p>
                    </div>
                    <div className="col-12 col-lg-2">
                        <Button
                            gentle
                            onClick={() => {
                                this.setState({ showModalToSelect: true })
                                if (!window.location.href.includes('?type[0]=TABLA')) {
                                    const filterBySlabs = window.location.href + '?type[0]=TABLA'
                                    window.history.replaceState(null, '', filterBySlabs)
                                }
                            }}
                        >
                            <Translation id="add" defaultMessage="Add" />
                        </Button>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="search-bar">
                                <input
                                    type="text"
                                    data-qa="query"
                                    className="search-box form-control"
                                    placeholder={this.props.intl.formatMessage({ id: 'search' })}
                                    data-cy="Impersonate_modal__search_box"
                                    value={this.state.search}
                                    onChange={e =>
                                        this.setState({
                                            search: e.target.value
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <ReactTable
                                className={'light'}
                                data={selectedMaterialsFiltered}
                                columns={[
                                    {
                                        id: 'image',
                                        Header: <Translation id="image" defaultMessage="Image" />,
                                        accessor: 'image',
                                        Cell: ({ original }) => {
                                            const image = this.props.getImageBackground(original)
                                            return (
                                                <div className="list-result" data-cy="catalog__product_sample_result" type={original.type}>
                                                    <DisplayOutletIndicator outletQuality={original.isSellingOutlet && original.outletQuality} style={{ top: 'initial' }} />
                                                    <div className="product-image-container">
                                                        {
                                                            image ?
                                                                (<img style={{ maxWidth: '100%'}} src={image}/>)
                                                                :
                                                                (<img style={{ maxWidth: '100%', height: '60px'}}/>)
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        },
                                        filterable: false
                                    },
                                    {
                                        id: 'name',
                                        Header: <Translation id="name" defaultMessage="Name" />,
                                        accessor: 'name',
                                        Cell: ({ original }) => {
                                            return (
                                                <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                                    <ProductDisplayName product={original} />
                                                    <DisplayBrand brand={original.brand} />
                                                    <span style={{ fontSize: '0.8em' }}># {original.productId}</span>
                                                </div>
                                            )
                                        },
                                        filterMethod: (filter, row) => {
                                            return (
                                                this.getNameText(row._original)
                                                    .toLowerCase()
                                                    .includes(filter.value.toLowerCase()) ||
                                                row._original.productId.toLowerCase().includes(filter.value.toLowerCase()) ||
                                                row._original.brand.toLowerCase().includes(filter.value.toLowerCase())
                                            )
                                        }
                                    },
                                    {
                                        id: 'thickness',
                                        Header: <Translation id="thickness" defaultMessage="Thickness" />,
                                        Cell: ({ original }) => {
                                            return (
                                                <div className="d-flex justify-content-center align-items-center h-100">
                                                    <DisplayThickness thickness={original.thickness} />
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        id: 'finish',
                                        Header: <Translation id="finish" defaultMessage="Finish" />,
                                        accessor: 'finish',
                                        Cell: ({ original }) => {
                                            return (
                                                <div className="d-flex justify-content-center align-items-center h-100">
                                                    <DisplayDetail class="finish" value={original.finish} />
                                                </div>
                                            )
                                        },
                                        filterMethod: (filter, row) => {
                                            return this.props.intl
                                                .formatMessage({ id: row._original.finish })
                                                .toLowerCase()
                                                .includes(filter.value.toLowerCase())
                                        }
                                    },
                                    {
                                        id: 'type',
                                        Header: <Translation id="type" defaultMessage="Type" />,
                                        accessor: 'type',
                                        Cell: ({ original }) => {
                                            return (
                                                <div className="d-flex justify-content-center align-items-center h-100 text-wrap">
                                                    {typeMap[original.type] ? typeMap[original.type] : <DisplayDetail class="finish" value={original.type} />}
                                                </div>
                                            )
                                        },
                                        filterMethod: (filter, row) => {
                                            return this.props.intl
                                                .formatMessage({ id: row._original.type })
                                                .toLowerCase()
                                                .includes(filter.value.toLowerCase())
                                        }
                                    },
                                    {
                                        id: 'subtypeName',
                                        accessor: 'subtypeName',
                                        Header: <Translation id="subtypeName" defaultMessage="Subtype" />,
                                        Cell: ({ original }) => {
                                            return (
                                                <div className="d-flex justify-content-center align-items-center h-100 text-wrap">
                                                    <DisplayDetail class="finish" value={original.subtypeName} />
                                                </div>
                                            )
                                        },
                                        filterMethod: (filter, row) => {
                                            return this.props.intl
                                                .formatMessage({ id: row._original.subtypeName })
                                                .toLowerCase()
                                                .includes(filter.value.toLowerCase())
                                        }
                                    },
                                    {
                                        id: 'stock',
                                        Header: <Translation id="stock" defaultMessage="Stock" />,
                                        Cell: ({ original }) => {
                                            return (
                                                <Button
                                                    size="small"
                                                    customClass="bt-add-cart"
                                                    onClick={() => {
                                                        this.setSkStock(original.productId)
                                                        this.setState({
                                                            showModalStock: true,
                                                            productIdToShowStock: original.productId
                                                        })
                                                    }}
                                                >
                                                    <Translation id="show_stock" defaultMessage="Show stock" />
                                                </Button>
                                            )
                                        },
                                        filterable: false
                                    },
                                    {
                                        id: 'rotation',
                                        accessor: 'indRotation',
                                        Header: <Translation id="rotation" defaultMessage="Rotation" />,
                                        Cell: ({ original }) => {
                                            let label = ''

                                            if (original.indRotation) {
                                                let lang = this.props.language.toUpperCase()
                                                if (!(lang in original.translations)) lang = 'Z2'
                                                if (lang in original.translations && original.translations[lang].indRotation) {
                                                    label = `(${original.indRotation}) ${original.translations[lang].indRotation}`
                                                }
                                            }

                                            return (
                                                <div className="d-flex h-100 text-wrap">
                                                    <DisplayDetail class="finish" value={label} />
                                                </div>
                                            )
                                        },
                                        // filterMethod: (filter, row) => {
                                        //     const lang = this.props.language ? this.props.language.toUpperCase() : 'Z2'
                                        //     const indRotationLabel = row._original.translations
                                        //         && row._original.translations[lang]
                                        //         && row._original.translations[lang].indRotation
                                        //
                                        //     // return indRotationLabel && indRotationLabel
                                        //     return 'alta rota'
                                        //         .toLowerCase()
                                        //         .includes(filter.value.toLowerCase())
                                        // }
                                    },
                                    {
                                        id: 'actions',
                                        Header: <Translation id="actions" defaultMessage="Actions" />,
                                        Cell: ({ original }) => {
                                            return (
                                                <div className="d-flex justify-content-center align-items-center h-100">
                                                    <div className="tooltip">
                                                        <span className="bt-delete" onClick={() => this.removeMaterial(original)} style={{ cursor: 'pointer' }}>
                                                            <i className="fal fa-times" />
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        },
                                        filterable: false
                                    }
                                ]}
                                defaultPageSize={4}
                                pageSizeOptions={[4, 8, 10, 25, 50, 100]}
                                previousText={<Translation id="previous" defaultMessage="Previous" />}
                                nextText={<Translation id="next" defaultMessage="Next" />}
                                noDataText={
                                    <div>
                                        <Translation id="no_data" defaultMessage="No data available" />
                                        <Button
                                            gentle
                                            onClick={() => {
                                                this.setState({ showModalToSelect: true })
                                            }}
                                        >
                                            <Translation id="add" defaultMessage="Add" />
                                        </Button>
                                    </div>
                                }
                                pageText={<Translation id="page" defaultMessage="Page" />}
                                ofText={<Translation id="of" defaultMessage="Of" />}
                            />
                            <form id="form_current_step" onSubmit={e => this.handleSubmit(e)} />
                        </div>
                    </div>
                    {this.state.lastModalProducts.length > 0 && (
                        <SimpleModal
                            customClass="landings__modal landings__modal--add-product"
                            title={''}
                            onClose={() =>
                                this.setState({
                                    // showModalAddProduct: false,
                                    lastModalProduct: null,
                                    lastModalProducts: this.state.lastModalProducts.filter(item => item.productId !== this.state.lastModalProducts[0].productId)
                                })
                            }
                            size="large"
                            footer={null}
                        >
                            <div className="results">
                                <div className="results-list">
                                    <div className="list-result" data-cy="catalog__product_sample_result" type={this.state.lastModalProducts[0].type}>
                                        <div className="product-image-container">
                                            <img style={{ maxWidth: '100%' }} src={this.props.getImageBackground(this.state.lastModalProducts[0])} alt={'name'} />
                                        </div>
                                        <ProductDisplayName product={this.state.lastModalProducts[0]} />
                                        <DisplayBrand brand={this.state.lastModalProducts[0].brand} />
                                        <span style={{ fontSize: '0.8em' }}># {this.state.lastModalProducts[0].productId}</span>
                                        <div className="d-flex justify-content-center align-items-center h-100">
                                            <DisplayThickness thickness={this.state.lastModalProducts[0].thickness} />
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center h-100">
                                            <DisplayDetail class="finish" value={this.state.lastModalProducts[0].finish} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p>
                                <Translation
                                    id="product_ambiguous_stock"
                                    defaultMessage="The product you are trying to add has normal stock and outlet stock available, which one do you want to use?"
                                />
                            </p>

                            <div className="btn-row">
                                <Button
                                    gentle
                                    size="medium"
                                    onClick={() => {
                                        this.tryAddProduct(this.state.lastModalProducts[0], false, false, () =>
                                            this.setState({
                                                // showModalAddProduct: false,
                                                lastModalProduct: null,
                                                lastModalProducts: this.state.lastModalProducts.filter(item => item.productId !== this.state.lastModalProducts[0].productId)
                                            })
                                        )
                                    }}
                                >
                                    <Translation id="normal" defaultMessage="Normal" />
                                </Button>
                                <Button
                                    gentle
                                    size="medium"
                                    onClick={() => {
                                        this.tryAddProduct(this.state.lastModalProducts[0], false, true, () =>
                                            this.setState({
                                                // showModalAddProduct: false,
                                                lastModalProduct: null,
                                                lastModalProducts: this.state.lastModalProducts.filter(item => item.productId !== this.state.lastModalProducts[0].productId)
                                            })
                                        )
                                    }}
                                >
                                    <Translation id="outlet" defaultMessage="Outlet" />
                                </Button>
                                <Button
                                    gentle
                                    size="medium"
                                    onClick={() => {
                                        this.tryAddProduct(this.state.lastModalProducts[0], false, false, () => {
                                            this.tryAddProduct(this.state.lastModalProducts[0], false, true, () =>
                                                this.setState({
                                                    // showModalAddProduct: false,
                                                    lastModalProduct: null,
                                                    lastModalProducts: this.state.lastModalProducts.filter(item => item.productId !== this.state.lastModalProducts[0].productId)
                                                })
                                            )
                                        })
                                    }}
                                >
                                    <Translation id="both" defaultMessage="Both" />
                                </Button>
                            </div>
                        </SimpleModal>
                    )}
                    {this.state.showModalToSelect && !this.state.lastModalProducts.length && (
                        <SearchkitProvider searchkit={this.state.sk}>
                            <Modal
                                title={
                                    <div>                                        
                                        <Translation id="choose_materials" defaultMessage="Choose materials" />
                                        <div style={{fontSize:"14px",fontFamily:'Roboto', fontWeight:'400'}}>
                                            <span style={{backgroundColor:"InfoBackground",padding:"4px"}}>
                                                <i className="far fa-exclamation-triangle"></i>&nbsp;
                                                <Translation id="materials_number_limit" defaultMessage="Maximum number of materials allowed"/>: <b>{materials_limit}</b>
                                            </span>
                                            <span style={{marginLeft:"8px"}}>
                                                <Translation id="selected_materials_count" defaultMessage="Selected materials count"/>: <b>{this.state.selectedMaterials.length}</b>
                                            </span>
                                        </div>
                                        
                                    </div>                                
                                }
                                onClose={() => this.setState({ showModalToSelect: false })}
                                size="xl"
                                customClass="promotions-choose-materials-modal"
                                footer={
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-lg-3" />
                                            <div className="col-12 col-lg-6 results">
                                                <div className="results-pagination" data-cy="catalog__pagination_catalog" onClick={() => window.scrollTo(0, 160)}>
                                                    <Pagination showNumbers={true} pageScope={4} />
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-3">
                                                <Button disabled={this.state.selectedMaterials.length > materials_limit} gentle onClick={() => this.setState({ showModalToSelect: false })}>
                                                    <Translation id="ok" defaultMessage="Ok" />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            >
                                <MaterialsListContainer
                                    centers={this.props.centers}
                                    selectMaterial={this.tryAddProduct}
                                    selectBulkMaterials={this.selectBulkMaterials}
                                    selectAllMaterials={this.selectAllMaterials}
                                    removeAllMaterials={this.removeAllMaterials}
                                    selectedMaterials={this.state.selectedMaterials}
                                    sk={this.state.sk}
                                />
                                <Spinner isLoading={this.props.isLoading} />
                            </Modal>
                        </SearchkitProvider>
                    )}
                    {this.state.showModalStock && (
                        <SearchkitProvider searchkit={this.state.skStock}>
                            <Modal
                                title={<Translation id="promotion_stock_modal_header" defaultMessage="Stock of the item on the promotion centers" />}
                                onClose={() => this.setState({ showModalStock: false })}
                                customClass="show-modal-stock"
                                size="large"
                            >
                                <div style={{ display: 'flex', flexDirection: 'row' }} className="" />
                                <MaterialsListContainer productId={this.state.productIdToShowStock} centers={this.props.centers} sk={this.state.skStock} />
                            </Modal>
                        </SearchkitProvider>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        isLogged: getIsLogged(state),
        isAdmin: getIsAdmin(state),
        userType: getUserType(state),
        language: getLanguage(state),
        specialNames: getSpecialNames(state, 'specialNames'),
        isLoading: getIsLoading(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getImageBackground: product => dispatch(getImageBackground(product)),
        getAllProductOfTheLastQueryMultiCenterByUser: () => dispatch(getAllProductOfTheLastQueryMultiCenterByUser())
    }
}
export default ChooseMaterials = withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(ChooseMaterials)
    )
)
