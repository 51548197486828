import React from 'react'
import Translation from '../global/Translation'

export const LowesInfoText = () => {
    return (
        <p>
            <h4>
                <Translation id="silestone_customer_ample_rogram_re_ordering" defaultMessage="Silestone Customer Sample Program Re-Ordering" />
            </h4>
            <ul>
                <li>
                    <Translation
                        id="to_start_your_order_click_on_the_color_that_you_need_to_re_order"
                        defaultMessage="To start your order, click on the color that you need to re-order"
                    />
                </li>
                <li>
                    <Translation
                        id="samples_are_in_sets_of_6_By_clicking_on_the_needed_color_you_are_ordering_6_individual_samples_of_that_color"
                        defaultMessage="Samples are in sets of 6. By clicking on the needed color you are ordering 6 individual samples of that color"
                    />
                </li>
                <li>
                    <Translation
                        id="you_can_order_no_more_than_1_set_of_6_of_any_color_at_one_time"
                        defaultMessage="You can order no more than 1 set of 6 of any color at one time"
                    />
                </li>
                <li>
                    <Translation
                        id="each_store_is_capped_at_30_samples_per_month_and_will_not_be_able_to_re_order_sample_until_the_following_month_if_you_have_reached_the_cap"
                        defaultMessage="Each store is capped at 30 samples per month and will not be able to re-order sample until the following month if you have reached the cap"
                    />
                </li>
                <li>
                    <Translation
                        id="all_orders_will_be_delivered_within_2_4_weeks_of_order_and_sent_to_the_person_who_placed_the_order"
                        defaultMessage="All orders will be delivered within 2-4 weeks of order and sent to the person who placed the order"
                    />
                </li>
                <li>
                    <Translation id="shipment_sent_to_the_assigned_store_only" defaultMessage="Shipment sent to the assigned store only" />
                </li>
                <li>
                    <Translation id="all_silestone_customer_samples_are_4x2" defaultMessage="All Silestone customer samples are 4 inches x 2 inches" />
                </li>
            </ul>
        </p>
    )
}
