import React from 'react'
import Translation from '../global/Translation'

import Lightbox from 'react-image-lightbox'
import Spinner from '../global/Spinner'
import {connect} from "react-redux";
import {downloadImage} from "../../store/ui/actions";


class PackagingImageViewer extends React.Component {

    state = {
        photoIndex: 0
    }

    componentDidMount() {
        document.body.style.overflow = 'hidden'
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto'
    }

    render() {
        const { images } = this.props
        const { photoIndex } = this.state;
        const prevIndex = (photoIndex + images.length - 1) % images.length
        const nextIndex = (photoIndex + 1) % images.length
        const currentImage = images && images.length && images[photoIndex]

        if (!currentImage) return null

        return (
            <section id="section-lots-viewer">
                <div className="carousel">
                    { !!currentImage ? (
                        <div>
                            <Lightbox
                                mainSrc={currentImage.url}
                                prevSrc={images[prevIndex].url}
                                nextSrc={images[nextIndex].url}
                                onCloseRequest={this.props.onClose}
                                wrapperClassName={'carousel-custom'}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + images.length - 1) % images.length,
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + 1) % images.length,
                                    })
                                }
                                imagePadding={60}
                                imageTitle={
                                    <div className="lot-viewer-title">
                                        <div style={{display:'flex'}}>
                                            <div style={{fontWeight:'700'}}>
                                                <div>
                                                    { currentImage.name } ({ photoIndex + 1 }/{ images.length })
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{display:'flex'}}>
                                            <div className="bt">
                                                <a href onClick={() => this.props.downloadImage(currentImage.url, currentImage.name)}>
                                                    <i className="fal fa-download mr-1" />
                                                    <Translation id="download" defaultMessage="Download" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                }
                                imageCaption={
                                    <div className="row lot-viewer-contents">
                                    </div>
                                }
                                toolbarButtons={[]}
                            />
                        </div>
                    ) : (
                        <Spinner loading={true} />
                    )}
                </div>
            </section>
        )
    }
}


const mapDispatchToProps = dispatch => {
    return {
        downloadImage: (url, name) => dispatch(downloadImage(url, name))
    }
}

export default connect(null, mapDispatchToProps)(PackagingImageViewer)
