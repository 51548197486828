import React from 'react'
import LocalizedLink from '../global/LocalizedLink'
import Banner_tarificador from '../../assets/img/banners-v2/banner-tarificador.png'
import Translation from '../global/Translation'

export default class BannerTarificador extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <LocalizedLink
                routeId="ROUTE_CATALOG"
                queryString="?type[0]=TABLA"
                params={{ outlet: '' }}
            >
                <div className={this.props.type && this.props.type === 'v2' ? 'box-parent-banner-tarificador-v2' : 'box-parent-banner-tarificador'} style={{
                    backgroundImage: `url(${Banner_tarificador})`,
                }}>
                    <div className="box-container">
                        <div className="box-title">
                            <Translation id="try_our_new_rater" defaultMessage="Try our new Rater" />
                        </div>
                        <div className="box-subtitle">
                            <Translation id="upload_your_rates" defaultMessage="Upload your rates and generate quotes in seconds" />
                        </div>
                        <div className="box-button-container">
                            <div className="box-button">
                                <a href='https://we.cosentino.com/s/financialPortal'>
                                    <Translation id="go_to_cosentino_we" defaultMessage="Go to Cosentino We" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </LocalizedLink>
        )
    }
}
