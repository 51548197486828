import { connect } from 'react-redux'
import checkoutFormLowes from './CheckoutFormLowes'
import { getShippingMethod, isEuropeOrIberia } from '../../../store/centers/reducers'
import { checkout } from '../../../store/cart/actions'
import {
    getAlert,
    isSending,
    weightSelector,
    getGoalMax,
    getKgPoundRatio,
    getReservationCode,
    qtySelector,
    getMonthItems,
    getWaitTillDate,
    getStatus
} from '../../../store/cart/reducers'
import { getLanguage } from '../../../store/ui/reducers'
import { getProfileConfiguration } from '../../../store/profile-configuration/reducers'

import { fetchShippingAddresses } from '../../../store/shipping-addresses/actions'

import {
    canSendMinMaxCheckout,
    canSendNeedDestination,
    resetCheckout,
    setDeliveryDate,
    setComments,
    setLowesDataFirstName,
    setLowesDataLastName,
    setLowesDataEmail,
    setLowesDataTitle,
    canSendLowesData
} from '../../../store/checkout/actions'
import { getDeliveryDate, getLowesDataFirstName, getLowesDataLastName, getLowesDataEmail, getLowesDataTitle } from '../../../store/checkout/reducers'
import { getUserName, getVersion } from '../../../store/login/reducers'
import CheckoutFormCustomerV2 from '../customer/CheckoutFormCustomerV2'
import CheckoutFormCustomer from '../customer/CheckoutFormCustomer'
import React from 'react'
import CheckoutFormLowesV2 from './CheckoutFormLowesV2'
import CheckoutFormLowes from './CheckoutFormLowes'

const mapStateToProps = state => {
    let language = getLanguage(state)
    let defaultUnit = language === 'en' || language === 'en-US' || language === 'fr-CA' ? 'LB' : 'KG'
    let unit = getProfileConfiguration(state).weightUnit ? getProfileConfiguration(state).weightUnit : defaultUnit

    return {
        current: unit === 'KG' ? weightSelector(state) : Math.floor(weightSelector(state) * getKgPoundRatio()),
        goalMax: getGoalMax(state, unit),
        shippingMethod: getShippingMethod(state),
        alert: getAlert(state),
        status: getStatus(state),
        monthItems: getMonthItems(state),
        waitTillDate: getWaitTillDate(state),
        isSending: isSending(state),
        reservationCode: getReservationCode(state),
        totalQty: qtySelector(state),
        isEuropeOrIberia: isEuropeOrIberia(state),
        deliveryDate: getDeliveryDate(state),
        lowesDataFirstName: getLowesDataFirstName(state),
        lowesDataLastName: getLowesDataLastName(state),
        lowesDataEmail: getLowesDataEmail(state),
        lowesDataTitle: getLowesDataTitle(state),
        userName: getUserName(state),
        newVersion: getVersion(state),
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchShippingAddresses: () => dispatch(fetchShippingAddresses()),
        submitCheckout: () => dispatch(checkout()),
        onLoadCheckout: () => dispatch({ type: 'CHECKOUT_VISIT' }),
        canSendMinMaxCheckout: () => dispatch(canSendMinMaxCheckout()),
        canSendNeedDestination: () => dispatch(canSendNeedDestination()),
        resetCheckout: () => dispatch(resetCheckout()),
        setDeliveryDate: value => dispatch(setDeliveryDate(value)),
        setLowesDataFirstName: value => dispatch(setLowesDataFirstName(value)),
        setLowesDataLastName: value => dispatch(setLowesDataLastName(value)),
        setLowesDataEmail: value => dispatch(setLowesDataEmail(value)),
        setLowesDataTitle: value => dispatch(setLowesDataTitle(value)),
        canSendLowesData: () => dispatch(canSendLowesData()),
        setComments: value => dispatch(setComments(value))
    }
}

const CheckoutFormLowesContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(React.forwardRef((props, ref) => props.newVersion ? <CheckoutFormLowesV2 ref={ref} {...props} /> : <CheckoutFormLowes {...props} />))

export default CheckoutFormLowesContainer
