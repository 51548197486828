import React from 'react'
import OrderDetails from './OrderDetails'
import OrderDetailsV2 from './OrderDetailsV2'

class OrderDetailsSelector extends React.Component {
    render() {
        return this.props.newVersion ? <OrderDetailsV2 {...this.props} /> : <OrderDetails {...this.props} />
    }
}

export default OrderDetailsSelector
