import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import { getLanguage, showingDektonGripModal } from '../../store/ui/reducers'
import * as ui from '../../store/ui/actions'
import { connect } from 'react-redux'
import Modal from '../global/Modal'
import Button from '../global/Button'
import { submitDektonGripModal } from '../../store/ui/actions'

const mapStateToProps = state => {
    return {
        show: showingDektonGripModal(state),
        lang: getLanguage(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(ui.closeDektonGripModal())
        },
        submitDektonGripModal: () => {
            dispatch(submitDektonGripModal())
        }
    }
}

class DektonGripModal extends React.Component {
    getLinkTechnical() {
        //REVIEW: calcule link technical
        let link = 'https://assetstools.cosentino.com/api/v1/bynder/doc/5874AEE2-9EAA-4107-AE305C7741528B3D/Dekton-Grip-Dossier-USA-CA.pdf'
        switch (this.props.lang) {
            case 'es':
                link = 'https://assetstools.cosentino.com/api/v1/bynder/doc/AB67584E-848F-445F-958A5AE8553F7F05/Dekton-Grip-Dossier-ES.pdf'
                break
            case 'en':
                break
            case 'de':
                break
            case 'da':
                break
            case 'fi':
                break
            case 'he':
                break
            case 'en-CA':
                break
            case 'fr-CA':
                break
            case 'fr':
                break
            case 'it':
                break
            case 'ja':
                break
            case 'nl':
                break
            case 'no':
                break
            case 'pt':
                break
            case 'pl':
                break
            case 'sv':
                break
            case 'tr':
                break
            default:
                break
        }
        return link
    }
    getLinkMaintenance() {
        //REVIEW: calcule link Maintenance
        let link = 'https://assetstools.cosentino.com/api/v1/bynder/doc/2A2B02AD-1B28-4F5B-A53531CE58AD97FF/Dekton-Grip-EN.pdf'
        switch (this.props.lang) {
            case 'es':
                link = 'https://assetstools.cosentino.com/api/v1/bynder/doc/1C9DE84C-A64B-47B3-AEAB2DAFD4198DB3/Dekton-Grip-ES.pdf'
                break
            case 'en':
                link = 'https://assetstools.cosentino.com/api/v1/bynder/doc/2A2B02AD-1B28-4F5B-A53531CE58AD97FF/Dekton-Grip-EN.pdf'
                break
            case 'de':
                link = 'https://assetstools.cosentino.com/api/v1/bynder/doc/7373372C-CB64-4A7E-B6D4EF5CC47BC0CF/Dekton-Grip-DE.pdf'
                break
            case 'da':
                link = 'https://assetstools.cosentino.com/api/v1/bynder/doc/2A2B02AD-1B28-4F5B-A53531CE58AD97FF/Dekton-Grip-EN.pdf'
                break
            case 'fi':
                break
            case 'he':
                break
            case 'en-CA':
                link = 'https://assetstools.cosentino.com/api/v1/bynder/doc/F5B12FFF-6BC3-4069-9AAC42C4E98A8674/Dekton-Grip-FR.pdf'
                break
            case 'fr-CA':
                link = 'https://assetstools.cosentino.com/api/v1/bynder/doc/F5B12FFF-6BC3-4069-9AAC42C4E98A8674/Dekton-Grip-FR.pdf'
                break
            case 'fr':
                link = 'https://assetstools.cosentino.com/api/v1/bynder/doc/F5B12FFF-6BC3-4069-9AAC42C4E98A8674/Dekton-Grip-FR.pdf'
                break
            case 'it':
                link = 'https://assetstools.cosentino.com/api/v1/bynder/doc/370BE055-F16F-44A0-A27873DD32FA16DD/Dekton-Grip-IT.pdf'
                break
            case 'ja':
                break
            case 'nl':
                break
            case 'no':
                break
            case 'pt':
                link = 'https://assetstools.cosentino.com/api/v1/bynder/doc/9C886ED8-A9E0-4AB2-A21D15A5F9FCF9A1/Dekton-Grip-PT.pdf'
                break
            case 'pl':
                link = 'https://assetstools.cosentino.com/api/v1/bynder/doc/5CC6EF1A-7561-4C2E-91EDBF0F718D6A5C/Dekton-Grip-PL.pdf'
                break
            case 'sv':
                link = 'https://assetstools.cosentino.com/api/v1/bynder/doc/8AB21F9C-2305-480F-85B91ABC68AF8A91/Dekton-Grip-SV.pdf'
                break
            case 'tr':
                break
            default:
                break
        }
        return link
    }
    render() {
        const { show, onClose, submitDektonGripModal } = this.props

        return show ? (
            <Modal
                title={
                    <h5 style={{ textAlign: 'center', flexGrow: '1' }}>
                        <Translation id="are_you_sure_add_to_cart_a_dekton_and" defaultMessage="Are you sure add to cart a dekton Grip + ?" />
                    </h5>
                }
                onClose={onClose}
                footer={
                    <div className="group_buttons" style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                        <Button onClick={onClose} size="medium" customClass="m-1">
                            <Translation id="cancel" defaultMessage="Cancel" />
                        </Button>
                        <Button onClick={submitDektonGripModal} inverted size="medium" customClass="m-1">
                            <Translation id="add_to_cart" defaultMessage="Add to cart" />
                        </Button>
                    </div>
                }
                customClass="dekton-grip-modal"
            >
                <div className="p-2">
                    <p>
                        <Translation
                            id="dekton_grip_is_the_anti_slip_treatment_for_those_areas_that_require_a_higher_grip_due_to_local_standard_and_is_not_recommended_for_areas_where_it_is_not_necessary"
                            defaultMessage="Dekton Grip + is the anti-slip treatment for those areas that require a higher grip due to local standard, and is not recommended for areas where it is not necessary.​"
                        />
                    </p>
                    <p>
                        <Translation
                            id="for_a_succesfull_installation_we_share_with_you_all_the_information_in_these_links"
                            defaultMessage="For a succesfull installation, we share with you all the information in these links ..."
                        />
                    </p>
                    <p>
                        <a href={this.getLinkTechnical()} target="_blank" rel="noopener noreferrer" size="small">
                            <Translation id="technical_information" defaultMessage="Technical Information" />
                        </a>{' '}
                        <Translation id="and" defaultMessage="and" />{' '}
                        <a href={this.getLinkMaintenance()} target="_blank" rel="noopener noreferrer" size="small">
                            <Translation id="cleaning_&_maintenance" defaultMessage="Cleaning & Maintenance" />
                        </a>
                    </p>
                </div>
            </Modal>
        ) : null
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DektonGripModal)
)
