import React from 'react'
import Translation from '../global/Translation'

import LocalizedLink from '../global/LocalizedLink'
import { injectIntl } from 'react-intl'
import * as ui from '../../store/ui/actions'
import { connect } from 'react-redux'
import {
    getUserType,
    getIsMktoolsAdmin,
    getIsDeviatedAdmin,
    getUserAuthorization,
    getIsMallAdmin,
    getIsCampaignsPilotUser,
    /* canViewV2 */
} from '../../store/login/reducers'
import { logout } from '../../store/login/actions'
import { countUnassignedReservations } from '../../store/reservations/reducers'
import { getChangesRead } from '../../store/changelog/reducers'
import {
    getSelectedCenter,
    getIsMkToolsCenter,
    getLowesCenterId,
    getIsLowesCenter,
    getIsIkeaCenter
} from '../../store/centers/reducers'
import { withMsal } from '@azure/msal-react'
import { toggleVersion } from '../../store/ui/actions';
import Button from '../global/Button';
const mapStateToProps = state => {
    return {
        userType: getUserType(state),
        countReservations: countUnassignedReservations(state),
        changesRead: getChangesRead(state),
        selectedCenter: getSelectedCenter(state),
        isMktoolsCenter: getIsMkToolsCenter(state),
        isMktoolsAdmin: getIsMktoolsAdmin(state),
        isMallAdmin: getIsMallAdmin(state),
        isUserMallAuthorized: getUserAuthorization(state),
        isCampaignAdmin: getIsCampaignsPilotUser(state),
        isDeviatedAdmin: getIsDeviatedAdmin(state),
        LowesCenterId: getLowesCenterId(),
        /* canViewV2: canViewV2(state), */
        isLowesCenter: getIsLowesCenter(state),
        isIkeaCenter: getIsIkeaCenter(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => {
            dispatch(logout())
        },
        onShowUpImpersonate: () => {
            dispatch(ui.showImpersonateModal())
        },
        toggleVersion: id => dispatch(toggleVersion())
    }
}

const ProfileLinks = ({
    intl,
    userType,
    /* canViewV2, */
    responsive,
    onLogout,
    countReservations,
    changesRead,
    selectedCenter,
    isMktoolsCenter,
    isMktoolsAdmin,
    isDeviatedAdmin,
    isUserMallAuthorized,
    isMallAdmin,
    isCampaignAdmin,
    LowesCenterId,
    msalContext,
    toggleVersion,
    onShowUpImpersonate,
    isIkeaCenter,
    isLowesCenter
}) => (
    <React.Fragment>
        <hr />
        {userType === 'ADMIN' && (
            <LocalizedLink routeId="ROUTE_KPIS" className={responsive ? 'nav-link' : null}>
                <li>
                    <Translation id="activity" defaultMessage="Activity" />
                </li>
            </LocalizedLink>
        )}
        {userType === 'SHOP' && (
            <React.Fragment>
                <LocalizedLink routeId="ROUTE_RESERVATIONS" params={{ unassigned: 'unassigned' }} className={responsive ? 'nav-link' : null}>
                    <li>
                        <Translation id="unassigned_reservations" defaultMessage="Unassigned reservations" />{' '}
                        {countReservations ? <span className="badge badge-pill badge-secondary"><span>{countReservations}</span></span> : null}
                    </li>
                </LocalizedLink>
                <LocalizedLink routeId="ROUTE_RESERVATIONS" params={{ unassigned: '' }} className={responsive ? 'nav-link' : null}>
                    <li>
                        <Translation id="reservations_history" defaultMessage="Reservations history" />
                    </li>
                </LocalizedLink>
            </React.Fragment>
        )}
        {/* <li>
            <span>
                <Translation id="favourites" defaultMessage="Favourites" />
            </span>
        </li> */}
        <LocalizedLink routeId={'ROUTE_PROFILE'} params={{ filter: 'configuration', open: '' }} className={responsive ? 'nav-link' : null}>
            <li className="nav-item">
                <Translation id="profile" defaultMessage="My personal area" />
            </li>
        </LocalizedLink>
        {userType === 'CUSTOMER' && (
            <LocalizedLink routeId={'ROUTE_PROFILE'} params={{ filter: 'orders', open: '' }} className={responsive ? 'nav-link' : null}>
                <li className="nav-item">
                    <Translation id="my_orders" defaultMessage="My orders" />
                </li>
            </LocalizedLink>
        )}
        {isUserMallAuthorized && isMallAdmin && (
            <LocalizedLink routeId="ROUTE_LANDING_MANAGER" queryString="" params={{ outlet: '' }} className={true ? 'nav-link' : null}>
                <Translation id="lndmngr" defaultMessage="Landing Manager" />
            </LocalizedLink>
        )}
        {/* Disabled because its unmantained and affects performance */}
        {/* {userType === 'ADMIN' && selectedCenter !== LowesCenterId && (
            <li className="nav-item">
                <LocalizedLink routeId={'ROUTE_CHANGELOG'} className={responsive ? 'nav-link' : null}>
                    <Translation id="changelog" defaultMessage="Changelog" /> {!changesRead && <span className="badge badge-pill badge-primary">!</span>}
                </LocalizedLink>
            </li>
        )} */}
        {isCampaignAdmin && (
            <LocalizedLink routeId={'ROUTE_PROMOTIONS_LIST'} className={responsive ? 'nav-link' : null}>
                <li className="nav-item">
                    <Translation id="admin_campaigns" defaultMessage="Admin campaigns" />
                </li>
            </LocalizedLink>
        )}
        {userType === 'ADMIN' && isMktoolsCenter && isMktoolsAdmin && (
            <React.Fragment>
                <LocalizedLink routeId={'ROUTE_MKT_ADMIN_PRODUCTS'} className={responsive ? 'nav-link' : null}>
                    <li className="nav-item">
                        <Translation id="mkt_admin_products" defaultMessage="Mktools admin" />
                    </li>
                </LocalizedLink>
                <LocalizedLink routeId={'ROUTE_MKT_ADMIN_COLORS'} className={responsive ? 'nav-link' : null}>
                    <li className="nav-item">
                        <Translation id="mkt_admin_colors" defaultMessage="Admin colors" />
                    </li>
                </LocalizedLink>
            </React.Fragment>
        )}
        {userType === 'ADMIN' && isDeviatedAdmin && (
            <LocalizedLink routeId={'ROUTE_DEVIATED_ADMIN'} className={responsive ? 'nav-link' : null}>
                <li className="nav-item">
                    <Translation id="deviated_reason" defaultMessage="Deviated Reason" />
                </li>
            </LocalizedLink>
        )}
        {userType === 'ADMIN' && (
            <li className="nav-item" onClick={() => onShowUpImpersonate()}>
                <Translation id="impersonate" defaultMessage="Impersonate" />
            </li>
        )}

        {(process.env.NODE_ENV !== 'production' || process.env.REACT_APP_ENV === 'qas') && (
            <a href="#!" className={responsive ? 'nav-link' : null}>
                <li className="nav-item" onClick={() => toggleVersion()}>
                    Swap version
                </li>
            </a>
        )}
        <hr />

        {/*<a href="javascript:void(0)" className={responsive ? 'nav-link' : null}>*/}
        {/*    <li className="nav-item" onClick={() => toggleVersion()}>*/}
        {/*        Swap version*/}
        {/*    </li>*/}
        {/*</a>*/}
        {userType === 'CUSTOMER' && !isMktoolsCenter && !isIkeaCenter && !isLowesCenter && (
            <React.Fragment>
                <li className="nav-item">
                    <LocalizedLink routeId={'ROUTE_PROFILE'} params={{ filter: 'recurring-orders', open: '' }} className={responsive ? 'nav-link' : null}>
                        <Translation id="recurring_orders_access" defaultMessage="Recurring orders access" />
                        <span className='new_access_recurring_order'><Translation id="new" defaultMessage="New" /></span>
                        {/*<div className='new_access_recurring_order'>*/}
                        {/*    <Translation id="new" defaultMessage="New" />*/}
                        {/*</div>*/}
                    </LocalizedLink>
                </li>
                <hr />
            </React.Fragment>
        )}
        <li className="nav-item">
            <span
                onClick={() => {
                    if (msalContext.accounts && msalContext.accounts[0]) {
                        msalContext.instance.logoutRedirect()
                    }

                    onLogout()
                }}
                className={responsive ? 'nav-link' : null}
            >
                <Translation id="logout" defaultMessage="Logout" />
            </span>
        </li>
    </React.Fragment>
)

export default withMsal(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(ProfileLinks)
    )
)
