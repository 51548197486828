import React from 'react'
import { injectIntl } from 'react-intl'
import {  showingNewVersionModal } from '../../store/ui/reducers'
import * as ui from '../../store/ui/actions'
import { connect } from 'react-redux'
import image from '../../assets/img/modal-version-mockup.png'
import { toggleVersionParams } from '../../store/ui/actions'
import Translation from '../global/Translation'

const mapStateToProps = state => {
    return {
        show: showingNewVersionModal(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(ui.closeNewVersionModal())
        },
        toggleVersion: (version) => {
            dispatch(toggleVersionParams(version))
            dispatch(ui.closeNewVersionModal())
        }
    }
}

class NewVersionModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { show, onClose, toggleVersion } = this.props
        return show && !this.props.previousPreferences ? (
            <div>
                <div className="full-overlay" style={{ display: show ? 'block' : 'none' }} />
                <div id="modal-new-version-container" style={{ display: show ? 'block' : 'none' }}>
                    <div style={{position: 'relative'}}>
                        <hr className='vertical'/>
                        <div className="row no-gutters">
                            <header className="container-fluid" style={{ position: 'absolute', zIndex: '5', color: 'black', padding: '16px 20px 0px' }}>
                                <div className="row">
                                    <div className="col-3 offset-9 my-auto text-right">
                                        <div className="bt-close" onClick={onClose} style={{cursor: 'pointer'}}>
                                            <i className="fal fa-times" style={{fontSize: '2rem'}} />
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className="col-12 logo-container">
                                <div className='row'>
                                    <div className='col-sm-12 col-md-5'>
                                        <h1 className='title'>
                                            <Translation id="title_modal_new_version" defaultMessage="We change version definitively" />
                                        </h1>
                                        <h3 className='subtitle-2'>
                                            <Translation id="subtitle_2_modal_new_version" defaultMessage="The classic version of e.Cosentino will no longer be available on 02/10/2023." />
                                        </h3>
                                        <h3 className='subtitle'>
                                            <Translation id="subtitle_modal_new_version" defaultMessage="Use our new version by clicking the button." />
                                        </h3>
                                    </div>
                                    <div className='col-sm-12 col-md-7 box-image' style={{textAlign: 'right'}}>
                                        <div className='image'>
                                            <img className='img-fluid' src={image} alt='' />
                                        </div>
                                    </div>
                                    <div className='col-12 buttons'>
                                        <button className='btn-new' onClick={() => toggleVersion(true)}>
                                            <Translation id="btn_2_modal_new_version" defaultMessage="Use the new version" />
                                        </button>
                                        <button className=' btn-old' onClick={() => toggleVersion(false)}>
                                            <Translation id="btn_1_modal_new_version" defaultMessage="Use the classic version" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(NewVersionModal)
)
