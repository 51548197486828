import React from 'react'

import { injectIntl } from 'react-intl'

class DisplayDetail extends React.Component {
    render() {
        if (this.props.value){
            let value = null
            if (this.props.class == 'format') {
                value = this.props.value.replace('X', 'x')
            } else if (this.props.class == 'finish' || this.props.class == 'zaddon') {
                value = this.props.intl.formatMessage({ id: this.props.value })
            } else {
                value = this.props.value
            }
            return (
                <div className={`product_detail ${this.props.class}`}>
                    {(this.props.icon || this.props.faIconClass) && (
                        <div className="icon">
                            {this.props.icon && <img alt="" src={this.props.icon} />}
                            {this.props.faIconClass && <i className={this.props.faIconClass} />}
                        </div>
                    )}
                    <div className="text">
                        {this.props.label && this.props.label}
                        {this.props.label && ': '}
                        {value}
                    </div>
                </div>
            )
        }
        else return null
    }
}
export default injectIntl(DisplayDetail)
