import React from 'react'
import Translation from '../../global/Translation'

import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import { FormattedMessage, injectIntl } from 'react-intl'

import Alerts from '../../alerts/Alerts'
import Button from '../../global/Button'
import confirm from '../../global/confirm'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import OrderFinishedContainer from '../../order-finished/OrderFinishedContainer'
import Select from 'react-select'
import Modal from '../../global/Modal'
import CheckboxLinkSaleConditions from '../global/CheckboxLinkSaleConditions'
import GroupInputVoucher from '../global/GroupInputVoucher'
import ProductDisplayName from '../../global/ProductDisplayName'
import ProductImage from '../../catalog/ProductImage'
import DisplayOutletIndicator from '../../catalog/rectangleResult/displays/DisplayOutletIndicator'

class CheckoutFormEndi extends React.Component {
    state = {
        showModalOffer: false
    }
    componentDidMount() {
        this.props.resetCheckout().then(() => {
            this.props.setDeliveryDate(moment().add(this.props.minDeliveryDate, 'days'))
            this.props.setEmployeeId(this.props.employeeId)
            this.props.fetchShippingAddresses().then(() => {
                if (this.props.shippingAddresses && this.props.shippingAddresses.length > 0) {
                    let endiSelectedShippingAddress = this.props.shippingAddresses.filter(shippingAddress => {
                        return shippingAddress.ecosentinoKey === this.props.endiSelectedShippingAddressId
                    })[0]

                    this.props.setCountryOfSend(endiSelectedShippingAddress.country)
                    this.props.setSelectedShippingCenterId(endiSelectedShippingAddress.centerId)
                    this.props.setSelectedShippingAddressId(endiSelectedShippingAddress.shippingAddressId)
                    this.props.setSelectedShippingAddressSalesOrg(endiSelectedShippingAddress.salesOrg)
                    this.props.setSelectedShippingAddressEcosentinoKey(endiSelectedShippingAddress.ecosentinoKey)

                    this.props.setSelectedShippingAddress(endiSelectedShippingAddress)
                    this.props.setHasSelectedCenter(false)
                    this.props.setDeliveryDate(moment().add(this.props.minDeliveryDate, 'days'))
                }
            })
        })

        this.props.onLoadCheckout()
    }

    canSendCheckout = () => {
        return (
            this.props.canSendMinMaxCheckout() &&
            !this.props.isSending &&
            this.props.totalQty > 0 &&
            this.props.canSendNeedDestination() &&
            this.props.canSendCheckoutSaleConditions
        )
    }

    setHaveOffer = value => {
        this.setState({ transformingSkuToLots: true })
        this.props.setHaveOffer(value)
        return this.props.transformSkuToLots()
    }
    getOptionsCountryOfSend = filteredShippingAddressesForUsers => {
        if (filteredShippingAddressesForUsers)
            return filteredShippingAddressesForUsers.map(shippingAddress => ({
                value: shippingAddress.ecosentinoKey,
                label:
                    shippingAddress.centerName +
                    ' ' +
                    shippingAddress.customerName +
                    ' ' +
                    shippingAddress.address +
                    ' ' +
                    shippingAddress.zipCode +
                    ' ' +
                    shippingAddress.city +
                    ' ' +
                    shippingAddress.country +
                    ' ' +
                    shippingAddress.salesOrg
            }))
        else return []
    }
    getFullNameShippingAddress = shippingAddress => {
        if (!shippingAddress) return null
        return (
            shippingAddress.centerName +
            ' ' +
            shippingAddress.customerName +
            ' ' +
            shippingAddress.address +
            ' ' +
            shippingAddress.zipCode +
            ' ' +
            shippingAddress.city +
            ' ' +
            shippingAddress.country +
            ' ' +
            shippingAddress.salesOrg
        )
    }
    confirmSubmitCheckout = () => {
        let shippingAddress = this.props.selectedShippingAddress
        confirm(
            <div className="confirm-checkout">
                <div className="confirm-checkout_title">
                    {this.props.intl.formatMessage({id:"please_confirm_the_order"})}:
                </div>
                <div className="confirm-checkout_text">
                    {this.props.intl.formatMessage({ id:"shipping_address_ikea"},
                        { shippingAddress: this.getFullNameShippingAddress(shippingAddress)}) }
                </div>
            </div>,
            { textCancel: this.props.intl.formatMessage({ id: 'change' }), textOk: this.props.intl.formatMessage({ id: 'submit' }) }
        , {intl: this.props.intl}).then(
            confirm => {
                this.submitCheckout()
            },
            cancel => {
                this.props.redirect(this.props.intl.formatMessage({ id: 'ROUTE_HOME' })).then(() => {
                    this.props.changeEndiShippingAddress()
                })
            }
        )
    }
    submitCheckout() {
        if (this.props.haveSomeProductsBelowThreshold) {
            confirm(
                <div className="confirm-checkout">
                    <div className="confirm-checkout_title">{this.props.intl.formatMessage({ id: 'please_confirm_the_order' })}:</div>
                    <div className="confirm-checkout_text">
                        {this.props.intl.formatMessage({
                            id:
                                'there_are_only_a_few_remaining_items_in_stock_in_at_least_one_of_the_chosen_materials_in_the_order_which_may_constrain_the_availability_upon_orden_confirmation'
                        })}
                    </div>
                </div>
            , {intl: this.props.intl}).then(
                confirm => {
                    if (this.props.canOffer) {
                        this.setState({ showModalOffer: true })
                    } else {
                        this.props.submitCheckout()
                    }
                },
                cancel => {
                    //close modeal
                }
            )
        } else {
            if (this.props.canOffer) {
                this.setState({ showModalOffer: true })
            } else {
                this.props.submitCheckout()
            }
        }
    }

    render() {
        const { shippingAddresses, current, shippingMethod, goalMax } = this.props

        let filteredShippingAddressesForUsers = shippingAddresses
            ? shippingAddresses.filter(shippingAddress => {
                  return shippingAddress.allowedShippingTypes.includes(shippingMethod)
              })
            : []

        const sendText =
            this.props.shippingAddressFilteredToSelectByCenter && this.props.shippingAddressFilteredToSelectByCenter.length === 1 ? (
                <Translation id="send" defaultMessage="Send" />
            ) : (
                <Translation id="confirm" defaultMessage="Confirm" />
            )

        const difCurrent = current <= goalMax ? 0 : current - goalMax
        const errorMessage = difCurrent !== 0 ? 'MAX_WEIGHT_EXCEEDED' : null
        const submit = this.canSendCheckout() ? (
            this.props.shippingAddressFilteredToSelectByCenter && this.props.shippingAddressFilteredToSelectByCenter.length === 1 ? (
                <Button inverted datacy="checkout__send_checkout" onClick={() => this.submitCheckout()}>
                    {sendText}
                </Button>
            ) : (
                <Button inverted datacy="checkout__send_checkout" onClick={() => this.confirmSubmitCheckout()}>
                    {sendText}
                </Button>
            )
        ) : (
            <Button inverted disabled>
                {sendText}
            </Button>
        )
        if (this.props.isLoadingShippingAddress) return <Translation id="loading_shipping_addresses" defaultMessage="Loading Shipping Addresses" />
        const destinationForCustomer = [
            { value: 'CLIENTE', label: this.props.intl.formatMessage({ id: 'customer' }) },
            {
                value: 'HUB_CLIENTE',
                label: this.props.intl.formatMessage({ id: 'cosentino_hub' })
            },
            {
                value: 'CENTER',
                label: this.props.intl.formatMessage({ id: 'center' })
            }
        ]
        return (
            <div>
                <h4 className="form-heading heading">{sendText}</h4>
                <form>
                    <Alerts
                        alert={this.props.alert}
                        values={{ monthItems: this.props.monthItems, waitTillDate: this.props.waitTillDate ? moment(this.props.waitTillDate).format('L') : null }}
                        status={this.props.status}
                    />

                    {this.props.centerSelected == 7500 && !this.props.canSendNaturalStoneContainer() ? (
                        <Alerts alert={this.props.intl.formatMessage({ id: 'Minimum/maximum weight exceeded, please adjust your order' })} />
                    ) : (
                        ''
                    )}
                    {(this.props.centerSelected == 1070 || this.props.centerSelected == 7500) && this.canSendCheckout() ? (
                        <Translation id="natural_stone_may_be_modified_due_to_shipment" defaultMessage="Please bear in mind the order can be modified due to the shipment" />
                    ) : (
                        ''
                    )}
                    {this.props.hasDestination && (
                        <div className={`form-group`}>
                            <label htmlFor="input-date">
                                {this.props.shippingMethod === 'CONTAINER' ? (
                                    <Translation id="destination_for_container" defaultMessage="Container unloading location" />
                                ) : (
                                    <Translation id="destination" defaultMessage="Destination" />
                                )}
                            </label>
                            <Select
                                className={'form-control '}
                                placeholder={this.props.intl.formatMessage({ id: 'select_shipping_address' }) + '...'}
                                value={this.props.destination}
                                onChange={e => {
                                    e && this.props.setDestination(e.value)
                                }}
                                options={destinationForCustomer}
                            />
                        </div>
                    )}

                    {this.props.endiSelectedShippingAddressId && (
                        <div className="form-group">
                            <label htmlFor="input-date">
                                <Translation id="shipping_address" defaultMessage="Shipping Address" />
                            </label>
                            <div>{this.getFullNameShippingAddress(this.props.selectedShippingAddress)}</div>
                        </div>
                    )}

                    <div className="form-group select_date">
                        <label htmlFor="input-date">
                            <Translation id="required_delivery_date" defaultMessage="Required delivery date" />
                        </label>
                        <DatePicker
                            selected={this.props.deliveryDate}
                            onChange={this.props.setDeliveryDate}
                            minDate={moment().add(this.props.minDeliveryDate, 'days')}
                            className="form-control"
                            autoComplete="off"
                            onKeyDown={e => e.preventDefault()}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="input-po">
                            <Translation id="insert_po" defaultMessage="Insert your PO#" />
                        </label>
                        <input
                            data-cy="checkout__input_po"
                            type="text"
                            className="form-control"
                            id="input-po"
                            onChange={e => {
                                e && this.props.setPo(e.target.value)
                            }}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="input-comments">
                            <Translation id="comment" defaultMessage="Comments" />
                        </label>
                        <textarea
                            data-cy="checkout__textarea_comment"
                            className="form-control"
                            id="input-comments"
                            rows="3"
                            maxlength="200"
                            onChange={e => {
                                e && this.props.setComments(e.target.value)
                            }}
                        />
                    </div>
                    <GroupInputVoucher />
                    <div className="form-group">
                        <CheckboxLinkSaleConditions />
                    </div>
                </form>
                <div className="row align-items-baseline">
                    <div className="col-12" style={{ paddingLeft: '35px', paddingBottom: '10px' }}>
                        {this.props.isEuropeOrIberia ? (
                            <Translation
                                id="same_batch"
                                defaultMessage="Please, indicate if you want the slabs to be the same batch. Check availability with your center. Thank you"
                            />
                        ) : null}
                    </div>
                    <div className="col-4 offset-8">{submit}</div>
                </div>
                {this.state.showModalOffer && (
                    <Modal
                        title={<Translation id="confirm_to_proceed" defaultMessage="Confirm to proceed" />}
                        onClose={() =>
                            this.setState({
                                showModalOffer: false
                            })
                        }
                        size="large"
                    >
                        <div className="row">
                            <div className="col-12">
                                <p className="text-center">
                                    {this.state.transformingSkuToLots && <Alerts alert={'TRANSFORMING_SKU_TO_LOTS'} status="warning" />}
                                    <Translation
                                        id="Please_select_one_of_the_available_options_to_continue"
                                        defaultMessage="Please select one of the available options to continue"
                                    />
                                </p>
                                <strong>
                                    <Translation id="quote" defaultMessage="Quote" />
                                    :&nbsp;
                                </strong>
                                <Translation
                                    id="quote_description"
                                    defaultMessage="You are requesting a quote. The center will provide you a budget, that you can either accept or dismiss"
                                />
                                <br />
                                <strong>
                                    <Translation id="order" defaultMessage="Order" />
                                    :&nbsp;
                                </strong>
                                <Translation id="order_description" defaultMessage="Your reservation will be approved by the center and it will turn into an order" />
                                <br />
                                <br />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 offset-md-2 col-md-4 p4">
                                <Button
                                    onClick={() =>
                                        this.setHaveOffer(true)
                                            .then(response => {
                                                this.setState({ showModalOffer: false, transformingSkuToLots: false })

                                                this.props.submitCheckout()
                                            })
                                            .catch(() => {
                                                this.setState({ showModalOffer: false, transformingSkuToLots: false })
                                            })
                                    }
                                    disabled={this.state.transformingSkuToLots}
                                    inverted
                                    size="medium"
                                >
                                    <Translation id="request_a_quote" defaultMessage="Request a Quote" />
                                </Button>
                            </div>
                            <div className="col-12 col-md-4 p4">
                                <Button
                                    onClick={() =>
                                        this.props.setHaveOffer(false).then(() => {
                                            this.setState({ showModalOffer: false })
                                            this.props.submitCheckout()
                                        })
                                    }
                                    inverted
                                    size="medium"
                                    disabled={this.state.transformingSkuToLots}
                                >
                                    <Translation id="make_a_reservation_or_order" defaultMessage="Make a reservation / order" />
                                </Button>
                            </div>
                        </div>
                    </Modal>
                )}
                {(this.props.reservationCode  || this.props.reservationCutToSizeCode) && <OrderFinishedContainer />}
                {this.props.showModalNoStock && (
                    <Modal
                        title={<Translation id="no_stock_checkout_title" defaultMessage="Materials without stock in your order" />}
                        onClose={() => this.props.closeNoStockModal()}
                        size="large"
                        footer={
                            <Button onClick={() => this.props.closeNoStockModal()} inverted size="medium">
                                <Translation id="accept" defaultMessage='Accept' />
                            </Button>
                        }
                    >
                        <div className="row">
                            <div className="col-12">
                                <div className="confirm-checkout_text">
                                    <Translation
                                        id="no_stock_checkout_desc"
                                        defaultMessage="There are some materials in your order without enough stock to continue. Please, review the material list below and make the adjustment in the materials units to continue with your order."
                                    />
                                </div>
                            </div>
                        </div>
                        {this.props.haveSomeProductsWithoutSapStock.length > 0 && (
                            <div className="row mb-2 ">
                                <div className="col-2"></div>
                                <div className="col-6 align-self-center">
                                    <b>
                                        <Translation id="displayName" defaultMessage='Product Name'></Translation>
                                    </b>
                                </div>
                                <div className="col-2 d-none d-lg-block text-center">
                                    <b>
                                        <Translation id="requestedQty" defaultMessage='requested qty'></Translation>
                                    </b>
                                </div>
                                <div className="col-2 text-center">
                                    <b>
                                        <Translation id="available" defaultMessage='available'></Translation>
                                    </b>
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-12">
                                {this.props.haveSomeProductsWithoutSapStock.map(line => (
                                    <div className="row mb-2 matNoStock-modal">
                                        <div className="col-4 col-md-2">
                                            {/* <img className="img" src={orderLine.product.thumbMd}/> */}
                                            <div className="indicators">
                                                {line.extra && line.extra.outlet && (
                                                    <DisplayOutletIndicator
                                                        outletQuality={line.extra.outlet}
                                                        // outletDeviated={this.props.isAdmin || this.props.impersonatedBy ? this.product.outletDeviated : null}
                                                    />
                                                )}
                                            </div>

                                            <ProductImage product={line.product}></ProductImage>
                                        </div>
                                        <div className="col-3 align-self-center">
                                            <b>
                                                <ProductDisplayName product={line.product} />
                                            </b>
                                            <div className="">{line.product.brand}</div>
                                        </div>

                                        <div className="col-3 align-self-center">
                                            {!line.cutToSize && line.product.finish && (
                                                <div className="mini_cart_line_info__line">
                                                    {this.props.intl.formatMessage({ id: line.product.finish })}
                                                    {/*<Translation id={line.product.finish} />*/}
                                                </div>
                                            )}
                                            {line.cutToSize && line.cutToSize.finish && (
                                                <div className="mini_cart_line_info__line">
                                                    <div className="subtitle">
                                                        {this.props.intl.formatMessage({ id: line.cutToSize.finish })}
                                                        {/*<Translation id={line.cutToSize.finish} />*/}
                                                    </div>
                                                </div>
                                            )}
                                            {line.product.thickness && !line.cutToSize && (
                                                <div className="mini_cart_line_info__line mini_cart_line_info__line--dimensions">
                                                    <span>
                                                        <i className="fas fa-sort-amount-down" />
                                                        {line.product.thickness} cm.{' '}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-2 d-none d-lg-block">
                                            <div className="qty-cell">
                                                <p>{line.qty}</p>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            {line.extra && line.extra.outlet ? (
                                                <div className="qty-cell">
                                                    <p>
                                                        <b>{line.stockOutletAvailable}</b>
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className="qty-cell">
                                                    <p>
                                                        <b>{line.stockAvailable}</b>
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Modal>
                )}
            </div>
        )
    }
}

export default injectIntl(CheckoutFormEndi)
