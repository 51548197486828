import React, { useState } from 'react'

import Button from '../../global/Button'
import DisplayPricePhase2 from '../../catalog/rectangleResult/displays/DisplayPricePhase2'
import * as analytics from '../../../analyticsEvents'
import DisplayOutletIndicator from '../../catalog/rectangleResult/displays/DisplayOutletIndicator'
import cameraOff from '../../../assets/img/icons/camera-off.svg'
import SimpleModal from '../../global/SimpleModal'
import ProductDisplayName from '../../global/ProductDisplayName'
import { getProductStock, isProductOutlet, isProductSoldOut } from '../utils'
import Translation from '../../global/Translation'

const ProductItemTemplate2 = ({ product, index, commonParamsAnalytics, currentIso, setProductSelectedToAdd, redirect, intl, addProductInFetchedProducts, setSelectedProductId, userType, ...props }) => {
    const [showImageModal, setShowImageModal] = useState(false);
    const [imageModal, setImageModal] = useState('');
    const stock = getProductStock(product)

    return (
        <React.Fragment>
            <div className="col-12">
                <div className="product-item">
                    <div className="container">
                        <div className="row no-gutters product-item--content">
                            <DisplayOutletIndicator
                                outletQuality={product && product.productLanding && isProductOutlet(product) && !isProductSoldOut(product) && product.productLanding.outletQuality}
                            />
                            <div className={"col-xl-6 col-lg-5 col-12 product-item--image" + (!product.detailImage || product.detailImage === 'null' ? '--placeholder' : '')}
                                style={{ backgroundImage: `url(${product.detailImage && product.detailImage !== 'null' ? product.detailImage : cameraOff})` }}
                            >
                                {
                                    product.detailImage && product.detailImage !== 'null' &&
                                    <Button
                                        className="slide-card__zoom"
                                        icon="expand"
                                        size="medium"
                                        inverted
                                        onClick={() => { setImageModal(product.detailImage); setShowImageModal(true); }}
                                    />
                                }
                                {isProductSoldOut(product) && <span className="product-item__tag"><Translation id="sold_out" defaultMessage="Sold out" /></span>}
                            </div>
                            <div className="col-xl-6 col-lg-7 col-12 product-item--text">
                                {
                                    !!product.hasImage && <img className={`product-item__brand product-item__brand--image product-item__brand--${product.brandWithoutSpaces}`} alt={product.brand} src={product.hasImage} />
                                }
                                {
                                    !product.hasImage && (
                                        <p className="product-item__brand product-item__brand--text">
                                            {product.brand} &reg;
                                        </p>
                                    )
                                }
                                <p className="product-item__name">
                                    <ProductDisplayName product={{ ...product, displayName: product.name ? product.name.toUpperCase() : '', translations: product.data.translations ? product.data.translations : {} }} />
                                </p>
                                <div className="product-item__data">
                                    <div className="product-item__data--specs">
                                        <p className="product-item__data--thickness">
                                            <i className="icon-thickness light"></i>
                                            <span>{product.thickness}</span>
                                        </p>
                                        <p className="product-item__data--finish">
                                            <i className="icon-finish"></i>
                                            <span>{product.finish}</span>
                                        </p>
                                    </div>
                                    {
                                        product && product.productLanding && userType !== 'ADMIN' ?
                                            <p className="product-item__data--price">
                                                <DisplayPricePhase2
                                                    product={product.productLanding}
                                                />
                                            </p>
                                            :
                                            <span><Translation id="price_not_available" defaultMessage="Price not available" /></span>
                                    }
                                </div>
                                <div className="product-item__order">
                                    <p className="product-item__order--stock">
                                        <strong>{intl.formatMessage({ id: 'stock' })}:</strong> {stock}
                                    </p>
                                    <p className="product-item__order--minimum">
                                        {
                                            !!product.minimumOrder && (
                                                <React.Fragment>
                                                    <strong>
                                                        <Translation id="minimum_order" defaultMessage="Minimum order" />:
                                                    </strong> {Math.min(stock, product.minimumOrder)}
                                                </React.Fragment>
                                            )
                                        }
                                    </p>
                                    {/* <p className="product-item__order--desc">
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Omnis velit corporis fuga explicabo ad debitis.
                    </p> */}
                                </div>
                                <div className="product-item__buttons">
                                    <Button
                                        size="medium"
                                        icon="plus-circle"
                                        customClass="product-item__buttons__btn product-item__buttons__btn--ghost"
                                        onClick={() => {
                                            analytics.productClick(product, index, 'MALL', null, null, commonParamsAnalytics);
                                            setSelectedProductId(product.id);
                                            addProductInFetchedProducts(product.productLanding, parseInt(product.centerId, 10), currentIso);
                                            redirect(intl.formatMessage({ id: 'ROUTE_PRODUCT_DETAIL' }).replace(':id', product.product_id).replace(':outlet?', product.outlet ? 'outlet' : ''))
                                        }}
                                    >
                                        <Translation id="info" defaultMessage="Information" />
                                    </Button>
                                    {
                                        !!stock && (
                                            <Button
                                                size="medium"
                                                icon="shopping-cart"
                                                customClass="product-item__buttons__btn"
                                                onClick={() => {
                                                    setProductSelectedToAdd(product);
                                                }}
                                            >
                                                {intl.formatMessage({ id: 'add_to_cart' })}
                                            </Button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showImageModal &&
                <SimpleModal customClass="image-zoom-modal" size="large" onClose={() => setShowImageModal(false)}>
                    <img src={imageModal} />
                </SimpleModal>
            }
        </React.Fragment>
    )
}

export default ProductItemTemplate2;
