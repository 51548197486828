import React from 'react'
import Translation from '../global/Translation'
import { defineMessages, injectIntl } from 'react-intl'
import Select from 'react-select'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import I18nPromotionModal from './I18nPromotionModal'
import StepsBar from './StepsBar'
import Button from '../global/Button'
import ChooseBanner from './steps/ChooseBanner'
import ChooseCustomers from './steps/ChooseCustomers'
import ChooseArea from './steps/ChooseArea'
import ChooseMaterials from './steps/ChooseMaterials'
import GoBack from '../global/GoBack'
import ReactTooltip from 'react-tooltip'
import ChooseTemplate from './steps/ChooseTemplate'
import ChooseDiscounts from "./steps/ChooseDiscounts";
import {canViewDiscountStep} from "../../store/promotions/reducers";
import {cloneDeep} from "lodash"
import confirm from '../global/confirm';
import { mapValues } from 'lodash'

defineMessages({
    country_AE: {
        id: 'country_AE',
        description: 'country_AE',
        defaultMessage: 'United Arab Emirates'
    },
    country_AT: {
        id: 'country_AT',
        description: 'country_AT',
        defaultMessage: 'Austria'
    },
    country_AU: {
        id: 'country_AU',
        description: 'country_AU',
        defaultMessage: 'Australia'
    },
    country_BE: {
        id: 'country_BE',
        description: 'country_BE',
        defaultMessage: 'Belgium'
    },
    country_BR: {
        id: 'country_BR',
        description: 'country_BR',
        defaultMessage: 'Brazil'
    },
    country_CA: {
        id: 'country_CA',
        description: 'country_CA',
        defaultMessage: 'Canada'
    },
    country_CH: {
        id: 'country_CH',
        description: 'country_CH',
        defaultMessage: 'Switzerland'
    },
    country_DE: {
        id: 'country_DE',
        description: 'country_DE',
        defaultMessage: 'Germany'
    },
    country_DK: {
        id: 'country_DK',
        description: 'country_DK',
        defaultMessage: 'Denmark'
    },
    country_DU: {
        id: 'country_DU',
        description: 'country_DU',
        defaultMessage: 'Country DU'
    },
    country_ES: {
        id: 'country_ES',
        description: 'country_ES',
        defaultMessage: 'Spain'
    },
    country_FI: {
        id: 'country_FI',
        description: 'country_FI',
        defaultMessage: 'Finland'
    },
    country_FR: {
        id: 'country_FR',
        description: 'country_FR',
        defaultMessage: 'France'
    },
    country_GB: {
        id: 'country_GB',
        description: 'country_GB',
        defaultMessage: 'United Kingdom'
    },
    country_IE: {
        id: 'country_IE',
        description: 'country_IE',
        defaultMessage: 'Ireland'
    },
    country_IL: {
        id: 'country_IL',
        description: 'country_IL',
        defaultMessage: 'Israel'
    },
    country_IT: {
        id: 'country_IT',
        description: 'country_IT',
        defaultMessage: 'Italy'
    },
    country_JP: {
        id: 'country_JP',
        description: 'country_JP',
        defaultMessage: 'Japan'
    },
    country_LT: {
        id: 'country_LT',
        description: 'country_LT',
        defaultMessage: 'Lithuania'
    },
    country_MX: {
        id: 'country_MX',
        description: 'country_MX',
        defaultMessage: 'Mexico'
    },
    country_MY: {
        id: 'country_MY',
        description: 'country_MY',
        defaultMessage: 'Malaysia'
    },
    country_NL: {
        id: 'country_NL',
        description: 'country_NL',
        defaultMessage: 'Netherlands'
    },
    country_NO: {
        id: 'country_NO',
        description: 'country_NO',
        defaultMessage: 'Norway'
    },
    country_NZ: {
        id: 'country_NZ',
        description: 'country_NZ',
        defaultMessage: 'New Zealand'
    },
    country_PL: {
        id: 'country_PL',
        description: 'country_PL',
        defaultMessage: 'Poland'
    },
    country_PR: {
        id: 'country_PR',
        description: 'country_PR',
        defaultMessage: 'Puerto Rico'
    },
    country_PT: {
        id: 'country_PT',
        description: 'country_PT',
        defaultMessage: 'Portugal'
    },
    country_SE: {
        id: 'country_SE',
        description: 'country_SE',
        defaultMessage: 'Sweden'
    },
    country_SG: {
        id: 'country_SG',
        description: 'country_SG',
        defaultMessage: 'Singapore'
    },
    country_TR: {
        id: 'country_TR',
        description: 'country_TR',
        defaultMessage: 'Turkey'
    },
    country_US: {
        id: 'country_US',
        description: 'country_US',
        defaultMessage: 'United States'
    },
    country_ZA: {
        id: 'country_ZA',
        description: 'country_ZA',
        defaultMessage: 'South Africa'
    },
    promotion_confirm_clean_customers_materials: {
        id: 'promotion_confirm_clean_customers_materials',
        description: 'promotion_confirm_clean_customers_materials',
        defaultMessage: 'Are you sure you want to modify the promotion zones? Customers and materials related to this promotion will be deleted.'
    },
    promotion_preferences_title: {
        id: 'promotion_preferences_title',
        description: 'promotion_preferences_title',
        defaultMessage: 'Newsletters, campaigns and promotions'
    },
    promotion_preferences_accept_notifications_check: {
        id: 'promotion_preferences_accept_notifications_check',
        description: 'promotion_preferences_accept_notifications_check',
        defaultMessage: 'I want to receive notifications about promotions and offers from my center in eCosentino'
    },
    promotion_preferences_copy_email_address: {
        id: 'promotion_preferences_copy_email_address',
        description: 'promotion_preferences_copy_email_address',
        defaultMessage: 'I want to use the email addresses of the rest of the notifications'
    },
    promotion_select_centers: {
        id: 'promotion_select_centers',
        description: 'promotion_select_centers',
        defaultMessage: 'Select centers'
    },
    promotion_select_countries: {
        id: 'promotion_select_countries',
        description: 'promotion_select_countries',
        defaultMessage: 'Select countries'
    },
    promotion_select_languages: {
        id: 'promotion_select_languages',
        description: 'promotion_select_languages',
        defaultMessage: 'Languages'
    },
    promotion_select_market: {
        id: 'promotion_select_market',
        description: 'promotion_select_market',
        defaultMessage: 'Select market'
    },
    promotion_select_user: {
        id: 'promotion_select_user',
        description: 'promotion_select_user',
        defaultMessage: 'Select user'
    },
    promotion_select_center: {
        id: 'promotion_select_center',
        description: 'promotion_select_center',
        defaultMessage: 'Select center'
    },
    details_of_the_promotion_it_must_include_terms_and_conditions_of_the_campaign: {
        id: 'details_of_the_promotion_it_must_include_terms_and_conditions_of_the_campaign',
        description: 'details_of_the_promotion_it_must_include_terms_and_conditions_of_the_campaign',
        defaultMessage: 'Details of the promotion. It must include terms and conditions of the campaign'
    },
    not_applicable: {
        id: 'not_applicable',
        description: 'not_applicable',
        defaultMessage: 'Not applicable'
    },
    rotation_discontinued_material: {
        id: 'rotation_discontinued_material',
        description: 'rotation_discontinued_material',
        defaultMessage: 'Discontinued material'
    },
    rotation_low_rotation: {
        id: 'rotation_low_rotation',
        description: 'rotation_low_rotation',
        defaultMessage: 'Low rotation'
    },
    rotation_discontinued_format: {
        id: 'rotation_discontinued_format',
        description: 'rotation_discontinued_format',
        defaultMessage: 'Discontinued format'
    },
    rotation_material_no_portfolio: {
        id: 'rotation_material_no_portfolio',
        description: 'rotation_material_no_portfolio',
        defaultMessage: 'Material no portfolio'
    },
    rotation_new_creation: {
        id: 'rotation_new_creation',
        description: 'rotation_new_creation',
        defaultMessage: 'New creation'
    },
    rotation_discontinued_finish: {
        id: 'rotation_discontinued_finish',
        description: 'rotation_discontinued_finish',
        defaultMessage: 'Discontinued finish'
    },
    rotation_high_rotation: {
        id: 'rotation_high_rotation',
        description: 'rotation_high_rotation',
        defaultMessage: 'High rotation'
    },
    rotation_medium_rotation: {
        id: 'rotation_medium_rotation',
        description: 'rotation_medium_rotation',
        defaultMessage: 'Medium rotation'
    },
    rotation_slow_moving: {
        id: 'rotation_slow_moving',
        description: 'rotation_slow_moving',
        defaultMessage: 'Slow moving'
    },
    rotation_purchase_sale: {
        id: 'rotation_purchase_sale',
        description: 'rotation_purchase_sale',
        defaultMessage: 'Purchase and sale'
    },
    confirm_unsaved_changes: {
        id: 'confirm_unsaved_changes',
        description: 'confirm_unsaved_changes',
        defaultMessage: 'You have unsaved changes. Indicate if you want to save them below.'
    },
    save_and_continue: {
        id: 'save_and_continue',
        description: 'save_and_continue',
        defaultMessage: 'Save and continue'
    },
    continue_without_saving: {
        id: 'continue_without_saving',
        description: 'continue_without_saving',
        defaultMessage: 'Continue without saving'
    }
})

const TITLE_CHARS_LIMIT = 64
const DESC_CHARS_LIMIT = 256

class PromotionAdmin extends React.Component {

    validDateToRequestDiscount = moment().add(7, 'days')

    constructor(props) {
        super(props)
        const startDate = this.props.startDate ? moment(this.props.startDate) : moment()
        this.state = {
            promotionName: this.props.promotionName,
            languages: this.props.languages,
            startDate: startDate,
            endDate: this.props.endDate ? moment(this.props.endDate) : moment().add(15, 'days'),
            commercial_name: this.props.commercial_name,
            description: this.props.description,
            showModali18n: false,
            objectToTranslate: null,
            objectTrans: null,
            urlPreview: null,
            fetchedMarkets: this.props.getMarkets(),
            fetchedCountries: [],
            fetchedCentres: [],
            //start banners
            bannerLanding: null,
            bannerHome: null,
            bannerNewsLetter: null,
            sideBannerNewsLetter: null,
            bannerTitle: null,
            bannerDescription: null,
            //end banners

            //alerts of validations form
            alertCommercialNameRequired: false,
            alertDescriptionRequired: false,
            descriptionModalForDescription: null,
            canViewDiscountRequest: startDate > this.validDateToRequestDiscount,
            //Unsaved changes
            hasUnsavedChanges: {
                promotionNameDirty: false,
                promotionLanguageDirty: false,
                promotionStartDateDirty: false,
                promotionEndDateDirty: false,
                promotionCommercialNameDirty: false,
                promotionDescriptionNameDirty: false
            },
            languagesCloned: null,
            commercialNameCloned: null,
            descriptionCloned: null
        }
        
        // Create the references to connect child components with parent component.
        this.references = {
            chooseAreaRef: React.createRef(),
            chooseCustomersRef: React.createRef(),
            chooseMaterialsRef: React.createRef(),
            chooseDiscountsRef: React.createRef(),
            chooseTemplateRef: React.createRef(),
            chooseBannerRef: React.createRef()
        }

        this.props.getMarkets()
    }

    componentDidMount() {
        //Get the initial value of languages to have something to compare
        const languagesCloned = cloneDeep(this.props.languages)
        this.setState({ languagesCloned: languagesCloned })

        //Get the initial value of commercial name to have something to compare
        const commercialNameCloned = cloneDeep(this.props.commercial_name)
        this.setState({ commercialNameCloned: commercialNameCloned })

        //Get the initial value of description to have something to compare
        const descriptionCloned = cloneDeep(this.props.description)
        this.setState({ descriptionCloned: descriptionCloned })
    }

    /**
     * Check if the promotion start date is more than 7 days before the promotion begins.
     * @param {object} date momentjs 
     */
    handleValidDateToShowDiscountStep(date) {
        if (date > this.validDateToRequestDiscount) {
            this.setState({ canViewDiscountRequest: true })
        } else {
            this.setState({ canViewDiscountRequest: false }) 
        }
    }

    /**
     * Handle the confirm modal popup for each step.
     */
    triggerConfirmUnsavedChanges = () => {
        return confirm(
            this.props.intl.formatMessage({ id: 'confirm_unsaved_changes' }), {textCancel: this.props.intl.formatMessage({ id: 'continue_without_saving' }), textOk: this.props.intl.formatMessage({ id: 'save_and_continue' })}).then(
            confirm => {
                switch (this.props.step) {
                    case 2:
                        this.confirmFromStepTwo()
                        break
                    case 3:
                        this.confirmFromStepThree()
                        break
                    case 4:
                        this.confirmFromStepFour()
                        break
                    case 5:
                        this.confirmFromStepFive()
                        break
                    case 6:
                        this.confirmFromStepSix()
                        break
                    case 7:
                        this.confirmFromStepSeven()
                        break
                    default:
                        break
                }

              return this.props.stepBack(this.props.step - 1)
            },
            cancel => {
                switch (this.props.step) {
                    case 2:
                        this.cancelFromStepTwo()
                        break
                    case 3:
                        this.cancelFromStepThree()
                        break
                    case 4:
                        this.cancelFromStepFour()
                        break
                    case 5:
                        this.cancelFromStepFive()
                        break
                    case 6:
                        this.cancelFromStepSix()
                        break
                    case 7:
                        this.cancelFromStepSeven()
                        break
                    default:
                        break
                }

                return this.props.stepBack(this.props.step - 1)
           }
        )
    }

    /**
     * Handles the call to the submit function of step 2 according to the requested step.
     */
    confirmFromStepTwo = () => {
        this.references.chooseAreaRef.current.handleSubmit()
    }

    /**
     * Handles the call to the submit function of step 3 according to the requested step.
     */
    confirmFromStepThree = () => {
        this.references.chooseCustomersRef.current.handleSubmit()
    }

    /**
     * Handles the call to the submit function of step 4 according to the requested step.
     */
    confirmFromStepFour = () => {
        this.references.chooseMaterialsRef.current.handleSubmit()
    }

    /**
     * Handles the call to the submit function of step 5 according to the requested step.
     */
    confirmFromStepFive = () => {
        this.references.chooseDiscountsRef.current.handleSubmit()
    }

    /**
     * Handles the call to the submit function of step 6 according to the requested step.
     */
    confirmFromStepSix = () => {
        this.references.chooseTemplateRef.current.handleSubmit() 
    }

    /**
     * Handles the call to the submit function of step 7 according to the requested step.
     */
    confirmFromStepSeven = () => {
        this.references.chooseBannerRef.current.handleSubmit() 
    }

    /**
     * Handle the continue without saving action for step 2.
     */
    cancelFromStepTwo = () => {
        /** @type {object} */
        const chooseAreaRef = this.references.chooseAreaRef.current

        /** @type {object} */
        let unsavedChanges = chooseAreaRef && chooseAreaRef.state.hasUnsavedChanges

        // Transform the object values to false value
        unsavedChanges = mapValues(unsavedChanges, () => false)
        // Update the global state
        this.props.setUnsavedChangesStepTwo(unsavedChanges)
    }

    /**
     * Handle the continue without saving action for step 3.
     */
    cancelFromStepThree = () => {
        /** @type {object} */
        const chooseCustomersRef = this.references.chooseCustomersRef.current

        /** @type {object} */
        let unsavedChanges = chooseCustomersRef && chooseCustomersRef.state.hasUnsavedChanges

        // Transform the object values to false value
        unsavedChanges = mapValues(unsavedChanges, () => false)
        // Update the global state
        this.props.setUnsavedChangesStepThree(unsavedChanges)
    }

    /**
     * Handle the continue without saving action for step 4.
     */
    cancelFromStepFour = () => {
        /** @type {object} */
        const chooseMaterialsRef = this.references.chooseMaterialsRef.current

        /** @type {object} */
        let unsavedChanges = chooseMaterialsRef && chooseMaterialsRef.state.hasUnsavedChanges

        // Transform the object values to false value
        unsavedChanges = mapValues(unsavedChanges, () => false)
        // Update the global state
        this.props.setUnsavedChangesStepFour(unsavedChanges)
    }

    /**
     * Handle the continue without saving action for step 5.
     */
    cancelFromStepFive = () => {
        /** @type {object} */
        const chooseDiscountsRef = this.references.chooseDiscountsRef.current

        /** @type {object} */
        let unsavedChanges = chooseDiscountsRef && chooseDiscountsRef.state.hasUnsavedChanges

        // Transform the object values to false value
        unsavedChanges = mapValues(unsavedChanges, () => false)
        // Update the global state
        this.props.setUnsavedChangesStepFive(unsavedChanges)
    }

    /**
     * Handle the continue without saving action for step 6.
     */
    cancelFromStepSix = () => {
        /** @type {object} */
        const chooseTemplateRef = this.references.chooseTemplateRef.current

        /** @type {object} */
        let unsavedChanges = chooseTemplateRef && chooseTemplateRef.state.hasUnsavedChanges

        // Transform the object values to false value
        unsavedChanges = mapValues(unsavedChanges, () => false)
        // Update the global state
        this.props.setUnsavedChangesStepSix(unsavedChanges)
    }

    /**
     * Handle the continue without saving action for step 7.
     */
    cancelFromStepSeven = () => {
        /** @type {object} */
        const chooseBannerRef = this.references.chooseBannerRef.current

        /** @type {object} */
        let unsavedChanges = chooseBannerRef && chooseBannerRef.state.hasUnsavedChanges

        // Transform the object values to false value
        unsavedChanges = mapValues(unsavedChanges, () => false)
        // Update the global state
        this.props.setUnsavedChangesStepSeven(unsavedChanges)
    }

    stepBack() {
        if (this.props.step === 1 && this.props.hasUnsavedChangesStepOne) {
            return this.triggerConfirmUnsavedChanges()
        } else if (this.props.step === 2 && this.props.hasUnsavedChangesStepTwo) {
            return this.triggerConfirmUnsavedChanges()
        } else if (this.props.step === 3 && this.props.hasUnsavedChangesStepThree) {
            return this.triggerConfirmUnsavedChanges()
        } else if (this.props.step === 4 && this.props.hasUnsavedChangesStepFour) {
            return this.triggerConfirmUnsavedChanges()
        } else if (this.props.step === 5 && this.props.hasUnsavedChangesStepFive) {
            return this.triggerConfirmUnsavedChanges()
        } else if (this.props.step === 6 && this.props.hasUnsavedChangesStepSix) {
            return this.triggerConfirmUnsavedChanges()
        } else if (this.props.step === 7 && this.props.hasUnsavedChangesStepSeven) {
            return this.triggerConfirmUnsavedChanges()
        } else {
            this.props.stepBack(this.props.step - 1)
        }
    }

    getLanguages = () => {
        return this.props.markets
            .map(land => {
                return { value: land.localecode, label: land.name, name: land.name, code: land.code }
            })
            .sort((a, b) => {
                if (a.label < b.label) return -1
                if (a.label > b.label) return 1
                return 0
            })
    }

    setDefaultLanguage() {
        let commercial_name = this.props.translations.commercial_name
        commercial_name['default_text'] = commercial_name[commercial_name['default']]
        let description = this.props.translations.description
        description['default_text'] = description[description['default']]

        this.setState({
            commercial_name: commercial_name,
            description: description
        })
    }

    updateTranslations(object, updatedTranslations) {
        this.props.saveTranslationObject(object, updatedTranslations)
        this.setState({
            showModali18n: false,
            objectToTranslate: null,
            objectTrans: null,
            [object]: updatedTranslations
        }, () => {
            if (object === 'commercial_name') this.handleCommercialNameUnsavedChanges()
            if (object === 'description') this.handleDescriptionUnsavedChanges()
        })

        if (object === 'commercial_name') this.setState({ alertCommercialNameRequired: false })
        if (object === 'description') this.setState({ alertDescriptionRequired: false })
    }

    /**
     * @param {string} modalType 
     */
    showi18nModal(modalType) {
        const charLimit = modalType === 'description' ? DESC_CHARS_LIMIT : TITLE_CHARS_LIMIT
        const limitWarning = this.props.intl.formatMessage({
            id: 'details_of_the_promotion_it_must_not_exceed_n_chars',
            defaultMessage: 'The text cannot exceed {value} characters'
        }, {value:charLimit})
        const descWarning = this.props.intl.formatMessage({
            id: 'details_of_the_promotion_it_must_include_terms_and_conditions_of_the_campaign',
            defaultMessage: 'Details of the promotion. It must include terms and conditions of the campaign'
        })

        if (modalType) {
            this.setState({
                showModali18n: true,
                objectToTranslate: modalType,
                objectTrans: this.props.translations[modalType],
                descriptionModalForDescription: modalType === 'description' ?
                    <div>
                        <div>{descWarning}</div>
                        <div>{limitWarning}</div>
                    </div>
                    : limitWarning,
                charLimit: charLimit
            }, () => {
                this.handleDescriptionUnsavedChanges()
                this.handleCommercialNameUnsavedChanges()
            })
        }
    }

    onCloseModal = e => {
        this.setState({
            showModali18n: false,
            objectToTranslate: null,
            objectTrans: null
        }, () => {
            this.handleDescriptionUnsavedChanges()
            this.handleCommercialNameUnsavedChanges()
        })
    }

    handleHeaderSubmit = e => {
        if(e) e.preventDefault()
        if (!!this.nameInput.value && !!this.state.startDate && !!this.state.endDate && !!this.props.commercial_name && !!this.props.description) {
            this.setDefaultLanguage()
            
            //No more unsaved changes after send the form for step one
            this.setState({ hasUnsavedChanges: {
                ...this.state.hasUnsavedChanges,
                promotionNameDirty: false,
                promotionLanguageDirty: false,
                promotionStartDateDirty: false,
                promotionEndDateDirty: false,
                promotionCommercialNameDirty: false,
                promotionDescriptionNameDirty: false
            }}, () => {
                this.props.sendPromotionHeader(this.nameInput.value, this.state.startDate, this.state.endDate, this.props.commercial_name, this.props.description)
                //Update global state after form submit
                this.props.setUnsavedChangesStepOne(this.state.hasUnsavedChanges)

                //Make sure the change step is the last function
                //Only if event exists we use this change step. If not we use the one inside StepsBar component
                if (e) {
                    this.props.changeStep(this.props.step + 1)
                }
            }) 
        } else {
            if (!this.props.commercial_name) this.setState({ alertCommercialNameRequired: true })
            if (!this.props.description) this.setState({ alertDescriptionRequired: true })
        }
    }

    /**
     * Handle the state promotion name for unsaved changes.
     * @param {object} event 
     */
    handlePromotionName = (event) => {
        const { value } = event.target

        // Check if old value is same or diferent of new the value. Then update state.
        this.setState({ promotionName: value }, () => {
            if (this.props.promotionName !== this.state.promotionName) {
                this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionNameDirty: true} }, () => {
                    //Update global state
                    this.props.setUnsavedChangesStepOne(this.state.hasUnsavedChanges)
                })
            } else if (this.props.promotionName === this.state.promotionName) {
                this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionNameDirty: false} }, () => {
                    //Update global state
                    this.props.setUnsavedChangesStepOne(this.state.hasUnsavedChanges)
                })
            }
        })
    }

    /**
     * Handle the state start date for unsaved changes.
     * @param {object} event momentjs
     */
    handlePromotionStartDate = (event) => {
        this.handleValidDateToShowDiscountStep(event)

        this.setState({ startDate: event }, () => {
            const propsStartDate = moment(this.props.startDate).format('YYYY/MM/DD')
            const stateStartDate = (this.state.startDate).format('YYYY/MM/DD')

            // Check if old value is same or diferent of new the value. Then update state.
            if (propsStartDate !== stateStartDate) {
                this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionStartDateDirty: true} }, () => {
                    //Update global state
                    this.props.setUnsavedChangesStepOne(this.state.hasUnsavedChanges)
                })
            } else if (propsStartDate === stateStartDate) {
                this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionStartDateDirty: false} }, () => {
                    //Update global state
                    this.props.setUnsavedChangesStepOne(this.state.hasUnsavedChanges)
                })
            }
        })
    }

    /**
     * Handle the state end date for unsaved changes.
     * @param {object} event momentjs
     */
    handlePromotionEndDate = (event) => {
        this.setState({ endDate: event }, () => {
            const propsEndDate = moment(this.props.endDate).format('YYYY/MM/DD')
            const stateEndDate = (this.state.endDate).format('YYYY/MM/DD')

            // Check if old value is same or diferent of new the value. Then update state.
            if (propsEndDate !== stateEndDate) {
                this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionEndDateDirty: true} }, () => {
                    //Update global state
                    this.props.setUnsavedChangesStepOne(this.state.hasUnsavedChanges)
                })
            } else if (propsEndDate === stateEndDate) {
                this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionEndDateDirty: false} }, () => {
                    //Update global state
                    this.props.setUnsavedChangesStepOne(this.state.hasUnsavedChanges)
                })
            }
        })
    }

    /**
     * Handle the state languages for unsaved changes.
     * @param {object} selectedOption
     */
    handlePromotionLanguageUnsavedChanges = (selectedOption) => {
        this.props.setAvailableLanguages(selectedOption)

        this.setState({ languages: selectedOption }, () => {
            const stateLanguages = this.state.languagesCloned
            const propsLanguages = this.props.languages
    
            const stateJsonLanguages = stateLanguages && JSON.stringify(this.state.languagesCloned)
            const propsJsonLanguages = propsLanguages && JSON.stringify(this.props.languages)
    
            // Check if old value is same or diferent of new the value. Then update state.
            if (propsJsonLanguages !== stateJsonLanguages) {
                this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionLanguageDirty: true} }, () => {
                    //Update global state
                    this.props.setUnsavedChangesStepOne(this.state.hasUnsavedChanges)
                })
            } else if (propsJsonLanguages === stateJsonLanguages) {
                this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionLanguageDirty: false} }, () => {
                    //Update global state
                    this.props.setUnsavedChangesStepOne(this.state.hasUnsavedChanges)
                })
            }
        })
    }

    /**
     * Handle the state commercial name for unsaved changes.
     */
    handleCommercialNameUnsavedChanges = () => {
        const stateCommercialName = this.state.commercialNameCloned && this.state.commercialNameCloned.default
        const propsCommercialName = this.props.commercial_name && this.props.commercial_name.default

        // Check if old value is same or diferent of new the value. Then update state.
        if (propsCommercialName !== stateCommercialName) {
            this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionCommercialNameDirty: true} }, () => {
                //Update global state
                this.props.setUnsavedChangesStepOne(this.state.hasUnsavedChanges)
            })
        } else if (propsCommercialName === stateCommercialName) {
            this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionCommercialNameDirty: false} }, () => {
                //Update global state
                this.props.setUnsavedChangesStepOne(this.state.hasUnsavedChanges)
            })
        }
    }

    /**
     * Handle the state description for unsaved changes.
     */
    handleDescriptionUnsavedChanges = () => {
        const stateDescription = this.state.descriptionCloned && this.state.descriptionCloned.default
        const propsDescription = this.props.description && this.props.description.default

        // Check if old value is same or diferent of new the value. Then update state.
        if (propsDescription !== stateDescription) {
            this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionDescriptionNameDirty: true} }, () => {
                //Update global state
                this.props.setUnsavedChangesStepOne(this.state.hasUnsavedChanges)
            })
        } else if (propsDescription === stateDescription) {
            this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionDescriptionNameDirty: false} }, () => {
                //Update global state
                this.props.setUnsavedChangesStepOne(this.state.hasUnsavedChanges)
            })
        }
    }

    getComponentOfTheStep = () => {
        let step = this.props.step

        if (step > 4 && !this.props.canViewDiscountStep) step++

        // eslint-disable-next-line default-case
        switch (step) {
            case 1:
                return (
                    <div>
                        <div className="row">
                            <div className="col-12">
                                <h3 style={{ fontWeight: '400' }}>
                                    <span style={{ fontWeight: '300' }}>
                                        <Translation id="step" defaultMessage={'Step'} /> {this.props.step}
                                    </span>{' '}
                                    <Translation id="promotion_line_general_info" defaultMessage="General info" />
                                </h3>
                                <p>
                                    <Translation
                                        id="fill_the_following_fields_with_the_general_information_of_your_campaign"
                                        defaultMessage="Fill the following fields with the general information of your campaign"
                                    />
                                </p>
                            </div>
                        </div>
                        <form id="form_current_step" onSubmit={(e) => this.handleHeaderSubmit(e)}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group input_name">
                                        <label htmlFor="input_name">
                                            <Translation id="campaign_name" defaultMessage="Campaign name" />{' '}
                                            <i
                                                className="fal fa-info-circle"
                                                style={{ margin: '5px', flex: '0 0 auto' }}
                                                data-tip={this.props.intl.formatMessage({
                                                    id: 'internal_name_of_the_promotion_not_show_to_the_customer',
                                                    defaultMessage: 'Internal name of the promotion (not show to the customer)'
                                                })}
                                            />
                                        </label>
                                        <input
                                            name="name"
                                            id="input_name"
                                            required
                                            type="text"
                                            className="form-control"
                                            placeholder={this.props.intl.formatMessage({ id: 'name' })}
                                            defaultValue={this.state.promotionName}
                                            ref={node => {
                                                this.nameInput = node
                                            }}
                                            data-cy="promotion-input_name"
                                            onChange={(event) => this.handlePromotionName(event)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group select_languages">
                                        <label htmlFor="select_languages">
                                            <Translation id="promotion_languages" defaultMessage="Promotion languages" />
                                        </label>
                                        <Select
                                            required
                                            id="select_languages"
                                            multi={true}
                                            className="form-control"
                                            placeholder={this.props.intl.formatMessage({ id: 'promotion_select_languages' })}
                                            value={this.props.languages}
                                            onChange={selectedOption => {
                                                    this.handlePromotionLanguageUnsavedChanges(selectedOption)
                                                }
                                            }
                                            options={this.getLanguages()}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row reverse-flex">
                                <div className="col-md-6">
                                    <div className="col-12 m-0 p-0">
                                        <div className="form-group select_date align-items-center">
                                            <label htmlFor="input-date" style={{ marginRight: '15px', marginBottom: '0px' }}>
                                                <Translation id="campaign_start_date" defaultMessage="Campaign start date" />
                                            </label>
                                            <DatePicker
                                                selected={this.state.startDate}
                                                selectsStart={true}
                                                onChange={selectedOption => this.handlePromotionStartDate(selectedOption)}
                                                autoComplete="off"
                                                onKeyDown={e => e.preventDefault()}
                                                showMonthDropdown
                                                showYearDropdown
                                                isClearable={!this.props.isAmortized}
                                                className="form-control date"
                                                disabled={this.props.isAmortized}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 m-0 p-0">
                                        <div className="form-group select_date align-items-center">
                                            <label htmlFor="input-date" style={{ marginRight: '15px', marginBottom: '0px' }}>
                                                <Translation id="promotion_end_date" defaultMessage="Promotion end date" />
                                            </label>
                                            <DatePicker
                                                selected={this.state.endDate}
                                                selectsStart={true}
                                                onChange={selectedOption => this.handlePromotionEndDate(selectedOption)}
                                                autoComplete="off"
                                                onKeyDown={e => e.preventDefault()}
                                                showMonthDropdown
                                                showYearDropdown
                                                isClearable={!this.props.isAmortized}
                                                className="form-control date"
                                                disabled={this.props.isAmortized}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 promo-alert-message-container">
                                    <div className="alert mb-1 mt-1 alert-warning d-flex justify-content-center align-items-center">
                                        <div>
                                            <i className="fal fa-info-circle" />
                                        </div>
                                        <div>
                                            <strong>
                                                <Translation id="start_date_promotion_alert_message" defaultMessage="If you are going to request discounts for this campaign, the start date must start in at least 7 calendar days." />
                                            </strong>
                                        </div>
                                    </div>
                                </div>                        
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <hr />
                                    <h5>
                                        <Translation id="enter_the_public_title_and_public_description" defaultMessage="Enter the public title and public description" />
                                    </h5>
                                </div>
                                <div className="col-6">
                                    <div className="form-group set_commercial_name">
                                        <label htmlFor="commercial_name">
                                            <Translation id="commercial_name" defaultMessage="Commercial name" />
                                        </label>
                                        <Button className="promotion-button d-inline-block" 
                                            disabled={this.props.languages.length === 0} 
                                            onClick={() => {
                                                    this.showi18nModal('commercial_name')
                                                    this.handleCommercialNameUnsavedChanges()
                                                    this.handleDescriptionUnsavedChanges()
                                                }
                                            }>
                                            <Translation id="promotion_edit_view_commercial_name" defaultMessage="Edit/View commercial name" />
                                        </Button>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group set_description">
                                        <label htmlFor="description">
                                            <Translation id="description" defaultMessage="Description" />
                                        </label>
                                        <i
                                            className="fal fa-info-circle"
                                            style={{ margin: '5px', flex: '0 0 auto' }}
                                            data-tip={this.props.intl.formatMessage({
                                                id: 'details_of_the_promotion_it_must_include_terms_and_conditions_of_the_campaign',
                                                defaultMessage: 'Details of the promotion. It must include terms and conditions of the campaign'
                                            })}
                                        />
                                        <ReactTooltip effect="solid" place="top" />
                                        <Button className="promotion-button d-inline-block" 
                                            disabled={this.props.languages.length === 0} 
                                            onClick={() => {
                                                    this.showi18nModal('description')
                                                    this.handleCommercialNameUnsavedChanges()
                                                    this.handleDescriptionUnsavedChanges()
                                                }
                                            }>
                                            <Translation id="promotion_edit_view_description" defaultMessage="Edit/View description" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {this.state.alertCommercialNameRequired && (
                                    <div className="col-12">
                                        <div className="alert alert-warning alert-dismissible d-flex justify-content-between">
                                            <Translation id="the_commercial_name_field_is_required" defaultMessage="The commercial name field is required" />
                                            <div className="bt-close" onClick={() => this.setState({ alertCommercialNameRequired: false })}>
                                                <i className="fal fa-times" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.alertDescriptionRequired && (
                                    <div className="col-12">
                                        <div className="alert alert-warning alert-dismissible d-flex justify-content-between">
                                            <Translation id="the_description_field_is_required" defaultMessage="The description field is required" />
                                            <div className="bt-close" onClick={() => this.setState({ alertDescriptionRequired: false })}>
                                                <i className="fal fa-times" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <ReactTooltip effect="solid" place="top" />
                        </form>
                    </div>
                )
            case 2:
                return <ChooseArea {...this.props} ref={this.references.chooseAreaRef} handleReference={this.handleReference}/>
            case 3:
                return <ChooseCustomers {...this.props} ref={this.references.chooseCustomersRef} handleReference={this.handleReference}/>
            case 4:
                return <ChooseMaterials {...this.props} ref={this.references.chooseMaterialsRef} handleReference={this.handleReference}/>
            case 5:
                return <ChooseDiscounts {...this.props} canViewDiscountRequest={this.state.canViewDiscountRequest} ref={this.references.chooseDiscountsRef} handleReference={this.handleReference}/>
            case 6:
                return <ChooseTemplate {...this.props} ref={this.references.chooseTemplateRef} handleReference={this.handleReference}/>
            case 7:
                return <ChooseBanner {...this.props} ref={this.references.chooseBannerRef} handleReference={this.handleReference}/>
        }
    }

    /**
     * Handles the reference created to be able to pass information from the child components to the parent.
     * @param {object} r reference 
     */
    handleReference = (r) => {
        this.references.chooseAreaRef.current = r
        this.references.chooseCustomersRef.current = r
        this.references.chooseMaterialsRef.current = r
        this.references.chooseDiscountsRef.current = r
        this.references.chooseTemplateRef.current = r
        this.references.chooseBannerRef.current = r
    }

    render() {
        if (this.props.step > 7) {
            this.props.redirect(this.props.intl.formatMessage({ id: 'ROUTE_PROMOTIONS_LIST' }))
            return null
        }

        return (
            <section id="section-promotion-admin">
                <div className="promotions">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 pt-3">
                                <GoBack goBack={() => this.props.goAdminList(this.props.intl)}></GoBack>
                            </div>
                        </div>
                        <div id="progressbar" className={`promotion-card card my-4 p-1 p-sm-4`}>
                            <StepsBar 
                                step={this.props.step}
                                changeStep={this.props.changeStep}
                                availableSteps={this.props.availableSteps}
                                canViewDiscountStep={this.props.canViewDiscountStep}
                                hasUnsavedChangesStepOne={this.props.hasUnsavedChangesStepOne}
                                hasUnsavedChangesStepTwo={this.props.hasUnsavedChangesStepTwo}
                                hasUnsavedChangesStepThree={this.props.hasUnsavedChangesStepThree}
                                hasUnsavedChangesStepFour={this.props.hasUnsavedChangesStepFour}
                                hasUnsavedChangesStepFive={this.props.hasUnsavedChangesStepFive}
                                hasUnsavedChangesStepSix={this.props.hasUnsavedChangesStepSix}
                                hasUnsavedChangesStepSeven={this.props.hasUnsavedChangesStepSeven}
                                references={this.references}
                                handleHeaderSubmit={this.handleHeaderSubmit}
                                stepOneUnsavedChanges={this.state.hasUnsavedChanges}
                                setUnsavedChangesStepOne={this.props.setUnsavedChangesStepOne}
                                setUnsavedChangesStepTwo={this.props.setUnsavedChangesStepTwo}
                                setUnsavedChangesStepThree={this.props.setUnsavedChangesStepThree}
                                setUnsavedChangesStepFour={this.props.setUnsavedChangesStepFour}
                                setUnsavedChangesStepFive={this.props.setUnsavedChangesStepFive}
                                setUnsavedChangesStepSix={this.props.setUnsavedChangesStepSix}
                                setUnsavedChangesStepSeven={this.props.setUnsavedChangesStepSeven}
                            />
                        </div>
                        <div id="promotion_card" className={`promotion-card card my-4 p-2 p-sm-4`}>
                            {this.getComponentOfTheStep()}         
                            <div className={`row ${!this.state.viewDiscountRequest && this.props.canViewDiscountStep && this.props.step === 5 ? 'd-none' : '' }`}>
                                <div className="col-12 buttons d-flex justify-content-between px-md-5 mt-4">
                                    <div className="d-flex">
                                        <Button id="back_button" 
                                                className="promotion-button promotion-button-back" 
                                                disabled={this.props.step <= 1}
                                                onClick={() => this.stepBack()}>
                                            <Translation id="back" defaultMessage="Back" />
                                        </Button>
                                    </div>
                                    <button className="promotion-button inverted" type="submit" form="form_current_step">
                                        {this.props.step <= 6 ? <Translation id="save_and_next" defaultMessage="Save and next" /> : <Translation id="save" defaultMessage="Save" />}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showModali18n && (
                    <I18nPromotionModal
                        translations={this.state.objectTrans}
                        languages={this.props.languages}
                        title={this.props.intl.formatMessage({ id: this.state.objectToTranslate })}
                        type={this.state.objectToTranslate}
                        onCloseModal={() => this.onCloseModal()}
                        onSaveForm={(object, updatedTranslations) => this.updateTranslations(object, updatedTranslations)}
                        description={this.state.descriptionModalForDescription}
                        limit={this.state.charLimit}
                    />
                )}
            </section>
        )
    }
}

export default injectIntl(PromotionAdmin)
