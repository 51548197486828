import React from 'react'
import Translation from '../global/Translation'

import { connect } from 'react-redux'
import CartSummaryContainer from './CartSummaryContainer'
import IncentivesContainer from '../incentives/IncentivesContainer'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'

import CheckoutFormIkeaContainer from './ikea/CheckoutFormIkeaContainer'
import CheckoutFormMktoolsContainer from './mktools/CheckoutFormMktoolsContainer'
import CheckoutFormLowesContainer from './lowes/CheckoutFormLowesContainer'
import CheckoutFormShopContainer from './shop/CheckoutFormShopContainer'
import CheckoutFormCustomerContainer from './customer/CheckoutFormCustomerContainer'
import CheckoutFormEndiContainer from './endi/CheckoutFormEndiContainer'

import LocalizedLink from '../global/LocalizedLink'
import Alerts from '../alerts/Alerts'
import { getLines } from '../../store/cart/reducers'
import GoBack from '../global/GoBack'
import { getIsIkeaCenter, getIsMkToolsCenter, getIsLowesCenter, getIsEndiCenter, canOffer } from '../../store/centers/reducers'
import { getUserType } from '../../store/login/reducers'
import { getLanguageDir } from '../../store/ui/reducers'

const mapStateToProps = state => {
    const lines = getLines(state)

    let haveCutToSize = lines.filter(line => line.cutToSize).length > 0
    let haveTwoType = haveCutToSize && lines.filter(line => !line.cutToSize).length > 0
    // let haveTwoType = getLines(state).filter(type => type.productId === 'CUT_TO_SIZE').length > 0 && getLines(state).filter(type => type.productId !== 'CUT_TO_SIZE').length > 0

    return {
        haveTwoType: haveTwoType,
        haveCutToSize: haveCutToSize,
        isIkeaCenter: getIsIkeaCenter(state),
        isMktoolsCenter: getIsMkToolsCenter(state),
        isLowesCenter: getIsLowesCenter(state),
        userType: getUserType(state),
        languageDir: getLanguageDir(state),
        isEndiCenter: getIsEndiCenter(state),
        isOffer: canOffer(state)
    }
}

class Checkout extends React.Component {
    getCheckoutType() {
        if (this.props.isIkeaCenter) return 'ikea'
        else if (this.props.isMktoolsCenter) return 'mktools'
        else if (this.props.isLowesCenter) return 'lowes'
        else if (this.props.userType === 'SHOP') return 'shop'
        else if (this.props.isEndiCenter) return 'endi'
        else return 'default'
    }
    checkoutForm() {
        switch (this.getCheckoutType()) {
            case 'ikea':
                return <CheckoutFormIkeaContainer />
            case 'mktools':
                return <CheckoutFormMktoolsContainer />
            case 'lowes':
                return <CheckoutFormLowesContainer />
            case 'shop':
                return <CheckoutFormShopContainer />
            case 'endi':
                return <CheckoutFormEndiContainer />
            default:
                return <CheckoutFormCustomerContainer />
        }
    }
    canViewIncentives = () => {
        if (this.props.isIkeaCenter) return false
        else if (this.props.isMktoolsCenter && this.props.userType === 'CUSTOMER') return false
        else return true
    }
    render() {
        let { haveTwoType, languageDir, haveCutToSize } = this.props

        return (
            <div>
                <div className="container-heading">
                    <div className="container">
                        <div className="row checkout-heading-row">
                            <div className="col-12">
                                <h2 className="heading checkout-heading">
                                    {this.props.isOffer ? (
                                        <Translation id="request_a_quote" defaultMessage="Request a Quote" />
                                    ) : (
                                        <Translation id="checkout" defaultMessage="Checkout" />
                                    )}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container checkout">
                    <div className="row">
                        <div className="col-12">
                            {this.props.isLowesCenter || this.props.isMktoolsCenter || this.props.isIkeaCenter ? (
                                <GoBack />
                            ) : (
                                <LocalizedLink routeId="ROUTE_CATALOG" queryString="?type[0]=TABLA" params={{ outlet: '' }}>
                                    <div className="go-back">
                                        <i className={`fal fa-chevron-${languageDir === 'rtl' ? 'right' : 'left'}`} />
                                        <span className="label">
                                            <Translation id="keep_buying" defaultMessage="Keep Buying" />
                                        </span>
                                    </div>
                                </LocalizedLink>
                            )}
                        </div>

                        <div className="col-12">
                            {this.canViewIncentives() && <IncentivesContainer />}
                            {haveTwoType && (
                                <div className="row">
                                    <div className="col-12" style={{ paddingBottom: '20px' }}>
                                        <Alerts
                                            alert="Your cart contains products cut to size and prefabricated products, two separate orders will be made to finalize the order"
                                            status="warning"
                                        />
                                    </div>
                                </div>
                            )}
                            {haveCutToSize && (
                                <div className="row">
                                    <div className="col-12" style={{ paddingBottom: '20px' }}>
                                        <Alerts
                                            alert="The weight of cut-to-size products is not computed"
                                            status="warning"
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="row">
                                <div className="col-12 col-lg-7">
                                    <CartSummaryContainer />
                                </div>
                                <div className="col-12 col-lg-5">{this.checkoutForm()}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row mt-3" />
                </div>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    null
)(Checkout)
