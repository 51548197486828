import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'

import LandingTemplates1 from './LandingTemplates_1';
import LandingTemplates2 from './LandingTemplates_2';

import {
    LANDING_TEMPLATES_IDS,
    generateFiltersState,
    isFilterSelected,
    getFilteredProducts, getMallProduct,
} from './utils';

import {
    CENTERS, parseLandingStatus
} from '../landing-manager/utils';
import Error from '../alerts/Error';
import Spinner from '../global/Spinner';


const LandingTemplates = ({
    showToastNotification,
    fetchLandingBySlug,
    fetchPreviewLandingBySlug,
    previewMode,
    slug,
    landing,
    landingId,
    currentIso,
    fetchLandingbyId,
    filters,
    fetchProductsLandingsByCenters,
    productsLanding,
    productsLots,
    commonParamsAnalytics,
    priceList,
    userPriceList,
    intl,
    selectedCenter,
    lines,
    userType,
    isLogged,
    loginRequired,
    isLoadingLanding,
    isLoadingProductsAndLots,
    newVersion,
    ...props
}) => {
    const [showFilters, setShowFilters] = useState(false);
    const [filtersSelected, setFiltersSelected] = useState(generateFiltersState);
    const [productSelectedToAdd, setProductSelectedToAdd] = useState(null);
    const [sortSelected, selectedSort] = useState('');
    const [fetchedProducts, setFetchedProducts] = useState(false);
    const [formattedProducts, setFormattedProducts] = useState([])

    useEffect(() => {
        if (!isLogged) {
            loginRequired(fetchLandingBySlug(slug))
        }
        if (previewMode) {
            fetchPreviewLandingBySlug(slug, currentIso)
        } else {
            fetchLandingBySlug(slug);
        }
    }, [slug]);

    useEffect(() => {
        const productsLanding = landing && landing.landing && landing.landing.products;
        if (!fetchedProducts && productsLanding) {
            fetchProductsLandingsByCenters(landing.landing.common_id, landing.landing.centers, productsLanding, previewMode ? null : landing.landing.portfolio_mode)
                .then(() => {
                    setFetchedProducts(true)
                });
        }
    }, [landing && landing.landing && landing.landing.products])

    const landingData = landing && landing.landing || {};
    const landingProducts = landingData && landingData.products || [];

    useEffect(() => {
        if (productsLanding && landingProducts) {
            const _formattedProducts = []
            landingProducts.forEach(product => {
                const fProduct = getMallProduct(product, productsLanding, productsLots)
                if (fProduct) {
                    _formattedProducts.push(fProduct)
                }
            })
            setFormattedProducts(_formattedProducts)
        }
    },[Object.keys(productsLots).length, productsLanding.length, landingProducts.length])

    // useEffect(() => {
    //     fetchLandingBySlug(slug);
    // }, [isLogged]);



    const filteredProducts = getFilteredProducts({
        filters: filtersSelected,
        products: formattedProducts,
        sortSelected,
        productsLanding,
    });

    const selectFilter = (type, filterValue) => () => {
        setFiltersSelected({
            ...filtersSelected,
            [type]: isFilterSelected(filtersSelected[type], filterValue) ?
                [...filtersSelected[type].filter(filter => filterValue !== filter)] :
                [...filtersSelected[type], filterValue]
        })
    }

    const unselectFilters = () => {
        const filters = {};
        Object.keys(filtersSelected).forEach(fc => {
            filters[fc] = [];
        })
        setFiltersSelected(filters)
    }



    if (!landing || !landing.landing || isLoadingProductsAndLots || !landing.landing.template_id || (parseLandingStatus(landing.landing) === 'EXPIRED' && !previewMode)) {
        if (isLoadingLanding || isLoadingProductsAndLots) {
            return <Spinner isLoading={true}></Spinner>
        }
        return <Error alert={intl.formatMessage({ id: 'SORRY_PAGE_NOT_FOUND' })} />;
    }

    
    if (landing.landing.template_id === LANDING_TEMPLATES_IDS.TEMPLATE_1) {
        return (
            <LandingTemplates1
                formattedProducts={filteredProducts}
                landing={landing}
                showToastNotification={showToastNotification}
                landingId={landingId}
                currentIso={intl.locale}
                lines={lines}
                filters={filters}
                fetchProductsLandingsByCenters={fetchProductsLandingsByCenters}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                productSelectedToAdd={productSelectedToAdd}
                setProductSelectedToAdd={setProductSelectedToAdd}
                sortSelected={sortSelected}
                selectedSort={selectedSort}
                selectFilter={selectFilter}
                unselectFilters={unselectFilters}
                filtersSelected={filtersSelected}
                landingData={landingData}
                commonParamsAnalytics={commonParamsAnalytics}
                priceList={priceList}
                userPriceList={userPriceList}
                selectedCenter={selectedCenter}
                userType={userType}
                newVersion={newVersion}
                {...props}
            />
        )
    }

    if (landing.landing.template_id === LANDING_TEMPLATES_IDS.TEMPLATE_2) {
        return (
            <LandingTemplates2
                landing={landing}
                showToastNotification={showToastNotification}
                landingId={landingId}
                currentIso={intl.locale}
                filters={filters}
                fetchProductsLandingsByCenters={fetchProductsLandingsByCenters}
                lines={lines}
                productsLanding={productsLanding}
                productsLots={productsLots}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                productSelectedToAdd={productSelectedToAdd}
                setProductSelectedToAdd={setProductSelectedToAdd}
                sortSelected={sortSelected}
                selectedSort={selectedSort}
                selectFilter={selectFilter}
                unselectFilters={unselectFilters}
                filtersSelected={filtersSelected}
                landingData={landingData}
                commonParamsAnalytics={commonParamsAnalytics}
                priceList={priceList}
                userPriceList={userPriceList}
                selectedCenter={selectedCenter}
                userType={userType}
                {...props}
            />
        )
    }

    return null;
}

export default injectIntl(LandingTemplates)
