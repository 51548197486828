import Select from 'react-select'
import React from 'react'
import Translation from '../global/Translation'

import { connect } from 'react-redux'
import { getCurrentSegmentation, getSelectedChannel, getChannelOptionsV3 } from '../../store/segmentation/reducers'
import { toggleSegmentation, selectChannel } from '../../store/segmentation/actions'
import SegmentationTranslation from './SegmentationTranslation'
const mapStateToProps = state => {
    return { show: getCurrentSegmentation(state) === 'CHANNEL', selected: getSelectedChannel(state), options: getChannelOptionsV3(state) }
}
const mapDispatchToProps = dispatch => {
    return {
        onToggle: () => {
            dispatch(toggleSegmentation('CHANNEL'))
        },
        onSelect: (value, valueName) => {
            dispatch(selectChannel(value, valueName))
        },
        onSelectRetail: value => {
            dispatch({ type: 'CLEAR_SEGMENTATION' })
        }
    }
}

const retailChannel = ['A0', 'D0']
const nonRetailChannel = ['B0', 'C0', 'HE', 'DA', 'BB', 'BD', 'HO']

class ChannelV4 extends React.Component {
    state = { selected: null, selectedSegment: 'retail' }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.options && prevProps.selected !== this.props.selected) {
            const channel = this.props.selected
            const retail = retailChannel.includes(channel) ? 'retail' : 'no_retail'
            this.setState({ selectedSegment: retail })
            // console.log('PREV PROPS UPDATEEEEEED!', this.props.selected)
            // console.log('PREV PROPS UPDATEEEEEED!', this.state.selectedSegment)
            // console.log('PROPS UPDATEEEEEED!', this.props.selected)
            // console.log('STATE UPDATEEEEEED!', this.state.selected)
            // this.setState({selected: channel})
            // if (this.props.options[channel]) this.props.onSelect(channel, this.props.options[channel].name)
        }
    }

    render() {
        const { selected, onSelect, options } = this.props

        if (options === undefined) {
            return ''
        }
        let retailOptions = []
        // console.log(Object.keys(options))
        // if(!options.includes())

        if (Object.keys(options).some(item => retailChannel.includes(item))) {
            // console.log('si tiene canales de retail')
            retailOptions.push({
                label: 'Retail',
                value: 'retail'
            })
        }

        if (Object.keys(options).some(item => nonRetailChannel.includes(item))) {
            // console.log('si tiene canales de no retail')
            retailOptions.push({
                label: 'Non Retail',
                value: 'no_retail'
            })
        }

        const showedOptions = {}
        Object.entries(options).forEach(([key, o]) => {
            if (!(key === 'HE' && showedOptions['B0']) && !(key === 'B0' && showedOptions['HE'])) {
                showedOptions[key] = o
            }

        })

        /**
         * This is for the attribute data-qas to show the typology name to the QAS Team.
         * @type {object}
         */
        const segmentationChannels = {
            A0: "kitchen-and-bath",
            D0: "home-center",
            B0: "projects",
            C0: "builder",
            HE: "projects",
            HO: "cosentino-home-owners",
            DA: "direct-accounts",
            BB: "projects",
            BD: "projects",
            '': 'no-channel'
        }

        return (
            <div>
                <div>
                    <Translation id="business_segment" defaultMessage="Bussines Segment" /> <small>{"*"}</small>
                    <div data-qa="options" className="sk-item-list mt-1" style={{ justifyContent: 'flex-start', flexWrap: 'wrap', marginTop: ".5em" }}>
                        {Object.entries(retailOptions).map((option, index) => {
                            let isActive = this.state.selectedSegment === option[1].value
                            // console.log('RETAIL:', option)
                            // console.log(this.state.selectedSegment)
                            return (
                                <div
                                    key={index}
                                    className={`segmentation-v4-box ${isActive ? 'is-active' : ''}`}
                                    data-qa="option"
                                    data-key={option[0]}
                                    onClick={() => {
                                        this.setState({ selectedSegment: option[1].value }, () => this.props.onSelectRetail())
                                    }}
                                    data-qas={option[1].value === 'retail' ? 'global_segmentation-bussiness-segment-retail' : 'global_segmentation-bussiness-segment-non-retail'}
                                >
                                    <div data-qa="label" className="sk-item-list-option__text">
                                        {
                                            option[1].value === 'retail' ?
                                                <Translation id="retail" defaultMessage="Retail" />
                                                :
                                                <Translation id="non-retail" defaultMessage="Non Retail" />
                                        }
                                        {/*<span>{option[1].label}</span>*/}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/* <hr /> */}
                <div>
                    <Translation id="typology" defaultMessage="Typology" /> <small>{"*"}</small>
                    <div data-qa="options" className="sk-item-list mt-1" style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                        {Object.entries(showedOptions)
                            .filter(([channel, option]) => (this.state.selectedSegment == 'retail' ? retailChannel.includes(channel) : !retailChannel.includes(channel)))
                            .map(([channel, option], index) => {
                                let isActive = selected === channel
                                let channelName = segmentationChannels[channel]

                                return (
                                    <div
                                        key={index}
                                        className={`segmentation-v4-box channel-icon ${isActive ? 'is-active' : ''}`}
                                        data-qa="option"
                                        data-key={channel}
                                        data-qas={'global_segmentation-typology-' + channelName}
                                        onClick={() => {
                                            this.setState({ selected: channel }, () => onSelect(channel, option.name))
                                            // onSelect(channel, option.name)
                                            // onSelect(channel, option.name, option.subchannelProgram, option.businessSegment, option.subchannelId)
                                        }}
                                    >
                                        <div data-qa="label" className="sk-item-list-option__text" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <img
                                                src={require(`../../assets/img/icons/${channel}.svg`)}
                                                style={{ background: 'black', width: '24px', height: '24px', borderRadius: '100%', marginRight: '8px' }}
                                            />
                                            &nbsp;
                                            <SegmentationTranslation channel={channel} />
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
                {/* <div className="">
                    <Select
                        placeholder={<Translation id="channel" defaultMessage="Channel" />}
                        // options={[
                        //     {
                        //         label: options.projectName ? options.projectName : 'PROJECT',
                        //         value: options.projectId
                        //     }
                        // ]}
                        options={Object.entries(options).map(option => ({
                            label: option[1].name,
                            value: option[0]
                        }))}
                        value={selected}
                        onChange={option => option && onSelect(option.value, option.label, option.subchannelProgram, option.businessSegment, option.subchannelId)}
                        clearable={false}
                    />
                </div> */}

                {/* <hr /> */}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChannelV4)
