import React from 'react'
import Translation from '../global/Translation'

import { FormattedHTMLMessage, injectIntl } from 'react-intl'

import ProgressBar from '../global/ProgressBar'
import ShippingMethodsContainer from '../shipping-method-selector/ShippingMethodsContainer'
import Alerts from '../alerts/Alerts'
import MoreInfo from '../global/MoreInfo'
import iconTabla from '../../assets/img/icons/tabla.svg'
import iconMedioCamion from '../../assets/img/icons/medio_camion.svg'
import iconCamionCompleto from '../../assets/img/icons/camion_completo.svg'
import iconTriangle from '../../assets/img/icons/triangle.svg'
import IncentivesContainer from './IncentivesContainer'
import iconCamion from '../../assets/img/icons/camion.svg'
import LocalizedLink from '../global/LocalizedLink'
import IncentivesMktools from "./IncentivesMktools";
import IncentivesWeights from "./IncentivesWeights";
const Incentives = ({
    current,
    goalMin,
    goalMax,
    unit,
    shippingMethod,
    hasIncentives,
    intl,
    isLowesCenter,
    isMktoolCenter,
    total,
    maxBudget,
    mktoolsBudgetWasted,
    coin,
    isIkeaCenter,
    center,
    isCartMallOnly,
    salesOrg,
    newVersion
}) => {
    const message = shippingMethod === 'SINGLE_SLAB' ? 'for_full_truck' : 'min_to_checkout'
    // const message = hasIncentives && shippingMethod === 'SINGLE_SLAB' ? 'and_get_discount' : defaultMessage
    const difCurrent = current <= goalMax ? 0 : current - goalMax
    const difCurrentForBudget = maxBudget * 0.15 // total <= maxBudget + 20000 ? 20000 : total - maxBudget

    const errorMessage = difCurrent !== 0 ? 'MAX_WEIGHT_EXCEEDED' : null
    const icons = {
        FULL_TRUCK: 'icon fal fa-truck',
        DIRECT_TRUCK: 'icon fal fa-truck',
        CONTAINER: 'icon fal fa-ship',
        SINGLE_SLAB: 'icon fal fa-rectangle-wide',
        ENDI_TRUCK: 'icon fal fa-truck',
        ENDI_CONTAINER: 'icon fal fa-ship'
    }
    const haveWeights = ['FULL_TRUCK', 'DIRECT_TRUCK', 'CONTAINER', 'SINGLE_SLAB', 'ENDI_CONTAINER', 'ENDI_TRUCK']

    if (shippingMethod === 'CONTAINER' && center == 7500) {
        return null
    }
    return (
        !newVersion ? (
            <div className="incentive-container">
                <div className="container">
                    <div className={shippingMethod === 'CONTAINER' ? 'incentive mini row full-container' : 'incentive mini row'}>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-2 offset-md-3 col-2 offset-1">
                                    <i className={icons[shippingMethod]} />
                                </div>
                                <div className="col-md-4 col-8">{isIkeaCenter ? 'IKEA' : !isLowesCenter && <ShippingMethodsContainer />}</div>
                            </div>
                        </div>
                        {haveWeights.includes(shippingMethod) && !isIkeaCenter ? (
                            <div className="col-12">
                                <ProgressBar
                                    config={[
                                        '0 kg.',
                                        'lightgray',
                                        `${goalMin} ${unit.toLowerCase()}`,
                                        'green',
                                        `${goalMax} ${unit.toLowerCase()}`,
                                        'red',
                                        `${goalMax + difCurrent}${unit.toLowerCase()}`
                                    ]}
                                    difCurrent={difCurrent}
                                    value={current}
                                />
                            </div>
                        ) : null}
                        {isMktoolCenter && (
                            <React.Fragment>
                                <div className="col-12">
                                    <ProgressBar
                                        // config={['0€', 'lightgray', `${0} `, 'green', `${maxBudget}`, 'red', `${maxBudget + difCurrentForBudget}`]}
                                        config={['0', 'lightgray', `${mktoolsBudgetWasted}`, 'green', `${maxBudget}`, 'red', `${maxBudget + difCurrentForBudget}`]}
                                        showLabels={true}
                                        value={total}
                                        coin={coin}
                                        difCurrent={difCurrent}
                                    />
                                </div>
                                <div className="col-12" style={{ paddingBottom: '30px' }}>
                                    <MoreInfo>
                                        <Translation
                                            id="gray_Budget_consumed_green_budget_to_consume_red_out_of_budget"
                                            defaultMessage="(Grey) Consumed budget / (Green) Remain budget / (Red) Out of budget"
                                        />
                                    </MoreInfo>
                                </div>
                            </React.Fragment>
                        )}

                        {haveWeights.includes(shippingMethod) && !isIkeaCenter && !(isCartMallOnly && ['2011', '2032'].includes(salesOrg)) ? (
                            <div className="col-12">
                                {errorMessage ? (
                                    <Alerts
                                        alert={errorMessage}
                                        values={{
                                            shippingMethod: intl.formatMessage({ id: shippingMethod }).toLowerCase()
                                        }}
                                        status={404}
                                    />
                                ) : current >= goalMin ? (
                                    <span className="current">
                                    <Translation id="you_currently_have" defaultMessage="You currently have" />{' '}
                                        <b>
                                        {current} {unit.toLowerCase()}
                                    </b>{' '}
                                        <Translation id="in_your_cart" defaultMessage="in your cart" />
                                </span>
                                ) : (
                                    <div>
                                        {' '}
                                        <span className="current">
                                        <Translation id="you_currently_have" defaultMessage="You currently have" />{' '}
                                            <b>
                                            {current} {unit.toLowerCase()}
                                        </b>{' '}
                                            <Translation id="in_your_cart" defaultMessage="in your cart" />
                                    </span>{' '}
                                        .{' '}
                                        <span>
                                        <Translation id="you_must_reach" defaultMessage="You must reach" />{' '}
                                            <b>
                                            {goalMin} {unit.toLowerCase()}
                                        </b>{' '}
                                            <Translation id={errorMessage ? errorMessage : message} />
                                    </span>
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        ) : (
            <div className="incentive-container">
                <div className="container">
                    <div className={shippingMethod === 'CONTAINER' ? 'incentive mini row full-container' : 'incentive mini row'}>
                        <div style={{display: 'flex'}}>

                            {haveWeights.includes(shippingMethod) && !isIkeaCenter && !(isCartMallOnly && ['2011', '2032'].includes(salesOrg)) ? (
                              <div className="incentive-container__checkout" style={{display: "flex", flexDirection: "column"}}>
                                  <div className={'incentive-info-text'}>
                                      {errorMessage ? (
                                        <Alerts
                                          alert={errorMessage}
                                          values={{
                                              shippingMethod: intl.formatMessage({ id: shippingMethod }).toLowerCase()
                                          }}
                                          status={404}
                                        />
                                      ) : current >= goalMin ? (
                                        <span className="current">
                                    <Translation id="you_currently_have" defaultMessage="You currently have" />{' '}
                                            <b>
                                        {current} {unit.toLowerCase()}
                                    </b>{' '}
                                            <Translation id="in_your_cart" defaultMessage="in your cart" />
                                    </span>
                                      ) : (
                                        <div>
                                            {' '}
                                            <span className="current">
                                        <Translation id="you_currently_have" defaultMessage="You currently have" />{' '}
                                                <b>
                                            {current} {unit.toLowerCase()}
                                        </b>{' '}
                                                <Translation id="in_your_cart" defaultMessage="in your cart" />
                                    </span>{' '}
                                            .{' '}
                                            <span>
                                        <Translation id="you_must_reach" defaultMessage="You must reach" />{' '}
                                                <b>
                                            {goalMin} {unit.toLowerCase()}
                                        </b>{' '}
                                                <Translation id={errorMessage ? errorMessage : message} />
                                    </span>
                                        </div>
                                      )}
                                  </div>
                                  <div style={{flex: 1}}></div>
                                  {
                                    !isMktoolCenter &&
                                    <React.Fragment>
                                        <IncentivesWeights isMobile={true} shippingMethod={shippingMethod} goalMax={goalMax} goalMin={goalMin} unit={unit} haveWeights={haveWeights} isLowesCenter={isLowesCenter} isIkeaCenter={isIkeaCenter} isMktoolCenter={isMktoolCenter} difCurrent={difCurrent} current={current}/>
                                        <div className={'incentive-buttons'} style={{display: "flex", flexWrap: "wrap", gap: "1em"}}>
                                            <div className='d-flex'>
                                                <LocalizedLink style={{ border: '1px solid black', padding: '0.5rem 1rem', cursor: 'pointer' }} routeId="ROUTE_CATALOG" queryString="?type[0]=TABLA" params={{ outlet: '' }} gtmLabel="ec_funnel_compra_carrito_añadir">
                                                    <Translation id={'add_more_items'} defaultMessage={'Add more items'} />
                                                </LocalizedLink>
                                            </div>
                                            {!isLowesCenter && (
                                              <div className="incentive-select-shipping"><ShippingMethodsContainer v2={true}/></div>
                                            )}
                                        </div>
                                    </React.Fragment>
                                  }
                              </div>

                            ) : null}
                                {
                                  !isMktoolCenter &&
                                  <IncentivesWeights shippingMethod={shippingMethod} goalMax={goalMax} goalMin={goalMin} unit={unit} haveWeights={haveWeights} isLowesCenter={isLowesCenter} isIkeaCenter={isIkeaCenter} isMktoolCenter={isMktoolCenter} difCurrent={difCurrent} current={current}/>
                                }

                                {isMktoolCenter && (
                                  <IncentivesMktools mktoolsBudgetWasted={mktoolsBudgetWasted} maxBudget={maxBudget} difCurrentForBudget={difCurrentForBudget} total={total} coin={coin}/>

                                )}



                        </div>
                    </div>
                </div>
            </div>
        )
        // ) : (
        //     <div className="incentive-container">
        //         <div className={shippingMethod === 'CONTAINER' ? 'incentive mini row full-container' : 'incentive mini row'}>
        //             {haveWeights.includes(shippingMethod) && !isIkeaCenter && !(isCartMallOnly && ['2011', '2032'].includes(salesOrg)) ? (
        //                 <div className="col-12">
        //                     {errorMessage ? (
        //                         <Alerts
        //                             alert={errorMessage}
        //                             values={{
        //                                 shippingMethod: intl.formatMessage({ id: shippingMethod }).toLowerCase()
        //                             }}
        //                             status={404}
        //                         />
        //                     ) : current >= goalMin ? (
        //                         <span className="current">
        //                         <Translation id="you_currently_have" defaultMessage="You currently have" />{' '}
        //                             <b>
        //                             {current} {unit.toLowerCase()}
        //                         </b>{' '}
        //                             <Translation id="in_your_cart" defaultMessage="in your cart" />
        //                     </span>
        //                     ) : (
        //                         <div className='icentives-title'>
        //                             <img alt="" className="icon" src={iconCamion} />
        //                             <FormattedHTMLMessage
        //                                 id='you_are_only_missingkg_to_get_to_half_truck_discount_and_kg_for_one_complete'
        //                                 defaultMessage='You are only missing <b>{middle}</b> to get to <b>half truck discount</b> and <b>{complete}</b> for one <b>complete.</b>'
        //                                 values={{
        //                                     middle: `${(goalMax / 2) - current} ${unit.toLowerCase()}`,
        //                                     complete: `${goalMax - current} ${unit.toLowerCase()}`
        //                                 }} />
        //                         </div>
        //                     )}
        //                 </div>
        //             ) : null}
        //             {/*{total && (*/}
        //                 <ProgressBar
        //                     config={['0', 'lightgray', `${mktoolsBudgetWasted}`, 'green', `${maxBudget}`, 'red', `${maxBudget + difCurrentForBudget}`]}
        //                     showLabels={true}
        //                     value={total}
        //                     current={current}
        //                     goalMax={goalMax}
        //                     unit={unit.toLowerCase()}
        //                     coin={coin}
        //                     newVersion={true}
        //                 />
        //             {/*)}*/}
        //         </div>
        //         <div className='add_more_items'>
        //             <LocalizedLink routeId="ROUTE_CATALOG" queryString="?type[0]=TABLA" params={{ outlet: '' }}>
        //                 <Translation id='add_more_items' defaultMessage='Add more items' /> <i style={{fontSize: '11px', marginLeft: '8px'}} className="fal fa-chevron-right" />
        //             </LocalizedLink>
        //         </div>
        //     </div>
        // )
    )
}
export default injectIntl(Incentives)
