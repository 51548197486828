import React, { useEffect, useState } from 'react'
import FiltersModal from "../../../global/Filters/FiltersModal";
import Translation from "../../../global/Translation";
import DatePicker from "react-datepicker";
import moment from "moment";
import {getCenters, getIsIkeaCenter} from "../../../../store/centers/reducers";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import FilterTags from "../../../global/Filters/FilterTags";
import debounce from "lodash/debounce";
import {cloneDeep} from "lodash";

let debSearch

const ShippingHistoryFilter = props => {

    const shippingTypeOptions = {
        empty: {label: props.intl.formatMessage({id: 'view_all'}), value: false, isSelected: true},
        CONTAINER: {
            value: 'CONTAINER',
            label: <Translation id="CONTAINER" defaultMessage="Container" />,
            isSelected: false
        },
        SINGLE_SLAB: {
            value: 'SINGLE_SLAB',
            label: <Translation id="SINGLE_SLAB" defaultMessage="Single slab" />,
            isSelected: false
        },
        DIRECT_TRUCK: {
            value: 'DIRECT_TRUCK',
            label: <Translation id="DIRECT_TRUCK" defaultMessage="Direct truck" />,
            isSelected: false
        },
        FULL_TRUCK: {
            value: 'FULL_TRUCK',
            label: <Translation id="FULL_TRUCK" defaultMessage="Full truck" />,
            isSelected: false
        }
    }

    const statusTypeOptions = {
        empty: {label: props.intl.formatMessage({id: 'view_all'}), value: false, isSelected: true},
        NOT_TREATED: {label: props.intl.formatMessage({ id: 'planned_transportation' }), value: 'NOT_TREATED', isSelected: false },
        TREATED_PARTIALLY: {label: props.intl.formatMessage({ id: 'order_status_shipped' }), value: 'TREATED_PARTIALLY', isSelected: false },
        TREATED_COMPLETELLY: {label: props.intl.formatMessage({ id: 'order_status_delivered' }), value: 'TREATED_COMPLETELLY', isSelected: false }
    }

    const [search, setSearch] = useState('')

    const dateFilterMapper = {
        output: {
            startDate: null,
            endDate: null
        },
        prevOutput: {
            startDate: null,
            endDate: null
        },
        onChange: {
            changeStartDate: selectedOption => dateFilterMapper.output.startDate = selectedOption,
            changeEndDate: selectedOption => dateFilterMapper.output.endDate = selectedOption
        }
    }

    const formatDateLabel = (output) => {
        const startLabel = `${output.startDate && !output.endDate ? props.intl.formatMessage({ id: 'from'}) + ' ' : ''}${output.startDate ? output.startDate.format('L') : ''}`
        const endLabel = `${output.startDate && output.endDate ? ' - ' : ''}${!output.startDate && output.endDate ? props.intl.formatMessage({ id: 'to'}) + ' ' : ''}${output.endDate ? output.endDate.format('L') : ''}`

        const label = startLabel + endLabel
        return label === '' ? 'all' : label
    }

    const DateFilter = (mapper, _filters) => {

        const startMaxDate = mapper.output.endDate ? mapper.output.endDate : moment().add(12, 'month')
        const endMaxDate = props.isIkeaCenter ? moment(mapper.output.startDate).add(3, 'month')
            : //RESTO DE CASOS 12 MESES
            moment(mapper.output.startDate).add(12, 'month')

        return <React.Children mapper={mapper}>

            <div className="order__shipping__date__section">
                <div className="order__shipping__date__section--dates">
                    <div className="from">
                        <label htmlFor='from_shipping_date'>
                            <Translation id="from" defaultMessage="From"/>
                        </label>
                        <DatePicker
                            id={'from_shipping_date'}
                            selected={mapper.output.startDate}
                            selectsStart={true}
                            startDate={mapper.output.startDate}
                            endDate={mapper.output.endDate}
                            maxDate={startMaxDate}
                            onChange={selectedOption => {
                                mapper.onChange.changeStartDate(selectedOption, 'startDate')
                                _filters.filters.custom_date_filter.label = formatDateLabel(mapper.output)
                                _filters.filters.custom_date_filter.isSelected = false
                            }}
                            autoComplete="off"
                            onKeyDown={e => e.preventDefault()}
                            showMonthDropdown
                            showYearDropdown
                            isClearable={true}
                            className="form-control form-control-sm date customDatePicker"
                        />
                    </div>
                    <div className="to">
                        <label htmlFor='to_shipping_date'>
                            <Translation id="to" defaultMessage="To"/>
                        </label>
                        <DatePicker
                            id={'to_shipping_date'}
                            selected={mapper.output.endDate}
                            selectsEnd
                            startDate={mapper.output.startDate}
                            endDate={mapper.output.endDate}
                            minDate={mapper.output.startDate}
                            maxDate={endMaxDate}
                            onChange={selectedOption => {
                                mapper.onChange.changeEndDate(selectedOption, 'endDate')
                                _filters.filters.custom_date_filter.label = formatDateLabel(mapper.output)
                                _filters.filters.custom_date_filter.isSelected = !!(mapper.output.startDate && mapper.output.endDate)
                            }}
                            autoComplete="off"
                            onKeyDown={e => e.preventDefault()}
                            showMonthDropdown
                            showYearDropdown
                            isClearable={true}
                            className="form-control form-control-sm date customDatePicker"
                        />
                    </div>
                </div>
            </div>
        </React.Children>
    }

    const initialFilters = {
        by_date_filter: {
            label: props.intl.formatMessage({ id: 'by_estimated_delivery_date' }),
            id: 'by_date_filter',
            filters: {
                custom_date_filter : {
                    id: 'custom_date_filter',
                    label: 'all',
                    defaultLabel: 'all',
                    value: cloneDeep(dateFilterMapper.output),
                    mapper: cloneDeep(dateFilterMapper),
                    custom: DateFilter,
                    isSelected: false,
                    defaultValue: {
                        startDate: null,
                        endDate: null,
                    },
                    getIsSelected: (output) => !!(output.startDate || output.endDate)
                }
            }
        },
        status_type_filter: {
            label: props.intl.formatMessage({ id: 'status_type_filter' }),
            id: 'status_type_filter',
            filters: statusTypeOptions
        },
        shipping_type_filter: {
            label: props.intl.formatMessage({ id: 'shipping_type_filter' }),
            id: 'shipping_type_filter',
            filters: shippingTypeOptions
        }
    }

    const [filters, setFilters] = useState(cloneDeep(initialFilters))
    const [selections, setSelections] = useState({})

    const changeDateFilter = type => {
        let endDate = moment()
        switch (type) {
            case 'arrive_today':
                filters.by_date_filter.filters.custom_date_filter.value.endDate = endDate
                break
            case 'arrive_this_week':
                endDate = endDate.add(7, 'days')
                filters.by_date_filter.filters.custom_date_filter.value.endDate = endDate
                break
            case 'arrive_this_month':
                endDate = endDate.add(30, 'days')
                filters.by_date_filter.filters.custom_date_filter.value.endDate = endDate
                break
            case 'all':
                break
        }

        const startDate = type !== 'all' ? moment() : null
        filters.by_date_filter.filters.custom_date_filter.label = type
        filters.by_date_filter.filters.custom_date_filter.isSelected = type !== 'all'
        filters.by_date_filter.filters.custom_date_filter.value.startDate = startDate

        // Actualizar mappers
        filters.by_date_filter.filters.custom_date_filter.mapper.output.startDate = startDate
        filters.by_date_filter.filters.custom_date_filter.mapper.output.endDate = endDate
        filters.by_date_filter.filters.custom_date_filter.mapper.prevOutput.startDate = startDate
        filters.by_date_filter.filters.custom_date_filter.mapper.prevOutput.endDate = endDate

        setFilters(Object.assign({}, filters))
    }

    const dateFilterActive = type => filters && filters.by_date_filter && filters.by_date_filter.filters.custom_date_filter.label === type

    useEffect(() => {
        const selections = Object.entries(filters).reduce(
            (p, [k, v]) => ({
                ...p, [k]: Object.entries(v.filters).reduce((_p, [_k, _v]) => ({..._p, [_k]: _v.isSelected}), {})
            }), {}
        )
        const selected = {}
        Object.entries(selections).forEach(([k, v]) => {
            const positives = Object.keys(v).filter(_k => !!v[_k])
            positives.forEach(v => {
                if (filters[k] && filters[k].filters[v].value) {
                    selected[k] = {id:v, value: filters[k].filters[v].value}}
            })
        })

        setSelections(selected)
    }, [filters])

    useEffect(() => {
        if (debSearch) {
            debSearch.cancel()
        }
        debSearch = debounce(() => {
            if ((search && search.length > 2) || !search || search.length === 0) {
                props.onChange({search, selections})
            }
        }, 1000)

        debSearch()
    }, [search, selections])


    const searchBar = (
        <input
            type="text"
            data-qa="query"
            placeholder={props.intl.formatMessage({ id: 'search' })}
            className="form-control"
            value={search}
            onChange={e => setSearch(e.target.value)}
            gtm-label="ec_historial_envios_barra_busqueda"
        />
    )

    const onClean = () => {
        setFilters(cloneDeep(initialFilters))
    }

    useEffect(() => {
        changeDateFilter('arrive_this_week')
    }, [])
    
    return (
        <React.Fragment>
            <div className="header__filters__heading">
                <h4><Translation id="filter_by_estimated_delivery_date" defaultMessage="Filter by estimated delivery date" />:</h4>
            </div>
            <div className="header__filters">
                <nav className="header__filters__buttons">
                    <div className={`shipping-walkthrough-filter-quick header__filters__buttons--btn ${dateFilterActive('arrive_today') ? 'active' : ''}`}>
                        <button onClick={() => changeDateFilter('arrive_today')}>
                            <Translation id={'arrive_today'} defaultMessage={'Arrive today'}/>
                        </button>
                    </div>
                    <div className={`header__filters__buttons--btn ${dateFilterActive('arrive_this_week') ? 'active' : ''}`}>
                        <button onClick={() => changeDateFilter('arrive_this_week')}>
                            <Translation id={'arrive_this_week'} defaultMessage={'Arrive this week'}/>
                        </button>
                    </div>
                    <div className={`header__filters__buttons--btn ${dateFilterActive('arrive_this_month') ? 'active' : ''}`}>
                        <button onClick={() => changeDateFilter('arrive_this_month')}>
                            <Translation id={'arrive_this_month'} defaultMessage={'Arrive this month'}/>
                        </button>
                    </div>
                    <div className={`header__filters__buttons--btn ${dateFilterActive('all') ? 'active' : ''}`}>
                        <button onClick={() => changeDateFilter('all')}>
                            <Translation id={'all'} defaultMessage={'All'}/>
                        </button>
                    </div>
                    <div className="header__filters__buttons--btn-more">
                        <FiltersModal
                            title={props.intl.formatMessage({ id: 'filters' })}
                            filters={filters}
                            customClassName="order__shipping__filter__modal"
                            onChange={_filters => setFilters(_filters)}
                            onClean={onClean}
                            gtmLabel="ec_historial_envios_mas_filtros"
                            gtmLabelModal="ec_historial_envios_filtros_popup"
                            gtmLabelApplyFilters="ec_historial_envios_filtros_aplicar"
                        />
                    </div>
                </nav>
                {/* Desktop search */}
                <div className="filters__search__desktop">
                    <div className="filters__search__desktop--input">{searchBar}</div>
                </div>
            </div>
            {/* Responsive search */}
            <div className="filters__search__responsive">
                <div className="flex__container">
                    <div className="flex__container--input">{searchBar}</div>
                    {/* For mobile screen */}
                    <div className="flex__container--btn">
                        <FiltersModal
                            title={props.intl.formatMessage({ id: 'filters' })}
                            filters={filters}
                            customClassName="order__shipping__filter__modal"
                            onChange={_filters => setFilters(_filters)}
                            onClean={onClean}
                            gtmLabel="ec_historial_envios_mas_filtros"
                            gtmLabelModal="ec_historial_envios_filtros_popup"
                            gtmLabelApplyFilters="ec_historial_envios_filtros_aplicar"
                        />
                    </div>
                </div>
            </div>

            <div className="order__history__header--filter-tags">
                <FilterTags
                    filters={filters}
                    onChange={_filters => setFilters(_filters)}
                    onClean={onClean}
                />
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        isIkeaCenter: getIsIkeaCenter(state),
        centers: getCenters(state),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(ShippingHistoryFilter)
)