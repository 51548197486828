import React from "react";
import Translation from "../global/Translation";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {defineMessages, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {setAllLinesDeliveryDates, unsetAllLinesDeliveryDates} from "../../store/cart/actions";

defineMessages({
    NOT_CHOOSE_ALL_ITEMS_DELIVERY_DATE: {
        id: 'NOT_CHOOSE_ALL_ITEMS_DELIVERY_DATE',
        description: 'NOT_CHOOSE_ALL_ITEMS_DELIVERY_DATE',
        defaultMessage: 'No, I want the products to be delivered according to the estimated delivery date'
    },
    CHOOSE_ALL_ITEMS_DELIVERY_DATE: {
        id: 'CHOOSE_ALL_ITEMS_DELIVERY_DATE',
        description: 'CHOOSE_ALL_ITEMS_DELIVERY_DATE',
        defaultMessage: 'Yes, choose the desired delivery date for all products'
    }
})



class SlaDateSelect extends React.Component {

    DATE_OPTIONS = [
        {value:0, label:this.props.intl.formatMessage({id:"NOT_CHOOSE_ALL_ITEMS_DELIVERY_DATE"})},
        {value:1, label:this.props.intl.formatMessage({id:"CHOOSE_ALL_ITEMS_DELIVERY_DATE"})}
    ]

    state = {
        selectedDateOption: 0
    }

    onChangeOption = (option) => {
        if (option.value !== this.state.selectedDateOption) {
            if (option.value === 0) {
                this.props.selected && this.props.unsetAllLinesDeliveryDates()
            }
            else {
                this.props.selected && this.props.setAllLinesDeliveryDates(this.props.selected)
            }
            this.setState({selectedDateOption:option.value})
        }
    }

    onChangeDate = (date) => {
        this.props.setAllLinesDeliveryDates(date)
        this.props.onChange(date)
    }

    render() {

        return (
            <div className="form-group select_date">

                <label htmlFor="checkout__select_all_items_preferred_delivery_date">
                    <Translation id="want_to_change_delivery_date"
                                 defaultMessage="Do you want to modify the delivery date for all products?" /><br/>
                    <small>
                        <Translation id="remember_can_change_delivery_date_individually"
                                     defaultMessage="* Remember that you can modify desired date individually" />
                    </small>
                </label>

                <Select
                    style={{fontSize:'14px'}}
                    id="checkout__select_all_items_preferred_delivery_date"
                    className="form-control"
                    value={this.DATE_OPTIONS[this.state.selectedDateOption]}
                    onChange={this.onChangeOption}
                    options={this.DATE_OPTIONS}
                    clearable={false}
                />

                {
                    this.state.selectedDateOption === 1 && (
                        <div style={{display:"flex", columnGap: "8px", marginTop:"8px"}}>
                            {/*<label htmlFor="all_items_sla_datepicker">*/}
                            {/*    <Translation id="required_delivery_date" defaultMessage="Required delivery date" />*/}
                            {/*</label>*/}
                            <DatePicker
                                id="all_items_sla_datepicker"
                                selected={this.props.selected}
                                onChange={date => this.onChangeDate(date)}
                                minDate={this.props.minDate}
                                className="form-control datepicker"
                                autoComplete="off"
                                onKeyDown={e => e.preventDefault()}
                                dropdownMode="select"
                            />
                            <div className="prefered-date-advertisement">
                                <i className="mt-1 far fa-exclamation-triangle fa-2x flex-grow-1"/>
                                <Translation id="all_items_desired_date_advertisement"
                                             defaultMessage="This date affects all items in your order. Remember that you can modify the dates individually"/>
                            </div>
                        </div>
                    )
                }

            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        unsetAllLinesDeliveryDates: () => dispatch(unsetAllLinesDeliveryDates()),
        setAllLinesDeliveryDates: deliveryDate => dispatch(setAllLinesDeliveryDates(deliveryDate))
    }
}

export default injectIntl(connect(
    null,
    mapDispatchToProps
)(SlaDateSelect))