import React from 'react'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import NewOrderCardItem from './NewOrderCardItem'
import { SliderNextArrow, SliderPrevArrow } from '../Utils'
import Slider from 'react-slick'
import Translation from '../../../global/Translation'
import Button from '../../../global/Button'
import Alerts from '../../../alerts/Alerts'

class NewOrderCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showMoreProducts: false
        }
    }

    showBtnToDetails = order => {
        if (order.orderId && order.reservationId && order.orderLines.length > 0 && !this.props.isIkeaCenter) return true
        return false
    }

    render() {
        const { order, orderStatusMap, intl, isIkeaCenter, redirect, adaptedFormat, replaceDotWithComma, locale, getMeasure } = this.props

        const showMoreLessProducts = () => {
            this.setState({ showMoreProducts: !this.state.showMoreProducts })
        }

        const positionsMap =
            order &&
            order.orderLines &&
            order.orderLines.length > 0 &&
            order.orderLines.map((orderLine, idx) => {
                return (
                    order.orderId !== null ? (
                        <NewOrderCardItem
                            key={idx}
                            order={order}
                            orderLine={orderLine}
                            orderStatusMap={orderStatusMap}
                            adaptedFormat={adaptedFormat}
                            isIkeaCenter={isIkeaCenter}
                            redirect={redirect}
                            intl={intl}
                            locale={locale}
                            replaceDotWithComma={replaceDotWithComma}
                            getMeasure={getMeasure}
                        />
                    ) : (null)
                )
            })

        const handleBtnToDetailsOnClick = () => {
            redirect(
                intl
                    .formatMessage({ id: 'ROUTE_ORDER_DETAILS' })
                    .replace(':filter', 'view-order')
                    .replace(':reservationId', order.reservationId)
            )
        }

        const sliderSettings = {
            arrows: true,
            nextArrow: <SliderNextArrow />,
            prevArrow: <SliderPrevArrow />,
            swipeToSlide: true,
            dots: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            variableWidth: false
        }

        return (
            <React.Fragment>
                {order && (
                    <div className="order__card">
                        {/* Header */}
                        <header className="order__card__header">
                            <div className="col-12 col-md-9 col-xl-9 p-0 order__card__header__info">
                                <div className="card__header__text">
                                    <div className="card__header__text--title">
                                        <span>
                                            <Translation id="status" defaultMessage="Status" />:
                                        </span>
                                        <div className="card__header__text--subtitle">
                                            {order.reservationStatus === 'PENDING_CANCELLATION' ? (
                                                <span>{intl.formatMessage({ id: orderStatusMap[order.reservationStatus].textToShow })}</span>
                                            ) : order.ecosentino_status === 'PENDING_CHANGES' ? (
                                                <span>{intl.formatMessage({ id: orderStatusMap[order.ecosentino_status].textToShow })}</span>
                                            ) : order.orderStatusV3.status ? (
                                                <span>{intl.formatMessage({ id: orderStatusMap[order.orderStatusV3.status].textToShow })}</span>
                                            ) : (
                                                <span>--</span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="card__header__text">
                                    <div className="card__header__text--title">
                                        <span>
                                            <Translation id="date" defaultMessage="Date" />
                                            :&nbsp;
                                        </span>
                                    </div>
                                    <div className="card__header__text--subtitle">
                                        {order.orderCreationDate ? (
                                            moment(order.orderCreationDate).isSame(moment(), 'day') ? (
                                                <span>
                                                    <Translation id="today" defaultMessage="Today" />
                                                </span>
                                            ) : (
                                                <span>{moment(order.orderCreationDate).format('L')}</span>
                                            )
                                        ) : order.createdDate ? (
                                            moment(order.createdDate).isSame(moment(), 'day') ? (
                                                <span>
                                                    <Translation id="today" defaultMessage="Today" />
                                                </span>
                                            ) : (
                                                <span>{moment(order.createdDate).format('L')}</span>
                                            )
                                        ) : (
                                            <span>--</span>
                                        )}
                                    </div>
                                </div>

                                <div className="card__header__text">
                                    {order.orderId ? (
                                        <React.Fragment>
                                            <div className="card__header__text--title">
                                                <span>
                                                    <Translation id="order_number" defaultMessage="Order number" />
                                                </span>
                                                :&nbsp;
                                            </div>
                                            <div className="card__header__text--subtitle">
                                                <span>{order.orderId}</span>
                                            </div>
                                        </React.Fragment>
                                    ) : order.reservationId ? (
                                        <React.Fragment>
                                            <div className="card__header__text--title">
                                                <span>
                                                    <Translation id="reservation_id" defaultMessage="Reservation ID" />
                                                </span>
                                            </div>
                                            <div className="card__header__text--subtitle">
                                                <span>{order.reservationId}</span>
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <div className="card__header__text--title">
                                                <span>
                                                    <Translation id="order_number" defaultMessage="Order number" />
                                                </span>
                                                :&nbsp;
                                            </div>
                                            <div className="card__header__text--subtitle">
                                                <span>--</span>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>

                                <div className="card__header__text">
                                    <div className="card__header__text--title">
                                        <span>
                                            <Translation id="number_of_products" defaultMessage="Number of products" />
                                        </span>
                                        :&nbsp;
                                    </div>
                                    <div className="card__header__text--subtitle">
                                        {order.orderId !== null && order.orderLines.length > 0 ? (
                                            <span>
                                                {order.orderLines.length}
                                            </span>
                                        ) : (
                                            <span>
                                                <Translation id="you_will_be_able_to_view_your_order" defaultMessage="You will be able to view your order in a few minutes" />
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* Desktop button */}
                            {this.showBtnToDetails(order) && (
                                <div className="col-12 col-md-3 col-xl-3 p-0 order__card__header--btn">
                                    <Button
                                        className="bt bt-inverted"
                                        gtmLabel="ec_historial_pedidos_detalles"
                                        onClick={() => {
                                            handleBtnToDetailsOnClick()
                                        }}
                                    >
                                        <Translation id="view_details" defaultMessage="View details" />
                                    </Button>
                                </div>
                            )}
                        </header>
                        {(order.ecosentino_status === 'PENDING_CHANGES' || order.reservationStatus === 'PENDING_CANCELLATION') && (
                            <div className="px-3 mb-3">
                                <Alerts status='warning'
                                        alert='order_in_process_of_modification_warning'
                                        cssIcon='fas fa-exclamation-triangle'
                                        iconSize='16px'
                                        className='order__alert__warning'   
                                />
                            </div>
                        )}
                        {/* Body */}
                        <section className="order__card__item--container">
                            <div className="d-none d-md-block">
                                <div className={`${this.state.showMoreProducts ? '' : 'show-more-less-order'}`}>
                                    {positionsMap}
                                </div>
                                {order.orderLines.length > 3 && (
                                    <div className="show__more__less">
                                        <button onClick={() => showMoreLessProducts()}>
                                            {this.state.showMoreProducts ? (
                                                <React.Fragment>
                                                    <span>
                                                        <Translation id="view_less" defaultMessage="View less" />
                                                    </span>
                                                    <i class="fas fa-chevron-up"></i>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <span>
                                                        <Translation id="view_all_products" defaultMessage="View all products" />
                                                    </span>
                                                    &nbsp;
                                                    <span>&#40;{order.orderLines.length}&#41;</span>
                                                    <i class="fas fa-chevron-down"></i>
                                                </React.Fragment>
                                            )}
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div className="d-block d-md-none">
                                <Slider ref={s => (this.slider = s)} {...sliderSettings}>
                                    {positionsMap}
                                </Slider>
                            </div>
                        </section>
                        {/* Responsive button */}
                        {this.showBtnToDetails(order) && (
                            <footer className="order__card__footer">
                                <div>
                                    <Button
                                        className="bt bt-inverted"
                                        gtmLabel="ec_historial_pedidos_detalles"
                                        onClick={() => {
                                            handleBtnToDetailsOnClick()
                                        }}
                                    >
                                        <Translation id="view_details" defaultMessage="View details" />
                                    </Button>
                                </div>
                            </footer>
                        )}
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default injectIntl(NewOrderCard)
