import React from 'react'
import { connect } from 'react-redux'

import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'
import LocalizedLink from '../global/LocalizedLink'

import ProductDisplayName from '../global/ProductDisplayName'
import ProductImage from '../catalog/ProductImage'
import DisplayBrand from '../catalog/rectangleResult/displays/DisplayBrand'
import DisplayOutletIndicator from '../catalog/rectangleResult/displays/DisplayOutletIndicator'
import DisplayTechnology from '../catalog/rectangleResult/displays/DisplayTechnology'
import mallLarge from '../../assets/img/logos/mall-large.jpg'

const mapStateToProps = state => {
    return {
        
    }
}

const mapDispatchToProps = dispatch => {
    return {        
        closeCrossSellinModal: () => {
            dispatch({ type: 'HIDE_CROSSELLLING_MODAL'})
        }
    }
}

class SimpleCartLine extends React.Component {
    constructor(props) {
        super(props)
        this.state = { showHelp: false, showComment: false }
    }    
    
    render() {
        let { line, type, intl } = this.props

        if (!line) return null

        let image = null

        if (line.cutToSize && line.cutToSize.thumb) {
            image = <img alt="" src={line.cutToSize.thumb} />
        } else if ((line.extra && (line.extra.type === 'wall_sample' || line.extra.type === 'custom_sample')) || (line.extra && line.extra.type === 'brochures')) {
            image = <img alt="" src={this.props.getImageBackground(line.product)} />
        } else {
            image = (
                <LocalizedLink className="cart_line__link_image" 
                    routeId="ROUTE_PRODUCT_DETAIL" 
                    params={{ id: line.product.productId }}
                    onClick={this.props.closeCrossSellinModal}
                >
                    <ProductImage product={line.product} />
                </LocalizedLink>
            )
        }

        if (!line || !line.lineId) return null

        if (line.extra && line.extra.itsFree && !this.props.canViewPricesPhase3) return null

        // let size = null
        // if (line.product && line.product.format) size = line.product.format.replace(/ *[xX] */,' x ')
        // if (size && line.product && line.product.thickness) size += ' x ' + line.product.thickness


        return (
            <div className="single-line-item row" key={line.lineId}>
                <div className="wrapper-product-image col-5">
                    {image}
                </div>

                <div className="content col-7 pl-0">

                    <div className="description-and-input">

                        <div className="mini_cart_line_title mt-2">
                            <div className="title">
                                <div className="title">
                                    <ProductDisplayName product={line.product} />
                                </div>
                                <span className="subtitle">
                                    <DisplayBrand brand={line.brand}></DisplayBrand>
                                    <span className="product-brand">&nbsp;(ID {line.productId})</span>
                                </span>
                            </div>
                        </div>
                        
                        <div className="mini_cart_line_block">
                            <div className="mini_cart_line_info">
                                
                                {line.product.finish && (
                                    <div className="mini_cart_line_info__line">
                                        <Translation id="finish" defaultMessage="Finish"/>:&nbsp;<b>{intl.formatMessage({ id: line.product.finish }).toLowerCase()}</b>
                                    </div>
                                )}
                                {line.product.zaddon === 'MAL' && (
                                    <div className="mini_cart_line_info__line">
                                        <Translation id="MAL"/>
                                    </div>
                                )}

                                {/*{size && (*/}
                                {/*    <div className="mini_cart_line_info__line">*/}
                                {/*        <Translation id="size" defaultMessage="Size"/>:&nbsp;<span style={{textTransform:'none'}}><b>{size}</b></span>*/}
                                {/*    </div>*/}
                                {/*)}*/}

                                <div className="mini_cart_line_info__line">
                                    <Translation id="added_units" defaultMessage="Added units"/>:&nbsp;<b>{line.qty}</b>
                                </div>
                                    
                                
                            </div>                            
                        </div> 
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SimpleCartLine))
