import React from 'react'

import MktProductsMaxContainer from '../components/mkt-admin/MktProductsMaxContainer'
import ErrorContainer from '../components/alerts/ErrorContainer'

const mapStateToProps = state => {
    return {
        isAdminMkt: true
    }
}

const MktAdminProductsScreen = mapStateToProps().isAdminMkt ? () => <MktProductsMaxContainer /> : () => <ErrorContainer />

export default MktAdminProductsScreen
