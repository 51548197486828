import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Translation from '../global/Translation'
import $ from 'jquery'
import visitasVirtuales from '../../assets/img/visitas_virtuales.png'
import { getVirtualVisitURL } from '../../store/login/reducers'
import { getLocalizedUrl, getIsShippingHistoryScreen, getIsOrderHistoryScreen } from '../../store/ui/reducers'
import { getInProductDetailScreen } from '../../store/ui/reducers'

defineMessages({
    tell_us_what_you_think: {
        id: 'tell_us_what_you_think',
        description: 'tell_us_what_you_think',
        defaultMessage: 'Tell us what you think'
    },
})


const mapStateToProps = (state, props ) => {
    return {
        URLVirtualVisit: getVirtualVisitURL(state),
        localizedUrl: (routeId) => getLocalizedUrl(state, props.intl, routeId),
        inProductDetailScreen: getInProductDetailScreen(state),
        isOrderHistoryScreen: getIsOrderHistoryScreen(state),
        isShippingHistoryScreen: getIsShippingHistoryScreen(state)
    }
}

class BannerVisitasVirtuales extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    showHiddenBanner() {
        if(this.props.type && this.props.type === 'v2'){
            if($('.banner-visitas-virtuales-hidden-v2').css('display') === 'none'){
                $('.banner-visitas-virtuales-hidden-v2').css('display', 'flex')
                $('.banner-visitas-virtuales-v2').css('display', 'none')
            }else{
                $('.banner-visitas-virtuales-hidden-v2').css('display', 'none')
                $('.banner-visitas-virtuales-v2').css('display', 'flex')
            }
        }else{
            if($('.banner-visitas-virtuales-hidden').css('display') === 'none'){
                $('.banner-visitas-virtuales-hidden').css('display', 'flex')
                $('.banner-visitas-virtuales').css('display', 'none')
            }else{
                $('.banner-visitas-virtuales-hidden').css('display', 'none')
                $('.banner-visitas-virtuales').css('display', 'flex')
            }
        }
    }

    render() {
        const displayBanner = this.props.inProductDetailScreen
        const isOrderHistoryScreen = this.props.isOrderHistoryScreen
        const isShippingHistoryScreen = this.props.isShippingHistoryScreen
        if(this.props.location && (this.props.location.pathname === this.props.localizedUrl('ROUTE_PROFILE').replace(':filter', 'configuration').replace('/:open?', '') ||
            this.props.location.pathname === this.props.localizedUrl('ROUTE_PROFILE').replace(':filter', 'orders').replace('/:open?', '') ||
            this.props.location.pathname === this.props.localizedUrl('ROUTE_PROFILE').replace(':filter', 'recurring-orders').replace('/:open?', '') ||
            this.props.location.pathname === this.props.localizedUrl('ROUTE_PROFILE').replace(':filter', 'segmentation').replace('/:open?', '') ||
            this.props.location.pathname === this.props.localizedUrl('ROUTE_PROFILE').replace(':filter', 'products-favourite').replace('/:open?', ''))
        ) {
            return false
        }
        return (
            <React.Fragment>
                {!displayBanner && !isOrderHistoryScreen && !isShippingHistoryScreen && (
                <div style={{
                    position: 'fixed',
                    bottom: 0,
                    right: 0
                }}>
                    <div className={this.props.type && this.props.type === 'v2' ? 'banner-visitas-virtuales-hidden-v2' : 'banner-visitas-virtuales-hidden'}>
                        <div className='banner-hidden' onClick={() => this.showHiddenBanner()}>
                            <i className='fal fa-angle-up' style={{fontSize: '1.5rem'}}/>
                        </div>
                        <div className='banner'>
                            <h3><Translation id="do_you_know_virtual_tours" defaultMessage="Do you know virtual tours?" /></h3>
                        </div>
                    </div>
                    <div className={this.props.type && this.props.type === 'v2' ? 'banner-visitas-virtuales-v2' : 'banner-visitas-virtuales'}>
                        <div className='banner-hidden' onClick={() => this.showHiddenBanner()}>
                            <i className='fal fa-angle-down' style={{fontSize: '1.5rem'}}/>
                        </div>
                        <div className='banner'>
                            <h1><Translation id="select_your_boards_virtually" defaultMessage="Select your boards virtually" /></h1>
                            <h3><Translation id="now_you_can_book_a_virtual_visit_with_our_warehouses_we_will_show_you_all_the_tables_you_want_to_see" defaultMessage="Now you can book a virtual visit with our warehouses. We will show you all the tables you want to see." /></h3>
                            <img src={visitasVirtuales} alt='' />
                            <a href={this.props.URLVirtualVisit} target="_blank">
                                <button><Translation id="reserve" defaultMessage="reserve" /></button>
                            </a>
                        </div>
                    </div>
                </div>
                )}
            </React.Fragment>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(BannerVisitasVirtuales)
)
