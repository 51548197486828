import React from 'react'

const Promotion = ({ landingData }) => (
    <div className="row">
        <div className="col-12">
            <div className="mall__quote">
                <i className="fas fa-quote-left mall__quote__open"></i>
                <p className="mall__quote__text" dangerouslySetInnerHTML={{ __html: landingData.promotion }} />
                <i className="fas fa-quote-right mall__quote__close"></i>
            </div>
        </div>
    </div>
);

export default Promotion;

