import React from 'react'
import CentersV2 from './CentersV2'
import Centers from './Centers'

class CentersSelectorRedesign extends React.Component {
    render() {
        return this.props.newVersion && !this.props.customOldVersion ? <CentersV2 {...this.props} /> : <Centers {...this.props} />
    }
}

export default CentersSelectorRedesign
