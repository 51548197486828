import { getIsLogged } from '../login/reducers'

export const initState = {
    currentSegmentation: 'BUSINESS_SEGMENT',
    segmentatingProductId: null,
    segmentatingProductIsOutlet: null,
    currentFavourite: null,
    segmentationSubmitted: false,
    businessSegment: null,
    channel: null,
    originalChannel: null,
    channelName: null,
    originalChannelName: null,
    subchannel: null,
    subchannelName: null,
    salesForceId: null,
    salesForceName: null,
    projectId: null,
    projectName: null,
    subchannelProgram: null,
    typology: null,
    typologyName: null,
    use: null,
    useName: null,
    segmentationOptions: [],
    kb: null,
    builder: null,
    po: [],
    useOptions: [],
    typologiesV3: [],
    kbOptions: [],
    buildersOptions: [],
    isSaving: false,
    fetchingSegmentation: false,
    segmentationV3: null
}

const segmentation = (state = initState, action) => {
    switch (action.type) {
        case 'SAVE_CURRENT_FAVOURITE_REQUEST':
            return Object.assign({}, state, { isSaving: true })
        case 'SAVE_CURRENT_FAVOURITE_SUCCESS':
        case 'SAVE_CURRENT_FAVOURITE_FAILURE':
            return Object.assign({}, state, { isSaving: false })
        case 'ADD_CART_LINE_SUCCESS':
        case 'ADD_CART_BULK_LOTS_SUCCESS':
            return Object.assign({}, state, { po: [] })
        case 'SET_LANGUAGE':
        case 'SIMPLE_IMPERSONATE_SUCCESS':
        case 'LOGOUT':
            return Object.assign({}, state, initState)
        case 'CHANGE_CENTER':
        case 'CLEAR_SEGMENTATION':
            return Object.assign({}, state, {
                currentFavourite: null,
                businessSegment: null,
                channel: null,
                channelName: null,
                subchannel: null,
                subchannelName: null,
                salesForceId: null,
                salesForceName: null,
                projectId: null,
                projectName: null,
                typology: null,
                typologyName: null,
                use: null,
                useName: null,
                kb: null,
                builder: null,
                po: []
            })
        case 'RESET_SEGMENTATION':
            return Object.assign({}, state, { segmentationSubmitted: false })
        case 'SUBMIT_SEGMENTATION_REQUEST':
            return Object.assign({}, state, {})
        case 'SHOW_SEGMENTATION_MODAL':
            return Object.assign({}, state, { segmentatingProductId: action.productId, segmentatingProductIsOutlet: action.outlet })

        case 'SUBMIT_SEGMENTATION_SUCCESS':
            return Object.assign({}, state, { segmentationSubmitted: true, showSegmentationModal: false })
        case 'SUBMIT_SEGMENTATION_FAILURE':
            return Object.assign({}, state, { segmentationSubmitted: false, showSegmentationModal: true })
        case 'SELECT_SEGMENTATION_A':
            return Object.assign({}, state, { selectedA: action.selected })
        case 'TOGGLE_SEGMENTATION':
            if (state.currentSegmentation === action.seg) {
                return Object.assign({}, state, { currentSegmentation: null })
            }
            return Object.assign({}, state, { currentSegmentation: action.seg })
        case 'LOAD_SEGMENTATION_PREFERED_REQUEST':
            return Object.assign({}, state, {})
        case 'LOAD_SEGMENTATION_PREFERED_FAILURE':
            return Object.assign({}, state, {})
        case 'LOAD_SEGMENTATION_PREFERED_SUCCESS':
            return Object.assign({}, state, {
                businessSegment: action.segmentation.businessSegment,
                currentSegmentation: null,
                channel: action.segmentation.channel,
                channelName: action.segmentation.channelName,
                subchannel: action.segmentation.subchannel,
                subchannelProgram: action.segmentation.subchannelProgram,
                subchannelName: action.segmentation.subchannelName,
                salesForceId: action.segmentation.salesforceId,
                salesForceName: action.segmentation.salesforceName,
                projectId: action.segmentation.projectId,
                projectName: action.segmentation.projectName,
                typology: action.segmentation.typology,
                typologyName: action.segmentation.typologyName,
                builder: { id: action.segmentation.builderId, name: action.segmentation.builderName },
                po: action.segmentation.purchaseOrders,
                use: state.useOptions.find(use => {
                    return use.id === action.segmentation.use
                }),
                kb: state.kbOptions.find(kb => {
                    return kb.id === action.segmentation.kbId
                })
            })
        case 'SELECT_BUSINESS_SEGMENT':
            return Object.assign({}, state, {
                businessSegment: action.value,
                currentSegmentation: 'CHANNEL',
                channel: null,
                channelName: null,
                subchannel: null,
                subchannelName: null,
                typology: null,
                typologyName: null,
                builder: null,
                kb: null
            })
        case 'SELECT_CHANNEL':
            return Object.assign({}, state, {
                businessSegment: state.segmentationV3 && action.value === 'A0' ? 'RE' : state.businessSegment,
                channel: action.value,
                channelName: action.name,
                currentSegmentation: 'SUBCHANNEL',
                subchannel: state.segmentationV3 && action.value === 'A0' ? '1A' : null,
                subchannelName: null,
                typology: null,
                typologyName: null,
                salesForceId: null,
                salesForceName: null,
                projectId: null,
                projectName: null,
                originalChannel: null,
                originalChannelName: null,
            })
        case 'SELECT_SUBCHANNEL':
            return Object.assign({}, state, {
                subchannel: action.value,
                subchannelName: action.name,
                currentSegmentation: 'TYPOLOGY',
                typology: null,
                typologyName: null,
                salesForceId: null,
                salesForceName: null,
                projectId: null,
                projectName: null
            })
        case 'SELECT_SALESFORCEID':
            return Object.assign({}, state, {
                salesForceId: action.value,
                salesForceName: action.name,
                subchannelProgram: action.subchannelProgram ? action.subchannelProgram : null,
                businessSegment: action.businessSegment ? action.businessSegment : null,
                currentSegmentation: null,
                subchannel: state.channel === 'A0' ? '1A' : action.subchannelId,
                projectId: null,
                projectName: null
            })
        case 'SELECT_PROJECTID':
            return Object.assign({}, state, {
                projectId: action.value,
                projectName: action.name,
                currentSegmentation: null,
                subchannelProgram: action.subchannelProgram ? action.subchannelProgram : state.subchannelProgram,
                businessSegment: action.businessSegment ? action.businessSegment : state.businessSegment,
                subchannel: state.channel === 'A0' ? '1A' : (action.subchannelId ? action.subchannelId : state.subchannel),
                salesForceId: state.salesForceId ? state.salesForceId : action.salesForceId,
                originalChannel: action.originalChannel,
                originalChannelName: action.originalChannelName,
            })
        case 'SELECT_TYPOLOGY':
            return Object.assign({}, state, {
                typology: action.value,
                typologyName: action.name,
                currentSegmentation: null
            })
        case 'SELECT_USE':
            return Object.assign({}, state, { use: action.value, useName: action.name, currentSegmentation: null })
        case 'SELECT_KB':
            return Object.assign({}, state, { kb: action.value, currentSegmentation: null })
        case 'SELECT_BUILDER':
            return Object.assign({}, state, { builder: action.value, currentSegmentation: null })
        case 'SET_PO':
            return Object.assign({}, state, { po: action.value })
        case 'FETCH_SEGMENTATION_REQUEST':
            return Object.assign({}, state, { fetchingSegmentation: true })
        case 'SET_CURRENT_FAVOURITE':
            return Object.assign({}, state, { currentFavourite: action.currentFavourite })
        case 'FETCH_SEGMENTATION_FAILURE':
            return Object.assign({}, state, { fetchingSegmentation: false })
        case 'FETCH_SEGMENTATION_SUCCESS':
            const q = action.segmentation.segmentation
            let b = action.segmentationV3 ? transformByIdV3(q) : transformById(q)
            return Object.assign({}, state, {
                segmentationOptions: b,
                fetchingSegmentation: false,
                useOptions: action.segmentation.uses,
                kbOptions: action.segmentation.kbs,
                buildersOptions: action.segmentation.builders,
                preferredSegmentation: fillFavouriteSegmentation(action.segmentation.favourites, action.segmentationV3, b),
                typologiesV3: action.segmentation.typologies,
                segmentationV3: action.segmentationV3
            })
        default:
            return state
    }
}
const transformByIdV3 = segmentation => {

    let channels = {}
    segmentation.forEach(channel => {
        let salesforceRelations = {}
        channel.salesforceRelations.forEach((salesforceRelation, index) => {
            let projectOptions = {}

            salesforceRelation.projectOptions.forEach((project) => {

                projectOptions[project.projectId] = {
                    projectId: project.projectId,
                    projectName: project.projectName,
                    subchannelId: project.subchannel,
                    subchannelProgram: project.subchannelProgram,
                    isHomeOwner: project.isHomeOwner
                }
            })

            if ((salesforceRelation.id !== '' && channel.id !== 'A0') || (salesforceRelation.id === '' && channel.id === 'A0') || salesforceRelation.id !== '') {
                salesforceRelations[salesforceRelation.id] = {
                    name: salesforceRelation.name,
                    businessSegment: salesforceRelation.businessSegment,
                    // subchannelProgram: salesforceRelation.subchannelProgram,
                    // subchannelId: salesforceRelation.subchannelId,
                    projectOptions: projectOptions
                }
            }
        })
        if (Object.keys(salesforceRelations).length >= 1) {
            channels[channel.id] = {
                name: channel.name,
                salesforceRelations
            }
        }
    })

    return channels
}
const transformById = segmentation => {
    let byId = {}
    segmentation.forEach(businessSegment => {
        let channels = {}
        businessSegment.channels.forEach(channel => {
            let subchannels = {}
            channel.subchannels.forEach(subchannel => {
                let typologies = {}
                subchannel.typologies.forEach(typology => {
                    typologies[typology.id] = { name: typology.name }
                })
                subchannels[subchannel.id] = { name: subchannel.name, typologies }
            })
            channels[channel.id] = { name: channel.name, subchannels }
        })
        byId[businessSegment.id] = { name: businessSegment.name, channels }
    })
    return byId
}
const fillFavouriteSegmentation = (favourites, segmentationV3, channelOptions) => {
    if (segmentationV3) {
        return favourites.map(segmentation => {
            return { ...segmentation, channelName: channelOptions[segmentation.channel] && channelOptions[segmentation.channel].name }
        })
    } else {
        return favourites.map(segmentation => {
            return {
                ...segmentation,
                channelName:
                    channelOptions[segmentation.businessSegment] &&
                    channelOptions[segmentation.businessSegment]['channels'] &&
                    channelOptions[segmentation.businessSegment]['channels'][segmentation.channel] &&
                    channelOptions[segmentation.businessSegment]['channels'][segmentation.channel].name,
                subchannelName:
                    channelOptions[segmentation.businessSegment] &&
                    channelOptions[segmentation.businessSegment]['channels'] &&
                    channelOptions[segmentation.businessSegment]['channels'][segmentation.channel] &&
                    channelOptions[segmentation.businessSegment]['channels'][segmentation.channel]['subchannels'] &&
                    channelOptions[segmentation.businessSegment]['channels'][segmentation.channel]['subchannels'][segmentation.subchannel] &&
                    channelOptions[segmentation.businessSegment]['channels'][segmentation.channel]['subchannels'][segmentation.subchannel].name
            }
        })
    }
}
export const getChannelOptionsV3 = state => {
    return state.segmentation.segmentationOptions
}
export const getSubchannelOptionsV3 = (state, channel) => {
    if (!channel) {
        return {}
    }
    return getChannelOptionsV3(state)[channel].subchannels
}
export const getTypologyOptionsV3 = (state, channel) => {
    return state.segmentation.typologiesV3
}
export const getSalesforceRelationsV3 = (state, channel) => {
    if (!channel) {
        return {}
    }
    return getChannelOptionsV3(state)[channel].salesforceRelations
}

export const getProjectIdV3 = (state, channel, salesForceId) => {
    if (channel === 'B0' || channel === 'HE' || channel === 'HO') {

        function mapChannel(channelOptions, projectOptions, originalChannel) {
            const salesForceRelations = Object.entries(channelOptions.salesforceRelations)
            salesForceRelations.map(([salesForceId, eachSalesForceId]) => {
                Object.values(eachSalesForceId.projectOptions).map(eachProjectId => {
                    projectOptions[eachProjectId.projectId] = {
                        projectId: eachProjectId.projectId,
                        projectName: eachProjectId.projectName,
                        subchannelProgram: eachProjectId.subchannelProgram,
                        isHomeOwner: eachProjectId.isHomeOwner,
                        businessSegment: eachSalesForceId.businessSegment,
                        subchannelId: eachProjectId.subchannelId,
                        originalChannel: originalChannel,
                        originalChannelName: channel ? channelOptions.name : null,
                        salesForceId: salesForceId
                    }
                })
            })
        }

        let projectOptions = []

        if (channel === 'B0' || channel === 'HE') {
            const B0channelOptions = state.segmentation.segmentationOptions['B0']
            const HEchannelOptions = state.segmentation.segmentationOptions['HE']
            if (B0channelOptions) mapChannel(B0channelOptions, projectOptions)
            if (HEchannelOptions) mapChannel(HEchannelOptions, projectOptions, 'HE')
        } else {
            mapChannel(state.segmentation.segmentationOptions['HO'], projectOptions)
        }

        projectOptions.sort()
        return projectOptions
    }
    if (!salesForceId) {
        return {}
    }
    if (!getChannelOptionsV3(state)[channel].salesforceRelations[salesForceId]) {
        return {}
    }

    return getChannelOptionsV3(state)[channel].salesforceRelations[salesForceId].projectOptions
}

export const getBusinesSegmentOptions = state => {
    return state.segmentation.segmentationOptions
}
export const getChannelOptions = (state, businessSegment) => {
    if (!businessSegment) {
        return {}
    }
    if (!state.segmentation.segmentationOptions[businessSegment]) {
        return {}
    }
    return state.segmentation.segmentationOptions[businessSegment].channels
}

export const getSubchannelOptions = (state, businessSegment, channel) => {
    if (!channel) {
        return {}
    }
    return getChannelOptions(state, businessSegment)[channel].subchannels
}

export const getTypologyOptions = (state, businessSegment, channel, subchannel) => {
    if (!subchannel) {
        return {}
    }
    return getSubchannelOptions(state, businessSegment, channel)[subchannel].typologies
}

export const getUseOptions = state => {
    return state.segmentation.useOptions
}
export const getKBOptions = state => {
    return state.segmentation.kbOptions
}
export const getBuildersOptions = state => {
    return state.segmentation.buildersOptions
}
export const getSelectedBusinesSegment = state => {
    return state.segmentation.businessSegment
}
export const getSelectedChannel = state => {
    return state.segmentation.channel
}
export const getSelectedOriginalChannel = state => {
    return state.segmentation.originalChannel
}
export const getSelectedChannelName = state => {
    return state.segmentation.channelName
}
export const getSelectedOriginalChannelName = state => {
    return state.segmentation.originalChannelName
}
export const getSelectedSalesForceId = state => {
    return state.segmentation.salesForceId
}
export const getSelectedProjectId = state => {
    return state.segmentation.projectId
}
export const getSelectedProjectName = state => {
    return state.segmentation.projectName
}
export const getSelectedSalesForceName = state => {
    return state.segmentation.salesForceName
}
export const getSelectedSubchannelProgram = state => {
    return state.segmentation.subchannelProgram
}
export const getSelectedSubchannel = state => {
    return state.segmentation.subchannel
}
export const getSelectedSubchannelName = state => {
    return state.segmentation.subchannelName
}
export const getSelectedTypology = state => {
    return state.segmentation.typology
}
export const getSelectedTypologyName = state => {
    return state.segmentation.typologyName
}
export const getSelectedUse = state => {
    return state.segmentation.use
}
export const getSelectedKB = state => {
    return state.segmentation.kb
}
export const getSelectedBuilder = state => {
    return state.segmentation.builder
}
export const getPO = state => {
    return state.segmentation.po
}
export const segmentationDone = state => {
    return state.segmentation.segmentationSubmitted
}
export const getCurrentSegmentation = state => {
    return state.segmentation.currentSegmentation
}
export const getCurrentFavourite = state => {
    return state.segmentation.currentFavourite
}
export const getPreferredSegmentation = state => {
    return state.segmentation.preferredSegmentation
}
export const getPreferredSegmentationByName = (state, name) => {
    return state.segmentation.preferredSegmentation.find(segmentation => {
        return segmentation.name === name
    })
}
export const getIsSaving = state => {
    return state.segmentation.isSaving
}
export const getIsLoading = state => {
    return state.segmentation.fetchingSegmentation
}
export const getHasSegmentationV3 = state => {
    if (!getIsLogged(state)) return false
    return state.segmentation.segmentationV3
}

export const getFullSelectedSegmentation = state => {
    const selectedKB = getSelectedKB(state)
    const selectedBuilder = getSelectedBuilder(state)

    const originalChannel = getSelectedOriginalChannel(state)
    const originalChannelName = getSelectedOriginalChannelName(state)

    return {
        businessSegment: getSelectedBusinesSegment(state) === 'NOT' ? null : getSelectedBusinesSegment(state),
        channel: originalChannel ? originalChannel : getSelectedChannel(state),
        channelName: originalChannelName ? originalChannelName : getSelectedChannelName(state),
        subchannel: getSelectedSubchannel(state),
        subchannelName: getSelectedSubchannelName(state),

        salesforceId: getSelectedSalesForceId(state),
        salesforceName: getSelectedSalesForceName(state),

        projectId: getSelectedProjectId(state),
        projectName: getSelectedProjectName(state),

        subchannelProgram: getSelectedSubchannelProgram(state) ? getSelectedSubchannelProgram(state) : null,

        typology: getSelectedTypology(state),
        typologyName: getSelectedTypologyName(state),

        use: getSelectedUse(state) ? getSelectedUse(state).id || getSelectedUse(state).value : null,
        useName: getSelectedUse(state) ? getSelectedUse(state).name || getSelectedUse(state).value : null,

        kbId: selectedKB ? selectedKB.id : null,
        kbName: selectedKB ? selectedKB.name : null,
        builderId: selectedBuilder ? selectedBuilder.id : null,
        builderName: selectedBuilder ? selectedBuilder.name : null,
        purchaseOrders: getPO(state)
    }
}
export const getChannelNameById = (state, channel) => {
    return getChannelOptionsV3(state)[channel]
}
export const getSubchannelNameById = (state, channel, subchannel) => {
    return getSubchannelOptionsV3(state, channel)[subchannel]
}
export const getSegmentatingProductId = state => {
    return state.segmentation.segmentatingProductId
}
export const getSegmentatingProductIsOutlet = state => {
    return state.segmentation.segmentatingProductIsOutlet
}
export const validHomeDepotSegmentation = state => {
    if (getSelectedSubchannelProgram(state) === 'D000000001' && getSelectedChannel(state) === 'D0') return true
    if (getSelectedSubchannel(state) === '4A' && getSelectedChannel(state) === 'D0') return true
    return false
}
export default segmentation
