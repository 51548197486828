import React from 'react'
import Translation from '../global/Translation'

import { NoHits, PageSizeSelector, Select, Pagination, SortingSelector } from 'searchkit'
import { injectIntl, defineMessages } from 'react-intl'
import Button from '../global/Button'
import FormatCSVModal from '../global/FormatCSVModal'
import axios from 'axios'
import BannerBanos from './BannerBanos'
import BannerOutlet from './BannerOutlet'
import BannerTablas from './BannerTablas'
import BannerCTS from './BannerCTS'
import BannerRecurringOrder from './BannerRecurringOrder'
import { canViewBannerRecurring } from '../../store/login/reducers'
import { connect } from 'react-redux'
import { getHideAllBannerCatalog } from '../../store/ui/reducers'

defineMessages({
    displayName: {
        id: 'displayName',
        description: 'displayName',
        defaultMessage: 'Product Name'
    }
})

const mapStateToProps = state => {
    return {
        canViewBannerRecurring: canViewBannerRecurring(state),
        isHideAllBannerCatalog: getHideAllBannerCatalog(state)
    }
}

class Results extends React.Component {

    constructor(props) {
        super(props);
    }

    state = { showCSVFormat: false }

    closeFormatCSVModal(format) {
        this.setState({ showCSVFormat: false })

        if (!format) {
            return
        }

        const { intl } = this.props
        const fieldsSlabs = [
            'brand',
            'subtypeName',
            'productId',
            'colorName',
            'finish',
            'thickness',
            'stock_m2',
            'stock_ft2',
            'stock',
            'stock_outlet_m2',
            'stock_outlet_ft2',
            'stock_outlet',
            'outletQuality'
        ]
        const fieldsMktools = [
            'productId',
            'displayName',
            'brand',
            'webHeading',
            'type',
            'subtypeName',
            'subtype1Name',
            'subtype2Name',
            'colorName',
            'finishFamily',
            'thickness',
            'stock',

            'stock_outlet',
            'outletQuality'
        ]
        const fields = this.props.isMkToolsCenter ? fieldsMktools : fieldsSlabs
        const header = fields.map(field => {
            return intl.formatMessage({ id: `${field}` })
        })

        const FileDownload = require('js-file-download')
        axios
            .get(process.env.REACT_APP_API_HOST + `/catalog/csv?format=${format}&fields=${encodeURIComponent(fields.join(','))}&header=${encodeURIComponent(header.join(','))}`, {
                headers: { Authorization: `Bearer ${this.props.token}` },
                'Content-Type': 'text/plain',
                responseType: 'blob'
            })
            .then(response => {
                FileDownload(response.data, `products.${format}`)
            })
    }

    render() {
        const sortingSelector = (
            <SortingSelector
                options={[
                    {
                        label: this.props.intl.formatMessage({
                            id: 'stock_high_to_low'
                        }),
                        field: this.props.outlet ? 'stock_outlet' : 'stock',
                        order: 'desc',
                        defaultOption: !this.props.isIkeaCenter
                    },
                    {
                        label: this.props.intl.formatMessage({
                            id: 'stock_low_to_high'
                        }),
                        field: this.props.outlet ? 'stock_outlet' : 'stock',
                        order: 'asc',
                        defaultOption: false
                    },
                    {
                        label: this.props.intl.formatMessage({
                            id: 'newest_arrivals'
                        }),
                        field: 'creationDate',
                        order: 'desc',
                        defaultOption: this.props.isIkeaCenter
                    },
                    {
                        label: this.props.intl.formatMessage({
                            id: 'oldest_materials'
                        }),
                        field: 'creationDate',
                        order: 'asc',
                        defaultOption: false
                    }
                ]}
            />
        )

        let banner;
        if(this.props.typeClient === 1 && this.props.location.search !== "?type[0]=BAÑOS" && this.props.testAB === 'A'){
            banner = <BannerBanos />
        }else if(this.props.typeClient === 1 && this.props.location.search !== "?type[0]=CTS" && this.props.testAB === 'A'){
            banner = <BannerCTS />
        }else if(this.props.typeClient === 1 && this.props.location.search !== "?type[0]=BAÑOS" && this.props.testAB === 'B'){
            banner = <BannerBanos />
        }else if(this.props.typeClient === 1 && this.props.location.search !== "?type[0]=CTS" && this.props.testAB === 'B'){
            banner = <BannerCTS />
        }else if(this.props.typeClient === 2 && this.props.location.search !== "?type[0]=TABLA"){
            banner = <BannerTablas />
        }else if(this.props.typeClient === 2 && this.props.location.search !== "?type[0]=CTS"){
            banner = <BannerOutlet />
        }else if(this.props.typeClient === 3 && this.props.location.search !== "?type[0]=BAÑOS"){
            banner = <BannerBanos />
        }else if(this.props.typeClient === 3 && this.props.location.search !== "?type[0]=CTS"){
            banner = <BannerCTS />
        }

        if(this.props.canViewBannerRecurring){
            banner = <BannerRecurringOrder />
        }

        return (
            <div className="results" style={{marginTop: '16px'}}>
                {/*{!this.props.isHideAllBannerCatalog && (*/}
                {/*    {banner}*/}
                {/*)}*/}
                <div className="row">
                    <div className="col-12 d-md-none" style={{ height: '24px' }} />
                    {!this.props.hideOptions && (
                        <div className="col-12 col-md-6">
                            <h1>
                                <Translation id="results" defaultMessage="Results" />
                            </h1>
                        </div>
                    )}
                    {!this.props.hideOptions && (
                        <div className="col-12 col-md-6 text-right">
                            {this.props.token && !this.props.isLowesCenter && (
                                <Button inline size="small" icon="download" customClass="mr-2" onClick={() => this.setState({ showCSVFormat: true })}>
                                    <Translation id="download_excel" defaultMessage="Download Excel" />
                                </Button>
                            )}
                            {!this.props.isLowesCenter && <div className="sorting-selector">
                                {/* <Translation id="sort_by" defaultMessage="Sort by"></Translation> */}
                                {sortingSelector}</div>}
                            {/*                     <Button inline size="small" icon="download" customClass="mr-2">
                            <Translation id="download_pdf" defaultMessage="Download PDF" />
                        </Button>*/}
                        </div>
                    )}
                </div>
                <div className="results-list text-center text-sm-left">
                    {this.props.hits}
                    <NoHits />
                </div>
                {!this.props.hidePagination && (
                    <div className="results-pagination" data-cy="catalog__pagination_catalog" onClick={() => window.scrollTo(0, 160)}>
                        <Pagination showNumbers={true} pageScope={4} />
                    </div>
                )}
                {!this.props.hideOptions && (
                    <div className="results-catalog__page_size-selector" data-cy="catalog__page_size">
                        <Translation id="results" defaultMessage="Results" />: <PageSizeSelector options={[25, 50, 100, 500]} listComponent={Select} />
                    </div>
                )}

                {this.state.showCSVFormat && <FormatCSVModal onClose={format => this.closeFormatCSVModal(format)} token={() => this.props.token}></FormatCSVModal>}
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(Results)
)