import React from 'react'
import Translation from '../global/Translation'

import { connect } from 'react-redux'
import {
    getCurrentSegmentation,
    getSelectedChannel,
    getSelectedSalesForceId,
    getSelectedProjectId,
    getProjectIdV3
} from '../../store/segmentation/reducers'
import {
    toggleSegmentation,
    selectProjectIdV4,
    selectTypology,
    selectUse
} from '../../store/segmentation/actions'
import Select from 'react-select'
import {isOptionalBuilderProjectCenter} from "../../store/centers/reducers";

const mapStateToProps = state => {
    const channel = getSelectedChannel(state)
    const salesForceId = getSelectedSalesForceId(state)

    return {
        show: getCurrentSegmentation(state) === 'SUBCHANNEL',
        selected: getSelectedProjectId(state),
        options: getProjectIdV3(state, channel, salesForceId),
        isOptionalBuilderProjectCenter: isOptionalBuilderProjectCenter(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onToggle: () => {
            dispatch(toggleSegmentation('SUBCHANNEL'))
        },
        onSelect: (value, name, subchannelProgram, businessSegment, subchannelId, salesForceId, originalChannel, originalChannelName) => {
            // dispatch(selectProjectId(value, name,subchannelProgram, businessSegment, subchannelId))
            dispatch(selectProjectIdV4(value, name, subchannelProgram, businessSegment, subchannelId, salesForceId, originalChannel, originalChannelName))
            dispatch(selectTypology(null, null))
            dispatch(selectUse(null))
        }
    }
}
class ProjectIdV3 extends React.Component {
    render() {
        const { selected, onSelect, options, intl } = this.props
        if (options === undefined) {
            return ''
        }
        const _options = options && Object.entries(options)
        if (_options && (_options.length === 0 /*|| (_options.length === 1 && !_options[0][1].projectId)*/)) {
            return ''
        }

        return (
            <div>
                <Translation id="project_id" defaultMessage="Project Id" />
                {
                    !this.props.isOptionalBuilderProjectCenter && (
                        <span>&nbsp;<small>{"*"}</small></span>
                    )
                }

                <Select
                    placeholder={intl.formatMessage({ id: 'choose_option' })}
                    options={_options.map(option => ({
                        label: option[1].projectName === '' ? 'PROJECT' : option[1].projectName,
                        value: option[0],
                        subchannelProgram: option[1].subchannelProgram ? option[1].subchannelProgram : null,
                        businessSegment: option[1].businessSegment ? option[1].businessSegment : null,
                        subchannelId: option[1].subchannelId ? option[1].subchannelId : null,
                        originalChannel: option[1].originalChannel ? option[1].originalChannel : null,
                        originalChannelName: option[1].originalChannelName ? option[1].originalChannelName : null,
                        salesForceId: option[1].salesForceId ? option[1].salesForceId : null
                    }))}
                    value={selected}
                    onChange={option => option && onSelect(option.value, option.label, option.subchannelProgram, option.businessSegment, option.subchannelId, option.salesForceId, option.originalChannel, option.originalChannelName)}
                    clearable={false}
                />
                {/* <hr /> */}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectIdV3)
