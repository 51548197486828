export const BaseProductLogic = props => {

    const product = props.result !== undefined ? props.result._source : props.product
    if (props.product && props.product.extra) product.extra = props.product.extra

    const getExtras = () => {
        const extras = {...product.extra}
        if (props.outlet) extras.outlet =  product.outletQuality
        if (props.sectionFlag) extras.sectionFlag = props.sectionFlag
        if (props.list) extras.analyticsTag = props.list
        if (props.crossType) extras.crossType = props.crossType
        if (props.isComingFromCampaign) extras.comingFromCampaign = true
        return Object.keys(extras).length > 0 ? extras : null
    }

    const getMin = () => {
        return product.min ? product.min : 0
    }

    const getLimit = () => {
        if (product.discontinued) {
            if (product.isMktool && getLimitDiscontinued() > getLimitMktool()) return getLimitMktool() < getMin() ? 0 : getLimitMktool()
            else return getLimitDiscontinued()
        } else if (product.isMktool) return getLimitMktool() < getMin() ? 0 : getLimitMktool()
        else if (props.outlet && product.outlet) return getLimitDiscontinued()
        else return null
    }

    const getLimitMktool = () => {
        let result = 0
        if (product.isMktool) {
            if (props.isMkToolsUsaCenter && (product.max || product.max === 0)) result = product.max
            else result = 1000
        }
        if (props.qtyProductCart[product.productId]) {
            result = result - parseInt(props.qtyProductCart[product.productId], 10)
        }
        return result
    }

    const getLimitDiscontinued = () => {
        let result = 0
        if (props.outlet) {
            if (product.stock_outlet) {
                result = parseInt(product.stock_outlet, 10)
            }
        } else {
            if (product.stock) {
                result = parseInt(product.stock, 10)
            }
        }
        if (props.qtyProductCart[product.productId]) {
            result = result - parseInt(props.qtyProductCart[product.productId], 10)
        }
        return result
    }

    const getType = () => {
        let type = null
        if (props.isIkeaCenter && product.brand === 'SERVICES') type = 'ikea_service'
        else if (props.isIkeaCenter) type = 'ikea'
        else if (props.isMkToolsCenter) type = 'mktools'
        return type
    }

    const getTypeProductForStyles = () => {
        if (props.isIkeaCenter && (product.brand === 'DEKTON' || product.brand === 'SILESTONE')) return 'TABLA'
        if(product.subtypeId == 'REVESTIMIENTO') return 'TABLA'
        else return product.type
    }

    const canDisplayDiscontinued = () => {
        console.log('PROPS', props)
        if (['MK', 'SH', 'WH'].includes(product.webHeading) && product.discontinued === 'DD') return false
        if (product.discontinued === 'DD' || product.discontinued === 'DF') return false
        return true
    }

    const validateOutletPromotions = (allPromotions) => {
        if (allPromotions == undefined || allPromotions == null || Object.keys(allPromotions).length == 0) return []
        //remove other outlet types promos.
        allPromotions = Object.values(allPromotions).filter(promo => {
            if (promo.outlet && promo.outlet == true && promo.outlet_types != product.outletQuality) {
                return false
            }
            return true
        })

        return allPromotions
    }

    const goToProductCard = (_product) => {
        _product.extra = getExtras()
        if (props.indexOfList !== undefined) props.clickDetail(_product, props.list, props.indexOfList)
    }

    const isCosentinoGlobal = props.centerId != 1100 && product.centerId == 1100

    return {
        getMin,
        getLimit,
        getLimitMktool,
        getLimitDiscontinued,
        getType,
        getTypeProductForStyles,
        canDisplayDiscontinued,
        validateOutletPromotions,
        getExtras,
        goToProductCard,
        product,
        isCosentinoGlobal
    }

}

