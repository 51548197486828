import {
    getCanCancelPreorder,
    getPreOrdersCts,
    getPreOrdersCurrentPage,
    getPreOrdersLastPage,
    getPreOrdersProjectAvailables,
    isLoading
} from './../../store/cut-to-size/reducers';
import {
    fetchPreOrdersCts,
    sendPreorderMessage,
    fetchPreOrdersCtsPaginate,
    fetchPreOrdersProjectAvailables,
    cancelPreorder
} from './../../store/cut-to-size/actions';
import { connect } from 'react-redux'
import PreOrdersCts from './PreOrdersCts'
import {defineMessages, injectIntl} from 'react-intl'
import { withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'
import { getVersion } from '../../store/login/reducers'


defineMessages({
    CONFIRM_REJECT_PREORDER_ADVISE: {
        id: 'CONFIRM_REJECT_PREORDER_ADVISE',
        description: 'CONFIRM_REJECT_PREORDER_ADVISE',
        defaultMessage: 'If you cancel now, you’ll permanently remove this preorder.'
    },
    CONFIRM_REJECT_PREORDER_OK_BUTTON: {
        id: 'CONFIRM_REJECT_PREORDER_OK_BUTTON',
        description: 'CONFIRM_REJECT_PREORDER_OK_BUTTON',
        defaultMessage: 'Yes, cancel this order'
    },
    CONFIRM_REJECT_PREORDER_CANCEL_BUTTON: {
        id: 'CONFIRM_REJECT_PREORDER_CANCEL_BUTTON',
        description: 'CONFIRM_REJECT_PREORDER_CANCEL_BUTTON',
        defaultMessage: 'No, continue with this order'
    },
    CONFIRM_APPROVE_PREORDER: {
        id: 'CONFIRM_APPROVE_PREORDER',
        description: 'CONFIRM_APPROVE_PREORDER',
        defaultMessage: 'Approve this preorder?'
    },
    CONFIRM_APPROVE_PREORDER_OK_BUTTON: {
        id: 'CONFIRM_APPROVE_PREORDER_OK_BUTTON',
        description: 'CONFIRM_APPROVE_PREORDER_OK_BUTTON',
        defaultMessage: 'Yes, approve this preorder'
    },
    CONFIRM_APPROVE_PREORDER_CANCEL_BUTTON: {
        id: 'CONFIRM_APPROVE_PREORDER_CANCEL_BUTTON',
        description: 'CONFIRM_APPROVE_PREORDER_CANCEL_BUTTON',
        defaultMessage: 'Reconsider'
    },
    CONFIRM_APPROVE_PREORDER_ADVISE_TOP: {
        id: 'CONFIRM_APPROVE_PREORDER_ADVISE_TOP',
        description: 'CONFIRM_APPROVE_PREORDER_ADVISE_TOP',
        defaultMessage: 'You`ll not be able to cancel this preorder using the platform.'
    },
    CONFIRM_APPROVE_PREORDER_ADVISE_BOTTOM: {
        id: 'CONFIRM_APPROVE_PREORDER_ADVISE_BOTTOM',
        description: 'CONFIRM_APPROVE_PREORDER_ADVISE_BOTTOM',
        defaultMessage: 'If you want to cancel it you have to contact your center.'
    },
    CONFIRM_DENY_PREORDER: {
        id: 'CONFIRM_DENY_PREORDER',
        description: 'CONFIRM_DENY_PREORDER',
        defaultMessage: 'Need more changes?'
    }
})


const mapStateToProps = (state, { match }) => {
    return {
        preOrdersCts: getPreOrdersCts(state),
        isNewVersion: getVersion(state),
        currentPage: getPreOrdersCurrentPage(state),
        lastPage: getPreOrdersLastPage(state),
        projectList:getPreOrdersProjectAvailables(state),
        isLoading: isLoading(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearPreOrderCts: () => {
            dispatch({ type: 'CLEAR_PRE_ORDERS_CTS' })
        },
        fetchPreOrdersCts: () => {
            dispatch(fetchPreOrdersCts())
        },
        fetchPreOrdersCtsPaginate: (startDate, endDate, filterProject, status,search,page) => {
            dispatch(fetchPreOrdersCtsPaginate(startDate, endDate, filterProject, status,search,page))
        },
        fetchPreOrdersProjectAvailables:  () => {
            dispatch(fetchPreOrdersProjectAvailables())
        },
        cancelPreorder: (preorderId) => dispatch(cancelPreorder(preorderId))
    }
}

const PreOrdersCtsContainer = withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(PreOrdersCts)
    )
)

export default PreOrdersCtsContainer
