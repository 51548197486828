import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Translation from '../global/Translation'
import { toggleVersion } from '../../store/ui/actions'
import { showBannerOldVersion } from '../../store/ui/reducers'
import { getIsShowBannerComments } from '../../store/login/reducers'

const mapStateToProps = state => {
    return {
        isShowBannerComments: getIsShowBannerComments(state),
        showBanner: showBannerOldVersion(state),
    }
}
const mapDispatchToProps = dispatch => {
    return {
        showModal: () => {
            dispatch({ type: 'SHOW_NEW_VERSION_MODAL_EXPERIENCE', showNewVersionModalExperience: true })
        },
        toggleVersion: id => dispatch(toggleVersion()),
        hideBanner: () => {
            dispatch({type: 'CLOSE_BANNER_OLD_VERSION'})
        }
    }
}

class BannerOldVersion extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    hiddenBanner(){
        this.props.hideBanner();
    }

    render() {
        const { toggleVersion, showBanner, showModal, isShowBannerComments } = this.props

        const showModalToFeedback = () => {
            if(isShowBannerComments) return showModal()
            return false
        }

        const onClick = () => {
            showModalToFeedback()
            toggleVersion()
        }
        return  (
            <React.Fragment>
                <div className='banner_old_version' style={{
                    display: showBanner ? 'block' : 'none'
                }}>
                <div className="banner_old_version--container">
                    <div className="content">
                        <span><Translation id="subtitle_2_modal_new_version" defaultMessage="The classic version of e.Cosentino will no longer be available on 02/10/2023." /></span>
                        <button onClick={() => onClick()}>
                            <Translation id="go_back_to_new_version" defaultMessage="Go to new version" />
                        </button>

                    </div>
                        <div className="remove-banner-icon" onClick={() => this.hiddenBanner()}>
                            <i className="fal fa-times"  />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(BannerOldVersion)
)
