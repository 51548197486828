import React from 'react'

import LocalizedLink from '../global/LocalizedLink'
import ResponsiveMenuContainer from './ResponsiveMenuContainer'
import IconButtonsContainer from './IconButtonsContainer'
import logo from '../../assets/img/icons/ecosentino.svg'
import TopRibbonV2 from './TopRibbonV2'
import LoadingBar from 'react-redux-loading-bar'
import { injectIntl } from 'react-intl'
import MenuLinks from './MenuLinks'
import IkeaModalEmployeeId from '../global/IkeaModalEmployeeId'
import LowesAdminViewSelector from '../catalog-lowes/LowesAdminViewSelector'
import LowesViewSelector from '../catalog-lowes/LowesViewSelector'
import { connect } from 'react-redux'
import BannerComments from '../catalog/BannerComments'
import BannerVisitasVirtuales from '../catalog/BannerVisitasVirtuales'
import { getIsShowBannerComments, getVirtualVisitURL } from '../../store/login/reducers'
import $ from 'jquery'
import { hasFeedback } from '../../store/login/actions'
import Toggle from 'react-toggle'
import TopRibbon from './TopRibbon'
import { getHideAllBannerCatalog } from '../../store/ui/reducers'
import { withRouter } from 'react-router-dom'
import MenuContainer from './MenuContainer'

const mapStateToProps = state => {
    return {
        isShowBannerComments: getIsShowBannerComments(state),
        URLVirtualVisit: getVirtualVisitURL(state),
        isHideAllBannerCatalog: getHideAllBannerCatalog(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        hasBanner: () => dispatch(hasFeedback('BANNER_NEW_VERSION_EXPERIENCE'))
    }
}

class HeaderV2 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showBannerComments: false,
        }
        if (window.location.host === 'localhost:3000') this.entorno = 'local'
        else if (window.location.href.indexOf('qas-') > -1) this.entorno = 'qas'
        else if (window.location.href.indexOf('dev-') > -1) this.entorno = 'dev'
        else if (window.location.href.indexOf('pre-') > -1) this.entorno = 'pre'

        if (this.entorno) this.props.fetchInfoEnv()
        this.props.fetchCenterPreferences()
    }

    componentDidMount() {
        this.props.fetchUserLandings()
        this.props.hasBanner()
        this.showBannerComments()
    }

    showBannerComments(){
        if(this.props.isShowBannerComments){
            setTimeout(() => {
                if(window.data_analytics){
                    window.data_analytics.sendEvent("feedback_component", "view", "view")
                }
                $('.banner-comments').show()
            }, 15000)
        }
    }

    render() {
        const { infoDev } = this.props
        const responsiveMenu = this.props.showMenu ? <ResponsiveMenuContainer /> : null

        return (
            <header id="header" className="header-v2">
                {this.entorno && (
                    <a href={process.env.REACT_APP_API_HOST + '/backoffice/env'} target="_blank" rel="noopener noreferrer">
                        <div className={`identification_flag identification_flag--${this.entorno}`}>
                            <span>
                                {this.entorno}
                                {process.env.REACT_APP_BRANCH && (
                                    <React.Fragment>
                                        {' '}
                                        <span style={{ fontSize: '10px' }}>{process.env.REACT_APP_BRANCH}</span>
                                    </React.Fragment>
                                )}
                                <br />
                                {infoDev && (
                                    <div style={{ fontSize: '12px' }}>
                                        <span>back: {infoDev.branch ? infoDev.branch : '????'}</span>
                                        <br />
                                        <span>MF: {infoDev.mf}</span> || <span>OD: {infoDev.odata}</span> || <span>CH: {infoDev.checkout}</span>
                                    </div>
                                )}
                            </span>
                            <Toggle
                                defaultChecked={this.props.translateMode}
                                onChange={() => {
                                    this.props.toggleTranslateMode()
                                }}
                            />
                        </div>
                    </a>
                )}
                 {/*<TopRibbon />*/}
                <TopRibbonV2 showMenu={this.props.showMenu}/>

                <div id="main-header">
                    <div className="container-fluid" >
                        <div className="no-gutters align-items-center logo-container">
                            <div id="logo" className="col-xl-2 col-4 logo-cosentino d-xl-none d-block">
                                        {/* //REVIEW: ¿Es necesario con el redirect que hay en el home.js? */}
                                        {this.props.center === 7777 ? (
                                            this.props.userType === 'ADMIN' ? (
                                                <LocalizedLink routeId="ROUTE_CATALOG_LOWES_ADMIN" params={{ filter: 'home' }}>
                                                    <img alt="" src={logo} />
                                                </LocalizedLink>
                                            ) : (
                                                <LocalizedLink routeId="ROUTE_CATALOG_LOWES" params={{ filter: 'home' }}>
                                                    <img alt="" src={logo} />
                                                </LocalizedLink>
                                            )
                                        ) : (
                                            <LocalizedLink routeId="ROUTE_HOME">
                                                <img alt="" src={logo} />
                                            </LocalizedLink>
                                        )}
                            </div>
                            {this.props.maintenanceMode ? null : (
                                <React.Fragment>                                    
                                    <nav id="main-menu">
                                        {this.props.isLowesCenter ? (
                                            this.props.isLowesAdmin ? (
                                                <LowesAdminViewSelector small />
                                            ) : (
                                                <LowesViewSelector small />
                                            )
                                        ) : (
                                            // <div>
                                            //     <img alt="" className="icon" src={iconList} style={{ marginRight: '5px' }} />
                                            //     <span>Categorias</span>
                                            // </div>
                                                <MenuContainer comp = {true}/> 
                                        )}
                                    </nav>                                    
                             
                                    <div className={`btns-header`}>
                                        <IconButtonsContainer />
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    <LoadingBar className="loading-bar" showFastActions />
                </div>
                {responsiveMenu}
                <IkeaModalEmployeeId />
                {this.props.isShowBannerComments && !this.props.isHideAllBannerCatalog && (
                    <BannerComments />
                )}
                {!this.props.isHideAllBannerCatalog && !this.props.location.pathname.includes(this.props.intl.formatMessage({ id: 'ROUTE_CHECKOUT' })) && (
                    <BannerVisitasVirtuales type='v2' {...this.props} />
                )}
            </header>
        )
    }
}

export default withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(HeaderV2)
    )
)
