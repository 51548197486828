import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import logo from '../../assets/img/e.cosentino.svg'
// import logo from '../../assets/img/DKTN.svg'

import AlertsContainer from '../alerts/AlertsContainer'
import Spinner from '../global/Spinner'
import Modal from '../global/Modal'
import CosentinoSingleSignOn from './CosentinoSingleSignOn'
import Alerts from '../alerts/Alerts'
import { MsalContext } from '@azure/msal-react'
import { withMsal } from '@azure/msal-react'
import { PublicClientApplication, EventType, InteractionRequiredAuthError,BrowserAuthError } from '@azure/msal-browser'
import axios from 'axios'
import InfoBox from "../global/InfoBox";

class OldLogin extends React.Component {
    static contextType = MsalContext

    state = { error: null, showOldLogin:false, isInfoHidden: false }
    handleSubmit = e => {
        e.preventDefault()
        if (this.usernameInput.value.includes('@'))
{            this.props.onLogin(
                this.usernameInput.value,
                this.passwordInput.value,
                this.props.callback,
                this.props.callbackCenter,
                this.props.intl,
                null,
                this.props.callbackSalesOrg
            )}
            else{
                this.setState({error:'You can only login with an email'})
            }
    }
    render() {
        const { isLogging } = this.props
        const spinner = isLogging || this.context.inProgress !== 'none' ? <Spinner isLoading={isLogging} /> : null
        // console.log('context')
        // console.log(this.props.msalContext)
        if(this.props.isLogged){
            return<div className="container">
             <h4><Translation id="already_login" defaultMessage="You are already logged in" /></h4>
            </div>
        }
        return (
            <div className="container">
                <div id="" className="">
                    <div className="form-signin mt-5" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{maxWidth:'480px',width:'100%'}}>
                            <form onSubmit={this.handleSubmit}>
                                <AlertsContainer values={{ lastLoginAccount: this.state.lastLoginAccount }} />
                                {this.state.error && <Alerts alert={this.state.error} status="404" />}
                                {spinner}
                                <div className="d-block d-lg-none">
                                    <img alt="" id="profile-img" className="profile-img-card" src={logo} style={{ marginBottom: '30px' }} />
                                </div>
                                <div className="azure-login">
                                    {/* <div className="text-center">
                            <Translation id="dont_have_a_registered_email_yet" defaultMessage="Dont have a registered email yet?" />
                            &nbsp;
                            <a href="https://we.cosentino.com/s/register?language=en_GB" target="_blank" style={{ color: '#007bff', textDecoration: 'underline !important' }}>
                                <Translation id="update_your_account_now" defaultMessage="Update your account now!" />
                            </a>
                        </div> */}
                                </div>

                                <div className={`identifier-login `}>
                                    <label htmlFor="email">
                                        <Translation id="identifier" defaultMessage="Identifier" />
                                    </label>
                                    <input
                                        type="text"
                                        id="inputEmail"
                                        className="form-control"
                                        placeholder={this.props.intl.formatMessage({ id: 'identifier' })}
                                        autoFocus
                                        ref={node => {
                                            this.usernameInput = node
                                        }}
                                    />
                                    <label htmlFor="email">
                                        <Translation id="password" defaultMessage="Password" />
                                    </label>
                                    <input
                                        type="password"
                                        id="inputPassword"
                                        className="form-control mb-3"
                                        placeholder={this.props.intl.formatMessage({ id: 'password' })}
                                        ref={node => {
                                            this.passwordInput = node
                                        }}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') {
                                                this.handleSubmit(e)
                                            }
                                        }}
                                    />
                                </div>
                                <input type="submit" className="bt bt-inverted mb-0" value={this.props.intl.formatMessage({ id: 'login_with_your_id' })} />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withMsal(injectIntl(OldLogin))
