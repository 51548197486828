import React from 'react'
import Translation from '../../global/Translation'

import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import { injectIntl } from 'react-intl'

import Alerts from '../../alerts/Alerts'
import Button from '../../global/Button'
import LocalizedLink from '../../global/LocalizedLink'
import moment from 'moment'
import OrderFinishedContainer from '../../order-finished/OrderFinishedContainer'
import Select from 'react-select'
import CheckboxLinkSaleConditions from '../global/CheckboxLinkSaleConditions'

class CheckoutFormMktools extends React.Component {
    state = { availableSalesOrg: [] }
    componentDidMount() {
        this.props.resetCheckout().then(() => {
            this.props.setDeliveryDate(moment().add(1, 'days'))
            this.props.fetchShippingAddresses().then(() => {
                if (this.props.shippingAddresses) {
                    let firstShippingAddress = this.props.shippingAddresses.filter(shippingAddress => {
                        return shippingAddress.allowedShippingTypes.includes(this.props.shippingMethod)
                    })[0]
                    if (this.props.userType === 'CUSTOMER') this.props.setCountryOfSend(firstShippingAddress.country)

                    //antes
                    let selectedShippingAddres = this.props.mktoolsShippingCenter
                        ? this.props.mktoolsShippingCenter.toString()
                        : this.props.shippingAddressesCenters[0].id.toString()

                    //dsps
                    this.props.setSelectedShippingCenterId(this.props.isAdmin ? selectedShippingAddres : firstShippingAddress.centerId)

                    this.props.setHasSelectedCenter(this.props.isAdmin)

                    this.props.setSelectedShippingAddress(this.props.hasSelectedCenter ? null : firstShippingAddress)
                    this.props.setSelectedShippingAddressId(this.props.hasSelectedCenter ? null : firstShippingAddress.shippingAddressId)

                    this.props.setProfileConfigurationEmail(this.props.profileConfiguration.email)
                    this.props.setDestination('CENTER')

                    let availableSalesOrg = []
                    availableSalesOrg = this.props.shippingAddresses.map(shippingAddress => {
                        return shippingAddress.salesOrg
                    })

                    availableSalesOrg = availableSalesOrg.reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), [])

                    this.setState({ availableSalesOrg })
                }
            })
        })

        this.props.onLoadCheckout()
    }

    submitCheckout = () => {
        if (this.props.hasSelectedCenter) this.props.setSelectedShippingAddressId(null)
        this.props.setDestination(this.props.hasSelectedCenter ? 'CENTER' : 'DISTRIBUIDOR')
        this.props.submitCheckout()
    }

    canSendMktoolLimit = () => {
        return true
        // if (this.props.isMkToolsUsaCenter) {
        //     return this.props.budgetPlusTotal <= this.props.mktoolsBudget
        // } else return true
    }

    canSendNeedDestination() {
        if (this.props.hasDestination || this.props.isAdmin) {
            return !!this.props.destination
        } else return true
    }

    canSendCheckout = () => {
        const { current, goalMin, goalMax, shippingMethod, hasMinWeight, totalQty } = this.props
        return (
            ((current >= goalMin && current <= goalMax) || (shippingMethod === 'SINGLE_SLAB' && current <= goalMax) || hasMinWeight || shippingMethod === 'MKTOOLS') &&
            this.canSendMktoolLimit() &&
            !this.props.isSending &&
            totalQty > 0 &&
            this.canSendNeedDestination() &&
            this.props.canSendCheckoutSaleConditions
        )
    }
    createShippingAddressLabel = shippingAddress => {
        return (
            shippingAddress.centerName +
            ' ' +
            shippingAddress.customerName +
            ' ' +
            shippingAddress.address +
            ' ' +
            shippingAddress.zipCode +
            ' ' +
            shippingAddress.city +
            ' ' +
            shippingAddress.country
        )
    }
    changeShippingAddress = value => {
        const { shippingAddresses } = this.props
        //REVIEW:: PV_REPLACE_CENTERS

        let shippingAddress =
            shippingAddresses.filter(shippingAddres => {
                if (this.props.userType === 'CUSTOMER' && this.state.availableSalesOrg && this.state.availableSalesOrg.length > 1) {
                    // return shippingAddres.shippingAddressId === value && shippingAddres.salesOrg === '1000'
                    return shippingAddres.shippingAddressId === value && shippingAddres.salesOrg === '1100'
                } else {
                    return shippingAddres.shippingAddressId === value
                }
            })[0] || null
        if (this.props.userType === 'CUSTOMER') this.props.setCountryOfSend(shippingAddress.country)
        if (this.props.userType === 'CUSTOMER') this.props.setSelectedShippingCenterId(shippingAddress.centerId)

        this.props.setHasSelectedCenter(this.props.selectedShippingCenterId === value)

        this.props.setSelectedShippingAddress(shippingAddress)

        this.props.setSelectedShippingAddressId(shippingAddress ? shippingAddress.shippingAddressId : null)
    }

    getOptionsCountryOfSend = (selectedCenterData, filteredShippingAddressesForUsers) => {
        let filteredShippingAddressesForAdmins = this.props.selectedShippingCenterId
            ? this.props.shippingAddresses.filter(center => center.centerId === this.props.selectedShippingCenterId)
            : this.props.shippingAddresses

        if (this.props.destination === 'CENTER' && this.props.isAdmin && selectedCenterData) {
            let options = filteredShippingAddressesForAdmins.map(shippingAddress => ({
                value: shippingAddress.shippingAddressId,
                label: this.createShippingAddressLabel(shippingAddress)
            }))
            options = [{ value: selectedCenterData.id, label: selectedCenterData.name }].concat(options)
            return options
        } else if (this.props.userType === 'CUSTOMER' && filteredShippingAddressesForUsers)
            return filteredShippingAddressesForUsers.map(shippingAddress => ({
                value: shippingAddress.shippingAddressId,
                label: this.createShippingAddressLabel(shippingAddress) + ' ' + shippingAddress.salesOrg
            }))
        else return []
    }
    getOptionsCenterCost() {
        let filteredCostCenters = this.props.selectedShippingCenterId
            ? this.props.costCenters.filter(costCenter => costCenter.center === this.props.selectedShippingCenterId)
            : this.props.costCenters
        let options =
            filteredCostCenters &&
            filteredCostCenters.map(costCenter => {
                return { value: costCenter.id, label: costCenter.name }
            })
        if (options && options.length > 0 && !options.find(item => item.value === this.props.selectedCostCenter) && options[0]) this.props.setSelectedCostCenter(options[0] ? options[0].value : null)
        return options
    }
    getOptionsShippingAddressesCenters() {
        if (this.props.mktoolsShippingCenter && !this.props.isMkToolsUsaCenter) {
            return this.props.shippingAddressesCenters
                .filter(center => center.id === this.props.mktoolsShippingCenter.toString())
                .map(center => {
                    return { value: center.id, label: center.name }
                })
        } else {
            return this.props.shippingAddressesCenters.map(center => {
                return { value: center.id, label: center.name }
            })
        }
    }
    render() {
        const { shippingAddresses, shippingAddressesCenters, current, shippingMethod, goalMax, centerSelected } = this.props
        //allowedShippingAddresses for admins

        //allowedShippingTypes for users distributors on mktools

        let filteredShippingAddressesForUsers = shippingAddresses
            ? shippingAddresses.filter(shippingAddress => {
                  if (this.props.userType === 'CUSTOMER' && this.state.availableSalesOrg && this.state.availableSalesOrg.length > 1) {
                      return shippingAddress.allowedShippingTypes.includes(shippingMethod) && shippingAddress.salesOrg === '1100'
                  } else {
                      return shippingAddress.allowedShippingTypes.includes(shippingMethod)
                  }
              })
            : []

        let selectedCenterData = shippingAddressesCenters
            ? shippingAddressesCenters.find(center => {
                  return center.id === this.props.selectedShippingCenterId
              })
            : null
        const sendText = <Translation id="send" defaultMessage="Send" />

        const difCurrent = current <= goalMax ? 0 : current - goalMax
        const errorMessage = difCurrent !== 0 ? 'MAX_WEIGHT_EXCEEDED' : null
        const submit = this.canSendCheckout() ? (
            <Button inverted datacy="checkout__send_checkout" onClick={() => this.submitCheckout()}>
                {sendText}
            </Button>
        ) : (
            <Button inverted disabled>
                {sendText}
            </Button>
        )
        if (this.props.isLoadingShippingAddress) return <Translation id="loading_shipping_addresses" defaultMessage="Loading Shipping Addresses" />
        const destinationForAdmin = [{ value: 'CENTER', label: this.props.intl.formatMessage({ id: 'center' }) }]
        const destinationForCustomer = [
            { value: 'CLIENTE', label: this.props.intl.formatMessage({ id: 'customer' }) },
            {
                value: 'HUB_CLIENTE',
                label: this.props.intl.formatMessage({ id: 'cosentino_hub' })
            },
            {
                value: 'CENTER',
                label: this.props.intl.formatMessage({ id: 'center' })
            }
        ]
        return (
            <div>
                <h4 className="form-heading heading">{sendText}</h4>
                <form>
                    <Alerts
                        alert={this.props.alert}
                        values={{ monthItems: this.props.monthItems, waitTillDate: this.props.waitTillDate ? moment(this.props.waitTillDate).format('L') : null }}
                        status={this.props.status}
                    />

                    {(this.props.hasDestination || this.props.isAdmin) && (
                        <div className={`form-group`}>
                            <label className={'displayNone '} htmlFor="input-date">
                                {this.props.shippingMethod === 'CONTAINER' ? (
                                    <Translation id="destination_for_container" defaultMessage="Container unloading location" />
                                ) : (
                                    <Translation id="destination" defaultMessage="Destination" />
                                )}
                            </label>
                            <Select
                                className={'form-control displayNone '}
                                placeholder={`${this.props.intl.formatMessage({ id: 'select_shipping_address' })}...`}
                                value={this.props.destination}
                                onChange={e => {
                                    e && this.props.setDestination(e.value)
                                }}
                                options={this.props.isAdmin ? destinationForAdmin : destinationForCustomer}
                                clearable={false}
                            />
                        </div>
                    )}
                    {this.props.isAdmin && shippingAddressesCenters && (
                        <div className="form-group">
                            <label htmlFor="input-date">
                                <Translation id="Center" defaultMessage="Center" />
                                {centerSelected === this.props.showroomCenterId && (
                                    <div className="tip">
                                        <Translation
                                            id="Your_shipping_center_has_been_selected_based_on_portfolio_chosen"
                                            defaultMessage="Your shipping center has been selected based on portfolio chosen, you can change it back on catalog"
                                        />
                                    </div>
                                )}
                            </label>
                            <Select
                                className="form-control"
                                placeholder={`${this.props.intl.formatMessage({ id: 'select_shipping_address' })}...`}
                                value={this.props.selectedShippingCenterId}
                                onChange={e => {
                                    if (e) {
                                        this.props.setSelectedShippingCenterId(e.value)
                                        this.props.setMkToolsShippingCenter(e.value)
                                    }
                                }}
                                options={this.getOptionsShippingAddressesCenters()}
                                clearable={false}
                            />
                        </div>
                    )}
                    {(shippingAddresses || this.props.destination === 'CENTER') && (
                        <div className="form-group">
                            <label htmlFor="input-date">
                                {this.props.shippingMethod === 'CONTAINER' ? (
                                    <Translation id="shipping_address_for_container" defaultMessage="Final Destination" />
                                ) : (
                                    <React.Fragment>
                                        <Translation id="shipping_address" defaultMessage="Shipping Address" /> <span style={{ color: 'red' }}>*</span>
                                    </React.Fragment>
                                )}
                            </label>
                            <Select
                                className="form-control"
                                value={
                                    this.props.hasSelectedCenter
                                        ? this.props.selectedShippingCenterId
                                        : this.props.selectedShippingAddress
                                        ? this.props.selectedShippingAddress.shippingAddressId
                                        : null
                                }
                                onChange={e => {
                                    e && this.changeShippingAddress(e.value)
                                }}
                                options={this.getOptionsCountryOfSend(selectedCenterData, filteredShippingAddressesForUsers)}
                                clearable={false}
                            />
                        </div>
                    )}
                    {/*NO DEBE PODER ELEGIRSE EL CECO EN MKTOOLS 5000*/}
                    {this.props.isAdmin && this.props.centerSelected != 5000 && this.getOptionsCenterCost() && (
                        <div className="form-group">
                            <label htmlFor="input-date">
                                <Translation id="cost_center" defaultMessage="Cost center" />
                            </label>
                            <Select
                                className="form-control"
                                placeholder={`${this.props.intl.formatMessage({ id: 'select_shipping_address' })}...`}
                                value={this.props.selectedCostCenter}
                                onChange={e => {
                                    e && this.props.setSelectedCostCenter(e.value)
                                }}
                                options={this.getOptionsCenterCost()}
                                clearable={false}
                            />
                        </div>
                    )}

                    <div className="form-group">
                        <label htmlFor="input-po">
                            <Translation id="insert_po" defaultMessage="Insert your PO#" />
                        </label>
                        <input
                            data-cy="checkout__input_po"
                            type="text"
                            className="form-control"
                            id="input-po"
                            onChange={e => {
                                e && this.props.setPo(e.target.value)
                            }}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="input-comments">
                            <Translation id="comment" defaultMessage="Comments" />
                        </label>
                        <textarea
                            data-cy="checkout__textarea_comment"
                            className="form-control"
                            id="input-comments"
                            rows="3"
                            maxlength="2500"
                            onChange={e => {
                                e && this.props.setComments(e.target.value)
                            }}
                        />
                    </div>
                    {this.props.profileConfigurationEmail && !this.props.profileConfigurationEmail.length > 0 && (
                        <div className="tip red-tip">
                            <LocalizedLink routeId={'ROUTE_PROFILE'} params={{ filter: 'configuration', open: '' }}>
                                <Translation
                                    id="please_add_your_email_address_in_your_personal_area_if_you_want_to_receive_an_order_confirmation_email"
                                    defaultMessage="Please add your email address in your personal area if you want to receive an order confirmation email"
                                />
                            </LocalizedLink>
                        </div>
                    )}
                    <div className="form-group">
                        <CheckboxLinkSaleConditions />

                    </div>
                </form>
                <div className="row align-items-baseline">
                    <div className="col-12" style={{ paddingLeft: '35px', paddingBottom: '10px' }}>
                        {errorMessage && (
                            <Translation
                                id="MAX_WEIGHT_EXCEEDED"
                                values={{
                                    shippingMethod: this.props.intl.formatMessage({ id: shippingMethod }).toLowerCase()
                                }}
                            />
                        )}
                    </div>
                    <div className="col-4 offset-8">{submit}</div>
                </div>
                {this.props.reservationCode && <OrderFinishedContainer />}
            </div>
        )
    }
}

export default injectIntl(CheckoutFormMktools)
