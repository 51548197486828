import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import Button from '../global/Button'
import LotsTable from './LotsTable'
import LotsGrid from './LotsGrid'
import ReactPaginate from 'react-paginate';
import Select from "react-select";
import CloseableAlerts from '../alerts/CloseableAlerts'
import ReactTablePaginationCount from "../promotions/discounts/ReactTablePaginationCount";
import Modal from "../global/Modal";
import ReactTable from "react-table";
import {v4 as uuidv4} from "uuid";

const DEFAULT_PAGE_SIZE = 32
const DEFAULT_PAGE_OPTION = {label: "32 rows", value:32}

class LotsList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedBundles: [],
            selectedLots: [],
            selectedIndex: 0,
            currentPageSize: DEFAULT_PAGE_SIZE ,
            currentValue: DEFAULT_PAGE_OPTION,
            isListView: true,
            showAdvisorAlert: true,
            // showLotsModal: false,
            // lotList: []
        }

        // if (props.lots && props.original) {
        //     const currentPageLots = props.lots.slice(PAGE_INDEX * PAGE_SIZE, PAGE_INDEX * PAGE_SIZE + PAGE_SIZE)
        //     // analytics.sendLotImpression(this.props.original, currentPageLots, this.props.outlet, this.props.userType)
        // }

    }


    componentDidMount() {
        if (window.location.pathname.includes('grid')) {
            this.setState({isListView:false, currentValue:DEFAULT_PAGE_OPTION, currentPageSize:9, selectedIndex:0 })
        }
        this.props.clearSelectedLots()
    }

    componentWillReceiveProps(nextProps) {
        // if (nextProps.lots && nextProps.original) {
        // const currentPageLots = nextProps.lots.slice(PAGE_INDEX * PAGE_SIZE, PAGE_INDEX * PAGE_SIZE + PAGE_SIZE)
        // }
        this.scrollTableTop()
    }

    scrollTableTop = () => {

        // const list = document.getElementsByClassName('lots-list')[0]
        // list.scrollTop
        const table = document.getElementsByClassName('lots_table')[0]
        if (table) table.getElementsByClassName('rt-tbody')[0].scrollTop = 0
    }

    selectLot = lotId => {
        if (this.state.selectedLots.includes(lotId)) {
            this.setState({ selectedLots: this.state.selectedLots.filter(lot => lot !== lotId) })
        } else {
            this.setState({ selectedLots: this.state.selectedLots.concat(lotId) })
        }
    }

    selectBundle = bundleId => {
        if (this.state.selectedBundles.includes(bundleId)) {
            this.setState({ selectedBundles: this.state.selectedBundles.filter(bundle => bundle !== bundleId) })
        } else {
            this.setState({ selectedBundles: this.state.selectedBundles.concat(bundleId) })
        }
    }

    addToCart = () => {
        // const tableData = [];
        // if (this.state.selectedLots) {
        //     this.state.selectedLots.forEach(l => tableData.push({lotId: l}))
        // }
        // if (this.state.selectedBundles) {
        //     this.state.selectedBundles.forEach(b => tableData.push({bundleId: b}))
        // }
        // this.props.buyTables(this.props.productId, tableData, this.props.center)
        this.props.bulkAddToCart(this.props.productId, this.state.selectedLots, this.state.selectedBundles, this.props.outletQuality)
        this.setState({ selectedBundles: [], selectedLots: [] })
    }

    toggleGrid = (isList) => {
        if (isList) {
            this.setState({isListView:true, currentValue:DEFAULT_PAGE_OPTION, currentPageSize:DEFAULT_PAGE_SIZE, selectedIndex:0 })
            const noGridLocation = window.location.href.replace('/grid', '')
            window.history.replaceState(null, '', noGridLocation)
        } else {
            this.setState({isListView:false, currentValue:DEFAULT_PAGE_OPTION, currentPageSize:9, selectedIndex:0 })
            if (!window.location.href.includes('grid')) {
                const gridLocation = window.location.href + '/grid'
                window.history.replaceState(null, '', gridLocation)
            }
        }
    }

    selectedLotsQuantity = () => Object.values(this.props.selectedLots).reduce((acc, currentValue) => acc + currentValue.quantity, 0)

    addSelectedLotsToCart = () => {
        const lotsToBuy = Object.values(this.props.selectedLots)
        this.props.addCartBulkLots(this.props.productId, lotsToBuy, !!this.props.outlet)
    }

    // showLotsModal = lots => this.setState({showLotsModal: true, lotList: lots})
    // closeLotsModal = () => this.setState({showLotsModal: false, lotList: []})



    render() {
        const {isListView} = this.state
        let pagesCount = 0
        if (this.props.lots) {
            pagesCount = Math.ceil(this.props.lots.length / this.state.currentPageSize)
        }

        const selectedLotsQuantity = this.selectedLotsQuantity()
        const key = uuidv4()

        return (


            <div id="lots-list">

                <div className="lots__list__header">
                    <div className="lots__list__header--counter">
                        <span><Translation id="show-n-results" defaultMessage="Showing {count} results" values={{count:this.props.lots.length}}/></span>
                    </div>

                    {!this.props.minimumOrder &&
                        <div className="lots__list__header--tabs">
                                <button className={`list-view g-sel ${isListView ? 'active' : ''}`} onClick={() => this.toggleGrid(true)} gtm-label="ec_ver_lista">
                                    <i className="fa fa-list"/>
                                    <Translation id="list_view" defaultMessage="List view" />
                                </button>
                                <button className={`grid-view g-sel ${!isListView ? 'active' : ''}`} onClick={() => this.toggleGrid(false)} gtml-label="ec_ver_cuadricula">
                                    <i className="fa fa-th"/>
                                    <Translation id="grid_view" defaultMessage="Grid view" />
                                </button>
                        </div>
                    }
                </div>

                {
                    this.state.isListView ? (
                        <LotsTable
                            {...this.props}
                            selectedIndex={this.state.selectedIndex}
                            pageSize={this.state.currentPageSize}
                            key={key}
                            // showLotsModal={this.showLotsModal}

                            // center={this.props.center}
                            // isAdmin={this.props.isAdmin}
                            // original={this.props.original}
                            // lotsImages={this.props.lotsImages}
                            // lots={this.props.lots}
                            // outlet={this.props.outlet}
                            // impersonatedBy={this.props.impersonatedBy}
                            // isUsaOrCanada={this.props.isUsaOrCanada}
                            // selectedProduct={this.props.selectedProduct}
                        />
                    ) : (
                        <LotsGrid
                            {...this.props}
                            selectedIndex={this.state.selectedIndex}
                            pageSize={this.state.currentPageSize}
                            key={key}
                            // showLotsModal={this.showLotsModal}

                            // center={this.props.center}
                            // isAdmin={this.props.isAdmin}
                            // original={this.props.original}
                            // lotsImages={this.props.lotsImages}
                            // lots={this.props.lots}
                            // outlet={this.props.outlet}
                            // impersonatedBy={this.props.impersonatedBy}
                            // isUsaOrCanada={this.props.isUsaOrCanada}
                            // isNewVersion={this.props.isNewVersion}
                            // selectedProduct={this.props.selectedProduct}
                        />
                    )
                }

                <div className="d-flex mt-2">
                    <div className={this.props.newVersion ? 'v2 paginator d-flex flex-grow-1 justify-content-center' : 'paginator d-flex flex-grow-1 justify-content-center'}>
                            <ReactPaginate
                                breakLabel="..."
                                previousLabel={<span><i className="fas fa-chevron-left mr-3" /></span>}
                                nextLabel={<span><i className="fas fa-chevron-right ml-3" /></span>}
                                onPageChange={ (e) => this.setState({selectedIndex: e.selected}) }
                                //pageRangeDisplayed={1}
                                //marginPagesDisplayed={4}
                                pageCount={pagesCount}
                                renderOnZeroPageCount={null}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link page-nav"
                                nextClassName="page-item"
                                nextLinkClassName="page-link page-nav"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                forcePage={this.state.selectedIndex}
                            />
                    </div>
                        {this.state.isListView && (
                            <div style={{width:"120px", flexGrow:0}}>
                                <Select
                                    //placeholder={this.props.intl.formatMessage({ id: '32 rows' })}
                                    clearable={false}
                                    value={this.state.currentValue}
                                    options={[
                                        {
                                            label: "8 rows",
                                            value: 8
                                        },
                                        {
                                            label: "16 rows",
                                            value: 16
                                        },
                                        {
                                            label: "32 rows",
                                            value: 32
                                        },
                                        {
                                            label: "64 rows",
                                            value: 64
                                        },
                                        {
                                            label: "128 rows",
                                            value: 128
                                        }
                                    ]}
                                    onChange={selectedOption => this.setState({currentValue: selectedOption, currentPageSize : selectedOption.value})}
                                />
                            </div>
                        )
                    }
                </div>

                {this.props.minimumOrder && <div className="my-2" style={{backgroundColor:'black', width:'100%', height:'1px'}}/>}

                <div className="row no-gutters mt-2" style={{width: '100%'}}>
                    {this.state.showAdvisorAlert && (
                            <div className="col-12 col-lg-6">
                                <div>
                                    <CloseableAlerts alerts={[{id:'LOTS_IMAGE_MAY_NOT_MATCH'},{id:'AVG_BUNDLE_SIZE'}]} status="warning" onClose={()=>this.setState({showAdvisorAlert:false})}/>
                                </div>
                            </div>
                    )}
                    {this.state.isListView && !this.props.isAdmin && this.props.minimumOrder && (
                        <div className="col-12 col-lg-6 mt-2 mt-lg-0 d-flex justify-content-end align-items-center" style={{columnGap: '24px'}}>
                            {/*<div className="d-flex flex-column align-items-end">*/}
                            <div>
                                <div style={{fontSize: '1em',fontWeight: 500, textAlign:'end'}}>
                                    <Translation id="added_units" defaultMessage="Added units"/>:&nbsp;{selectedLotsQuantity}
                                </div>
                                <div style={{fontSize: '0.72em',fontWeight: 100, textAlign:'end'}}>
                                    <Translation id="minimun_order" defaultMessage="Minimun order"/>::&nbsp;{this.props.minimumOrder}
                                </div>

                            </div>
                            <Button onClick={this.addSelectedLotsToCart}
                                    inverted icon="shopping-cart"
                                    size="medium"
                                    disabled={selectedLotsQuantity < this.props.minimumOrder}
                                    customClass="btn-purchase-list justify-content-center"
                                    style={{padding:'4px', minWidth:'96px'}}
                            >
                                <Translation id="add" defaultMessage="Add" />
                            </Button>
                        </div>
                    )}
                </div>


                {/*{*/}
                {/*    this.state.showLotsModal && (*/}
                {/*        <Modal title={*/}
                {/*            <div>*/}
                {/*                <Translation id="bundle_lots" defaultMessage="Bundle lots" />*/}
                {/*                &nbsp;({this.state.lotList.length})*/}
                {/*            </div>*/}
                {/*        }*/}
                {/*               onClose={this.closeLotsModal}*/}
                {/*               customClass="modal__lots__ids"*/}
                {/*        >*/}
                {/*            <ReactTable*/}
                {/*                // data={this.state.lotList}*/}
                {/*                data={this.state.lotList}*/}
                {/*                columns={[*/}
                {/*                    {*/}
                {/*                        Header: <Translation id="lot_id" defaultMessage="Lot id" />,*/}
                {/*                        Cell: ({ original }) => {*/}
                {/*                            return (*/}
                {/*                                <span*/}
                {/*                                    className="lot_id_row"*/}
                {/*                                >*/}
                {/*                                    {original.lotId}*/}
                {/*                                </span>*/}
                {/*                            )*/}
                {/*                        }*/}
                {/*                    }*/}
                {/*                ]}*/}
                {/*                defaultPageSize={4}*/}
                {/*                pageSizeOptions={[4, 8, 16, 32]}*/}
                {/*                previousText={<Translation id="previous" defaultMessage="Previous" />}*/}
                {/*                nextText={<Translation id="next" defaultMessage="Next" />}*/}
                {/*                noDataText={<Translation id="no_data" defaultMessage="No data available" />}*/}
                {/*                pageText={<Translation id="page" defaultMessage="Page" />}*/}
                {/*                ofText={<Translation id="of" defaultMessage="Of" />}*/}
                {/*                // PaginationComponent={ReactTablePaginationCount}*/}
                {/*            />*/}
                {/*        </Modal>*/}
                {/*    )*/}
                {/*}*/}



                {/*{*/}
                {/*    this.state.isListView && !this.props.isNewVersion && (*/}
                {/*        <div style={{width:"120px", flexGrow:0}}>*/}
                {/*            <Select*/}
                {/*                //placeholder={this.props.intl.formatMessage({ id: '32 rows' })}*/}
                {/*                clearable={false}*/}
                {/*                value={this.state.currentValue}*/}
                {/*                options={[*/}
                {/*                    {*/}
                {/*                        label: "8 rows",*/}
                {/*                        value: 8*/}
                {/*                    },*/}
                {/*                    {*/}
                {/*                        label: "16 rows",*/}
                {/*                        value: 16*/}
                {/*                    },*/}
                {/*                    {*/}
                {/*                        label: "32 rows",*/}
                {/*                        value: 32*/}
                {/*                    },*/}
                {/*                    {*/}
                {/*                        label: "64 rows",*/}
                {/*                        value: 64*/}
                {/*                    },*/}
                {/*                    {*/}
                {/*                        label: "128 rows",*/}
                {/*                        value: 128*/}
                {/*                    }*/}
                {/*                ]}*/}
                {/*                onChange={selectedOption => this.setState({currentValue: selectedOption, currentPageSize : selectedOption.value})}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    )*/}
                {/*}*/}



            </div>
        )
    }
}

export default injectIntl(LotsList)
