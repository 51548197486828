import axios from 'axios'
import FileDownload from "js-file-download";

const ApiLocation = process.env.REACT_APP_API_HOST
axios.defaults.headers.common['Content-Type'] = 'application/json'


export const fetchCutToSizeMainParams = (lang, token, centerId) => {
    return axios.get(`${ApiLocation}/cut-to-size/main_params/${centerId}?lang=${lang}`
        ,{headers: { Authorization: `Bearer ${token}` }})
}

export const fetchCutToSizeSecondaryParams = (elaboration, brand, color, pricingGroup, hasSoleriaDekton, token) => {
    return axios.post(`${ApiLocation}/cut-to-size/secondary_params`,
        { elaboration, brand, color, hasSoleriaDekton, pricingGroup },
        {headers: { Authorization: `Bearer ${token}` }})
}

export const askForBudget = (formData, token) => {
    return axios.post(`${ApiLocation}/cut-to-size/ask_for_budget`, formData,
        {headers: { Authorization: `Bearer ${token}` }})
}

export const preOrderCts = (formData, token) => {
    return axios.post(`${ApiLocation}/cut-to-size/pre-order-cts`, formData,
        {headers: { Authorization: `Bearer ${token}` }})
}

export const preOrderCtsList = (token,startDate,endDate,project,status,search,page) => {
    const body = { project, startDate, endDate, status, search, page }
    return axios.post(`${ApiLocation}/cut-to-size/pre-orders-cts-list`, body, {headers: { Authorization: `Bearer ${token}` }})
}
export const fetchPreOrdersProjectAvailables = token => {
           return axios.get(`${ApiLocation}/cut-to-size/pre-orders-cts-project-list`, { headers: { Authorization: `Bearer ${token}` } })
       }
export const downloadPackagingTypesPdf = (token) => {
    const FileDownload = require('js-file-download');
    return axios.get(process.env.REACT_APP_API_HOST + `/cut-to-size/get-packaging-types-document`,
        {
            headers: {Authorization: `Bearer ${token}`},
            responseType: 'blob'
        }).then((response) => {
        FileDownload(response.data, `PackagingTypes.pdf`);
    });
}

export const downloadPreorderFile = (token, file) => {
    const FileDownload = require('js-file-download');
    return axios.post(process.env.REACT_APP_API_HOST + `/cut-to-size/get-preorder-file`, {file_data: file},
        {
            headers: {Authorization: `Bearer ${token}`},
            responseType: 'blob'
        }).then((response) => {
        FileDownload(response.data, file.file_name);
    });
}

export const sendPreorderMessage = (formData, token) => {
    return axios.post(`${ApiLocation}/cut-to-size/send-preorder-message`, formData,
        {headers: { Authorization: `Bearer ${token}` }})
}

export const sendApprovePreorderMessage = (formData, token) => {
    return axios.post(`${ApiLocation}/cut-to-size/send-approve-preorder-message`, formData,
        {headers: { Authorization: `Bearer ${token}` }})
}

export const markMessages = (data, token) => {
    return axios.put(`${ApiLocation}/cut-to-size/mark-messages`, data,
        {headers: { Authorization: `Bearer ${token}` }})
}

export const cancelPreorder = (data, token) => {
    return axios.post(`${ApiLocation}/cut-to-size/cancel-preorder`, data,
        {headers: { Authorization: `Bearer ${token}` }})
}