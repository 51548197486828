import React from 'react'

import Translation from '../../../global/Translation'

export default class DisplayPromoIndicator extends React.Component {
    render() {
        return (
            <div className="promo-indicator">
                <Translation id="promotion" defaultMessage="Promotion" />
            </div>
        )
    }
}
