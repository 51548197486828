import { getLocaleWithft2 } from './store/products/reducers'

import moment from "moment";
import {isProductOutlet} from "./components/landing-templates/utils";

export function getReplaceDotWithComma(element, locale, state){
    if(element && state) {
        let replacement = element.toString().replace('.', ',')
        // We need . for EEUU, rest of countries is ,
        if (locale !== 'en-US') {
            return replacement
        }
        return element
    }
}

function copy(text) {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text)
    } else {
        var textArea = document.createElement('textarea')
        textArea.value = text
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        try {
            document.execCommand('copy')
        } catch (err) {}

        document.body.removeChild(textArea)
    }
}

export { copy }

const CM_IN_INCHES = 0.393701
const INCH_IN_CM = 2.5400013716

export function convertCentimetersToInches(cm, decimals = 2) {
    return parseFloat((cm * CM_IN_INCHES).toFixed(decimals))
}

export function convertInchesToCentimeters(inches, decimals = 2) {
    return parseFloat((inches * INCH_IN_CM).toFixed(decimals))
}

export function hasImperialUnits(state) {
    if (state) {
        if (state.profileConfiguration.profileConfiguration && state.profileConfiguration.profileConfiguration.lengthUnit) {
            return state.profileConfiguration.profileConfiguration.lengthUnit === 'FT2'
        }
        const localeWithFt = getLocaleWithft2()
        return localeWithFt.includes(state.ui.language)
    }
}

export function getAdaptedFormat(format, params, state) {
    if (format && state) {
        const isImperialUnits = hasImperialUnits(state) 
        let measures = format.split(/[xX]/)
        if (measures.length === 2) {
            let separator = 'x'
            let decimals = isImperialUnits ? 2 : 0
            let units = (params && params.showUnits) ? ` ${getCmOrInches(state)}` : ''
            if (params && params.upper) separator = 'X'
            if (params && params.spaced) separator = ` ${separator} `
            if (params && params.decimals) decimals = params.decimals

            if (isImperialUnits) {
                measures = measures.map(item => {
                    let newMeasures = item.replace(/,/g, '.')
                    return convertCentimetersToInches(parseFloat(newMeasures), decimals)
                })
            }
            return `${measures[0]}${separator}${measures[1]}${units}`
        } else {
            return format
        }
    }
}

export function getMeasureFromMetric(measure, decimals, state) {
    return hasImperialUnits(state) ? convertCentimetersToInches(measure, decimals) : measure
}

export function getMetricMeasureFromInput(measure, decimals, state) {
    return hasImperialUnits(state) ? convertInchesToCentimeters(measure, decimals) : measure
}

export function getCmOrInches(state) {
    return hasImperialUnits(state) ? 'in' : 'cm'
}

export function getNextWorkingDay(days) {
    let plannedDay = moment().startOf('day').add(days, 'days')
    let plannedWeekDay = plannedDay.day()
    if (plannedWeekDay === 0) {
        plannedDay = plannedDay.add(1, 'days')
    }

    return plannedDay
}

export function getCtsMeasures(cts, state) {
    const units = getCmOrInches(state)
    const isImperial = units === 'in';
    if (cts.shape === 'ROUNDED') {
        const diameter = isImperial ? convertCentimetersToInches(cts.width, 1) : cts.width
        return {
            diameter: `${diameter} ${units}`
        }
    } else {
        const measures = {}
        const height = isImperial ? convertCentimetersToInches(cts.height, 1) : cts.height
        const width = isImperial ? convertCentimetersToInches(cts.width, 1) : cts.width
        measures.format = `${width}x${height} ${units}`
        if (cts.shape === 'RECTANGULAR_CURVE_RADIUS') {
            const radius = isImperial ? convertCentimetersToInches(cts.width, 1) : cts.radius
            measures.radius = `${radius} ${units}`
        }
        return measures
    }
}

// export const getLineProductStock = line => {
//     if (line && line.product) {
//         if (line.product.mall === 'X') {
//             if (line.lots) {
//                 return (line.product.productsLots && line.product.productsLots[line.productId]) ? line.product.productsLots[product.id].stock : 0
//             }
//             else return line.product.productLanding.stock_mall
//         }
//         return !!isProductOutlet(product) ? product.productLanding.stock_outlet : product.productLanding.stock
//     }
//
//     return ''
//     // product && product.productLanding && product.data ? (product.data.outlet ? (product.data.isStockOutlet ? product.productLanding.stock_outlet : product.productLanding.stock) : product.productLanding.stock) : '';
//
// }
