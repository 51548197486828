import React from 'react'
import { injectIntl } from 'react-intl'
import Translation from '../../../global/Translation'
import { ReactComponent as ShippingTruckIcon } from '../../../../assets/img/order-history/shipping-truck.svg'
import ProductImage from '../../../catalog/ProductImage'
import { capitalizeFirstLetter, addForwardSlash } from '../Utils'
import ProductDisplayName from '../../../global/ProductDisplayName'
import LineSegmentation from '../../../cart-line/LineSegmentation'
import { OrderLineStatus } from '../Enums/OrderLineStatus'
import moment from 'moment'
import {ElaborationOptionsMapById} from "../../../cut-to-size/CutToSizeMaps";

class NewOrderCardItem extends React.Component {
    render() {
        const { order, orderLine, orderStatusMap, intl, adaptedFormat, isIkeaCenter, redirect, replaceDotWithComma, locale, getMeasure } = this.props

        const showBtnToDetails = () => {
            if (order.orderId && order.reservationId && order.orderLines.length > 0 && !isIkeaCenter) return true
            return false
        }

        const handleBtnToDetailsOnClick = () => {
            redirect(
                intl
                    .formatMessage({ id: 'ROUTE_ORDER_DETAILS' })
                    .replace(':filter', 'view-order')
                    .replace(':reservationId', order.reservationId)
            )
        }

        const thicknessFormatIntl = orderLine => {
            if (!orderLine.product.thickness) return null
            return replaceDotWithComma(orderLine.product.thickness, locale) + ' cm'
        }

        const zaddonFormatIntl = orderLine => {
            if (orderLine.product.zaddon !== 'MAL') return null
            return intl.formatMessage({ id: 'MAL' })
        }

        const thicknessFormatCtsIntl = cts => {
            if (!cts.thickness) return null
            return replaceDotWithComma(cts.thickness, locale) + ' cm'
        }

        const finishFormatIntl = (orderLine, intl) => {
            if (!orderLine.product.finish) return null
            return intl.formatMessage({ id: orderLine.product.finish })
        }

        const finishFormatCtsInt = cts => {
            if (!cts.finish) return null
            return intl.formatMessage({ id: cts.finish })
        }

        const productFormatIntl = orderLine => {
            if (!orderLine.product.format) return null
            return adaptedFormat(orderLine.product.format, { upper: true, showUnits: true })
        }

        const validOrderLineStatus = orderLine && orderLine.status && orderLine.status.status ? orderLine.status.status : order.orderStatusV3.status
        const validOrderLineStatusDate = orderLine && orderLine.status && orderLine.status.date ? orderLine.status.date : order.orderStatusV3.date

        const orderLinesStatusColor = () => {
            if (validOrderLineStatus === OrderLineStatus.Created) return '--position-created'
            if (validOrderLineStatus === OrderLineStatus.Approved) return '--position-confirmed'
            if (validOrderLineStatus === OrderLineStatus.Pending) return '--position-pending'
            if (validOrderLineStatus === OrderLineStatus.In_process) return '--position-in-process'
            if (validOrderLineStatus === OrderLineStatus.Partially_delivered) return '--position-partially-delivered'
            if (validOrderLineStatus === OrderLineStatus.Delivered) return '--position-delivered'
            if (validOrderLineStatus === OrderLineStatus.Order_cancelled) return '--position-cancelled'
        }

        const deliveryStatusLogic = () => {
            if (validOrderLineStatus === OrderLineStatus.Created && validOrderLineStatusDate == null) {
                return (
                    <div className="shipping__status__gray">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="no_planned_delivery" defaultMessage="No planned delivery" />
                            </span>
                        </div>
                    </div>
                )
            }

            if (validOrderLineStatus === OrderLineStatus.Created && validOrderLineStatusDate !== null) {
                return (
                    <div className="shipping__status__blue">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="estimated_delivery" defaultMessage="Estimated delivery" />
                            </span>
                            :&nbsp;
                            <span>{moment(validOrderLineStatusDate).isSame(moment(), 'day') ? <Translation id="today" defaultMessage="Today" /> : moment(validOrderLineStatusDate).format('L')}</span>
                        </div>
                    </div>
                )
            }

            if (validOrderLineStatus === OrderLineStatus.Approved && validOrderLineStatusDate == null) {
                return (
                    <div className="shipping__status__gray">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="no_planned_delivery" defaultMessage="No planned delivery" />
                            </span>
                        </div>
                    </div>
                )
            }

            if (validOrderLineStatus === OrderLineStatus.Approved && validOrderLineStatusDate !== null) {
                return (
                    <div className="shipping__status__blue">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="estimated_delivery" defaultMessage="Estimated delivery" />
                            </span>
                            :&nbsp;
                            <span>{moment(validOrderLineStatusDate).isSame(moment(), 'day') ? <Translation id="today" defaultMessage="Today" /> : moment(validOrderLineStatusDate).format('L')}</span>
                        </div>
                    </div>
                )
            }

            if (validOrderLineStatus === OrderLineStatus.Pending && validOrderLineStatusDate == null) {
                return (
                    <div className="shipping__status__gray">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="no_planned_delivery" defaultMessage="No planned delivery" />
                            </span>
                        </div>
                    </div>
                )
            }

            if (validOrderLineStatus === OrderLineStatus.Pending && validOrderLineStatusDate !== null) {
                return (
                    <div className="shipping__status__blue">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="estimated_delivery" defaultMessage="Estimated delivery" />
                            </span>
                            :&nbsp;
                            <span>{moment(validOrderLineStatusDate).isSame(moment(), 'day') ? <Translation id="today" defaultMessage="Today" /> : moment(validOrderLineStatusDate).format('L')}</span>
                        </div>
                    </div>
                )
            }

            if (validOrderLineStatus === OrderLineStatus.In_process && validOrderLineStatusDate == null) {
                return (
                    <div className="shipping__status__gray">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="no_planned_delivery" defaultMessage="No planned delivery" />
                            </span>
                        </div>
                    </div>
                )
            }

            if (validOrderLineStatus === OrderLineStatus.In_process && validOrderLineStatusDate !== null) {
                return (
                    <div className="shipping__status__blue">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="estimated_delivery" defaultMessage="Estimated delivery" />
                            </span>
                            :&nbsp;
                            <span>{moment(validOrderLineStatusDate).isSame(moment(), 'day') ? <Translation id="today" defaultMessage="Today" /> : moment(validOrderLineStatusDate).format('L')}</span>
                        </div>
                    </div>
                )
            }

            if (validOrderLineStatus === OrderLineStatus.Partially_delivered) {
                return (
                    <div className="shipping__status__blue__partially">
                        <div className="title">
                            <div>
                                <ShippingTruckIcon />
                            </div>
                            <div>
                                <span>
                                    <Translation id="product_has_multiple_deliveries" defaultMessage="This product has multiple deliveries" />
                                </span>
                            </div>
                        </div>
                        {showBtnToDetails() && (
                            <div className="subtitle">
                                <span>
                                    <Translation id="check_deliveries_at" defaultMessage="Check deliveries at" />
                                    &nbsp;
                                    <button onClick={() => handleBtnToDetailsOnClick()}>
                                        <Translation id="order_details" defaultMessage="Order details" />
                                    </button>
                                </span>
                            </div>
                        )}
                    </div>
                )
            }

            if (validOrderLineStatus === OrderLineStatus.Delivered && validOrderLineStatusDate == null) {
                return (
                    <div className="shipping__status__green">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="entregado" defaultMessage="Delivered" />
                            </span>
                        </div>
                    </div>
                )
            }

            if (validOrderLineStatus === OrderLineStatus.Delivered && validOrderLineStatusDate !== null) {
                return (
                    <div className="shipping__status__green">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="entregado" defaultMessage="Delivered" />
                            </span>
                            :&nbsp;
                            <span>{moment(validOrderLineStatusDate).isSame(moment(), 'day') ? <Translation id="today" defaultMessage="Today" /> : moment(validOrderLineStatusDate).format('L')}</span>
                        </div>
                    </div>
                )
            }

            if (validOrderLineStatus === OrderLineStatus.Order_cancelled && orderLine.reject_reason === 'D1') {
                return (
                    <div className="shipping__status__red">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="product_canceled_by_user" defaultMessage="Product canceled by user" />
                            </span>
                        </div>
                    </div>
                )
            }

            if (validOrderLineStatus === OrderLineStatus.Order_cancelled && orderLine.reject_reason !== 'D1') {
                return (
                    <div className="shipping__status__red">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="product_canceled_by_center" defaultMessage="Product cancelled by center" />
                            </span>
                        </div>
                    </div>
                )
            }
        }

        const positionStatus = (
            <div className="position__status">
                <div>
                    <div className={'position__status--dot ' + orderLinesStatusColor()}></div>
                </div>
                <div>
                    <span className={'position__status--text ' + orderLinesStatusColor()}>
                        {validOrderLineStatus ? intl.formatMessage({ id: orderStatusMap[validOrderLineStatus].textToShow }) : intl.formatMessage({ id: orderStatusMap[order.orderStatusV3.status].textToShow })}
                    </span>
                </div>
            </div>
        )

        //Third column
        const deliveryStatusBlock = (
            <div className="shipping__status__content">
                <div className="d-block d-md-none">{positionStatus}</div>
                <div>{deliveryStatusLogic()}</div>
            </div>
        )

        //Second column
        const segmentationBlock = (
            <React.Fragment>
                {orderLine.segmentation && (
                    <div className="card__item__segmentation--desktop">
                        <LineSegmentation line={orderLine} showAsList />
                    </div>
                )}
            </React.Fragment>
        )

        const cutToSizeDescriptionBlock = orderLine => {
            const cts = orderLine.cut_to_size
            const measures = getMeasure(cts)
            const materialId =  ElaborationOptionsMapById[orderLine.material_id] ? this.props.intl.formatMessage(ElaborationOptionsMapById[orderLine.material_id].name) : orderLine.material_id
            const features = [
                materialId,
                measures.format,
                finishFormatCtsInt(cts),
                thicknessFormatCtsIntl(cts)
            ]
            if (orderLine.cut_to_size.addon === 'MAL') features.push(this.props.intl.formatMessage({id:'MAL'}))

            return (
                <div>
                    <div className="order-divider-x"></div>
                    <div className="order__card__item order__card__item__grid">
                        {/* Firts column */}
                        <div className="card__item__productinfo">
                            <div className="card__item__productinfo--img">
                                <img src={cts.thumb} alt="Cut to size" />
                            </div>
                            <div className="card__item__productinfo--text">
                                <div>
                                    <span className="brand">{capitalizeFirstLetter(cts.brand)} &reg;</span>
                                </div>

                                <div className="product">
                                    <span>{capitalizeFirstLetter(cts.colorName)}</span>
                                </div>

                                <div>
                                    <span className="technical">
                                        {addForwardSlash(...features)}
                                    </span>
                                </div>

                                <div>
                                    <span className="qty">
                                        <Translation id="quantity" defaultMessage="Quantity" />
                                        :&nbsp;
                                        {orderLine.reservation_quantity ? (
                                            parseInt(orderLine.reservation_quantity) === 0 ? (
                                                <Translation id="product_added_by_center" defaultMessage="Product added by center" />
                                            ) : (
                                                <React.Fragment>{parseInt(orderLine.reservation_quantity)}</React.Fragment>
                                            )
                                        ) : (
                                            <Translation id="not_available" defaultMessage="No available" />
                                        )}
                                    </span>
                                </div>

                                {orderLine.segmentation && (
                                    <div className="card__item__segmentation--tablet">
                                        <LineSegmentation line={orderLine} showAsList />
                                    </div>
                                )}

                                {/* Position status desktop*/}
                                <div className="d-none d-md-block">{positionStatus}</div>
                            </div>
                        </div>

                        {/* Second column */}
                        {segmentationBlock}

                        {/* Third column */}
                        {deliveryStatusBlock}
                    </div>
                </div>
            )
        }
        
        const descriptionBlock = orderLine => {
            return (
                <div>
                    <div className="order-divider-x"></div>
                    <div className="order__card__item order__card__item__grid">
                        {/* Firts column */}
                        <div className="card__item__productinfo">
                            <div className="card__item__productinfo--img">
                                <ProductImage product={orderLine.product} />
                            </div>
                            <div className="card__item__productinfo--text">
                                <div>
                                    <span className="brand">{capitalizeFirstLetter(orderLine.product.brand)} &reg;</span>
                                </div>
                                <div>
                                    <span className="product">
                                        <ProductDisplayName product={orderLine.product} />
                                    </span>
                                </div>
                                <div>
                                    <span className="technical">
                                        {addForwardSlash(
                                            orderLine.product.productId,
                                            productFormatIntl(orderLine),
                                            finishFormatIntl(orderLine, intl),
                                            thicknessFormatIntl(orderLine),
                                            zaddonFormatIntl(orderLine)
                                        )}
                                    </span>
                                </div>

                                {orderLine.lot_id && (
                                    <div className="lot-id">
                                        <span>
                                            <Translation id="lot_id" defaultMessage="Lot ID" />
                                            :&nbsp;
                                        </span>
                                        <span>{orderLine.lot_id}</span>
                                    </div>
                                )}

                                <div>
                                    <span className="qty">
                                        <Translation id="quantity" defaultMessage="Quantity" />
                                        :&nbsp;
                                        {orderLine.reservation_quantity ? (
                                            parseInt(orderLine.reservation_quantity) === 0 ? (
                                                <Translation id="product_added_by_center" defaultMessage="Product added by center" />
                                            ) : (
                                                <React.Fragment>{parseInt(orderLine.reservation_quantity)}</React.Fragment>
                                            )
                                        ) : (
                                            <Translation id="not_available" defaultMessage="No available" />
                                        )}
                                    </span>
                                </div>

                                {orderLine.segmentation && (
                                    <div className="card__item__segmentation--tablet">
                                        <LineSegmentation line={orderLine} showAsList />
                                    </div>
                                )}

                                {/* Position status desktop*/}
                                <div className="d-none d-md-block">{positionStatus}</div>
                            </div>
                        </div>

                        {/* Second column */}
                        {segmentationBlock}

                        {/* Third column */}
                        {deliveryStatusBlock}
                    </div>
                </div>
            )
        }

        return (
            <React.Fragment>
                {orderLine.product ? (
                    <React.Fragment>
                        {descriptionBlock(orderLine)}                       
                    </React.Fragment>
                ) : orderLine.cut_to_size ? (
                    <React.Fragment>
                        {cutToSizeDescriptionBlock(orderLine)}
                    </React.Fragment>
                ) : (
                    <div>
                        <div className="order-divider-x"></div>
                        <div className="order__card__item order__card__item__grid">
                            {/* First column */}
                            <div className="card__item__productinfo">
                                <div className="card__item__productinfo--img">
                                    <ProductImage product={null} />
                                </div>
                                <div className="card__item__productinfo--text">
                                    {orderLine.short_text && (
                                        <div>
                                            <span className="product">
                                                <span>{capitalizeFirstLetter(orderLine.short_text)}</span>
                                            </span>
                                        </div>
                                    )}
                                    {orderLine.material_id && (
                                        <div>
                                            <span className="technical">{orderLine.material_id}</span>
                                        </div>
                                    )}

                                    {orderLine.lot_id && (
                                        <div className="lot-id">
                                            <span>
                                                <Translation id="lot_id" defaultMessage="Lot ID" />
                                                :&nbsp;
                                            </span>
                                            <span>{orderLine.lot_id}</span>
                                        </div>
                                    )}

                                    <div>
                                        <span className="qty">
                                            <Translation id="quantity" defaultMessage="Quantity" />
                                            :&nbsp;
                                            {orderLine.reservation_quantity ? (
                                                parseInt(orderLine.reservation_quantity) === 0 ? (
                                                    <Translation id="product_added_by_center" defaultMessage="Product added by center" />
                                                ) : (
                                                    <React.Fragment>{parseInt(orderLine.reservation_quantity)}</React.Fragment>
                                                )
                                            ) : (
                                                <Translation id="not_available" defaultMessage="No available" />
                                            )}
                                        </span>
                                    </div>

                                    {orderLine.segmentation && (
                                        <div className="card__item__segmentation--tablet">
                                            <LineSegmentation line={orderLine} showAsList />
                                        </div>
                                    )}

                                    {/* Position status desktop*/}
                                    <div className="d-none d-md-block">{positionStatus}</div>
                                </div>
                            </div>
                            {/* Second column */}
                            {segmentationBlock}

                            {/* Third column */}
                            {deliveryStatusBlock}
                        </div>
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default injectIntl(NewOrderCardItem)
