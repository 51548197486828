import React from 'react'

import LocalizedLink from '../global/LocalizedLink'
import { injectIntl } from 'react-intl'
import ProfileLinks from '../header/ProfileLinks'
import { showBannerOldVersion } from '../../store/ui/reducers'
import { getVersion } from '../../store/login/reducers'
import { connect } from 'react-redux'
import ProfileContainer from '../profile/ProfileContainer'
import Translation from '../global/Translation'
import { logout } from '../../store/login/actions'
import { MsalContext } from '@azure/msal-react'

const mapStateToProps = state => {
    return {
        showBanner: showBannerOldVersion(state),
        newVersion: getVersion(state),
    }
}

class UserProfileModal extends React.Component {
// const UserProfileModal = ({ show, onLogin, onClose, callback, onLogout, username, userimage, intl, name, totalUnassignedReservations, userType, email, employeeId, showBanner, newVersion }) => {
//     var colors = [
//         '#1abc9c',
//         '#2ecc71',
//         '#3498db',
//         '#9b59b6',
//         '#34495e',
//         '#16a085',
//         '#27ae60',
//         '#2980b9',
//         '#8e44ad',
//         '#2c3e50',
//         '#f1c40f',
//         '#e67e22',
//         '#e74c3c',
//         '#95a5a6',
//         '#f39c12',
//         '#d35400',
//         '#c0392b',
//         '#bdc3c7',
//         '#7f8c8d'
//     ]

    constructor(props) {
        super(props)
    }
    render(){
        const { show, onClose, onLogout, username, name, userType, email, employeeId, showBanner, newVersion } = this.props
        let initials = name.split(' ')[0].charAt(0).toUpperCase()
        if (name.split(' ')[1]){
            initials =
                initials +name
                    .split(' ')[1]
                    .charAt(0)
                    .toUpperCase()
        }
        return (
            !newVersion ? (
                <div>
                    <div className="modal-overlay" style={{
                        display: show ? 'block' : 'none',
                    }} onClick={onClose} />
                    <div id="user-profile-modal-container" className="user-profile" style={{ display: show ? 'block' : 'none' }}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 ">
                                    <LocalizedLink routeId={'ROUTE_PROFILE'} params={{ filter: 'orders', open: '' }}>
                                        <header className="container-fluid">
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div className="circle" style={{ marginRight: '45px' }}>
                                                    <span className="initials">{initials}</span>
                                                </div>
                                                {/* <img alt="" src={userimage || iconProfile} className="icon" style={{ borderRadius: userimage ? '100%' : '' }} /> */}
                                                <span style={{ fontFamily: 'diagramm-medium', width: '250px' }}>
                                                    {userType === 'SHOP' || userType === 'LOWES' ? email : username} {name} {employeeId && <strong>{employeeId}</strong>}
                                                </span>
                                            </div>
                                        </header>
                                    </LocalizedLink>
                                    <div className="user-profile-menu">
                                        <ul>
                                            <ProfileLinks />
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="modal-overlay" style={{
                        display: show ? 'block' : 'none',
                    }} onClick={onClose} />
                    <div id="user-profile-modal-container" className="user-profile user-profile-modal-container-v2" style={{ display: show ? 'block' : 'none' }}>
                        <ProfileContainer />

                        {/*<div className="">*/}
                        {/*    <div className="">*/}
                        {/*        <div>*/}
                        {/*            <LocalizedLink routeId={'ROUTE_PROFILE'} params={{ filter: 'orders', open: '' }}>*/}
                        {/*                <div className='d-flex' style={{padding: '0.5rem 1rem', fontWeight: '300'}}>*/}
                        {/*                    <div className='mr-3'>*/}
                        {/*                        <i style={{fontSize: '40px'}} className="fas fa-user" />*/}
                        {/*                    </div>*/}
                        {/*                    <div>*/}
                        {/*                        <span>*/}
                        {/*                            {userType === 'SHOP' || userType === 'LOWES' ? email : username} {name} {employeeId && <strong>{employeeId}</strong>}*/}
                        {/*                        </span>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </LocalizedLink>*/}
                        {/*            <div className="user-profile-menu">*/}
                        {/*                <ul>*/}
                        {/*                    <ProfileLinks />*/}
                        {/*                </ul>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            )
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(UserProfileModal)
)