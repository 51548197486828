import React from 'react'

import { connect } from 'react-redux'
import { getSelectedCenter } from '../../store/centers/reducers'

const mapStateToProps = state => {
    return {
        selectedCenter: getSelectedCenter(state)
    }
}
const getLink = selectedCenter => {
    let centersOfSpain = ['2010', '2020', '2030', '2040', '2050', '2060', '2070', '2080', '2100', '2110', '2120', '2130', '2140', '2150', '2160', '2170', '2180', '7080', '7095']
    let centersOfIberia = ['1010', '2010', '2020', '2030', '2040', '2050', '2060', '2070', '2080', '2100', '2110', '2120', '2130', '2140', '2150', '2160', '2170', '2180']
    let centersOfEuropa = [
        '7000',
        '7010',
        '7015',
        '7016',
        '7017',
        '7030',
        '7031',
        '7032',
        '7034',
        '7035',
        '7036',
        '7037',
        '7040',
        '7050',
        '7056',
        '7060',
        '7100',
        '7110',
        '7120',
        '7125',
        '7126',
        '7127',
        '7130',
        '7140',
        '7150',
        '7160',
        '7210',
        '7220',
        '7240'
    ]
    let centersOfCanada = [
        '7590',
        '7591',
        '7593',
        '7595',
        '7601',
        '7602',
        '7603',
        '7604',
        '7605',
        '7606',
        '7607',
        '7608',
        '7609',
        '7610',
        '7611',
        '7612',
        '7613',
        '7614',
        '7615',
        '7616',
        '7617',
        '7618',
        '7619',
        '7620',
        '7621',
        '7622',
        '7623',
        '7624',
        '7625',
        '7626',
        '7627',
        '7628',
        '7629',
        '7630',
        '7631',
        '7632',
        '7633',
        '7634',
        '7635',
        '7636',
        '7637',
        '7638',
        '7640',
        '7642',
        '7643',
        '7666'
    ]
    let centersOfLatam = ['7500', '7501', '7502', '7503', '7504', '7505', '7506', '7510', '7520']
    let centersOfROW = ['7180', '7181', '7182', '7190', '7200', '7201', '7230', '7530', '7531', '7532', '7533', '7534', '7535', '7536', '7560', '7570', '7583', '7584']

    if (centersOfSpain.includes(selectedCenter)) return 'https://cosentino.my.salesforce.com/_ui/core/chatter/groups/GroupProfilePage?g=0F9b0000000c9LV'
    else if (centersOfIberia.includes(selectedCenter)) return 'https://cosentino.my.salesforce.com/_ui/core/chatter/groups/GroupProfilePage?g=0F9b0000000c9LV'
    else if (centersOfEuropa.includes(selectedCenter)) return 'https://cosentino.my.salesforce.com/_ui/core/chatter/groups/GroupProfilePage?g=0F9b0000000c9Ar'
    else if (centersOfCanada.includes(selectedCenter)) return 'https://cosentino.my.salesforce.com/_ui/core/chatter/groups/GroupProfilePage?g=0F9b0000000c9VL'
    else if (centersOfLatam.includes(selectedCenter)) return 'https://cosentino.my.salesforce.com/_ui/core/chatter/groups/GroupProfilePage?g=0F9b0000000c9S7'
    else if (centersOfROW.includes(selectedCenter)) return 'https://cosentino.my.salesforce.com/_ui/core/chatter/groups/GroupProfilePage?g=0F9b0000000c9PN'
    else return 'https://cosentino.my.salesforce.com/_ui/core/chatter/groups/GroupProfilePage?g=0F9b0000000c9Ar'
}
class GoChatter extends React.Component {
    render() {
        let { selectedCenter } = this.props
        return (
            selectedCenter && (
                <a id="chatter-doll" data-cy="user_menu__chatter_doll" href={getLink(selectedCenter.toString())} target="_blank" rel="noopener noreferrer">
                    {this.props.children}
                </a>
            )
        )
    }
}
export default connect(
    mapStateToProps,
    null
)(GoChatter)
