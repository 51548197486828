import React from 'react'
import {canViewFurniture, canViewPreorder, getVersion} from '../../store/login/reducers'
import { connect } from 'react-redux'
import MenuLinksV2 from '../header/MenuLinksV2'

import {
  canViewCatalog,
  canViewBaths,
  canViewMktools,
  canViewOutlet,
  canViewPromotions,
  getCenterMktool,
  getCenterShowroom,
  getCenterWarehose,
  getDRIUSACenterId,
  getLowesCenterId,
  getMktoolsShippingCenter,
  getSelectedCenter,
  getSelectedCenterPreferences,
  getShippingMethod,
  getShowroomCenterId,
  getWarehoseCenterId,
  hasPromoActivated,
  isNoSilestoneCenter
} from '../../store/centers/reducers'
import { changeCenter } from '../../store/centers/actions'
import { canViewCutToSize, getIsLogged, getIsMallPilotUser, getUserType } from '../../store/login/reducers'
import { getUserLandings } from '../../store/landing/reducers'
import { injectIntl } from 'react-intl'

const mapStateToProps = (state, ownProps) => {
  return {
    canViewCatalog: canViewCatalog(state),
    canViewPromotions: canViewPromotions(state),
    canViewPreorder: canViewPreorder(state),
    canViewFurniture: canViewFurniture(state),
    centerPreferences: getSelectedCenterPreferences(state),
    canViewOutlet: canViewOutlet(state),
    hasPromoActivated: hasPromoActivated(state),
    isLogged: getIsLogged(state),
    shippingMethod: getShippingMethod(state),
    userType: getUserType(state),
    selectedCenter: getSelectedCenter(state),
    centerMktool: getCenterMktool(state),
    centerShowroom: getCenterShowroom(state),
    centerWarehouse: getCenterWarehose(state),
    mktoolShippingCenter: getMktoolsShippingCenter(state),
    canViewBaths: canViewBaths(state),
    userLandings: getUserLandings(state),
    isMallPilotUser: getIsMallPilotUser(state),
    warehoseCenterId: getWarehoseCenterId(),
    showroomCenterId: getShowroomCenterId(),
    DRIUSACenterId: getDRIUSACenterId(),
    lowesCenerId: getLowesCenterId(),
    canViewMktools: canViewMktools(state),
    canViewCutToSize: canViewCutToSize(state),
    customClass: ownProps.customClass ? ownProps.customClass : null,
    newVersion: getVersion(state),
    header: ownProps.comp,
    isWarehouse: getSelectedCenter(state) == getWarehoseCenterId(),
    isNoSilestoneCenter: isNoSilestoneCenter(state)
  }
}
const mapDispatchToProps = dispatch => {
  return {
    changeCenter: centerId => {
      dispatch(changeCenter(centerId))
    }
  }
}
//Cambiar MenuLinks 1st por MenuLinksV2 cuando exista


const Menu = React.forwardRef((props, ref) =>  {
  //old version null
  //new version true
  //return(!props.newVersion ? <MenuLinksV2 ref={ref} {...props} /> : <MenuLinks {...props} />)
  return (<MenuLinksV2 ref={ref} {...props} />)
}
)

const MenuContainer = injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(Menu)
)

export default  MenuContainer
