import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import OrdersContainer from '../components/orders/OrdersContainer'
import PreOrdersCtsContainer from '../components/orders/PreOrdersCtsContainer'
import ProfileConfigurationContainer from '../components/profile/ProfileConfigurationContainer'
import DirectSlabAccessContainer from '../components/direct-slab-access/DirectSlabAccessContainer'
import { injectIntl } from 'react-intl'
import { getUserType, getVersion } from '../store/login/reducers'
import ProductsFavouriteContainer from '../components/products-favourite/ProductFavouriteContainer'
import RecurringOrdersContainer from '../components/recurring-orders/RecurringOrdersContainer'
import ProfileSegmentation from '../components/segmentation/ProfileSegmentation'
import TransfersContainer from '../components/orders/TransfersContainer'
import { getIsMkToolsCenter, getIsIkeaCenter, getIsLowesCenter, canOffer } from '../store/centers/reducers'
import Error404Screen from '../screens/Error404Screen'
import LowesOrders from '../components/orders/LowesOrders'
import ProfileLowesConfigurationContainer from '../components/profile/ProfileLowesConfigurationContainer'
import PreOrdersCtsDetailsContainer from '../components/orders/PreOrdersCtsDetailsContainer'
import OfferStatusHistoryContainer from '../components/orders/OfferStatusHistoryContainer'
import { getLocalizedUrl, showMenuSelector, showPersonalDataSelector, getIsOrderHistoryScreen, getIsShippingHistoryScreen } from '../store/ui/reducers'
import { toggleMenu, showPersonalData } from '../store/ui/actions'
import Translation from '../components/global/Translation'
//New Orders, Shipping history
import NewOrderHistoryContainer from '../components/orders/new-order-history/order/NewOrderHistoryContainer'
import NewOrderDetailsContainer from '../components/orders/new-order-history/order/NewOrderDetailsContainer'
import NewShippingHistoryContainer from '../components/orders/new-order-history/shipping/NewShippingHistoryContainer'
import NewShippingDetailsContainer from '../components/orders/new-order-history/shipping/NewShippingDetailsContainer'

const mapDispatchToProps = dispatch => {
    return {
        onToggleMenu: active => {
            dispatch(toggleMenu(active))
        },
        onTogglePersonalData: active => {
            dispatch(showPersonalData(active))
        },
    }
}

const mapStateToProps = (state, props) => {
    return {
        showMenu: showMenuSelector(state),
        showPersonalData: showPersonalDataSelector(state),
        userType: getUserType(state),
        isMkToolsCenter: getIsMkToolsCenter(state),
        isIkeaCenter: getIsIkeaCenter(state),
        isLowesCenter: getIsLowesCenter(state),
        canOffer: canOffer(state),
        localizedUrl: (routeId) => getLocalizedUrl(state, props.intl, routeId),
        newVersion: getVersion(state),
        isOrderHistoryScreen: getIsOrderHistoryScreen(state),
        isShippingHistoryScreen: getIsShippingHistoryScreen(state)
    }
}
class ProfileScreen extends React.Component {
    canRouteConfiguration = () => true
    canRouteOrders = () => this.props.userType === 'CUSTOMER' || this.props.isLowesCenter
    canRouteEditOrder = () => !this.props.isLowesCenter
    canRouteTransfers = () => this.props.isMkToolsCenter && this.props.userType === 'ADMIN'
    canRouteDirectSlabAccess = () => !this.props.isIkeaCenter && !this.props.isLowesCenter
    canRouteProductsFavourite = () => !this.props.isLowesCenter
    canRouteRecurringOrders = () => !this.props.isLowesCenter
    canRouteSegmentation = () => !this.props.isLowesCenter
    canRouteOfferStatusHistory = () => this.props.canOffer

    render() {
        const globalGoBackProfile = (
            <div className="go-back-profile">
                <i className="fal fa-chevron-left"></i>
                <span
                    className="label label-v2"
                    onClick={() => {                    
                        if (!this.props.showMenu) this.props.onToggleMenu(this.props.showMenu)
                        if (this.props.showMenu && this.props.showPersonalData) {
                            this.props.onToggleMenu(this.props.showMenu)
                        }
                    this.props.onTogglePersonalData(this.props.showPersonalData)}}>
                    <Translation id="back_to_personal_area" defaultMessage="Back to personal area"/>
                </span>
            </div>
        )
        return (
            <div>
                <section id="section-profile">
                    <div className="container">
                        <div className="row" />
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <br />                              
                                <div className="profile-form">
                                    {!this.props.isOrderHistoryScreen && !this.props.isShippingHistoryScreen && (
                                        globalGoBackProfile
                                    )}
                                    <Switch>
                                        {this.canRouteConfiguration() && (
                                            <Route path={this.props.localizedUrl('ROUTE_PROFILE').replace(':filter', 'configuration')} component={this.props.isLowesCenter ? ProfileLowesConfigurationContainer : ProfileConfigurationContainer} />
                                        )}
                                        {this.canRouteOrders() && (
                                            <Route path={this.props.localizedUrl('ROUTE_PROFILE').replace(':filter', 'orders')} component={this.props.isLowesCenter ? LowesOrders : OrdersContainer} />
                                        )}                  
                                        {/* New Order History Start */}
                                        {/* {this.canRouteOrders() && (
                                            <Route path={this.props.localizedUrl('ROUTE_PROFILE').replace(':filter', 'orders')} component={NewOrderHistoryContainer} />
                                        )} */}
                                        {this.canRouteOrders() && (
                                            <Route path={this.props.localizedUrl('ROUTE_ORDER_DETAILS').replace(':filter', 'view-order')} component={NewOrderDetailsContainer} />
                                        )}
                                        {this.canRouteOrders() && (
                                            <Route path={this.props.localizedUrl('ROUTE_PROFILE').replace(':filter', 'shipping-history')} component={NewShippingHistoryContainer} />
                                        )}
                                        {this.canRouteOrders() && (
                                            <Route path={this.props.localizedUrl('ROUTE_SHIPPING_DETAILS').replace(':filter', 'view-shipping')} component={NewShippingDetailsContainer} />
                                        )}
                                        {/* New Order History End */}
                                        {this.canRouteOrders() && (
                                            <Route path={this.props.localizedUrl('ROUTE_PROFILE').replace(':filter', 'pre-orders-cts')} component={PreOrdersCtsContainer} />
                                        )}           
                                        {this.canRouteOrders() && (
                                            <Route path={this.props.localizedUrl('ROUTE_PROFILE').replace(':filter', 'pre-orders-cts-details')} component={PreOrdersCtsDetailsContainer} />
                                        )}
                                        {this.canRouteTransfers() && (
                                            <Route path={this.props.localizedUrl('ROUTE_PROFILE').replace(':filter', 'transfers')} component={TransfersContainer} />
                                        )}
                                        {this.canRouteDirectSlabAccess() && (
                                            <Route path={this.props.localizedUrl('ROUTE_PROFILE').replace(':filter', 'direct-slab-access')} component={DirectSlabAccessContainer} />
                                        )}
                                        {this.canRouteProductsFavourite() && (
                                            <Route path={this.props.localizedUrl('ROUTE_PROFILE').replace(':filter', 'products-favourite')} component={ProductsFavouriteContainer} />
                                        )}
                                        {this.canRouteRecurringOrders() && (
                                            <Route path={this.props.localizedUrl('ROUTE_PROFILE').replace(':filter', 'recurring-orders')} component={RecurringOrdersContainer} />
                                        )}
                                        {this.canRouteSegmentation() && (
                                            <Route path={this.props.localizedUrl('ROUTE_PROFILE').replace(':filter', 'segmentation')} component={ProfileSegmentation} />
                                        )}
                                        {this.canRouteOfferStatusHistory() && (
                                            <Route path={this.props.localizedUrl('ROUTE_PROFILE').replace(':filter', 'offers')} component={OfferStatusHistoryContainer} />
                                        )}
                                        <Route component={Error404Screen} />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ProfileScreen)
)
