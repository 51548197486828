import { push } from 'react-router-redux'
import { fetchSegmentation } from '../segmentation/actions'
import { canViewV2, getIsLogged, getToken, getEmailUsedToLogin } from '../login/reducers'
import { getCurrentUrl } from '../ui/reducers'
import { canBuy } from '../centers/reducers'
import { fetchLotsImages } from '../lots/actions'
import * as api from '../api'
import Cookies from 'js-cookie'
import { getCountry, getLanguage, getUICallback} from './reducers'
import { getVersion } from '../../store/login/reducers'

export const CLOSE_MENU = 'CLOSE_MENU'

export const setLanguage = locale => (dispatch, getState) => {
    // console.log('DISPAT LENTGAE')
    dispatch({ type: 'SET_LANGUAGE', locale })

    if (canBuy(getState()) && getIsLogged(getState())) {
        dispatch(fetchSegmentation())
    }
}

export const toggleVersion = () => (dispatch, getState) => {
    dispatch({ type: 'TOGGLE_VERSION' })
    const state = getState()
    const isNewVersion = getVersion(state)
    const currentUrl = getCurrentUrl(state)
    currentUrl.pathname = currentUrl.pathname.replace('/v2', '')

    if (isNewVersion) {
        const locale = getLanguage(state)
        if (locale === 'en-US') currentUrl.pathname = '/v2' + currentUrl.pathname
        else currentUrl.pathname = currentUrl.pathname.replace(locale, locale + '/v2')
    }

    dispatch(push(currentUrl))
}

export const toggleVersionParams = (version) => (dispatch, getState) => {
    dispatch({ type: 'TOGGLE_VERSION_PARAMS', newVersion: version })
    const state = getState()
    const currentUrl = getCurrentUrl(state)
    currentUrl.pathname = currentUrl.pathname.replace('/v2', '')

    if (version) {
        const locale = getLanguage(state)
        if (locale === 'en-US') currentUrl.pathname = '/v2' + currentUrl.pathname
        else currentUrl.pathname = currentUrl.pathname.replace(locale, locale + '/v2')
    }

    dispatch(push(currentUrl))
}
export const CLOSE_BUNDLE_CONTENT = 'CLOSE_BUNDLE_CONTENT'
export const closeBundleContent = () => {
    return { type: CLOSE_BUNDLE_CONTENT }
}
export const openBundleContent = bundle => (dispatch, getState) => {
    dispatch(resetUI())
    let lotIds = []
    bundle.lots.forEach(lot => {
        lotIds.push(lot.lotId)
    })
    dispatch({ type: 'OPEN_BUNDLE_CONTENT', bundle })
    dispatch(fetchLotsImages(lotIds))
}
export const closeMenu = () => {
    return { type: CLOSE_MENU }
}
export const CLOSE_SEGMENTATION_MODAL = 'CLOSE_SEGMENTATION_MODAL'
export const closeSegmentationModal = () => {
    return { type: CLOSE_SEGMENTATION_MODAL }
}
export const closeDektonGripModal = () => {
    return { type: 'CLOSE_DEKTON_GRIP_MODAL' }
}
export const closeEmailPromotionsModal = () => {
    return { type: 'CLOSE_EMAIL_PROMOTIONS_MODAL' }
}
export const closeWhatsappModal = () => {
           return { type: 'CLOSE_WHATSAPP_MODAL' }
       }

export const closeNewVersionModal = () => {
    return { type: 'CLOSE_NEW_VERSION_MODAL' }
}
export const closePedidosRecurrentesModal = () => {
    return { type: 'CLOSE_PEDIDOS_RECURRENTES_MODAL' }
}
export const closeNewVersionModalExperience = () => {
    return { type: 'CLOSE_NEW_VERSION_MODAL_EXPERIENCE' }
}
export const sendFeedback = (score, comments, banner) => (dispatch, getState) => {
    let lang = getLanguage(getState())
    dispatch({ type: 'ADD_EMAIL_CENTER_REQUEST', score, comments, banner, lang })

    return api
        .sendFeedback(score, comments, banner, lang, getToken(getState()))
        .then(response => {
            dispatch({ type: 'SEND_FEDDBACK_SUCCESS', alert: response.data, status: response.status })
        })
        .catch(error => {
            dispatch({
                type: 'SEND_FEDDBACK_FAILURE',
                statusText: error.response.data.err,
                status: error.response.status
            })
        })
}
export const submitEmailPromotionsModal = (acceptPromotionNotifications, email, acceptPolicies) => (dispatch, getState) => {
    dispatch({
        type: 'SAVE_EMAIL_PROMOTIONS_MODAL'
    })
    return api
        .savePromotionConfiguration(getToken(getState()), {
            promotionEmail: email,
            acceptPromotionNotifications: acceptPromotionNotifications,
            acceptPolicies: acceptPolicies
        })
        .then(
            response => {
                dispatch({
                    type: 'SAVE_EMAIL_PROMOTIONS_MODAL_SUCCESS'
                })
            },
            error => {
                dispatch({ type: 'SAVE_EMAIL_PROMOTIONS_MODAL_FAILURE', alert: error.response.data.err, status: error.response.status })
            }
        )
}
export const submitWhatsappOptinModal = (phone, email, acceptPolicies, onClose) => (dispatch, getState) => {
    dispatch({
        type: 'SAVE_WHATSAPP_OPTIN_MODAL_REQUEST'
    })
    return api
        .submitWhatsappOptin(getToken(getState()), {
            email,
            phone,
            acceptPolicies
        })
        .then(
            response => {
                dispatch({
                    type: 'SAVE_WHATSAPP_OPTIN_MODAL_SUCCESS'
                })
                onClose()
            },
            error => {
                dispatch({ type: 'SAVE_WHATSAPP_OPTIN_MODAL_FAILURE', alert: error.response.data.err, status: error.response.status })
            }
        )
}
export const submitDektonGripModal = () => (dispatch, getState) => {
    dispatch({ type: 'ACCEPT_DEKTON_GRIP_MODAL' }) //REVIEW: DON'T USED
    dispatch({ type: 'CLOSE_DEKTON_GRIP_MODAL' })
    dispatch(getUICallback(getState()))
}
export const showSegmentationModal = (mode, callback) => (dispatch, getState) => {
    //dispatch(resetUI())
    dispatch({
        type: 'SHOW_SEGMENTATION_MODAL',
        mode: mode,
        callback: callback
    })
}
export const showLanguageModal = () => (dispatch, getState) => {
    dispatch(resetUI())
    dispatch({
        type: 'SHOW_LANGUAGE_MODAL'
    })
}
export const CLOSE_LANGUAGE_MODAL = 'CLOSE_LANGUAGE_MODAL'
export const closeLanguageModal = () => {
    return { type: CLOSE_LANGUAGE_MODAL }
}
export const showObsoleteModal = () => (dispatch, getState) => {
    dispatch(resetUI())
    dispatch({
        type: 'SHOW_OBSOLETE_MODAL'
    })
}
export const CLOSE_OBSOLETE_MODAL = 'CLOSE_OBSOLETE_MODAL'
export const closeObsoleteModal = () => {
    return { type: CLOSE_OBSOLETE_MODAL }
}
export const toggleIsObsolete = () => {
    return { type: 'TOGGLE_ISOBSOLETE' }
}
export const showCheckoutModal = () => (dispatch, getState) => {
    dispatch(resetUI())
    dispatch({
        type: 'SHOW_CHECKOUT_MODAL'
    })
}
export const CLOSE_CHECKOUT_MODAL = 'CLOSE_CHECKOUT_MODAL'
export const closeCheckoutModal = () => {
    return { type: CLOSE_CHECKOUT_MODAL }
}
export const showAdd2CartSuccessModal = () => (dispatch, getState) => {
    dispatch(resetUI())
    dispatch({
        type: 'SHOW_ADD2CARTSUCCESS_MODAL'
    })
}
export const closeAdd2CartSuccessModal = () => {
    return { type: 'CLOSE_ADD2CARTSUCCESS_MODAL' }
}
export const CLOSE_PROFILE_MODAL = 'CLOSE_PROFILE_MODAL'
export const closeProfileModal = () => {
    return { type: CLOSE_PROFILE_MODAL }
}
export const showProfileModal = () => (dispatch, getState) => {
    dispatch(resetUI())
    dispatch({
        type: 'SHOW_PROFILE_MODAL'
    })
}
export const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL'
export const closeLoginModal = () => {
    return { type: CLOSE_LOGIN_MODAL }
}
export const showLoginModal = () => (dispatch, getState) => {
    dispatch(resetUI())
    dispatch({
        type: 'SHOW_LOGIN_MODAL'
    })
}
export const CLOSE_REDIRECT_SSO_MODAL = 'CLOSE_REDIRECT_SSO_MODAL'
export const closeRedirectSSOModal = () => {
    return { type: CLOSE_REDIRECT_SSO_MODAL }
}
export const showRedirectSSOModal = () => (dispatch, getState) => {
    dispatch(resetUI())
    dispatch({
        type: 'SHOW_REDIRECT_SSO_MODAL'
    })
}
export const CLOSE_REDIRECT_JT_MODAL = 'CLOSE_REDIRECT_JT_MODAL'
export const closeRedirectJTModal = () => {
    return { type: CLOSE_REDIRECT_JT_MODAL }
}
export const showRedirectJTModal = () => (dispatch, getState) => {
    dispatch(resetUI())
    dispatch({
        type: 'SHOW_REDIRECT_JT_MODAL'
    })
}
export const CLOSE_CART_MODAL = 'CLOSE_CART_MODAL'
export const closeCartModal = () => {
    return { type: CLOSE_CART_MODAL }
}
export const showCartModal = () => (dispatch, getState) => {
    dispatch(resetUI())
    dispatch({
        type: 'SHOW_CART_MODAL'
    })
}
export const CLOSE_IMPERSONATE_MODAL = 'CLOSE_IMPERSONATE_MODAL'
export const closeImpersonateModal = () => {
    return { type: CLOSE_IMPERSONATE_MODAL }
}
export const CLOSE_PRICES_DISCLAIMER = 'CLOSE_PRICES_DISCLAIMER'
export const closePricesDisclaimer = () => {
    return { type: CLOSE_PRICES_DISCLAIMER }
}
export const showImpersonateModal = () => (dispatch, getState) => {
    dispatch(resetUI())
    dispatch({
        type: 'SHOW_IMPERSONATE_MODAL'
    })
}
export const toggleImpersonate = showImpersonate => (dispatch, getState) => {
    if (!showImpersonate) {
        dispatch(resetUI())
        dispatch({ type: 'SHOW_IMPERSONATE_MODAL' })
    } else {
        dispatch({ type: 'CLOSE_IMPERSONATE_MODAL' })
    }
}
export const toggleProfile = showProfile => (dispatch, getState) => {
    if (!showProfile) {
        dispatch(resetUI())
        dispatch({ type: 'SHOW_PROFILE_MODAL' })
    } else {
        dispatch({ type: 'CLOSE_PROFILE_MODAL' })
    }
}
export const SHOW_CART_MODAL = 'SHOW_CART_MODAL'
export const toggleCart = showCart => (dispatch, getState) => {
    if (!showCart) {
        dispatch(resetUI())
        dispatch({ type: SHOW_CART_MODAL })
    } else {
        dispatch({ type: CLOSE_CART_MODAL })
    }
}
export const toggleMenu = showMenu => {
    if (!showMenu) {
        return { type: 'SHOW_MENU' }
    } else {
        return { type: 'CLOSE_MENU' }
    }
}
export const showPersonalData = showPersonalData => {
    if (!showPersonalData) {
        return { type: 'SHOW_PERSONAL_DATA' }
    } else {
        return { type: 'CLOSE_PERSONAL_DATA' }
    }
}
export const toggleTranslateMode = showFilters => (dispatch, getState) => {
    dispatch({ type: 'TOGGLE_TRANSLATE_MODE' })
}
export const toggleKpisFilters = showFilters => (dispatch, getState) => {
    dispatch(resetUI())
    dispatch({ type: 'TOGGLE_KPIS_FILTERS', showFilters })
}
export const RESET_UI = 'RESET_UI'
export const resetUI = () => {
    return { type: RESET_UI }
}
export const fetchLocation = () => (dispatch, getState) => {
    dispatch({ type: 'FETCH_LOCATION_COUNTRY_REQUEST' })
    return api
        .askLocation()
        .then(response => {
            dispatch({ type: 'FETCH_LOCATION_COUNTRY_SUCCESS', location: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_LOCATION_COUNTRY_FAILURE_SAVE_DEFAULT' })
        })
}
export const fetchMaintenance = () => (dispatch, getState) => {
    dispatch({ type: 'FETCH_MAINTENANCE_SCHEDULED_REQUEST' })
    return api
        .fetchMaintenance()
        .then(response => {
            dispatch({ type: 'FETCH_MAINTENANCE_SCHEDULED_SUCCESS', maintenance: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_MAINTENANCE_SCHEDULED_FAILURE' })
        })
}
export const askLocation = (routeLang, pathname) => (dispatch, getState) => {
    const locales = {
        US: 'en-US',
        CA: 'fr-CA',
        ES: 'es',
        FR: 'fr',
        IT: 'it',
        PT: 'pt',
        NL: 'nl',
        DE: 'de',
        GB: 'en',
        SE: 'sv',
        BE: 'fr',
        AT: 'de',
        IE: 'en',
        NO: 'no',
        TR: 'tr',
        DK: 'no',
        FI: 'fi',
        PL: 'en-US',
        JP: 'en',
        IL: 'en',
        NZ: 'en-US',
        AU: 'en-US',
        AE: 'en',
        SG: 'en',
        ZA: 'en',
        BR: 'pt',
        MX: 'es'
    }
    const state = getState()
    if (routeLang !== undefined) {
        //Si tiene idioma en la url ej /es/catalogo (/es)
        dispatch(setLanguage(routeLang))
    } else if (routeLang === undefined && pathname !== '/') {
        //si no tiene idioma definido (en-US) pero tiene dirección ej (/catalog)
        dispatch(setLanguage('en-US'))
    } else if (Cookies.get('defaultLanguage') !== undefined) {
        // si existe cookie definida
        dispatch(setLanguage(Cookies.get('defaultLanguage')))
        if(pathname === '/'){
            dispatch(dispatchPushURL())
        }
    } else {
        // los demás casos (estando en home sin idioma definido en url y sin cookie)
        dispatch(fetchLocation()).then(() => {
            let locale = locales[getCountry(getState())] ? locales[getCountry(getState())] : 'en-US'
            dispatch(setLanguage(locale))
            Cookies.set('defaultLanguage', locale)
            if(pathname === '/'){
                dispatch(dispatchPushURL())
            }
        })
    }
}
export const sendTranslation = (termId, translation) => (dispatch, getState) => {
    dispatch({ type: 'SEND_TRANSLATION_REQUEST', termId, translation })
    return api
        .sendTranslation(termId, translation, getLanguage(getState()))
        .then(response => {
            dispatch({ type: 'SEND_TRANSLATION_SUCCESS', translations: response })
        })
        .catch(error => {
            dispatch({ type: 'SEND_TRANSLATION_FAILURE', error: error })
        })
}
export const getTranslation = () => (dispatch, getState) => {
    dispatch({ type: 'GET_TRANSLATION_REQUEST' })
    return api
        .getTranslation(getLanguage(getState()))
        .then(response => {
            dispatch({ type: 'GET_TRANSLATION_SUCCESS', translations: response })
        })
        .catch(error => {
            dispatch({ type: 'GET_TRANSLATION_FAILURE' })
        })
}

export const noShowMoreSubscribeModal = () => (dispatch, getState) => {
    dispatch({
        type: 'NO_SHOW_MORE_SUBSCRIBE_MODAL_REQUEST'
    })
    return api.noShowMoreSubscribeModal(getToken(getState())).then(
        () => {
            dispatch({
                type: 'NO_SHOW_MORE_SUBSCRIBE_MODAL_SUCCESS'
            })
        },
        error => {
            dispatch({
                type: 'NO_SHOW_MORE_SUBSCRIBE_MODAL_FAILURE',
                alert: error.response.data.err,
                status: error.response.status
            })
        }
    )
}
export const noShowMoreWhatsappModal = () => (dispatch, getState) => {
    dispatch({
        type: 'NO_SHOW_MORE_WHATSAPP_MODAL_REQUEST'
    })
    return api.noShowMoreWhatsappModal(getToken(getState())).then(
        () => {
            dispatch({
                type: 'NO_SHOW_MORE_WHATSAPP_MODAL_SUCCESS'
            })
        },
        error => {
            dispatch({
                type: 'NO_SHOW_MORE_WHATSAPP_MODAL_FAILURE',
                alert: error.response.data.err,
                status: error.response.status
            })
        }
    )
}

export const dispatchPushURL = (path = null, returnURL = null) => (dispatch, getState) => {
    const state = getState()
    let lang = getLanguage(state)
    const isNewVersion = getVersion(state)
    const isCanViewV2 = !getIsLogged(state) ? false : canViewV2(state)
    let goTo = '/'

    if (lang != 'en-US') {
        goTo = goTo + lang
    }

    // if ((isNewVersion /*&& isCanViewV2*/) || (isNewVersion && !getIsLogged(state))) {
    //     goTo = goTo + '/v2'
    // }

    if(path && path.includes('/' + lang)){
        goTo = goTo.replace('/' + lang, '')
    }

    if(path && path.includes('/v2')){
        goTo = goTo.replace('/v2', '')
    }


    path = !!path ? path : ''
    let allPath = goTo + path
    allPath = allPath.replace('//', '/')

    if(returnURL){
        return allPath
    }else{
        dispatch(push(allPath))
    }
}

export const downloadImage = (url, name) => (dispatch, getState) => {

    const token = getToken(getState())

    return api.downloadImage(token, url, name).then(
        () => {
            dispatch({
                type: 'REQUEST_DOWNLOAD_IMAGE_SUCCESS'
            })
        },
        error => {
            dispatch({
                type: 'REQUEST_DOWNLOAD_IMAGE_SUCCESS_FAILURE',
                alert: error.response.data.err
            })
        }
    )

}

export const proccessInDetailScreen = (inDetailScreen) => (dispatch, getState) => {
    dispatch({
        type: 'PROCCESS_IN_DETAILS_SCREEN', inProductDetailScreen: inDetailScreen
    })
}

export const setIsOrderHistoryScreen = (isOrderHistoryScreen) => (dispatch, getState) => {
    dispatch({
        type: 'PROCCESS_IS_ORDER_HISTORY_SCREEN', isOrderHistoryScreen: isOrderHistoryScreen
    })
}

export const setIsShippingHistoryScreen = (isShippingHistoryScreen) => (dispatch, getState) => {
    dispatch({
        type: 'PROCCESS_IS_SHIPPING_HISTORY_SCREEN', isShippingHistoryScreen: isShippingHistoryScreen
    })
}

/**
 * Action to send / post the walkthrough data after finishing the walkthrough
 * @param {string} type 
 * @param {boolean} noShowMore 
 */
export const submitWalkthrough = (type, noShowMore) => (dispatch, getState) => {
    const email = getEmailUsedToLogin(getState())
    const lang = getLanguage(getState())
    const token = getToken(getState())

    dispatch({ type: 'SEND_WALKTHROUGH_REQUEST' })

    return api.sendWalkthrough(email, lang, type, noShowMore, token).then(response => {
            dispatch({ 
                type: 'SEND_WALKTHROUGH_SUCCESS',
                status: response.data.status
            })
        },
        error => {
            dispatch({
                type: 'SEND_WALKTHROUGH_FAILURE',
                status: error.response.status
            })
        }
    )
}

export const closeSilestoneXMModal = () => {
    return { type: 'CLOSE_SILESTONEXM_MODAL' }
}