import { connect } from 'react-redux'
import MiniCart from './MiniCart'
import { removeCartLine, changeCartLineQty, changeCartLineSegmentation, emptyCart, fetchCart } from '../../store/cart/actions'
import {
    totalSelector,
    qtySelector,
    showModalSelector,
    getLines,
    getCalculatingLinesPrice,
    totalCoin,
    isMallCartForContainer,
    getGoalMin,
    weightSelector,
    getKgPoundRatio,
} from '../../store/cart/reducers'
import * as ui from '../../store/ui/actions'
import { getSelectedCenter, getShippingMethod, getCoin, getIsIkeaCenter, getIsMkToolsCenter, canOffer } from '../../store/centers/reducers'

import { injectIntl } from 'react-intl'
import * as segmentation from '../../store/segmentation/actions'
import { getUserType, getVersion, canViewRocketPromotion } from '../../store/login/reducers'
import { getLanguageDir, getLanguage } from '../../store/ui/reducers'
import { canViewPricesPhase2 } from '../../store/products/reducers'
import { getProfileConfiguration } from '../../store/profile-configuration/reducers'

const mapStateToProps = state => {
    let language = getLanguage(state)
    let defaultUnit = language === 'en' || language === 'en-US' || language === 'fr-CA' ? 'LB' : 'KG'
    let unit = getProfileConfiguration(state).weightUnit ? getProfileConfiguration(state).weightUnit : defaultUnit

    return {
        lines: getLines(state),
        total: totalSelector(state),
        totalQty: qtySelector(state),
        show: showModalSelector(state),
        selectedCenter: getSelectedCenter(state),
        shippingMethod: getShippingMethod(state),
        key: getSelectedCenter(state),
        coin: getCoin(state),
        isIkeaCenter: getIsIkeaCenter(state),
        userType: getUserType(state),
        languageDir: getLanguageDir(state),
        calculatingLinesPrice: getCalculatingLinesPrice(state),
        totalCoin: totalCoin(state),
        canViewPricesPhase2: canViewPricesPhase2(state),
        lang: getLanguage(state),
        isMallCartForContainer: isMallCartForContainer(state),
        unit,
        goalMin: getGoalMin(state),
        current: unit === 'KG' ? weightSelector(state) : Math.floor(weightSelector(state) * getKgPoundRatio()),
        isOffer: canOffer(state),
        newVersion: getVersion(state),
        canViewRocketPromotion: canViewRocketPromotion(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCart: centerId => {
            dispatch(fetchCart(centerId))
        },
        onDelete: lineId => {
            dispatch(removeCartLine(lineId))
        },
        changeCartLineQty: (lineId, qty) => {
            dispatch(changeCartLineQty(lineId, qty))
        },
        onClose: () => {
            dispatch(ui.closeCartModal())
        },
        emptyCart: () => {
            dispatch({ type: 'EMPTY_CART_BY_USER' })
            dispatch(emptyCart())
        },
        onOpenBundleContent: product => {
            dispatch(ui.openBundleContent(product))
        },
        loadThisSegmentation: seg => {
            dispatch(segmentation.loadThisSegmentation(seg))
        },
        showSegmentationModal: (mode, callback) => {
            dispatch(ui.showSegmentationModal(mode, callback))
        },
        changeCartLineSegmentation: lineId => {
            dispatch(changeCartLineSegmentation(lineId))
        }
    }
}

const MiniCartContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MiniCart)

export default injectIntl(MiniCartContainer)
