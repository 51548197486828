import React from 'react'
import ProductDisplayName from '../global/ProductDisplayName'
import DisplayThickness from '../catalog/rectangleResult/displays/DisplayThickness'
import { injectIntl } from 'react-intl'
import Translation from '../global/Translation'
import InputNumber from '../global/InputNumber'
import LineSegmentation from '../cart-line/LineSegmentation'
import ProductImage from '../catalog/ProductImage'
import DisplayOutletIndicator from '../catalog/rectangleResult/displays/DisplayOutletIndicator'
import DisplayTechnology from '../catalog/rectangleResult/displays/DisplayTechnology'
import mallLarge from '../../assets/img/logos/mall-large.jpg'
import {getAdaptedFormat} from "../../helpers";
import {connect} from "react-redux";
import getNumberFormat from '../getNumberFormat'
import moment from "moment/moment";


const CUT_TO_SIZE_MATERIALS = ['80457', '80461', '80451', '80458']

const OrderDetailsTableV2 = props => {
    const { orderLines, orderToEdit, editMode, canEdit, onLineChangeValue, isEmserUser, lang } = props

    if (editMode && !orderToEdit) return null

    const getOrderToEditLine = orderLine => {
        return orderToEdit.find(line => line.position === orderLine.Posnr)
    }

    const editModeQuantityCell = orderLine => {
        const editLine = getOrderToEditLine(orderLine)

        if (orderLine.product) {
            if (orderLine.product.lotId && orderLine.product.lotId !== '') {
                return (
                    <span>
                        <Translation id="lot_id" defaultMessage="Lot ID" />: {orderLine.product.lotId}
                    </span>
                )
            } else if (orderLine.product.bundleId && orderLine.product.bundleId !== '') {
                return (
                    <span>
                        <Translation id="bundle_id" defaultMessage="bundle ID" />: {orderLine.product.bundleId}
                    </span>
                )
            } else if (canEdit(orderLine.Posnr)) {
                return <InputNumber key={orderLine.Posnr + '' + editLine.quantity} value={editLine.quantity} change={qty => onLineChangeValue(orderLine.Posnr, 'qty', qty)} />
            }
        }

        return editLine ? editLine.quantity : null
    }

    const editModeActionCell = orderLine => {
        const editLine = getOrderToEditLine(orderLine)

        if (canEdit(orderLine.Posnr)) {
            if (!editLine.rejected) {
                return (
                    <div className="reject" style={{ cursor: 'pointer' }}>
                        <i className="fal fa-trash-alt" onClick={() => onLineChangeValue(orderLine.Posnr, 'rejected', editLine.rejected)} />
                    </div>
                )
            } else {
                return (
                    <div className="accept" style={{ cursor: 'pointer' }}>
                        <i className="fal fa-undo" onClick={() => onLineChangeValue(orderLine.Posnr, 'rejected', editLine.rejected)} />
                    </div>
                )
            }
        } else return null
    }

    const isRejected = orderLine => {
        return getOrderToEditLine(orderLine).rejected
    }

    const isEditable = orderLine => {
        return !indicator(orderLine)
    }

    const indicator = orderLine => {
        let indicator = null

        if (orderLine.reservation_quantity != orderLine.quantity) {
            indicator = (
                <div className="new-indicator mt-1 mb-3">
                    <i className="fa fa-check" /> <Translation id="modified_line" defaultMessage="Modified line" />
                </div>
            )
        }
        if (orderLine.reservation_quantity == 0 && orderLine.quantity > 0) {
            indicator = (
                <div className="new-indicator mt-1 mb-3">
                    <i className="fa fa-check" /> <Translation id="added_line" defaultMessage="Added line by center" />
                </div>
            )
        }
        if ((orderLine.reservation_quantity > 0 && orderLine.quantity == 0) || orderLine.rejected || orderLine.reject_reason) {
            indicator = (
                <div className="reject-indicator mt-1 mb-3">
                    <i className="fa fa-times" /> <Translation id="rejected_line" defaultMessage="Rejected line" />
                </div>
            )
        }

        return indicator
    }

    const showPrice = () => !isEmserUser && !CUT_TO_SIZE_MATERIALS.includes(String(orderLines[0].material_id))

    return (

        <React.Fragment>
            {orderLines &&
                orderLines.length > 0 &&
                orderLines.map(orderLine => {
                    let numberFormat = getNumberFormat(lang, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                    if(orderLine.coin === 'JPY') {
                        numberFormat = getNumberFormat(lang, { maximumFractionDigits: 0, minimumFractionDigits: 0 })
                    }
                    return (
                        <div
                            className="d-flex order-details"
                            style={{
                                // borderBottom: '1px solid #EBEBEB',
                                borderTop: '1px solid #EBEBEB',
                                backgroundColor: 'white',
                                // marginBottom: '1rem',
                                padding: '1em',
                                marginLeft: '-15px',
                                overflowX: 'auto',
                                marginRight: '-15px'
                            }}
                        >
                            <div style={{ minWidth: '110px', marginRight: '3rem', width: '110px', height: '110px', flexGrow: 1 }}>
                                <ProductImage product={orderLine.product} />
                            </div>

                            <div className="d-flex flex-column" style={{ marginRight: '3rem', flexGrow: 1, width: '15%' }}>
                                {orderLine.product ? (
                                    <React.Fragment>
                                        {orderLine.outlet_quality && <DisplayOutletIndicator outletQuality={orderLine.outlet_quality} />}
                                        {orderLine && orderLine.product && orderLine.product.productId && (
                                            <div>
                                                <b><Translation id="material" defaultMessage="Material" />:</b>
                                                <br></br>
                                                <ProductDisplayName product={orderLine.product} />
                                            </div>
                                        )}
                                        {orderLine && orderLine.product && orderLine.product.brand && (
                                            <div>
                                                <b><Translation id="brand" defaultMessage="Brand" />:</b>
                                                <br></br>
                                                {orderLine.product.brand}
                                            </div>
                                        )}
                                        {orderLine && orderLine.product && orderLine.product.finish && (
                                            <div>
                                                <b><Translation id="finish" defaultMessage="Finish" />:</b>
                                                <br></br>
                                                {props.intl.formatMessage({ id: orderLine.product.finish })}
                                            </div>
                                        )}
                                        {orderLine && orderLine.product && orderLine.product.productId && (
                                            <div>
                                                <b>
                                                    <Translation id="material_id" defaultMessage="Material Id" />:
                                                </b>
                                                <br></br>
                                                {orderLine.product.productId}
                                            </div>
                                        )}
                                        {orderLine.lot_id && (
                                            <React.Fragment>
                                                <b>
                                                    <Translation id="lot_id" defaultMessage="Lot ID" />:
                                                </b>
                                                <br></br>
                                                {orderLine.lot_id}
                                            </React.Fragment>
                                        )}
                                        {orderLine && orderLine.product && orderLine.product.format && (
                                            <div>
                                                <b>
                                                    <Translation id="format" defaultMessage="Format" />:
                                                </b>
                                                <br></br>
                                                {props.adaptedFormat(orderLine.product.format, { upper: true, showUnits: true })}
                                            </div>
                                        )}
                                        {orderLine && orderLine.product && orderLine.product.thickness && (
                                          <div>
                                              <b>
                                                  <Translation id="thickness" defaultMessage="Thickness" />:
                                              </b>
                                              <br></br>
                                              <DisplayThickness thickness={orderLine.product.thickness} string />
                                          </div>
                                        )}
                                        {/*{orderLine.delivery_qty && (*/}
                                        {/*    <React.Fragment>*/}
                                        {/*        <div>*/}
                                        {/*            <b><Translation id="delivery_units" defaultMessage="Units included in shipment" />: {' '}</b><br></br>*/}
                                        {/*            {parseInt(orderLine.delivery_qty, 10)}/{parseInt(orderLine.quantity, 10)}*/}
                                        {/*        </div>*/}
                                        {/*    </React.Fragment>*/}
                                        {/*)}*/}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {orderLine && orderLine.short_text && (
                                            <div>
                                                <b><Translation id="material" defaultMessage="Material" />:</b>
                                                <br></br>
                                                {orderLine.short_text}
                                            </div>
                                        )}
                                        {orderLine && orderLine.material_id && (
                                            <div>
                                                <b>
                                                    <Translation id="material_id" defaultMessage="Material Id" />:
                                                </b>
                                                <br></br>
                                                {orderLine.material_id}
                                            </div>
                                        )}
                                    </React.Fragment>
                                )}
                            </div>

                            <div className="d-flex flex-column box-segmentation" style={{ marginRight: '3rem', flexGrow: 3, width: 'min-content' }}>
                                {/* {orderLine.serializedLine && (
                                    <React.Fragment>
                                        <b><Translation id="segmentation" defaultMessage="Segmentation" /></b>
                                        <LineSegmentation line={orderLine.serializedLine} showAsList />
                                    </React.Fragment>
                                )} */}
                                {orderLine.segmentation && (
                                    <React.Fragment>
                                        <b style={{ paddingInline: '40px' }}><Translation id="segmentation" defaultMessage="Segmentation" /></b>
                                        <LineSegmentation line={orderLine} showAsList />
                                    </React.Fragment>
                                )}
                            </div>
                            <div className="d-flex flex-column" style={{ marginRight: '3rem', flexGrow: 3, width: 'min-content' }}>
                                <b><Translation id="comment" defaultMessage="Comments" /></b>
                                <br></br>
                                {orderLine.comments ? orderLine.comments : '--'}
                            </div>
                            <div className="d-flex flex-column box-price" style={{ marginLeft: 'auto', flexGrow: 2, width: 'min-content' }}>
                                {showPrice() && (
                                    <div>
                                        <b><Translation id="price" defaultMessage="Price" />: </b>
                                        <br></br>
                                        {numberFormat.format(orderLine.import)} {orderLine.coin}                                      
                                    </div>
                                )}
                                <div>
                                    <b><Translation id="order_quantity" defaultMessage="Order quantity" />: </b>
                                    <br></br>
                                    {orderLine.reservation_quantity == 0 ? (
                                        <Translation id="added_line" defaultMessage="Added line by center" />
                                    ) : (
                                        parseInt(orderLine.reservation_quantity)
                                    )}
                                </div>
                                <div>
                                    <b><Translation id="quantity_request" defaultMessage="Quantity requested" />: </b>
                                    <br></br>
                                    {orderLine.reservation_quantity == 0 ? (
                                        <Translation id="added_line" defaultMessage="Added line by center" />
                                    ) : (
                                        parseInt(orderLine.reservation_quantity)
                                    )}
                                </div>
                                <div>
                                    <b><Translation id="requested_delivery_date" defaultMessage="Requested delivery date" />:</b>
                                    <br></br>
                                    {orderLine.preferredDeliveryDate
                                      ? moment(orderLine.preferredDeliveryDate).format('L')
                                      : ''}
                                </div>
                            </div>
                        </div>
                    )
                })}
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        adaptedFormat: (format, params) => getAdaptedFormat(format, params, state)
    }
}


export default injectIntl(connect(
    mapStateToProps,
    null
)(OrderDetailsTableV2))
