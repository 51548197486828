import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'
import newsletter from '../../assets/img/newsletter.jpg'
import { showingEmailPromotionsModal } from '../../store/ui/reducers'
import {
    getPromotionConfigurationEmails,
    getPromotionConfigurationAcceptPromotionNotifications,
    getPromotionConfigurationAcceptPromotionPolicies,
    getPromotionConfigurationPreviousPreferences
} from '../../store/profile-configuration/reducers'
import * as ui from '../../store/ui/actions'
import { connect } from 'react-redux'
import Button from '../global/Button'
import LocalizedLink from '../global/LocalizedLink'
import Alerts from '../alerts/Alerts'
import { noShowMoreSubscribeModal } from '../../store/ui/actions'

const mapStateToProps = state => {
    return {
        show: showingEmailPromotionsModal(state),
        previousPreferences: getPromotionConfigurationPreviousPreferences(state),
        promotionEmails: getPromotionConfigurationEmails(state),
        acceptPromotionNotifications: getPromotionConfigurationAcceptPromotionNotifications(state),
        acceptPolicies: getPromotionConfigurationAcceptPromotionPolicies(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(ui.closeEmailPromotionsModal())
        },
        submitEmailPromotionsModal: (acceptPromotionNotifications, promotionEmails, acceptPolicies) => {
            if (acceptPromotionNotifications && promotionEmails.length !== 0 && acceptPolicies) {
                return dispatch(ui.submitEmailPromotionsModal(acceptPromotionNotifications, promotionEmails, acceptPolicies))
            }
        },
        dontShowMore: () => {
            dispatch(noShowMoreSubscribeModal())
            dispatch(ui.closeEmailPromotionsModal())
        }
    }
}

class EmailPromotionsModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            promotionEmails: this.props.promotionEmails,
            acceptPromotionNotifications: this.props.acceptPromotionNotifications,
            acceptPolicies: this.props.acceptPolicies
        }
    }
    validateEmail = email => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(email)
    }
    setUserEmail = input => {
        if (input.validity.valid && input.value !== '' && this.validateEmail(this.state.email)) {
            this.props
                .submitEmailPromotionsModal(this.state.acceptPromotionNotifications, this.state.email, this.state.acceptPolicies)
                .then(() => {
                    this.props.onClose() //moved to ui reducer
                })
                .catch(() => {})
        } else {
            this.setState({ error: 'MUST_BE_A_VALID_EMAIL' })
        }
    }
    render() {
        const { show, onClose } = this.props
        return show && !this.props.previousPreferences ? (
            <div>
                <div className="full-overlay" style={{ display: show ? 'block' : 'none' }} />
                <div id="modal-login-container" className="modal-login modal-newsletter" style={{ display: show ? 'block' : 'none' }}>
                    <div className="">
                        <div className="row no-gutters">
                            <div className="col-12 logo-container">
                                <header className="container-fluid" style={{ position: 'absolute', zIndex: '5', color: 'white' }}>
                                    <div className="row">
                                        <div className="col-3 offset-9 my-auto text-right">
                                            <div className="bt-close" onClick={onClose}>
                                                <i className="fal fa-times" />
                                            </div>
                                        </div>
                                    </div>
                                </header>
                                <img alt="" id="profile-img" className="profile-img-card" src={newsletter} />
                            </div>
                            <div className="col-12 card-container">
                                <div className="form-signin">
                                    <div className="col-12">
                                        <header className="container-fluid">
                                            <h3 style={{ textAlign: 'center' }}>
                                                <Translation id="join_our_newsletter" defaultMessage="Join our newsletter" />
                                            </h3>
                                            <label style={{ textAlign: 'center' }}>
                                                <Translation
                                                    id="receive_news_about_offers_and_promotions_from_your_center_on_email"
                                                    defaultMessage="Receive news about offers and promotions from your center on email"
                                                />
                                            </label>
                                            {this.state.error && <Alerts alert={this.state.error} status="404" />}
                                        </header>
                                        <div className="form-group employee-id" style={{ textAlign: 'center' }}>
                                            <div className="container">
                                                <div className="row" style={{ textAlign: 'left' }}>
                                                    <div className="col-12">
                                                        <div className="form-group" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <input
                                                                className="form-control col-9"
                                                                placeholder={'example@email.com'}
                                                                defaultValue={this.state.email}
                                                                ref={node => {
                                                                    this.inputEmail = node
                                                                }}
                                                                onChange={e => this.setState({ email: e.target.value })}
                                                                type="email"
                                                            />
                                                            <Button
                                                                disabled={
                                                                    !(this.state.email && this.state.email !== '') ||
                                                                    this.state.isLoading ||
                                                                    !this.state.acceptPromotionNotifications ||
                                                                    !this.state.acceptPolicies
                                                                }
                                                                onClick={() => this.setUserEmail(this.inputEmail)}
                                                                inverted
                                                                type="submit"
                                                                className="bt bt-inverted loginbutton form-control"
                                                            >
                                                                <Translation id="send" defaultMessage="Send" />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <label className="CheckboxLinkSaleConditions">
                                                            <input
                                                                name="accept_policies_check"
                                                                type="checkbox"
                                                                onClick={() => this.setState({ acceptPolicies: !this.state.acceptPolicies })}
                                                                defaultChecked={this.state.acceptPolicies}
                                                            />{' '}
                                                            <Translation id="i_accept_the" defaultMessage="I accept the" />{' '}
                                                            <LocalizedLink routeId="ROUTE_PRIVACY">
                                                                <b>
                                                                    <Translation id="privacy_policy" defaultMessage="Privacy policy" />
                                                                </b>
                                                            </LocalizedLink>
                                                        </label>
                                                    </div>
                                                    <div className="col-12">
                                                        <label className="CheckboxLinkSaleConditions">
                                                            <input
                                                                name="accept_notifications_check"
                                                                type="checkbox"
                                                                onClick={() => this.setState({ acceptPromotionNotifications: !this.state.acceptPromotionNotifications })}
                                                                defaultChecked={this.state.acceptPromotionNotifications}
                                                                disabled={!this.state.acceptPolicies}
                                                            />{' '}
                                                            <Translation
                                                                id="promotion_preferences_accept_notifications_check"
                                                                defaultMessage="I want to receive notifications about promotions and offers from my center in eCosentino"
                                                            />
                                                        </label>
                                                    </div>
                                                    <div className="col-12 mt-4">
                                                        <span>
                                                            <small>
                                                                <Translation
                                                                    id="remember_you_can_set_these_and_other_configurations_on_your_profile"
                                                                    defaultMessage="* Remember you can set these and also other configuration options on your profile"
                                                                />
                                                            </small>
                                                        </span>
                                                    </div>
                                                    <div className="col-12 text-right mt-4">
                                                        <span onClick={() => this.props.dontShowMore()} style={{ cursor: 'pointer' }}>
                                                            <small>
                                                                <Translation id="dont_show_more" defaultMessage="Don't show more." />
                                                            </small>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <footer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EmailPromotionsModal)
)
