import React, { Component } from 'react'
import { injectIntl } from 'react-intl'         
import ElaborationType from './ElaborationType'
import { ElaborationOptionsMap } from './CutToSizeMaps'
import Button from '../global/Button'
import Translation from '../global/Translation'
import backgroundImageV1 from '../../assets/img/cut-to-size/custom_cts_banner.jpg'
import backgroundImageV2 from '../../assets/img/cut-to-size/Path-v2.png'
import LocalizedLink from "../global/LocalizedLink";
class ElaborationTypeSelector extends Component {

    render () {
        const {materials, newVersion} = this.props

        return (
            <section name="cut_to_size_elaboration_type_selector">
                <div className="row no-gutters">
                    <h2 className="questa-bold title-cts">     
                        {
                            !newVersion &&
                            <Translation id="cut_to_size_and_elaborated" defaultMessage="Cut to size and elaborated" />
                        }    
                        {
                            newVersion &&
                            <Translation id="custom_products" defaultMessage="Custom Products" />
                        }               
                    </h2>
                </div>
                {
                    materials && (
                        <div>
                            <div className="row no-gutters d-flex justify-content-center cut_to_size_elaboration_types">
                                {
                                    Object.keys(materials).filter(el => el !== 'BUDGET').map(elaboration => {

                                        const option = ElaborationOptionsMap[elaboration]
                                        return (
                                            <div className="cut_to_size_elaboration_type_container" key={`id_${elaboration}`}>
                                                <LocalizedLink routeId={'ROUTE_CUT_TO_SIZE'} header={true} queryString={`?type=${elaboration.toLowerCase()}`} params={{'outlet':''}} gtmLabel={option.gtmLabel}>
                                                    <ElaborationType
                                                        id={elaboration}
                                                        brands={Object.keys(materials[elaboration])}
                                                        title={this.props.intl.formatMessage(option.name)}
                                                        icon={newVersion ? option.image : option.icon}
                                                        newVersion={newVersion}
                                                    />
                                                </LocalizedLink>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="row no-gutters cts-ask-banner mt-3" style={{ backgroundImage: newVersion ? `url(${backgroundImageV2})` : `url(${backgroundImageV1})`}}>
                                <div className="w-100">
                                    <h3 className="questa-bold col-md-5 p-0">
                                        <Translation id="dont_find_your_cut" defaultMessage="Don't find the cut to size you need?" />
                                    </h3>
                                    <LocalizedLink routeId={'ROUTE_CUT_TO_SIZE'} header={true} queryString={`?type=${this.props.hasPreOrder ? 'preorder' : 'budget'}`} params={{'outlet':''}}>
                                        <Button size="small" customClass={`my-3 ${newVersion ? "bt-cts-inverted-v2" : "bt-cts-inverted"}`} dataQas="cts_main_request-quotation-button">
                                            {
                                                this.props.hasPreOrder ?
                                                    <Translation id="request_for_preorder" defaultMessage="Request an order/offer" />
                                                    :
                                                    <Translation id="request_for_quote" defaultMessage="Request for quote" />
                                            }
                                        </Button>
                                    </LocalizedLink>
                                </div>
                                
                            </div>
                        </div>
                    )
                }
                
            </section>
        )
    }
}

export default injectIntl(ElaborationTypeSelector)