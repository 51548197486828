import React from 'react'
import Translation from '../global/Translation'

import LocalizedLink from '../global/LocalizedLink'
import { injectIntl } from 'react-intl'

import { withRouter } from 'react-router-dom'

class LowesAdminViewSelector extends React.Component {
    render() {
        const { match } = this.props
        if (this.props.small) {
            return (
                <div className="lowes-container">
                    <LocalizedLink className={match.params.filter === 'pog' && !match.params.open ? 'active' : ''} routeId="ROUTE_CATALOG_LOWES_ADMIN" params={{ filter: 'pog' }}>
                        <div className="lowes-button">
                            <span className="lowes-title">
                                <i className="fas fa-th-large">
                                    {' '}
                                    <Translation id="pog" defaultMessage="POG" />
                                </i>
                            </span>
                        </div>
                    </LocalizedLink>
                    <LocalizedLink
                        className={match.params.filter === 'stores' && !match.params.open ? 'active' : ''}
                        routeId="ROUTE_CATALOG_LOWES_ADMIN"
                        params={{ filter: 'stores' }}
                    >
                        <div className="lowes-button">
                            <span className="lowes-title">
                                <i className="fas fa-user">
                                    {' '}
                                    <Translation id="stores" defaultMessage="Stores" />
                                </i>
                            </span>
                        </div>
                    </LocalizedLink>
                    <LocalizedLink
                        className={match.params.filter === 'reports' && !match.params.open ? 'active' : ''}
                        routeId="ROUTE_CATALOG_LOWES_ADMIN"
                        params={{ filter: 'reports' }}
                    >
                        <div className="lowes-button">
                            <span className="lowes-title">
                                <i className="fas fa-list">
                                    {' '}
                                    <Translation id="reports" defaultMessage="Reports" />
                                </i>
                            </span>
                        </div>
                    </LocalizedLink>
                </div>
            )
        }
        return (
            <div className="container">
                <div className="lowes-container" style={{ height: 'calc(100vh - 346px)' }}>
                    <div className="lowes-col">
                        <LocalizedLink
                            className={match.params.filter === 'pog' && !match.params.open ? 'active' : ''}
                            routeId="ROUTE_CATALOG_LOWES_ADMIN"
                            params={{ filter: 'pog' }}
                        >
                            <div className="lowes-button">
                                <i className="fas fa-th-large lowes-icon fa-5x" />
                                <span className="lowes-title">
                                    {' '}
                                    <Translation id="pog" defaultMessage="POG" />
                                </span>
                            </div>
                        </LocalizedLink>
                    </div>
                    <div className="lowes-col">
                        <LocalizedLink
                            className={match.params.filter === 'stores' && !match.params.open ? 'active' : ''}
                            routeId="ROUTE_CATALOG_LOWES_ADMIN"
                            params={{ filter: 'stores' }}
                        >
                            <div className="lowes-button">
                                <i className="fas fa-user lowes-icon fa-5x" />
                                <span className="lowes-title">
                                    {' '}
                                    <Translation id="stores" defaultMessage="Stores" />
                                </span>
                            </div>
                        </LocalizedLink>
                    </div>
                    <div className="lowes-col">
                        <LocalizedLink
                            className={match.params.filter === 'reports' && !match.params.open ? 'active' : ''}
                            routeId="ROUTE_CATALOG_LOWES_ADMIN"
                            params={{ filter: 'reports' }}
                        >
                            <div className="lowes-button">
                                <i className="fas fa-list lowes-icon fa-5x" />
                                <span className="lowes-title">
                                    {' '}
                                    <Translation id="reports" defaultMessage="Reports" />
                                </span>
                            </div>
                        </LocalizedLink>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(withRouter(LowesAdminViewSelector))
