import React from "react";
import Translation from '../global/Translation'

const channels = {
    A0: <Translation id="channel_A0" defaultMessage="Kitchen & bath" />,
    D0: <Translation id="channel_D0" defaultMessage="Home center" />,
    B0: <Translation id="channel_B0" defaultMessage="Projects" />,
    C0: <Translation id="channel_C0" defaultMessage="Builder" />,
    HE: <Translation id="channel_HE" defaultMessage="Projects" />,
    HO: <Translation id="channel_HO" defaultMessage="Cosentino Home Owners" />,
    DA: <Translation id="channel_DA" defaultMessage="Direct Accounts" />,
    BB: <Translation id="channel_BB" defaultMessage="Projects" />,
    BD: <Translation id="channel_BD" defaultMessage="Projects" />,
};

const noChannel = <Translation id="no_channel" defaultMessage="No Channel" />

const SegmentationTranslation = ({ channel }) => {
    return !!channels[channel] ? channels[channel] : noChannel
}
export default SegmentationTranslation;
