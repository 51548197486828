import React from 'react'
import Translation from '../global/Translation'
// import { isMobile } from 'react-device-detect'
import chevronDown from '../../assets/img/icons/chevron-down.svg'
import filterBlack from '../../assets/img/icons/filter-black.svg'
import { injectIntl } from 'react-intl'
import { RefinementListFilter, ResetFilters, CheckboxFilter, TermsQuery, TermQuery, BoolShould, BoolMust, SelectedFilters  } from 'searchkit'
import { withRouter } from 'react-router-dom'
import Button from '../global/Button'
import confirm from '../global/confirm'
import iconFilters from '../../assets/img/icons/filters.svg'
import Modal from '../global/Modal'
import Select from 'react-select'
import LocalizedLink from '../global/LocalizedLink'
import BannerTarificador from './BannerTarificador'
import BannerMiPortalFinanciero from './BannerMiPortalFinanciero'
import BannerPPCC from './BannerPPCC'
import FilterV2Box from "./FilterV2Box";

const confirmChangeShippingCenter = (center, onChangeShippingCenter, intl) => {
    return confirm(intl.formatMessage({ id: 'CHANGE_SHIPPING_CENTER' })).then(
        confirm => {
            return onChangeShippingCenter(center)
        },
        cancel => {
            return Promise.reject()
        }
    )
}
const initState = {
    filtersDivStyles: {},
    selectedShippingCenter: null,
    showClearFilter: false
}

class FiltersV2 extends React.Component {
    constructor(props) {
        super(props)

        this.state = initState
    }
    
    componentDidMount() {
        setInterval(() => {
            this.checkFilters()
        }, 1000);
          
        if (this.props.mktoolsShippingCenter)
            this.setState({ selectedShippingCenter: this.props.location.search.indexOf('mktoolShippingCenter[0]') > -1 ? this.props.mktoolsShippingCenter : null })
    }
    /* componentDidUpdate( prevProps, prevState ) {        
        [...document.querySelectorAll(".Select-placeholder")].map(e => console.log("existe"));        
    } */
    componentWillUnmount() {
        window.removeEventListener('popstate', this.checkFilters);
    }
    changeShippingCenter = selectedCenter => {
        this.props.setMkToolsShippingCenter(selectedCenter)
        this.setState({ selectedShippingCenter: selectedCenter })
    }
    onReset = () => {
        // this.setState({ selectedShippingCenter: null })
        if (this.props.selectedCenter === 1060)
            this.props.redirect(this.props.intl.formatMessage({ id: 'ROUTE_CATALOG' }).replace(':outlet?', '') + '?mktoolShippingCenter[0]=' + this.state.selectedShippingCenter)
    }
    noShippingCentermsg = () => {
        return <Translation id="havent_center_shipping_addres" defaultMessage="You do not have center shipping addresses, please contact your supplier" />
    }
    ShippingCenterOptions = props => {
        return (
            <div>
                {props.items && props.items.length > 0 ? (
                    <React.Fragment>

                    <Select
                        options={props.items.map(item => ({
                            label: item.name,
                            value: item.id,
                        }))}
                        className={"select-shipping"}
                        value={this.state.selectedShippingCenter}
                        clearable={false}
                        onChange={selectedOption => {
                            selectedOption &&
                                confirmChangeShippingCenter(selectedOption.value, this.changeShippingCenter, this.props.intl).then(() => {
                                    let selected = selectedOption ? [selectedOption.value] : []
                                    if (this.props.selectedCenter === 1060) props.setItems(selected)
                                    // Add the mktoolShippingCenter in url after close the modal
                                    if (!window.location.href.includes('mktoolShippingCenter[0]')) {
                                        const mktoolShippingParam = window.location.href + '&mktoolShippingCenter[0]=' + selected
                                        window.history.replaceState(null, '', mktoolShippingParam)
                                    }
                                })
                        }}
                        placeholder={this.props.intl.formatMessage({ id: 'select' }) + '...'}
                    />
                    </React.Fragment>
                ) : (
                    this.noShippingCentermsg()
                )}
            </div>
        )
    }
    ShippingCenterOptionsWithoutFilter = options => {

        return (
            <div style={{ width: '100%' }}>
                {options && options.length > 0 ? (
                    <Select
                        options={options.map(item => ({
                            label: item.name,
                            value: item.id
                        }))}
                        value={this.state.selectedShippingCenter}
                        clearable={false}
                        onChange={selectedOption => {
                            selectedOption && confirmChangeShippingCenter(selectedOption.value, this.changeShippingCenter, this.props.intl)
                        }}
                        placeholder={this.props.intl.formatMessage({ id: 'select' }) + '...'}
                    />
                ) : (
                    this.noShippingCentermsg()
                )}
            </div>
        )
    }
    subtypeOptions = (props, subType) => {
        return (
            <Select
                options={props.items.map(item => ({ label: this.props.intl.formatMessage({ id: subType + '_' + item.key }), value: item.key }))}
                value={props.selectedItems[0]}
                onChange={selectedOption => {
                    let selected = selectedOption ? [selectedOption.value] : []
                    props.setItems(selected)
                }}
                placeholder={
                    this.props.intl.formatMessage({
                        id: 'select'
                    }) + '...'
                }
            />
        )
    }
    subtypeOptions0 = props => this.subtypeOptions(props, 'subtype')
    subtypeOptions1 = props => this.subtypeOptions(props, 'subtype_1')
    subtypeOptions2 = props => this.subtypeOptions(props, 'subtype_2')

    colorOptions = props => {
        return (

            <Select
                options={props.items.map(item => ({ label: item.key, value: item.key }))}
                value={props.selectedItems[0]}
                onChange={selectedOption => {
                    let selected = selectedOption ? [selectedOption.value] : []
                    props.setItems(selected)
                }}
                placeholder={
                    this.props.intl.formatMessage({
                        id: 'color'
                    })
                }


            />

        )
    }

    showMobileFilters = () => {
        this.setState({
            filtersDivStyles: {
                flexDirection: "row"
            }
        })
        document.body.classList.add('noscroll')
    }

    hideMobileFilters = () => {
        this.setState({
            filtersDivStyles: {}
        })
        document.body.classList.remove('noscroll')
    }

    applyFilters = () => {
        this.hideMobileFilters()
    }

    clearFilters = () => {
        this.hideMobileFilters()
    }

    checkFilters = () => {
        let filtersArray = [];
        [...document.querySelectorAll(".sk-selected-filters")].map(e => {
            //console.log({data: e.children})
            for (let i of e.children) {
                //console.log(i.classList)
                for (let x of i.classList) {
                    if (x.includes("selected-filter--")) {
                        let fil = x.split("selected-filter--")[1]
                        filtersArray.push(fil)
                    }
                }
            }

        })
        //this.setState({ showClearFilter: true })
        filtersArray = new Set(filtersArray)
        filtersArray = [...filtersArray]
        filtersArray = filtersArray.filter(fil => fil !== "type" && fil !== "subtype")
        if(filtersArray.length > 0){
            if(!this.state.showClearFilter){
                this.setState({ showClearFilter: true })                
            }
        }else{
            if(this.state.showClearFilter) this.setState({ showClearFilter: false })              
        }
        
    }
    finishTransform = filters => {
        return filters.map(filter => {
            filter.title = this.props.intl.formatMessage({ id: filter.key })
            return filter
        })
    }
    outletQualityTransform = filters => {
        let result = filters.map(filter => {
            filter.title = this.props.intl.formatMessage({ id: 'outlet_' + filter.key.toLowerCase() })
            return filter
        })
        if (result.length !== 0 && !this.state.showMoreInfoOfOutlet) this.setState({ showMoreInfoOfOutlet: true })
        return result
    }
    discontinuedTransform = filters => {
        let result = filters.map(filter => {
            filter.title = this.props.intl.formatMessage({ id: filter.key.toUpperCase() + '_SHORT' })
            return filter
        })
        return result
    }
    outletDeviatedTransform = filters => {
        let result = filters.map(filter => {
            filter.title = this.props.intl.formatMessage({ id: filter.key.toUpperCase() + '_SHORT' })
            return filter
        })
        return result
    }
    operationTypeTransform = filters => {
        return filters.map(filter => {
            filter.title = this.props.intl.formatMessage({ id: 'operation_type_' + filter.key.toLowerCase() })
            return filter
        })
    }
    thicknessTransform(filters) {
        return filters
            .map(filter => {
                filter.title = filter.key.replace('.', ',') + ' cm'
                return filter
            })
            .sort((a, b) => parseFloat(a.key) - parseFloat(b.key))
    }

    brandTransform = filters => {
        const typeMap = {
            SERVICES: <Translation id="operaciones" defaultMessage="Operaciones" />,
            DEKTON: this.props.selectedCenter === 7130 ? 'DKTN' : 'Dekton'
        }

        //filters = filters.filter(e => )
        return filters.map(bucket => {
            return {
                title: typeMap[bucket.key] || bucket.key,
                ...bucket
            }
        })
    }
    typeTransform(filters) {
        const typeMap = {
            TABLA: <Translation id="tables" defaultMessage="Slabs" />,
            PPCC: <Translation id="professional_products" defaultMessage="Professional Products" />,
            FREGADERO: <Translation id="sinks" defaultMessage="Sinks" />,
            MK: <Translation id="mktool" defaultMessage="Marketing Tools" />,
            SH: <Translation id="showroom" defaultMessage="Showroom" />,
            WH: <Translation id="warehouse" defaultMessage="Warehouse" />,
            OPERATIONS: <Translation id="OPERATIONS" defaultMessage="Operations" />,
            SERVICE: <Translation id="SERVICE" defaultMessage="Services" />,
            ENCIMERA: <Translation id="WORKTOPS" defaultMessage="Worktops" />,
            ELABORADOS: <Translation id="manufactured_products" defaultMessage="Manufactured products" />,
            BAÑOS: <Translation id="baths_and_wall_cladding" defaultMessage="Bathrooms and Wall Cladding" />,
            '01': <Translation id="SAMPLES" defaultMessage="Samples" />,
            '02': <Translation id="OTHERS" defaultMessage="Others" />,
            '03': <Translation id="MERCHANDISING" defaultMessage="Merchandising" />,
            '04': <Translation id="LITERATURE" defaultMessage="Literature" />,
            '05': <Translation id="TAGS" defaultMessage="Tags" />,
            '06': <Translation id="ANCHORING" defaultMessage="anchoring" />,
            '07': <Translation id="BOXES" defaultMessage="Boxes" />,
            '08': <Translation id="DISPLAYS" defaultMessage="Displays" />,
            '09': <Translation id="BOOKS" defaultMessage="Books" />,
            '10': <Translation id="CABINETS" defaultMessage="Cabinets" />,
            '11': <Translation id="SIGNAGE" defaultMessage="Signage" />,
            '12': <Translation id="TEST_KITS" defaultMessage="Test kits" />,
            '13': <Translation id="WORKWEAR" defaultMessage="Workwear" />,
            '14': <Translation id="EPP" defaultMessage="EPP" />,
            '15': <Translation id="WAREHOUSE_SUPPLIES" defaultMessage="warehouse supplies" />,
            NEWS: <Translation id="NEWS" defaultMessage="NEWS" />
        }
        let sorting = {
            TABLA: { val: 0 },
            PPCC: { val: 3 },
            FREGADERO: { val: 2 },
            BAÑOS: { val: 2 },
            '01': { val: 0 },
            '08': { val: 1 },
            '07': { val: 2 },
            '09': { val: 3 },
            '12': { val: 4 },
            '04': { val: 5 },
            '03': { val: 6 },
            '05': { val: 7 },
            '02': { val: 9999 }
        }
        filters.sort((a, b) => {
            if (b.key === '02') return -1
            else if (sorting[b.key] && sorting[a.key]) return sorting[a.key].val - sorting[b.key].val
            else return 1
        })

        return filters
            .filter(bucket => typeMap[bucket.key])
            .map(bucket => {
                return {
                    title: typeMap[bucket.key],
                    ...bucket
                }
            })
    }
    shippingCenterTransform = filters => {
        return this.props.shippingAddressesCenters && this.props.shippingAddressesCenters
    }
    subtypeTransform = data => {
        return data.map(item => {
            item.title = this.props.intl.formatMessage({ id: 'subtype_' + item.key.toLowerCase().replace(/\s/g, '_'), defaultMessage: item.key.toLowerCase() })
            return item
        })
    }

    supplyWarehouseTransform = filters => {
        const typeMap = {
            '13': <Translation id="WORKWEAR" defaultMessage="Workwear" />,
            '14': <Translation id="EPP" defaultMessage="EPP" />,
            '15': <Translation id="WAREHOUSE_SUPPLIES" defaultMessage="warehouse supplies" />,
        }

        return filters.map(bucket => {
            return {
                title: typeMap[bucket.key] || bucket.key,
                ...bucket
            }
        })
    }

    render() {
        let bannerSelector =
            this.props.center === 7110
                ? 'BE-' + this.props.intl.locale.toUpperCase()
                : this.props.center === 7130
                ? 'CH-' + this.props.intl.locale.toUpperCase()
                : this.props.intl.locale.toUpperCase()
        const favouritesFilter = (
            <CheckboxFilter
                id='favouriteV2'
                title={this.props.intl.formatMessage({ id: 'favourites' })}
                label={this.props.intl.formatMessage({ id: 'only_show_favourites' })}
                filter={TermsQuery('productId.raw', this.props.favouriteProducts)}
            />
        )
        const discountsCatalogFilter = (
            <CheckboxFilter
                id="discountsV2"
                title=''
                // title={this.props.intl.formatMessage({
                //     id: 'filter_discounts'
                // })}
                label={this.props.intl.formatMessage({
                    id: 'only_show_discounts'
                })}
                // filter={TermsQuery('productId.raw', this.props.userDiscountsProductIds)} only user discounts
                // filter={BoolShould([TermQuery('haveDiscounts', true)])} only catalog discounts
                filter={BoolShould([TermQuery('haveDiscounts', true), TermsQuery('productId.raw', this.props.userDiscountsProductIds)])} //both
            />
        )


        const mkToolShippingCenterFilter =


            this.props.selectedCenter === 1060 && !this.props.isMagazineMk ? (
            < div className = 'operationType mktools'>
                <div id='operationType-filter' className='filter-list filter-mktool-v2'>

                    <RefinementListFilter
                        id="mktoolShippingCenter"
                        title={this.props.intl.formatMessage({
                            id: 'mktoolShippingCenter'
                        })}
                        field="mktoolShippingCenter.raw"
                        listComponent={this.ShippingCenterOptions}
                        bucketsTransform={this.shippingCenterTransform}
                        size={9999}
                        operator="OR"
                    />
                </div>
            </div >
            )
            :
            (this.ShippingCenterOptionsWithoutFilter(this.props.shippingAddressesCenters))

        const typeFilter = (
            <RefinementListFilter
                id="type"
                title={this.props.intl.formatMessage({
                    id: 'type'
                })}
                field={`${this.props.mktools ? 'typeId.raw' : 'type.raw'}`}
                operator="OR"
                bucketsTransform={this.typeTransform}
                orderKey="_term"
                className='test'
            />
        )
        const subTypeFilter = (
            <div style={{display: "none"}}>
            <RefinementListFilter
                id="subtype"
                title={this.props.intl.formatMessage({ id: 'subtype' })}
                field="subtypeName.raw"
                operator="OR"
                bucketsTransform={this.subtypeTransform}
                size={5}
            />
            </div>
        )
        const subTypeFilterForMktools = (
            <RefinementListFilter
                id="subtype"
                title={this.props.intl.formatMessage({
                    id: 'subtype'
                })}
                field="subtypeId.raw"
                listComponent={this.subtypeOptions0}
                operator="OR"
            />
        )
        const subType1filter = (
            <RefinementListFilter
                id="subtype1"
                title={this.props.intl.formatMessage({
                    id: 'subtype1'
                })}
                field="subtype1Id.raw"
                listComponent={this.subtypeOptions1}
                operator="OR"
            />
        )
        const subType2Filter = (
            <RefinementListFilter
                id="subtype2"
                title={this.props.intl.formatMessage({
                    id: 'subtype2'
                })}
                field="subtype2Id.raw"
                listComponent={this.subtypeOptions2}
                operator="OR"
            />
        )
        const operationTypeFilterForIkea = <FilterV2Box
                                              filteredDataName='operationType'
                                              translatedTitle={this.props.intl.formatMessage({ id: 'OPERATIONS' })}
                                              onTransform={this.operationTypeTransform}
        />

        const outletQualityFilter = <FilterV2Box
          filteredDataName='outletQuality'
          translatedTitle={this.props.intl.formatMessage({ id: 'outletQuality' })}
          onTransform={this.outletQualityTransform}
        />

        const discontinuedFilter = (
            <RefinementListFilter
                id="discontinued"
                title={this.props.intl.formatMessage({
                    id: 'discontinued'
                })}
                field="discontinued.raw"
                operator="OR"
                bucketsTransform={this.discontinuedTransform}
            />
        )
        const outletDeviatedFilter = (
            <CheckboxFilter
                id="outletDeviated"
                title={this.props.intl.formatMessage({
                    id: 'deviated'
                })}
                label="deviated"
                filter={BoolShould([TermQuery('outletDeviated.raw', true)])}
            />
        )

        const outletFilter = (
            <CheckboxFilter
                id="outlet"
                title={this.props.intl.formatMessage({
                    id: 'outlet'
                })}
                label="outlet"
                filter={BoolShould([TermQuery('outlet.raw', false)])}
            />
        )
        const webHeadingFilter = (
            <RefinementListFilter
                id="webHeading"
                title={this.props.intl.formatMessage({
                    id: 'category'
                })}
                field="webHeading.raw"
                operator="OR"
                bucketsTransform={this.typeTransform}
                orderKey="_term"
            />
        )
        const thicknessFilter = <FilterV2Box
                                filteredDataName='thickness'
                                translatedTitle={this.props.intl.formatMessage({ id: 'thickness' })}
                                onTransform={this.thicknessTransform}
                                size={5}
                                gtmLabel='ec_filtros_grosor'
        />

        const formatFilter = <FilterV2Box
                              filteredDataName='format'
                              translatedTitle={this.props.intl.formatMessage({ id: 'format' })}
                              onTransform={this.finishTransform}
                              size={5}
                              gtmLabel='ec_filtros_formato'           
        />
        const finishFilter = <FilterV2Box
                              filteredDataName='finish'
                              translatedTitle={this.props.intl.formatMessage({ id: 'finish' })}
                              onTransform={this.finishTransform}
                              gtmLabel='ec_filtros_acabado'
        />

        const finishFamilyFilter = <FilterV2Box
                                      filteredDataName='finishFamily'
                                      translatedTitle={this.props.intl.formatMessage({ id: 'finishFamily' })}
                                      onTransform={this.finishTransform}
                                      gtmLabel='ec_filtros_familia_acabados'
        />
        const meshedFilter = (
            <CheckboxFilter
                id="zaddon"
                title={this.props.intl.formatMessage({
                    id: 'MAL'
                })}
                label={this.props.intl.formatMessage({
                    id: 'MAL'
                })}
                filter={BoolShould([TermQuery('zaddon.raw', 'MAL')])}
            />
        )
        const colorFilter = (

                <div className='filtersV2-box'>
                    <div id='colorMktooks-filter' className='filter-list'>
                        <RefinementListFilter
                            id="color"
                            title={this.props.intl.formatMessage({
                                id: 'color'
                            })}
                            field="colorName.raw"
                            operator="OR"
                            listComponent={this.colorOptions}
                            size={9999}
                            orderKey="_term"
                        />
                    </div>
                </div>


        )
        const brandFilter = <FilterV2Box
                                filteredDataName='brand'
                                translatedTitle={this.props.intl.formatMessage({ id: 'brands' })}
                                onTransform={this.brandTransform}
                                gtmLabel='ec_filtros_marcas'

        />

        const promoFilter = (
            <CheckboxFilter
                id="promo"
                title={this.props.intl.formatMessage({
                    id: 'promotion'
                })}
                field="promo.raw"
                operator="OR"
                label="promotion"
                filter={BoolShould([TermQuery('promo.raw', true)])}
            />
        )

        const supplyWarehouseFilter = <FilterV2Box 
                                    filteredDataName='typeId'
                                    translatedTitle={this.props.intl.formatMessage({ id: 'WAREHOUSE_SUPPLIES' })}
                                    onTransform={this.supplyWarehouseTransform}
                                />


        return (
            <div>
                {
                /*<div

                    className="toggle-mobile-filters d-none d-lg-none"
                    onClick={this.showMobileFilters}
                    style={{
                        position: 'fixed',
                        zIndex: 150,
                        background: 'white',
                        width: '70px',
                        height: '40px',
                        bottom: '100px'
                    }}
                >
                    <img alt="" src={iconFilters} />
                    {/* <Translation id="view_filters" defaultMessage="View Filters" />
                    </div>
                */}

                <div data-cy="catalog__group_filters_options v2" style={{ background:'transparent', padding: '10px 0px' }}>
                    {this.props.mktools && this.props.isLogged && !this.state.selectedShippingCenter && this.props.userType === 'ADMIN' && (
                        <div className="container">
                            <Modal
                                customClass="showOverflow"
                                title={<Translation id="please_select_shipping_center" defaultMessage="Please select shipping center" />}
                                showCloseButton={false}
                            >
                                {this.props.isLoadingShippingAddresses ? (
                                  <Translation id="loading_shipping_addresses" defaultMessage="Loading Shipping Addresses" />
                                ) : (

                                        <div className={this.props.mktools ? 'filters__contain mktools' : 'filters__contain'}>{mkToolShippingCenterFilter}

                                        </div>
                                )}
                            </Modal>
                        </div>
                    )}
                    {this.props.hasPromoActivated &&
                        (this.props.promoBanners[bannerSelector] || this.props.promoBanners['default'].catalogBanner) &&
                        !this.props.location.search.includes('promo') && (
                            <div className="">
                                <LocalizedLink routeId="ROUTE_CATALOG" queryString="?type[0]=TABLA&promo=true" params={{ outlet: '' }}>
                                    <div className="d-block d-md-none">
                                        <img
                                            src={
                                                this.props.promoBanners[bannerSelector]
                                                    ? this.props.promoBanners[bannerSelector].homeBannerMobile
                                                    : this.props.promoBanners['default'].homeBannerMobile
                                            }
                                            style={{ width: '100%' }}
                                            alt=""
                                            extraClass=" image-slide"
                                        />
                                    </div>
                                    <div className="d-none d-md-block">
                                        <img
                                            src={
                                                this.props.promoBanners[bannerSelector]
                                                    ? this.props.promoBanners[bannerSelector].homeBannerMobile
                                                    : this.props.promoBanners['default'].homeBannerMobile
                                            }
                                            style={{ width: '100%' }}
                                            alt=""
                                            extraClass=" image-slide"
                                        />
                                    </div>
                                </LocalizedLink>
                                <br />
                            </div>
                        )}
                    {/*<div style={{ display: 'none' }}>
                        <RefinementListFilter id="webHeading" title={this.props.intl.formatMessage({ id: 'subtype' })} field="webHeading.raw" operator="OR" size={5} />
                    </div>*/}

                    {

                        <span className='filter-responsive' onClick={() => {

                            [...document.querySelectorAll("#filters")].map(e => {
                                e.hidden = !e.hidden;
                                e.style.display = "flex";
                                [...document.querySelectorAll("#show-filters")].map(i => i.hidden = !e.hidden);
                                [...document.querySelectorAll("#hide-filters")].map(i => i.hidden = e.hidden);

                            });
                        }}>

                            <strong id='show-filters'>Mostrar Filtros </strong>
                            <strong hidden id='hide-filters'>Ocultar Filtros </strong>
                            <i className="fas fa-filter fa-xs"></i>
                        </span>
                    }

                    <div className="filters" id='filters' hidden={window.innerWidth < 776}>


                        {/*this.props.isLogged && this.props.canViewPricesPhase3 && (
                            <div className="filters__contain" onClick={() => this.props.filterDiscounts()}>
                                <div className="col-12" style={{ padding: '0px' }}>
                                    {discountsCatalogFilter}
                                </div>
                            </div>
                        )*/}

                        {this.props.mktools && (
                            <div style={{ display: 'none' }} className="row no-gutters">
                                <div className="col-12">{webHeadingFilter}</div>
                            </div>
                        )}
                        {!this.props.isLowesCenter && (
                            <div className="d-none">
                                <div className="col-12 v2">{typeFilter}</div>
                            </div>
                        )}

                        <div className={this.props.mktools ? 'filters__contain mktools' : 'filters__contain'}>
                            <div className='first-line'>
                                {/*this.props.mktools ? subTypeFilterForMktools : this.props.isIkeaCenter || this.props.isLowesCenter ? null : subTypeFilter*/}

                                {/*this.props.mktools && (
                                    <React.Fragment>
                                        {subType1filter}
                                        {subType2Filter}
                                    </React.Fragment>
                                )*/}

                                {brandFilter}

                                {!this.props.isLowesCenter && this.props.mktools && this.props.isWarehouse && (
                                    supplyWarehouseFilter
                                )}

                                {/*this.props.mktools && colorFilter*/}

                                {finishFamilyFilter}
                                {subTypeFilter}
                                {!this.props.mktools && (this.props.userType === 'ADMIN' || this.props.impersonatedBy || this.props.isIkeaCenter) && finishFilter}
                                {!this.props.isLowesCenter && !this.props.isIkeaCenter && formatFilter /* FORMATO */}
                                {this.props.isIkeaCenter && operationTypeFilterForIkea}
                                {thicknessFilter /*GROSOR*/}
                                {/*{meshedFilter}*/}
                                {this.props.outlet && outletQualityFilter}
                                {this.props.selectedCenter === 1000 && (
                                    <React.Fragment>
                                        {this.props.outlet && discontinuedFilter}
                                        {(this.props.isAdmin || this.props.impersonatedBy) && outletDeviatedFilter}
                                    </React.Fragment>
                                )}
                                <div className="d-none" gtm-label='ec_filtros_outlet'>{outletFilter}</div>
                                <div className="d-none">{promoFilter}</div>

                            {this.props.selectedCenter === 7777 && (
                                <span>
                                    For assistance please email <a href="mailto:lowescustomercare@cosentino.com">lowescustomercare@cosentino.com</a>
                                </span>
                            )}
                            {this.props.isLogged && this.props.favouriteProducts && (
                                <div className="contain-favourites" onClick={() => this.props.filterFavourites()}>
                                    <div  style={{ padding: '0px'}}>
                                        {favouritesFilter}
                                    </div>
                                </div>
                            )}
                        </div>
                        </div>
                        <div className='tags_clean'>
                            <SelectedFilters

                            />
                            
                            {
                                this.state.showClearFilter &&
                                <ResetFilters component={<ClearFiltersDesktop onReset={this.onReset} />} />
                            }
                        </div>
                        {/*<div className="mobile-actions d-block d-lg-none">
                            <ResetFilters component={<ClearFiltersMobile onReset={this.onReset} />} />
                            <div className="bbt bt-apply-filters" onClick={this.applyFilters}>
                                <Translation id="apply" defaultMessage="Apply" />
                            </div>
                        </div>*/}
                    </div>
                </div>
                {/*<div className="row no-gutters d-none d-lg-block" style={{ marginTop: '20px' }}>
                    <BannerHybriq squared />

                    {!this.props.isHideAllBannerCatalog && (
                        <React.Fragment>
                            {this.props.typeClient && this.props.typeClient === 1 && (
                                <React.Fragment>
                                    {this.props.typeClient && this.props.testAB === 'A' && (
                                        <BannerMiPortalFinanciero type="v2" />
                                    )}
                                    {this.props.typeClient && this.props.testAB === 'B' && (
                                        <BannerTarificador type="v2" />
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                    {this.props.typeClient && (this.props.typeClient === 2 || this.props.typeClient === 3) && (
                        <BannerPPCC type="v2" />
                    )}
                </div>*/}
            </div>
        )
    }
}
export default withRouter(injectIntl(FiltersV2))

const ClearFiltersDesktop = ({ bemBlock, hasFilters, translate, resetFilters, onReset }) => (

    <span
        size="small"
        icon="trash"
        hidden={!hasFilters}
        className="clean-filters"
        onClick={() => {
            resetFilters()
            onReset()
        }}
        datacy="catalog__clear_all_filters"
    >

        {translate('reset.clear_all')}
    </span>
)

const ClearFiltersMobile = ({ bemBlock, hasFilters, translate, resetFilters, onReset }) => (
    <div
        className="bbt bt-clear-filters"
        onClick={() => {
            resetFilters()
            onReset()
        }}
        datacy="catalog__clear_all_filters-mobile"
    >
        {translate('reset.clear_all')}
    </div>
)
