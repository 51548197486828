import React from 'react'

import { connect } from 'react-redux'
import {
    getSelectedBusinesSegment,
    getSelectedChannel,
    getSelectedSubchannel,
    getSelectedTypology,
    getCurrentSegmentation,
    getTypologyOptionsV3
} from '../../store/segmentation/reducers'
import { toggleSegmentation, selectTypology } from '../../store/segmentation/actions'
import Select from 'react-select'
import Translation from '../global/Translation'
import { getProductById } from "../../store/products/actions";

const mapStateToProps = state => {
    const businessSegment = getSelectedBusinesSegment(state)
    const channel = getSelectedChannel(state)
    const subchannel = getSelectedSubchannel(state)

    return {
        show: getCurrentSegmentation(state) === 'TYPOLOGY',
        selected: getSelectedTypology(state),
        options: getTypologyOptionsV3(state, businessSegment, channel, subchannel)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onToggle: () => {
            dispatch(toggleSegmentation('TYPOLOGY'))
        },
        onSelect: (value, name) => {
            dispatch(selectTypology(value, name))
        }
    }
}
const TypologyV3 = ({ show, selected, onToggle, product, onSelect, options, intl }) => {



    if (!options || Object.entries(options).length === 0 || !product) {
        return ''
    }

    const isBath = product.brand === 'BATHS'
    // const isBathType = product.type === 'BAÑOS'
    const isOptional = !isBath //&& isBathType

    if (isBath) options = options.filter(o => o.id === 'BA')
    // else if (!isBathType) options = options.filter(o => o.id === 'NB')

    let placeholder = intl.formatMessage({ id: 'choose_option' })
    if (isOptional) placeholder += ' (' + intl.formatMessage({ id: 'optional' }) + ')'

    if (options.length === 1 && selected !== options[0].id) {
        onSelect(options[0].id, options[0].name)
    }



    return (
        <div>
            <Translation id="enviroment" defaultMessage="Environment" />

            <Select
                placeholder={placeholder}
                // placeholder={intl.formatMessage({ id: 'choose_option' }) + ' (' + intl.formatMessage({ id: 'optional' }) + ')'}
                options={Object.entries(options).map(option => ({
                    label: option[1].name,
                    value: option[1].id
                }))}
                value={selected}
                onChange={option => option && onSelect(option.value, option.label)}
                clearable={false}
            />
            {/* <hr /> */}
        </div>
    )
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TypologyV3)
